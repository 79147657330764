import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { api } from "../../../../../../../../utility/AxiosInceptor";
import Axios from "axios";
import { useEffect, useState } from "react";
import PaymentStripeFormAlternateCard from "./stripe_payment_dialog/stripe_payment_form";
const BASE_URL = process.env.REACT_APP_BASE_URL;
let stripePromise;
export default function PaymentStripModuleAlternateCard({ setAlternateCardToken, setAlternateCardDetailVisibility }) {
    let [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

        api.post(`/api/web/billing/paymentintent`, { amount: 1200 })
            .then((response) => {
                setClientSecret(response.data.clientSecret);
            })
            .catch((err) => {});
    }, []);

    return (
        <>
            {clientSecret !== null ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                    }}
                >
                    <PaymentStripeFormAlternateCard setAlternateCardToken={setAlternateCardToken} setAlternateCardDetailVisibility={setAlternateCardDetailVisibility} />
                </Elements>
            ) : undefined}
        </>
    );
}
