import React, { useRef, useState } from "react";
import "./css/UploadCustomerFiles.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import CommonLoaderBlue from "../../../../../../../../components/Loader/LoaderBlue";
import { FileUploadIcon } from "./assets/Assets";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
export const UploadCustomerFiles = ({ cpData }) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const toast = useRef();
    const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
    const [validationSchema, setValidationSchema] = useState(
        Yup.object().shape({
            fileorurl: Yup.string().required("Field Is Required"),
            fileType: Yup.string().required("Field Is Required"),
            file: Yup.mixed().required("Field Is Required"),
            audioUrl: Yup.string().required("Field Is Required"),
        }),
    );
    const formik = useFormik({
        initialValues: {
            fileType: "",
            fileorurl: "",
            file: null,
            audioUrl: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            setIsUploadFileLoading(true);
            let updatedvalues = {};
            let formData = new FormData();
            if (values.fileorurl === "file") {
                let { fileorurl, audioUrl, ...updatedvaluehere } = values;
                updatedvalues = updatedvaluehere;

                formData.append("fileType", updatedvalues.fileType);
                formData.append("file", updatedvalues.file);
                formData.append("enrollmentId", cpData._id);
                formData.append("uploadedBy", userData._id);
            } else if (values.fileorurl === "audioUrl") {
                let { fileorurl, file, ...updatedvaluehere } = values;
                updatedvalues = updatedvaluehere;
                formData.append("fileType", updatedvalues.fileType);
                formData.append("audioUrl", updatedvalues.audioUrl);
                formData.append("enrollmentId", cpData._id);
                formData.append("uploadedBy", userData._id);
            }
            api.post(`/api/web/uploadfiles/upload-file`, formData)
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "File Upload", detail: res?.data?.msg || "File Uploaded Successfully" });

                    setIsUploadFileLoading(false);
                })
                .catch((error) => {
                    toast.current.show({ severity: "error", summary: "File Upload", detail: error?.response?.data?.message || "File Uploading Failed" });
                    setIsUploadFileLoading(false);
                });
        },
    });

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
            }}
            className="main-uploadcustomer-files"
        >
            <div className="main-upload-file-container">
                <div className="main-top-header-uploadfiles">
                    <p>Upload File</p>
                    <p>Upload Customer Files</p>
                </div>
                <div className="file-uploader-section">
                    <div className="file-uploader-section-wrapper">
                        <div className="file-upload-inputcontainer">
                            <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                                <label>Select a File</label>
                                {formik.touched.fileorurl && formik.errors.fileorurl && <label>{formik.errors.fileorurl}</label>}
                            </div>
                            <Dropdown
                                options={[
                                    {
                                        label: "File",
                                        value: "file",
                                    },
                                    {
                                        label: "audioUrl",
                                        value: "audioUrl",
                                    },
                                ]}
                                className={`main-drop ${formik.touched.fileorurl && formik.errors.fileorurl && "p-invalid"}`}
                                name="fileorurl"
                                onChange={(e) => {
                                    if (e.target.value === "file") {
                                        setValidationSchema(
                                            Yup.object().shape({
                                                fileorurl: Yup.string().required("Field Is Required"),
                                                fileType: Yup.string().required("Field Is Required"),
                                                file: Yup.mixed().required("Field Is Required"),
                                            }),
                                        );
                                    } else {
                                        setValidationSchema(
                                            Yup.object().shape({
                                                fileorurl: Yup.string().required("Field Is Required"),
                                                fileType: Yup.string().required("Field Is Required"),
                                                audioUrl: Yup.mixed().required("Field Is Required"),
                                            }),
                                        );
                                    }
                                    formik.handleChange(e);
                                }}
                                placeholder="Choose a file (PDF, SVG, or Audio URL)"
                                value={formik.values.fileorurl}
                            />
                        </div>
                        <div className="file-upload-inputcontainer">
                            <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                                <label>File Type</label>
                                {formik.touched.fileType && formik.errors.fileType && <label>{formik.errors.fileType}</label>}
                            </div>
                            <Dropdown
                                options={[
                                    { label: "Address Proof", value: "Address Proof History" },
                                    { label: "Bill Proof History", value: "Bill Proof History" },
                                ]}
                                name="fileType"
                                onChange={formik.handleChange}
                                className={`main-drop ${formik.touched.fileType && formik.errors.fileType && "p-invalid"}`}
                                placeholder="Select File (Address Proof, Bill History)"
                                value={formik.values.fileType}
                            />
                        </div>
                        {formik.values.fileorurl === "audioUrl" && (
                            <div className="file-upload-inputcontainer">
                                <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                                    <label>Link</label>
                                    {formik.touched.audioUrl && formik.errors.audioUrl && <label>{formik.errors.audioUrl}</label>}
                                </div>
                                <input name="audioUrl" onChange={formik.handleChange} className={`main-drop ${formik.touched.audioUrl && formik.errors.audioUrl && "p-invalid"}`} placeholder="Link" value={formik.values.audioUrl} />
                            </div>
                        )}
                    </div>
                    {formik.values.fileorurl === "file" && (
                        <div
                            onClick={() => {
                                let createInputField = document.createElement("input");
                                createInputField.type = "file";
                                createInputField.onchange = (e) => {
                                    formik.setFieldValue("file", e.target.files[0]);
                                };
                                createInputField.click();
                            }}
                            className="choose-file"
                        >
                            <p>{formik?.values?.file?.name || "Upload File"}</p>
                            <div className="choose-file-icon-wrapper">
                                <FileUploadIcon />
                            </div>
                        </div>
                    )}
                    <div className="flex flex-wrap flex-row justify-content-end w-full ">
                        <Button
                            disabled={isUploadFileLoading}
                            type="submit"
                            label={
                                isUploadFileLoading ? (
                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue sizehere={12} />
                                    </div>
                                ) : (
                                    "Submit"
                                )
                            }
                        ></Button>
                    </div>
                </div>
            </div>
            <Toast ref={toast} />
        </form>
    );
};
