import React from "react";
export const InvoiceActionIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.71875 8.00052C7.71875 8.15577 7.84475 8.28177 8 8.28027C8.15525 8.28027 8.28125 8.15427 8.28125 7.99902C8.28125 7.84377 8.15525 7.71777 8 7.71777C7.84475 7.71852 7.71875 7.84452 7.71875 8.00052"
                stroke="#475CED"
                stroke-width="1.125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.71875 5.00052C7.71875 5.15577 7.84475 5.28177 8 5.28027C8.15525 5.28027 8.28125 5.15427 8.28125 4.99902C8.28125 4.84377 8.15525 4.71777 8 4.71777C7.84475 4.71852 7.71875 4.84452 7.71875 5.00052"
                stroke="#475CED"
                stroke-width="1.125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.71875 11.0005C7.71875 11.1558 7.84475 11.2818 8 11.2803C8.15525 11.2803 8.28125 11.1543 8.28125 10.999C8.28125 10.8438 8.15525 10.7178 8 10.7178C7.84475 10.7185 7.71875 10.8445 7.71875 11.0005"
                stroke="#475CED"
                stroke-width="1.125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.75 4.25V11.75C14.75 13.4068 13.4068 14.75 11.75 14.75H4.25C2.59325 14.75 1.25 13.4068 1.25 11.75V4.25C1.25 2.59325 2.59325 1.25 4.25 1.25H11.75C13.4068 1.25 14.75 2.59325 14.75 4.25Z"
                stroke="#475CED"
                stroke-width="1.125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
