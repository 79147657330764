import "../css/AddNoteType.css";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Falsebutton, Tickbutton, Tickbuttonfill } from "../../../../../../../../../utility";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
export const AddNoteType = ({ toast, setRefreshNoteType, setIsAddNoteTypeActive }) => {
    const [mouseEnter, setMouseEnter] = useState(false);
    const LoginRes = JSON.parse(localStorage.getItem("userData"));
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        validationSchema: Yup.object().shape({
            noteType: Yup.string().required("Field Is Required"),
            note: Yup.string().required("Field Is Required"),
            active: Yup.string().required("Field Is Required"),
        }),
        initialValues: {
            serviceProvider: LoginRes.company,
            noteType: "",
            active: true,
            note: "",
        },

        onSubmit: (values, { resetForm }) => {
            setIsLoading(true);
            api.post(`/api/noteType`, values)
                .then(() => {
                    setIsLoading(false);
                    toast.current.show({ severity: "success", summary: "Note Type", detail: "Note Type Added Successfully" });
                    setRefreshNoteType((prev) => !prev);
                    setIsAddNoteTypeActive((prev) => !prev);
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Note Type", detail: error?.response?.data?.msg || "Adding Note Type Failed" });
                });
        },
    });
    return (
        <form onSubmit={formik.handleSubmit} className="main-add-notetype">
            <div className="add-note-type-field-here">
                <p>Add Note Type</p>
            </div>
            <div className="main-add-note-type-fields">
                <div className="input-container-notetypeadd">
                    <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                        <label>Note Type Name</label>
                        {formik.touched.noteType && formik.errors.noteType && <label>{formik.errors.noteType}</label>}
                    </div>
                    <input className={`${formik.touched.noteType && formik.errors.noteType ? "p-invalid" : ""}`} onChange={formik.handleChange} name="noteType" placeholder="Note Type Name" value={formik.values.noteType} />
                </div>{" "}
                <div className=" input-container-notetypeadd">
                    <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                        <label>Status</label>
                        {formik.touched.active && formik.errors.active && <label>{formik.errors.active}</label>}
                    </div>
                    <Dropdown
                        className={`status-dropdown ${formik.touched.active && formik.errors.active ? "p-invalid" : ""}`}
                        onChange={formik.handleChange}
                        options={[
                            { label: "Active", value: true },
                            { label: "In-Active", value: false },
                        ]}
                        name="active"
                        placeholder="Status"
                        value={formik.values.active}
                    />
                </div>{" "}
                <InputTextarea autoResize value={formik.values.note} onChange={formik.handleChange} name="note" placeholder="Note Description" className={`main-note-description ${formik.touched.note && formik.errors.note ? "p-invalid" : ""}`} />
            </div>
            <div className="bottom-button-container">
                <div
                    onClick={() => {
                        setIsAddNoteTypeActive((prev) => !prev);
                    }}
                    className="discountbutton"
                >
                    <Falsebutton />
                </div>
                <div
                    onClick={() => {
                        formik.handleSubmit();
                    }}
                    onMouseEnter={() => {
                        setMouseEnter(true);
                    }}
                    onMouseLeave={() => {
                        setMouseEnter(false);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {!mouseEnter ? <Tickbutton /> : <Tickbuttonfill />}
                </div>
            </div>
        </form>
    );
};
