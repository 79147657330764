import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import "./css/StripeCheckoutForm.css";
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "../../../../../../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { api } from "../../../../../../../../../../../../../../utility/AxiosInceptor";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";

export const PayInvoiceCheckoutForm = ({ setShowAmountError, setRefreshPayInvoice, setShowPayInvoiceDialog, funcToCall, setFucToCallOnPay, cpData, amount, selectedInvoices }) => {
    let toast = useRef();
    const stripe = useStripe();
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            cardNumber: "",
            cardExpiry: "",
            cardCvc: "",
        },
        validationSchema: Yup.object({
            cardNumber: Yup.string().required("Field is required"),
            cardExpiry: Yup.string().required("Required"),
            cardCvc: Yup.string().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (amount === "" || amount === undefined) {
                setShowAmountError(true);
            } else {
                setIsLoading(true);
                let response = await api.post(`/api/web/billing/paymentintent`, { amount: amount });
                const clientSecret = await response.data.clientSecret;
                if (!elements || !clientSecret) {
                    toast.current.show({ severity: "error", summary: "Stripe Elements not loaded", detail: "Please try again later." });
                    setIsLoading(false);
                    return;
                }

                // Call confirmCardPayment directly to confirm the payment
                const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement), // The CardNumberElement
                        billing_details: {
                            name: "Customer Name", // You can pass the customer name dynamically here
                        },
                    },
                });
                if (paymentIntent?.status === "requires_action") {
                    // 3D Secure authentication is required
                    handle3DSecureAuthentication(paymentIntent.client_secret, paymentIntent);
                } else if (error) {
                    toast.current.show({
                        severity: "error",
                        summary: "Payment Processing",
                        detail: (
                            <p>
                                {" "}
                                {error?.message} {error.code ? error.code : ""}
                            </p>
                        ),
                        life: 2000,
                    });

                    const idArray = selectedInvoices.map((obj) => obj._id);
                    const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);

                    api.post(`/api/web/billing/updatePaymentLog`, {
                        customerId: cpData?._id,
                        invoiceNo: idArray,
                        invoiceType: invoiceTypeArray,
                        paymentMethod: "Credit Card",
                        stripeId: error?.payment_intent?.id,
                        action: "failure",
                        cardNumber: error?.payment_method?.card?.last4,
                        reason: "failure",
                        transactionStatus: error?.message,
                        paymentStatus: "failure",
                        userId: loginRes?._id,
                        amount: amount,
                    })
                        .then((res) => {
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                        });
                } else {
                    const idArray = selectedInvoices.map((obj) => obj._id);
                    const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);
                    toast.current.show({ severity: "success", summary: "Payment Proceeding", detail: "Payment Proceed Successfully" });

                    Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                        invoices: idArray,
                        amountPaid: amount,
                        invoicePaymentMethod: "Credit Card",
                        paymentId: paymentIntent?.id,
                        customerId: cpData?._id,
                        userId: loginRes?._id,
                        isWallet: false,
                    })
                        .then(() => {
                            api.post(`/api/web/billing/updatePaymentLog`, {
                                customerId: cpData?._id,
                                invoiceNo: idArray,
                                invoiceType: invoiceTypeArray,
                                paymentMethod: "Credit Card",
                                stripeId: paymentIntent?.id,
                                userId: loginRes?._id,
                                action: "success",
                                reason: "success",
                                transactionStatus: paymentIntent?.status,
                                paymentStatus: "success",
                                amount: amount,
                            })
                                .then((res) => {
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setShowPayInvoiceDialog(false);
                                        setRefreshPayInvoice((prev) => !prev);
                                    }, 500);
                                    // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                                })
                                .catch((err) => {
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setShowPayInvoiceDialog(false);
                                        setRefreshPayInvoice((prev) => !prev);
                                    }, 500);
                                    ////toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                                });
                        })
                        .catch((err) => {
                            setIsLoading(false);
                        });
                }
            }
        },
    });

    const handle3DSecureAuthentication = async (clientSecret, paymentIntent) => {
        try {
            const { error } = await stripe.handleCardAction(clientSecret);
            if (error) {
                toast.current.show({
                    severity: "error",
                    summary: "Payment Processing",
                    detail: (
                        <p>
                            {" "}
                            {error?.message} {error.code ? error.code : "An error occurred while processing the payment"}
                        </p>
                    ),
                });
                const idArray = selectedInvoices.map((obj) => obj._id);
                const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);

                api.post(`/api/web/billing/updatePaymentLog`, {
                    customerId: cpData?._id,
                    invoiceNo: idArray,
                    invoiceType: invoiceTypeArray,
                    paymentMethod: "Credit Card",
                    stripeId: error?.payment_intent?.id,
                    action: "failure",
                    cardNumber: error?.payment_method?.card?.last4,
                    reason: "failure",
                    transactionStatus: error?.message,
                    paymentStatus: "failure",
                    userId: loginRes?._id,
                    amount: amount,
                })
                    .then((res) => {
                        // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                    })
                    .catch((err) => {
                        //toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                    });
            } else {
                const idArray = selectedInvoices.map((obj) => obj._id);
                const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);
                toast.current.show({ severity: "success", summary: "Payment Proceeding", detail: "Payment Proceed Successfully" });

                Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                    invoices: idArray,
                    amountPaid: amount,
                    invoicePaymentMethod: "Credit Card",
                    paymentId: paymentIntent?.id,
                    customerId: cpData?._id,
                    userId: loginRes?._id,
                    isWallet: false,
                })
                    .then(() => {
                        api.post(`/api/web/billing/updatePaymentLog`, {
                            customerId: cpData?._id,
                            invoiceNo: idArray,
                            invoiceType: invoiceTypeArray,
                            paymentMethod: "Credit Card",
                            stripeId: paymentIntent?.id,
                            userId: loginRes?._id,
                            action: "success",
                            reason: "success",
                            transactionStatus: paymentIntent?.status,
                            paymentStatus: "success",
                            amount: amount,
                        })
                            .then((res) => {
                                setIsLoading(false);
                                setTimeout(() => {
                                    setShowPayInvoiceDialog(false);
                                    setRefreshPayInvoice((prev) => !prev);
                                }, 500);
                            })
                            .catch((err) => {
                                setIsLoading(false);
                                setTimeout(() => {
                                    setShowPayInvoiceDialog(false);
                                    setRefreshPayInvoice((prev) => !prev);
                                }, 500);
                            });
                    })
                    .catch((err) => {});
            }
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message : "An error occurred while processing the payment"}</p> });

            const idArray = selectedInvoices.map((obj) => obj._id);
            const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);

            api.post(`/api/web/billing/updatePaymentLog`, {
                customerId: cpData?._id,
                invoiceNo: idArray,
                invoiceType: invoiceTypeArray,
                paymentMethod: "Credit Card",
                stripeId: error?.payment_intent?.id,
                action: "failure",
                cardNumber: error?.payment_method?.card?.last4,
                reason: "failure",
                transactionStatus: error?.message,
                paymentStatus: "failure",
                userId: loginRes?._id,
                amount: amount,
            })
                .then((res) => {
                    // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    //toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
        } finally {
            setIsLoading(false);
        }
    };
    const handleStripeElementChange = (formik, elementType) => (event) => {
        formik.setFieldTouched(elementType, event.complete); // sets touched state when element interaction completes
        formik.setFieldValue(elementType, event.complete ? event.value : ""); // sets formik value
    };

    return (
        <div>
            <Toast ref={toast} />

            <form
                className="main-payment-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
            >
                <div className="card-input-main">
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Number</label>
                            {formik.errors.cardNumber && formik.touched.cardNumber && <label>{formik.errors.cardNumber}</label>}
                        </div>
                        <CardNumberElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardNumber", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardNumber && formik.touched.cardNumber ? "p-invalid" : ""}`}
                        />
                    </div>
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Expiry</label>
                            {formik.errors.cardExpiry && formik.touched.cardExpiry && <label>{formik.errors.cardExpiry}</label>}
                        </div>

                        <CardExpiryElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardExpiry", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardExpiry && formik.touched.cardExpiry ? "p-invalid" : ""}`}
                        />
                    </div>
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Security Code *</label>
                            {formik.errors.cardCvc && formik.touched.cardCvc && <label>{formik.errors.cardCvc}</label>}
                        </div>

                        <CardCvcElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardCvc", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardCvc && formik.touched.cardCvc ? "p-invalid" : ""}`}
                        />
                    </div>
                </div>
            </form>
            <div className="action-buttons-container flex flex-wrap flex-row justify-content-end">
                <Button
                    disabled={isLoading}
                    onClick={() => {
                        setRefreshPayInvoice((prev) => !prev);
                        setShowPayInvoiceDialog(false);
                    }}
                    type="button"
                    label="Cancel"
                />
                <Button
                    onClick={() => {
                        if (amount === "" || amount === undefined) {
                            setShowAmountError(true);
                        }
                        formik.handleSubmit();
                    }}
                    disabled={isLoading}
                    label={
                        isLoading ? (
                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                <CommonLoaderBlue customSize={12} color={"white"} />
                            </div>
                        ) : (
                            "Pay Now"
                        )
                    }
                />
            </div>
        </div>
    );
};
