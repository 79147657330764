import { api } from "../../../../../../utility/AxiosInceptor";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { toast, useToast } from "react-toastify";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import Axios from "axios";
import { Dialog } from "primereact/dialog";
import "./billingmodel_configurations/css/updatebill.css";
import AddNewFeature from "./add_newfeature";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function UpdateBill({ rowData, setUpdatePlanVisibility, setRefresh }) {
    //const toast=Toast
    const [isLoading, setIsLoading] = useState(false);
    const [currentBillingId, setCurrentBillingId] = useState("");
    const [newDiscount, setNewDiscount] = useState(false);
    const [newFeature, setNewFeature] = useState(false);
    const [allPlan, setAllPlan] = useState([]);
    const [allDiscount, setAllDiscount] = useState([]);
    const [allFeature, setAllFeature] = useState([]);
    // const [inventoryTypeOptions, setInventoryTypeOptions] = useState([]);
    const [optionsForBillingmodel, setOptionsForBillingModel] = useState([]);
    const [optionsForInventoryType, setOptionForInventoryType] = useState([]);

    let additionalFeature = [];
    for (let i = 0; i < rowData.additionalFeature.length; i++) {
        additionalFeature.push(rowData?.additionalFeature[i]?._id);
    }
    additionalFeature = additionalFeature.filter((item) => item !== undefined);
    let oneTimeCharge = [];
    for (let i = 0; i < rowData.monthlyCharge.length; i++) {
        oneTimeCharge.push(rowData?.monthlyCharge[i]?._id);
    }

    oneTimeCharge = oneTimeCharge.filter((item) => item !== undefined);
    let selecteddiscount = [];
    for (let i = 0; i < rowData.selectdiscount.length; i++) {
        selecteddiscount.push(rowData?.selectdiscount[i]?._id);
    }
    selecteddiscount = selecteddiscount.filter((item) => item !== undefined);
    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            billingmodel: rowData.billingmodel,
            oneTimeCharge: rowData.oneTimeCharge,
            monthlyCharge: oneTimeCharge,
            inventoryType: rowData.inventoryType,
            dueDate: rowData.dueDate,
            paymentMethod: rowData.paymentMethod,
            selectdiscount: selecteddiscount,
            BillCreationDate: rowData.BillCreationDate,
            additionalFeature: additionalFeature,
            latefeeCharge: rowData.latefeeCharge,
            makeModel: `${rowData?.make} - ${rowData?.model}`,
            applyLateFee: rowData.applyLateFee,
            subsequentBillCreateDate: rowData.subsequentBillCreateDate,
            // applyToCustomer: rowData?.applyToCustomer,
            applyToCustomer: "both",
        },
        onSubmit: async (values, actions) => {
            /*  let selectdiscount = [];
            for (let i = 0; i < Object.keys(formik.values.selectdiscount).length; i++) {
                selectdiscount.push(formik.values.selectdiscount[i]._id);
            }
            let additionalfeature = [];
            for (let i = 0; i < Object.keys(formik.values.additionalFeature).length; i++) {
                additionalfeature.push(formik.values.additionalFeature[i]._id);
            }
            let paymentMethod = [];
            for (let i = 0; i < Object.keys(formik.values.paymentMethod).length; i++) {
                paymentMethod.push(formik.values.paymentMethod[i]._id);
            }*/
            setIsLoading(true);
            const dataToSend = {
                ServiceProvider: parseLoginRes?.company,
                oneTimeCharge: formik.values.oneTimeCharge,
                monthlyCharge: formik.values.monthlyCharge,
                dueDate: formik.values.dueDate,
                paymentMethod: formik.values.paymentMethod,
                selectdiscount: formik.values.selectdiscount,
                BillCreationDate: formik.values.BillCreationDate,
                additionalFeature: formik.values.additionalFeature,
                inventoryType: formik.values.inventoryType,
                latefeeCharge: formik.values.latefeeCharge,
                applyLateFee: formik.values.applyLateFee,
                subsequentBillCreateDate: formik.values.subsequentBillCreateDate,
                applyToCustomer: formik.values.applyToCustomer,
                make: formik.values.makeModel.substring(0, formik.values.makeModel.indexOf("-") - 1),
                devicemodel: formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2),
            };

            try {
                const response = await api.put(`/api/web/billing/billconfig?id=${rowData._id}`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Bill Updated Successfully");
                    setIsLoading(false);
                    setTimeout(() => {
                        setUpdatePlanVisibility(false);
                        setRefresh((prev) => !prev);
                    }, 2000);
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.msg);
            }
        },
    });
    const [makeModelOptions, setMakeModelOptions] = useState();
    useEffect(() => {
        if (formik.values.inventoryType !== "" && currentBillingId !== "") {
            api.get(`/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    let Data = res?.data?.data;
                    let dataNew = [];
                    for (let i = 0; i < Data.length; i++) {
                        let data = Data[i];
                        data.makemodel = `${data.make} - ${data.model}`;
                        dataNew.push(data);
                    }
                    setMakeModelOptions(dataNew);
                })
                .catch((err) => {});
        }
    }, [currentBillingId, formik.values.inventoryType]);

    const addDiscount = async () => {
        const dataToSend = {
            ServiceProvider: parseLoginRes?.company,
            discountname: formik.values.discountname,
            amount: formik.values.amount,
        };

        try {
            const response = await api.post(`/api/web/discount/adddiscount`, dataToSend);
            if (response?.status == 200 || response?.status == 201) {
                setNewDiscount(false);
                getDiscount();
                toast.success("Discount added successfully");
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const optionsForPayment = [
        // { label: "Cash", value: "Cash" },
        // { label: "Credit Card", value: "Credit Card" },
        //{ label: "Credit Card (Authorize)", value: "Credit Card Authorize" },
        { label: "Credit Card", value: "Credit Card Stripe" },
        //{ label: "Money Order", value: "Money Order" },
        // { label: "E-Check", value: "E-Check" },
        // { label: "E-Check (Authorize)", value: "E-Check Authorize" },
        //  { label: "E-Check (Stripe)", value: "E-Check Stripe" },
        { label: "Skip Payment", value: "Skip Payment" },
    ];

    const optionsForCreation = [
        { label: "On Activation", value: "On Activation" },
        { label: "After QA Approval ", value: "On QA Approval" },
    ];

    const getDiscount = async () => {
        try {
            const res = await api.get(`/api/web/discount/getalldiscounts`);
            setAllDiscount(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const getFeature = async () => {
        try {
            const res = await api.get(`/api/web/feature/getallfeatures`);
            setAllFeature(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        getDiscount();
        getBillingModelList();
        getFeature();
    }, []);
    const [firstTimerender, setFirstTimerender] = useState(true);
    useEffect(() => {
        if (formik.values.inventoryType !== "" && formik.values.makeModel !== "") {
            api.get(
                `/api/web/plan/getByInventoryType?inventoryType=${formik.values.inventoryType}&make=${formik.values.makeModel?.substring(0, formik.values.makeModel.indexOf("-") - 1)}&model=${formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2)}&serviceProvider=${parseLoginRes.company}&billingModel=${formik.values.billingmodel}`,
            ).then((res) => {
                if (!firstTimerender) {
                    formik.setFieldValue("monthlyCharge", []);
                } else {
                    setFirstTimerender(false);
                }

                setAllPlan(res?.data?.data || []);
            });
        }
    }, [formik.values.inventoryType, formik.values.makeModel]);
    useEffect(() => {
        async function fetchData() {
            if (formik.values.billingmodel !== "") {
                try {
                    const res = await api.get(`/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                    let obj = [];
                    let data = res?.data?.data;
                    data.forEach((item) => {
                        let obj2 = {};
                        obj2.inventoryType = item;
                        obj.push(obj2);
                    });
                    // setInventoryTypeOptions(obj);
                    setOptionForInventoryType(obj);
                } catch (error) {
                    //toast.error(error?.response?.data?.msg);
                }
            }
        }
        fetchData();
    }, [currentBillingId]);
    const getBillingModelList = async () => {
        try {
            const res = await api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`);
            setOptionsForBillingModel(res?.data?.data || []);
            let billingmodel = res?.data?.data;
            let id;
            billingmodel.map((item) => {
                if (item.billingModel === formik.values.billingmodel) {
                    id = item._id;
                }
            });
            setCurrentBillingId(id);
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Plan Updation", detail: error?.response?.data?.msg });
        }
    };
    return (
        <div className="billingform">
            <div className="forminner">
                <p>Configuration</p>
                <div className="addbillingfield">
                    <div className="flex justify-content-between" style={{ width: "33.2rem" }}>
                        <label className="mt-0">Billing Model </label>
                        {formik.touched.billingmodel && formik.errors.billingmodel ? <p className="p-error">{formik.errors.billingmodel}</p> : null}
                    </div>
                    <Dropdown
                        className="addbillingfieldinput"
                        id="billingmodel"
                        options={optionsForBillingmodel}
                        value={formik.values.billingmodel}
                        placeholder="Select Billing Model"
                        optionLabel="billingModel"
                        optionValue="billingModel"
                        onChange={(e) => {
                            formik.setFieldValue("billingmodel", e.value);
                            let id;
                            optionsForBillingmodel.map((item) => {
                                if (item.billingModel === e.value) {
                                    id = item._id;
                                }
                            });
                            formik.handleChange(e);
                            formik.setFieldValue("inventoryType", "");
                            setCurrentBillingId(id);
                            // setOnChangeBillingModel((prev) => !prev);
                        }}
                    />
                </div>
                <label style={{ fontSize: "1.2rem", fontWeight: "600", color: "#72728F" }} className="addbillingfield">
                    Add Inventory Details
                </label>
                <div>
                    <div className="mainbillingconfig flex w-full flex-wrap flex-row justify-content-left  ">
                        <div className="flex">
                            <div className="addbillingfield ">
                                <div className="flex justify-content-between">
                                    <label className="">Inventory Type</label>
                                    {formik.touched.inventoryType && formik.errors.inventoryType ? <p className="p-error">{formik.errors.inventoryType}</p> : null}
                                </div>
                                <Dropdown
                                    className="addbillingfieldinput"
                                    id="inventoryType"
                                    placeholder="Select Inventory Type"
                                    options={optionsForInventoryType}
                                    value={formik.values.inventoryType}
                                    optionLabel="inventoryType"
                                    optionValue="inventoryType"
                                    onChange={(e) => {
                                        formik.setFieldValue("inventoryType", e.value);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex justify-content-between">
                                    <label>Make And Model</label>
                                    {formik.touched.makeModel && formik.errors.makeModel ? <p className="p-error">{formik.errors.makeModel}</p> : null}
                                </div>
                                <Dropdown optionLabel="makemodel" optionValue="makemodel" value={formik.values.makeModel} name="makeModel" options={makeModelOptions} onChange={formik.handleChange} placeholder="Select an option" className="addbillingfieldinput " />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex justify-content-between">
                                    <label className="">One Time Charges</label>
                                    {formik.touched.oneTimeCharge && formik.errors.oneTimeCharge ? <p className="p-error">{formik.errors.oneTimeCharge}</p> : null}
                                </div>
                                <InputText disabled id="oneTimeCharge" className="addbillingfieldinput" placeholder="Enter One Time Charges" value={formik.values.oneTimeCharge} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="addbillingfield">
                                <div className="flex justify-content-between">
                                    <label>Monthly Charges</label>
                                    {formik.touched.monthlyCharge && formik.errors.monthlyCharge ? <p className="p-error">{formik.errors.monthlyCharge}</p> : null}
                                </div>
                                <MultiSelect
                                    id="monthlyCharge"
                                    display="chip"
                                    options={allPlan}
                                    placeholder="Monthly Charges"
                                    value={formik.values.monthlyCharge}
                                    onChange={(e) => formik.setFieldValue("monthlyCharge", e.value)}
                                    optionLabel={(option) => `${option.name} - (${option.planId})`}
                                    optionValue="_id"
                                    className="addbillingfieldinput"
                                />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex justify-content-between">
                                    <label>First Bill Create Date</label>
                                    {formik.touched.BillCreationDate && formik.errors.BillCreationDate ? <p className="p-error">{formik.errors.BillCreationDate}</p> : null}
                                </div>
                                <Dropdown
                                    className="addbillingfieldinput"
                                    id="BillCreationDate"
                                    placeholder="First Bill Create Date"
                                    options={optionsForCreation}
                                    value={formik.values.BillCreationDate}
                                    onChange={(e) => {
                                        formik.setFieldValue("BillCreationDate", e.value);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex justify-content-between">
                                    <label>Subsequent Bill Create Date </label>
                                    {formik.touched.subsequentBillCreateDate && formik.errors.subsequentBillCreateDate ? <p className="p-error">{formik.errors.subsequentBillCreateDate}</p> : null}
                                </div>
                                <InputText id="subsequentBillCreateDate" className="addbillingfieldinput" placeholder="No of Days From First Bill Create Date" value={formik.values.subsequentBillCreateDate} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="addbillingfield">
                                <div className="flex justify-content-between">
                                    <label>Due Date</label>
                                    {formik.touched.dueDate && formik.errors.dueDate ? <p className="p-error">{formik.errors.dueDate}</p> : null}
                                </div>
                                <InputText id="dueDate" placeholder="No of days From Bill Create Date" className="addbillingfieldinput" value={formik.values.dueDate} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex justify-content-between">
                                    <label>Late Fee Charge</label>
                                    {formik.touched.latefeeCharge && formik.errors.latefeeCharge ? <p className="p-error">{formik.errors.latefeeCharge}</p> : null}
                                </div>
                                <InputText id="latefeeCharge" placeholder="Late Fee Charge" value={formik.values.latefeeCharge} className="addbillingfieldinput" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex justify-content-between">
                                    <label>Apply Late Fee </label>
                                    {formik.touched.applyLateFee && formik.errors.applyLateFee ? <p className="p-error">{formik.errors.applyLateFee}</p> : null}
                                </div>
                                <InputText id="applyLateFee" placeholder="No of Days from Due Date" className="addbillingfieldinput" value={formik.values.applyLateFee} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="addbillingfield">
                                <div className="flex justify-content-between">
                                    <label>Payment Method</label>
                                    {formik.touched.paymentMethod && formik.errors.paymentMethod ? <p className="p-error">{formik.errors.paymentMethod}</p> : null}
                                </div>
                                <MultiSelect
                                    className="addbillingfieldinput"
                                    id="paymentMethod"
                                    placeholder="Select Payment Method"
                                    options={optionsForPayment}
                                    display="chip"
                                    optionLabel="label"
                                    optionValue="label"
                                    value={formik.values.paymentMethod}
                                    onChange={(e) => {
                                        formik.setFieldValue("paymentMethod", e.value);
                                        formik.handleChange(e);
                                    }}
                                />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <label>Select Discount</label>
                                <MultiSelect
                                    id="selectdiscount"
                                    display="chip"
                                    placeholder="Select Discounts"
                                    options={allDiscount}
                                    value={formik.values.selectdiscount}
                                    onChange={(e) => formik.setFieldValue("selectdiscount", e.value)}
                                    optionLabel={(option) => `${option.discountname} - (${option.amount})`}
                                    optionValue="_id"
                                    className="addbillingfieldinput"
                                />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <label>Additional Feature</label>
                                <MultiSelect
                                    id="additionalFeature"
                                    display="chip"
                                    placeholder="Select Additional Features"
                                    options={allFeature}
                                    value={formik.values.additionalFeature}
                                    onChange={(e) => formik.setFieldValue("additionalFeature", e.value)}
                                    optionLabel={(option) => `${option.featureName} - (${option.featureAmount})`}
                                    optionValue="_id"
                                    className="addbillingfieldinput"
                                />
                            </div>
                        </div>
                        <div className="flex" style={{ marginTop: "1.8rem" }}>
                            <a
                                className="linkfordiscount"
                                // onClick={() => {
                                //     if (newDiscount || newFeature) {
                                //     } else {
                                //         setNewDiscount(true);
                                //         setNewFeature(false);
                                //     }
                                // }}
                                style={{ opacity: "0.5" }}
                            >
                                Add Discount
                            </a>
                            <a
                                className="linkfordiscount"
                                // onClick={() => {
                                //     if (newDiscount || newFeature) {
                                //     } else {
                                //         setNewDiscount(false);
                                //         setNewFeature(true);
                                //     }
                                // }}
                                style={{ opacity: "0.5", marginLeft: "2.4rem" }}
                            >
                                Add Feature
                            </a>
                        </div>
                    </div>
                    {newDiscount ? (
                        <div className="flex" style={{ marginTop: "2rem" }}>
                            <div className="addbillingfield">
                                <label>Discount Name</label>
                                <InputText id="discountname" className="addbillingfieldinput" value={formik.values.discountname} onChange={formik.handleChange} />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <label>Amount </label>
                                <InputText id="amount" className="addbillingfieldinput" value={formik.values.amount} onChange={formik.handleChange} />
                            </div>
                            <div style={{ marginTop: "1.2rem", marginLeft: "1.2rem", width: "33.2rem" }} className="flex align-items-end justify-content-end">
                                <Button
                                    type="button"
                                    label="Cancel"
                                    className="backbtn"
                                    onClick={() => {
                                        setNewFeature(false);
                                        setNewDiscount(false);
                                    }}
                                    style={{ width: "13.2rem", height: "4.2rem" }}
                                />
                                <Button type="button" label="Add Discount" onClick={addDiscount} className="nextbtn" style={{ width: "13.2rem", height: "4.2rem" }} />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    {newFeature ? (
                        <div className="flex">
                            <div className="addbillingfield">
                                <label>Feature Name</label>
                                <InputText id="featureName" className="addbillingfieldinput" value={formik.values.featureName} onChange={formik.handleChange} />
                            </div>
                            <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                <label> Amount </label>
                                <InputText id="featureAmount" className="addbillingfieldinput" value={formik.values.featureAmount} onChange={formik.handleChange} />
                            </div>
                            <div style={{ marginTop: "1.2rem", marginLeft: "1.2rem", width: "33.2rem" }} className="flex align-items-end justify-content-end">
                                <Button
                                    type="button"
                                    label="Cancel"
                                    className="backbtn"
                                    onClick={() => {
                                        setNewFeature(false);
                                        setNewDiscount(false);
                                    }}
                                    style={{ width: "13.2rem", height: "4.2rem" }}
                                />
                                <Button disabled type="button" label="Add Feature" className="nextbtn" style={{ width: "13.2rem", height: "4.2rem" }} />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className="flex justify-content-end " style={{ width: "102rem", marginTop: "3.2rem", gap: "1.6rem" }}>
                <Button
                    className="backbtn"
                    style={{ width: "16rem", height: "5rem" }}
                    label="Cancel"
                    type="button"
                    onClick={() => {
                        setUpdatePlanVisibility(false);
                    }}
                />
                <Button className="nextbtn" style={{ width: "16rem", height: "5rem" }} loading={isLoading} disabled={isLoading} label="Submit" type="Submit" onClick={formik.handleSubmit} />
            </div>
        </div>
    );
}
