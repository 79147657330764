import React, { useEffect, useRef, useState } from "react";
import "./css/wificalling.css";
import { useFormik } from "formik";
import { Button } from "primereact";
import * as Yup from "yup";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
export const WifiCalling = ({ cpData }) => {
    const [isWifiCallingLoading, setIsWifiCallingLoading] = useState(false);
    const toast = useRef();
    const formik = useFormik({
        initialValues: {
            address1: cpData?.address1 || "",
            address2: cpData?.address2 || "",
            city: cpData?.city || "",
            state: cpData?.state || "",
            zip: cpData?.zip || "",
            enrollmentId: cpData?.enrollmentId || "",
        },
        validationSchema: Yup.object({
            address1: Yup.string().required("Field Is Required"),

            city: Yup.string().required("Field Is Required"),
            state: Yup.string().required("Field Is Required"),
            zip: Yup.string().required("Field Is Required"),
            enrollmentId: Yup.string().required("Field Is Required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setIsWifiCallingLoading(true);
            api.post(`/api/user/addWfc`, values)
                .then((response) => {
                    toast.current.show({ severity: "success", summary: "Wifi Calling", detail: "Wifi Calling Enabled Successfully" });
                    setIsWifiCallingLoading(false);
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Wifi Calling", detail: err?.response?.data?.status?.errors?.error?.message });
                    setIsWifiCallingLoading(false);
                });
        },
    });
    useEffect(() => {
        if (cpData) {
            formik.setFieldValue("address1", cpData.address1 ?? "");
            formik.setFieldValue("address2", cpData.address2 ?? "");
            formik.setFieldValue("city", cpData.city ?? "");
            formik.setFieldValue("state", cpData.state ?? "");
            formik.setFieldValue("zip", cpData.zip ?? "");
            formik.setFieldValue("enrollmentId", cpData._id ?? "");
        }
    }, [cpData]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
            }}
            className="main-wificalling-container"
        >
            <div className="top-header-wificalling">
                <h1>Wifi Calling</h1>
                <div className="w-full main-detailmdnesn flex flex-wrap flex-row justify-content-start align-items-center">
                    <div className="calling-item flex flex-wrap flex-row justify-content-start">
                        <p>SIM/ESN:</p>
                        <p>{cpData?.esn ?? cpData?.esnId?.esn ?? "NIL"}</p>
                    </div>
                    <div className="calling-item flex flex-wrap flex-row justify-content-start">
                        <p>MDN:</p>
                        <p>{cpData?.phoneNumber ?? "NIL"}</p>
                    </div>
                </div>
            </div>
            <div className="field-container-wificalling">
                <div className="wifi-calling-inputontainer">
                    <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                        <label>Address 1</label>

                        {formik.touched.address1 && formik.errors.address1 && <label>{formik.errors.address1}</label>}
                    </div>
                    <input className={`${formik.touched.address1 && formik.errors.address1 ? "p-invalid" : ""}`} name="address1" onChange={formik.handleChange} placeholder="Address 1" value={formik.values.address1} />
                </div>
                <div className="wifi-calling-inputontainer">
                    <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                        <label>Address 2</label>

                        {formik.touched.address2 && formik.errors.address2 && <label>{formik.errors.address2}</label>}
                    </div>
                    <input className={`${formik.touched.address2 && formik.errors.address2 ? "p-invalid" : ""}`} name="address2" onChange={formik.handleChange} placeholder="Address 2" value={formik.values.address2} />
                </div>
                <div className="wifi-calling-inputontainer">
                    <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                        <label>City</label>

                        {formik.touched.city && formik.errors.city && <label>{formik.errors.city}</label>}
                    </div>
                    <input className={`${formik.touched.city && formik.errors.city ? "p-invalid" : ""}`} name="city" onChange={formik.handleChange} placeholder="City" value={formik.values.city} />
                </div>
                <div className="wifi-calling-inputontainer">
                    <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                        <label>State</label>

                        {formik.touched.state && formik.errors.state && <label>{formik.errors.state}</label>}
                    </div>
                    <input className={`${formik.touched.state && formik.errors.state ? "p-invalid" : ""}`} name="state" onChange={formik.handleChange} placeholder="State" value={formik.values.state} />
                </div>
                <div className="wifi-calling-inputontainer">
                    <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                        <label>Zipcode</label>

                        {formik.touched.zip && formik.errors.zip && <label>{formik.errors.zip}</label>}
                    </div>
                    <input className={`${formik.touched.zip && formik.errors.zip ? "p-invalid" : ""}`} name="zip" onChange={formik.handleChange} placeholder="Zipcode" value={formik.values.zip} />
                </div>
            </div>
            <div className="flex flex-wrap flex-row justify-content-end ">
                <Button
                    disabled={isWifiCallingLoading}
                    label={
                        isWifiCallingLoading ? (
                            <div className="flex flex-wrap flex-row justify-content-center w-full">
                                <CommonLoaderBlue customSize={12} />{" "}
                            </div>
                        ) : (
                            `Submit`
                        )
                    }
                    type="submit"
                />
            </div>
            <Toast ref={toast} />
        </form>
    );
};
