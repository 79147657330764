import React, { useEffect, useRef, useState } from "react";
import "./css/SetAutoPay.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../../../../../../../../../utility/AxiosInceptor";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import { detectCardType } from "./utilities/DetectCardType";
import { Button } from "primereact/button";
import CommonLoaderBlue from "../../../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import UpdateAutoPay from "./UpdateAutoPay/UpdateAutoPay";
export const SetAutoPay = ({ setRefreshCustomerData, cpData }) => {
    const toast = useRef();
    const [setAutoPayLoading, setSetAutoPayLoading] = useState(false);
    const parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const formik = useFormik({
        initialValues: {
            customerId: "",
            day: "",
            cardNumber: "",
            cardType: "",
            country: "",
            securityCode: "",
            expirationDate: "",
            nameOnCard: "",
            email: "",
            totalAmount: "",
            city: "",
            state: "",
            zipCode: cpData.zip,
            billingAddress: "",
            billingAddress2: "wewewew",
            country: "US",
            userId: parseLoginRes?._id,
            phoneNumber: "",
        },
        validationSchema: Yup.object({
            cardNumber: Yup.string()
                .matches(/^[0-9\s]*$/, "Field Is Invalid") // Only digits and spaces allowed
                .required("Field Is Required"),

            securityCode: Yup.string()
                .matches(/^\d+$/, "Only numbers are allowed") // Ensure only numbers
                .test("security-code", "Field Is Invalid", function (value) {
                    if (!value) return false; // Ensure value exists
                    const { cardType } = this.parent;

                    if (cardType === "American Express") {
                        return /^\d{4}$/.test(value); // Exactly 4 digits
                    } else {
                        return /^\d{3}$/.test(value); // Exactly 3 digits
                    }
                })
                .required("Field Is Required"),
            expirationDate: Yup.string()
                .matches(/^(0[1-9]|1[0-2])\/(2[0-9])$/, "Field Is Invalid")
                .test("not-expired", "Field Is Invalid", (value) => {
                    if (!value) return true;
                    const [month, year] = value.split("/").map((num) => parseInt(num, 10));
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth() + 1;
                    const currentYear = currentDate.getFullYear() % 100;

                    if (year < currentYear || (year === currentYear && month < currentMonth)) {
                        return false;
                    }
                    return true;
                })
                .required("Field Is Required"),
            nameOnCard: Yup.string().required("Field Is Required"),
            city: Yup.string().required("Field Is Required"),
            zipCode: Yup.string().required("Field Is Required"),
            billingAddress: Yup.string().required("Field Is Required"),
            day: Yup.string().required("Field Is Required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setSetAutoPayLoading(true);
            api.post(`/api/web/billing/StripeautopayDetails`, { ...values, expirationDate: formik.values.expirationDate.replace(/\//g, "-") })
                .then((response) => {
                    toast.current.show({ severity: "success", summary: "Info", detail: response?.data?.msg || "AutoPay SetUp Successfully" });
                    setSetAutoPayLoading(false);
                    setRefreshCustomerData((prev) => !prev);
                })
                .catch((error) => {
                    setSetAutoPayLoading(false);
                    toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg || "AutoPay SetUp Failed" });
                });
        },
    });
    useEffect(() => {
        formik.setValues({
            ...formik.values,
            city: cpData?.city,
            state: cpData?.state,
            zipCode: cpData?.zip,
            billingAddress: cpData?.address1,
            billingAddress2: cpData?.address2,
            customerId: cpData?._id,
            phoneNumber: cpData?.contact,
            email: cpData?.email,
        });
    }, []);
    const debounceTimer = useRef(null);
    const debounceTimerZipFetch = useRef(null);
    const [firstTimeZipCalled, setFirstTimeZipCalled] = useState(false);
    useEffect(() => {
        if (debounceTimerZipFetch.current) {
            clearTimeout(debounceTimerZipFetch.current);
        }
        debounceTimerZipFetch.current = setTimeout(() => {
            if (firstTimeZipCalled && formik.values.zipCode.toString().length === 5) {
                api.get(`/api/zipCode/getByZipCode?zipCode=${formik.values.zipCode}`)
                    .then((zipResponse) => {
                        formik.setFieldValue("city", zipResponse.data.data.city || "");
                        formik.setFieldValue("state", zipResponse.data.data.state || "");
                    })
                    .catch((err) => {
                        formik.setFieldValue("city", "");
                        formik.setFieldValue("state", "");
                    });
            } else {
                setFirstTimeZipCalled(true);
            }
        }, 500);
        return () => {
            if (debounceTimerZipFetch.current) {
                clearTimeout(debounceTimerZipFetch.current);
            }
        };
    }, [formik.values.zipCode]);

    useEffect(() => {
        // Clear the previous timer if it exists
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        // Set a new debounce timer
        debounceTimer.current = setTimeout(() => {
            const cardNumber = formik.values.cardNumber;
            formik.setFieldValue("cardType", detectCardType(cardNumber));
        }, 500); // 500 ms debounce delay

        // Clean up the timeout when the component is unmounted or on re-render
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        };
    }, [formik.values.cardNumber]);
    const [autoPayDetails, setAutoPayDetails] = useState(false);
    return (
        <>
            {autoPayDetails && <UpdateAutoPay setRefreshCustomerData={setRefreshCustomerData} setAutoPayDetails={setAutoPayDetails} userDetails={cpData} />}

            <form onSubmit={formik.handleSubmit} className="main-set-autopay">
                <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                    <h1 className="main-header-autopay"> Set Auto Pay</h1>
                    {cpData?.autopaySetup && (
                        <h1
                            onClick={() => {
                                setAutoPayDetails((prev) => !prev);
                            }}
                            className="main-header-autopay"
                        >
                            {" "}
                            Auto Pay Details
                        </h1>
                    )}
                </div>
                <p className="title-set-autopay">Customer Information tab displays and allows editing of personal details.</p>
                <div className="setautopay-main-inputfields">
                    <h1 className="header-card-details">Card Details</h1>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Total Amount</label>
                            {formik.touched.totalAmount && formik.errors.totalAmount ? <p className="error">{formik.errors.totalAmount}</p> : null}
                        </div>
                        <input placeholder="Total Amount" type="number" onChange={formik.handleChange} className={`${formik.touched.totalAmount && formik.errors.totalAmount ? "p-invalid" : ""}`} name="totalAmount" value={formik.values.totalAmount} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Day Of Month</label>
                            {formik.touched.day && formik.errors.day ? <p className="error">{formik.errors.day}</p> : null}
                        </div>
                        <input placeholder="Day Of Month" type="number" onChange={formik.handleChange} className={`${formik.touched.day && formik.errors.day ? "p-invalid" : ""}`} name="day" value={formik.values.day} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Number</label>
                            {formik.touched.cardNumber && formik.errors.cardNumber ? <p className="error">{formik.errors.cardNumber}</p> : null}
                        </div>
                        <input name="cardNumber" className={formik.errors.cardNumber && formik.touched.cardNumber && "p-invalid"} placeholder="Card Number" value={formik.values.cardNumber} onChange={formik.handleChange} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Type</label>
                            {formik.touched.cardType && formik.errors.cardType ? <p className="error">{formik.errors.cardType}</p> : null}
                        </div>
                        <input disabled placeholder="Card Type" onChange={formik.handleChange} className={`${formik.touched.cardType && formik.errors.cardType ? "p-invalid" : ""}`} name="cardType" value={formik.values.cardType} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Security Code</label>
                            {formik.touched.securityCode && formik.errors.securityCode ? <p className="error">{formik.errors.securityCode}</p> : null}
                        </div>
                        <input
                            placeholder="CVC"
                            type="text"
                            onChange={(e) => {
                                let numericValue = e.target.value.replace(/\D/g, "");
                                const maxLength = formik.values.cardType === "American Express" ? 4 : 3;
                                numericValue = numericValue.slice(0, maxLength); // Restrict length
                                formik.setFieldValue("securityCode", numericValue);
                            }}
                            className={`${formik.touched.securityCode && formik.errors.securityCode ? "p-invalid" : ""}`}
                            name="securityCode"
                            value={formik.values.securityCode}
                        />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Expiry Date</label>
                            {formik.touched.expirationDate && formik.errors.expirationDate ? <p className="error">{formik.errors.expirationDate}</p> : null}
                        </div>
                        <InputMask inputMode="numeric" placeholder="MM/YY" slotChar="-" name="expirationDate" mask="99/99" onChange={formik.handleChange} className={`${formik.touched.expirationDate && formik.errors.expirationDate ? "p-invalid" : ""}`} value={formik.values.expirationDate} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Name On Card</label>
                            {formik.touched.nameOnCard && formik.errors.nameOnCard ? <p className="error">{formik.errors.nameOnCard}</p> : null}
                        </div>
                        {/* <input placeholder="Name On Card" type="text" onChange={formik.handleChange} className={`${formik.touched.nameOnCard && formik.errors.nameOnCard ? "p-invalid" : ""}`} name="nameOnCard" value={formik.values.nameOnCard} /> */}
                        <input
                            placeholder="Name On Card"
                            type="text"
                            onChange={formik.handleChange}
                            onKeyPress={(e) => {
                                const charCode = e.charCode;
                                if (
                                    !(charCode >= 65 && charCode <= 90) && // A-Z
                                    !(charCode >= 97 && charCode <= 122) && // a-z
                                    charCode !== 32
                                ) {
                                    // Space
                                    e.preventDefault(); // Block the input
                                }
                            }}
                            className={`${formik.touched.nameOnCard && formik.errors.nameOnCard ? "p-invalid" : ""}`}
                            name="nameOnCard"
                            value={formik.values.nameOnCard}
                        />
                    </div>

                    <h1 className="header-address-details">Address</h1>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Country</label>
                            {formik.touched.country && formik.errors.country ? <p className="error">{formik.errors.country}</p> : null}
                        </div>
                        <input className={formik.errors.country && formik.touched.country && "p-invalid"} placeholder="Country" onChange={formik.handleChange} name="country" value={formik.values.country} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Zipcode</label>
                            {formik.touched.zipCode && formik.errors.zipCode ? <p className="error">{formik.errors.zipCode}</p> : null}
                        </div>
                        <input className={formik.errors.zipCode && formik.touched.zipCode && "p-invalid"} type="number" placeholder="Zipcode" onChange={formik.handleChange} name="zipCode" value={formik.values.zipCode} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>State</label>
                            {formik.touched.state && formik.errors.state ? <p className="error">{formik.errors.state}</p> : null}
                        </div>

                        <input className={formik.errors.state && formik.touched.state && "p-invalid"} placeholder="State" onChange={formik.handleChange} name="state" value={formik.values.state} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>City</label>
                            {formik.touched.city && formik.errors.city ? <p className="error">{formik.errors.city}</p> : null}
                        </div>
                        <input className={formik.errors.city && formik.touched.city && "p-invalid"} placeholder="City" onChange={formik.handleChange} name="city" value={formik.values.city} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Billing Address 1</label>
                            {formik.touched.billingAddress && formik.errors.billingAddress ? <p className="error">{formik.errors.billingAddress}</p> : null}
                        </div>
                        <input className={formik.errors.billingAddress && formik.touched.billingAddress && "p-invalid"} placeholder="Billing Address 1" onChange={formik.handleChange} name="billingAddress" value={formik.values.billingAddress} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Billing Address 2</label>
                            {formik.touched.billingAddress2 && formik.errors.billingAddress2 ? <p className="error">{formik.errors.billingAddress2}</p> : null}
                        </div>
                        <input placeholder="Billing Address 2" onChange={formik.handleChange} name="billingAddress2" className={formik.errors.billingAddress2 && formik.touched.billingAddress2 && "p-invalid"} value={formik.values.billingAddress2} />
                    </div>
                </div>
                <div className="setpaybtn-main-container flex flex-wrap flex-row justify-content-end align-items-center">
                    <Button
                        type="submit"
                        label={
                            setAutoPayLoading ? (
                                <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full">
                                    <CommonLoaderBlue customSize={14} color="royalblue" />{" "}
                                </div>
                            ) : (
                                "Set Up"
                            )
                        }
                        className="setpaybtn"
                    />
                </div>
                <Toast ref={toast} />
            </form>
        </>
    );
};
