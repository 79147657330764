import { api } from "../../../../../../utility/AxiosInceptor";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const UpdateCard = ({ userDetails, setUpdateCard, updateData, setUpdateDetails }) => {
    const [isLoading, setIsLoading] = useState();

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    const validationSchema = Yup.object().shape({
        cardNo1: Yup.string().required("Required"),
        cardNo2: Yup.string().required("Required"),
        cardNo3: Yup.string().required("Required"),
        cardNo4: Yup.string().required("Required"),
        securityCode: Yup.string().required("Required"),
        expDate: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        address1: Yup.string().required("Required"),
        zip: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cardNo1: "",
            cardNo2: "",
            cardNo3: "",
            cardNo4: "",
            cardType: "",
            securityCode: "",
            expDate: "",
            name: "",
            address1: "",
            address2: "",
            zip: "",
            city: "",
            state: "",
        },
        onSubmit: async (values, actions) => {
            const dataToSend = {
                cardNumber: formik.values.cardNo1 + formik.values.cardNo2 + formik.values.cardNo3 + formik.values.cardNo4,
                cardType: formik.values.cardType,
                securityCode: formik.values.securityCode,
                expirationDate: formik.values.expDate,
                nameOnCard: formik.values.name,
                billingAddress: formik.values.address1,
                billingAddress2: formik.values.address2,
                cardId: updateData[0]?.cardId,
                customerId: userDetails?._id,
            };
            if (parseLoginRes?.companyName.toUpperCase().includes("IJ") || parseLoginRes?.companyName.toUpperCase().includes("MAGNET") || parseLoginRes?.companyName.toUpperCase().includes("TALKDAILY") || parseLoginRes?.companyName.toUpperCase().includes("ZISFONE")) {
                setIsLoading(true);
                try {
                    const response = await api.post(`/api/web/billing/updateStripeCustomerPaymentProfile`, dataToSend);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success("Successfully Updated");
                        setIsLoading(false);
                        setTimeout(() => {
                            setUpdateDetails((prev) => !prev);
                            setUpdateCard(false);
                        }, 500);
                    }
                } catch (error) {
                    setIsLoading(false);
                    toast.error(error?.response?.data?.error?.message[0]?.text);
                }
            } else {
                setIsLoading(true);
                try {
                    const response = await api.put(`/api/web/invoices/updateCardDetails`, dataToSend);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success("Successfully Updated");
                        setIsLoading(false);
                        setTimeout(() => {
                            setUpdateDetails((prev) => !prev);
                            setUpdateCard(false);
                        }, 500);
                    }
                } catch (error) {
                    setIsLoading(false);
                    toast.error(error?.response?.data?.error?.message[0]?.text);
                }
            }
        },
    });

    useEffect(() => {
        if (formik.values.cardNo1[0] === "3") {
            formik.setFieldValue("cardType", "American Express");
        } else if (formik.values.cardNo1[0] === "4") {
            formik.setFieldValue("cardType", "Visa Card");
        } else if (formik.values.cardNo1[0] === "5") {
            formik.setFieldValue("cardType", "Master Card");
        } else {
            formik.setFieldValue("cardType", "");
        }
    }, [formik.values.cardNo1]);

    useEffect(() => {
        if (formik.values.zip && formik.values.zip.length === 5) {
            async function getData() {
                const response = await api.get(`/api/zipCode/getByZipCode?zipCode=${formik.values.zip}`);
                const data = response?.data?.data;
                formik.setFieldValue("city", data?.city);
                formik.setFieldValue("state", data?.abbreviation);
            }
            getData();
        }
    }, [formik.values.zip]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formatExpirationDate = (value) => {
        // Remove non-numeric characters
        value = value.replace(/\D/g, "");
        // Ensure value length does not exceed 6 characters
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
        // Insert "-" after the second character
        if (value.length > 2) {
            value = value.slice(0, 2) + "-" + value.slice(2);
        }
        return value;
    };

    const inputRefs = {
        cardNo1: useRef(null),
        cardNo2: useRef(null),
        cardNo3: useRef(null),
        cardNo4: useRef(null),
    };

    const handleInputChange = (e, fieldName, nextField) => {
        formik.handleChange(e);
        if (e.target.value.length === 4 && nextField) {
            inputRefs[nextField].current.focus();
        }
    };

    const handleInputKeyDown = (e, fieldName, prevField) => {
        if (e.key === "Backspace" && e.target.value.length === 0 && prevField) {
            inputRefs[prevField].current.focus();
        }
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-12  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>
                                        <tr className="text-lg">
                                            <td className="w-21rem ">
                                                Credit Card Number <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText
                                                    ref={inputRefs.cardNo1}
                                                    id="cardNo1"
                                                    name="cardNo1"
                                                    maxLength="4"
                                                    value={formik.values.cardNo1}
                                                    onChange={(e) => handleInputChange(e, "cardNo1", "cardNo2")}
                                                    onKeyDown={(e) => handleInputKeyDown(e, "cardNo1", null)}
                                                    placeholder="0000"
                                                    className={classNames({ "w-8rem mr-3": true, " p-invalid ": isFormFieldValid("cardNo1") })}
                                                />
                                                {getFormErrorMessage("cardNo1")}
                                                <InputText
                                                    ref={inputRefs.cardNo2}
                                                    id="cardNo2"
                                                    name="cardNo2"
                                                    maxLength="4"
                                                    value={formik.values.cardNo2}
                                                    onChange={(e) => handleInputChange(e, "cardNo2", "cardNo3")}
                                                    onKeyDown={(e) => handleInputKeyDown(e, "cardNo2", "cardNo1")}
                                                    placeholder="0000"
                                                    className={classNames({ "w-8rem mr-3": true, "p-invalid ": isFormFieldValid("cardNo2") })}
                                                />
                                                <InputText
                                                    ref={inputRefs.cardNo3}
                                                    id="cardNo3"
                                                    name="cardNo3"
                                                    maxLength="4"
                                                    value={formik.values.cardNo3}
                                                    onChange={(e) => handleInputChange(e, "cardNo3", "cardNo4")}
                                                    onKeyDown={(e) => handleInputKeyDown(e, "cardNo3", "cardNo2")}
                                                    placeholder="0000"
                                                    className={classNames({ "w-8rem mr-3": true, "p-invalid": isFormFieldValid("cardNo3") })}
                                                />
                                                {getFormErrorMessage("cardNo3")}
                                                <InputText
                                                    ref={inputRefs.cardNo4}
                                                    id="cardNo4"
                                                    name="cardNo4"
                                                    maxLength="4"
                                                    value={formik.values.cardNo4}
                                                    onChange={formik.handleChange}
                                                    onKeyDown={(e) => handleInputKeyDown(e, "cardNo4", "cardNo3")}
                                                    placeholder="0000"
                                                    className={classNames({ "w-8rem mr-3": true, "p-invalid": isFormFieldValid("cardNo4") })}
                                                />
                                                {getFormErrorMessage("cardNo4")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">Card Type</td>
                                            <td>
                                                <InputText className="w-15rem mr-3" type="text" id="cardType" value={formik.values.cardType} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Security Code <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText
                                                    className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("securityCode") }, "input_text")}
                                                    type="password"
                                                    id="securityCode"
                                                    value={formik.values.securityCode}
                                                    onChange={formik.handleChange}
                                                    keyfilter={/^\d{0,3}$/}
                                                    maxLength={3}
                                                />
                                                {getFormErrorMessage("securityCode")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                EXP Date <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText
                                                    className={classNames({ "w-15rem ": true, "p-invalid": isFormFieldValid("expDate") }, "input_text")}
                                                    type="text"
                                                    id="expDate"
                                                    maxLength={7}
                                                    placeholder="MM-YYYY"
                                                    value={formatExpirationDate(formik.values.expDate)}
                                                    onChange={(e) => {
                                                        const formattedValue = formatExpirationDate(e.target.value);
                                                        formik.setFieldValue("expDate", formattedValue);
                                                    }}
                                                />
                                                {getFormErrorMessage("expDate")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Name On Card <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("name") }, "input_text")} type="text" id="name" value={formik.values.name} onChange={formik.handleChange} />
                                                {getFormErrorMessage("name")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Zip Code <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("zip") }, "input_text")} type="text" id="zip" value={formik.values.zip} onChange={formik.handleChange} keyfilter={/^\d{0,4}$/} maxLength={5} />
                                                {getFormErrorMessage("zip")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                City <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("city") }, "input_text")} type="text" id="city" value={formik.values.city} onChange={formik.handleChange} />
                                                {getFormErrorMessage("city")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                State <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("state") }, "input_text")} type="text" id="state" value={formik.values.state} onChange={formik.handleChange} />
                                                {getFormErrorMessage("state")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Billing Address 1 <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("address1") }, "input_text")} type="text" id="address1" value={formik.values.address1} onChange={formik.handleChange} />
                                                {getFormErrorMessage("address1")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">Billing Address 2</td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("address2") }, "input_text")} type="text" id="address2" value={formik.values.address2} onChange={formik.handleChange} />
                                                {getFormErrorMessage("address2")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-end mr-5">
                    <Button label="Update" type="submit" disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
                </div>
            </form>
        </>
    );
};

export default UpdateCard;
