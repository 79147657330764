import { api } from "../../../../utility/AxiosInceptor";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const EditUser = ({ data, setShowEditUser }) => {
    console.log("data", data);
    const [allRoles, setAllRoles] = useState([]);
    const [allDepartment, setAllDepartment] = useState([]);
    const [allUser, setAllUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { rowData } = location.state || {};
    const navigate = useNavigate();
    // Get user data from ls
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    // Validation Schema
    const validationSchema = Yup.object().shape({
        role: Yup.string().required("Required"),
        name: Yup.string().required("Incorrect Name"),
        email: Yup.string().email("Incorrect Email").required("Email is Required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            role: data?.role?._id,
            user: data?._id,
            name: data?.name,
            email: data?.email,
            currentUser: parseLoginRes?._id,
            city: data?.city,
            state: data?.state,
            zip: data?.zip,
            reportingTo: data?.reportingTo,
            department: data?.department?.department,
        },
        onSubmit: async (values) => {
            setLoading(true);
            const data = {
                company: parseLoginRes?.company,
                userId: formik.values.user,
                updatedBy: formik.values.currentUser,
                roleId: formik.values.role,
                email: formik.values.email,
                name: formik.values.name,
            };

            // Send the data to the server using Axios POST request
            api.put(`/api/web/user/`, data)
                .then((response) => {
                    if (response?.status === 200) {
                        toast.success("User Updated Successfully");
                        setLoading(false);
                        setShowEditUser(false);
                        navigate("/manage-user");
                    }
                })
                .catch((error) => {
                    // Handle errors here
                    toast.error(error?.response?.data?.msg);
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleUserDataMapping = () => {
        if (rowData) {
            // Check if formik and rowData exist
            Object.keys(rowData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, rowData[key]);
                    formik.setFieldValue("role", rowData?.role?._id);
                }
            });
        }
    };

    const getRoles = async () => {
        try {
            const res = await api.get(`/api/web/role/all?serviceProvider=${parseLoginRes?.company}`);
            setAllRoles(res?.data?.data || []);
        } catch (error) {}
    };
    useEffect(() => {
        const getDepartment = async () => {
            try {
                const res = await api.get(`/api/deparments/getDepartments?company=${parseLoginRes?.company}`);
                setAllDepartment(res?.data?.data || []);
            } catch (error) {}
        };
        getDepartment();
    }, []);

    useEffect(() => {
        if (formik.values.department) {
            const departId = formik.values.department;
            const getRoles = async () => {
                try {
                    const res = await api.get(`/api/web/user/getByDepartments?department=${departId}`);

                    setAllUser(res?.data?.data || []);
                } catch (error) {}
            };
            getRoles();
        }
    }, [formik.values.department]);

    useEffect(() => {
        handleUserDataMapping();
        getRoles();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className="edituserdialog">
            <div className="edituserdialog">
                <div className="edituserform">
                    <h1>Edit Details</h1>
                    <p className="para">Enter the full name, email, and role for the new user.</p>
                    <div className="mb-3">
                        <div className="mb-3">
                            <div className="flex justify-content-between w-full">
                                <label htmlFor="name" className="labeltext mb-2">
                                    Full Name
                                </label>
                                {formik.touched.name && formik.errors.name && <span className="error">{formik.errors.name}</span>}
                            </div>
                            <InputText
                                className={`w-full mb-3 ${formik.touched.name && formik.errors.name ? "input_texterror" : "input_text"}`}
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                keyfilter={/^[A-Za-z\s]+$/}
                                placeholder="Eleanor Pena"
                            />
                        </div>
                        <div className="mb-3">
                            <div className="flex justify-content-between w-full">
                                <label htmlFor="email" className="labeltext mb-2">
                                    Email
                                </label>
                                {formik.touched.email && formik.errors.email && <span className="error">{formik.errors.email}</span>}
                            </div>
                            <InputText
                                className={`w-full mb-3 ${formik.touched.email && formik.errors.email ? "input_texterror" : "input_text"}`}
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="email"
                                keyfilter={/^[a-zA-Z0-9_.@]*$/}
                                placeholder="nathan.roberts@example.com"
                            />
                        </div>

                        <div className="mb-3">
                            <div className="flex justify-content-between w-full">
                                <label htmlFor="email" className="labeltext mb-2">
                                    Role
                                </label>
                                {formik.touched.role && formik.errors.role && <span className="error">{formik.errors.role}</span>}
                            </div>
                            <Dropdown
                                className={`w-full mb-3 ${formik.touched.role && formik.errors.role ? "input_texterror" : "input_text"}`}
                                id="role"
                                options={allRoles}
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                optionLabel="role"
                                optionValue="_id"
                                keyfilter={/^[A-Za-z\s]+$/}
                            />
                        </div>
                        {/* <div className="p-field col-12 md:col-3">
                        <label className="labeltext">
                            Department <span className="steric">*</span>
                        </label>
                        <Dropdown
                            id="department"
                            options={allDepartment}
                            value={formik.values.department}
                            onChange={(e) => formik.setFieldValue("department", e.value)}
                            optionLabel="department"
                            optionValue="_id"
                            filter
                            showClear
                            filterBy="department" // Set the property to be used for filtering
                        />
                        {getFormErrorMessage("department")}
                    </div>
                    <div className="p-field col-12 md:col-3">
                        <label className="labeltext">
                            Reporting To <span className="steric">*</span>
                        </label>
                        <Dropdown id="reportingTo" options={allUser} value={formik.values.reportingTo} onChange={(e) => formik.setFieldValue("reportingTo", e.value)} optionLabel="name" optionValue="_id" showClear filter filterBy="name" />
                        {getFormErrorMessage("reportingTo")}
                    </div>

                    <div className="p-field col-12 md:col-3">
                        <label className="labeltext">City</label>
                        <InputText id="city" value={formik.values.city} onChange={formik.handleChange} keyfilter={/^[A-Za-z\s]+$/} />
                        {getFormErrorMessage("city")}
                    </div>

                    <div className="p-field col-12 md:col-3">
                        <label className="labeltext">State</label>
                        <InputText id="state" value={formik.values.state} onChange={formik.handleChange} keyfilter={/^[A-Za-z\s]+$/} />
                        {getFormErrorMessage("state")}
                    </div> */}

                        {/* <div className="p-field col-12 md:col-3">
                        <label className="labeltext">Zip</label>
                        <InputText
                            id="zip"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            // type='number'
                        />
                        {getFormErrorMessage("zip")}
                    </div> */}
                    </div>
                </div>
                <div className="flex  justify-content-end" style={{ height: "5rem", width: "63.6rem", marginTop: "4.8rem" }}>
                    <Button className="mr-4 backbtn" type="button" label="Cancel" onClick={() => setShowEditUser(false)} />
                    <Button type="submit" className="submitbtn" label="Submit" loading={loading} />
                </div>
            </div>
        </form>
    );
};

export default EditUser;
