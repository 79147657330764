import React, { useRef, useState } from "react";
import "./css/TopBar.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { StatusIcon } from "./assets/svgs";
import { ChangeCustomerStatusCustomerProfile } from "./components/change_customer_status/change_customer_status";
import { Toast } from "primereact/toast";
import { AssignLabel } from "./AssignLabel/AssignLabel/AssignLabel";
const TopBarCustomerProfile = ({ setServiceInfoApiCalled, setSelectedId, setRefreshCustomerData, cpData, setCurrentSelectedTab, currentSelectedTab, setChangeCustomerStatus, changeCustomerStatusDialog }) => {
    const [visible, setVisible] = useState(false);
    let model = JSON.parse(localStorage.getItem("permissions"));
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const navigate = useNavigate();
    return (
        <div className="main-customerprofile-topmost-container">
            <div className="main-customer-topbarconatiner">
                <div className="main-left-topbar">
                    <div
                        className={`main-status-container align-items-center flex flex-wrap flex-row justify-start ${cpData.status === "rejected" ? "status-rejected" : cpData.status === "active" ? "status-active" : cpData.status === "inactive" ? "status-inactive" : cpData.status === "prospect" ? "status-prospected" : cpData.status === "suspended" ? "status-suspended" : cpData.status === "disconnected" ? "status-disconnected" : cpData.status === "cancelled" ? "status-cancelled" : "status-prospected"}`}
                        onClick={async () => {
                            if (cpData?.status !== "rejected") {
                                setChangeCustomerStatus(true);
                            }
                        }}
                    >
                        <StatusIcon />
                        <p
                            onClick={() => {
                                if (cpData?.status !== "rejected") {
                                    setChangeCustomerStatus(true);
                                }
                            }}
                        >
                            {cpData?.status === "prospect"
                                ? "Prospect"
                                : cpData.status === "disconnected"
                                  ? "Disconnected"
                                  : cpData.status === "active"
                                    ? "Active"
                                    : cpData.status === "inActive"
                                      ? "InActive"
                                      : cpData.status === "rejected"
                                        ? "Rejected"
                                        : cpData.status === "cancelled"
                                          ? "Cancelled"
                                          : cpData.status === "suspended"
                                            ? "Suspended"
                                            : ""}
                        </p>
                    </div>
                    <p className="customer-name">{cpData?.firstName !== "" && cpData?.firstName !== undefined && cpData?.lastName !== "" && cpData?.lastName !== undefined ? cpData?.firstName + " " + cpData?.lastName : cpData?.firstName}</p>
                    <div className="bottom-topbar-customerdetails">
                        <div className="accounts-details">
                            <p>
                                Account Id:&nbsp;&nbsp;<span>{cpData.accountId ? cpData.accountId : "Nil"}</span>
                            </p>
                            <p>
                                MDN:&nbsp;&nbsp;<span>{cpData.phoneNumber ? cpData.phoneNumber : "Nil"}</span>
                            </p>
                            {cpData?.linkedAccount && (
                                <p
                                    onClick={() => {
                                        setServiceInfoApiCalled(false);
                                        localStorage.removeItem("selectedId");
                                        localStorage.setItem("selectedId", JSON.stringify(cpData.parent_id));
                                        setSelectedId(cpData?.parent_id);

                                        setRefreshCustomerData((prev) => !prev);
                                        setCurrentSelectedTab("customer-profile");
                                    }}
                                    className="parent-account-link"
                                >
                                    Parent Account
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="main-right-topbar">
                    <Button
                        onClick={() => {
                            navigate("/add-service");
                        }}
                        disabled={cpData?.linkedAccount || !model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Add Services"))}
                        type="button"
                        className="addservicebutton"
                        label="Add Services"
                        icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                    />
                </div>
            </div>
            <div className="tabbar">
                <div className="tab-main">
                    <p
                        className={`${currentSelectedTab === "customer-profile" ? "selectedtab" : ""}`}
                        onClick={() => {
                            setCurrentSelectedTab("customer-profile");
                        }}
                    >
                        Customer Info
                    </p>
                    <p
                        className={`${currentSelectedTab === "line-info" ? "selectedtab" : ""}`}
                        onClick={() => {
                            setCurrentSelectedTab("line-info");
                        }}
                    >
                        Line Info
                    </p>
                    <p
                        className={`${currentSelectedTab === "other-info" ? "selectedtab" : ""}`}
                        onClick={() => {
                            setCurrentSelectedTab("other-info");
                        }}
                    >
                        Other Info{" "}
                    </p>
                    {model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Billing And Invoice")) && (
                        <p
                            className={`flex flex-wrap flex-row justify-center items-center ${currentSelectedTab === "payment" ? "selectedtab" : ""}`}
                            onClick={() => {
                                setCurrentSelectedTab("payment");
                            }}
                        >
                            <span>Payment</span>
                            <span>00</span>
                        </p>
                    )}
                    <p
                        className={`${currentSelectedTab === "wallet" ? "selectedtab" : ""}`}
                        onClick={() => {
                            setCurrentSelectedTab("wallet");
                        }}
                    >
                        Wallet
                    </p>
                    {model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Files")) && (
                        <p
                            className={`${currentSelectedTab === "files" ? "selectedtab" : ""}`}
                            onClick={() => {
                                setCurrentSelectedTab("files");
                            }}
                        >
                            Files
                        </p>
                    )}
                    {model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Usage")) && (
                        <p
                            className={`${currentSelectedTab === "usage" ? "selectedtab" : ""}`}
                            onClick={() => {
                                setCurrentSelectedTab("usage");
                            }}
                        >
                            Usage
                        </p>
                    )}
                    {(model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Query Network")) ||
                        model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Query Sim")) ||
                        model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Imei Validation"))) && (
                        <p
                            className={`${currentSelectedTab === "quick-links" ? "selectedtab" : ""}`}
                            onClick={() => {
                                setCurrentSelectedTab("quick-links");
                            }}
                        >
                            Quick Links
                        </p>
                    )}
                    {!cpData?.linkedAccount && model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Linked Accounts")) && (
                        <p
                            className={`flex flex-wrap flex-row justify-center items-center ${currentSelectedTab === "child-accounts" ? "selectedtab" : ""}`}
                            onClick={() => {
                                setCurrentSelectedTab("child-accounts");
                            }}
                        >
                            <span>Child Accounts</span>
                        </p>
                    )}
                </div>
                {/* {cpData?.esn !== undefined && cpData?.label === undefined && cpData?.isEnrollmentComplete && (cpData?.accountType === "Prepaid" || cpData?.accountType === "Postpaid") && cpData?.accountType !== "ACP" && !cpData?.AcptoPrepaid && (
                    <Button
                        onClick={() => {
                            setVisible(true);
                            //setAssignType("Label");
                        }}
                        type="button"
                        className="assignlabelbtn"
                        label="Assign Label"
                        icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                    />
                )} */}
                {cpData?.esn === undefined && cpData?.isEnrollmentComplete && (cpData?.accountType === "Prepaid" || cpData?.accountType === "Postpaid") && cpData?.accountType !== "ACP" && !cpData?.AcptoPrepaid && (
                    <Button
                        onClick={() => {
                            setVisible(true);
                            //setAssignType("Label");
                        }}
                        type="button"
                        className="assignlabelbtn"
                        label="Assign ESN"
                        icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                    />
                )}
            </div>

            {visible && <AssignLabel setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} setVisible={setVisible} />}
            {changeCustomerStatusDialog && (
                <div className="customer-status-main-main">
                    <ChangeCustomerStatusCustomerProfile setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} setChangeCustomerStatus={setChangeCustomerStatus} />
                </div>
            )}
            <Toast ref={toast} />
        </div>
    );
};

export default TopBarCustomerProfile;
