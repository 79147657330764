import React, { useRef, useState } from "react";
import "./css/UsageMain.css";
import { Calendar, ModuleSelector } from "./assets/assets";
import { OverlayPanel } from "primereact/overlaypanel";
import DateRangeCalendar from "./Components/Calendar/Calendar-DropDown";
import { Usagedatatable } from "./Components/UsageDataTable/UsageDataTable";
export const CustomerUsage = ({ cpData }) => {
    const [isModuleSelected, setIsModuleSelected] = useState(false);
    const [isRangeSelected, setIsRangeSelected] = useState(false);
    const [selectedRange, setSelectedRange] = useState([new Date(new Date().setDate(new Date().getDate() - 15)), new Date()]);
    const [shortSelection, setShortSelection] = useState();
    const [selectedModule, setSelectedModule] = useState();
    const [isEmpty, setIsEmpty] = useState(true);
    let calendarRef = useRef();
    let moduleRef = useRef();
    return (
        <>
            <div className="usage-main">
                <div className="usage-main-header">
                    <h1>Usage is Available for Last 90 Days</h1>
                    {!isEmpty && (
                        <div className="range-date-selector">
                            <div
                                onClick={(e) => {
                                    setIsModuleSelected(false);
                                    setIsRangeSelected(true);
                                    calendarRef.current.toggle(e);
                                }}
                                className={`calendar-wrapper ${isRangeSelected ? "calendar-usage-main-selected" : ""}`}
                            >
                                <Calendar />
                                <div className="main-overlay">
                                    <OverlayPanel ref={calendarRef}>
                                        <DateRangeCalendar setShortSelection={setShortSelection} shortSelection={shortSelection} calendar={calendarRef} setSelectedRange={setSelectedRange} selectedRange={selectedRange} />
                                    </OverlayPanel>
                                </div>
                            </div>
                            <div
                                onClick={(e) => {
                                    setIsModuleSelected(true);
                                    setIsRangeSelected(false);
                                    moduleRef.current.toggle(e);
                                }}
                                className={`module-wrapper ${isModuleSelected ? "usage-mainmodule-selected" : ""}`}
                            >
                                <ModuleSelector />
                            </div>
                            <div className="main-overlay">
                                <OverlayPanel ref={moduleRef}>
                                    <div className="main-module-container">
                                        <p
                                            onClick={() => {
                                                setSelectedModule("DATA");
                                                setTimeout(() => {
                                                    moduleRef.current.hide();
                                                }, 250);
                                            }}
                                            className={`${selectedModule === "prepaid" ? "selected-module" : ""}`}
                                        >
                                            DATA
                                        </p>
                                        <p
                                            className={`${selectedModule === "postpaid" ? "selected-module" : ""}`}
                                            onClick={() => {
                                                setSelectedModule("VOICE");
                                                setTimeout(() => {
                                                    moduleRef.current.hide();
                                                }, 250);
                                            }}
                                        >
                                            VOICE
                                        </p>
                                        <p
                                            className={`${selectedModule === "acp" ? "selected-module" : ""}`}
                                            onClick={() => {
                                                setSelectedModule("SMS");
                                                setTimeout(() => {
                                                    moduleRef.current.hide();
                                                }, 250);
                                            }}
                                        >
                                            SMS
                                        </p>
                                        <p
                                            className={`${selectedModule === "all" ? "selected-module" : ""}`}
                                            onClick={() => {
                                                setSelectedModule("ALL");
                                                setTimeout(() => {
                                                    moduleRef.current.hide();
                                                }, 250);
                                            }}
                                        >
                                            All
                                        </p>
                                    </div>
                                    {/*  <div className="flex flex-column w-full overlay">
                                                    <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                                        <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("prepaid", e.target.checked)} checked={formik.values.prepaid} />
                                                        <label>Prepaid</label>
                                                    </div>
                                                    <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                                        <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("postpaid", e.target.checked)} checked={formik.values.postpaid} />
                                                        <label>Postpaid</label>
                                                    </div>
                                                    <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                                        <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("acp", e.target.checked)} checked={formik.values.acp} />
                                                        <label>ACP</label>
                                                    </div>
                                                </div>    */}
                                </OverlayPanel>
                            </div>
                        </div>
                    )}
                </div>
                <Usagedatatable setIsEmpty={setIsEmpty} selectedRange={selectedRange} selectedModule={selectedModule} cpData={cpData} />
            </div>
        </>
    );
};
