import React, { useEffect, useRef, useState } from "react";
import "./css/UpdateCard.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../../../../../../../../../../../utility/AxiosInceptor";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import { detectCardType } from "../../utilities/DetectCardType";
import { Button } from "primereact/button";
import CommonLoaderBlue from "../../../../../../../../../../../../components/Loader/LoaderBlue";
export const UpdateCard = ({ setRefreshCardDetails, updateData, setUpdateCard, cpData }) => {
    const toast = useRef();
    const [setAutoPayLoading, setSetAutoPayLoading] = useState(false);
    const formik2 = useFormik({
        initialValues: {
            customerId: "",
            cardId: updateData[0]?.cardId,
            cardNumber: "",
            cardType: updateData[0].cardType ?? "",
            country: "",
            securityCode: updateData[0].securityCode,
            expirationDate: updateData[0].expirationDate,
            nameOnCard: updateData[0]?.nameOnCard,
            city: "",
            state: "",
            zipCode: cpData.zip,
            billingAddress: updateData[0].billingAddress ?? "",
            billingAddress2: updateData[0].billingAddress2 ?? "",
        },
        validationSchema: Yup.object({
            cardNumber: Yup.string()
                .matches(/^[0-9\s]*$/, "Field Is Invalid")
                .test("length", "Field Is Invalid", (value) => {
                    const sanitizedValue = value.replace(/\s/g, "");
                    return sanitizedValue.length === 15 || sanitizedValue.length === 16;
                })
                .required("Field Is Required"),

            securityCode: Yup.string()
                .matches(/^\d+$/, "Only numbers are allowed") // Ensure only numbers
                .test("security-code", "Field Is Invalid", function (value) {
                    if (!value) return false; // Ensure value exists
                    const { cardType } = this.parent;

                    if (cardType === "American Express") {
                        return /^\d{4}$/.test(value); // Exactly 4 digits
                    } else {
                        return /^\d{3}$/.test(value); // Exactly 3 digits
                    }
                })
                .required("Field Is Required"),
            expirationDate: Yup.string()
                .matches(/^(0[1-9]|1[0-2])\/(2[0-9])$/, "Field Is Invalid")
                .test("not-expired", "Field Is Invalid", (value) => {
                    if (!value) return true;
                    const [month, year] = value.split("/").map((num) => parseInt(num, 10));
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth() + 1;
                    const currentYear = currentDate.getFullYear() % 100;

                    if (year < currentYear || (year === currentYear && month < currentMonth)) {
                        return false;
                    }
                    return true;
                })
                .required("Field Is Required"),
            nameOnCard: Yup.string().required("Field Is Required"),
            city: Yup.string().required("Field Is Required"),
            zipCode: Yup.string().required("Field Is Required"),
            billingAddress: Yup.string().required("Field Is Required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setSetAutoPayLoading(true);
            api.post(`/api/web/billing/updateStripeCustomerPaymentProfile`, { ...values, expirationDate: formik2.values.expirationDate.replace(/\//g, "-") })
                .then((response) => {
                    toast.current.show({ severity: "success", summary: "Info", detail: response?.data?.msg || "AutoPay SetUp Successfully" });
                    setTimeout(() => {
                        setSetAutoPayLoading(false);
                        setRefreshCardDetails((prev) => !prev);
                        setUpdateCard(false);
                    }, 500);
                })
                .catch((error) => {
                    setSetAutoPayLoading(false);
                    toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg || "AutoPay SetUp Failed" });
                });
        },
    });

    const debounceTimer = useRef(null);
    const debounceTimerZipFetch = useRef(null);
    useEffect(() => {
        formik2.setValues({
            ...formik2.values,
            city: cpData?.city,
            state: cpData?.state,
            zipCode: cpData?.zip,
            customerId: cpData?._id,
            phoneNumber: cpData?.contact,
            email: cpData?.email,
        });
    }, []);
    const [firstTimeZipCalled, setFirstTimeZipCalled] = useState(false);
    useEffect(() => {
        if (debounceTimerZipFetch.current) {
            clearTimeout(debounceTimerZipFetch.current);
        }
        debounceTimerZipFetch.current = setTimeout(() => {
            if (firstTimeZipCalled && formik2.values.zipCode.toString().length === 5) {
                api.get(`/api/zipCode/getByZipCode?zipCode=${formik2.values.zipCode}`)
                    .then((zipResponse) => {
                        formik2.setFieldValue("city", zipResponse.data.data.city || "");
                        formik2.setFieldValue("state", zipResponse.data.data.state || "");
                    })
                    .catch((err) => {
                        formik2.setFieldValue("city", "");
                        formik2.setFieldValue("state", "");
                    });
            } else {
                setFirstTimeZipCalled(true);
            }
        }, 500);
        return () => {
            if (debounceTimerZipFetch.current) {
                clearTimeout(debounceTimerZipFetch.current);
            }
        };
    }, [formik2.values.zipCode]);

    useEffect(() => {
        // Clear the previous timer if it exists
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        // Set a new debounce timer
        debounceTimer.current = setTimeout(() => {
            const cardNumber = formik2.values.cardNumber;
            formik2.setFieldValue("cardType", detectCardType(cardNumber));
        }, 500); // 500 ms debounce delay

        // Clean up the timeout when the component is unmounted or on re-render
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        };
    }, [formik2.values.cardNumber]);
    const [autoPayDetails, setAutoPayDetails] = useState(false);
    return (
        <>
            <div style={{ position: "fixed", zIndex: 999999999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>

            <div id="update-autopay-updatecard" style={{ zIndex: 999999 }} className="main-update-autopay">
                <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                    <h1 style={{ fontWeight: 600, fontSize: "2.4rem", width: "100%", padding: 0, color: "#414064", marginTop: "2.4rem", margin: 0, display: "block", lineHeight: "2.9rem" }} className="main-header-autopay">
                        {" "}
                        Update Card Details
                    </h1>
                </div>
                <p className="title-set-autopay">Update your card details for seamless autopay transactions.</p>
                <div className="setautopay-main-inputfields">
                    <h1 className="header-card-details">Card Details</h1>

                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Number</label>
                            {formik2.touched.cardNumber && formik2.errors.cardNumber ? <p className="error">{formik2.errors.cardNumber}</p> : null}
                        </div>
                        <input name="cardNumber" className={formik2.errors.cardNumber && formik2.touched.cardNumber && "p-invalid"} placeholder="Card Number" value={formik2.values.cardNumber} onChange={formik2.handleChange} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Type</label>
                            {formik2.touched.cardType && formik2.errors.cardType ? <p className="error">{formik2.errors.cardType}</p> : null}
                        </div>
                        <input placeholder="Card Type" onChange={formik2.handleChange} className={`${formik2.touched.cardType && formik2.errors.cardType ? "p-invalid" : ""}`} name="cardType" value={formik2.values.cardType} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Security Code</label>
                            {formik2.touched.securityCode && formik2.errors.securityCode ? <p className="error">{formik2.errors.securityCode}</p> : null}
                        </div>
                        <input
                            placeholder="CVC"
                            type="text"
                            onChange={(e) => {
                                let numericValue = e.target.value.replace(/\D/g, "");
                                const maxLength = formik2.values.cardType === "American Express" ? 4 : 3;
                                numericValue = numericValue.slice(0, maxLength); // Restrict length
                                formik2.setFieldValue("securityCode", numericValue);
                            }}
                            className={`${formik2.touched.securityCode && formik2.errors.securityCode ? "p-invalid" : ""}`}
                            name="securityCode"
                            value={formik2.values.securityCode}
                        />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Expiry Date</label>
                            {formik2.touched.expirationDate && formik2.errors.expirationDate ? <p className="error">{formik2.errors.expirationDate}</p> : null}
                        </div>
                        <InputMask inputMode="numeric" placeholder="MM/YY" slotChar="-" name="expirationDate" mask="99/99" onChange={formik2.handleChange} className={`${formik2.touched.expirationDate && formik2.errors.expirationDate ? "p-invalid" : ""}`} value={formik2.values.expirationDate} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Name On Card</label>
                            {formik2.touched.nameOnCard && formik2.errors.nameOnCard ? <p className="error">{formik2.errors.nameOnCard}</p> : null}
                        </div>
                        {/* <input placeholder="Name On Card" type="text" onChange={formik2.handleChange} className={`${formik2.touched.nameOnCard && formik2.errors.nameOnCard ? "p-invalid" : ""}`} name="nameOnCard" value={formik2.values.nameOnCard} /> */}
                        <input
                            placeholder="Name On Card"
                            type="text"
                            onChange={formik2.handleChange}
                            onKeyPress={(e) => {
                                const charCode = e.charCode;
                                if (
                                    !(charCode >= 65 && charCode <= 90) && // A-Z
                                    !(charCode >= 97 && charCode <= 122) && // a-z
                                    charCode !== 32
                                ) {
                                    // Space
                                    e.preventDefault(); // Block the input
                                }
                            }}
                            className={`${formik2.touched.nameOnCard && formik2.errors.nameOnCard ? "p-invalid" : ""}`}
                            name="nameOnCard"
                            value={formik2.values.nameOnCard}
                        />
                    </div>

                    <h1 className="header-address-details">Address</h1>

                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Zipcode</label>
                            {formik2.touched.zipCode && formik2.errors.zipCode ? <p className="error">{formik2.errors.zipCode}</p> : null}
                        </div>
                        <input className={formik2.errors.zipCode && formik2.touched.zipCode && "p-invalid"} type="number" placeholder="Zipcode" onChange={formik2.handleChange} name="zipCode" value={formik2.values.zipCode} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>State</label>
                            {formik2.touched.state && formik2.errors.state ? <p className="error">{formik2.errors.state}</p> : null}
                        </div>

                        <input className={formik2.errors.state && formik2.touched.state && "p-invalid"} placeholder="State" onChange={formik2.handleChange} name="state" value={formik2.values.state} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>City</label>
                            {formik2.touched.city && formik2.errors.city ? <p className="error">{formik2.errors.city}</p> : null}
                        </div>
                        <input className={formik2.errors.city && formik2.touched.city && "p-invalid"} placeholder="City" onChange={formik2.handleChange} name="city" value={formik2.values.city} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Billing Address 1</label>
                            {formik2.touched.billingAddress && formik2.errors.billingAddress ? <p className="error">{formik2.errors.billingAddress}</p> : null}
                        </div>
                        <input className={formik2.errors.billingAddress && formik2.touched.billingAddress && "p-invalid"} placeholder="Billing Address 1" onChange={formik2.handleChange} name="billingAddress" value={formik2.values.billingAddress} />
                    </div>
                    <div className="input-container-setautopay">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Billing Address 2</label>
                            {formik2.touched.billingAddress2 && formik2.errors.billingAddress2 ? <p className="error">{formik2.errors.billingAddress2}</p> : null}
                        </div>
                        <input placeholder="Billing Address 2" onChange={formik2.handleChange} name="billingAddress2" className={formik2.errors.billingAddress2 && formik2.touched.billingAddress2 && "p-invalid"} value={formik2.values.billingAddress2} />
                    </div>
                </div>
                <div className="setpaybtn-main-container flex flex-wrap flex-row justify-content-end align-items-center">
                    <Button
                        type="button"
                        label="Close"
                        className="setpaybtn"
                        onClick={() => {
                            setUpdateCard((prev) => !prev);
                        }}
                    />
                    <Button
                        onClick={() => {
                            formik2.handleSubmit();
                        }}
                        label={
                            setAutoPayLoading ? (
                                <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full">
                                    <CommonLoaderBlue sizehere={14} color="royalblue" />{" "}
                                </div>
                            ) : (
                                "Update"
                            )
                        }
                        className="setpaybtn"
                    />
                </div>
                <Toast style={{ zIndex: 999999999 }} ref={toast} />
            </div>
        </>
    );
};
