import React, { useEffect, useState } from "react";
import "./css/ChildAccountMain.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { api } from "../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../components/Loader/LoaderBlue";
import NotFoundData from "../Payments/Common/Notfound/Notfound";
export const ChildAccountMain = ({ setServiceInfoApiCalled, setRefreshOnNotification, setCurrentSelectedTab, cpData, setSelectedId, setRefreshCustomerData }) => {
    const [allChildAccounts, setAllChildAccounts] = useState([]);
    const [isChildLoading, setIsChildAccountLoading] = useState(true);
    const [isAPiCalled, setIsApiCalled] = useState(false);
    const getChildAccounts = async () => {
        api.get(`/api/user/getAllchildaccounts?parentid=${cpData._id}`)
            .then((invoiceResponse) => {
                setAllChildAccounts(invoiceResponse?.data);
                setIsApiCalled(true);
                setIsChildAccountLoading(false);
            })
            .catch((err) => {
                setIsChildAccountLoading(false);
            });
    };
    useEffect(() => {
        if (cpData !== undefined) {
            getChildAccounts();
        }
    }, []);
    return (
        <div className="main-childaccount-container">
            {!isChildLoading && isAPiCalled && allChildAccounts.length === 0 ? (
                <NotFoundData currentNoFoundData={"childaccount"} />
            ) : (
                <DataTable
                    onRowClick={(e) => {
                        setServiceInfoApiCalled(false);
                        setRefreshOnNotification("");
                        setSelectedId(e.data._id);
                        localStorage.setItem("selectedId", JSON.stringify(e.data._id));
                        setRefreshCustomerData((prev) => !prev);
                        setCurrentSelectedTab("customer-profile");
                    }}
                    value={allChildAccounts}
                >
                    <Column field="enrollmentId" header="Enrollment ID" body={(rowData) => <span style={{ cursor: "pointer" }}>{rowData?.enrollmentId}</span>} />

                    <Column field="invoiceStatus" body={(rowData) => <p>{rowData?.firstName && rowData?.lastName ? `${rowData?.firstName} ${rowData?.lastName}` : rowData?.firstName ? rowData?.firstName : rowData?.lastName}</p>} header="Name" />
                    <Column header="Contact No" field="contact" />
                    <Column
                        body={(rowData) => {
                            return rowData?.phoneNumber || "NIL";
                        }}
                        header="MDN"
                    />
                    <Column field="Inventory" header="Inventory" body={(rowData) => cpData?.plan?.inventoryType || "NIL"} />
                    <Column
                        field="status"
                        header="Status"
                        body={(rowData) => (
                            <p
                                style={{ background: "transparent" }}
                                className={`${rowData.status === "rejected" ? "status-rejected" : rowData.status === "active" ? "status-active" : rowData.status === "inactive" ? "status-inactive" : rowData.status === "prospect" ? "status-prospected" : rowData.status === "suspended" ? "status-suspended" : rowData.status === "disconnected" ? "status-disconnected" : rowData.status === "cancelled" ? "status-cancelled" : "status-prospected"}`}
                            >
                                {rowData?.status === "prospect"
                                    ? "Prospect"
                                    : rowData.status === "disconnected"
                                      ? "Disconnected"
                                      : rowData.status === "active"
                                        ? "Active"
                                        : rowData.status === "inActive"
                                          ? "InActive"
                                          : rowData.status === "rejected"
                                            ? "Rejected"
                                            : rowData.status === "cancelled"
                                              ? "Cancelled"
                                              : rowData.status === "suspended"
                                                ? "Suspended"
                                                : ""}
                            </p>
                        )}
                    />
                </DataTable>
            )}
            {isChildLoading ? (
                <div className="loader-wrapper">
                    <CommonLoaderBlue color="royalblue" sizehere={21} />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};
