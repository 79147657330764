import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import "./css/UpdateAutoPay.css";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { api } from "../../../../../../../../../../../../utility/AxiosInceptor";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { UpdateCard } from "./UpdateCard/UpdateCard";
import "primeicons/primeicons.css";
import CommonLoaderBlue from "../../../../../../../../../../../components/Loader/LoaderBlue";
import { AddNewCard } from "./AddNewCard/AddNewCard";
import { AmericanExpress, DinerClub, Discover, JCB, MasterCard, UnionPy, Visa } from "./assets";
const UpdateAutoPay = ({ setRefreshCustomerData, setAutoPayDetails, setShowAutoPay, setRefersh, userDetails, autoPayStatus }) => {
    const [isLoading, setIsLoading] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [ingredient, setIngredient] = useState("");
    const [showExestingCard, setShowExestingCard] = useState(false);
    const [autoPayDetails, setautoPayDetails] = useState();
    const [cardStatus, setCardStatus] = useState();
    const [cardDetails, setCardDetails] = useState([]);
    const [showDateDialoge, setShowDateDialoge] = useState();
    const [cardId, setCardId] = useState();
    const [showDialogeForActivateCard, setShowDialogeForActivateCard] = useState(false);
    const [showDialogeForRemoveCard, setShowDialogeForRemoveCard] = useState(false);
    const [updateDetails, setUpdateDetails] = useState(false);
    const [updateCard, setUpdateCard] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const validationSchema = Yup.object().shape({
        day: Yup.string().required("Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            day: "",
        },
        onSubmit: async (values, actions) => {},
    });

    useEffect(() => {
        if (autoPayDetails) {
            formik.setValues({ day: autoPayDetails?.day || "" });
        }
    }, [autoPayDetails]);

    const options = [
        { label: "Select", value: "" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        // { label: "29", value: "29" },
        // { label: "30", value: "30" },
    ];

    useEffect(() => {
        if (userDetails.isAutopay) {
            setIngredient(true);
        } else {
            setIngredient(false);
        }
    }, [userDetails]);
    const [refreshCardDetails, setRefreshCardDetails] = useState(false);
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [showAddNewCard, setShowAddNewCard] = useState(false);
    const changeStatus = async () => {
        try {
            const response = await api.put(`/api/user/AutoPayStatus`, { customerId: userDetails?._id, isAutopay: ingredient });
            if (response?.status === 200 || response?.status === 201) {
                if (!ingredient) {
                    toast.success("Successfully Disabled");
                } else {
                    toast.success("Successfully Enabled");
                }
                setIsLoading(false);
                setRefreshCustomerData((prev) => !prev);
                setTimeout(() => {
                    setShowDialog(false);
                }, 200);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setShowDialog(false);
            setIsLoading(false);
        }
    };
    const handleYesClick = async () => {
        setIsLoading(true);
        changeStatus();
    };
    const handleNoClick = () => {
        setShowDialog(false);
        setShowDateDialoge(false);
        setShowDialogeForActivateCard(false);
        setShowDialogeForRemoveCard(false);
        setUpdateDetails((prev) => !prev);
    };

    const getCardDetails = async () => {
        const response = await api.get(`/api/web/invoices/autopayDetails?customerId=${userDetails?._id}`);

        if (response?.status === 200 || response?.status === 201) {
            setautoPayDetails(response?.data);

            setCardDetails(response?.data?.cardDetails);
        }
    };
    useEffect(() => {
        getCardDetails();
    }, [updateDetails, refreshCardDetails]);

    const handleDayUpdate = async () => {
        setIsLoading(true);
        const dataToSend = {
            customerId: userDetails?._id,
            day: formik.values.day,
        };
        try {
            const response = await api.put(`/api/web/invoices/dayUpdate`, dataToSend);
            if (response?.status === 200 || response?.status === 201) {
                toast.success("Successfully Updated");
                setShowDateDialoge(false);
                setIsLoading(false);
                setRefersh(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
        setIsLoading(false);
    };
    const handleActiveCard = async () => {
        const dataToSend = {
            customerId: userDetails?._id,
            cardId: cardId,
            activeCard: true,
        };
        if (parseLoginRes?.companyName.includes("IJ")) {
            setIsLoading(true);
            try {
                const response = await api.put(`/api/web/billing/updateCardStatus`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Successfully Activated");
                    setShowDialogeForActivateCard(false);
                    setIsLoading(false);
                    setUpdateDetails((prev) => !prev);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
            setIsLoading(false);
        } else {
            setIsLoading(true);
            try {
                const response = await api.put(`/api/web/invoices/updateCardStatus`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Successfully Activated");
                    setShowDialogeForActivateCard(false);
                    setIsLoading(false);
                    setUpdateDetails((prev) => !prev);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
            setIsLoading(false);
        }
    };

    const handleRemoveCard = async () => {
        if (cardStatus) {
            toast.error("You cannot remove the only auto pay card. If you wish to change the card, please provide a different card for auto pay. If you wish to disable this option, please disable the auto pay feature..");
            setShowDialogeForRemoveCard(false);
        } else {
            setIsLoading(true);
            try {
                const response = await api.delete(`/api/web/invoices/removeCardDetail?customerId=${userDetails?._id}&cardId=${cardId}`);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Removed Successfully");
                    setShowDialogeForRemoveCard(false);
                    setIsLoading(false);
                    setUpdateDetails((prev) => !prev);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
            setIsLoading(false);
        }
    };
    return (
        <div style={{ width: "108.4rem" }} className="flex flex-wrap flex-row justify-content-center ">
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>

            <ToastContainer />
            {updateCard && <UpdateCard setRefreshCardDetails={setRefreshCardDetails} setUpdateCard={setUpdateCard} cpData={userDetails} setUpdateDetails={setUpdateDetails} updateData={updateData} />}
            {showAddNewCard && <AddNewCard setRefreshCardDetails={setRefreshCardDetails} setUpdateCard={setShowAddNewCard} cpData={userDetails} setUpdateDetails={setUpdateDetails} updateData={updateData} />}

            <form className="main-auto-pay" onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div
                    onClick={() => {
                        setAutoPayDetails((prev) => !prev);
                    }}
                    className="goback-addNote"
                >
                    <i className="pi pi-angle-left" />
                </div>
                <div className="main-tag-container">
                    <label className="top-header">Update AutoPay</label>
                    <label className="top-header-title">Adjust payment method, cycle, or status.</label>
                </div>
                <table className="w-full">
                    <tbody>
                        <tr className="flex flex-wrap flex-row  justify-content-between align-items-center">
                            <td>Current Auto Pay Status</td>
                            <td>
                                <RadioButton
                                    inputId="enabled"
                                    name="pizza"
                                    value={true}
                                    onChange={(e) => {
                                        setIngredient(e.value);
                                        setShowDialog(true);
                                        setTimeout(() => {
                                            var element2 = document.getElementById("update-auto-paystatus");
                                            // Scroll the element into view
                                            element2.scrollIntoView({
                                                behavior: "smooth", // Smooth scroll
                                                block: "center", // Scroll to the top of the element
                                            });
                                        }, 200);
                                    }}
                                    checked={ingredient}
                                />
                                <label style={{ color: "#414064" }} htmlFor="enabled" className="ml-2">
                                    Enabled
                                </label>
                                <RadioButton
                                    className="ml-5"
                                    inputId="disabled"
                                    name="pizza"
                                    value={false}
                                    onChange={(e) => {
                                        setIngredient(e.value);
                                        setShowDialog(true);
                                        setTimeout(() => {
                                            var element2 = document.getElementById("update-auto-paystatus");
                                            // Scroll the element into view
                                            element2.scrollIntoView({
                                                behavior: "smooth", // Smooth scroll
                                                block: "center", // Scroll to the top of the element
                                            });
                                        }, 200);
                                    }}
                                    checked={!ingredient}
                                />
                                <label style={{ color: "#414064" }} htmlFor="disabled" className="ml-2">
                                    Disabled
                                </label>
                            </td>
                        </tr>
                        <tr className="flex flex-wrap flex-row  justify-content-between align-items-center">
                            <td>Amount</td>
                            {/* <td>${invoiceData[0]?.dueAmount}</td> */}
                            {autoPayDetails?.totalAmount && autoPayDetails?.length > 0 ? <td style={{ color: "#414064", fontWeight: 600 }}>${autoPayDetails?.totalAmount}</td> : "NIL"}
                        </tr>
                        <tr className="flex flex-wrap flex-row  justify-content-between align-items-center">
                            <td>
                                Day Of Month <span style={{ color: "red" }}>*</span>
                            </td>
                            <td>
                                <Dropdown
                                    options={options}
                                    value={formik.values.day}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setShowDateDialoge(true);
                                        setTimeout(() => {
                                            var element2 = document.getElementById("update-autopay-day");
                                            // Scroll the element into view
                                            element2.scrollIntoView({
                                                behavior: "smooth", // Smooth scroll
                                                block: "center", // Scroll to the top of the element
                                            });
                                        }, 200);
                                    }}
                                    onBlur={formik.handleBlur}
                                    id="day"
                                    className={classNames({ "p-invalid": isFormFieldValid("day") })}
                                />

                                {getFormErrorMessage("day")}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="link-to">
                    <p onClick={() => setShowExestingCard((prev) => !prev)}>Auto Pay Cards </p>
                    <p onClick={() => setShowAddNewCard((prev) => !prev)}>Add New Card</p>
                </div>
                {showExestingCard && (
                    <>
                        {cardDetails.map((item, index) => (
                            <div style={{ gap: "1.6rem" }} className="mt-4 w-full flex flex-wrap flex-row justify-content-start" key={item?._id}>
                                <div className="mt-4">
                                    <RadioButton
                                        inputId={`cardEnabled_${item?._id}`} // Ensure unique inputId
                                        name="cardStatus"
                                        value="true"
                                        onChange={() => {
                                            setCardId(item?._id);
                                            setShowDialogeForActivateCard(true);
                                        }}
                                        checked={item?.activeCard === true}
                                    />
                                </div>
                                <div className="credit-card ">
                                    <div className="credit-card__details">
                                        <div className="credit-card__pics">
                                            <div className="flex">
                                                {item.cardType === "Visa" ? (
                                                    <Visa />
                                                ) : item.cardType === "MasterCard" ? (
                                                    <MasterCard />
                                                ) : item.cardType === "American Express" ? (
                                                    <AmericanExpress />
                                                ) : item.cardType === "Discover" ? (
                                                    <Discover />
                                                ) : item.cardType === "UnionPay" ? (
                                                    <UnionPy />
                                                ) : item.cardType === "JCB" ? (
                                                    <JCB />
                                                ) : item.cardType === "Diners Club" ? (
                                                    <DinerClub />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <img
                                                onError={(e) => {
                                                    e.target.src = "./placeholderimage.jpg";
                                                }}
                                                src={`${process.env.PUBLIC_URL}/chip.png`}
                                                alt="chip"
                                                width={48}
                                                className="mb-3 mr-2"
                                            />
                                        </div>
                                        <div className="card-num-name">
                                            <div className="credit-card__number">**** **** **** {item?.cardNumber}</div>
                                            <div className="credit-card__expiry">Expires: {item?.expirationDate}</div>
                                        </div>
                                        <div className="credit-card__holder">
                                            {item?.nameOnCard}
                                            <span className="update-btn link-to">
                                                <p
                                                    className="ml-5 mr-2 card-button"
                                                    onClick={() => {
                                                        setUpdateData([
                                                            {
                                                                nameOnCard: item?.nameOnCard,
                                                                cardId: item?._id,
                                                                cardType: item?.cardType,
                                                                cardNumber: item?.cardNumber,
                                                                expirationDate: item?.expirationDate,
                                                                billingAddress: item?.billingAddress,
                                                                billingAddress2: item?.billingAddress2,
                                                                securityCode: item?.securityCode,
                                                            },
                                                        ]);
                                                        setUpdateCard((prev) => !prev);
                                                        setTimeout(() => {
                                                            var element2 = document.getElementById("update-autopay-updatecard");
                                                            // Scroll the element into view
                                                            element2.scrollIntoView({
                                                                behavior: "smooth", // Smooth scroll
                                                                block: "center", // Scroll to the top of the element
                                                            });
                                                        }, 200);
                                                    }}
                                                >
                                                    Update
                                                </p>

                                                <p
                                                    className="ml-2 mr-2 card-button"
                                                    onClick={() => {
                                                        setCardId(item?._id);
                                                        setCardStatus(item?.activeCard);
                                                        setShowDialogeForRemoveCard(true);
                                                        setTimeout(() => {
                                                            var element2 = document.getElementById("update-autopay-remove");
                                                            // Scroll the element into view
                                                            element2.scrollIntoView({
                                                                behavior: "smooth", // Smooth scroll
                                                                block: "center", // Scroll to the top of the element
                                                            });
                                                        }, 200);
                                                    }}
                                                >
                                                    Remove
                                                </p>
                                            </span>
                                        </div>
                                        <div className="edit-address">Billing Address:</div>
                                        <div className="mt-3">{item?.billingAddress}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </form>
            {showDialog && (
                <>
                    <div style={{ position: "fixed", zIndex: 999999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>

                    <div id="update-auto-paystatus" style={{ zIndex: 9999999 }} className="invoice-download-main flex flex-wrap flex-row justify-content-center">
                        <div style={{ marginTop: "-60px" }} className="main-download-invoice">
                            <div className="top-header-downloadinvoice">
                                <h1>Confirm Update AutoPay Status</h1>
                                <p>Are you sure you want to {autoPayStatus ? "Enabled" : "Disable"} AutoPay.</p>
                            </div>
                            <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleNoClick();
                                    }}
                                    label="Cancel"
                                />
                                <Button
                                    onClick={() => {
                                        handleYesClick();
                                    }}
                                    label={
                                        isLoading ? (
                                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                                <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                            </div>
                                        ) : (
                                            "Update"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {showDateDialoge && (
                <>
                    {" "}
                    <div style={{ position: "fixed", zIndex: 999999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
                    <div id="update-autopay-day" style={{ zIndex: 9999999 }} className="invoice-download-main flex flex-wrap flex-row justify-content-center">
                        <div style={{ marginTop: "-60px" }} className="main-download-invoice">
                            <div className="top-header-downloadinvoice">
                                <h1>Confirm Update AutoPay Day</h1>
                                <p>Are you sure you want to AutoPay Day to {formik.values.day}.</p>
                            </div>
                            <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleNoClick();
                                    }}
                                    label="Cancel"
                                />
                                <Button
                                    onClick={() => {
                                        handleDayUpdate();
                                    }}
                                    label={
                                        isLoading ? (
                                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                                <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                            </div>
                                        ) : (
                                            "Update"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {showDialogeForActivateCard && (
                <>
                    {" "}
                    <div style={{ position: "fixed", zIndex: 999999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
                    <div id="update-autopay-card" style={{ zIndex: 9999999 }} className="invoice-download-main flex flex-wrap flex-row justify-content-center">
                        <div style={{ marginTop: "-60px" }} className="main-download-invoice">
                            <div className="top-header-downloadinvoice">
                                <h1>Confirm AutoPay Card</h1>
                                <p> Are you sure you want to switch the credit card for Auto Pay.</p>
                            </div>
                            <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleNoClick();
                                    }}
                                    label="Cancel"
                                />
                                <Button
                                    onClick={() => {
                                        handleActiveCard();
                                    }}
                                    label={
                                        isLoading ? (
                                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                                <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                            </div>
                                        ) : (
                                            "Submit"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showDialogeForRemoveCard && (
                <>
                    {" "}
                    <div style={{ position: "fixed", zIndex: 999999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
                    <div id="update-autopay-remove" style={{ zIndex: 9999999 }} className="invoice-download-main flex flex-wrap flex-row justify-content-center">
                        <div style={{ marginTop: "-60px" }} className="main-download-invoice">
                            <div className="top-header-downloadinvoice">
                                <h1>Confirm Card Removal</h1>
                                <p>Are you sure you want to remove this Card.</p>
                            </div>
                            <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleNoClick();
                                    }}
                                    label="Cancel"
                                />
                                <Button
                                    onClick={() => {
                                        handleRemoveCard();
                                    }}
                                    label={
                                        isLoading ? (
                                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                                <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                            </div>
                                        ) : (
                                            "Submit"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default UpdateAutoPay;
