import { api } from "../../../../utility/AxiosInceptor";
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import _ from "lodash";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import EditUser from "./EditUser";
import "./user_management.css";
import { Checkbox } from "primereact/checkbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { Status, NextIcon, PrevIcon, Editbutton, CancelPopup, PlanEditIcon } from "../../../../utility";
import CommonLoaderBlue from "../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
import CreateUser from "./CreateUser";
import CreateRole from "../roles_and_permissions/CreateRole";
import Roles from "./Roles";
import EmptyUserScreen from "./EmptyUserScreen";

const ManageUser = () => {
    const [showEditUser, setShowEditUser] = useState(false);
    const rolesall = useRef(null);
    let toastfordelete = useRef(null);
    const [allUsers, setAllUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [visibleDeleteUser, setVisibleDelelteUser] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showAddUser, setShowAddUser] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allRolesData, setAllRolesData] = useState([]);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [userScreen, setUserScreen] = useState(false);
    const [showRoles, setShowRoles] = useState(false);
    const [showUser, setShowUser] = useState(true);
    const [rolesData, setRolesData] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [page, setPage] = useState(1);
    const [allUsersData, setAllUsersData] = useState();
    const [load, setLoad] = useState(true);
    const [nextbtnclick, setnextbtnclick] = useState(false);
    const [prevbtnclick, setprevbtnclick] = useState(false);
    const [roles, setRoles] = useState([]);
    const [singleUser, setSingleUser] = useState(true);
    const rolesString = roles.join(",");
    const op = useRef(null);
    const roleref = useRef(null);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const role = parseLoginRes?.role?.role;
    const updateStatustrue = async () => {
        try {
            const response = await api.patch(`/api/web/role/updateStatus`, {
                status: true,
                roleId: rowData?._id,
            });
            setRefresh((prev) => !prev);
        } catch (error) {
            console.error("Error updating status", error);
        }
    };
    const updateStatusfalse = async () => {
        try {
            const response = await api.patch(`/api/web/role/updateStatus`, {
                status: false,
                roleId: rowData?._id,
            });
            setRefresh((prev) => !prev);
            console.log("Status updated successfully", response.data);
        } catch (error) {
            console.error("Error updating status", error);
        }
    };
    const actionsrole = (rowData) => {
        return (
            <>
                <div className="flex align-items-center justify-content-end">
                    <div
                        onClick={(e) => {
                            roleref.current.toggle(e);
                            setRowData(rowData);
                        }}
                    >
                        <span className="actionbtnmain">
                            <Editbutton />
                        </span>
                    </div>
                    <div className="roleoverlay">
                        <OverlayPanel ref={roleref} style={{ width: "11rem", height: "12.2rem" }}>
                            <div className="flex flex-column w-full overlay">
                                <div
                                    className="flex  justify-content-start align-items-center overlaycontent"
                                    style={{ height: "36px", paddingLeft: "1.45rem", width: "10.8rem", marginTop: ".8rem" }}
                                    onClick={() => {
                                        updateStatusfalse();
                                        roleref.current.hide();
                                    }}
                                >
                                    <div className="inner">
                                        <label>Disable</label>
                                    </div>
                                </div>
                                <div
                                    className="flex  justify-content-start align-items-center overlaycontent"
                                    style={{ height: "36px", paddingLeft: "1.45rem", width: "10.8rem" }}
                                    onClick={() => {
                                        updateStatustrue();
                                        roleref.current.hide();
                                    }}
                                >
                                    <div className="inner">
                                        <label>Active</label>
                                    </div>
                                </div>
                                <div
                                    className="flex  justify-content-start align-items-center overlaycontent"
                                    style={{ height: "36px", paddingLeft: "1.45rem", width: "10.8rem" }}
                                    onClick={() => {
                                        setUserScreen(true);
                                        roleref.current.hide();
                                    }}
                                >
                                    <div className="inner">
                                        <label>Permission</label>
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                    {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleRoleDelete(rowData)} /> */}
                </div>
            </>
        );
    };
    const actions = (rowData) => {
        return (
            <>
                <div className="flex align-items-center justify-content-end">
                    <div
                        onClick={(e) => {
                            op.current.toggle(e);
                            setRowData(rowData);
                        }}
                    >
                        <span className="actionbtnmain">
                            <PlanEditIcon />
                        </span>
                    </div>
                    {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleUserDelete(rowData)} /> */}
                </div>
            </>
        );
    };
    const handleUserEdit = (rowData) => {
        setRowData(rowData);
        setShowEditUser((prev) => !prev);
    };

    const getAllUsers = useCallback(
        _.debounce(async () => {
            try {
                setLoad(true);
                const res = await api.get(`/api/web/user/all?company=${parseLoginRes?.company}&page=${page}&search=${searchText}&roles=${rolesString}&limit=18`);
                setAllUsers(res?.data?.data.users || []);
                setLoad(false);
                setAllUsersData(res?.data?.data);
                setAllRolesData(res?.data?.data.users);
                setCompleteListEnrollment(res?.data?.data.users);
            } catch (error) {
                setLoad(false);
                toast.error(`Error fetching module data: ${error?.response?.data?.msg}`);
            }
        }, 500), // 500ms debounce time
        [parseLoginRes?.company, page, searchText, rolesString],
    );

    useEffect(() => {
        getAllUsers();
        return () => getAllUsers.cancel(); // Cancel debounce on unmount
    }, [refresh, page, searchText, roles, showEditUser]);

    function confirmDeleteUser() {
        api.delete(`/api/web/user?userId=${userId}`)
            .then(() => {
                toastfordelete.current.show({ severity: "success", summary: "Info", detail: "Deleted User Successfully" });
                getAllUsers();
            })
            .catch((err) => {
                toastfordelete.current.show({ severity: "error", summary: "Info", detail: "Deleted User Failed" });
            });

        setVisibleDelelteUser(false);
    }
    function skipDeleteUser() {
        setVisibleDelelteUser(false);
    }
    useEffect(() => {
        api.get(`/api/web/role/all?serviceProvider=${userData.company}`)
            .then((res) => {
                const data = res?.data?.data;
                setRolesData(data);
            })
            .catch((error) => {
                console.error("Error fetching roles:", error);
            });
    }, [refresh]);

    // const allUsers = allUsers.filter((user) => {
    //     return user?.name.toLowerCase().includes(searchText.toLowerCase()) || user?.role?.role.toLowerCase().includes(searchText.toLowerCase());
    // });
    const filteredRoles = useMemo(() => {
        if (!searchText.trim()) return rolesData;

        return rolesData.filter((role) => [role.role, role.userCount, role.createdAt].some((field) => field?.toString().toLowerCase().includes(searchText.toLowerCase())));
    }, [searchText, rolesData]);
    const handleCheckboxChange = (role) => {
        const selectedRoles = roles.includes(role)
            ? roles.filter((r) => r !== role) // Remove role if already selected
            : [...roles, role]; // Add role if not selected

        setRoles(selectedRoles);
    };

    const handleAllChange = (e) => {
        if (e.checked) {
            // Select all roles
            setRoles(rolesData.map((item) => item.role));
        } else {
            // Clear all roles
            setRoles([]);
        }
    };
    const handleActive = () => {
        const userIds = singleUser ? [rowData?._id] : selectedRoles.map((user) => user._id); // Extract only _id

        const dataToSend = {
            userIds,
            active: true,
        };

        api.patch(`/api/web/user/changeActiveStatus`, dataToSend)
            .then((res) => {
                toast.success(res?.data?.msg);
                setSelectedRoles([]);
                getAllUsers();
            })
            .catch((err) => {
                console.error(err?.res?.data?.msg);
            });
    };
    const handleDeactive = () => {
        const userIds = singleUser ? [rowData?._id] : selectedRoles.map((user) => user._id); // Extract only _id

        const dataToSend = {
            userIds,
            active: false,
        };

        api.patch(`/api/web/user/changeActiveStatus`, dataToSend)
            .then((res) => {
                toast.success(res?.data?.msg);
                setSelectedRoles([]);
                getAllUsers();
            })
            .catch((err) => {
                console.error(err?.res?.data?.msg);
            });
    };
    console.log("rowdata", rowData);
    return (
        <>
            {/* : filteredRoles?.length < 1 && showRoles ? (
                <Roles setUserScreen={setUserScreen} />
            ) */}
            {userScreen ? (
                <CreateRole setRefreshManageUser={setRefresh} setUserScreen={setUserScreen} isEdit={isEdit} roledata={rowData} />
            ) : (
                <div className="manageusermain">
                    {selectedRoles?.length > 0 && (
                        <div className="flex flex-column justify-content-start align-items-center selecteddatapopup">
                            <div className="flex w-full justify-content-end">
                                <span onClick={() => setSelectedRoles([])} style={{ cursor: "pointer" }}>
                                    <CancelPopup />
                                </span>
                            </div>
                            <div className="flex justify-content-evenly w-full align-items-center selectedrowdata">
                                <div className="flex showselectedroles" style={{ width: "12rem" }}>
                                    <p>
                                        Selected: <span>{selectedRoles?.length}</span>
                                    </p>
                                </div>
                                <div className="flex justify-content-between" style={{ width: "26.2rem" }}>
                                    <Button onClick={handleDeactive} on className="addnewrolebtn" label="Deactive" style={{ width: "12rem" }} />
                                    <Button onClick={handleActive} className="adduserbtn" label="Active" style={{ width: "12rem" }} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="usermanagementheader">
                        <h3>User Management</h3>
                        <p>Efficiently manage users and permissions with tools to add, edit, or deactivate roles for secure access.</p>
                    </div>
                    <div className="flex justify-content-between align-items-center" style={{ marginTop: "2.4rem" }}>
                        <div className="flex justify-content-between align-items-center" style={{ width: "15.5rem", height: "4.2rem" }}>
                            <div
                                className={showUser ? "activetab" : "notactivetab"}
                                onClick={() => {
                                    setShowUser(true);
                                    setShowRoles(false);
                                }}
                            >
                                <p>All Users</p>
                            </div>
                            <div
                                className={showRoles ? "activetab" : "notactivetab"}
                                onClick={() => {
                                    setShowRoles(true);
                                    setShowUser(false);
                                }}
                            >
                                <p>Roles</p>
                            </div>
                        </div>
                        <div className="flex align-items-center justify-content-between" style={{ width: showUser ? "66.8rem" : "61.2rem" }}>
                            <div className="flex align-items-center" style={{ width: "30rem", height: "4rem" }}>
                                <InputText className="searchfield w-full h-full" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Name, Email, Role" />
                            </div>
                            {showUser && (
                                <div className="overlaymainheader">
                                    <button className="selector" onClick={(e) => rolesall.current.toggle(e)}>
                                        <Status />
                                    </button>
                                    <OverlayPanel ref={rolesall} style={{ width: "auto", height: "auto", marginLeft: "-7rem" }}>
                                        <div className="flex flex-column w-full overlay" style={{ paddingTop: ".8rem", paddingBottom: ".8rem" }}>
                                            <div className="flex justify-content-start align-items-center overlaycontent" style={{ height: "36px", width: "auto" }}>
                                                <div className="flex align-items-center overlayinnerdata">
                                                    <Checkbox name="all" onChange={handleAllChange} checked={roles?.length === rolesData?.length} />
                                                    <label style={{ marginLeft: "1.05rem", marginRight: "1.2rem" }}>All</label>
                                                </div>
                                            </div>
                                            {rolesData?.map((item) => (
                                                <div className="flex justify-content-start align-items-center overlaycontent" style={{ height: "36px", width: "auto" }} key={item.role} onClick={() => handleCheckboxChange(item.role)}>
                                                    <div className="flex align-items-center overlayinnerdata">
                                                        <Checkbox name={item.role} checked={roles.includes(item.role)} />
                                                        <label style={{ marginLeft: "1.05rem", marginRight: "1.2rem" }}>{item.role}</label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </OverlayPanel>
                                </div>
                            )}
                            <div style={{ width: "140px" }}>
                                <Button
                                    className="addnewrolebtn w-full"
                                    label="Add New Role"
                                    onClick={() => {
                                        setUserScreen(true);
                                        setIsEdit(false);
                                    }}
                                    style={{ marginLeft: "1.2rem" }}
                                />
                            </div>

                            <div style={{ width: "140px" }}>
                                <Button className="adduserbtn  w-full" label="Add User" onClick={() => setShowAddUser(true)} style={{ marginLeft: "1.2rem" }} />
                            </div>
                        </div>
                    </div>

                    <div className="alluserstable">
                        {showRoles && filteredRoles?.length > 0 ? (
                            <div className="roletable">
                                <DataTable
                                    value={filteredRoles}
                                    onRowClick={null}
                                    emptyMessage={
                                        // <EmptyUserScreen setShowAddUser={setShowAddUser} setRefresh={setRefresh} />

                                        load ? (
                                            <div className="flex justify-content-center" style={{ width: "108.4rem", padding: "5rem" }}>
                                                <CommonLoaderBlue customSize={12} />
                                            </div>
                                        ) : rolesData?.length < 1 ? (
                                            <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">No Result Found</div>
                                        ) : (
                                            ""
                                        )
                                    }
                                >
                                    <Column field="role" header="Role"></Column>
                                    <Column field="userCount" header="NO OF USERS"></Column>
                                    <Column field="createdAt" header="DATE" body={(rowData) => new Date(rowData.createdAt).toLocaleDateString()}></Column>
                                    <Column body={(rowData) => <span style={{ color: rowData?.active ? "#27AE60" : "#B3261E" }}>{rowData?.active ? "Active" : "Disable"}</span>} header="STATUS" />
                                    <Column body={actionsrole} header="ACTION"></Column>
                                </DataTable>
                            </div>
                        ) : (
                            <div className="usertable">
                                <DataTable
                                    value={allUsers}
                                    selectionMode="checkbox"
                                    selection={selectedRoles}
                                    onSelectionChange={
                                        role !== "Admin"
                                            ? null
                                            : (e) => {
                                                  setSelectedRoles(e.value);
                                                  setSingleUser(false);
                                              }
                                    }
                                    onRowClick={null}
                                    emptyMessage={
                                        load ? (
                                            <div className="flex justify-content-center" style={{ width: "108.4rem", padding: "5rem" }}>
                                                <CommonLoaderBlue customSize={12} />
                                            </div>
                                        ) : allUsers?.length < 1 ? (
                                            <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">No Result Found</div>
                                        ) : load ? (
                                            <CommonLoaderBlue customSize={12} />
                                        ) : (
                                            ""
                                        )
                                    }
                                >
                                    <Column selectionMode="multiple" />
                                    <Column field="name" header="NAME"></Column>
                                    <Column field="email" header="EMAIL"></Column>
                                    <Column field="role.role" header="ROLE"></Column>
                                    <Column field="createdDate" header="DATE" body={(rowData) => new Date(rowData.createdDate).toLocaleDateString()}></Column>
                                    <Column field="active" header="STATUS" body={(item) => <span className={item?.active ? "statusactive" : "statusinactive"}>{item?.active ? "Active" : "Inactive  "}</span>} />
                                    <Column body={actions} header="ACTION"></Column>
                                </DataTable>
                                <OverlayPanel ref={op} style={{ width: "10.9em", height: "11.2rem" }} className="flex align-items-center inventorylist">
                                    <div className="flex flex-column w-full overlay">
                                        <div
                                            className={rowData?.active ? "flex justify-content-start align-items-center prospectoverlay" : "flex justify-content-start align-items-center prospectoverlayactive"}
                                            style={{ height: "32px" }}
                                            onClick={() => {
                                                if (rowData?.active) {
                                                    setSingleUser(true);
                                                    handleDeactive();
                                                    op.current.hide();
                                                } else {
                                                }
                                            }}
                                        >
                                            <label style={{ fontSize: "1.2rem", fontWeight: "500", color: !rowData?.active ? "#2627CF" : "" }}>Deactive</label>
                                        </div>
                                        <div
                                            className={!rowData?.active ? "flex justify-content-start align-items-center prospectoverlay" : "flex justify-content-start align-items-center prospectoverlayactive"}
                                            style={{ height: "32px" }}
                                            onClick={() => {
                                                if (!rowData?.active) {
                                                    setSingleUser(true);
                                                    handleActive();
                                                    op.current.hide();
                                                } else {
                                                }
                                            }}
                                        >
                                            <label style={{ fontSize: "1.2rem", fontWeight: "500", color: rowData?.active ? "#2627CF" : "" }}>Active</label>
                                        </div>
                                        <div
                                            className="flex justify-content-start align-items-center prospectoverlay"
                                            style={{ height: "32px" }}
                                            onClick={() => {
                                                handleUserEdit(rowData);
                                                setShowEditUser(true);
                                                if (op.current) {
                                                    op.current.hide();
                                                }
                                            }}
                                        >
                                            <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Edit</label>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                        )}
                        {showUser && allUsersData?.totalUsers >= 18 && (
                            <div className="flex w-full justify-content-between align-items-center mt-5">
                                <div className="totalcount">
                                    <h4>
                                        {allUsersData?.cumulativeCount} / {allUsersData?.totalUsers}
                                    </h4>
                                </div>

                                <div className="flex pages" style={{ width: allUsersData?.totalPages > 6 ? "47.8rem" : "auto" }}>
                                    <button
                                        onClick={() => {
                                            if (page > 1 && !load) {
                                                setLoad(true);
                                                setprevbtnclick(true);
                                                setnextbtnclick(false);
                                                setPage(page - 1);
                                            } else {
                                            }
                                        }}
                                        className="cursor-pointer backbutton"
                                        style={{ backgroundColor: "#FFFFFF33", width: "10.6rem", height: "3.8rem", margin: "0rem" }}
                                    >
                                        {prevbtnclick && load ? (
                                            <CommonLoaderBlue customSize={10} />
                                        ) : (
                                            <span className="flex align-items-center">
                                                <PrevIcon /> &nbsp; Previous
                                            </span>
                                        )}
                                    </button>

                                    {allUsersData?.totalPages > 6 ? (
                                        <div>
                                            <div>
                                                <Button
                                                    className={allUsersData?.currentPage === Math.max(1, Math.min(allUsersData?.currentPage - 1, allUsersData?.totalPages - 4)) ? "pagecounteractive" : "pagecounter"}
                                                    label={Math.max(1, Math.min(allUsersData?.currentPage - 1, allUsersData?.totalPages - 4)).toString()}
                                                    onClick={() => {
                                                        setPage(Math.max(1, Math.min(allUsersData?.currentPage - 1, allUsersData?.totalPages - 4)));
                                                    }}
                                                />
                                                <Button
                                                    className={allUsersData?.currentPage === Math.max(2, Math.min(allUsersData?.currentPage, allUsersData?.totalPages - 3)) ? "pagecounteractive" : "pagecounter"}
                                                    label={Math.max(2, Math.min(allUsersData?.currentPage, allUsersData?.totalPages - 3)).toString()}
                                                    onClick={() => {
                                                        setPage(Math.max(2, Math.min(allUsersData?.currentPage, allUsersData?.totalPages - 3)));
                                                    }}
                                                />
                                                <Button
                                                    className={allUsersData?.currentPage === Math.max(3, Math.min(allUsersData?.currentPage + 1, allUsersData?.totalPages - 2)) ? "pagecounteractive" : "pagecounter"}
                                                    label={Math.max(3, Math.min(allUsersData?.currentPage + 1, allUsersData?.totalPages - 2)).toString()}
                                                    onClick={() => {
                                                        setPage(Math.max(3, Math.min(allUsersData?.currentPage + 1, allUsersData?.totalPages - 2)));
                                                    }}
                                                />
                                                {allUsersData?.currentPage + 2 < allUsersData?.totalPages - 1 && <Button className="continuee" label="..." />}
                                                <Button
                                                    className={allUsersData?.currentPage === allUsersData?.totalPages - 1 ? "pagecounteractive" : "pagecounter"}
                                                    label={(allUsersData?.totalPages - 1).toString()}
                                                    onClick={() => {
                                                        setPage(allUsersData?.totalPages - 1);
                                                    }}
                                                />
                                                <Button
                                                    className={allUsersData?.currentPage === allUsersData?.totalPages ? "pagecounteractive" : "pagecounter"}
                                                    label={allUsersData?.totalPages.toString()}
                                                    onClick={() => {
                                                        setPage(allUsersData?.totalPages);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {Array.from({ length: allUsersData?.totalPages }, (_, index) => {
                                                const pageNumber = index + 1;
                                                return (
                                                    <Button
                                                        key={pageNumber}
                                                        onClick={() => {
                                                            setPage(pageNumber);
                                                        }}
                                                        className={allUsersData?.currentPage === pageNumber ? "pagecounteractive" : "pagecounter"}
                                                        label={pageNumber.toString()}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                    <button
                                        onClick={() => {
                                            if (allUsersData?.cumulativeCount === allUsersData?.totalUsers || load) {
                                            } else {
                                                setLoad(true);
                                                setnextbtnclick(true);
                                                setprevbtnclick(false);
                                                setPage(page + 1);
                                            }
                                        }}
                                        className="cursor-pointer nextbutton"
                                        style={{ backgroundColor: "#FFFFFF33", width: "10.6rem", height: "3.8rem", opacity: allUsersData?.cumulativeCount === allUsersData?.totalUsers ? "0.5" : "" }}
                                    >
                                        {nextbtnclick && load ? (
                                            <CommonLoaderBlue customSize={10} />
                                        ) : (
                                            <span className="flex align-items-center">
                                                Next &nbsp;
                                                <NextIcon />
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                        <Dialog
                            header="Delete User"
                            visible={visibleDeleteUser}
                            style={{ width: "50vw", overflowX: "hidden", marginLeft: "50%", width: "100%", transform: "translate(-50%)" }}
                            draggable={false}
                            onHide={() => {
                                setVisibleDelelteUser(false);
                            }}
                        >
                            <div style={{ textAlign: "center" }}>
                                <p> Are You Sure to Delete a User ? </p>
                                <div style={{ marginTop: "45px" }}>
                                    <Button
                                        style={{ marginRight: "45px" }}
                                        label="Yes"
                                        onClick={() => {
                                            confirmDeleteUser();
                                        }}
                                    />
                                    <Button
                                        label="No"
                                        onClick={() => {
                                            skipDeleteUser();
                                        }}
                                    />
                                </div>
                            </div>
                        </Dialog>
                        <div className="userdialog">
                            <Dialog
                                header="null"
                                headerClassName="edituserdialogheader"
                                visible={showEditUser}
                                style={{ width: "71.6rem", height: "50rem", display: "flex", flexDirection: "column", alignItems: "center" }}
                                onHide={() => {
                                    setShowEditUser((prev) => !prev);
                                }}
                            >
                                <EditUser data={rowData} setShowEditUser={setShowEditUser} />
                            </Dialog>
                        </div>
                        <div className="userdialog">
                            <Dialog
                                header="null"
                                headerClassName="edituserdialogheader"
                                visible={showAddUser}
                                style={{ width: "71.6rem", height: "50rem", display: "flex", flexDirection: "column", alignItems: "center" }}
                                onHide={() => {
                                    setShowAddUser(false);
                                }}
                            >
                                <CreateUser showAddUser={showAddUser} setShowAddUser={setShowAddUser} setRefresh={setRefresh} />
                            </Dialog>
                        </div>
                    </div>

                    <Toast ref={toastfordelete} />
                </div>
            )}
        </>
    );
};

export default ManageUser;
