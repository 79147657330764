import React from "react";
export const PayInvoiceCheckIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 11.75C9.0375 11.75 9.92188 11.3844 10.6531 10.6531C11.3844 9.92188 11.75 9.0375 11.75 8C11.75 6.9625 11.3844 6.07812 10.6531 5.34688C9.92188 4.61562 9.0375 4.25 8 4.25C6.9625 4.25 6.07812 4.61562 5.34688 5.34688C4.61562 6.07812 4.25 6.9625 4.25 8C4.25 9.0375 4.61562 9.92188 5.34688 10.6531C6.07812 11.3844 6.9625 11.75 8 11.75ZM8 15.5C6.9625 15.5 5.9875 15.3031 5.075 14.9094C4.1625 14.5156 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.48438 11.8375 1.09063 10.925C0.696875 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48438 5.075 1.09063C5.9875 0.696875 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.3031 10.0125 14.9094 10.925C14.5156 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14C9.675 14 11.0938 13.4187 12.2563 12.2563C13.4187 11.0938 14 9.675 14 8C14 6.325 13.4187 4.90625 12.2563 3.74375C11.0938 2.58125 9.675 2 8 2C6.325 2 4.90625 2.58125 3.74375 3.74375C2.58125 4.90625 2 6.325 2 8C2 9.675 2.58125 11.0938 3.74375 12.2563C4.90625 13.4187 6.325 14 8 14Z"
                fill="#2627CF"
            />
        </svg>
    );
};
export const CheckMark = () => {
    return (
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33333 8.66634L0 5.33301L1.16667 4.16634L3.33333 6.33301L8.83333 0.833008L10 1.99967L3.33333 8.66634Z" fill="white" />
        </svg>
    );
};
