import React, { useEffect, useState } from "react";
import "./css/UsageDataTable.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../components/Loader/LoaderBlue";
import NotFoundData from "../../../Payments/Common/Notfound/Notfound";
export const Usagedatatable = ({ setIsEmpty, selectedModule, selectedRange, cpData }) => {
    const [allInvoicesData, setAllUsageData] = useState([]);
    const [isInvoiceLoading, setIsUsageLoading] = useState(true);
    const [isAPiCalled, setIsApiCalled] = useState(false);
    const getCustomerUsage = async () => {
        api.post(`/api/user/helixUsage`, { startDate: selectedRange[0] ? moment(selectedRange[0]).format("YYYY-MM-DD HH:mm:ss") : "", endDate: selectedRange[1] !== "" ? moment(selectedRange[1]).format("YYYY-MM-DD HH:mm:ss") : "", mdn: cpData?.phoneNumber, usageType: selectedModule })
            .then((invoiceResponse) => {
                setAllUsageData(invoiceResponse?.data?.data);
                setIsApiCalled(true);
                setIsUsageLoading(false);
                if (invoiceResponse?.data?.data?.length > 0) {
                    setIsEmpty(false);
                }
            })
            .catch((err) => {
                setAllUsageData([]);
                setIsUsageLoading(false);
            });
    };
    useEffect(() => {
        if (cpData && Object.keys(cpData).length > 0 && selectedRange?.[0] && selectedRange?.[1]) {
            if (cpData?.carrier?.name === "AT&T") {
                getCustomerUsage();
            } else {
                setIsApiCalled(true);
                setIsUsageLoading(false);
            }
        }
    }, [cpData, selectedRange, selectedModule]);

    return (
        <div className="main-usage-container">
            {!isInvoiceLoading && isAPiCalled && allInvoicesData.length === 0 ? (
                <NotFoundData isCarrierTmb={cpData?.carrier?.name !== "AT&T"} currentNoFoundData={"usage"} />
            ) : (
                <DataTable value={allInvoicesData}>
                    <Column
                        header={() => {
                            return (
                                <div className="main-header-mdn flex flex-wrap flex-row  align-items-center">
                                    <p>#</p>
                                    <p>MDN</p>
                                </div>
                            );
                        }}
                        body={(rowData, { rowIndex }) => (
                            <div className="main-row-mdn flex flex-wrap flex-row  align-items-center">
                                <p>{rowIndex < 9 ? "0" + (rowIndex + 1) : rowIndex + 1}</p>
                                <p>{rowData.mdn || "NIL"}</p>
                            </div>
                        )}
                    />

                    <Column field="DialledDigit" header="Dialed Digit" />
                    <Column
                        header="Call Date and Time"
                        body={(rowData) => {
                            return (
                                <span>
                                    {rowData.ATTCallDatetime
                                        ? moment(rowData.ATTCallDatetime)
                                              .tz("America/New_York") // Convert to New York time (Eastern Time Zone)
                                              .format("MM-DD-YYYY hh:mm:ss")
                                        : "NIL"}
                                </span>
                            );
                        }}
                    />
                    <Column
                        field="redirectingParty"
                        body={(rowData) => {
                            return <span>{rowData.redirectingParty || "NIL"}</span>;
                        }}
                        header="Redirecting Party"
                    />
                    <Column field="BillableUnits" header="Billable Units" body={(rowData) => (rowData?.BillableUnits !== undefined && rowData?.BillableUnits !== "NaN" && rowData?.amountPaid !== "" ? parseFloat(rowData?.BillableUnits).toFixed(4) + " MB" : "NIL")} />
                    <Column field="CallType" header="Call Type" body={(rowData) => rowData.CallType || "NIL"} />
                    <Column field="UsageType" body={(rowData) => rowData.UsageType || "NIL"} header="Usage Type" />
                    <Column field="DestinationClass" body={(rowData) => rowData.DestinationClass || "NIL"} header="Destination Class" />
                    <Column field="PlanCode" header="Plan Code" body={(rowData) => rowData.PlanCode || "NIL"} />
                    <Column field="Roaming" header="Roaming" body={(rowData) => rowData.Roaming || "NIL"} />
                </DataTable>
            )}
            {isInvoiceLoading ? (
                <div className="loader-wrapper">
                    <CommonLoaderBlue color="royalblue" sizehere={21} />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};
