import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import { api } from "../../../../../../../../utility/AxiosInceptor";
import { useEffect, useState } from "react";
import PaymentStripeForm from "./stripe_payment_dialog/stripe_payment_form";
const BASE_URL = process.env.REACT_APP_BASE_URL;
let stripePromise;
export default function PaymentStripModule({
    initiationData,
    setPreviewScreenActive,
    parentDetails,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    allplans,
    paymentScreenDetails,
    currentBillingConfig,
    paid,
    plan,
    paymentDone,
    amount,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
}) {
    let [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

        api.post(`/api/web/billing/paymentintent`, { amount: paid })
            .then((response) => {
                setClientSecret(response.data.clientSecret);
            })
            .catch((err) => {});
    }, []);

    return (
        <>
            {clientSecret !== null ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                    }}
                >
                    <PaymentStripeForm
                        initiationData={initiationData}
                        parentDetails={parentDetails}
                        setPreviewScreenActive={setPreviewScreenActive}
                        setPaymentScreenActive={setPaymentScreenActive}
                        plan={plan}
                        setAllPaymentInfo={setAllPaymentInfo}
                        setDataSendForInvoice={setDataSendForInvoice}
                        allplans={allplans}
                        paymentScreenDetails={paymentScreenDetails}
                        currentBillingConfig={currentBillingConfig}
                        paymentDone={paymentDone}
                        setPaymentDone={setPaymentDone}
                        setPaymentScreenDetails={setPaymentScreenDetails}
                        setPaymentDialogVisibility={setPaymentDialogVisibility}
                        clientSecret={clientSecret}
                        amount={amount}
                        paid={paid}
                        setActiveIndex={setActiveIndex}
                        object={object}
                    />
                </Elements>
            ) : undefined}
        </>
    );
}
