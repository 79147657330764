import React, { useRef, useState } from "react";
import "./DownloadInvoice.css";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../../components/Loader/LoaderBlue";
export const StatusConfirmation = ({ toast, reconnectRestoreReason, setReconnectionRestoreRequired, setReconnectRestore, statusTo, connectionType, suspendReason, setSuspendReasonError, setRefreshCustomerData, setIsConfirmationType, isLoading, setIsLoading, cpData, setChangeCustomerStatus }) => {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    async function Updatestatus() {
        if (statusTo === "labelPrinted") {
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Success label Printed";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "preShipment") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    setIsLoading(true);
                    const successMessage = response?.data?.msg || "Successfully Pre shipped";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "inTransit") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Success In ";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setIsLoading(false);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "delivered") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully delivered";
                    setTimeout(() => {
                        setIsLoading(false);
                        toast.success(successMessage);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "active" && cpData?.status === "prospect" && connectionType == "Internally") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    setChangeCustomerStatus(false);
                    const successMessage = response?.data?.msg || "Successfully Active";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "labelCreated" && connectionType == "Externally") {
            setIsLoading(true);
            const dataToSend = {
                enrollmentId: cpData?._id,
                userId: parseLoginRes?._id,
            };
            try {
                const response = await api.post(`/api/user/activate`, dataToSend);
                setIsLoading(false);
                if (response?.status === 200 || response?.status === 201) {
                    const successMessage = response?.data?.msg || "Successfully active";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Activation Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "labelCreated" && connectionType == "Internally") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    setChangeCustomerStatus(false);
                    const successMessage = response?.data?.msg || "Successfully Active";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "prospect" && connectionType == "Internally") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    setChangeCustomerStatus(false);
                    const successMessage = response?.data?.msg || "Successfully Active";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "evaluation") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    const successMessage = response?.data?.msg || "Successfully evaluated";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                toast.error(error.response?.data?.msg || "Evaluation Failed");
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "prospect" && connectionType === "Externally") {
            setIsLoading(true);
            const dataToSend = {
                enrollmentId: cpData?._id,
                userId: parseLoginRes?._id,
            };
            try {
                const response = await api.post(`/api/user/activate`, dataToSend);
                setIsLoading(false);
                if (response?.status === 200 || response?.status === 201) {
                    const successMessage = response?.data?.msg || "Successfully active";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Activation Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "disconnected" && cpData?.status === "suspended" && connectionType === "Externally") {
            setIsLoading(true);
            api.post(`/api/user/disconnectMdnByPwg`, {
                enrollmentId: cpData?._id,
                // disconnectReason: disconnectReason,
            })
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully disconnected";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "reconnect" && connectionType === "Externally") {
            if (cpData?.carrier.name === "AT&T") {
                if (reconnectRestoreReason !== "") {
                    setIsLoading(true);
                    api.post(`/api/user/reConnectMdnByPwg`, {
                        // reasonCode: reconnectRestoreReason,
                        enrollmentId: cpData?._id,
                        userId: parseLoginRes?._id,
                    })
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Successfully Reconnected";
                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setTimeout(() => {
                                    setRefreshCustomerData((prev) => !prev);
                                }, 200);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 50);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.error?.message ? error.response?.data?.error?.message : error.response?.data?.msg ? error.response?.data?.msg : "Reconnecting Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setReconnectionRestoreRequired(true);
                }
            } else {
                setIsLoading(true);
                api.post(`/api/user/reConnectMdnByPwg`, { enrollmentId: cpData?._id, userId: parseLoginRes?._id })
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Successfully Reconnected";
                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setTimeout(() => {
                                setRefreshCustomerData((prev) => !prev);
                            }, 200);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 50);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.error?.message ? error.response?.data?.error?.message : error.response?.data?.msg ? error.response?.data?.msg : "Reconnecting Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (statusTo === "reconnect" && connectionType === "Internally") {
            if (cpData?.carrier?.name === "AT&T") {
                if (reconnectRestoreReason !== "") {
                    setIsLoading(true);
                    api.post(`/api/user/reConnectMdnByPwg`, {
                        // reasonCode: reconnectRestoreReason,
                        enrollmentId: cpData?._id,
                        userId: parseLoginRes?._id,
                    })
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Success reconnected";
                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setTimeout(() => {
                                    setRefreshCustomerData((prev) => !prev);
                                }, 200);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 50);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setReconnectionRestoreRequired(true);
                }
            } else {
                setIsLoading(true);
                api.post(`/api/user/reConnectMdnByPwg`, { enrollmentId: cpData?._id, userId: parseLoginRes?._id })
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Success reconnected";
                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setTimeout(() => {
                                setRefreshCustomerData((prev) => !prev);
                            }, 200);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 50);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (statusTo === "disconnected" && connectionType === "Externally") {
            setIsLoading(true);
            api.post(`/api/user/disconnectMdnByPwg`, {
                enrollmentId: cpData?._id,
                // disconnectReason: disconnectReason,
            })
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully disconnected";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "disconnected" && connectionType === "Internally") {
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            setIsLoading(true);
            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully Disconnected";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "cancelled" && connectionType === "Externally") {
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            setIsLoading(true);
            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully Cancelled";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Cancellation Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "prospect" && connectionType === "Externally") {
            if (cpData?.linkedAccount) {
                const data = {
                    accountType: cpData.accountType,
                    contact: cpData.contact,
                    alternateContact: cpData.alternateContact,
                    customerId: cpData._id,
                    linkedAccount: true,
                    parent_id: cpData.parent_id,
                };
                api.post(`/api/user/checkCustomerDuplication`, data)
                    .then(() => {
                        const dataToSend = {
                            customerId: cpData?._id,
                            status: statusTo,
                        };
                        setIsLoading(true);
                        api.post(`/api/user/statusnonelectronically`, dataToSend)
                            .then((response) => {
                                const successMessage = response?.data?.msg || "Successfully Cancelled";
                                setTimeout(() => {
                                    toast.success(successMessage);
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setRefreshCustomerData((prev) => !prev);
                                    }, 200);
                                    setChangeCustomerStatus((prev) => !prev);
                                }, 50);
                            })
                            .catch((error) => {
                                const errorMessage = error.response?.data?.msg || "Cancellation Failed";
                                toast.error(errorMessage);
                                setIsLoading(false);
                            });
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data?.msg);
                        setIsLoading(false);
                    });
            } else {
                const data = {
                    contact: cpData.contact,
                    accountType: cpData.accountType,
                    alternateContact: cpData.alternateContact,
                };
                api.post(`/api/user/checkCustomerDuplication`, data)
                    .then(() => {
                        const dataToSend = {
                            customerId: cpData?._id,
                            status: statusTo,
                        };
                        setIsLoading(true);
                        api.post(`/api/user/statusnonelectronically`, dataToSend)
                            .then((response) => {
                                const successMessage = response?.data?.msg || "Successfully Prospected";
                                setTimeout(() => {
                                    toast.success(successMessage);
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setRefreshCustomerData((prev) => !prev);
                                    }, 200);
                                    setChangeCustomerStatus((prev) => !prev);
                                }, 50);
                            })
                            .catch((error) => {
                                const errorMessage = error.response?.data?.msg || "Prospection Failed";
                                toast.error(errorMessage);
                                setIsLoading(false);
                            });
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data?.msg);
                        setIsLoading(false);
                    });
            }
        } else if (statusTo === "suspended" && connectionType === "Internally") {
            if (cpData?.carrier?.name === "AT&T") {
                if (suspendReason !== "") {
                    setIsLoading(true);
                    const dataToSend = {
                        // reasonCode: suspendReason,
                        enrollmentId: cpData?._id,
                    };
                    api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Successfully Suspended";
                            toast.success(successMessage);
                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setTimeout(() => {
                                    setRefreshCustomerData((prev) => !prev);
                                }, 200);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 50);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setSuspendReasonError(true);
                }
            } else {
                setIsLoading(true);
                const dataToSend = {
                    enrollmentId: cpData?._id,
                };
                api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Successfully Suspended";
                        toast.success(successMessage);
                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setTimeout(() => {
                                setRefreshCustomerData((prev) => !prev);
                            }, 200);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 50);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (statusTo === "suspended" && connectionType === "Externally") {
            if (cpData?.carrier?.name === "AT&T") {
                if (suspendReason !== "") {
                    setIsLoading(true);
                    const dataToSend = {
                        reasonCode: suspendReason,
                        enrollmentId: cpData?._id,
                    };
                    api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Successfully suspended";
                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setTimeout(() => {
                                    setRefreshCustomerData((prev) => !prev);
                                }, 200);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 10);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setSuspendReasonError(true);
                }
            } else {
                setIsLoading(true);
                const dataToSend = {
                    enrollmentId: cpData?._id,
                };
                api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Successfully suspended";
                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setTimeout(() => {
                                setRefreshCustomerData((prev) => !prev);
                            }, 200);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 10);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (cpData?.status === "suspended" && statusTo === "active" && connectionType === "Externally") {
            setIsLoading(true);
            const dataToSend = {
                enrollmentId: cpData?._id,
            };
            api.post(`/api/user/removeHotline`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully suspended";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (cpData?.status === "active" && connectionType === "Externally") {
            setIsLoading(true);
            api.post(`/api/user/disconnectMdnByPwg`, {
                enrollmentId: cpData?._id,
                // disconnectReason: disconnectReason,
            })
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully disconnected";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (cpData?.status === "active" && connectionType === "Internally") {
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            setIsLoading(true);
            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully Disconnected";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefreshCustomerData((prev) => !prev);
                        }, 200);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else {
            toast.error("Please Select Status OR Type");
        }
    }

    return (
        <>
            <div id="invoice-download-main" className="status-confirmation-main flex flex-wrap flex-row justify-content-center">
                <div className="main-download-invoice">
                    <div className="top-header-downloadinvoice">
                        <h1>Update Profile Status</h1>
                        <p>
                            Are you sure you want to update the status of {cpData?.firstName && cpData?.lastName ? `${cpData?.firstName + " " + cpData?.lastName}` : `${cpData?.firstName}`} to {statusTo.charAt(0).toUpperCase() + statusTo.slice(1)}?
                        </p>
                    </div>
                    <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                setIsConfirmationType(false);
                            }}
                            label="Cancel"
                        />
                        <Button
                            type="button"
                            onClick={() => {
                                Updatestatus();
                            }}
                            label={
                                isLoading ? (
                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                    </div>
                                ) : (
                                    "Update"
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
