import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import "./css/AddBillingModel.css";
import Axios from "axios";
import { Button } from "primereact/button";
import { api } from "../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
export default function UpdateBillingModel({ rowData, setUpdateBillingVisibility, setRefresh }) {
    const [billingModelAdded, setBillingModelAdded] = useState(rowData?.billingModel);
    let toast = useRef();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    function AddBillingModel() {
        if (billingModelAdded.length !== 0) {
            setLoader(true);

            api.put(`/api/billingModel/update`, {
                billingModelId: rowData?._id,

                billingModel: billingModelAdded,
            })
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Billing Model", detail: "Billing Model Updated Successfully" });
                    setTimeout(() => {
                        setLoader(false);
                        setUpdateBillingVisibility(false);
                        setRefresh((prev) => !prev);
                    }, 500);
                })
                .catch((err) => {
                    setLoader(false);
                    toast.current.show({ severity: "error", summary: "Billing Model", detail: err?.response?.data?.msg !== undefined ? err?.response?.data?.msg : `Billing Model Updation Failed` });
                });
        } else {
            setShowError(true);
        }
    }
    return (
        <div className="mainaddbillingmodel">
            <div className="mainaddbillingmodelitem">
                <div className="remaneheader">
                    <h1>Rename Billing Model</h1>
                    <p>Would you like to proceed with the Suggested Address ?</p>
                </div>
                <div className="remaneinputdata">
                    <label className="w-full">Enter Name</label>
                    <InputText
                        value={billingModelAdded}
                        onChange={(e) => {
                            setBillingModelAdded(e.target.value);
                            if (e.target.value.length === 0) {
                                setShowError(true);
                            } else {
                                setShowError(false);
                            }
                        }}
                        placeholder="Billing Model"
                        className="calendar_field w-full mt-1"
                    />
                </div>
                {/* {showError ? <p className="p-error mt-1 w-full">Billing Model Is Required</p> : undefined} */}
            </div>

            <div className="remanesubmitbtn">
                <Button
                    className="backbtn"
                    style={{ width: "16rem", height: "5rem" }}
                    onClick={() => {
                        setUpdateBillingVisibility(false);
                    }}
                    label="Cancel"
                />
                <Button className="nextbtn" style={{ width: "16rem", height: "5rem" }} onClick={AddBillingModel} disabled={loader || !billingModelAdded} loading={loader} label="Submit" />
            </div>
            <Toast ref={toast} />
        </div>
    );
}
