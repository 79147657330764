import React from "react";
import "./css/DetailsAutoPay.css";
import moment from "moment";
export const DetailsAutoPay = ({ cpData }) => {
    return (
        <div className="main-details-autopay">
            <h1 className="header-tag-detail">Details</h1>
            <div className="main-detail-container">
                <div className="detail-autopay-item">
                    <p>Enrollment ID</p>
                    <p>{cpData.enrollmentId || ""}</p>
                </div>
                <div className="detail-autopay-item">
                    <p>Customer Name</p>
                    <p>
                        {cpData.firstName || ""} {cpData.lastName || ""}
                    </p>
                </div>
                <div className="detail-autopay-item">
                    <p>Phone Number</p>
                    <p>{cpData.contact || ""}</p>
                </div>
                <div className="detail-autopay-item">
                    <p>Date</p>
                    <p>{cpData.createdAt ? moment.utc(cpData.createdAt).tz("America/New_York").format("YYYY-MM-DD") : ""}</p>
                </div>
            </div>
        </div>
    );
};
