import React, { useEffect, useRef, useState } from "react";

import "./css/StripeCheckoutForm.css";
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "../../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { api } from "../../../../../../../../../../utility/AxiosInceptor";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";

export const PayInvoiceCheckoutForm = ({ setRefreshCustomerData, setRefreshNotes, setShowAmountError, setRefreshPayInvoice, setShowPayInvoiceDialog, funcToCall, setFucToCallOnPay, cpData, amount, selectedInvoices }) => {
    let toast = useRef();
    const parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const stripe = useStripe();
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            cardNumber: "",
            cardExpiry: "",
            cardCvc: "",
        },
        validationSchema: Yup.object({
            cardNumber: Yup.string().required("Field is required"),
            cardExpiry: Yup.string().required("Required"),
            cardCvc: Yup.string().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (amount === 0 || amount === undefined || amount === "") {
                setShowAmountError(true);
            } else {
                setIsLoading(true);
                let response = await api.post(`/api/web/billing/paymentintent`, { amount: amount });
                const clientSecret = await response.data.clientSecret;
                if (!elements || !clientSecret) {
                    toast.current.show({ severity: "error", summary: "Stripe Elements not loaded", detail: "Please try again later." });
                    setIsLoading(false);
                    return;
                }

                // Call confirmCardPayment directly to confirm the payment
                const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement), // The CardNumberElement
                        billing_details: {
                            name: "Customer Name", // You can pass the customer name dynamically here
                        },
                    },
                });
                if (paymentIntent?.status === "requires_action") {
                    // 3D Secure authentication is required
                    handle3DSecureAuthentication(paymentIntent.client_secret, paymentIntent);
                } else if (error) {
                    toast.current.show({
                        severity: "error",
                        summary: "Stripe Payment",
                        detail: (
                            <p>
                                {" "}
                                {error?.message} {error.code ? error.code : ""}
                            </p>
                        ),
                    });

                    api.post(`/api/web/billing/updatePaymentLog`, {
                        customerId: cpData?._id,
                        paymentMethod: "Credit Card",
                        stripeId: paymentIntent,
                        invoiceType: "Add Wallet",
                        action: "failure",
                        invoiceNo: [],
                        reason: "failure",
                        cardNumber: error?.payment_method?.card?.last4,
                        transactionStatus: error?.message,
                        paymentStatus: "failure",
                        amount: amount,
                        userId: parseLoginRes?._id,
                    })
                        .then((res) => {
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                        });
                    toast.current.show({ severity: "error", summary: "Add Wallet Payment", detail: "Wallet Updatdation Failed" });
                } else {
                    api.post(`/api/web/invoices/addinWallet`, {
                        amount: amount,
                        userId: parseLoginRes?._id,
                        customerId: cpData?._id,
                        serviceProvider: parseLoginRes?.company,
                        paymentIntentId: paymentIntent?.id,
                    })
                        .then((response) => {
                            toast.current.show({ severity: "success", summary: "Add Wallet Payment", detail: "Wallet Updated Successfully" });
                            setIsLoading(false);
                            setRefreshNotes((prev) => !prev);
                            setRefreshCustomerData((prev) => !prev);
                        })
                        .catch((err) => {
                            toast.current.show({ severity: "error", summary: "Add Wallet Payment", detail: "Wallet Updatdation Failed" });
                            setIsLoading(false);
                        });
                }
            }
        },
    });

    const handle3DSecureAuthentication = async (clientSecret, paymentIntent) => {
        try {
            const { error } = await stripe.handleCardAction(clientSecret);
            if (error) {
                toast.current.show({
                    severity: "error",
                    summary: "Stripe Payment",
                    detail: (
                        <p>
                            {" "}
                            {error?.message} {error.code ? error.code : ""}
                        </p>
                    ),
                });
                api.post(`/api/web/billing/updatePaymentLog`, {
                    customerId: cpData?._id,
                    paymentMethod: "Credit Card",
                    stripeId: paymentIntent,
                    invoiceType: "Add Wallet",
                    action: "failure",
                    invoiceNo: [],
                    reason: "failure",
                    cardNumber: error?.payment_method?.card?.last4,
                    transactionStatus: error?.message,
                    paymentStatus: "failure",
                    amount: amount,
                    userId: parseLoginRes?._id,
                })
                    .then((res) => {
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        setIsLoading(false);
                    });
                toast.current.show({ severity: "success", summary: "Add Wallet Payment", detail: "Wallet Payment Failed" });
            } else {
                api.post(`/api/web/invoices/addinWallet`, {
                    amount: amount,
                    userId: parseLoginRes?._id,
                    customerId: cpData?._id,
                    serviceProvider: parseLoginRes?.company,
                    paymentIntentId: paymentIntent?.id,
                })
                    .then((response) => {
                        toast.current.show({ severity: "success", summary: "Add Wallet Payment", detail: "Wallet Updated Successfully" });
                        setIsLoading(false);
                        setRefreshNotes((prev) => !prev);
                        setRefreshCustomerData((prev) => !prev);
                    })
                    .catch((err) => {
                        toast.current.show({ severity: "error", summary: "Add Wallet Payment", detail: "Wallet Updatdation Failed" });
                        setIsLoading(false);
                    });
            }
        } catch (error) {
            api.post(`/api/web/billing/updatePaymentLog`, {
                customerId: cpData?._id,
                paymentMethod: "Credit Card",
                stripeId: paymentIntent,
                invoiceType: "Add Wallet",
                action: "failure",
                invoiceNo: [],
                reason: "failure",
                cardNumber: error?.payment_method?.card?.last4,
                transactionStatus: error?.message,
                paymentStatus: "failure",
                amount: amount,
                userId: parseLoginRes?._id,
            })
                .then((res) => {
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
            toast.current.show({ severity: "error", summary: "Add Wallet Payment", detail: "Wallet Updatdation Failed" });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="wallet-container">
            <Toast ref={toast} />

            <form
                className="main-wallet-paymentform"
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
            >
                <div className="card-input-main">
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Number</label>
                            {formik.errors.cardNumber && formik.touched.cardNumber && <label>{formik.errors.cardNumber}</label>}
                        </div>
                        <CardNumberElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardNumber", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardNumber && formik.touched.cardNumber ? "p-invalid" : ""}`}
                        />
                    </div>
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Expiry</label>
                            {formik.errors.cardExpiry && formik.touched.cardExpiry && <label>{formik.errors.cardExpiry}</label>}
                        </div>

                        <CardExpiryElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardExpiry", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardExpiry && formik.touched.cardExpiry ? "p-invalid" : ""}`}
                        />
                    </div>
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Security Code *</label>
                            {formik.errors.cardCvc && formik.touched.cardCvc && <label>{formik.errors.cardCvc}</label>}
                        </div>

                        <CardCvcElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardCvc", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardCvc && formik.touched.cardCvc ? "p-invalid" : ""}`}
                        />
                    </div>
                </div>
            </form>
            <div className="action-buttons-container flex flex-wrap flex-row justify-content-end">
                <Button
                    onClick={() => {
                        if (amount === 0 || amount === undefined || amount === "") {
                            setShowAmountError(true);
                        }
                        formik.handleSubmit();
                    }}
                    disabled={isLoading}
                    label={
                        isLoading ? (
                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                <CommonLoaderBlue customSize={12} color={"royalblue"} />
                            </div>
                        ) : (
                            "Pay Now"
                        )
                    }
                />
            </div>
        </div>
    );
};
