import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import "./css/AddBillingModel.css";
import Axios from "axios";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../utility/AxiosInceptor";
export default function AddBillingModel({ setAddBillingModelDialog, setRefreshBillingModel }) {
    const [billingModelAdded, setBillingModelAdded] = useState("");
    let toast = useRef();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    function AddBillingModel() {
        if (billingModelAdded.length !== 0) {
            setLoader(true);

            api.post(`/api/billingModel/addBillingmodel`, { billingModel: billingModelAdded, serviceProvider: parseLoginRes?.company })
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Billing Model", detail: "Billing Model Submitted Successfully" });
                    setTimeout(() => {
                        setLoader(false);
                        setAddBillingModelDialog(false);
                        setRefreshBillingModel((prev) => !prev);
                    }, 500);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.msg !== undefined ? err?.response?.data?.msg : `Billing Model Addition Failed`);
                    toast.current.show({ severity: "error", summary: "Billing Model", detail: "Billing Model Submission Failed" });
                });
        } else {
            setShowError(true);
        }
    }
    return (
        <div className="mainaddbillingmodel">
            <div className="mainaddbillingmodelitem">
                <label className="w-full">Billing Model</label>
                <InputText
                    value={billingModelAdded}
                    onChange={(e) => {
                        setBillingModelAdded(e.target.value);
                        if (e.target.value.length === 0) {
                            setShowError(true);
                        } else {
                            setShowError(false);
                        }
                    }}
                    placeholder="Billing Model"
                    className="w-full mt-2"
                />
                {showError ? <p className="p-error mt-1 w-full">Billing Model Is Required</p> : undefined}
            </div>

            <div className="w-full">
                <Button onClick={AddBillingModel} disabled={loader} className="mt-2" loading={loader} label="Submit" />
            </div>
            <Toast ref={toast} />
        </div>
    );
}
