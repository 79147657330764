import React, { useEffect, useRef, useState } from "react";
import "./css/Customer-Profile.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import moment from "moment";
import { api } from "../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { Dropdown } from "primereact/dropdown";
import { EditProfile } from "../../../../../../../utility";
export const CustomerProfileInfo = ({ cpData, setRefreshCustomerData }) => {
    const toast = useRef();
    const [isEditInfoSubmitLoading, setIsEditInfoSubmitLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            firstName: cpData.firstName || "",
            lastName: cpData.lastName || "",
            fullName: cpData.firstName && cpData.lastName ? cpData.firstName + " " + cpData.lastName : cpData.firstName,
            city: cpData.city || "",
            address1: cpData.address1 || "",
            state: cpData.state || "",
            zipCode: cpData.zipCode || "",
            contact: cpData.contact || "",
            email: cpData.email || "",
            mailingAddress: cpData.mailingAddress || "",
            mailingCity: cpData.mailingCity || "",
            mailingState: cpData.mailingState || "",
            mailingZip: cpData.mailingZip || "",
            SSN: cpData.SSN || "",
            maidenMotherName: cpData.motherName || "",
            DOB: cpData.DOB || "",
            tribalArea: cpData.tribalArea || "",
            company: cpData.company || "",
            salesChannel: cpData.salesChannel || "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Field Is Required"),
            DOB: Yup.string().required("Field Is Required"),
            contact: Yup.string().required("Field Is Required"),
            firstName: Yup.string().required("Field Is Required"),
            lastName: Yup.string().required("Field Is Required"),
            SSN: Yup.string().required("Field Is Required"),
            salesChannel: Yup.string().required("Field Is Required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setIsEditInfoSubmitLoading(true);
            api.patch(`/api/user?enrollmentId=${cpData?._id}`, { ...values, DOB: moment(values.DOB).format("YYYY-MM-DD") })
                .then((response) => {
                    toast.current.show({ severity: "success", summary: "Info", detail: response?.data?.msg || "Customer Information Updated Successfully" });
                    setIsEditInfoSubmitLoading(false);
                    setIsEditable(false);
                    setRefreshCustomerData((prev) => !prev);
                })
                .catch((error) => {
                    toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg || "Customer Information Updation Failed" });
                    setIsEditInfoSubmitLoading(false);
                });
        },
    });
    useEffect(() => {
        formik.setValues({
            firstName: cpData.firstName || "",
            lastName: cpData.lastName || "",
            fullName: cpData.firstName && cpData.lastName ? cpData.firstName + " " + cpData.lastName : cpData.firstName,
            city: cpData.city || "",
            address1: cpData.address1 || "",
            state: cpData.state || "",
            zipCode: cpData.zip || "",
            contact: cpData.contact || "",
            email: cpData.email || "",
            mailingAddress: cpData?.isSameServiceAddress ? cpData?.address1 : cpData?.isNotSameServiceAddress ? cpData?.mailingAddress1 + (cpData?.mailingAddress2 || "") : cpData?.isPoBoxAddress ? cpData?.PoBoxAddress : cpData?.address1,
            mailingCity: cpData?.isSameServiceAddress ? cpData?.city : cpData?.isNotSameServiceAddress ? cpData?.mailingCity : cpData?.isPoBoxAddress ? cpData?.poBoxCity : cpData?.city,
            mailingState: cpData?.isSameServiceAddress ? cpData?.state : cpData?.isNotSameServiceAddress ? cpData?.mailingState : cpData?.isPoBoxAddress ? cpData?.poBoxState : cpData?.state,
            mailingZip: cpData?.isSameServiceAddress ? cpData?.zip : cpData?.isNotSameServiceAddress ? cpData?.mailingZip : cpData?.isPoBoxAddress ? cpData?.poBoxZip : cpData?.zip,
            SSN: cpData.SSN || "",
            maidenMotherName: cpData?.maidenMotherName || "",
            DOB: cpData.DOB ? new Date(cpData.DOB) : "",
            tribalArea: cpData.tribalArea || "",
            company: JSON.parse(localStorage.getItem("userData")).companyName || "",
            salesChannel: cpData.salesChannel || "",
        });
    }, [cpData]);
    const [isEditable, setIsEditable] = useState(false);
    const salesChannelOptions = [
        {
            label: "Web Consent",
            value: "Web Consent",
        },
        {
            label: "Old Facebook",
            value: "Old Facebook",
        },
        {
            label: "New Facebook",
            value: "New Facebook",
        },
        {
            label: "Auto",
            value: "Auto",
        },
        {
            label: "Email",
            value: "Email",
        },
        {
            label: "SMM",
            value: "SMM",
        },
    ];
    return (
        <>
            <div className="customer-info-main-container">
                <div className="top-customer-info">
                    <h1>Customer Information</h1>
                    <span
                        onClick={() => {
                            setIsEditable((prev) => !prev);
                            formik.setValues({
                                firstName: cpData.firstName || "",
                                lastName: cpData.lastName || "",
                                fullName: cpData.firstName && cpData.lastName ? cpData.firstName + " " + cpData.lastName : cpData.firstName,
                                city: cpData.city || "",
                                address1: cpData.address1 || "",
                                state: cpData.state || "",
                                zipCode: cpData.zip || "",
                                contact: cpData.contact || "",
                                email: cpData.email || "",
                                mailingAddress: cpData?.isSameServiceAddress ? cpData?.address1 : cpData?.isNotSameServiceAddress ? cpData?.mailingAddress1 + (cpData?.mailingAddress2 || "") : cpData?.isPoBoxAddress ? cpData?.PoBoxAddress : cpData?.address1,
                                mailingCity: cpData?.isSameServiceAddress ? cpData?.city : cpData?.isNotSameServiceAddress ? cpData?.mailingCity : cpData?.isPoBoxAddress ? cpData?.poBoxCity : cpData?.city,
                                mailingState: cpData?.isSameServiceAddress ? cpData?.state : cpData?.isNotSameServiceAddress ? cpData?.mailingState : cpData?.isPoBoxAddress ? cpData?.poBoxState : cpData?.state,
                                mailingZip: cpData?.isSameServiceAddress ? cpData?.zip : cpData?.isNotSameServiceAddress ? cpData?.mailingZip : cpData?.isPoBoxAddress ? cpData?.poBoxZip : cpData?.zip,
                                SSN: cpData.SSN || "",
                                maidenMotherName: cpData?.maidenMotherName || "",
                                DOB: cpData.DOB ? new Date(cpData.DOB) : "",
                                tribalArea: cpData.tribalArea || "",
                                company: JSON.parse(localStorage.getItem("userData")).companyName || "",
                                salesChannel: cpData.salesChannel || "",
                            });
                        }}
                        className={isEditable ? "edit-icondisable cursor-pointer actionbtnmain" : "edit-icon cursor-pointer actionbtnmain"}
                    >
                        <EditProfile />
                    </span>
                    <p>Customer Information tab displays and allows editing of personal details.</p>
                </div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                    }}
                    className="bottom-customer-info"
                >
                    {!isEditable ? (
                        <>
                            <div className="input-container-customerprofile">
                                <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                                    <label>First Name</label>
                                    {isEditable && formik.touched.firstName && formik.errors.firstName && <label>{formik.errors.firstName}</label>}
                                </div>
                                <input disabled={!isEditable} className={`${formik.touched.firstName && formik.errors.firstName ? "p-invalid" : ""}`} name="firstName" onChange={formik.handleChange} placeholder="First Name" value={formik.values.firstName} />
                            </div>
                            <div className="input-container-customerprofile">
                                <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                                    <label>Last Name</label>
                                    {isEditable && formik.touched.lastName && formik.errors.lastName && <label>{formik.errors.lastName}</label>}
                                </div>
                                <input disabled={!isEditable} className={`${formik.touched.lastName && formik.errors.lastName ? "p-invalid" : ""}`} name="lastName" onChange={formik.handleChange} placeholder="Last Name" value={formik.values.lastName} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="input-container-customerprofile">
                                <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                                    <label>First Name</label>
                                    {isEditable && formik.touched.firstName && formik.errors.firstName && <label>{formik.errors.firstName}</label>}
                                </div>
                                <input disabled={!isEditable} className={`${formik.touched.firstName && formik.errors.firstName ? "p-invalid" : ""}`} name="firstName" onChange={formik.handleChange} placeholder="First Name" value={formik.values.firstName} />
                            </div>
                            <div className="input-container-customerprofile">
                                <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                                    <label>Last Name</label>
                                    {isEditable && formik.touched.lastName && formik.errors.lastName && <label>{formik.errors.lastName}</label>}
                                </div>
                                <input disabled={!isEditable} className={`${formik.touched.lastName && formik.errors.lastName ? "p-invalid" : ""}`} name="lastName" onChange={formik.handleChange} placeholder="Last Name" value={formik.values.lastName} />
                            </div>
                        </>
                    )}
                    <div className="input-container-customerprofile">
                        <label>Address 1</label>
                        <input disabled={!isEditable} placeholder="Address 1" onChange={formik.handleChange} name="address1" value={formik.values.address1} />
                    </div>
                    <div className="input-container-customerprofile">
                        <label>City</label>
                        <input disabled={!isEditable} name="city" value={formik.values.city} onChange={formik.handleChange} placeholder="Full Name" />
                    </div>
                    <div className="input-container-customerprofile">
                        <label>State</label>
                        <input disabled={!isEditable} name="state" onChange={formik.handleChange} placeholder="State" value={formik.values.state} />
                    </div>
                    <div className="input-container-customerprofile">
                        <label>Zipcode</label>
                        <input disabled={!isEditable} name="zipCode" placeholder="Zipcode" value={formik.values.zipCode} onChange={formik.handleChange} />
                    </div>
                    <div className="input-container-customerprofile">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Contact</label>
                            {isEditable && formik.touched.contact && formik.errors.contact && <label>{formik.errors.contact}</label>}
                        </div>
                        <input disabled={!isEditable} placeholder="Contact" name="contact" className={`${formik.touched.contact && formik.errors.contact ? "p-invalid" : ""}`} onChange={formik.handleChange} value={formik.values.contact} />
                    </div>
                    <div className="input-container-customerprofile">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Email</label>
                            {isEditable && formik.touched.email && formik.errors.email && <label>{formik.errors.email}</label>}
                        </div>
                        <input disabled={!isEditable} onChange={formik.handleChange} className={`${formik.touched.email && formik.errors.email ? "p-invalid" : ""}`} name="email" placeholder="Email" value={formik.values.email} />
                    </div>
                    <div className="input-container-customerprofile">
                        <label>Mailing Address</label>
                        <input disabled={!isEditable} onChange={formik.handleChange} name="mailingAddress" placeholder="Mailing Address" value={formik.values.mailingAddress} />
                    </div>{" "}
                    <div className="input-container-customerprofile">
                        <label>Mailing City</label>
                        <input disabled={!isEditable} onChange={formik.handleChange} name="mailingCity" placeholder="Mailing City" value={formik.values.mailingCity} />
                    </div>{" "}
                    <div className="input-container-customerprofile">
                        <label>Mailing State</label>
                        <input disabled={!isEditable} onChange={formik.handleChange} name="mailingState" placeholder="Mailing State" value={formik.values.mailingState} />
                    </div>{" "}
                    <div className="input-container-customerprofile">
                        <label>Mailing Zip</label>
                        <input disabled={!isEditable} onChange={formik.handleChange} name="mailingZip" placeholder="Mailing Zip" value={formik.values.mailingZip} />
                    </div>{" "}
                    <div className="input-container-customerprofile">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Customer SSN</label>
                            {isEditable && formik.touched.SSN && formik.errors.SSN && <label>{formik.errors.SSN}</label>}
                        </div>
                        <input disabled={!isEditable} className={`${formik.touched.SSN && formik.errors.SSN ? "p-invalid" : ""}`} onChange={formik.handleChange} name="SSN" placeholder="Customer SSN" value={formik.values.SSN} />
                    </div>{" "}
                    <div className="input-container-customerprofile">
                        <label>Mother's Maiden Name</label>
                        <input disabled={!isEditable} onChange={formik.handleChange} name="maidenMotherName" placeholder="Mother's Maiden Name" value={formik.values.maidenMotherName} />
                    </div>{" "}
                    <div className="input-container-customerprofile-customerprofile">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Customer DOB</label>
                            {isEditable && formik.touched.DOB && formik.errors.DOB && <label>{formik.errors.DOB}</label>}
                        </div>

                        <Calendar className={`w-full  customer-dob-main ${formik.touched.DOB && formik.errors.DOB ? "p-invalid" : ""}`} disabled={!isEditable} onChange={formik.handleChange} name="DOB" placeholder="Customer DOB" value={formik.values.DOB} />
                    </div>{" "}
                    <div className="input-container-customerprofile">
                        <label>Tribal</label>
                        <input disabled={!isEditable} onChange={formik.handleChange} name="tribal" placeholder="Tribal Area" value={formik.values.tribal} />
                    </div>{" "}
                    <div className="input-container-customerprofile">
                        <label>Company</label>
                        <input disabled={!isEditable} onChange={formik.handleChange} name="company" placeholder="Company" value={formik.values.company} />
                    </div>{" "}
                    <div className="input-container-customerprofile-customerprofile">
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Sales Channel</label>
                            {isEditable && formik.touched.DOB && formik.errors.DOB && <label>{formik.errors.DOB}</label>}
                        </div>
                        <Dropdown
                            options={salesChannelOptions}
                            disabled={!isEditable}
                            className={`sales-channel-drop w-full ${formik.touched.salesChannel && formik.errors.salesChannel ? "p-invalid" : ""}`}
                            onChange={formik.handleChange}
                            name="salesChannel"
                            placeholder="Sales Channel"
                            value={formik.values.salesChannel}
                        />
                    </div>{" "}
                    {isEditable ? (
                        <div className="w-full flex flex-wrap flex-row justify-content-end  button-update-status">
                            <Button
                                onClick={() => {
                                    setIsEditable(false);
                                }}
                                label="Cancel"
                            />

                            <Button type="submit" icon={isEditInfoSubmitLoading ? <CommonLoaderBlue color="white" customSize={14} /> : ""} disabled={isEditInfoSubmitLoading} label={isEditInfoSubmitLoading ? "" : "Submit"} />
                        </div>
                    ) : undefined}
                </form>
            </div>

            <Toast ref={toast} />
        </>
    );
};
