import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import "./css/AddType.css";
import { Button } from "primereact/button";
import { api } from "../../../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { Toast } from "primereact/toast";
const AddType = ({ setRefershInvoiceTypes, setIsAddTypeActive }) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const toast = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [validationSchema, setValidationSchema] = useState(
        Yup.object().shape({
            typeName: Yup.string().required("Field Is Required"),
        }),
    );
    const formik = useFormik({
        initialValues: {
            typeName: "",
            servicePrvider: userData?.company,
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            setIsLoading(true);

            api.post(`/api/web/invoiceType/add`, {
                typeName: formik.values.typeName,
                serviceProvider: userData?.company,
            })
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Add Invoice Type", detail: "Invoice Type Added Successfully" });
                    setIsLoading(false);
                    setRefershInvoiceTypes((prev) => !prev);
                    resetForm();
                    setTimeout(() => {
                        setIsAddTypeActive(false);
                    }, 500);
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Add Invoice Type", detail: err?.response?.data?.msg || "Invoice Type Addition Failed" });
                    setIsLoading(false);
                });
        },
    });

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
            }}
            className="main-add-type"
        >
            <div className="input-container-addhoc">
                <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                    <label>Type Name</label>

                    {formik.touched.typeName && formik.errors.typeName && <label>{formik.errors.typeName}</label>}
                </div>
                <input name="typeName" className={`${formik.touched.typeName && formik.errors.typeName && "p-invalid"}`} value={formik.values.typeName} onChange={formik.handleChange} placeholder="Type Name" />
            </div>
            <div className="addhoc-action-buttons-container flex flex-wrap flex-row justify-content-end">
                <Button
                    disabled={isLoading}
                    onClick={() => {
                        setIsAddTypeActive(false);
                    }}
                    label="Cancel"
                />
                <Button
                    disabled={isLoading}
                    type="submit"
                    label={
                        isLoading ? (
                            <div className="flex flex-wrap  w-full flex-row justify-content-center">
                                <CommonLoaderBlue customSize={12} />
                            </div>
                        ) : (
                            "Add Type"
                        )
                    }
                />
            </div>
            <Toast ref={toast} />
        </form>
    );
};

export default AddType;
