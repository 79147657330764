import React from "react";
import "./css/welcome-screen.css";
const WelcomeScreen = () => {
    const loginRes = JSON.parse(localStorage.getItem("userData")).companyName;
    return (
        <div className="welcome-screen">
            <h1>Welcome to {loginRes}</h1>
            <div className="flex w-full flex-wrap flex-row justify-content-center align-items-center">
                <img src="/Crm-Image.webp" alt="welcome-screen" />
            </div>
        </div>
    );
};

export default WelcomeScreen;
