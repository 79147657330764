import React, { useRef, useState } from "react";
import "./PaidExternally.css";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../../../components/Loader/LoaderBlue";
export const PaidExternally = ({ setSelectedAction, cpData, currentInvoice, setCurrentActiveDialog }) => {
    let toast = useRef();
    console.log(currentInvoice);
    let str = [...currentInvoice.transId, ...currentInvoice.paymentIds] // Merge both arrays
        .filter((item) => item) // Remove falsy values
        .join(",");
    const LoginRes = JSON.parse(localStorage.getItem("userData"));
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        transactionId: Yup.string().required("Field Is Required"),
        note: Yup.string().required("Field Is Required"),
        paymentChannel: Yup.string().required("Field Is Required"),
        amountPaid: Yup.string().required("Field Is Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            transactionId: "",
            paymentChannel: "",
            amountPaid: "",
            customerId: cpData?._id,
            note: "",
            userId: LoginRes?._id,
            invoiceId: currentInvoice?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            api.post(`/api/web/invoices/payExternally`, { ...values, status: parseFloat(currentInvoice.dueAmount) > formik.values.amountPaid ? "Partial" : parseFloat(currentInvoice.dueAmount) === formik.values.amountPaid ? "Paid" : "Unpaid", note: `EPT:${values.transactionId}, ${values.note}` })
                .then((res) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "success", summary: "Invoice Pay Externally", detail: "Invoice Paid Externally Successfully" });
                    setTimeout(() => {
                        setSelectedAction("");
                        setCurrentActiveDialog(false);
                    }, 1000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Invoice Pay Externally", detail: err?.response?.data?.message || err?.response?.data?.msg || "Invoice Externally Paying Failed" });
                });
        },
    });
    return (
        <>
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
                id="paid-externally-container"
                className="paid-externally-container flex flex-wrap flex-row justify-content-center"
            >
                <div className="main-void-invoice">
                    <div className="header-main-container-voidinvoice">
                        <div className="header-main-void-invoice">
                            <h1>Pay Externally</h1>
                            <p>Enter the Transaction ID for the payment made externally and add a note for reference. This will help track the payment status accurately.</p>
                        </div>
                        <div className="header-bottom-void">
                            <p>
                                Invoice No<span>{currentInvoice.invoiceNo || "NIL"}</span>
                            </p>
                            <div className="flex flex-wrap flex-row justify-content-start container-getdetails">
                                <div className="void-reason-container ">
                                    <div className="flex flex-wrap w-full flex-row justify-content-between">
                                        <label>Transaction Id</label>
                                        {formik.touched.transactionId && formik.errors.transactionId && <label>{formik.errors.transactionId}</label>}
                                    </div>
                                    <input name="transactionId" value={formik.values.transactionId} className={`${formik.touched.transactionId && formik.errors.transactionId && "p-invalid"}`} onChange={formik.handleChange} placeholder="Transaction Id" />
                                </div>

                                <div className="void-reason-container ">
                                    <div className="flex flex-wrap w-full flex-row justify-content-between">
                                        <label>Paid Using/ Channel</label>
                                        {formik.touched.paymentChannel && formik.errors.paymentChannel && <label>{formik.errors.paymentChannel}</label>}
                                    </div>
                                    <input name="paymentChannel" value={formik.values.paymentChannel} className={`${formik.touched.paymentChannel && formik.errors.paymentChannel && "p-invalid"}`} onChange={formik.handleChange} placeholder="Paid Using/ Channel" />
                                </div>
                                <div className="void-reason-container ">
                                    <div className="flex flex-wrap w-full flex-row justify-content-between">
                                        <label>Amount Paid</label>
                                        {formik.touched.amountPaid && formik.errors.amountPaid && <label>{formik.errors.amountPaid}</label>}
                                    </div>
                                    <input type="number" name="amountPaid" value={formik.values.amountPaid} className={`${formik.touched.amountPaid && formik.errors.amountPaid && "p-invalid"}`} onChange={formik.handleChange} placeholder="$00.00" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="void-reason-container note-add-container ">
                        <div className="flex flex-wrap w-full flex-row justify-content-between">
                            <label>Add note</label>
                            {formik.touched.note && formik.errors.note && <label>{formik.errors.note}</label>}
                        </div>
                        <textarea name="note" value={formik.values.note} className={`${formik.touched.note && formik.errors.note && "p-invalid"}`} onChange={formik.handleChange} placeholder="Add New Note" />
                    </div>
                    <div className="action-buttons flex flex-wrap flex-row justify-content-end aligbn-item-center">
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                setSelectedAction("");
                                setCurrentActiveDialog({
                                    void: false,
                                    lateFee: false,
                                    adjustInvoice: false,
                                    download: false,
                                });
                            }}
                            label="Cancel"
                        />
                        <Button
                            type="submit"
                            label={
                                isLoading ? (
                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                    </div>
                                ) : (
                                    "Submit"
                                )
                            }
                        />
                    </div>
                </div>
                <Toast ref={toast} />
            </form>
        </>
    );
};
