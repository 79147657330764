import Axios from "axios";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "./css/Change_customer.css";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import React, { useState } from "react";
import { TransferException, prospectStatusOptions, activeStatusOptions, suspendStatusOptions, disconnectStatusOptions, connection, connectionExternally, cancelledStatusOption } from "./dropdown_options/options";
import { StatusConfirmation } from "./Components/StatusConfirmation";
export const ChangeCustomerStatusCustomerProfile = ({ setRefreshCustomerData, cpData, setChangeCustomerStatus }) => {
    const [isLoading, setIsLoading] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [reconnectRestoreReason, setReconnectRestore] = useState();
    const [reconnectionRestoreRequired, setReconnectionRestoreRequired] = useState(false);
    //ConnectionType State
    const [isConfirmationType, setIsConfirmationType] = useState(false);
    const [connectionType, setConnectionType] = useState("Externally");
    const [disconnectReason, setdisconnectReason] = useState("");
    const [discconectReasonEror, setdisconnectReasonError] = useState("");
    const [suspendReason, setSuspendReason] = useState("");
    const [suspendReasonError, setSuspendReasonError] = useState(false);
    //StatusType State
    const [statusTo, setStatusTo] = useState("");
    //Option for TransferException
    const transferExceptionOption = TransferException;

    //Options For Connection Type
    const connectionTypeOption = connection;
    const ATTDisconnect = [
        { label: "Non-Payment of Bills", value: "Non-Payment of Bills" },
        { label: "Fraudulent Activity", value: "Fraudulent Activity" },
        { label: "Violation of Legal Requirements", value: "Violation of Legal Requirements" },
        { label: "Request by Subscriber", value: "Request by Subscriber" },
        { label: "Service Termination by Provider", value: "Service Termination by Provider" },
        { label: "Subscriber Ported to Another Operator", value: "Subscriber Ported to Another Operator" },
        { label: "Inactive or Dormant Account", value: "Inactive or Dormant Account" },
        { label: "Service Unavailable", value: "Service Unavailable" },
    ];
    const ATTsuspendreasons = [
        { label: "Non-Payment of Bills (NPG)", value: "Non-PaymentofBills(NPG)" },
        { label: "Fraudulent Activity", value: "FraudulentActivity" },
        { label: "Violation of Terms and Conditions", value: "ViolationofTermsandConditions" },
        { label: "Request by Subscriber", value: "RequestbySubscriber" },
        { label: "Regulatory Compliance", value: "RegulatoryCompliance" },
        { label: "Network Abuse or Misuse", value: "NetworkAbuseorMisuse" },
        { label: "Inactive or Dormant Account", value: "InactiveorDormantAccount" },
    ];
    const ATTreconnecting_restoring = [
        { label: "Payment of Outstanding Bills", value: "PaymentofOutstandingBills" },
        { label: "Resolution of Fraud or Security Issues", value: "ResolutionofFraudorSecurityIssues" },
        { label: "Compliance with Terms and Conditions", value: "CompliancewithTermsandConditions" },
        { label: "Request by Subscriber", value: "RequestbySubscriber" },
        { label: "Completion of Identity Verification", value: "CompletionofIdentityVerification" },
        { label: "Resolution of Technical Issues", value: "ResolutionofTechnicalIssues" },
        { label: "Settlement of Legal or Regulatory Issues", value: "SettlementofLegalorRegulatoryIssues" },
    ];
    const UpdateStatus = async () => {
        if (statusTo === "reconnect" && connectionType === "Externally") {
            if (cpData?.carrier.name === "AT&T") {
                if (reconnectRestoreReason !== "") {
                    setIsConfirmationType(true);
                } else {
                    setReconnectionRestoreRequired(true);
                }
            } else {
                setIsConfirmationType(true);
            }
        } else if (statusTo === "suspended" && connectionType === "Externally") {
            if (cpData?.carrier?.name === "AT&T") {
                if (suspendReason !== "") {
                    setIsConfirmationType(true);
                } else {
                    setSuspendReasonError(true);
                }
            } else {
                setIsConfirmationType(true);
            }
        } else if (statusTo === "") {
        } else {
            setIsConfirmationType(true);
        }
    };
    return (
        <div className="status-main flex flex-wrap flex-row justify-content-center">
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>

            {isConfirmationType && (
                <StatusConfirmation
                    toast={toast}
                    setReconnectionRestoreRequired={setReconnectionRestoreRequired}
                    reconnectRestoreReason={reconnectRestoreReason}
                    setReconnectRestore={setReconnectRestore}
                    suspendReason={suspendReason}
                    setSuspendReasonError={setSuspendReasonError}
                    setIsLoading={setIsLoading}
                    connectionType={connectionType}
                    isLoading={isLoading}
                    setRefreshCustomerData={setRefreshCustomerData}
                    statusTo={statusTo}
                    cpData={cpData}
                    setIsConfirmationType={setIsConfirmationType}
                    setChangeCustomerStatus={setChangeCustomerStatus}
                />
            )}
            {!isConfirmationType && (
                <div style={{ alignItems: "flex-start" }} className="status-main-container">
                    <label className="top-header">Change Account Status</label>
                    <p className="below-title">
                        Current status: "
                        {cpData?.status === "prospect"
                            ? "Prospect"
                            : cpData.status === "disconnected"
                              ? "Disconnected"
                              : cpData.status === "active"
                                ? "Active"
                                : cpData.status === "inActive"
                                  ? "InActive"
                                  : cpData.status === "rejected"
                                    ? "Rejected"
                                    : cpData.status === "cancelled"
                                      ? "Cancelled"
                                      : cpData.status === "suspended"
                                        ? "Suspended"
                                        : ""}
                        ." Update the enrollment status to proceed.
                    </p>
                    <div className="status-drop-main-container">
                        {cpData?.status === "cancelled" && (
                            <div className="dropdown-container-change-customer">
                                <label>Change Status</label>
                                <Dropdown
                                    value={statusTo}
                                    onChange={(e) => {
                                        setStatusTo(e.value);
                                    }}
                                    options={cancelledStatusOption}
                                    className="select-change-account-status"
                                    placeholder="Select Status"
                                />
                            </div>
                        )}
                        {cpData?.status === "prospect" && (
                            <div className="dropdown-container-change-customer">
                                <label>Change Status</label>
                                <Dropdown
                                    value={statusTo}
                                    onChange={(e) => {
                                        setStatusTo(e.value);
                                    }}
                                    options={prospectStatusOptions}
                                    className="select-change-account-status"
                                    placeholder="Select Status"
                                />
                            </div>
                        )}
                        {cpData?.status === "active" && (
                            <>
                                {/* <div className="dropdown-container-change-customer">
                                <label>Change Status</label>
                                <Dropdown
                                    value={statusTo}
                                    onChange={(e) => {
                                        setStatusTo(e.value);
                                    }}
                                    options={prospectStatusOptions}
                                    className="select-change-account-status"
                                    placeholder="Select Status"
                                />
                            </div> */}
                                <div className="dropdown-container-change-customer">
                                    <label>Account Status To</label>
                                    <Dropdown
                                        value={statusTo}
                                        onChange={(e) => {
                                            setStatusTo(e.value);
                                        }}
                                        options={activeStatusOptions}
                                        className="select-change-account-status"
                                        placeholder="Select Status"
                                    />
                                </div>
                            </>
                        )}
                        {cpData?.status === "labelCreated" && (
                            <div className="dropdown-container-change-customer">
                                <label>Account Status To</label>
                                <Dropdown
                                    value={statusTo}
                                    onChange={(e) => {
                                        setStatusTo(e.value);
                                    }}
                                    options={suspendStatusOptions}
                                    className="select-change-acccount-status"
                                    placeholder="Select Status"
                                />
                            </div>
                        )}
                        {cpData?.status === "suspended" && (
                            <div className="dropdown-container-change-customer">
                                <label>Account Status To</label>
                                <Dropdown
                                    value={statusTo}
                                    onChange={(e) => {
                                        setStatusTo(e.value);
                                    }}
                                    options={suspendStatusOptions}
                                    className="select-change-account-status"
                                    placeholder="Select Status"
                                />
                            </div>
                        )}
                        {cpData?.status === "disconnected" && (
                            <div className="dropdown-container-change-customer">
                                <label>Account Status To</label>
                                <Dropdown
                                    value={statusTo}
                                    onChange={(e) => {
                                        setStatusTo(e.value);
                                    }}
                                    options={disconnectStatusOptions}
                                    className="select-change-account-status"
                                    placeholder="Select Status"
                                />
                            </div>
                        )}
                        {statusTo === "disconnected" ? (
                            <>
                                <div className="dropdown-container-change-customer">
                                    <label>
                                        Reason <span className="steric">*</span>
                                    </label>
                                    <Dropdown
                                        value={disconnectReason}
                                        onChange={(e) => {
                                            setdisconnectReason(e.value);
                                            setdisconnectReasonError(false);
                                        }}
                                        options={cpData?.carrier?.name === "AT&T" ? ATTDisconnect : transferExceptionOption}
                                        placeholder="Select Reason"
                                        className="select-change-account-status"
                                    />{" "}
                                    {discconectReasonEror ? <p className="p-error">Field Is Required</p> : ""}
                                </div>
                            </>
                        ) : undefined}
                        {statusTo === "reconnect" && cpData?.carrier?.name === "AT&T" ? (
                            <>
                                <div className="dropdown-container-change-customer">
                                    <label>
                                        Reason <span className="steric">*</span>
                                    </label>
                                    <Dropdown
                                        value={reconnectRestoreReason}
                                        onChange={(e) => {
                                            setReconnectRestore(e.value);
                                            setReconnectionRestoreRequired(false);
                                        }}
                                        options={cpData?.carrier?.name === "AT&T" ? ATTreconnecting_restoring : []}
                                        placeholder="Select Reason"
                                        className="select-change-account-status"
                                    />
                                    {reconnectionRestoreRequired ? <p className="p-error">Recconection Field Is Required</p> : ""}
                                </div>
                            </>
                        ) : undefined}

                        {statusTo === "suspended" && cpData?.carrier?.name === "AT&T" ? (
                            <>
                                <div className="dropdown-container-change-customer">
                                    <label className="block  font-semibold mt-4">
                                        Reason<span className="steric">*</span>
                                    </label>
                                    <Dropdown
                                        value={suspendReason}
                                        onChange={(e) => {
                                            setSuspendReason(e.value);
                                            setSuspendReasonError(false);
                                        }}
                                        options={cpData?.carrier?.name === "AT&T" ? ATTsuspendreasons : []}
                                        placeholder="Select Reason"
                                        className="select-change-account-status"
                                    />
                                    {suspendReasonError ? <p className="p-error">Suspending Field Is Required</p> : ""}
                                </div>
                            </>
                        ) : undefined}
                        {cpData?.status === "suspended" && statusTo === "active" && cpData?.carrier?.name === "AT&T" ? (
                            <>
                                <div className="dropdown-container-change-customer">
                                    <label>
                                        Reason <span className="steric">*</span>
                                    </label>
                                    <Dropdown
                                        value={reconnectRestoreReason}
                                        onChange={(e) => {
                                            setReconnectRestore(e.value);
                                            setReconnectionRestoreRequired(false);
                                        }}
                                        options={cpData?.carrier?.name === "AT&T" ? ATTreconnecting_restoring : []}
                                        placeholder="Select Activation Reason"
                                        className="select-change-account-status"
                                    />

                                    {reconnectionRestoreRequired ? <p className="p-error">Activation Field Is Required</p> : ""}
                                </div>
                            </>
                        ) : undefined}

                        {/* <div className="dropdown-container-change-customer">
                        <label>Connection Type</label>
                        <Dropdown
                            className="select-change-account-status"
                            value={connectionType}
                            onChange={(e) => {
                                setConnectionType(e.value);
                            }}
                            options={statusTo === "suspended" && cpData?.status === "active" ? connectionExternally : connectionTypeOption}
                            placeholder="Select Account Type"
                        />
                    </div> */}
                        <div className="w-full flex flex-wrap flex-row justify-content-end  button-change-status">
                            <Button
                                onClick={() => {
                                    setChangeCustomerStatus(false);
                                }}
                                label="Cancel"
                                disabled={isLoading}
                                icon={isLoading ? "pi pi-spin pi-spinner " : ""}
                            />

                            <Button onClick={UpdateStatus} label="Submit " disabled={isLoading} icon={isLoading ? "pi pi-spin pi-spinner " : ""} />
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};
