import { api } from "../../../../../../utility/AxiosInceptor";
import React, { useEffect, useState } from "react";
import "./css/add_billingmodel.css";
import { useRef } from "react";
import AddBillingModelNew from "./AddBillingModel/AddBillingModel";
import { Toast } from "primereact/toast";
import Axios from "axios";
import AddInventoryAgainstBillingModel from "./addbillingmodelinventory/AddBillingModelnventory";
import { Dialog } from "primereact/dialog";

const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function AddBillingModel({ setRefresh, setAddBillingModelVisibility }) {
    const [selectedBillingModel, setSelectedBillingModel] = useState("");
    const [billinModelSelectdError, setBillingModelSelectedError] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const [refreshBillingModel, setRefreshBillingModel] = useState(false);
    const parseLoginRes = JSON.parse(loginRes);
    const [addBillingModelDialog, setAddBillingModelDialog] = useState(false);
    const [billingModelList, setBillingModelList] = useState([]);
    const toast = useRef(null);
    useEffect(() => {
        api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setBillingModelList(res?.data?.data);
                //setLoader(false);
            })
            .catch((err) => {});
    }, [refreshBillingModel]);

    return (
        <div className="flex flex-wrap  justify-content-around flex-row">
            <AddInventoryAgainstBillingModel selectedBillingModel={selectedBillingModel} setBillingModelSelectedError={setBillingModelSelectedError} setAddBillingModelVisibility={setAddBillingModelVisibility} />
            <Toast ref={toast} />
            <Dialog
                visible={addBillingModelDialog}
                onHide={() => {
                    setAddBillingModelDialog(false);
                }}
                header="Add Billing Model"
            >
                <AddBillingModelNew setAddBillingModelDialog={setAddBillingModelDialog} setRefreshBillingModel={setRefreshBillingModel} />
            </Dialog>
        </div>
    );
}
