import moment from "moment";
import { NotesCommonCustomerProfile } from "../CommonComponent/Notes/MainNotesCustomerProfile";
import "./OtherInfo.css";
import React from "react";
export const OtherInfoCustomerProfileMain = ({ cpData }) => {
    return (
        <div className="other-info-wrapper">
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Tracking Number</p>
                <p>{cpData?.trackingNumber || "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Wallet Balance</p>
                <p>{cpData?.wallet || "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Order By</p>
                <p>{cpData?.AcptoPrepaid === undefined ? cpData?.createdBy?.name || "NIL" : "System Imported"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Account ID</p>
                <p>{cpData?.accountId || "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Enrollment ID</p>
                <p>{cpData?.enrollmentId || "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>PWG Customer ID</p>
                <p>{cpData?.customerId || "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Role</p>
                <p>{cpData?.source || "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p> {cpData?.AcptoPrepaid !== undefined ? (cpData?.AcptoPrepaid ? "Converted To Prepaid On" : "Order Create Date") : "Order Create Date"}</p>
                <p>
                    {cpData?.AcptoPrepaid !== undefined
                        ? cpData?.AcptoPrepaid
                            ? cpData?.convertToPrepaidDate
                                ? moment(cpData.convertToPrepaidDate).format("YYYY-MM-DD")
                                : "NIL"
                            : cpData?.orderCreateDate
                              ? moment(cpData.orderCreateDate).format("YYYY-MM-DD")
                              : "NIL"
                        : cpData?.orderCreateDate
                          ? moment(cpData.orderCreateDate).format("YYYY-MM-DD")
                          : "NIL"}
                </p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Activation Date</p>
                <p>{cpData?.activatedAt ? moment.utc(cpData.activatedAt).tz("America/New_York").format("YYYY-MM-DD [at] HH:mm") : "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Disconnection Date</p>
                <p>{cpData?.disconnectionDate ? moment.utc(cpData.disconnectionDate).tz("America/New_York").format("YYYY-MM-DD [at] HH:mm") : "NIL"}</p>
            </div>
            <div className="other-info-detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Disconnection Reason</p>
                <p>{cpData?.disconnectionReason || "NIL"}</p>
            </div>
        </div>
    );
};
