import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import Axios from "axios";
import { api } from "../../../../../../utility/AxiosInceptor";
import moment from "moment";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Carrier-Chart.css";
import CommonLoaderBlue from "../../../../../components/Loader/LoaderBlue";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const CarrierChart = ({ currentModuleSelect, allEnrollmentsCount, setAllEnrollmentsCount, setAllChartData, allEnrollments, shortSelection, selectedRange }) => {
    const [carrierData, setCarrierData] = useState([]);
    const [selectedCarrierId, setSelectedCarrierId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentSelect, setCurrentSelect] = useState("at&t");
    const [chartLabels, setChartLabels] = useState([]);
    const [dataChart, setDataChart] = useState([]);

    useEffect(() => {
        api.get(`/api/web/carrier/all`)
            .then((res) => {
                const defaultCarrier = res?.data?.data.find((carrier) => carrier?.name === "AT&T" || carrier?.name === "TMB") || res?.data?.data[0];
                setCarrierData(res?.data?.data);
                if (defaultCarrier) {
                    setCurrentSelect(defaultCarrier.name.toLowerCase());
                    setSelectedCarrierId(defaultCarrier._id);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const chartRef = useRef(null);
    const [fontSize, setFontSize] = useState(12);
    const [barWidth, setBarWidth] = useState(24);

    const getFontSize = () => {
        if (window.innerHeight < 639) return 8;
        if (window.innerHeight < 800) return 10;
        return 12;
    };

    const getBarWidth = () => {
        return window.innerHeight < 800 ? 18 : 24;
    };

    useEffect(() => {
        const updateDimensions = () => {
            setFontSize(getFontSize());
            setBarWidth(getBarWidth());
        };
        window.addEventListener("resize", updateDimensions);
        updateDimensions();
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    const fetchDataAndInitializeChart = () => {
        let accountType = [];
        if (currentModuleSelect.prepaid) {
            accountType.push("Prepaid");
        }
        if (currentModuleSelect.postpaid) {
            accountType.push("Postpaid");
        }
        if (currentModuleSelect.acp) {
            accountType.push("Acp");
        }
        accountType = accountType.join(",");
        api.get(`/api/web/dashboard/enrollmentStatsByStatus?accountType=${accountType}`, {
            params: {
                startDate: moment(selectedRange[0])?.isValid() ? moment(selectedRange[0])?.format("MM-DD-YYYY") : null,
                endData: moment(selectedRange[1])?.isValid() ? moment(selectedRange[1])?.format("MM-DD-YYYY") : null,
            },
        })
            .then((res) => {
                const labels = [];
                const data = [];
                Object.keys(res?.data?.result?.statsByStatus).map((key) => {
                    if (key === "active") {
                        labels.push("Active");
                        data.push(res.data.result.statsByStatus[key]);
                    } else if (key === "suspended") {
                        labels.push("Suspended");
                        data.push(res.data.result.statsByStatus[key]);
                    }
                    if (key === "prospect") {
                        labels.push("Prospect");
                        data.push(res.data.result.statsByStatus[key]);
                    }
                    if (key === "disconnected") {
                        labels.push("Disconnected");
                        data.push(res.data.result.statsByStatus[key]);
                    }
                    if (key === "suspect") {
                        labels.push("Suspect");
                        data.push(res.data.result.statsByStatus[key]);
                    }
                });
                console.log("data is", data, labels);
                setAllChartData(res.data.result.statsByStatus);
                setAllEnrollmentsCount(res?.data?.result?.totalEnrollments);
                setChartLabels(labels);
                setDataChart(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            })
            .catch((err) => {
                console.error(err);
                if (err?.response?.data?.msg === "No customers found.") {
                    setChartLabels([]);
                    setDataChart([]);
                }
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchDataAndInitializeChart();
    }, [selectedCarrierId, shortSelection, currentModuleSelect, selectedRange]);

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: "Enrollment",
                data: dataChart,
                backgroundColor: ["#27AE60", "#4A90E2", "#F39C12", "#E74C3C", "#95A5A6"],
                borderRadius: 8,
                borderSkipped: false,
                barThickness: 80,

                roundCap: false,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 20, // Top padding
                right: 0, // Right padding
                bottom: 0, // Bottom padding
                left: 0, // Left padding
            },
            margin: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
        },
        backgroundColor: "#FFFFFF",
        scales: {
            x: {
                grid: { display: false, drawBorder: false },
                border: false,
                ticks: {
                    font: { size: 12, family: "Inter", weight: 500, color: "#72728F" },

                    maxRotation: 0, // Disable rotation
                    minRotation: 0, // Disable rotation
                    padding: 0,
                    autoSkip: true, // Enable auto-skipping of labels
                },
                offset: true,
            },
            y: {
                grid: { display: false, drawBorder: false },
                display: false,
                border: false,
                ticks: {
                    font: { size: 12, family: "Inter", weight: 500, color: "#72728F" },
                },
            },
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                enabled: true,
                titleFont: {
                    family: "Inter", // Set font family for tooltip title
                    size: 14, // Font size for title
                    weight: "600", // Font weight for title
                },
                bodyFont: {
                    family: "Inter", // Set font family for tooltip body
                    size: 13, // Font size for body
                    weight: "500",
                },
                footerFont: {
                    family: "Inter", // Set font family for tooltip footer
                    size: 12, // Font size for footer
                },
                callbacks: { label: (context) => `Customers ${context.raw}` },
            },
            datalabels: {
                anchor: "end",
                align: "end",
                offset: -2,
                color: "#8B8BA7",
                font: { family: "Space Grotesk", size: 14, weight: "700" },
                formatter: (value) => value,
            },
        },
    };

    return (
        <div className="carrier-chart-main">
            <h1>
                Total Enrollments &nbsp;&nbsp;&nbsp;<span>{allEnrollmentsCount}</span>
            </h1>
            <div className="carrier-chart-main-container">
                {isLoading ? (
                    <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">
                        <div style={{ marginTop: "19px" }}>
                            <CommonLoaderBlue sizehere={40} color={"rgb(78, 105, 251)"} />
                        </div>
                    </div>
                ) : (
                    <Bar ref={chartRef} data={data} options={options} />
                )}
            </div>
        </div>
    );
};

export default CarrierChart;
