import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import "./css/Adhocinvoice.css";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { Button } from "primereact/button";
import AddType from "./components/AddType/AddType";
import CommonLoaderBlue from "../../../../../../../../components/Loader/LoaderBlue";
export const AddAdhocinvoice = ({ cpData, setCurrentActiveTab }) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const toast = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const [validationSchema, setValidationSchema] = useState(
        Yup.object().shape({
            // invoiceAmount: Yup.string().required("Please Select Invoice Amount"),
            invoiceType: Yup.string().required("Please select Invoice Type"),
            amount: Yup.string().required("Please select Amount"),
            // isTax: Yup.string().required("Please select isTax"),
            // taxAmount: Yup.string().required("Please Enter Tax Amount"),
            // taxBreakup: Yup.string().required("Please Enter Tax Breakup"),
            dueDate: Yup.string().required("Please Enter Due Date"),
        }),
    );
    const formik = useFormik({
        initialValues: {
            invoiceType: "",
            amount: "",
            dueDate: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const formatDate = (date) => {
                // Get month, day, and year from the date object
                const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero based
                const day = ("0" + date.getDate()).slice(-2);
                const year = date.getFullYear();
                // Return formatted date string in "mm-dd-yyyy" format
                return `${month}-${day}-${year}`;
            };

            const dataToSend = {
                userId: userData?._id,
                enrollmentId: cpData?.enrollmentId,
                accountId: cpData?.accountId,
                invoiceOneTimeCharges: formik.values.amount,
                totalAmount: formik.values.amount,
                invoiceOneTimeCharges: formik.values.amount,
                includeTaxes: false,
                invoicetype: formik.values.invoiceType,
                invoiceDueDate: formatDate(formik.values.dueDate),
                amountPaid: "0",
                invoicePaymentMethod: "Skip",
            };

            setIsLoading(true);
            api.post(`/api/web/invoices/adHocInvoiceGeneration`, dataToSend)
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Info", detail: res?.data?.messsage || "Invoice Generated Successfully" });
                    setIsLoading(false);
                    setTimeout(() => {
                        setCurrentActiveTab("invoices");
                    }, 500);
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Info", detail: err?.res?.data?.messsage || "Invoice Generation Failed" });
                    setIsLoading(false);
                });
        },
    });
    const [refreshInvoiceTypes, setRefershInvoiceTypes] = useState(false);
    const [allInvoiceType, setAllInvoiceType] = useState([]);
    const [isAddTypeActive, setIsAddTypeActive] = useState(false);
    useEffect(() => {
        api.get(`/api/web/invoiceType/all?serviceProvider=${userData.company}`)
            .then((res) => {
                setAllInvoiceType(res?.data?.data || []);
                console.log(res?.data?.data);
            })
            .catch((err) => {});
    }, [refreshInvoiceTypes]);
    return (
        <div style={{ width: "108.4rem" }} className="flex flex-wrap flex-row justify-content-center ">
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
            <div className="AddAdhoc-main">
                <div className="adhoc-topheader">
                    <h1>Add AD HOC Invoice</h1>
                    <p>Create instant AD Hoc Invoices for quick payments</p>
                </div>
                <div className="adhoc-customer-details">
                    <div className="adhoc-customer-details-item">
                        <p>Customer</p>
                        <p>{cpData?.firstName && cpData?.lastName ? cpData?.firstName + " " + cpData?.lastName : cpData?.firstName ? cpData?.firstName : ""}</p>
                    </div>
                    <div className="adhoc-customer-details-item">
                        <p>Customer ID</p>
                        <p>{cpData?.enrollmentId || ""}</p>
                    </div>
                    <div className="adhoc-customer-details-item">
                        <p>Telephone Number</p>
                        <p>{cpData?.contact || ""}</p>
                    </div>
                    <div className="adhoc-customer-details-item">
                        <p>Address</p>
                        <p>{cpData?.address1 || ""}</p>
                    </div>
                </div>
                <div className="addadhoc-field-main">
                    {!isAddTypeActive && (
                        <div className="addtype-link">
                            <p
                                onClick={() => {
                                    setIsAddTypeActive(true);
                                }}
                            >
                                Add Type
                            </p>
                        </div>
                    )}
                    {isAddTypeActive && <AddType setRefershInvoiceTypes={setRefershInvoiceTypes} setIsAddTypeActive={setIsAddTypeActive} />}
                    <div className="Addadhoc-field-container">
                        <div className="input-container-addhoc">
                            <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                                <label>Select Type</label>
                                {formik.touched.invoiceType && formik.errors.invoiceType && <label>{formik.errors.invoiceType}</label>}
                            </div>
                            <Dropdown
                                optionLabel="typeName"
                                className={`main-drop ${formik.touched.amount && formik.errors.amount && "p-invalid"}`}
                                optionValue="typeName"
                                options={allInvoiceType}
                                name="invoiceType"
                                value={formik.values.invoiceType}
                                onChange={formik.handleChange}
                                placeholder="Invoice Type"
                            />
                        </div>
                        <div className="input-container-addhoc">
                            <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                                <label>Amount</label>

                                {formik.touched.amount && formik.errors.amount && <label>{formik.errors.amount}</label>}
                            </div>
                            <input className={`${formik.touched.amount && formik.errors.amount && "p-invalid"}`} name="amount" value={formik.values.amount} type="number" onChange={formik.handleChange} placeholder="$00.00" />
                        </div>
                        <div className="input-container-addhoc">
                            <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                                <label>Due Date</label>

                                {formik.touched.dueDate && formik.errors.dueDate && <label>{formik.errors.dueDate}</label>}
                            </div>
                            <Calendar name="dueDate" value={formik.values.dueDate} className={`main-drop main-calendar ${formik.touched.dueDate && formik.errors.dueDate && "p-invalid"}`} onChange={formik.handleChange} placeholder="MM/DD/YYYY" />
                        </div>
                    </div>
                </div>
                <div className="action-buttons-container flex flex-wrap flex-row justify-content-end">
                    <Button
                        disabled={isLoading}
                        onClick={() => {
                            setCurrentActiveTab("invoices");
                        }}
                        label="Cancel"
                    />
                    <Button
                        disabled={isLoading}
                        onClick={formik.handleSubmit}
                        label={
                            isLoading ? (
                                <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                    <CommonLoaderBlue sizehere={12} color={"white"} />
                                </div>
                            ) : (
                                "Submit"
                            )
                        }
                    />
                </div>
            </div>
            <Toast ref={toast} />
        </div>
    );
};
