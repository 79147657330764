import React, { useState, useRef } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "../loader/LoaderBlue";
import CommonLoader from "../loader/Loader";
export default function Square({
    parentDetails,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
}) {
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [isLoading, setIsLoading] = useState(false);
    const parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const [card, setcard] = useState();
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const toast = useRef(null);
    async function initializeCard(payments) {
        const card = await payments.card();
        await card.attach("#card-container");
        return card;
    }
    async function handlePaymentMethodSubmission(event, paymentMethod) {
        event.preventDefault();

        try {
            // disable the submit button as we await tokenization and make a
            // payment request.

            const token = await tokenize(paymentMethod);
            console.log("token is", token);
            //const paymentResults = await createPayment(token);
            let paymentResults;
            displayPaymentResults("SUCCESS");

            console.log("Payment Success", paymentResults);

            toast.current.show({ severity: "success", summary: "Payment Processed", detail: <p>Payment Proceed Successfully</p> });

            setPaymentDone(true);
            //setActiveIndex(3);
            let additionalFeature = [];
            let discounts = [];
            let dueDate = "";
            let applyLateFee = "";
            let oneTimeCharge = "";
            let planName = "";
            let planId = "";
            let planCharges = "";
            dueDate = currentBillingConfig?.dueDate;
            oneTimeCharge = currentBillingConfig?.oneTimeCharge;
            applyLateFee = currentBillingConfig?.applyLateFee;
            let selectedDiscounts = paymentScreenDetails.discount;

            for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                for (let k = 0; k < selectedDiscounts.length; k++) {
                    if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                        let obj = {
                            name: currentBillingConfig?.selectdiscount[i]?.discountname,
                            amount: currentBillingConfig?.selectdiscount[i]?.amount,
                        };
                        discounts.push(obj);
                    }
                }
            }
            for (let i = 0; i < allplans?.length; i++) {
                if (object.plan === allplans[i]?._id) {
                    planName = allplans[i]?.name;
                    planCharges = allplans[i]?.price;

                    planId = allplans[i]?._id;
                }
            }

            let additionalFeatures = paymentScreenDetails?.additional;
            for (let k = 0; k < additionalFeatures?.length; k++) {
                for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                    if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                        let obj = {
                            name: currentBillingConfig?.additionalFeature[i]?.featureName,
                            amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                        };
                        additionalFeature.push(obj);
                    }
                }
            }

            let plan = object?.plan;
            // let dateincasepart
            //object.totalAmount === paid ? dueDate:"Partial"
            const loginRes = localStorage.getItem("userData");
            const parseLoginRes = JSON.parse(loginRes);
            const shipmentName = localStorage.getItem("shipmentName");
            const shipmentCost = localStorage.getItem("shipmentCost");
            let dataToSend = {
                paymentId: "",
                isAutopay: false,
                customerId: object.customerid,
                invoiceType: "Sign Up",
                totalAmount: object.totalamount,
                additionalCharges: additionalFeature,
                discount: discounts,
                amountPaid: paid,
                selectProduct: object?.billId,
                invoiceDueDate: dueDate,
                isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                lateFee: applyLateFee,
                invoiceOneTimeCharges: oneTimeCharge,
                invoiceStatus: object.totalamount <= paid ? "Paid" : "Partial",
                planId: plan,
                planName: planName,
                planCharges: planCharges,
                chargingType: "monthly",
                invoicePaymentMethod: object?.paymentMode,
                printSetting: "Both",
                isWithInvoice: true,

                isInvoice: true,
                userId: parseLoginRes?._id,
                billingPeriod: {
                    from: "onActivation",
                    to: "onActivation",
                },
                isShipmentCostDiscounted: checkLabel,
                shipmentCost: {
                    name: shipmentName,
                    amount: shipmentCost,
                },
            };

            setDataSendForInvoice(dataToSend);

            api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                .then((response) => {
                    api.post(`/api/web/billing/updatePaymentLog`, {
                        customerId: initiationData?._id,
                        paymentMethod: "Credit Card",
                        stripeId: paymentResults?.payment?.id,
                        action: "success",
                        reason: "success",
                        transactionStatus: paymentResults?.payment?.status,
                        paymentStatus: "success",
                        invoiceNo: response?.data?.data?.invoice,
                        amount: paid,
                        userId: parseLoginRes?._id,
                    })
                        .then((res) => {
                            toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                            setTimeout(() => {
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            }, 500);
                        })
                        .catch((err) => {
                            toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                            setTimeout(() => {
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            }, 500);
                        });
                })
                .catch((err) => {});
        } catch (e) {
            displayPaymentResults("FAILURE");
            console.error(e.message);

            api.post(`/api/web/billing/updatePaymentLog`, {
                customerId: initiationData?._id,
                paymentMethod: "Credit Card",
                stripeId: "",
                invoiceType: "Sign Up",
                action: "failure",
                invoiceNo: [],
                reason: "failure",
                cardNumber: "",
                transactionStatus: "",
                paymentStatus: "failure",
                amount: paid,
                userId: parseLoginRes?._id,
            })
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
            toast.current.show({ severity: "error", summary: "Payment Processing", detail: "An error occurred while processing the payment" });

            setTimeout(() => {
                setPaymentDialogVisibility(false);
            }, 2000);
        }
    }
    // Call this function to send a payment token, buyer name, and other details
    // to the project server code so that a payment can be created with
    // Payments API
    async function createPayment(token) {
        const body = JSON.stringify({
            amount: Math.round(parseFloat(paid) * 100).toString(),
            token: token,
        });
        const paymentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/sq-process-payment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body,
        });
        if (paymentResponse.ok) {
            return paymentResponse.json();
        }
        const errorBody = await paymentResponse.text();
        throw new Error(errorBody);
    }

    // This function tokenizes a payment method.
    // The ‘error’ thrown from this async function denotes a failed tokenization,
    // which is due to buyer error (such as an expired card). It's up to the
    // developer to handle the error and provide the buyer the chance to fix
    // their mistakes.
    async function tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === "OK") {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }
            throw new Error(errorMessage);
        }
    }

    // Helper method for displaying the Payment Status on the screen.
    // status is either SUCCESS or FAILURE;
    function displayPaymentResults(status) {
        const statusContainer = document.getElementById("payment-status-container");
        if (status === "SUCCESS") {
            statusContainer.classList.remove("is-failure");
            statusContainer.classList.add("is-success");
        } else {
            statusContainer.classList.remove("is-success");
            statusContainer.classList.add("is-failure");
        }

        statusContainer.style.visibility = "visible";
    }

    useEffect(() => {
        async function Loader() {
            if (!window.Square) {
                throw new Error("Square.js failed to load properly");
            }
            const payments = window.Square.payments(process.env.REACT_APP_SQUARE_APPLICATION_ID, process.env.REACT_APP_SQUARE_LOCATION_ID);

            try {
                setcard(await initializeCard(payments));
                setButtonDisabled(false);
            } catch (e) {
                console.error("Initializing Card failed", e);
                return;
            }
        }
        Loader();
        return () => {
            if (card) {
                card.detach();
            }
        };
    }, []);

    return (
        <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">
            <div style={{ width: "100%", overflow: "hidden" }}>
                <form id="payment-form">
                    <div id="card-container"></div>
                    <div className="flex flex-wrap flex-row justify-content-center">
                        <button
                            id="card-button"
                            style={{ background: "royalblue", cursor: "pointer", border: "none", padding: "8px", borderRadius: "4px", color: "white", paddingLeft: "10px", paddingRight: "10px", fontSize: "17px" }}
                            onClick={(e) => {
                                handlePaymentMethodSubmission(e, card);
                                setIsLoading(true);
                            }}
                            type="button"
                            disabled={buttonDisabled || isLoading}
                        >
                            {isLoading ? <CommonLoader /> : `Pay ${paid}`}
                        </button>
                    </div>
                </form>
            </div>
            <div id="payment-status-container"></div>
            <Toast ref={toast} />
        </div>
    );
}
