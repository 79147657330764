import React from "react";
import "./css/dashboard.css";
import { useState } from "react";
import Users from "./new-dashboard-components/Users/User";
import "./components/Area-Chart.css";
import CarrierEarningChart from "./components/Carrirer-Earning-Chart";
import TopCards from "./new-dashboard-components/top-cards/TopCards";
import WelcomeScreen from "./WelcomeScreen/WelcomeScreen";
const Dashboard = () => {
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [shortSelection, setShortSelection] = useState();
    const isAdmin = JSON.parse(localStorage.getItem("userData"))?.role?.role?.toLowerCase();
    return (
        <>
            {" "}
            {isAdmin === "admin" ? (
                <div style={{ padding: "2.4rem" }}>
                    <TopCards />
                    <CarrierEarningChart setSelectedRange={setSelectedRange} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} />
                    <Users />
                </div>
            ) : (
                <WelcomeScreen />
            )}
        </>
    );
};
export default Dashboard;
