import React, { useState } from "react";
import "./css/wallet.css";
import { NotesCommonCustomerProfile } from "../CommonComponent/Notes/MainNotesCustomerProfile";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { WalletStripeCardPayment } from "./component/StripeCardPayment/StripeCardpayment";
export const Wallet = ({ setRefreshCustomerData, cpData }) => {
    const [validationSchema, setValidationSchema] = useState(
        Yup.object().shape({
            amount: Yup.number().required("Field Is Required"),
            paymentMethod: Yup.string().required("Field Is Required"),
        }),
    );
    const [showPaymentMethodError, setShowPaymentMethodError] = useState(false);

    const [showAmountError, setShowAmountError] = useState(false);

    const formik = useFormik({
        initialValues: {
            amount: "",
            paymentMethod: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {},
    });
    const [refreshNotes, setRefreshNotes] = useState(false);
    return (
        <div className="main-wallet-container">
            <div className="wallet-wrapper">
                <div className="wallet-top-header">
                    <p>Wallet</p>
                    <p>Add funds to the client wallet by entering the amount and card details.</p>
                </div>
                <div className="wallet-customer-details">
                    <div className="wallet-customer-item">
                        <p>Customer ID</p>
                        <p>{cpData?.enrollmentId || "NIL"}</p>
                    </div>
                    <div className="wallet-customer-item">
                        <p>Customer Name</p>
                        <p>{cpData?.firstName ? (cpData?.lastName ? cpData?.firstName + " " + cpData?.lastName : cpData?.firstName) : "NIL"}</p>
                    </div>
                    <div className="wallet-customer-item">
                        <p>Phone Number</p>
                        <p>{cpData?.contact ? cpData?.contact : "NIL"}</p>
                    </div>
                    <div className="wallet-customer-item">
                        <p>Wallet Amount</p>
                        <p>{cpData?.wallet && cpData?.wallet !== "" ? `${parseFloat(cpData?.wallet).toFixed(2)}$` : "NIL"}</p>
                    </div>
                </div>
                <div className="wallet-card-details">
                    <div className="card-detail-main">
                        <p className="card-detail-header">Card Details</p>

                        <div className="card-detail-inputcontainer">
                            <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                                <label>Amount</label>
                                {showAmountError && <label>Field Is Required</label>}
                            </div>
                            <input
                                name="amount"
                                type="number"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    if (e.target.value === "" || e.target.value === 0 || e.target.value === undefined) {
                                        setShowAmountError(true);
                                    } else {
                                        setShowAmountError(false);
                                    }
                                }}
                                className={`main-drop ${showAmountError && "p-invalid"}`}
                                placeholder="Amount"
                                value={formik.values.amount}
                            />
                        </div>
                        <div className="card-detail-inputcontainer">
                            <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                                <label>Payment Method</label>
                                {showPaymentMethodError && <label>Field Is Required</label>}
                            </div>
                            <Dropdown
                                options={[{ label: "Credit Card", value: "Credit Card" }]}
                                name="paymentMethod"
                                onChange={(e) => {
                                    formik.handleChange(e);

                                    setShowPaymentMethodError(false);
                                }}
                                className={`main-drop ${showPaymentMethodError && "p-invalid"}`}
                                placeholder="Payment Method"
                                value={formik.values.paymentMethod}
                            />
                        </div>
                    </div>
                </div>

                {formik.values.paymentMethod === "Credit Card" && <WalletStripeCardPayment setRefreshCustomerData={setRefreshCustomerData} setRefreshNotes={setRefreshNotes} setShowAmountError={setShowAmountError} amount={formik.values.amount} cpData={cpData} />}
                {formik.values.paymentMethod !== "Credit Card" && (
                    <div className="wallet-pay-button flex flex-wrap flex-row justify-content-end align-items-center">
                        <Button
                            onClick={() => {
                                if (formik.values.amount === "" || formik.values.amount === undefined || formik.values.amount === 0) {
                                    setShowAmountError(true);
                                }
                                if (formik.values.paymentMethod === "") {
                                    setShowPaymentMethodError(true);
                                }
                            }}
                            label="Pay Now "
                        />
                    </div>
                )}
            </div>
            <div className="wallet-notes-container">
                <NotesCommonCustomerProfile refreshNotes={refreshNotes} cpData={cpData} />
            </div>
        </div>
    );
};
