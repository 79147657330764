import React, { useRef, useState } from "react";
import "./css/addBillingModelInventory.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../../../../../../utility/AxiosInceptor";
import { useEffect } from "react";
import Axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Backbtn } from "../../../../../../../../utility";
export default function AddInventoryAgainstBillingmode({ setRefresh, setAddInventory, billingModelSelect }) {
    const [imgsrc, setimgsrc] = useState();
    const [selectedBillingModel, setSelectedBillingModel] = useState("");
    let toast = useRef();
    const [loader, setLoader] = useState(false);
    const Imgref = useRef();
    const parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const validationSchema = Yup.object().shape({
        billingModel: selectedBillingModel,
        make: Yup.string()
            .required("This is required")
            .matches(/^[^-]*$/, "Hyphens are not allowed"),
        inventoryType: Yup.string().required("This is Required"),
        model: Yup.string()
            .required("This is required")
            .matches(/^[^-]*$/, "Hyphens are not allowed"),
        length: Yup.number().required("This Is Required"),
        width: Yup.number().required("This Is Required"),
        weight: Yup.string()
            .required("This Is Required")
            .matches(/^\d+(\.\d{2})?$/, "Requires 2 decimals"),
        ShipFromaddress1: Yup.string().required("This is Required"),
        height: Yup.number().required("This is Required"),
        identifierType: Yup.string().required("This is Required"),
        city: Yup.string().required("This is Required"),
        state: Yup.string().required("This is Required"),
        zipCode: Yup.number().required("This is Required"),
        costPrice: Yup.number().required("This is Required"),

        sellingPrice: Yup.number().required("This is Required"),

        profitMargin: Yup.number().required("This is Required"),
        discount: Yup.number().required("This is Required"),
        image: Yup.mixed().required("This is Required"),
        billingModel: Yup.string().required("This is Required"),
    });
    const [identifierList, setIdentifierList] = useState([]);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        api.get(`/api/identifier/getAll?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setIdentifierList(res?.data?.data);
            })
            .catch((err) => {});
    }, []);
    const formik = useFormik({
        initialValues: {
            make: "",
            model: "",
            inventoryType: "",
            length: "",
            width: "",
            weight: "",
            ShipFromaddress1: "",
            ShipFromaddress2: "",
            height: "",
            identifierType: "",
            city: "",
            state: "",
            zipCode: "",
            costPrice: "",
            sellingPrice: "",
            profitMargin: "",
            discount: "",
            image: "",
            billingModel: billingModelSelect,
            serviceProvider: parseLoginRes?.company,
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            setLoader(true);
            let formData = new FormData();
            Object.keys(formik.values).forEach((item) => {
                if (item === "weight") {
                    formData.append("weight.lbs", formik.values[item]);
                } else if (item === "ShipFromaddress1" || item === "city" || item === "state" || item === "zipCode" || item === "ShipFromaddress2") {
                    if (item === "ShipFromaddress1") {
                        formData.append("ShipFrom.address", formik.values[item]);
                    } else if (item === "city") {
                        formData.append("ShipFrom.city", formik.values[item]);
                    } else if (item === "state") {
                        formData.append("ShipFrom.state", formik.values[item]);
                    } else if (item === "zipCode") {
                        formData.append("ShipFrom.zipCode", formik.values[item]);
                    } else {
                        formData.append("ShipFrom.address2", formik.values[item]);
                    }
                } else {
                    formData.append(item, formik.values[item]);
                }
            });

            api.post(`/api/inventoryType/addInventory?serviceProvider=${parseLoginRes?.company}`, formData)
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Inventory Type", detail: "Inventory Type Submitted Successfully" });
                    setLoader(false);
                    // setTimeout(()=>{
                    //  setAddInventory(false)
                    // setRefresh(prev=>!prev)
                    //},500)
                    setAddInventory(false);
                    Imgref.current.value = "";
                    setimgsrc("");
                    resetForm();
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Inventory Type", detail: err?.response?.data?.msg });
                    setLoader(false);
                });
        },
    });
    const [billingModelList, setBillingModelList] = useState([]);
    useEffect(() => {
        if (formik.values.sellingPrice !== "" && formik.values.discount !== "" && formik.values.costPrice !== "") {
            formik.setFieldValue("profitMargin", formik.values.sellingPrice - formik.values.discount - formik.values.costPrice);
        }
    }, [formik.values.sellingPrice, formik.values.discount, formik.values.costPrice]);
    const [refreshBillingModel, setRefreshBillingModel] = useState(false);
    useEffect(() => {
        api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setBillingModelList(res?.data?.data);
                //setLoader(false);
            })
            .catch((err) => {});
    }, [refreshBillingModel]);
    return (
        <div className="inventorymain">
            <div className="inventoryinner">
                <div
                    onClick={() => {
                        setAddInventory(false);
                    }}
                >
                    <button className="backicon">
                        <i className="pi pi-angle-left" style={{ fontSize: "1.5rem", color: "#323232" }}></i>
                    </button>
                </div>
                <div className="flex flex-column inventoryaddheader">
                    <h1>Add Inventory</h1>
                    <p>Add and define inventory details to ensure accurate tracking and streamlined management across your system.</p>
                </div>

                <form onSubmit={formik.handleSubmit} className="mainAddInventory">
                    <div className="addbillingfield" style={{ marginTop: "2.4rem" }}>
                        <div className="flex justify-content-between" style={{ width: "30rem" }}>
                            <label>Billing Model </label>
                            {formik.touched.billingModel && formik.errors.billingModel ? <p className="p-error">{formik.errors.billingModel}</p> : undefined}
                        </div>
                        <Dropdown
                            className={formik.touched.billingModel && formik.errors.billingModel ? "error calendar_field" : "calendar_field"}
                            value={formik.values.billingModel}
                            name="billingModel"
                            options={billingModelList}
                            onChange={formik.handleChange}
                            optionLabel="billingModel"
                            optionValue="_id"
                            placeholder="Select Billing Model"
                        />
                    </div>
                    <div className="inventoryadd">
                        <h5>Inventory Details</h5>
                    </div>
                    <div className="flex">
                        <div className="addbillingfield">
                            <div className="flex justify-content-between">
                                <label>Inventory Type</label>
                                {formik.touched.inventoryType && formik.errors.inventoryType ? <p className="p-error">{formik.errors.inventoryType}</p> : undefined}
                            </div>
                            <InputText value={formik.values.inventoryType} name="inventoryType" onChange={formik.handleChange} className={formik.touched.inventoryType && formik.errors.inventoryType ? "error calendar_field" : "calendar_field"} placeholder="Inventory Type" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Make</label>
                                {formik.touched.make && formik.errors.make ? <p className="p-error">{formik.errors.make}</p> : undefined}
                            </div>
                            <InputText value={formik.values.make} name="make" onChange={formik.handleChange} className={formik.touched.make && formik.errors.make ? "error calendar_field" : "calendar_field"} placeholder="make" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Model</label>
                                {formik.touched.model && formik.errors.model ? <p className="p-error">{formik.errors.model}</p> : undefined}
                            </div>
                            <InputText value={formik.values.model} name="model" onChange={formik.handleChange} className={formik.touched.model && formik.errors.model ? "error calendar_field" : "calendar_field"} placeholder="model" />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="addbillingfield">
                            <div className="flex justify-content-between">
                                <label>Length (in)</label>
                                {formik.touched.length && formik.errors.length ? <p className="p-error">{formik.errors.length}</p> : undefined}
                            </div>
                            <InputText keyfilter="num" value={formik.values.length} name="length" onChange={formik.handleChange} className={formik.touched.length && formik.errors.length ? "error calendar_field" : "calendar_field"} placeholder="length" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Width (in)</label>
                                {formik.touched.width && formik.errors.width ? <p className="p-error">{formik.errors.width}</p> : undefined}
                            </div>
                            <InputText keyfilter="num" value={formik.values.width} name="width" onChange={formik.handleChange} className={formik.touched.width && formik.errors.width ? "error calendar_field" : "calendar_field"} placeholder="width" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Height (in)</label>
                                {formik.touched.height && formik.errors.height ? <p className="p-error">{formik.errors.height}</p> : undefined}
                            </div>
                            <InputText keyfilter="num" value={formik.values.height} name="height" onChange={formik.handleChange} placeholder="height" className={formik.touched.height && formik.errors.height ? "error calendar_field" : "calendar_field"} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="addbillingfield">
                            <div className="flex justify-content-between">
                                <label>Weight (lbs)</label>
                                {formik.touched.weight && formik.errors.weight ? <p className="p-error">{formik.errors.weight}</p> : undefined}
                            </div>
                            <InputText keyfilter="num" name="weight" value={formik.values.weight} onChange={formik.handleChange} className={formik.touched.weight && formik.errors.weight ? "error calendar_field" : "calendar_field"} placeholder="0.00 lbs" />
                        </div>

                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Identifier Type</label>
                                {formik.touched.identifierType && formik.errors.identifierType ? <p className="p-error">{formik.errors.identifierType}</p> : undefined}
                            </div>
                            <Dropdown
                                optionLabel="identifier"
                                optionValue="_id"
                                value={formik.values.identifierType}
                                name="identifierType"
                                onChange={formik.handleChange}
                                className={formik.touched.identifierType && formik.errors.identifierType ? "error calendar_field" : "calendar_field"}
                                options={identifierList}
                                placeholder="Identifier Type"
                            />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Ship From</label>
                                {formik.touched.ShipFromaddress1 && formik.errors.ShipFromaddress1 ? <p className="p-error">{formik.errors.ShipFromaddress1}</p> : undefined}
                            </div>
                            <InputText className={formik.touched.ShipFromaddress1 && formik.errors.ShipFromaddress1 ? "error calendar_field" : "calendar_field"} value={formik.values.ShipFromaddress1} name="ShipFromaddress1" onChange={formik.handleChange} placeholder="Street Address" />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="addbillingfield">
                            <div className="flex justify-content-between">
                                <label>Ship From (Address2)</label>
                                {formik.touched.ShipFromaddress2 && formik.errors.ShipFromaddress2 ? <p className="p-error">{formik.errors.ShipFromaddress2}</p> : undefined}
                            </div>
                            <InputText name="ShipFromaddress2" value={formik.values.ShipFromaddress2} onChange={formik.handleChange} className={formik.touched.ShipFromaddress2 && formik.errors.ShipFromaddress2 ? "error calendar_field" : "calendar_field"} placeholder="Address2" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>City</label>
                                {formik.touched.city && formik.errors.city ? <p className="p-error">{formik.errors.city}</p> : undefined}
                            </div>
                            <InputText className={formik.touched.city && formik.errors.city ? "error calendar_field" : "calendar_field"} value={formik.values.city} placeholder="city" name="city" onChange={formik.handleChange} />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>State</label>
                                {formik.touched.state && formik.errors.state ? <p className="p-error">{formik.errors.state}</p> : undefined}
                            </div>
                            <InputText name="state" value={formik.values.state} onChange={formik.handleChange} className={formik.touched.state && formik.errors.state ? "error calendar_field" : "calendar_field"} placeholder="state" />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="addbillingfield">
                            <div className="flex justify-content-between">
                                <label>Zip Code</label>
                                {formik.touched.zipCode && formik.errors.zipCode ? <p className="p-error">{formik.errors.zipCode}</p> : undefined}
                            </div>
                            <InputText keyfilter="int" name="zipCode" value={formik.values.zipCode} onChange={formik.handleChange} className={formik.touched.zipCode && formik.errors.zipCode ? "error calendar_field" : "calendar_field"} placeholder="Zip Code" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Cost Price</label>
                                {formik.touched.costPrice && formik.errors.costPrice ? <p className="p-error">{formik.errors.costPrice}</p> : undefined}
                            </div>
                            <InputText keyfilter="num" name="costPrice" value={formik.values.costPrice} onChange={formik.handleChange} className={formik.touched.costPrice && formik.errors.costPrice ? "error calendar_field" : "calendar_field"} placeholder="Cost Price" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Selling Price</label>
                                {formik.touched.sellingPrice && formik.errors.sellingPrice ? <p className="p-error">{formik.errors.sellingPrice}</p> : undefined}
                            </div>
                            <InputText keyfilter="num" onChange={formik.handleChange} value={formik.values.sellingPrice} name="sellingPrice" className={formik.touched.sellingPrice && formik.errors.sellingPrice ? "error calendar_field" : "calendar_field"} placeholder="Selling Price" />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="addbillingfield">
                            <div className="flex justify-content-between">
                                <label>Discount</label>
                                {formik.touched.discount && formik.errors.discount ? <p className="p-error">{formik.errors.discount}</p> : undefined}
                            </div>
                            <InputText keyfilter="num" name="discount" value={formik.values.discount} onChange={formik.handleChange} className={formik.touched.discount && formik.errors.discount ? "error calendar_field" : "calendar_field"} placeholder="discount" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>Profit Margin</label>
                                {/* {formik.touched.profitMargin && formik.errors.profitMargin ? <p className="p-error">{formik.errors.profitMargin}</p> : undefined} */}
                            </div>
                            <InputText disabled keyfilter="num" name="profitMargin" value={formik.values.profitMargin} onChange={formik.handleChange} className="calendar_field" placeholder="Profit Margin" />
                        </div>
                        <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                            <div className="flex justify-content-between">
                                <label>
                                    Image
                                    {/* <span style={{ fontSize: "" }} className="p-error">
                                    (Jpeg, Jpg, Png format allowed)
                                </span> */}
                                </label>
                                {formik.touched.image && formik.errors.image ? <p className="p-error">{formik.errors.image}</p> : undefined}
                            </div>
                            <div className={formik.touched.image && formik.errors.image ? "error custom-file-upload" : "custom-file-upload"}>
                                <div className="flex w-full justify-content-between custom" style={{ paddingLeft: "1.2rem", paddingRight: "1.2rem" }}>
                                    <span className={formik.values.image === "" || formik.values.image === undefined || formik.values.image === null ? "file-name-null" : "file-name"}>{formik.values.image ? formik.values.image.name : "Upload(JPG, JPEG, PNG)"}</span>
                                    <label htmlFor="fileInput" className={formik.values.image !== "" ? " custom-btn" : "backbtn"} style={{ width: "8.1rem", height: "3.4rem" }}>
                                        Upload
                                    </label>
                                </div>
                                <InputText
                                    id="fileInput"
                                    ref={Imgref}
                                    name="image"
                                    accept=".jpeg, .jpg, .png"
                                    type="file"
                                    onChange={(e) => {
                                        let reader = new FileReader();
                                        reader.readAsDataURL(e.target.files[0]);
                                        reader.onload = () => {
                                            setimgsrc(reader.result);
                                        };
                                        formik.setFieldValue("image", e.target.files[0]);
                                    }}
                                    className="hidden-file-input"
                                />
                            </div>

                            {/* <InputText
                                ref={Imgref}
                                name="image"
                                accept=".jpeg, .jpg, .png"
                                type="file"
                                onChange={(e) => {
                                    let reader = new FileReader();
                                    reader.readAsDataURL(e.target.files[0]);
                                    reader.onload = () => {
                                        setimgsrc(reader.result);
                                    };
                                    formik.setFieldValue("image", e.target.files[0]);
                                }}
                                className=" calendar_field hidden-file-input"
                                placeholder="Profit Margin"
                            /> */}
                        </div>
                    </div>
                    <div className="imageselected">
                        {imgsrc ? (
                            <div className="imageinventorymain">
                                <div className="inventoryimg">
                                    <div className="imageinv">
                                        <img
                                            onError={(e) => {
                                                e.target.src = "./placeholderimage.jpg";
                                            }}
                                            src={imgsrc}
                                        />
                                    </div>
                                    <div className="imagename">
                                        <p>{formik?.values?.image?.name}</p>
                                    </div>
                                </div>
                            </div>
                        ) : undefined}
                    </div>
                </form>
                <div className="flex justify-content-end" style={{ marginTop: "3.2rem", width: "92.4rem", gap: "1.6rem" }}>
                    <Button
                        className="backbtn"
                        label="Cancel"
                        type="button"
                        onClick={() => {
                            setAddInventory(false);
                        }}
                    />
                    <Button className="nextbtn" disabled={loader} loading={loader} label="Add" type="submit" onClick={formik.handleSubmit} />
                </div>
            </div>
            <Toast ref={toast} />
        </div>
    );
}
