import React, { useState } from "react";
import "./css/QuickLinksMain.css";
import { Button } from "primereact/button";
import { ValidateImeiDialogMain } from "./dialogs/ValidateImei/ValidateImeiDialogMain";
import { WifiCalling } from "./components/ImeiValidation/WifiCalling";
import { QueryNetwork } from "./components/QueryNetwork/QueryNetwork";
import { QuerySim } from "./components/QuerySIM/QuerySim";
export const QuickLinkMain = ({ cpData }) => {
    let model = JSON.parse(localStorage.getItem("permissions"));
    const [showImeiValidateDialog, setShowImeiValidateDialog] = useState(false);
    return (
        <div className="main-quick-links">
            <div className="quick-link-header">
                <div className="quicklink-header-container">
                    <h1>Quick Links</h1>
                    <p>Customer Information tab displays and allows editing of personal details.</p>
                </div>
                {cpData?.carrier?.name !== "TMB" && (
                    <Button
                        onClick={() => {
                            setShowImeiValidateDialog(true);
                        }}
                        disabled={!model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Imei Validation"))}
                        className="imei-validate-button"
                        label="IMEI Validation"
                    ></Button>
                )}
            </div>
            {model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Wifi Calling")) && (
                <div>
                    <WifiCalling cpData={cpData} />
                </div>
            )}
            {(model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Query Network")) || model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Query Sim"))) && (
                <div className="main-query-network-sim w-full flex flex-wrap flex-row justify-between">
                    {model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Query Network")) && <QueryNetwork cpData={cpData} />}
                    {model.some((element) => element.subModules.some((subModule) => subModule.subModuleName === "Query Sim")) && <QuerySim cpData={cpData} />}
                </div>
            )}
            {showImeiValidateDialog && (
                <div className="custom-dialog-wrapper">
                    <ValidateImeiDialogMain setShowImeiValidateDialog={setShowImeiValidateDialog} cpData={cpData} />
                </div>
            )}
        </div>
    );
};
