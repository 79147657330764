import React, { useEffect, useState } from "react";
import BillingNavbar from "../../billing_and_invoices/components/BillingNavbar";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import Axios from "axios";
import "./customer_usage.css";
import { api } from "../../../../../utility/AxiosInceptor";
import { Dropdown } from "primereact/dropdown";
import CommonLoaderBlue from "../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function CustomerUsage() {
    const [usageData, setUsageData] = useState([]);
    const selectedid = localStorage.getItem("selectedId");
    const [loader, setLoader] = useState(true);
    const parseselectedid = JSON.parse(selectedid);
    const options = [
        { label: "All", value: "" },
        { label: "VOICE", value: "VOICE" },
        { label: "SMS", value: "SMS" },
        { label: "DATA", value: "DATA" },
    ];
    const [dataTypeSelected, setDataTypeSelected] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [cpData, setCpData] = useState("");
    useEffect(() => {
        if (cpData === "") {
            api.get(`/api/user/userDetails?userId=${parseselectedid}`)
                .then((cpdatares) => {
                    setCpData(cpdatares?.data?.data);
                    if (cpdatares?.data?.data.carrier === "AT&T") {
                        api.post(`/api/user/helixUsage`, {
                            startDate: startDate !== "" ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : "",
                            endDate: endDate !== "" ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : "",
                            mdn: cpData?.data?.data?.phoneNumber,
                            usageType: dataTypeSelected,
                        })
                            .then((res) => {
                                setUsageData(res?.data?.data);
                                setLoader(false);
                            })
                            .catch((err) => {});
                    } else {
                        setLoader(false);
                    }
                })
                .catch((err) => {});
        } else {
            if (cpData?.carrier?.name === "AT&T" && startDate !== "" && endDate !== "") {
                setUsageData([]);
                setLoader(true);

                api.post(`/api/user/helixUsage`, { startDate: startDate !== "" ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : "", endDate: endDate !== "" ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : "", mdn: cpData?.phoneNumber, usageType: dataTypeSelected })
                    .then((res) => {
                        setUsageData(res?.data?.data);
                        setLoader(false);
                    })
                    .catch((err) => {
                        setLoader(false);
                    });
            } else {
                setLoader(false);
            }
        }
        return () => {};
    }, [startDate, endDate, dataTypeSelected]);
    const Header = () => {
        return (
            <div className="flex flex-wrap justify-content-left mt-2 w-full maindiv" style={{ backgroundColor: "white" }}>
                <div className="flex flex-row  justify-content-start w-full flex-wrap  ">
                    <div
                        className="cal"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "43%",
                        }}
                    >
                        <span style={{ fontWeight: "400", paddingLeft: "2%" }}>From</span>
                        <Calendar
                            value={startDate}
                            dateFormat="mm/dd/yy"
                            // placeholder="Start Date"
                            onChange={(e) => {
                                setStartDate(e.value);
                            }}
                            showIcon
                            style={{ height: "30px", width: "40%" }}
                        />
                        <span style={{ fontWeight: "400" }}>To</span>
                        <Calendar
                            value={endDate}
                            dateFormat="mm/dd/yy"
                            // placeholder="End Date"
                            onChange={(e) => {
                                setEndDate(e.value);
                            }}
                            showIcon
                            style={{ height: "30px", border: "none", width: "40%", color: "red" }}
                        />
                    </div>
                    <Dropdown
                        style={{ width: "30%" }}
                        options={options}
                        value={dataTypeSelected}
                        onChange={(e) => {
                            setDataTypeSelected(e.value);
                        }}
                        placeholder="Data Type"
                    />

                    {/* <div className="w-20rem ml-4 mt-2">
                        <label className="p-2" style={{ fontSize: "19px", textAlign: "center", color: "grey" }}>
                            To
                        </label>
                    </div> */}
                </div>
            </div>
        );
    };
    return (
        <>
            <div className="customerusagemain card">
                <div className="mt-4">
                    <BillingNavbar cpData={cpData} />
                </div>
                <hr />
                <h3 className="font-semibold ">Customer Usage </h3>
                <Header />
                <DataTable
                    value={usageData}
                    size="small"
                    stripedRows
                    resizableColumns
                    paginator
                    className="mt-4"
                    rows={10}
                    emptyMessage={
                        loader ? (
                            <div className="w-full flex flex-wrap flex-row justify-content-center align-items-center ">
                                <CommonLoaderBlue />
                            </div>
                        ) : (
                            "Custome Usage Not Found"
                        )
                    }
                >
                    <Column headerClassName="pt-3 pb-3" header="MDN" field="mdn" />
                    <Column header="Dialled Digit" field="DialledDigit" />

                    <Column header="ATT Call Datetime" field="ATTCallDatetime" />

                    <Column header="CDR Process Datetime" field="CDRProcessDatetime" />
                    <Column header="Redirecting Party" field="redirectingParty" />

                    <Column header="Billable Units" field="BillableUnits" />

                    <Column header="Call Type" field="CallType" />
                    <Column header="Usage Type" field="UsageType" />
                    <Column header="Destination Class" field="DestinationClass" />
                    <Column header="Plan Code" field="PlanCode" />
                    <Column header="Roaming" field="Roaming" />
                </DataTable>
            </div>
        </>
    );
}
