import React, { useEffect, useRef, useState } from "react";
import "./css/PaymentLogs.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../components/Loader/LoaderBlue";
import NotFoundData from "../../Common/Notfound/Notfound";
import { RowExpansionTemplate } from "./Components/RowExpansionTemplate";
import { Continue, PlanEditIcon } from "../../../../../../../../../utility";
import { toast } from "react-toastify";
export const PaymentLogMain = ({ refreshAllpayment, cpData }) => {
    const [allPaymentLogsData, setAllPaymentLogsData] = useState([]);
    const [isPaymentLogApiLoading, setIsPaymentLogApiLoading] = useState(true);
    const [isAPiCalled, setIsApiCalled] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [rowData, setRowData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefersh] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const op = useRef(null);
    const processPaymentLogsData = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const key = item.invoiceNo;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
        const latestTransactions = {};
        Object.values(groupedData).forEach((group) => {
            group.forEach((transaction) => {
                const transactionId = transaction.transactionId;
                if (!latestTransactions[transactionId] || new Date(latestTransactions[transactionId].date) < new Date(transaction.date)) {
                    latestTransactions[transactionId] = transaction;
                }
            });
        });
        Object.values(groupedData).forEach((group) => {
            group.forEach((transaction) => {
                transaction.isLatest = transaction === latestTransactions[transaction.transactionId];
            });
        });
        return Object.values(groupedData).map((group) => ({
            ...group[0], // First object
            remaining: group.slice(1),
        }));
    };
    const getPaymentLogs = async () => {
        api.get(`/api/web/invoices/paymentlogs?customerId=${cpData._id}`)
            .then((paymentLogResponse) => {
                setAllPaymentLogsData(processPaymentLogsData(paymentLogResponse.data));
                setIsApiCalled(true);
                setIsPaymentLogApiLoading(false);
            })
            .catch((err) => {
                setIsPaymentLogApiLoading(false);
                setIsApiCalled(true);
            });
    };
    useEffect(() => {
        if (cpData !== undefined) {
            getPaymentLogs();
        }
    }, [refreshAllpayment, refresh]);

    const toggleRowExpansion = (event) => {
        if (expandedRows[0]?.invoiceNo !== event.data.invoiceNo) {
            const rowData = event.data; // Get the row data
            console.log(event.data);
            setExpandedRows([rowData]); // Update the expanded rows state
        } else {
            setExpandedRows([]);
        }
    };
    const OverlayRef = useRef(null);

    const [overlayContent, setOverlayContent] = useState("");
    const Refund = async () => {
        const dataToSend1 = {
            paymentIntentId: rowData?.transactionId,
            customerId: cpData?._id,
            userId: parseLoginRes?._id,
            amount: rowData?.amount,
        };
        try {
            const response = await api.post(`/api/web/billing/refund`, dataToSend1);
            if (response?.status === 200 || response?.status === 201) {
                toast.success(response?.data?.msg);
                setIsLoading(false);
                // setShowDialog(false);
                setRefersh(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };
    const Void = async () => {
        const dataToSend1 = {
            transactionId: rowData?.transactionId,
            customerId: cpData?._id,
            userId: parseLoginRes?._id,
        };
        try {
            const response = await api.post(`/api/web/billing/voidCharge`, dataToSend1);
            if (response?.status === 200 || response?.status === 201) {
                toast.success("Void Successfully");
                setRefersh(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    return (
        <div className="main-payment-logs-container">
            {!isPaymentLogApiLoading && isAPiCalled && allPaymentLogsData.length === 0 ? (
                <NotFoundData currentNoFoundData={"payment-logs"} />
            ) : (
                <DataTable
                    onRowClick={(e) => toggleRowExpansion(e)}
                    expandedRows={expandedRows}
                    rowExpansionTemplate={() => {
                        return <RowExpansionTemplate PaymentLogExpansionRowData={expandedRows} cpData={cpData} />;
                    }}
                    value={allPaymentLogsData}
                >
                    <Column
                        field="invoiceNo"
                        header="Invoice No"
                        body={(rowData) => (
                            <div className="expander-row-invoiceno">
                                <i className={`pi ${expandedRows[0]?.invoiceNo === rowData.invoiceNo ? "pi-angle-up" : "pi-angle-down"}`} />
                                <p>{rowData?.invoiceNo}</p>
                            </div>
                        )}
                    />

                    <Column
                        header="Created Date"
                        body={(rowData) => {
                            return (
                                <span>
                                    {moment
                                        .utc(rowData.date)
                                        .tz("America/New_York") // Convert to New York time (Eastern Time Zone)
                                        .format("MM-DD-YYYY")}
                                </span>
                            );
                        }}
                    />
                    <Column
                        field="invoiceType"
                        header="Type"
                        body={(rowData) => (
                            <>
                                <p
                                    onMouseEnter={(e) => {
                                        if (rowData?.invoiceType[0].length > 15) {
                                            setOverlayContent(rowData?.invoiceType[0]);
                                            OverlayRef.current.show(e);
                                        }
                                    }}
                                    onMouseLeave={() => OverlayRef.current.hide()}
                                >
                                    {rowData?.invoiceType[0].length > 15 ? rowData?.invoiceType[0]?.substring(0, 12) + "..." : rowData.type}
                                </p>
                                <OverlayPanel ref={OverlayRef}>
                                    <div style={{ padding: "5px 10px" }}>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: "1.4rem",
                                                overflow: "hidden",
                                                border: "none",
                                                margin: "0px",
                                                lineHeight: "1.8rem",
                                                color: "#72728f",
                                            }}
                                        >
                                            {" "}
                                            {overlayContent}
                                        </p>
                                    </div>
                                </OverlayPanel>
                            </>
                        )}
                    />

                    <Column field="paymentStatus" body={(rowData) => <p className={`${rowData.paymentStatus === "failure" ? "status-failure" : "status-success"}`}>{rowData.paymentStatus.charAt(0).toUpperCase() + rowData.paymentStatus.slice(1)}</p>} header="Status" />
                    <Column
                        field="cardDetails.cardNumber"
                        header="Card Details"
                        body={(rowData) => {
                            return (
                                <>
                                    <p
                                        onMouseEnter={(e) => {
                                            if (rowData?.cardDetails?.cardNumber?.length > 4) {
                                                setOverlayContent(rowData?.cardDetails?.cardNumber);
                                                OverlayRef.current.show(e);
                                            }
                                        }}
                                        onMouseLeave={() => OverlayRef.current.hide()}
                                    >
                                        {rowData?.cardDetails?.cardNumber ? (rowData?.cardDetails?.cardNumber?.length > 4 ? rowData?.cardDetails?.cardNumber?.substring(0, 4) + "..." : rowData?.cardDetails?.cardNumber) : "NIL"}
                                    </p>
                                    <OverlayPanel ref={OverlayRef}>
                                        <div style={{ padding: "5px 10px" }}>
                                            <p
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: "1.4rem",
                                                    overflow: "hidden",
                                                    border: "none",
                                                    margin: "0px",
                                                    lineHeight: "1.8rem",
                                                    color: "#72728f",
                                                }}
                                            >
                                                {" "}
                                                {overlayContent}
                                            </p>
                                        </div>
                                    </OverlayPanel>
                                </>
                            );
                        }}
                    />

                    <Column field="amount" header="Amount" body={(rowData) => (rowData?.amount !== undefined && rowData?.amount !== "0.00" && rowData?.amount !== "0" && rowData?.amount !== "NaN" && rowData?.amount !== "" ? parseFloat(rowData?.amount).toFixed(2) : "NIL")} />
                    <Column
                        field="currentBalance"
                        header="Current Bill"
                        body={(rowData) => (rowData?.currentBalance !== undefined && rowData?.currentBalance !== "0.00" && rowData?.currentBalance !== "0" && rowData?.currentBalance !== "NaN" && rowData?.currentBalance !== "" ? parseFloat(rowData?.currentBalance).toFixed(2) : "NIL")}
                    />
                    <Column
                        field="previousBalance"
                        body={(rowData) => (rowData?.previousBalance !== undefined && rowData?.previousBalance !== "0.00" && rowData?.previousBalance !== "0" && rowData?.previousBalance !== "NaN" && rowData?.previousBalance !== "" ? parseFloat(rowData?.previousBalance).toFixed(2) : "NIL")}
                        header="Previous Balance"
                    />
                    <Column
                        field="transactionId"
                        body={(rowData) => {
                            return (
                                <>
                                    <p
                                        onMouseEnter={(e) => {
                                            if (rowData?.transactionId?.length > 23) {
                                                setOverlayContent(rowData?.transactionId);
                                                OverlayRef.current.show(e);
                                            }
                                        }}
                                        onMouseLeave={() => OverlayRef.current.hide()}
                                    >
                                        {rowData?.transactionId ? (rowData?.transactionId?.length > 23 ? rowData?.transactionId?.substring(0, 22) + "..." : rowData?.transactionId) : "NIL"}
                                    </p>
                                    <OverlayPanel ref={OverlayRef}>
                                        <div style={{ padding: "5px 10px" }}>
                                            <p
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: "1.4rem",
                                                    overflow: "hidden",
                                                    border: "none",
                                                    margin: "0px",
                                                    lineHeight: "1.8rem",
                                                    color: "#72728f",
                                                }}
                                            >
                                                {overlayContent}
                                            </p>
                                        </div>
                                    </OverlayPanel>
                                </>
                            );
                        }}
                        header="Transaction ID"
                    />
                    <Column
                        field="Action"
                        header="Action"
                        body={(rowData) => {
                            return (
                                <div className="flex flex-wrap flex-row justify-content-end  w-full h-full align-items-center main-action-wrapper actionbtnmain">
                                    <div
                                        className="flex flex-wrap flex-row justify-content-end  w-full h-full align-items-center main-action-wrapper actionbtnmain"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            op.current.toggle(e);
                                            setRowData(rowData);
                                        }}
                                    >
                                        <PlanEditIcon />
                                    </div>
                                    <OverlayPanel ref={op} style={{ width: "10.9rem", height: "8rem" }} className="flex align-items-center billingconfig">
                                        <div className="flex flex-column w-full overlay">
                                            <div
                                                className="flex justify-content-start align-items-center prospectoverlay"
                                                style={{ height: "32px", width: "10.7rem", paddingLeft: "1.2rem" }}
                                                onClick={() => {
                                                    setRowData(rowData);
                                                    Void();
                                                }}
                                            >
                                                <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Void</label>
                                            </div>
                                            <div
                                                className="flex justify-content-start align-items-center prospectoverlay"
                                                style={{ height: "32px", width: "10.7rem", paddingLeft: "1.2rem" }}
                                                onClick={() => {
                                                    setRowData(rowData);
                                                    Refund();
                                                }}
                                            >
                                                <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Refund</label>
                                            </div>
                                        </div>
                                    </OverlayPanel>
                                </div>
                            );
                        }}
                    />
                </DataTable>
            )}
            {isPaymentLogApiLoading ? (
                <div className="loader-wrapper">
                    <CommonLoaderBlue color="royalblue" sizehere={21} />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};
