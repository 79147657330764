import React, { useEffect, useState } from "react";
import "./css/CustomerFilesData.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../components/Loader/LoaderBlue";
import NotFoundData from "../../../Payments/Common/Notfound/Notfound";
import { CancelPopup, CheckMark } from "./assets/Assets";
import { Button } from "primereact/button";
export const CustomerFilesData = ({ selectedModule, selectedRange, cpData }) => {
    const [allFilesData, setAllFilesData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isFileDataLoading, setIsFileDataLoading] = useState(true);
    const [isAPiCalled, setIsApiCalled] = useState(false);
    const getCustomerFiles = async () => {
        api.get(`/api/web/uploadfiles/get-uploaded-files?enrollmentId=${cpData._id}`)
            .then((invoiceResponse) => {
                setAllFilesData(invoiceResponse?.data);
                setIsApiCalled(true);
                setIsFileDataLoading(false);
            })
            .catch((err) => {
                setAllFilesData([]);
                setIsFileDataLoading(false);
            });
    };
    useEffect(() => {
        if (cpData && Object.keys(cpData).length > 0) {
            getCustomerFiles();
        }
    }, [cpData]);

    return (
        <div className="main-customerfiles-data">
            <h1 className="top-header-files-data">Uploaded Files</h1>
            {!isFileDataLoading && isAPiCalled && allFilesData?.length === 0 ? (
                <NotFoundData currentNoFoundData={"files"} />
            ) : (
                <DataTable value={allFilesData}>
                    <Column
                        header={() => {
                            return (
                                <div tooltip="hello" className="main-header-filetype flex flex-wrap flex-row  align-items-center">
                                    <div>
                                        {" "}
                                        <div
                                            onClick={() => {
                                                if (selectedRows.length < allFilesData.length) {
                                                    setSelectedRows(allFilesData);
                                                } else {
                                                    setSelectedRows([]);
                                                }
                                            }}
                                            className={`${allFilesData.length > 0 && selectedRows.length === allFilesData.length ? "checked" : ""}`}
                                        >
                                            {allFilesData.length > 0 && selectedRows.length === allFilesData.length && <CheckMark />}
                                        </div>
                                    </div>
                                    <p>File Type</p>
                                </div>
                            );
                        }}
                        body={(rowData, { rowIndex }) => {
                            const ischecked = selectedRows.some((item) => rowData.filepath === item.filepath);
                            let filetypevalue;
                            let fileType = rowData?.filepath;
                            if (fileType) {
                                let actualfiletype = rowData.filepath.substring(rowData.filepath.lastIndexOf("/") + 1);
                                if (actualfiletype.length > 8) {
                                    filetypevalue = actualfiletype.substring(0, 8) + "...";
                                } else {
                                    filetypevalue = actualfiletype;
                                }
                            }
                            return (
                                <div className="main-row-filetype flex flex-wrap flex-row  align-items-center">
                                    <div>
                                        <div
                                            className={`${ischecked ? "checked" : ""}`}
                                            onClick={() => {
                                                if (selectedRows.some((item) => rowData.filepath === item.filepath)) {
                                                    setSelectedRows(selectedRows.filter((item) => item.filepath !== rowData.filepath));
                                                } else {
                                                    setSelectedRows([...selectedRows, rowData]);
                                                }
                                            }}
                                        >
                                            {ischecked && <CheckMark />}
                                        </div>
                                    </div>
                                    <p>{filetypevalue || "NIL"}</p>
                                </div>
                            );
                        }}
                    />
                    <Column
                        header="Date"
                        body={(rowData) => {
                            return (
                                <span>
                                    {rowData?.uploadDate
                                        ? moment(rowData?.uploadDate)
                                              .tz("America/New_York") // Convert to New York time (Eastern Time Zone)
                                              .format("MM-DD-YYYY")
                                        : "NIL"}
                                </span>
                            );
                        }}
                    />
                    <Column
                        field=""
                        header="Uploaded By"
                        body={(rowData) => {
                            return <span> {rowData?.uploadedBy?.name || "NIL"}</span>;
                        }}
                    />

                    <Column
                        field="file"
                        body={(rowData) => {
                            let filePathvalue;
                            let filePath = rowData?.filepath;
                            if (filePath) {
                                let actualfilepath = rowData.filepath.substring(rowData.filepath.lastIndexOf("/") + 1);
                                if (actualfilepath.length > 8) {
                                    filePathvalue = actualfilepath.substring(0, 8) + "...";
                                } else {
                                    filePathvalue = actualfilepath;
                                }
                            }
                            return <span tooltip="helooo">{filePathvalue ? filePathvalue : "NIL"}</span>;
                        }}
                        header="File"
                    />
                </DataTable>
            )}
            {isFileDataLoading ? (
                <div className="loader-wrapper">
                    <CommonLoaderBlue color="royalblue" sizehere={21} />
                </div>
            ) : (
                ""
            )}
            {selectedRows?.length > 0 && (
                <div className="flex flex-column justify-content-start align-items-center selecteddatapopup">
                    <div className="flex w-full justify-content-end">
                        <span onClick={() => setSelectedRows([])} style={{ cursor: "pointer" }}>
                            <CancelPopup />
                        </span>
                    </div>
                    <div className="flex justify-content-between w-full align-items-center selectedrowdata   ">
                        <div className="show-details-row flex flex-wrap fex-row justify-content-start align-items-center">
                            <p>Selected:</p>
                            <p>{selectedRows?.length}</p>
                        </div>

                        <Button className="adduserbtn" label="Download" style={{ width: "12rem" }} />
                    </div>
                </div>
            )}
        </div>
    );
};
