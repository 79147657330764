import React, { useState, useEffect } from "react";
import { RolesIcon } from "../../../../utility";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export default function Role({ setUserScreen }) {
    const navigate = useNavigate();

    return (
        <div className="rolesEmptyScreen">
            <RolesIcon />
            <div className="flex flex-column align-items-center" style={{ marginTop: "2.4rem" }}>
                <h1>No Roles Created Yet!</h1>
                <p>It seems your roles list is empty. Click below to start adding new roles and empower your team!</p>
            </div>
            <div>
                <Button className="nextbtn" label="Create a Role" onClick={() => navigate("/createrole")} style={{ width: "18.6rem", height: "5rem", marginTop: "3.2rem" }} />
            </div>
        </div>
    );
}
