import React, { useEffect, useState } from "react";
import "./calendar.css";
import { Calendar } from "primereact/calendar";
const CalendarDropdown = ({ setShortSelection, calendar, selectedRange, setSelectedRange }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(selectedRange);
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const generateCalendarDays = () => {
        const days = [];

        // Days from the previous month
        const previousMonthDays = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
        for (let i = firstDayOfMonth - 1; i >= 0; i--) {
            days.push(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, previousMonthDays - i));
        }

        // Days of the current month
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
        }

        // Days from the next month to fill the grid (up to 35 days total)
        const nextMonthDays = 35 - days.length;
        for (let i = 1; i <= nextMonthDays; i++) {
            days.push(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, i));
        }

        return days.slice(0, 35);
    };

    const handleDateClick = (date) => {
        setShortSelection();
        if (!selectedDate[0] || (selectedDate[0] && selectedDate[1])) {
            setSelectedDate([date, null]);
        } else {
            const [start, end] = [selectedDate[0], date].sort((a, b) => a - b);
            setSelectedDate([start, end]);
        }
    };

    const isDateInRange = (date) => {
        if (!selectedDate[0] || !selectedDate[1]) return false;
        return date >= selectedDate[0] && date <= selectedDate[1];
    };

    const isStartDate = (date) => {
        return selectedDate[0] && date?.getTime() === selectedDate[0]?.getTime();
    };

    const isEndDate = (date) => {
        return selectedDate[1] && date?.getTime() === selectedDate[1]?.getTime();
    };

    const changeMonth = (increment) => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + increment);
        setCurrentDate(newDate);
    };

    const setDateRange = (start, end) => {
        setSelectedRange([start, end]);
    };
    useEffect(() => {}, [selectedRange]);

    return (
        <div className="calendar-container">
            <div className="flex flex-wrap flex-row justify-content-center w-full">
                <div className="calendar-header">
                    <i onClick={() => changeMonth(-1)} className="pi pi-chevron-left" />
                    <h2 className="month-year">
                        {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
                    </h2>
                    <i onClick={() => changeMonth(1)} className="pi pi-chevron-right" />
                </div>
            </div>

            <div className="flex direct-calendar-picker flex-wrap flex-row justify-content-between w-full">
                <div className="">
                    <label>From</label>
                    <Calendar
                        value={selectedDate[0]}
                        onChange={(e) => {
                            if (selectedDate[1]) {
                                setSelectedDate([e.value, selectedDate[1]]);
                            } else {
                                setSelectedDate([e.value, null]);
                            }
                        }}
                        className="calendar-picker"
                    />
                </div>
                <div>
                    <label>To</label>
                    <Calendar
                        value={selectedRange[1]}
                        onChange={(e) => {
                            if (selectedDate[0]) {
                                setSelectedDate([selectedDate[0], e.value]);
                            } else {
                                setSelectedDate([null, e.value]);
                            }
                        }}
                        className="calendar-picker"
                    />
                </div>
            </div>

            <div className="calendar-grid">
                <div className="day-name-container flex flex-wrap flex-row align-items-center">
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                        <div key={day} className="day-name">
                            {day}
                        </div>
                    ))}
                </div>
                <div className="month-days-container flex flex-wrap flex-row align-items-center">
                    {generateCalendarDays().map((date, index) => (
                        <div key={index} className={`calendar-day ${isStartDate(date) ? "start-date" : ""} ${isEndDate(date) ? "end-date" : ""} ${isDateInRange(date) ? "in-range" : ""}`} onClick={() => date && handleDateClick(date)}>
                            <p className="flex flex-wrap flex-row justify-content-center align-items-center"> {date ? date.getDate() : ""} </p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-wrap flex-row justify-content-between align-items-center action-button-container">
                <button
                    onClick={() => {
                        //y setSelectedRange([null, null]);
                        calendar.current.hide();
                    }}
                >
                    Cancel
                </button>
                <button
                    onClick={() => {
                        setSelectedRange(selectedDate);
                        calendar.current.hide();
                    }}
                >
                    Apply
                </button>
            </div>
        </div>
    );
};

export default CalendarDropdown;
