import React, { useEffect, useRef, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { api } from "../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import * as Yup from "yup";
import CommonLoaderBlue from "./loader/LoaderBlue";
import { Button } from "primereact/button";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const CheckoutForm = ({
    parentDetails,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
}) => {
    let toast = useRef();
    let checkLabel;
    const stripe = useStripe();
    const elements = useElements();
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const [alternateCardToken, setAlternateCardToken] = useState("");
    const [autoPay, setAutoPay] = useState(true);
    const parseLoginRes = JSON.parse(localStorage.getItem("userData"));

    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            cardNumber: "",
            cardExpiry: "",
            cardCvc: "",
        },
        validationSchema: Yup.object({
            cardNumber: Yup.string().required("Field is required"),
            cardExpiry: Yup.string().required("Required"),
            cardCvc: Yup.string().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            let response = await api.post(`/api/web/billing/paymentintent`, { amount: paid });
            const clientSecret = await response.data.clientSecret;
            if (!elements || !clientSecret) {
                toast.current.show({ severity: "error", summary: "Stripe Elements not loaded", detail: "Please try again later." });
                setIsLoading(false);
                return;
            }

            // Call confirmCardPayment directly to confirm the payment
            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement), // The CardNumberElement
                    billing_details: {
                        name: "Customer Name", // You can pass the customer name dynamically here
                    },
                },
            });
            if (paymentIntent?.status === "requires_action") {
                // 3D Secure authentication is required
                handle3DSecureAuthentication(paymentIntent.client_secret, paymentIntent);
            } else if (error) {
                api.post(`/api/web/billing/updatePaymentLog`, {
                    customerId: initiationData?._id,
                    paymentMethod: "Credit Card",
                    stripeId: paymentIntent?.id,
                    invoiceType: "Sign Up",
                    action: "failure",
                    invoiceNo: [],
                    reason: "failure",
                    cardNumber: error?.payment_method?.card?.last4,
                    transactionStatus: error?.message,
                    paymentStatus: "failure",
                    amount: paid,
                    userId: parseLoginRes?._id,
                })
                    .then((res) => {
                        toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                    })
                    .catch((err) => {
                        toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                    });
                toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message : "An error occurred while processing the payment"}</p> });

                setTimeout(() => {
                    setPaymentDialogVisibility(false);
                }, 2000);
            } else {
                toast.current.show({ severity: "success", summary: "Payment Processed", detail: <p>{paymentIntent?.status}</p> });

                if (alternateCardToken === "") {
                    let customerId = ""; //customercreation?.data?.data?.id;
                    let token = "";
                    setPaymentDone(true);
                    //setActiveIndex(3);
                    let additionalFeature = [];
                    let discounts = [];
                    let dueDate = "";
                    let applyLateFee = "";
                    let oneTimeCharge = "";
                    let planName = "";
                    let planId = "";
                    let planCharges = "";
                    dueDate = currentBillingConfig?.dueDate;
                    oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                    applyLateFee = currentBillingConfig?.applyLateFee;
                    let selectedDiscounts = paymentScreenDetails.discount;

                    for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                        for (let k = 0; k < selectedDiscounts.length; k++) {
                            if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                    amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    for (let i = 0; i < allplans?.length; i++) {
                        if (object.plan === allplans[i]?._id) {
                            planName = allplans[i]?.name;
                            planCharges = allplans[i]?.price;

                            planId = allplans[i]?._id;
                        }
                    }

                    let additionalFeatures = paymentScreenDetails?.additional;
                    for (let k = 0; k < additionalFeatures?.length; k++) {
                        for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                            if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                let obj = {
                                    name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                    amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }

                    let plan = object?.plan;
                    // let dateincasepart
                    //object.totalAmount === paid ? dueDate:"Partial"
                    const loginRes = localStorage.getItem("userData");
                    const parseLoginRes = JSON.parse(loginRes);
                    const shipmentName = localStorage.getItem("shipmentName");
                    const shipmentCost = localStorage.getItem("shipmentCost");
                    let dataToSend = {
                        paymentId: paymentIntent.id,
                        isAutopay: autoPay,
                        customerId: object.customerid,
                        invoiceType: "Sign Up",
                        totalAmount: object.totalamount,
                        additionalCharges: additionalFeature,
                        discount: discounts,
                        amountPaid: paid,
                        selectProduct: object?.billId,
                        invoiceDueDate: dueDate,
                        isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                        lateFee: applyLateFee,
                        invoiceOneTimeCharges: oneTimeCharge,
                        invoiceStatus: parseFloat(object.totalamount) <= parseFloat(paid) ? "Paid" : "Partial",
                        planId: plan,
                        planName: planName,
                        planCharges: planCharges,
                        chargingType: "monthly",
                        invoicePaymentMethod: object?.paymentMode,
                        printSetting: "Both",
                        isWithInvoice: true,

                        isInvoice: true,
                        userId: parseLoginRes?._id,
                        billingPeriod: {
                            from: "onActivation",
                            to: "onActivation",
                        },
                        isShipmentCostDiscounted: checkLabel,
                        shipmentCost: {
                            name: shipmentName,
                            amount: shipmentCost,
                        },
                    };
                    if (autoPay) {
                        dataToSend.paymentMethodId = token;
                        dataToSend.stripeCustomerId = customerId;
                    }
                    setDataSendForInvoice(dataToSend);

                    api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                        .then((response) => {
                            api.post(`/api/web/billing/updatePaymentLog`, {
                                customerId: initiationData?._id,
                                paymentMethod: "Credit Card",
                                stripeId: paymentIntent?.id,
                                action: "success",
                                reason: "success",
                                transactionStatus: paymentIntent?.status,
                                paymentStatus: "success",
                                invoiceNo: response?.data?.data?.invoice,
                                amount: paid,
                                userId: parseLoginRes?._id,
                            })
                                .then((res) => {
                                    toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                                    setTimeout(() => {
                                        setAllPaymentInfo(response?.data);
                                        setPaymentScreenActive(false);
                                        setPreviewScreenActive(true);
                                    }, 500);
                                })
                                .catch((err) => {
                                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                                    setTimeout(() => {
                                        setAllPaymentInfo(response?.data);
                                        setPaymentScreenActive(false);
                                        setPreviewScreenActive(true);
                                    }, 500);
                                });
                        })
                        .catch((err) => {});
                    // })
                    //.catch((err) => {
                    //});
                    //})
                    //.catch((err) => {});
                } else {
                    const datatosendincreateAccount = {
                        name: `${parentDetails?.firstName} ${parentDetails?.lastName}`,
                        email: `${parentDetails?.email}`,
                        phone: `+1${parentDetails?.contact}`,
                        description: "New customer account",
                    };

                    api.post(`/api/bannerRouter/create_account`, datatosendincreateAccount)
                        .then((customercreation) => {
                            let customerId = customercreation?.data?.data?.id;
                            setPaymentDone(true);
                            //setActiveIndex(3);

                            let additionalFeature = [];
                            let discounts = [];
                            let dueDate = "";
                            let applyLateFee = "";
                            let oneTimeCharge = "";
                            let planName = "";
                            let planId = "";
                            let planCharges = "";
                            dueDate = currentBillingConfig?.dueDate;
                            oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                            applyLateFee = currentBillingConfig?.applyLateFee;
                            let selectedDiscounts = paymentScreenDetails.discount;

                            for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                                for (let k = 0; k < selectedDiscounts.length; k++) {
                                    if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                        let obj = {
                                            name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                            amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                        };
                                        discounts.push(obj);
                                    }
                                }
                            }
                            for (let i = 0; i < allplans?.length; i++) {
                                if (object.plan === allplans[i]?._id) {
                                    planName = allplans[i]?.name;
                                    planCharges = allplans[i]?.price;

                                    planId = allplans[i]?._id;
                                }
                            }

                            let additionalFeatures = paymentScreenDetails?.additional;
                            for (let k = 0; k < additionalFeatures?.length; k++) {
                                for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                    if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                        let obj = {
                                            name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                            amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                        };
                                        additionalFeature.push(obj);
                                    }
                                }
                            }

                            let plan = object?.plan;
                            // let dateincasepart
                            //object.totalAmount === paid ? dueDate:"Partial"

                            const loginRes = localStorage.getItem("userData");
                            const parseLoginRes = JSON.parse(loginRes);
                            const shipmentName = localStorage.getItem("shipmentName");
                            const shipmentCost = localStorage.getItem("shipmentCost");
                            let dataToSend = {
                                paymentId: paymentIntent.id,
                                isAutopay: autoPay,

                                customerId: object.customerid,
                                invoiceType: "Sign Up",
                                totalAmount: object.totalamount,
                                additionalCharges: additionalFeature,
                                discount: discounts,
                                amountPaid: paid,
                                selectProduct: object?.billId,
                                isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                                invoiceDueDate: dueDate,
                                lateFee: applyLateFee,
                                invoiceOneTimeCharges: oneTimeCharge,
                                invoiceStatus: parseFloat(object.totalamount) <= parseFloat(paid) ? "Paid" : "Partial",
                                planId: plan,
                                planName: planName,
                                isWithInvoice: true,
                                planCharges: planCharges,
                                chargingType: "monthly",
                                invoicePaymentMethod: object?.paymentMode,
                                printSetting: "Both",
                                isInvoice: true,

                                billingPeriod: {
                                    from: "onActivation",
                                    to: "onActivation",
                                },
                                isShipmentCostDiscounted: checkLabel,
                                shipmentCost: {
                                    name: shipmentName,
                                    amount: shipmentCost,
                                },

                                userId: parseLoginRes?._id,
                            };
                            if (autoPay) {
                                dataToSend.paymentMethodId = alternateCardToken;
                                dataToSend.stripeCustomerId = customerId;
                            }
                            setDataSendForInvoice(dataToSend);

                            api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                                .then((response) => {
                                    api.post(`/api/web/billing/updatePaymentLog`, {
                                        customerId: initiationData?._id,
                                        paymentMethod: "Credit Card",
                                        stripeId: paymentIntent?.id,
                                        action: "success",
                                        reason: "success",
                                        transactionStatus: paymentIntent?.status,
                                        paymentStatus: "success",
                                        invoiceNo: response?.data?.data?.invoice,
                                        amount: paid,
                                        userId: parseLoginRes?._id,
                                    })
                                        .then((res) => {
                                            toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                                            setTimeout(() => {
                                                setAllPaymentInfo(response?.data);
                                                setPaymentScreenActive(false);
                                                setPreviewScreenActive(true);
                                            }, 500);
                                        })
                                        .catch((err) => {
                                            toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                                            setTimeout(() => {
                                                setAllPaymentInfo(response?.data);
                                                setPaymentScreenActive(false);
                                                setPreviewScreenActive(true);
                                            }, 500);
                                        });
                                })
                                .catch((err) => {});
                        })
                        .catch((err) => {});
                }
            }
            {
            }
        },
    });
    const handle3DSecureAuthentication = async (clientSecret, paymentIntent) => {
        try {
            const { error } = await stripe.handleCardAction(clientSecret);
            if (error) {
                // Handle error
                console.error("3D Secure authentication failed:", error.message);
            } else {
                toast.current.show({ severity: "success", summary: "Payment Processed", detail: <p>{paymentIntent?.status}</p> });

                if (alternateCardToken === "") {
                    let customerId = ""; //customercreation?.data?.data?.id;
                    let token = "";
                    setPaymentDone(true);
                    //setActiveIndex(3);
                    let additionalFeature = [];
                    let discounts = [];
                    let dueDate = "";
                    let applyLateFee = "";
                    let oneTimeCharge = "";
                    let planName = "";
                    let planId = "";
                    let planCharges = "";
                    dueDate = currentBillingConfig?.dueDate;
                    oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                    applyLateFee = currentBillingConfig?.applyLateFee;
                    let selectedDiscounts = paymentScreenDetails.discount;

                    for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                        for (let k = 0; k < selectedDiscounts.length; k++) {
                            if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                    amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    for (let i = 0; i < allplans?.length; i++) {
                        if (object.plan === allplans[i]?._id) {
                            planName = allplans[i]?.name;
                            planCharges = allplans[i]?.price;

                            planId = allplans[i]?._id;
                        }
                    }

                    let additionalFeatures = paymentScreenDetails?.additional;
                    for (let k = 0; k < additionalFeatures?.length; k++) {
                        for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                            if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                let obj = {
                                    name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                    amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }

                    let plan = object?.plan;
                    // let dateincasepart
                    //object.totalAmount === paid ? dueDate:"Partial"
                    const loginRes = localStorage.getItem("userData");
                    const parseLoginRes = JSON.parse(loginRes);
                    const shipmentName = localStorage.getItem("shipmentName");
                    const shipmentCost = localStorage.getItem("shipmentCost");
                    let dataToSend = {
                        paymentId: paymentIntent.id,
                        isAutopay: autoPay,
                        customerId: object.customerid,
                        invoiceType: "Sign Up",
                        totalAmount: object.totalamount,
                        additionalCharges: additionalFeature,
                        discount: discounts,
                        amountPaid: paid,
                        selectProduct: object?.billId,
                        invoiceDueDate: dueDate,
                        isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                        lateFee: applyLateFee,
                        invoiceOneTimeCharges: oneTimeCharge,
                        invoiceStatus: parseFloat(object.totalamount) <= parseFloat(paid) ? "Paid" : "Partial",
                        planId: plan,
                        planName: planName,
                        planCharges: planCharges,
                        chargingType: "monthly",
                        invoicePaymentMethod: object?.paymentMode,
                        printSetting: "Both",
                        isWithInvoice: true,

                        isInvoice: true,
                        userId: parseLoginRes?._id,
                        billingPeriod: {
                            from: "onActivation",
                            to: "onActivation",
                        },
                        isShipmentCostDiscounted: checkLabel,
                        shipmentCost: {
                            name: shipmentName,
                            amount: shipmentCost,
                        },
                    };
                    if (autoPay) {
                        dataToSend.paymentMethodId = token;
                        dataToSend.stripeCustomerId = customerId;
                    }
                    setDataSendForInvoice(dataToSend);

                    api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                        .then((response) => {
                            api.post(`/api/web/billing/updatePaymentLog`, {
                                customerId: initiationData?._id,
                                paymentMethod: "Credit Card",
                                stripeId: paymentIntent?.id,
                                action: "success",
                                reason: "success",
                                transactionStatus: paymentIntent?.status,
                                paymentStatus: "success",
                                invoiceNo: response?.data?.data?.invoice,
                                amount: paid,
                                userId: parseLoginRes?._id,
                            })
                                .then((res) => {
                                    toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                                    setTimeout(() => {
                                        setAllPaymentInfo(response?.data);
                                        setPaymentScreenActive(false);
                                        setPreviewScreenActive(true);
                                    }, 500);
                                })
                                .catch((err) => {
                                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                                    setTimeout(() => {
                                        setAllPaymentInfo(response?.data);
                                        setPaymentScreenActive(false);
                                        setPreviewScreenActive(true);
                                    }, 500);
                                });
                        })
                        .catch((err) => {});
                    // })
                    //.catch((err) => {
                    //});
                    //})
                    //.catch((err) => {});
                } else {
                    const datatosendincreateAccount = {
                        name: `${parentDetails?.firstName} ${parentDetails?.lastName}`,
                        email: `${parentDetails?.email}`,
                        phone: `+1${parentDetails?.contact}`,
                        description: "New customer account",
                    };

                    api.post(`/api/bannerRouter/create_account`, datatosendincreateAccount)
                        .then((customercreation) => {
                            let customerId = customercreation?.data?.data?.id;
                            setPaymentDone(true);
                            //setActiveIndex(3);

                            let additionalFeature = [];
                            let discounts = [];
                            let dueDate = "";
                            let applyLateFee = "";
                            let oneTimeCharge = "";
                            let planName = "";
                            let planId = "";
                            let planCharges = "";
                            dueDate = currentBillingConfig?.dueDate;
                            oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                            applyLateFee = currentBillingConfig?.applyLateFee;
                            let selectedDiscounts = paymentScreenDetails.discount;

                            for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                                for (let k = 0; k < selectedDiscounts.length; k++) {
                                    if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                        let obj = {
                                            name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                            amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                        };
                                        discounts.push(obj);
                                    }
                                }
                            }
                            for (let i = 0; i < allplans?.length; i++) {
                                if (object.plan === allplans[i]?._id) {
                                    planName = allplans[i]?.name;
                                    planCharges = allplans[i]?.price;

                                    planId = allplans[i]?._id;
                                }
                            }

                            let additionalFeatures = paymentScreenDetails?.additional;
                            for (let k = 0; k < additionalFeatures?.length; k++) {
                                for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                    if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                        let obj = {
                                            name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                            amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                        };
                                        additionalFeature.push(obj);
                                    }
                                }
                            }

                            let plan = object?.plan;
                            // let dateincasepart
                            //object.totalAmount === paid ? dueDate:"Partial"

                            const loginRes = localStorage.getItem("userData");
                            const parseLoginRes = JSON.parse(loginRes);
                            const shipmentName = localStorage.getItem("shipmentName");
                            const shipmentCost = localStorage.getItem("shipmentCost");
                            let dataToSend = {
                                paymentId: paymentIntent.id,
                                isAutopay: autoPay,

                                customerId: object.customerid,
                                invoiceType: "Sign Up",
                                totalAmount: object.totalamount,
                                additionalCharges: additionalFeature,
                                discount: discounts,
                                amountPaid: paid,
                                selectProduct: object?.billId,
                                isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                                invoiceDueDate: dueDate,
                                lateFee: applyLateFee,
                                invoiceOneTimeCharges: oneTimeCharge,
                                invoiceStatus: parseFloat(object.totalamount) <= parseFloat(paid) ? "Paid" : "Partial",
                                planId: plan,
                                planName: planName,
                                isWithInvoice: true,
                                planCharges: planCharges,
                                chargingType: "monthly",
                                invoicePaymentMethod: object?.paymentMode,
                                printSetting: "Both",
                                isInvoice: true,

                                billingPeriod: {
                                    from: "onActivation",
                                    to: "onActivation",
                                },
                                isShipmentCostDiscounted: checkLabel,
                                shipmentCost: {
                                    name: shipmentName,
                                    amount: shipmentCost,
                                },

                                userId: parseLoginRes?._id,
                            };
                            if (autoPay) {
                                dataToSend.paymentMethodId = alternateCardToken;
                                dataToSend.stripeCustomerId = customerId;
                            }
                            setDataSendForInvoice(dataToSend);

                            api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                                .then((response) => {
                                    api.post(`/api/web/billing/updatePaymentLog`, {
                                        customerId: initiationData?._id,
                                        paymentMethod: "Credit Card",
                                        stripeId: paymentIntent?.id,
                                        action: "success",
                                        reason: "success",
                                        transactionStatus: paymentIntent?.status,
                                        paymentStatus: "success",
                                        invoiceNo: response?.data?.data?.invoice,
                                        amount: paid,
                                        userId: parseLoginRes?._id,
                                    })
                                        .then((res) => {
                                            toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                                            setTimeout(() => {
                                                setAllPaymentInfo(response?.data);
                                                setPaymentScreenActive(false);
                                                setPreviewScreenActive(true);
                                            }, 500);
                                        })
                                        .catch((err) => {
                                            toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                                            setTimeout(() => {
                                                setAllPaymentInfo(response?.data);
                                                setPaymentScreenActive(false);
                                                setPreviewScreenActive(true);
                                            }, 500);
                                        });
                                })
                                .catch((err) => {});
                        })
                        .catch((err) => {});
                }
            }
        } catch (error) {
            console.error("Error handling 3D Secure authentication:", error.message);

            api.post(`/api/web/billing/updatePaymentLog`, {
                customerId: initiationData?._id,
                paymentMethod: "Credit Card",
                stripeId: paymentIntent?.id,
                invoiceType: "Sign Up",
                action: "failure",
                invoiceNo: [],
                reason: "failure",
                cardNumber: error?.payment_method?.card?.last4,
                transactionStatus: error?.message,
                paymentStatus: "failure",
                amount: paid,
                userId: parseLoginRes?._id,
            })
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
            toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message : "An error occurred while processing the payment"}</p> });

            setTimeout(() => {
                setPaymentDialogVisibility(false);
            }, 2000);
        } finally {
            //setIsLoading(false);
        }
    };
    return (
        <div style={{ width: "64.4rem", marginLeft: "2.1rem" }} className="wallet-container">
            <Toast ref={toast} />

            <form
                className="main-wallet-paymentform"
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
            >
                <div className="card-input-main">
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Number</label>
                            {formik.errors.cardNumber && formik.touched.cardNumber && <label>{formik.errors.cardNumber}</label>}
                        </div>
                        <CardNumberElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardNumber", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardNumber && formik.touched.cardNumber ? "p-invalid" : ""}`}
                        />
                    </div>
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Card Expiry</label>
                            {formik.errors.cardExpiry && formik.touched.cardExpiry && <label>{formik.errors.cardExpiry}</label>}
                        </div>

                        <CardExpiryElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardExpiry", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardExpiry && formik.touched.cardExpiry ? "p-invalid" : ""}`}
                        />
                    </div>
                    <div className="card-container">
                        <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                            <label>Security Code *</label>
                            {formik.errors.cardCvc && formik.touched.cardCvc && <label>{formik.errors.cardCvc}</label>}
                        </div>

                        <CardCvcElement
                            onChange={(event) => {
                                console.log(event);
                                formik.setFieldValue("cardCvc", event.complete ? "entered" : "");
                            }}
                            className={`card-input ${formik.errors.cardCvc && formik.touched.cardCvc ? "p-invalid" : ""}`}
                        />
                    </div>
                </div>
            </form>
            <div className="action-buttons-container flex flex-wrap flex-row justify-content-end">
                <Button
                    style={{ width: "10rem !important" }}
                    onClick={() => {
                        // if (amount === 0 || amount === undefined || amount === "") {
                        //     setShowAmountError(true);
                        // }
                        formik.handleSubmit();
                    }}
                    disabled={isLoading}
                    label={
                        isLoading ? (
                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                <CommonLoaderBlue customSize={12} color={"white"} />
                            </div>
                        ) : (
                            "Pay Now"
                        )
                    }
                />
            </div>
        </div>
    );
};

const Payment = ({
    parentDetails,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
}) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm
                setAllPaymentInfo={setAllPaymentInfo}
                parentDetails={parentDetails}
                setPreviewScreenActive={setPreviewScreenActive}
                setPaymentScreenActive={setPaymentScreenActive}
                setDataSendForInvoice={setDataSendForInvoice}
                paymentScreenDetails={paymentScreenDetails}
                currentBillingConfig={currentBillingConfig}
                paid={paid}
                plan={plan}
                paymentDone={paymentDone}
                setPaymentDone={setPaymentDone}
                setPaymentScreenDetails={setPaymentScreenDetails}
                setPaymentDialogVisibility={setPaymentDialogVisibility}
                initiationData={initiationData}
                object={object}
            />
        </Elements>
    );
};

export default Payment;
