import React, { useEffect, useState, useRef } from "react";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { useFormik } from "formik";
import "./css/billing_configuration.css";
import * as Yup from "yup";
import { api } from "../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import ListAllBilling from "./components/billingmodel_configurations/billing_model_configurations";
import Axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import UpdateBill from "./components/update_bill";
import { Dialog } from "primereact/dialog";
import { Falsebutton, PlanEditIcon, Tickbtn, Tickbutton, Tickbuttonfill } from "../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
import CommonLoaderBlue from "../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
const BillingConfiguration = () => {
    const [overlayContent, setOverlayContent] = useState("");
    const [rowData, setRowData] = useState();
    const [configBillingModel, setConfigBillingModel] = useState(false);
    const [delteBillingLoader, setDeleteBillingLoader] = useState(false);
    const [isDiscountLoading, setIsDiscountLoading] = useState(false);
    const [isFeatureLoading, setIsFeatureLoading] = useState(false);
    const [currentBillingId, setCurrentBillingId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const toast2 = useRef(null);
    const [loader, setLoader] = useState(true);
    const [visible, setVisible] = useState(false);
    const [newDiscount, setNewDiscount] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const [updatePlanVisibility, setUpdatePlanVisibility] = useState(false);
    const [newFeature, setNewFeature] = useState(false);
    const [allPlan, setAllPlan] = useState([]);
    const [allDiscount, setAllDiscount] = useState([]);
    const [allFeature, setAllFeature] = useState([]);
    const [refreshOnBillingConfig, setRefreshOnBillingConfig] = useState(false);
    const [configData, setconfigData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);
    const op = useRef(null);
    const formRef = useRef(null);
    const scrollToForm = () => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };
    const validationSchema = Yup.object().shape({
        // monthlyCharge: Yup.array().required("This is Required").min(1, "Field must contain at least one value").of(Yup.string()),
        paymentMethod: Yup.array().required("This is Required").min(1, "Field must contain at least one value").of(Yup.string()),
        billingmodel: Yup.string().required("This is Required"),
        inventoryType: Yup.string().required("This is Required"),
        oneTimeCharge: Yup.string().required("This is Required"),
        dueDate: Yup.string().required("This is Required"),
        BillCreationDate: Yup.string().required("This is Required"),
        latefeeCharge: Yup.string().required("This is Required"),
        applyLateFee: Yup.string().required("This is Required"),
        makeModel: Yup.string().required("This is Required"),
        subsequentBillCreateDate: Yup.string().required("This is Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            billingmodel: "",
            inventoryType: "",
            oneTimeCharge: "",
            monthlyCharge: [],
            dueDate: "",
            makeModel: "",
            paymentMethod: [],
            selectdiscount: [],
            discountname: "",
            amount: "",
            BillCreationDate: "",
            additionalFeature: [],
            featureName: "",
            featureAmount: "",
            latefeeCharge: "",
            applyLateFee: "",
            subsequentBillCreateDate: "",
        },
        onSubmit: async (values, actions) => {
            setIsLoading(true);
            const dataToSend = {
                ServiceProvider: parseLoginRes?.company,
                billingmodel: formik.values.billingmodel,
                inventoryType: formik.values.inventoryType,
                oneTimeCharge: formik.values.oneTimeCharge,
                monthlyCharge: formik.values.monthlyCharge,
                dueDate: formik.values.dueDate,
                paymentMethod: formik.values.paymentMethod,
                selectdiscount: formik.values.selectdiscount,
                BillCreationDate: formik.values.BillCreationDate,
                additionalFeature: formik.values.additionalFeature,
                latefeeCharge: formik.values.latefeeCharge,
                applyLateFee: formik.values.applyLateFee,
                applyToCustomer: formik.values.applyToCustomer,
                make: formik.values.makeModel.substring(0, formik.values.makeModel.indexOf("-") - 1),
                devicemodel: formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2),
                subsequentBillCreateDate: formik.values.subsequentBillCreateDate,
            };

            try {
                const response = await api.post(`/api/web/billing/billconfig`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Bill Configured Successfully");
                    getConfigData();
                    actions.resetForm();
                    setIsLoading(false);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
        },
    });

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [optionsForBillingmodel, setOptionsForBillingModel] = useState([]);
    const [optionsForInventoryType, setOptionForInventoryType] = useState([]);
    const [onChangeBillingModel, setOnChangeBillingModel] = useState(false);

    const optionsForPayment = [
        // { label: "Cash", value: "Cash" },
        // { label: "Credit Card", value: "Credit Card" },
        // { label: "Credit Card (Authorize)", value: "Credit Card Authorize" },
        { label: "Credit Card", value: "Credit Card Stripe" },
        //{ label: "Money Order", value: "Money Order" },
        // { label: "E-Check", value: "E-Check" },
        //{ label: "E-Check (Authorize)", value: "E-Check Authorize" },
        // { label: "E-Check (Stripe)", value: "E-Check Stripe" },
        { label: "Skip Payment", value: "Skip Payment" },
    ];
    const optionsForCreation = [
        { label: "On Activation", value: "On Activation" },
        { label: "After QA Approval ", value: "On QA Approval" },
    ];

    const getDiscount = async () => {
        try {
            const res = await api.get(`/api/web/discount/getalldiscounts`);
            setAllDiscount(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const getFeature = async () => {
        try {
            const res = await api.get(`/api/web/feature/getallfeatures`);
            setAllFeature(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const getConfigData = async () => {
        try {
            const res = await api.get(`/api/web/billing/getall`);
            setconfigData(res?.data?.data || []);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            toast.error(error?.response?.data?.msg);
            setLoader(false);
        }
    };
    const getBillingModelList = async () => {
        try {
            const res = await api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`);

            setOptionsForBillingModel(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const getInventoryList = async () => {
        if (formik.values.billingmodel !== "") {
            try {
                const res = await api.get(`/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                let obj = [];
                let data = res?.data?.data;
                data.forEach((item) => {
                    let obj2 = {};
                    obj2.inventoryType = item;
                    obj.push(obj2);
                });
                setOptionForInventoryType(obj);
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        }
    };

    useEffect(() => {
        getDiscount();
        getConfigData();
        getFeature();
    }, [refresh]);
    useEffect(() => {
        getBillingModelList();
    }, [refreshOnBillingConfig]);
    useEffect(() => {
        getInventoryList();
    }, [onChangeBillingModel]);
    useEffect(() => {
        if (formik.values.inventoryType !== "" && formik.values.makeModel !== "") {
            api.get(
                `/api/web/plan/getByInventoryType?inventoryType=${formik.values.inventoryType}&make=${formik.values.makeModel?.substring(0, formik.values.makeModel.indexOf("-") - 1)}&model=${formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2)}&serviceProvider=${parseLoginRes.company}&billingModel=${formik.values.billingmodel}`,
            ).then((res) => {
                formik.setFieldValue("monthlyCharge", []);
                setAllPlan(res?.data?.data || []);
            });
        }
    }, [formik.values.inventoryType, formik.values.makeModel]);

    const addDiscount = async () => {
        setIsDiscountLoading(true);
        const dataToSend = {
            ServiceProvider: parseLoginRes?.company,
            discountname: formik.values.discountname,
            amount: formik.values.amount,
        };

        try {
            const response = await api.post(`/api/web/discount/adddiscount`, dataToSend);
            if (response?.status == 200 || response?.status == 201) {
                formik.setFieldValue("discountname", "");
                formik.setFieldValue("amount", "");
                setNewDiscount(false);
                getDiscount();
                toast.success("Discount added successfully");
                setIsDiscountLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsDiscountLoading(false);
        }
    };

    const addFeature = async () => {
        setIsFeatureLoading(true);
        const dataToSend = {
            ServiceProvider: parseLoginRes?.company,
            featureName: formik.values.featureName,
            featureAmount: formik.values.featureAmount,
        };

        try {
            const response = await api.post(`/api/web/feature/addfeature`, dataToSend);
            if (response?.status == 200 || response?.status == 201) {
                setIsFeatureLoading(false);
                formik.setFieldValue("featureName", "");
                formik.setFieldValue("featureAmount", "");
                setNewFeature(false); // Fix the typo here
                getFeature();
                toast.success("Feature added successfully");
            }
        } catch (error) {
            setIsFeatureLoading(false);
            toast.error(error?.response?.data?.msg);
        }
    };

    // {
    //     useEffect(() => {
    //         const getPlan = async () => {
    //             try {
    //                 const res = await api.get(`/api/web/plan/all?serviceProvider=${parseLoginRes?.company}`);
    //                 setAllPlan(res?.data?.data || []);
    //             } catch (error) {
    //                 toast.error(error?.response?.data?.msg);
    //             }
    //         };
    //         getPlan();
    //     }, []);
    // }

    useEffect(() => {
        if (formik.values.inventoryType !== "" && formik.values.billingmodel !== "") {
            // api.get(`/api/inventoryType/getSellingPrice?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}`)
            api.get(
                `/api/inventoryType/getSellingPriceByMakeModel?inventoryType=${formik.values.inventoryType}&make=${formik.values.makeModel?.substring(0, formik.values.makeModel.indexOf("-") - 1)}&model=${formik.values.makeModel?.substring(0, formik.values.makeModel.indexOf("-") - 1)}&model=${formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2)}`,
            )
                .then((res) => {
                    formik.setFieldValue("oneTimeCharge", res?.data?.OneTimeCharges);
                })
                .catch((err) => {});
        }
    }, [formik.values.inventoryType, formik.values.billingmodel, formik.values.makeModel, formik.values.devicemodel]);
    const [makeModelOptions, setMakeModelOptions] = useState();
    useEffect(() => {
        if (formik.values.inventoryType !== "" && currentBillingId !== "") {
            api.get(`/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    let Data = res?.data?.data;
                    let dataNew = [];
                    for (let i = 0; i < Data.length; i++) {
                        let data = Data[i];
                        data.makemodel = `${data.make} - ${data.model}`;
                        dataNew.push(data);
                    }
                    setMakeModelOptions(dataNew);
                })
                .catch((err) => {});
        }
    }, [currentBillingId, formik.values.inventoryType]);
    const statusOverlayRef = useRef(null);
    return (
        <div className="billingmain">
            <Dialog
                header="Billing Model Configurations"
                visible={configBillingModel}
                className="pt-0"
                style={{ width: "80vw" }}
                onHide={() => {
                    setConfigBillingModel(false);
                    setRefreshOnBillingConfig((prev) => !prev);
                }}
            >
                <ListAllBilling />
            </Dialog>

            <ToastContainer />
            {updatePlanVisibility ? (
                <UpdateBill rowData={currentRow} setRefresh={setRefresh} optionsForInventoryType={optionsForInventoryType} setUpdatePlanVisibility={setUpdatePlanVisibility} />
            ) : (
                <form className="billingform " onSubmit={formik.handleSubmit} ref={formRef}>
                    <div className="forminner">
                        <p>Configuration</p>
                        <div className="addbillingfield">
                            <div className="flex justify-content-between" style={{ width: "33.2rem" }}>
                                <label className="mt-0">Billing Model </label>
                                {formik.touched.billingmodel && formik.errors.billingmodel ? <p className="p-error">{formik.errors.billingmodel}</p> : null}
                            </div>
                            <Dropdown
                                className={formik.touched.billingmodel && formik.errors.billingmodel ? "error calendar_field" : "calendar_field"}
                                id="billingmodel"
                                options={optionsForBillingmodel}
                                value={formik.values.billingmodel}
                                placeholder="Billing Model"
                                optionLabel="billingModel"
                                optionValue="billingModel"
                                onChange={(e) => {
                                    formik.setFieldValue("billingmodel", e.value);
                                    let id;
                                    optionsForBillingmodel.map((item) => {
                                        if (item.billingModel === e.value) {
                                            id = item._id;
                                        }
                                    });

                                    formik.handleChange(e);
                                    // formik.setFieldValue("billingmodel", "");
                                    setCurrentBillingId(id);
                                    setOnChangeBillingModel((prev) => !prev);
                                }}
                            />
                        </div>
                        <label style={{ fontSize: "1.2rem", fontWeight: "600", color: "#72728F" }} className="addbillingfield">
                            Add Inventory Details
                        </label>
                        <div>
                            <div className="mainbillingconfig flex w-full flex-wrap flex-row justify-content-left  ">
                                <Toast ref={toast2} />
                                <div className="flex">
                                    <div className="addbillingfield ">
                                        <div className="flex justify-content-between">
                                            <label className="">Inventory Type</label>
                                            {/* {formik.touched.inventoryType && formik.errors.inventoryType ? <p className="p-error">{formik.errors.inventoryType}</p> : null} */}
                                        </div>
                                        <Dropdown
                                            // className={formik.touched.inventoryType && formik.errors.inventoryType ? "error calendar_field" : "calendar_field"}
                                            className={formik.touched.inventoryType && formik.errors.inventoryType ? " calendar_field" : "calendar_field"}
                                            id="inventoryType"
                                            placeholder="Inventory Name"
                                            options={optionsForInventoryType}
                                            value={formik.values.inventoryType}
                                            optionLabel="inventoryType"
                                            optionValue="inventoryType"
                                            onChange={(e) => {
                                                formik.setFieldValue("inventoryType", e.value);
                                                formik.handleChange(e);
                                                formik.setFieldValue("oneTimeCharge", "");
                                            }}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Make And Model</label>
                                            {formik.touched.makeModel && formik.errors.makeModel ? <p className="p-error">{formik.errors.makeModel}</p> : null}
                                        </div>
                                        <Dropdown
                                            optionLabel="makemodel"
                                            optionValue="makemodel"
                                            value={formik.values.makeModel}
                                            name="makeModel"
                                            options={makeModelOptions}
                                            onChange={formik.handleChange}
                                            placeholder="Select an option"
                                            className={formik.touched.makeModel && formik.errors.makeModel ? "error calendar_field" : "calendar_field"}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label className="">One Time Charges</label>
                                            {/* {formik.touched.oneTimeCharge && formik.errors.oneTimeCharge ? <p className="p-error">{formik.errors.oneTimeCharge}</p> : null} */}
                                        </div>
                                        <InputText
                                            disabled
                                            id="oneTimeCharge"
                                            className={formik.touched.oneTimeCharge && formik.errors.oneTimeCharge ? " calendar_field" : "calendar_field"}
                                            placeholder="Enter One Time Charges"
                                            value={formik.values.oneTimeCharge}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="addbillingfield">
                                        <div className="flex justify-content-between">
                                            <label>Monthly Charges</label>
                                            {/* {formik.touched.monthlyCharge && formik.errors.monthlyCharge ? <p className="p-error">{formik.errors.monthlyCharge}</p> : null} */}
                                        </div>
                                        <MultiSelect
                                            id="monthlyCharge"
                                            display="chip"
                                            options={allPlan}
                                            placeholder="Monthly Charges"
                                            value={formik.values.monthlyCharge}
                                            onChange={(e) => formik.setFieldValue("monthlyCharge", e.value)}
                                            optionLabel={(option) => `${option.name} - (${option.planId})`}
                                            optionValue="_id"
                                            className={formik.touched.monthlyCharge && formik.errors.monthlyCharge ? "error calendar_field" : "calendar_field"}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>First Bill Create Date</label>
                                            {formik.touched.BillCreationDate && formik.errors.BillCreationDate ? <p className="p-error">{formik.errors.BillCreationDate}</p> : null}
                                        </div>
                                        <Dropdown
                                            className={formik.touched.BillCreationDate && formik.errors.BillCreationDate ? "error calendar_field" : "calendar_field"}
                                            id="BillCreationDate"
                                            placeholder="First Bill Create Date"
                                            options={optionsForCreation}
                                            value={formik.values.BillCreationDate}
                                            onChange={(e) => {
                                                formik.setFieldValue("BillCreationDate", e.value);
                                                formik.handleChange(e);
                                            }}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Subsequent Bill Create Date </label>
                                            {formik.touched.subsequentBillCreateDate && formik.errors.subsequentBillCreateDate ? <p className="p-error">{formik.errors.subsequentBillCreateDate}</p> : null}
                                        </div>
                                        <InputText
                                            id="subsequentBillCreateDate"
                                            className={formik.touched.subsequentBillCreateDate && formik.errors.subsequentBillCreateDate ? "error calendar_field" : "calendar_field"}
                                            placeholder="No of Days From First Bill Create Date"
                                            value={formik.values.subsequentBillCreateDate}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="addbillingfield">
                                        <div className="flex justify-content-between">
                                            <label>Due Date</label>
                                            {formik.touched.dueDate && formik.errors.dueDate ? <p className="p-error">{formik.errors.dueDate}</p> : null}
                                        </div>
                                        <InputText
                                            id="dueDate"
                                            placeholder="No of days From Bill Create Date"
                                            className={formik.touched.dueDate && formik.errors.dueDate ? "error calendar_field" : "calendar_field"}
                                            value={formik.values.dueDate}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Late Fee Charge</label>
                                            {formik.touched.latefeeCharge && formik.errors.latefeeCharge ? <p className="p-error">{formik.errors.latefeeCharge}</p> : null}
                                        </div>
                                        <InputText
                                            id="latefeeCharge"
                                            placeholder="Late Fee Charge"
                                            value={formik.values.latefeeCharge}
                                            className={formik.touched.latefeeCharge && formik.errors.latefeeCharge ? "error calendar_field" : "calendar_field"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Apply Late Fee </label>
                                            {formik.touched.applyLateFee && formik.errors.applyLateFee ? <p className="p-error">{formik.errors.applyLateFee}</p> : null}
                                        </div>
                                        <InputText
                                            id="applyLateFee"
                                            placeholder="No of Days from Due Date"
                                            className={formik.touched.applyLateFee && formik.errors.applyLateFee ? "error calendar_field" : "calendar_field"}
                                            value={formik.values.applyLateFee}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="addbillingfield">
                                        <div className="flex justify-content-between">
                                            <label>Payment Method</label>
                                            {formik.touched.paymentMethod && formik.errors.paymentMethod ? <p className="p-error">{formik.errors.paymentMethod}</p> : null}
                                        </div>
                                        <MultiSelect
                                            className={formik.touched.paymentMethod && formik.errors.paymentMethod ? "error calendar_field" : "calendar_field"}
                                            id="paymentMethod"
                                            placeholder="Select Payment Method"
                                            options={optionsForPayment}
                                            display="chip"
                                            optionLabel="label"
                                            optionValue="label"
                                            value={formik.values.paymentMethod}
                                            onChange={(e) => {
                                                formik.setFieldValue("paymentMethod", e.value);
                                                formik.handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <label>Select Discount</label>
                                        <MultiSelect
                                            id="selectdiscount"
                                            display="chip"
                                            placeholder="Select Discounts"
                                            options={allDiscount}
                                            value={formik.values.selectdiscount}
                                            onChange={(e) => formik.setFieldValue("selectdiscount", e.value)}
                                            optionLabel={(option) => `${option.discountname} - (${option.amount})`}
                                            optionValue="_id"
                                            className="calendar_field"
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <label>Additional Feature</label>
                                        <MultiSelect
                                            id="additionalFeature"
                                            display="chip"
                                            placeholder="Select Additional Features"
                                            options={allFeature}
                                            value={formik.values.additionalFeature}
                                            onChange={(e) => formik.setFieldValue("additionalFeature", e.value)}
                                            optionLabel={(option) => `${option.featureName} - (${option.featureAmount})`}
                                            optionValue="_id"
                                            className="calendar_field"
                                        />
                                    </div>
                                </div>
                                <div className="flex" style={{ marginTop: "1.8rem" }}>
                                    <a
                                        className="linkfordiscount"
                                        onClick={() => {
                                            if (newDiscount || newFeature) {
                                            } else {
                                                setNewDiscount(true);
                                                setNewFeature(false);
                                            }
                                        }}
                                        style={{ opacity: newDiscount || newFeature ? "0.5" : "1" }}
                                    >
                                        Add Discount
                                    </a>
                                    <a
                                        className="linkfordiscount"
                                        onClick={() => {
                                            if (newDiscount || newFeature) {
                                            } else {
                                                setNewDiscount(false);
                                                setNewFeature(true);
                                            }
                                        }}
                                        style={{ opacity: newDiscount || newFeature ? "0.5" : "1", marginLeft: "2.4rem" }}
                                    >
                                        Add Feature
                                    </a>
                                </div>
                            </div>
                            {newDiscount ? (
                                <div className="flex" style={{ marginTop: "1.6rem" }}>
                                    <div className="addbillingfield" style={{ marginTop: "0rem" }}>
                                        <label>Discount Name</label>
                                        <InputText id="discountname" placeholder="Type Name" className="calendar_field" value={formik.values.discountname} onChange={formik.handleChange} />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem", marginTop: "0rem" }}>
                                        <label>Amount </label>
                                        <InputText id="amount" placeholder="$00.00" className="calendar_field" value={formik.values.amount} onChange={formik.handleChange} />
                                    </div>
                                    <div style={{ marginTop: "1.2rem", marginLeft: "1.2rem", width: "33.2rem", gap: "1.6rem" }} className="flex align-items-end justify-content-start">
                                        <span
                                            onClick={() => {
                                                setNewFeature(false);
                                                setNewDiscount(false);
                                            }}
                                            className="discountbutton"
                                        >
                                            <Falsebutton />
                                        </span>

                                        <span
                                            onClick={addDiscount}
                                            onMouseEnter={() => {
                                                setMouseEnter(true);
                                            }}
                                            onMouseLeave={() => {
                                                setMouseEnter(false);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {mouseEnter ? <Tickbuttonfill /> : <Tickbutton />}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            {newFeature ? (
                                <div className="flex" style={{ marginTop: "1.6rem" }}>
                                    <div className="addbillingfield" style={{ marginTop: "0rem" }}>
                                        <label>Feature Name</label>
                                        <InputText id="featureName" placeholder="Type Name" className="calendar_field" value={formik.values.featureName} onChange={formik.handleChange} />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem", marginTop: "0rem" }}>
                                        <label> Amount </label>
                                        <InputText id="featureAmount" placeholder="$00.00" className="calendar_field" value={formik.values.featureAmount} onChange={formik.handleChange} />
                                    </div>
                                    <div style={{ marginTop: "1.2rem", marginLeft: "1.2rem", width: "33.2rem", gap: "1.6rem" }} className="flex align-items-end justify-content-start">
                                        <span
                                            onClick={() => {
                                                setNewFeature(false);
                                                setNewDiscount(false);
                                            }}
                                            className="discountbutton"
                                        >
                                            <Falsebutton />
                                        </span>

                                        <span
                                            onClick={addFeature}
                                            onMouseEnter={() => {
                                                setMouseEnter(true);
                                            }}
                                            onMouseLeave={() => {
                                                setMouseEnter(false);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {mouseEnter ? <Tickbuttonfill /> : <Tickbutton />}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="flex justify-content-end " style={{ width: "102rem", marginTop: "3.2rem", gap: "1.6rem" }}>
                        <Button
                            className="backbtn"
                            style={{ width: "16rem", height: "5rem" }}
                            label="Cancel"
                            type="button"
                            onClick={() => {
                                setUpdatePlanVisibility(false);
                            }}
                        />
                        <Button className="nextbtn" style={{ width: "16rem", height: "5rem" }} loading={isLoading} disabled={isLoading} label="Submit" type="Submit" />
                    </div>
                </form>
            )}

            <div className="billingmainheader">
                <div className="flex flex-column w-full justify-content-start " style={{ height: "6.3rem" }}>
                    <h1 className="">Billing Configurations</h1>
                    <p style={{ marginTop: "1.2rem" }}>View and manage all billing configurations in one place, ensuring streamlined processes and accurate setup for your business needs.</p>
                </div>
                {/* <Dialog
                    visible={updatePlanVisibility}
                    style={{ width: "80vw" }}
                    header="Update Billing Configuration "
                    onHide={() => {
                        setUpdatePlanVisibility(false);
                    }}
                >
                    <UpdateBill rowData={currentRow} setRefresh={setRefresh} optionsForInventoryType={optionsForInventoryType} setUpdatePlanVisibility={setUpdatePlanVisibility} />
                </Dialog> */}
                <div className="billingconfigtable">
                    <DataTable
                        emptyMessage={
                            <div style={{ position: "absolute", marginTop: "-1.4rem", width: "108.4rem" }} className="flex flex-wrap justify-content-center align-items-center">
                                <CommonLoaderBlue color="royalblue" customSize={14} />
                            </div>
                        }
                        value={configData}
                        showGridlines
                        resizableColumns
                        columnResizeMode="fit"
                        style={{ marginTop: "2.4rem", width: "157.2rem" }}
                        className="billingdatatable"
                    >
                        <Column
                            header="Billing Model"
                            field="billingmodel"
                            body={(rowData) => {
                                return <p>{rowData?.billingmodel}</p>;
                            }}
                        />
                        <Column
                            header="Inventory Type"
                            field="inventoryType"
                            body={(rowData) => {
                                const truncatedText = rowData?.inventoryType.length > 16 ? rowData?.inventoryType.slice(0, 16) + "..." : rowData?.inventoryType;
                                return <p>{truncatedText}</p>;
                            }}
                        />
                        <Column
                            header="One Time Charges"
                            field="oneTimeCharge"
                            body={(rowData) => {
                                return `$${rowData?.oneTimeCharge}`;
                            }}
                        />

                        <Column
                            header="Plan Name"
                            body={(rowData) => {
                                const text = rowData?.monthlyCharge?.map((mc) => mc.name).join(", ") || "";
                                const maxChars = 14;
                                return (
                                    <>
                                        <p
                                            onMouseEnter={(e) => {
                                                if (text.length > maxChars) {
                                                    setOverlayContent(text);
                                                    statusOverlayRef.current.show(e);
                                                }
                                            }}
                                            onMouseLeave={() => statusOverlayRef.current.hide()}
                                        >
                                            {text.length > maxChars ? text.substring(0, maxChars) + "..." : text.length === 0 ? "NIL" : text}
                                        </p>
                                        <OverlayPanel ref={statusOverlayRef}>
                                            <div style={{ maxWidth: "20rem", padding: "5px 10px" }}>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: "1.4rem",
                                                        overflow: "hidden",
                                                        border: "none",
                                                        margin: "0px",
                                                        lineHeight: "1.8rem",
                                                        color: "#72728f",
                                                    }}
                                                >
                                                    {" "}
                                                    {overlayContent}
                                                </p>
                                            </div>
                                        </OverlayPanel>
                                    </>
                                );
                            }}
                        />
                        <Column header="Created At" field="BillCreationDate" />
                        <Column header="Due Date" field="Due Date" body={(rowData) => `${rowData?.dueDate} ${rowData?.dueDate == 1 ? "Day" : "Days"} `} />
                        <Column header="Billing Days" field="subsequentBillCreateDate" />
                        <Column header="Late Fee   " field="Late Fee" body={(rowData) => `$${rowData.latefeeCharge}`} />
                        <Column header="Late Fee Days" field="Apply Late Fee" body={(rowData) => `${rowData.applyLateFee} ${rowData?.applyLateFee === 1 ? "Day Late" : "Days Late"}`} />
                        <Column
                            header="Feature"
                            body={(rowData) => {
                                let additionalFeature = rowData?.additionalFeature?.map((sd) => `${sd.featureName} - $${sd.featureAmount}`).join(", ");

                                return (
                                    <>
                                        <p
                                            onMouseEnter={(e) => {
                                                if (additionalFeature?.length > 23) {
                                                    setOverlayContent(additionalFeature);
                                                    statusOverlayRef.current.show(e);
                                                }
                                            }}
                                            onMouseLeave={() => statusOverlayRef.current.hide()}
                                        >
                                            {additionalFeature.length > 23 ? additionalFeature.substring(0, 23) + "..." : additionalFeature.length === 0 ? "NIL" : additionalFeature}{" "}
                                        </p>
                                        <OverlayPanel ref={statusOverlayRef}>
                                            <div style={{ maxWidth: "20rem", padding: "5px 10px" }}>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: "1.4rem",
                                                        overflow: "hidden",
                                                        border: "none",
                                                        margin: "0px",
                                                        lineHeight: "1.8rem",
                                                        color: "#72728f",
                                                    }}
                                                >
                                                    {" "}
                                                    {overlayContent}
                                                </p>
                                            </div>
                                        </OverlayPanel>
                                    </>
                                );
                            }}
                        />

                        {/* <Column header="Feature" body={(rowData) => rowData?.additionalFeature?.map((sd) => `${sd.featureName} - $${sd.featureAmount}`).join(", ")} /> */}
                        <Column
                            header="Discount"
                            body={(rowData) => {
                                let selectedDiscounts = rowData?.selectdiscount?.map((sd) => `${sd?.discountname} - $${sd?.amount}`).join(", ");

                                return (
                                    <>
                                        <p
                                            onMouseEnter={(e) => {
                                                if (selectedDiscounts?.length > 40) {
                                                    setOverlayContent(selectedDiscounts);
                                                    statusOverlayRef.current.show(e);
                                                }
                                            }}
                                            onMouseLeave={() => statusOverlayRef.current.hide()}
                                        >
                                            {selectedDiscounts?.length > 40 ? selectedDiscounts?.substring(0, 40) + "..." : selectedDiscounts?.length === 0 ? "NIL" : selectedDiscounts.length === 0 ? "NIL" : selectedDiscounts}{" "}
                                        </p>
                                        <OverlayPanel ref={statusOverlayRef}>
                                            <div style={{ maxWidth: "20rem", padding: "5px 10px" }}>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: "1.4rem",
                                                        overflow: "hidden",
                                                        border: "none",
                                                        margin: "0px",
                                                        lineHeight: "1.8rem",
                                                        color: "#72728f",
                                                    }}
                                                >
                                                    {" "}
                                                    {overlayContent}
                                                </p>
                                            </div>
                                        </OverlayPanel>
                                    </>
                                );
                            }}
                        />
                        <Column
                            header="Pay Method"
                            field="paymentMethod"
                            body={(rowData) => {
                                let paymentMethod = rowData.paymentMethod.join(", ");
                                return (
                                    <>
                                        <p
                                            onMouseEnter={(e) => {
                                                if (paymentMethod?.length > 22) {
                                                    setOverlayContent(paymentMethod);
                                                    statusOverlayRef.current.show(e);
                                                }
                                            }}
                                            onMouseLeave={() => statusOverlayRef.current.hide()}
                                        >
                                            {paymentMethod.length > 22 ? paymentMethod.substring(0, 22) + "..." : paymentMethod.length === 0 ? "NIL" : paymentMethod}
                                        </p>
                                        <OverlayPanel ref={statusOverlayRef}>
                                            <div style={{ maxWidth: "20rem", padding: "5px 10px" }}>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: "1.4rem",
                                                        overflow: "hidden",
                                                        border: "none",
                                                        margin: "0px",
                                                        lineHeight: "1.8rem",
                                                        color: "#72728f",
                                                    }}
                                                >
                                                    {overlayContent}
                                                </p>
                                            </div>
                                        </OverlayPanel>
                                    </>
                                );
                            }}
                        />
                        <Column
                            header="Actions"
                            body={(rowData) => {
                                return (
                                    <>
                                        <div
                                            className="actionbtnmain"
                                            onClick={(e) => {
                                                op.current.toggle(e);
                                                setCurrentRow(rowData);
                                            }}
                                            style={{ paddingLeft: "2.5rem" }}
                                        >
                                            <PlanEditIcon />
                                        </div>
                                        <OverlayPanel ref={op} style={{ width: "10.9rem", height: "8rem" }} className="flex align-items-center billingconfig">
                                            <div className="flex flex-column w-full overlay">
                                                <div
                                                    className="flex justify-content-start align-items-center prospectoverlay"
                                                    style={{ height: "32px", width: "10.7rem", paddingLeft: "1.2rem" }}
                                                    onClick={() => {
                                                        setUpdatePlanVisibility(true);
                                                        scrollToForm();
                                                    }}
                                                >
                                                    <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Update</label>
                                                </div>
                                                <div
                                                    className="flex justify-content-start align-items-center prospectoverlay"
                                                    style={{ height: "32px", width: "10.7rem", paddingLeft: "1.2rem" }}
                                                    onClick={() => {
                                                        setRowData(rowData);
                                                        setVisible(true);
                                                    }}
                                                >
                                                    <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Delete</label>
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog
                headerClassName="dialogheader"
                closable={false}
                visible={visible}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
                className="delete-dialog"
            >
                <div className="flex flex-column">
                    <div className="datadelete">
                        <h1>Confirm Deletion</h1>
                        <p> Are you sure you want to delete this plan? This action cannot be undone. Proceed with caution.</p>{" "}
                    </div>
                    <div className="deletedialogbtn">
                        <Button className="backbtn" label="Cancel" onClick={() => setVisible(false)} style={{ width: "16rem", height: "5rem" }} />
                        <Button
                            label="Delete"
                            className="nextbtn"
                            loading={delteBillingLoader}
                            disabled={delteBillingLoader}
                            onClick={() => {
                                setDeleteBillingLoader(true);
                                api.delete(`/api/web/billing/deletebillconfig?billId=${rowData._id}`)
                                    .then(() => {
                                        toast.success("Plan Deleted Successfully ");
                                        setRefresh((prev) => !prev);
                                        setTimeout(() => {
                                            setVisible(false);
                                        }, 500);
                                    })
                                    .catch((err) => {
                                        toast.error("Plan Deletion Failed");
                                        setDeleteBillingLoader(false);
                                    });
                            }}
                            style={{ width: "16rem", height: "5rem" }}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default BillingConfiguration;
