import { api } from "../../../../../../../utility/AxiosInceptor";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { debounce } from "lodash";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import DialogForReject from "./dialogs/DialogForReject";
import DialogForActivateSim from "./dialogs/DialogForActivateSim";
import { InputText } from "primereact/inputtext";
import DialogeForRemarks from "./dialogs/DialogeForRemarks";
import DialogeForTransferUser from "./dialogs/DialogeForTransferUser";
import { OverlayPanel } from "primereact/overlaypanel";
import DialogeForRemarksForIJ from "./dialogs/DialogeForRemarksForIJ";
import "./styles.css";
import "./all_enrollment.css";
import { Editbutton, Status, Calendar, DownloadIcon, NextIcon, PrevIcon, EmptyEnrollment, PrepaidSims } from "../../../../../../../utility";
import CommonLoaderBlue from "../../../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
import DateRangeCalendar from "./Calendar/Calendar-DropDown";
import { Checkbox } from "primereact/checkbox";
const PrepaidAllEnrollments = () => {
    const [overlayContent, setOverlayContent] = useState("");
    const statusOverlayRef = useRef(null);

    const [isFirstTimeCalledApi, setIsFirstTimeCalledApi] = useState(false);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [listLimit, setListLimit] = useState(18);
    const [currentPagination, setCurrentPagination] = useState({});
    const [floatPagesLeft, setFloatPagesLeft] = useState([]);
    const [floatPagesRight, setFloatPagesRight] = useState([]);
    const [hiddenPages, setHiddenPages] = useState([]);
    const [statusFiltersSelected, setStatusFiltersSelected] = useState({
        prospectchecked: false,
        cancelchecked: false,
        all: false,
    });
    const [secondTimeCalled, setSecondTimeCalled] = useState(false);

    const [isEnrolmentId, setIsEnrolmentId] = useState();
    const [CsrId, setCsrId] = useState();
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [zipCode, setZipCode] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDialogeForActivate, setOpenDialogeForActivate] = useState(false);
    const [OpenDialogeForRemarks, setOpenDialogeForRemarks] = useState(false);
    const [OpenDialogeForRemarksForIJ, setOpenDialogeForRemarksForIJ] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isButtonLoading, setisButtonLoading] = useState(false);
    const [link, setLink] = useState();
    const [allEnrollments, setAllEnrollments] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [checkType, setCheckType] = useState();
    const [customerSearchName, setCustomerSearchName] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [dialogeForTransfer, setDialogeForTransfer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [checkRemarks, setCheckRemarks] = useState();

    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [enrollmentFrom, setEnrollmentFrom] = useState(1);
    const [enrollmentTo, setEnrollmentTo] = useState(15);
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [shortSelection, setShortSelection] = useState();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [role, setRole] = useState(userData?.role?.role);
    console.log("role is", role);
    const op = useRef(null);
    const date = useRef(null);
    const navigate = useNavigate();
    const handleEnrollmentIdClick = (rowData) => {
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
    };
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setCurrentPage(1);
    }, [customerSearchName, statusFiltersSelected, selectedRange]);

    const getAllEnrollments = useCallback(
        debounce(async () => {
            let status = [];
            if (statusFiltersSelected.prospectchecked) {
                status.push("prospect");
            }
            if (statusFiltersSelected.cancelchecked) {
                status.push("cancelled");
            }
            status = status.join(",");
            try {
                const res = await api.get(
                    `/api/user/EnrollmentApprovedByUser?userId=${parseLoginRes?._id}&status=${status}&endDate=${selectedRange[1] ? moment(new Date(selectedRange[1])).format("MM-DD-YYYY") : ""}&startDate=${selectedRange[0] ? moment(new Date(selectedRange[0])).format("MM-DD-YYYY") : ""}&search=${customerSearchName}&page=${currentPage}&limit=${listLimit}&accountType=Prepaid`,
                );
                if (res?.status === 200 || res?.status === 201) {
                    if (!res?.data?.data) {
                        toast.success(" No enrollments have been received from the previous department yet");
                    } else if (res?.data?.data) {
                        const updatedData = res?.data?.data.map((item) => ({
                            ...item,
                            enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                            name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                            createdDate: new Date(item.createdAt)
                                .toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                })
                                .replace(/\//g, "-"),
                            createdTo: item.createdAt,
                        }));
                        setCurrentPagination({ total: res?.data?.totalRecords, limit: listLimit, page: res?.data?.currentPage, totalPages: res?.data?.totalPages });
                        if (res?.data?.totalPages > 6) {
                            if (floatPagesLeft.length === 0 || res?.data?.totalPages !== currentPagination.totalPages) {
                                setFloatPagesLeft([1, 2, 3]);
                                setHiddenPages([...Array.from({ length: res?.data?.totalPages - 5 }, (_, index) => 4 + index)]);
                                setFloatPagesRight([res?.data?.totalPages - 1, res?.data?.totalPages]);
                            }
                        }
                        setAllEnrollments(updatedData);
                        setCompleteListEnrollment(updatedData);
                        if (isFirstTimeCalledApi) {
                            setSecondTimeCalled(true);
                        }
                        setIsFirstTimeCalledApi(true);
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 500);
                    }
                }
            } catch (error) {
                if (isFirstTimeCalledApi) {
                    setSecondTimeCalled(true);
                }
                setCurrentPagination({
                    total: 0,
                    limit: 0,
                    page: 0,
                    totalPages: 0,
                });
                setAllEnrollments([]);
                setCompleteListEnrollment([]);
                if (!secondTimeCalled) {
                    toast.error(`Error Fetching Enrollment data: ${error?.response?.data?.msg}`);
                }
                setIsLoading(false);
                setIsFirstTimeCalledApi(true);
            }
        }, 500),
        [statusFiltersSelected, selectedRange, currentPage, customerSearchName],
    );

    useEffect(() => {
        getAllEnrollments();
        return () => getAllEnrollments.cancel();
    }, [getAllEnrollments]);
    return (
        <div className="prepaidall">
            <ToastContainer className="custom-toast-container" />
            <form>
                <Dialog visible={isModalOpen} style={{ width: "50vw" }} draggable={false} onHide={() => setIsModalOpen(false)}>
                    <DialogForReject setIsModalOpen={setIsModalOpen} checkType={checkType} enrollmentId={isEnrolmentId} CSRid={CsrId} getAllEnrollments={getAllEnrollments} />
                </Dialog>
                <Dialog header={"Activate Sim"} visible={openDialogeForActivate} style={{ width: "70vw" }} onHide={() => setOpenDialogeForActivate(false)}>
                    <DialogForActivateSim enrollmentId={isEnrolmentId} setOpenDialogeForActivate={setOpenDialogeForActivate} zipCode={zipCode} />
                </Dialog>
                <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarks} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarks(false)}>
                    <DialogeForRemarks getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} />
                </Dialog>
                <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarksForIJ} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarksForIJ(false)}>
                    <DialogeForRemarksForIJ getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} setOpenDialogeForRemarksForIJ={setOpenDialogeForRemarksForIJ} />
                </Dialog>
                <Dialog header={"Transfer User"} visible={dialogeForTransfer} style={{ width: "30vw" }} onHide={() => setDialogeForTransfer(false)}>
                    <DialogeForTransferUser enrollmentId={isEnrolmentId} setDialogeForTransfer={setDialogeForTransfer} />
                </Dialog>
            </form>
            <div className="flex w-full flex-column justify-content-start h-4">
                <div className="prepaidsimslogo">
                    <span>
                        <PrepaidSims />
                    </span>
                    <h6>Prepaid SIMs</h6>
                </div>
                <h3 className="heading" style={{ marginTop: ".6rem" }}>
                    Enrollments Queue
                </h3>
                <p className="para" style={{ marginTop: "1.2rem" }}>
                    Track all enrollments with 'Prospect' or 'Cancelled' status and manage them easily from one unified view.{" "}
                </p>
                <div className="flex flex-wrap justify-content-start align-items-center w-full" style={{ marginTop: "2.4rem", height: "4rem" }}>
                    <div className="flex flex-wrap w-full justify-content-between align-items-center">
                        <div className="flex align-items-center prosp">
                            <h2 className="prosp-heading">Prospects/ Cancelled</h2>
                        </div>
                        {(!isLoading || allEnrollments?.length > 0) && (
                            <div className="flex align-items-center" style={{ width: "50.5rem", gap: "1.6rem" }}>
                                <InputText
                                    className="searchbar"
                                    value={customerSearchName}
                                    onChange={(e) => {
                                        setCustomerSearchName(e.target.value);
                                        setCurrentPage(1);

                                        if (e.target.value.length > 0) {
                                            let searchResult = completeListEnrollment.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

                                            setAllEnrollments(searchResult);
                                            setTotalIncaseSearch(searchResult.length);
                                            setTotalSearchResult(searchResult);
                                            setEnrollmentFrom(1);
                                            setEnrollmentTo(searchResult.length > 15 ? 15 : searchResult.length);
                                        } else {
                                            setTotalSearchResult([]);
                                            setAllEnrollments(completeListEnrollment);
                                            setEnrollmentFrom(1);
                                            setEnrollmentTo(completeListEnrollment.length > 15 ? 15 : completeListEnrollment.length);
                                        }
                                    }}
                                    placeholder="ID, Name, Contact, Date"
                                />
                                <div>
                                    <button className="selector" onClick={(e) => date.current.toggle(e)}>
                                        <Calendar />
                                    </button>
                                    <OverlayPanel ref={date}>
                                        <DateRangeCalendar calendar={date} setSelectedRange={setSelectedRange} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} />
                                    </OverlayPanel>
                                </div>
                                <div>
                                    <button className="selector" onClick={(e) => op.current.toggle(e)}>
                                        <Status />
                                    </button>
                                    <OverlayPanel ref={op} style={{ width: "11rem", height: "12.4rem" }}>
                                        <div style={{ paddingTop: "0.7rem", paddingBottom: "0.7rem" }} className=" flex flex-column w-full overlay">
                                            <div className="flex  justify-content-start align-items-center overlaycontent" style={{ height: "36px", width: "10.8rem" }}>
                                                <div style={{ gap: "0.8rem" }} className="flex w-full align-items-center overlayinnerdata">
                                                    <Checkbox onChange={(e) => setStatusFiltersSelected((prev) => ({ ...prev, prospectchecked: e.checked }))} checked={statusFiltersSelected.prospectchecked}></Checkbox>
                                                    <label>Prospect</label>
                                                </div>
                                            </div>
                                            <div className="flex  justify-content-start align-items-center overlaycontent" style={{ height: "36px", width: "10.8rem" }}>
                                                <div style={{ gap: "0.8rem" }} className="flex w-full align-items-center overlayinnerdata">
                                                    <Checkbox onChange={(e) => setStatusFiltersSelected((prev) => ({ ...prev, cancelchecked: e.checked }))} checked={statusFiltersSelected.cancelchecked}></Checkbox>
                                                    <label>Cancelled</label>
                                                </div>
                                            </div>
                                            <div className="flex  justify-content-start align-items-center overlaycontent" style={{ height: "36px", width: "10.8rem" }}>
                                                <div style={{ gap: "0.8rem" }} className="flex w-full align-items-center overlayinnerdata">
                                                    <Checkbox
                                                        onChange={(e) => setStatusFiltersSelected((prev) => ({ ...prev, allChecked: e.checked, prospectchecked: e.checked ? true : prev.prospectchecked, cancelchecked: e.checked ? true : prev.cancelchecked }))}
                                                        checked={statusFiltersSelected.allChecked}
                                                    ></Checkbox>
                                                    <label>All</label>
                                                </div>
                                            </div>
                                        </div>
                                    </OverlayPanel>
                                </div>
                                <div>
                                    <button
                                        onClick={async () => {
                                            setIsExportLoading(true);
                                            let status = [];
                                            if (statusFiltersSelected.prospectchecked) {
                                                status.push("prospect");
                                            }
                                            if (statusFiltersSelected.cancelchecked) {
                                                status.push("cancelled");
                                            }
                                            status = status.join(",");
                                            api.get(
                                                `/api/user/EnrollmentApprovedByUser?userId=${parseLoginRes?._id}&exportCustomers=true&status=${status}&endDate=${selectedRange[1] ? moment(new Date(selectedRange[1])).format("MM-DD-YYYY") : ""}&startDate=${selectedRange[0] ? moment(new Date(selectedRange[0])).format("MM-DD-YYYY") : ""}&search=${customerSearchName}&page=${currentPage}&limit=${listLimit}&accountType=Prepaid`,
                                                { responseType: "blob" },
                                            )
                                                .then((response) => {
                                                    const contentDisposition = response.headers["content-disposition"];
                                                    let filename = "Prepaid_EnrollmentQueue_CustomerData.xlsx";

                                                    if (contentDisposition) {
                                                        const match = contentDisposition.match(/filename\*=UTF-8''([^;]+)|filename="?([^";]+)"?/);
                                                        if (match) {
                                                            filename = decodeURIComponent(match[1] || match[2]);
                                                        }
                                                    }

                                                    const blob = new Blob([response.data], {
                                                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                    });

                                                    const url = window.URL.createObjectURL(blob);
                                                    const a = document.createElement("a");
                                                    a.href = url;
                                                    a.download = filename;
                                                    document.body.appendChild(a);
                                                    a.click();
                                                    window.URL.revokeObjectURL(url);
                                                    document.body.removeChild(a);

                                                    setIsExportLoading(false);
                                                })
                                                .catch((error) => {
                                                    setIsExportLoading(false);
                                                    toast.current.show({ severity: "error", summary: "Export Customer", detail: error?.response?.data?.message || "Exporting Customers Failed" });
                                                });
                                        }}
                                        className="export backbtn flex justify-content-center align-items-center"
                                    >
                                        {!isExportLoading ? (
                                            <div className="flex flex-wrap flex-row justify-content-start" style={{ marginTop: "-4px", height: "1.6rem", width: "6.9rem", gap: "0.4rem" }}>
                                                <p style={{ padding: "0px", margin: "0px" }}>Export</p>
                                                <div style={{ marginTop: "3px", marginLeft: "0px" }}>
                                                    <DownloadIcon />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                                <CommonLoaderBlue color={"royalblue"} customSize={14} />
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {allEnrollments.length === 0 && !isLoading && !secondTimeCalled ? (
                <div className="flex w-full justify-content-center " style={{ marginTop: "5rem" }}>
                    <div className="emptystatediv">
                        <div className="emptyicon">
                            <span>
                                <EmptyEnrollment />
                            </span>
                        </div>
                        <div className="emptystatedata">
                            <h1>No Enrollments in the Queue!</h1>
                            <p>There are currently no active, suspended, or disconnected enrollments to display. Start new enrollments to fill your queue!</p>
                            <div className="flex w-full justify-content-center">
                                <Button className="emptystatebtn" label="Start Enrollment" onClick={() => navigate("/prepaid-newenrollment")} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={role === "Admin" ? "allenrollmenttableadmin" : "allenrollmenttable"} style={{ marginTop: "2.4rem" }}>
                    {isButtonLoading ? <ProgressSpinner className="flex flex-wrap justify-content-center flex-row mt-4" /> : null}

                    <DataTable
                        value={allEnrollments}
                        selection={selectedRows}
                        onSelectionChange={role !== "Admin" ? null : (e) => setSelectedRows(e.value)}
                        size="small"
                        emptyMessage={
                            isLoading ? (
                                <div style={{ width: "108.4rem" }} className="flex p-2 flex-wrap flex-row justify-content-center align-items-center ">
                                    <CommonLoaderBlue customSize={14} />
                                </div>
                            ) : (
                                ""
                            )
                        }
                        stripedRows
                        resizableColumns
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowClick={(e) => handleEnrollmentIdClick(e.data)}
                        style={{ fontFamily: "Inter", fontSize: "14.4rem" }}
                    >
                        <Column selectionMode="multiple" style={{ pointerEvents: role !== "Admin" ? "none" : "auto", opacity: role !== "Admin" ? 0.5 : 1 }} />
                        <Column
                            header="Id"
                            field="enrollmentId"
                            // body={(rowData) => (
                            //     <button style={{ fontSize: "1.4rem", fontWeight: "500", color: "#5E79FF", border: "none", backgroundColor: "transparent", cursor: "pointer", marginLeft: "-0.5rem" }} onClick={() => handleEnrollmentIdClick(rowData)}>
                            //         {rowData.enrollmentId}
                            //     </button>
                            // )}
                        ></Column>
                        <Column
                            header="Link Acc"
                            body={(rowData) => {
                                return <p className={rowData?.linkedAccount ? "yes" : "no"}>{rowData?.linkedAccount ? "Yes" : "No"}</p>;
                            }}
                        />
                        <Column header="Name" field="name"></Column>
                        <Column field="contact" header="Contact" />
                        {role === "Admin" && (
                            <Column
                                field="createdBy.name"
                                header="Created By"
                                body={(rowData) => (
                                    <>
                                        <p
                                            onMouseEnter={(e) => {
                                                if (rowData?.createdBy?.name?.length > 11) {
                                                    setOverlayContent(rowData?.createdBy?.name);
                                                    statusOverlayRef.current.show(e);
                                                }
                                            }}
                                            onMouseLeave={() => statusOverlayRef.current.hide()}
                                        >
                                            {rowData?.createdBy?.name?.length > 11 ? rowData?.createdBy?.name?.substring(0, 8) + "..." : rowData.createdBy?.name?.length === 0 ? "NIL" : rowData?.createdBy?.name}
                                        </p>
                                        <OverlayPanel ref={statusOverlayRef}>
                                            <div style={{ padding: "5px 10px" }}>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: "1.4rem",
                                                        overflow: "hidden",
                                                        border: "none",
                                                        margin: "0px",
                                                        lineHeight: "1.8rem",
                                                        color: "#72728f",
                                                    }}
                                                >
                                                    {" "}
                                                    {overlayContent}
                                                </p>
                                            </div>
                                        </OverlayPanel>
                                    </>
                                )}
                            />
                        )}
                        {/*
                        <Column header="Actions" body={actionTemplate}></Column> */}
                        <Column
                            header="Status"
                            field="status"
                            body={(rowData) => (
                                <span
                                    style={{
                                        color:
                                            rowData?.status === "prospect"
                                                ? "#4A90E2"
                                                : rowData?.status === "cancelled"
                                                  ? "#95A5A6"
                                                  : rowData?.status === "active"
                                                    ? "#27AE60"
                                                    : rowData?.status === "disconnected"
                                                      ? "#B3261E"
                                                      : rowData?.status === "inactive"
                                                        ? "#B3261E"
                                                        : rowData?.status === "suspended"
                                                          ? "#F39C12"
                                                          : "",
                                        fontSize: "1.4rem",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {rowData?.status}
                                </span>
                            )}
                        />
                        <Column field="createdDate" header="Date" />
                    </DataTable>
                    <div style={{ marginTop: "2.4rem" }} className="flex flex-wrap w-full  flex-row justify-content-between align-items-center ">
                        {isFirstTimeCalledApi && (
                            <h1 className="infoenrollmentsall">
                                {currentPagination?.total ? (currentPagination?.page * currentPagination.limit > currentPagination.total ? currentPagination.total : currentPagination?.page * currentPagination.limit) : "0"}/{currentPagination?.total ? currentPagination?.total : "0"}
                            </h1>
                        )}
                        {currentPagination?.totalPages > 1 && isFirstTimeCalledApi && (
                            <div className="flex  flex-wrap flex-row justify-content-between pages">
                                <button
                                    disabled={currentPage === 1}
                                    onClick={() => {
                                        let pagetocheck = currentPage;
                                        pagetocheck--;
                                        if (currentPagination.totalPages <= 6 && pagetocheck > 0) {
                                            setCurrentPage(pagetocheck);
                                        } else if (floatPagesLeft.includes(pagetocheck) || floatPagesRight.includes(pagetocheck)) {
                                            setCurrentPage(pagetocheck);
                                        } else if (pagetocheck > 0) {
                                            if (pagetocheck === floatPagesLeft[0] - 1) {
                                                let arr = floatPagesLeft;

                                                arr.pop();
                                                arr.unshift(floatPagesLeft[0] - 1);
                                                setFloatPagesLeft(arr);
                                                setCurrentPage(pagetocheck);
                                            } else if (pagetocheck === floatPagesRight[0] - 1) {
                                                let arr = floatPagesRight;

                                                arr.pop();
                                                arr.unshift(floatPagesRight[0] - 1);
                                                setFloatPagesRight(arr);
                                                setCurrentPage(pagetocheck);
                                            }
                                        }
                                    }}
                                    className="cursor-pointer backbtn"
                                    style={{ opacity: currentPage === 1 && "0.6", backgroundColor: "#FFFFFF33", width: "10.6rem", height: "3.8rem", margin: "0rem" }}
                                >
                                    <span>
                                        <PrevIcon /> &nbsp; Previous
                                    </span>
                                </button>
                                <div style={{ maxWidth: "26.9rem", gap: "1rem" }} className="flex flex-wrap flex-row justify-content-start align-items-start">
                                    {currentPagination.totalPages <= 6 ? (
                                        [...Array(currentPagination.totalPages)].map((_, index) => (
                                            <Button
                                                onClick={() => {
                                                    console.log("current pagination", currentPagination.page);
                                                    setCurrentPage(index + 1);
                                                }}
                                                style={{ width: "3.1rem", height: "3.4rem" }}
                                                key={index + 1}
                                                className={Number(currentPagination.page) === index + 1 ? "pagecounteractive" : "pagecounter"}
                                                label={index + 1}
                                            />
                                        ))
                                    ) : (
                                        <>
                                            {floatPagesLeft.map((page) => (
                                                <Button
                                                    key={page}
                                                    onClick={() => {
                                                        setCurrentPage(page);
                                                    }}
                                                    style={{ width: "3.1rem", height: "3.4rem" }}
                                                    className={Number(currentPagination.page) === page ? "pagecounteractive" : "pagecounter"}
                                                    label={page}
                                                />
                                            ))}
                                            {floatPagesLeft[floatPagesLeft.length - 1] + 1 !== floatPagesRight[0] && <Button className="pagecounter" label={"..."} />}

                                            {floatPagesRight.map((page) => (
                                                <Button
                                                    onClick={() => {
                                                        setCurrentPage(page);
                                                    }}
                                                    key={page}
                                                    className={Number(currentPagination.page) === page ? "pagecounteractive" : "pagecounter"}
                                                    label={page}
                                                />
                                            ))}
                                        </>
                                    )}
                                </div>
                                <button
                                    disabled={currentPage === currentPagination.totalPages}
                                    onClick={() => {
                                        console.log(currentPagination);
                                        let pagetocheck = currentPage;
                                        pagetocheck++;
                                        if (currentPagination.totalPages <= 6 && pagetocheck > 0 && pagetocheck <= currentPagination.totalPages) {
                                            setCurrentPage(pagetocheck);
                                        } else if (floatPagesLeft.includes(pagetocheck) || floatPagesRight.includes(pagetocheck)) {
                                            setCurrentPage(pagetocheck);
                                        } else if (pagetocheck <= currentPagination.totalPages) {
                                            if (pagetocheck === floatPagesLeft[floatPagesLeft.length - 1] + 1) {
                                                let arr = floatPagesLeft;

                                                arr.shift();
                                                arr.push(pagetocheck);
                                                setFloatPagesLeft(arr);
                                                setCurrentPage(pagetocheck);
                                            } else {
                                                if (pagetocheck === floatPagesRight[floatPagesRight.length - 1] + 1 && pagetocheck <= currentPagination.totalPages) {
                                                    let arr = floatPagesRight;

                                                    arr.shift();
                                                    arr.push(pagetocheck);
                                                    setFloatPagesRight(arr);
                                                    setCurrentPage(pagetocheck);
                                                }
                                            }
                                        }
                                    }}
                                    className="cursor-pointer backbtn"
                                    style={{ opacity: currentPage === currentPagination.totalPages && "0.6", backgroundColor: "#FFFFFF33", width: "8rem", height: "3.8rem" }}
                                >
                                    <span className="flex align-items-center">
                                        Next &nbsp;
                                        <NextIcon />
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
export default PrepaidAllEnrollments;
