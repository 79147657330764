import { api } from "../../../../utility/AxiosInceptor";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import "./login.css";
import Axios from "axios";
import { CheckTickBtn, Eyebutton, EyeButtonOpen, LoginBg, LoginFrame, LoginLogo, LoginScreenimg, Loginwifi, LoginWifi, SniptelLogin, TeleyorkLogo } from "../../../../utility";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function LoginScreen({ setShowApps, setRefreshApp }) {
    const [showPassowrd, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errormsg, setErrorMsg] = useState(null);
    const [splashScreen, setSplashScreen] = useState(false);
    const [forgetScreen, setForgetScreen] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const toast = useRef(null);

    setTimeout(() => {
        setSplashScreen(true);
    }, 3000);
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email").required("Email is required"),
            password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
        }),
        onSubmit: async (values) => {
            setLoading((prev) => !prev);

            api.post(`/api/web/user/login`, formik.values, { withCredentials: true })
                .then((response) => {
                    console.log(response.headers);
                    const cookies = response.headers["set-cookie"];
                    sessionStorage.setItem("cookies", cookies);
                    const allowdPerms = response?.data?.data?.permissions;
                    localStorage.setItem("userData", JSON.stringify(response?.data?.data));
                    localStorage.setItem("accessToken", JSON.stringify(response?.data?.data?.token));
                    localStorage.setItem("refreshToken", JSON.stringify(response?.data?.refreshToken));
                    localStorage.setItem("permissions", JSON.stringify(allowdPerms));
                    localStorage.setItem("newpermission", "yes");
                    setRefreshApp((prev) => !prev);
                    setShowApps(true);
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMsg(err.response?.data?.msg);
                    toast.current.show({ severity: "error", summary: "Login", detail: err?.response?.data?.msg });
                    setLoading((prev) => !prev);
                });
        },
    });
    useEffect(() => {
        var currentURL;
        var modifiedURL;
        currentURL = window.location.href;
        // currentURL = "http://dev-ijwireless.teleyork.com/#/login";
        if (currentURL.includes("dev-")) {
            modifiedURL = currentURL.replace("http://dev-", "");
            modifiedURL = modifiedURL.replace("/#/login", "");
        } else {
            modifiedURL = currentURL.replace("http://", "");
            modifiedURL = modifiedURL.replace("/#/login", "");
        }
        const sendURl = async () => {
            try {
                const response = await api.get(`/api/web/serviceProvider/getSPdetailByDomain?subDomain=${modifiedURL}`);
            } catch (error) {}
        };
        sendURl();
    }, []);
    return (
        <div className="mainloginscreen">
            <Toast ref={toast} />
            <div className="wifi">
                <Loginwifi />
            </div>
            <div className="loginbg">
                <LoginBg />
            </div>
            <div className="loginframe">
                {/* <LoginFrame /> */}
                <img src="./LoginImage.png" alt="" />
            </div>
            <div className="loginform">
                {!forgetScreen ? (
                    <div className="loginforminner">
                        <div className="flex flex-column welcome">
                            <span>
                                <SniptelLogin />
                            </span>
                            <p>Welcome To Sniptel</p>
                            <label>Access your account by entering your email and password below.</label>
                        </div>

                        <div className="form">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="flex w-full justify-content-between">
                                    <label>Email</label>
                                    {formik.touched.email && formik.errors.email ? <p className="p-error">{formik.errors.email}</p> : null}
                                </div>
                                <InputText
                                    type="text"
                                    name="email"
                                    className={formik.touched.email && formik.errors.email ? "inputerror" : "input "}
                                    placeholder="example@email.com"
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    style={{ marginTop: ".2rem" }}
                                />

                                <div className="flex w-full justify-content-between " style={{ marginTop: "1.6rem" }}>
                                    <label>Password</label>
                                    {formik.touched.password && formik.errors.password ? <p className="p-error">{formik.errors.password}</p> : null}
                                </div>

                                <div style={{ position: "relative", width: "100%" }}>
                                    <InputText
                                        type={showPassowrd ? "text" : "password"}
                                        name="password"
                                        className={formik.touched.password && formik.errors.password ? "inputerror" : "input"}
                                        placeholder="Password"
                                        value={formik.values.password}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        style={{ paddingRight: "2.5rem", marginTop: ".2rem" }} // Adding padding to avoid text overlapping with button
                                    />
                                    <span
                                        style={{
                                            position: "absolute",
                                            right: "1.2rem",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                            setShowPassword((prev) => !prev);
                                        }}
                                    >
                                        {showPassowrd ? <EyeButtonOpen /> : <Eyebutton />}
                                    </span>
                                </div>

                                {/* <Password className="input" type="password" style={{ height: "35px" }} feedback={false} tabIndex={1} name="password" placeholder="Password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} /> */}
                                {/* <i
                                        className={`${showPassowrd ? "pi-eye" : "pi-eye-slash"} pi`}
                                        onClick={(e) => {
                                            if (e.target.previousElementSibling.children[0].type === "text") {
                                                e.target.previousElementSibling.children[0].type = "password";
                                            } else {
                                                e.target.previousElementSibling.children[0].type = "text";
                                            }
                                            setShowPassword((prev) => !prev);
                                        }}
                                    /> */}

                                <div style={{ marginTop: ".8rem" }}>
                                    <div className="forgot flex w-full justify-content-end">
                                        <NavLink to={{ pathname: "/sendotp", state: { email: formik.values.email } }}>
                                            <span onClick={() => setForgetScreen(true)}>Forgot Password?</span>
                                        </NavLink>
                                    </div>
                                </div>
                                <Button type="submit" className="loginbtn" label="Login" disabled={loading} icon={loading === true ? "pi pi-spin pi-spinner " : ""} style={{ marginBottom: "5%" }} />
                            </form>
                        </div>
                    </div>
                ) : sendEmail ? (
                    <div className="flex flex-column align-items-center justify-content-center forgetpasspopup" style={{ gap: "3%" }}>
                        <div>
                            <CheckTickBtn />
                        </div>
                        <div className="flex flex-column align-items-center checkinbox mt-4">
                            <h1>Check Your Inbox</h1>
                            <p>We’ve sent a password reset link to your email. Follow the instructions to regain access to your Sniptel account.</p>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-column justify-content-evenly align-items-center forgotpass">
                        <div style={{ width: "355px" }}>
                            <h1>Reset Your Password</h1>
                            <p className="flex mt-4">Enter your email, and we’ll send you a link to get back on track with Sniptel.</p>
                        </div>
                        <div className="flex flex-column " style={{ width: "355px" }}>
                            <div className="flex justify-content-between">
                                <label className={formik.touched.email && formik.errors.email ? "emaillabelerr" : "emaillabel"}>Email</label>
                                {formik.touched.email && formik.errors.email ? <span className="err">{formik.errors.email}</span> : ""}
                            </div>
                            <InputText id="email" name="email" className={formik.touched.email && formik.errors.email ? "emailinputerr mt-2" : "emailinput mt-2"} placeholder="example@email.com" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                            <div className="flex w-full" style={{ marginTop: "25px" }}>
                                <Button onClick={() => setSendEmail(true)} disabled={formik.errors.email ? true : false} className="forgetbtn" label="Reset Password" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
