import React, { useEffect, useCallback } from "react";
import { useFormik } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import "react-toastify/dist/ReactToastify.css";
import "./PersonalInfo.css";
import { api } from "../../../../../../../utility/AxiosInceptor";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import classNames from "classnames";
import moment from "moment/moment";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const PersonalInfo = ({ isLinkedAccountIncomplete, parentDetails, setParentDetails, parentCurrentContact, initiationData, currentBillingModel, setInitiateActive, setPersonalInfoActive, setAddressActive }) => {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const _id = initiationData?._id;
    const csr = parseLoginRes?._id;
    const [checkdoberror, setCheckDOBError] = useState(false);
    const startYear = 1900;
    const endYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const yearOptions = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
        const year = endYear - index; // Adjusted to generate years in descending order
        return { label: year.toString(), value: year };
    });
    const customItemTemplate = (option) => <div style={{ marginTop: "-10px", marginBottom: "-10px" }}>{option.label}</div>;
    const monthOptions = [
        { label: "Jan-01", value: 1 },
        { label: "Feb-02", value: 2 },
        { label: "Mar-03", value: 3 },
        { label: "Apr-04", value: 4 },
        { label: "May-05", value: 5 },
        { label: "Jun-06", value: 6 },
        { label: "Jul-07", value: 7 },
        { label: "Aug-08", value: 8 },
        { label: "Sep-09", value: 9 },
        { label: "Oct-10", value: 10 },
        { label: "Nov-11", value: 11 },
        { label: "Dec-12", value: 12 },
    ];
    const [eSim, seteSim] = useState(false);
    const [selectedOption, setSelectedOption] = useState(parentDetails?.bestWayToReach ? parentDetails?.bestWayToReach : "email");
    const [isLoading, setIsLoading] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [dayerror, setDayError] = useState(false);
    const [montherror, setMonthError] = useState(false);
    const [yearerror, setYearError] = useState(false);
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("This is Required"),
        lastName: Yup.string().required("This is Required"),
        SSN: Yup.string().required("This is Required"),
        contact: Yup.string().required("This is Required"),
        email: Yup.string().email().required("This is Required"),
        salesChannel: Yup.string().required("This is Required"),
        relationWithParent: Yup.string().required("This is Required"),
    });
    const relationWithParentOptions = [
        {
            label: "Sibling",
            value: "Sibling",
        },
        {
            label: "Self",
            value: "Self",
        },
        {
            label: "Child",
            value: "Child",
        },
        {
            label: "Spouse",
            value: "Spouse",
        },
    ];
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstName: parentDetails?.firstName,
            middleName: parentDetails?.middleName,
            lastName: parentDetails?.lastName,
            suffix: parentDetails?.suffix,
            SSN: parentDetails?.SSN,
            DOB: "",
            year: null,
            month: null,
            day: null,
            contact: parentDetails?.contact,
            drivingLicense: parentDetails?.drivingLicense,
            email: parentDetails?.email,
            ESim: parentDetails?.ESim,
            bestWayToReach: parentDetails?.bestWayToReach ? parentDetails?.bestWayToReach : "email",
            salesChannel: parentDetails?.salesChannel,
            accountType: currentBillingModel === "PREPAID" ? "Prepaid" : "Postpaid",
            maidenMotherName: parentDetails?.maidenMotherName,
            alternateContact: parentDetails?.alternateContact,
            isACP: false,
            relationWithParent: parentDetails?.relationWithParent,
        },
        onSubmit: async (values, actions) => {
            if (selectedDay === null || selectedYear === null || selectedMonth === null) {
                setCheckDOBError(true);
            } else {
                const dateObject = new Date(selectedYear, formik.values.month - 1, formik.values.day);
                const isoString = dateObject.toISOString();
                const selectedDate = isoString;
                const formattedDate = selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "";
                const userId = _id;
                const dataToSend = {
                    csr: csr,
                    userId: userId,
                    isACP: formik.values.isACP,
                    firstName: formik.values.firstName,
                    middleName: formik.values.middleName,
                    lastName: formik.values.lastName,
                    suffix: formik.values.suffix,
                    SSN: formik.values.SSN,
                    DOB: formattedDate,
                    contact: formik.values.contact,
                    drivingLicense: formik.values.drivingLicense,
                    email: formik.values.email,
                    ESim: formik.values.ESim,
                    bestWayToReach: formik.values.bestWayToReach,
                    salesChannel: formik.values.salesChannel,
                    accountType: formik.values.accountType,
                    maidenMotherName: formik.values.maidenMotherName,
                    alternateContact: formik.values.alternateContact,
                    relationWithParent: formik.values.relationWithParent,
                };
                if (localStorage.getItem("izZipVerified") === "yes") {
                    dataToSend.izZipVerified = true;
                } else {
                    dataToSend.izZipVerified = false;
                }
                setIsLoading(true);
                try {
                    const response = await api.post(`/api/user/initialInformation`, dataToSend);
                    if (response?.status === 200 || response?.status === 201) {
                        localStorage.setItem("prepaidbasicData", JSON.stringify(response.data));
                        toast.success("Information Saved Successfully");
                        setPersonalInfoActive(false);
                        setAddressActive(true);
                        // handleNext();
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                    setIsLoading(false);
                }
            }
        },
    });

    useEffect(() => {
        seteSim(parentDetails?.ESim);
        setSelectedOption(parentDetails?.bestWayToReach);

        const dateString = parentDetails?.DOB;
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        setSelectedMonth(month);
        formik.setFieldValue("month", month);

        formik.setFieldValue("year", year);
        formik.setFieldValue("day", day);

        setSelectedYear(year);
        setSelectedDay(day);
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const [previousContact, setPrevousContact] = useState(parentDetails?.contact);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleESim = (e) => {
        seteSim(e.target.value);
    };
    const options = [
        { label: "Select", value: "" },
        { label: "JR.", value: "JR." },
        { label: "SR.", value: "SR." },
        { label: "II", value: "II" },
        { label: "III", value: "III" },
        { label: "IV", value: "IV" },
        { label: "V", value: "V" },
    ];
    let parsebasicResponse = {};
    parsebasicResponse.data = parentDetails;
    const [dayoption, setDayOptions] = useState(null);
    useEffect(() => {
        if (formik.values.month === 1 || formik.values.month === 3 || formik.values.month === 5 || formik.values.month === 7 || formik.values.month === 8 || formik.values.month === 10 || formik.values.month === 12) {
            const startDay = 1;
            const endDay = 31;
            const option = Array.from({ length: endDay - startDay + 1 }, (_, index) => {
                const day = startDay + index;
                return { label: day.toString(), value: day };
            });
            setDayOptions(option);
            if (formik.values.day !== null && formik.values.year !== null) {
                const dateObject = new Date(formik.values.year, formik.values.month - 1, formik.values.day);
                const isoString = dateObject.toISOString();
                formik.setFieldValue("DOB", isoString);
                setParentDetails((prev) => ({
                    ...prev,
                    DOB: isoString,
                }));
            } else {
                formik.setFieldValue("DOB", "");
            }
        } else if (formik.values.month === 4 || formik.values.month === 6 || formik.values.month === 9 || formik.values.month === 11) {
            const startDay = 1;
            const endDay = 30;
            const option = Array.from({ length: endDay - startDay + 1 }, (_, index) => {
                const day = startDay + index;
                return { label: day.toString(), value: day };
            });
            setDayOptions(option);
            if (formik.values.day !== null && formik.values.year !== null) {
                if (formik.values.day > 30) {
                    formik.setFieldValue("day", 30);
                    setSelectedDay(30);
                }
                const dateObject = new Date(formik.values.year, formik.values.month - 1, formik.values.day);
                const isoString = dateObject.toISOString();
                formik.setFieldValue("DOB", isoString);
                setParentDetails((prev) => ({
                    ...prev,
                    DOB: isoString,
                }));
            } else {
                formik.setFieldValue("DOB", "");
            }
        } else {
            if (formik.values.year !== null) {
                if (isLeapYear(formik.values.year)) {
                    if (formik.values.month === 2) {
                        const startDay = 1;
                        const endDay = 29;
                        const option = Array.from({ length: endDay - startDay + 1 }, (_, index) => {
                            const day = startDay + index;
                            return { label: day.toString(), value: day };
                        });
                        setDayOptions(option);
                        if (formik.values.day !== null) {
                            if (formik.values.day > 29) {
                                formik.setFieldValue("day", 29);
                                setSelectedDay(29);
                            }
                            const dateObject = new Date(formik.values.year, formik.values.month - 1, formik.values.day);
                            const isoString = dateObject.toISOString();
                            formik.setFieldValue("DOB", isoString);
                            setParentDetails((prev) => ({
                                ...prev,
                                DOB: isoString,
                            }));
                        } else {
                            formik.setFieldValue("DOB", "");
                        }
                    }
                } else {
                    const startDay = 1;
                    const endDay = 28;
                    const option = Array.from({ length: endDay - startDay + 1 }, (_, index) => {
                        const day = startDay + index;
                        return { label: day.toString(), value: day };
                    });
                    setDayOptions(option);
                    if (formik.values.day !== null && formik.values.year !== null) {
                        if (formik.values.day > 28) {
                            formik.setFieldValue("day", 28);
                            setSelectedDay(28);
                        }
                        const dateObject = new Date(formik.values.year, formik.values.month - 1, formik.values.day);
                        const isoString = dateObject.toISOString();
                        formik.setFieldValue("DOB", isoString);
                        setParentDetails((prev) => ({
                            ...prev,
                            DOB: isoString,
                        }));
                    } else {
                        formik.setFieldValue("DOB", "");
                    }
                }
            } else {
                const startDay = 1;
                const endDay = 29;
                const option = Array.from({ length: endDay - startDay + 1 }, (_, index) => {
                    const day = startDay + index;
                    return { label: day.toString(), value: day };
                });
                setDayOptions(option);
            }
        }
    }, [formik.values.month]);

    useEffect(() => {
        if (isLeapYear(formik.values.year)) {
            if (formik.values.month === 2) {
                const startDay = 1;
                const endDay = 29;
                const option = Array.from({ length: endDay - startDay + 1 }, (_, index) => {
                    const day = startDay + index;
                    return { label: day.toString(), value: day };
                });
                setDayOptions(option);
            }
        } else {
            if (formik.values.month === 2) {
                const startDay = 1;
                const endDay = 28;

                const option = Array.from({ length: endDay - startDay + 1 }, (_, index) => {
                    const day = startDay + index;
                    return { label: day.toString(), value: day };
                });
                setDayOptions(option);
            }
        }
    }, [formik.values.year]);
    function isLeapYear(year) {
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            return true;
        } else {
            return false;
        }
    }
    //check customer Duplication
    useEffect(() => {
        const fetchData = _.debounce(async () => {
            const data = {
                accountType: formik.values.accountType,
                contact: formik.values.contact,
                alternateContact: formik.values.alternateContact,
                customerId: _id,
                linkedAccount: true,
                parent_id: parentDetails._id,
            };

            try {
                const response = await api.post(`/api/user/checkCustomerDuplication`, data);
                setIsDuplicate(false);
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsDuplicate(true);
            }
        }, 500); // Debounce with 500ms delay

        if (isLinkedAccountIncomplete !== "") {
            if (previousContact !== formik.values.contact) {
                fetchData();
            }
        } else {
            if (parentCurrentContact !== formik.values.contact) {
                fetchData();
            }
        }

        return () => {
            fetchData.cancel(); // Cancel any pending request when effect re-runs
        };
    }, [formik.values.contact]);

    const salesChannelOptions = [
        {
            label: "Web Consent",
            value: "Web Consent",
        },
        {
            label: "Old Facebook",
            value: "Old Facebook",
        },
        {
            label: "New Facebook",
            value: "New Facebook",
        },
        {
            label: "Auto",
            value: "Auto",
        },
        {
            label: "Email",
            value: "Email",
        },
        {
            label: "SMM",
            value: "SMM",
        },
    ];
    return (
        <>
            <ToastContainer />
            {/* <form className="personalInfoform" onSubmit={formik.handleSubmit} style={{ border: "none", marginLeft: "3.0rem", marginBottom: "3rem" }}>
                <h1 className="heading">PERSONAL INFORMATION</h1>
                <p>Please provide your basic information, including name, contact details, and email, to start the enrollment process.</p>
                <div className="flex flex-wrap flex-row justify-content-left personalinfoinner" style={{ gap: "1rem", marginTop: "-4rem" }}>
                    <div className="calendar_field ">
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2 ">
                                First Name
                                <span className="steric ">*</span>
                            </label>
                            {getFormErrorMessage("firstName")}
                        </div>
                        <InputText
                            id="firstName"
                            placeholder="First Name"
                            value={formik.values.firstName}
                            onChange={(e) => {
                                formik.setFieldValue("firstName", e.target.value?.toUpperCase());
                                setParentDetails((prev) => ({
                                    ...prev,
                                    firstName: e.target.value?.toUpperCase(),
                                }));
                            }}
                            className={classNames({ "p-invalid": isFormFieldValid("firstName") }, "input_text w-full")}
                            keyfilter={/^[a-zA-Z\s]*$/}
                            minLength={3}
                            maxLength={20}
                            autoComplete="new-password"
                            // style={{ textTransform: "uppercase" }}
                        />
                    </div>

                    <div className="calendar_field ">
                        <label className="field_label mb-2">Middle Name</label>
                        <InputText
                            id="middleName"
                            placeholder="Middle Name"
                            value={formik.values.middleName}
                            onChange={(e) => {
                                formik.setFieldValue("middleName", e.target.value?.toUpperCase());
                                setParentDetails((prev) => ({
                                    ...prev,
                                    middleName: e.target.value?.toUpperCase(),
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            keyfilter={/^[a-zA-Z\s]*$/}
                            maxLength={10}
                            autoComplete="off"
                            className="w-full input_text mt-2"
                            style={{ textTransform: "uppercase" }}
                        />
                    </div>

                    <div className="calendar_field ">
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2">
                                Last Name
                                <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                    *
                                </span>
                            </label>
                            {getFormErrorMessage("lastName")}
                        </div>
                        <InputText
                            id="lastName"
                            placeholder="Last Name"
                            value={formik.values.lastName}
                            onChange={(e) => {
                                formik.setFieldValue("lastName", e.target.value?.toUpperCase());
                                setParentDetails((prev) => ({
                                    ...prev,
                                    lastName: e.target.value?.toUpperCase(),
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className={classNames({ "p-invalid": isFormFieldValid("lastName") }, "input_text w-full")}
                            keyfilter={/^[a-zA-Z\s]*$/}
                            minLength={3}
                            maxLength={20}
                            autoComplete="off"
                            style={{ textTransform: "uppercase" }}
                        />
                    </div>

                    <div className="calendar_field ">
                        <label className="field_label mb-2 ">Suffix</label>
                        <Dropdown
                            id="suffix"
                            placeholder="Title"
                            options={options}
                            value={formik.values.suffix}
                            onChange={(e) => {
                                formik.setFieldValue("suffix", e.value);
                                formik.handleChange(e);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    suffix: e.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className={formik.values.suffix ? "dropdown w-full mt-2" : "w-full input_text mt-2"}
                            style={{ textTransform: "uppercase" }}
                        />
                    </div>

                    <div className="calendar_field  ">
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2">
                                SSN
                                <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                    *
                                </span>{" "}
                                <small style={{ color: "#CCCBCB", fontSize: "1.0rem" }}>(Last 4 Digits)</small>
                            </label>
                            {getFormErrorMessage("SSN")}
                        </div>
                        <InputText
                            type="text"
                            id="SSN"
                            placeholder="SSN"
                            value={formik.values.SSN}
                            onChange={(e) => {
                                formik.setFieldValue("SSN", e.target.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    SSN: e.target.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className={classNames({ "p-invalid": isFormFieldValid("SSN") }, "input_text w-full")}
                            keyfilter={/^\d{0,4}$/}
                            maxLength={4}
                            minLength={4}
                        />
                    </div>
                    <div className="calendar_field ">
                        <label className="field_label mb-2">Mother's Maiden Name</label>
                        <InputText
                            id="maidenMotherName"
                            value={formik.values.maidenMotherName}
                            placeholder="Mother Name"
                            onChange={(e) => {
                                formik.setFieldValue("maidenMotherName", e.target.value?.toUpperCase());
                                setParentDetails((prev) => ({
                                    ...prev,
                                    maidenMotherName: e.target.value?.toUpperCase(),
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className="w-full input_text mt-2"
                            style={{ textTransform: "uppercase" }}
                        />
                    </div>
                    <div className="flex flex-wrap flex-row justify-content-between calendarmainpersonalinfo">
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2">
                                DOB
                                <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                    *
                                </span>
                                <small>(MM/DD/YYYY)</small>
                            </label>
                            {checkdoberror ? (
                                <p className="p-error" style={{ color: "#e24c4c" }}>
                                    This is required
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="flex flex-wrap  w-full  justify-content-between flex-row ">
                            <Dropdown
                                placeholder="Month"
                                value={formik.values.month}
                                id="month"
                                filter
                                onChange={(e) => {
                                    if (selectedYear !== null && selectedDay !== null) {
                                        setCheckDOBError(false);
                                    }
                                    setMonthError(false);
                                    formik.handleChange(e);

                                    formik.handleChange(e);

                                    setSelectedMonth(e.value);
                                }}
                                options={monthOptions}
                                className={classNames(
                                    {
                                        "p-invalid": montherror,
                                        "dropdown calendar_field_date": formik.values.month,
                                    },
                                    "input_text calendar_field_date",
                                )}
                            />
                            <Dropdown
                                placeholder="Day"
                                value={formik.values.day}
                                id="day"
                                filter
                                onChange={(e) => {
                                    if (selectedYear !== null && selectedMonth !== null) {
                                        setCheckDOBError(false);
                                    }
                                    setDayError(false);
                                    formik.handleChange(e);
                                    setSelectedDay(e.value);
                                    if (formik.values.month !== null && !isNaN(formik.values.month) && formik.values.year !== null && !isNaN(formik.values.year)) {
                                        const dateObject = new Date(formik.values.year, formik.values.month - 1, e.value);
                                        const isoString = dateObject.toISOString();
                                        formik.setFieldValue("DOB", isoString);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            DOB: isoString,
                                        }));
                                    }
                                }}
                                options={dayoption}
                                className={classNames(
                                    {
                                        "p-invalid": dayerror,
                                        "dropdown calendar_field_date": formik.values.month,
                                    },
                                    "input_text calendar_field_date",
                                )}
                            />
                            <Dropdown
                                placeholder="Year"
                                filter
                                className={classNames(
                                    {
                                        "p-invalid": yearerror,
                                        "dropdown calendar_field_date": formik.values.month,
                                    },
                                    "input_text calendar_field_date",
                                )}
                                value={formik.values.year}
                                name="year"
                                itemTemplate={customItemTemplate}
                                onChange={(e) => {
                                    if (selectedMonth !== null && selectedDay !== null) {
                                        setCheckDOBError(false);
                                    }
                                    setYearError(false);
                                    setSelectedYear(e.value);
                                    if (formik.values.month !== null && !isNaN(formik.values.month) && formik.values.day !== null && !isNaN(formik.values.day)) {
                                        if (isLeapYear(e.value)) {
                                            if (formik.values.month === 2) {
                                                if (formik.values.day <= 29) {
                                                    const dateObject = new Date(e.value, formik.values.month - 1, formik.values.day);
                                                    const isoString = dateObject.toISOString();

                                                    formik.setFieldValue("DOB", isoString);
                                                    setParentDetails((prev) => ({
                                                        ...prev,
                                                        DOB: isoString,
                                                    }));
                                                } else {
                                                    formik.setFieldValue("day", 29);
                                                    setSelectedDay(e.value);
                                                    const dateObject = new Date(e.value, formik.values.month - 1, 29);
                                                    const isoString = dateObject.toISOString();

                                                    formik.setFieldValue("DOB", isoString);
                                                    setParentDetails((prev) => ({
                                                        ...prev,
                                                        DOB: isoString,
                                                    }));
                                                }
                                            }
                                        } else {
                                            if (formik.values.month === 2) {
                                                if (formik.values.day > 28) {
                                                    formik.setFieldValue("day", 28);
                                                    setSelectedDay(28);
                                                    const dateObject = new Date(e.value, formik.values.month - 1, 28);
                                                    const isoString = dateObject.toISOString();

                                                    formik.setFieldValue("DOB", isoString);
                                                    setParentDetails((prev) => ({
                                                        ...prev,
                                                        DOB: isoString,
                                                    }));
                                                } else {
                                                    const dateObject = new Date(e.value, formik.values.month - 1, formik.values.day);
                                                    const isoString = dateObject.toISOString();

                                                    formik.setFieldValue("DOB", isoString);
                                                    setParentDetails((prev) => ({
                                                        ...prev,
                                                        DOB: isoString,
                                                    }));
                                                }
                                            }
                                        }
                                        formik.handleChange(e);
                                    } else {
                                        formik.handleChange(e);
                                    }
                                }}
                                options={yearOptions}
                            />
                        </div>
                    </div>

                    <div className="calendar_field">
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2">
                                Email
                                <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                    *
                                </span>
                            </label>
                            {getFormErrorMessage("email")}
                        </div>
                        <InputText
                            id="email"
                            value={formik.values.email}
                            placeholder="Email"
                            onChange={(e) => {
                                formik.setFieldValue("email", e.target.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className={classNames({ "p-invalid": isFormFieldValid("email") }, "input_text w-full")}
                        />
                    </div>
                    <div className="calendar_field">
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2" htmlFor="contact">
                                Contact Number
                                <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                    *
                                </span>
                            </label>
                            {getFormErrorMessage("contact")}
                        </div>

                        <InputText
                            onChange={(e) => {
                                formik.setFieldValue("contact", e.target.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    contact: e.target.value,
                                }));
                            }}
                            id="contact"
                            value={formik.values.contact}
                            placeholder="+1 ** *** ****"
                            onBlur={formik.handleBlur}
                            className={classNames({ "p-invalid": isFormFieldValid("contact") }, "input_text w-full")}
                            minLength={10}
                            maxLength={10}
                            keyfilter={/^[0-9]*$/}
                            pattern="^(?!1|0|800|888|877|866|855|844|833).*$"
                        />
                    </div>
                    <div className="calendar_field " style={{ marginTop: "12px" }}>
                        <label className="field_label mb-2" htmlFor="contact">
                            Alternate Contact
                        </label>

                        <InputText
                            onChange={(e) => {
                                formik.setFieldValue("alternateContact", e.target.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    alternateContact: e.target.value,
                                }));
                            }}
                            placeholder="+1 ** *** ****"
                            id="alternateContact"
                            value={formik.values.alternateContact}
                            onBlur={formik.handleBlur}
                            minLength={10}
                            maxLength={10}
                            keyfilter={/^[0-9]*$/}
                            pattern="^(?!1|0|800|888|877|866|855|844|833).*$"
                            className="w-full input_text mt-2"
                        />
                    </div>
                    <div className="calendar_field" style={{ marginTop: "12px" }}>
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2" htmlFor="sales">
                                Sales Channel
                                <span className="steric ">*</span>
                            </label>
                            {getFormErrorMessage("salesChannel")}
                        </div>
                        <Dropdown
                            placeholder="Sale Channel"
                            id="salesChannel"
                            className={classNames(
                                {
                                    "p-invalid": isFormFieldValid("salesChannel"),
                                    "dropdown calendar_field_date": formik.values.month,
                                },
                                "input_text",
                            )}
                            options={salesChannelOptions}
                            value={formik.values.salesChannel}
                            onChange={(e) => {
                                formik.setFieldValue("salesChannel", e.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    salesChannel: e.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="calendar_field" style={{ marginTop: "12px" }}>
                        <div className="flex w-full justify-content-between">
                            <label className="field_label mb-2">
                                Relation With Parent <span className="steric">*</span>
                            </label>
                            {getFormErrorMessage("relationWithParent")}
                        </div>
                        <Dropdown
                            options={relationWithParentOptions}
                            id="relationWithParent"
                            className="w-full input_text"
                            value={formik.values.relationWithParent}
                            onChange={(e) => {
                                formik.setFieldValue("relationWithParent", e.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    relationWithParent: e.value,
                                }));
                            }}
                        />
                    </div>
                </div>
                <hr style={{ border: "1px solid #EFF2F8", marginTop: "5rem" }} />

                <div className="calendar_field " style={{ width: "auto", marginTop: "2rem" }}>
                    <p style={{ fontFamily: "Inter", color: "#72728F", fontSize: "1.6rem", fontWeight: "500" }}>What is the Best way to reach you?</p>
                    <div className="flex flex-wrap mt-2" style={{ gap: "1.5rem" }}>
                        <div className="mr-3 flex alignitem-center">
                            <RadioButton
                                style={{ zIndex: "0" }}
                                inputId="email"
                                name="email"
                                value="email"
                                onChange={(e) => {
                                    setSelectedOption(e.value);
                                    formik.setFieldValue("bestWayToReach", e.value);
                                    setParentDetails((prev) => ({
                                        ...prev,
                                        bestWayToReach: e.value,
                                    }));
                                }}
                                checked={selectedOption === "email"}
                            />
                            <label className="radiolabel" htmlFor="email">
                                Email
                            </label>
                        </div>
                        <div className="mr-3 flex alignitem-center">
                            <RadioButton
                                style={{ zIndex: "0" }}
                                inputId="Phone"
                                name="phone"
                                value="phone"
                                onChange={(e) => {
                                    setSelectedOption(e.value);
                                    formik.setFieldValue("bestWayToReach", e.value);
                                    setParentDetails((prev) => ({
                                        ...prev,
                                        bestWayToReach: e.value,
                                    }));
                                }}
                                checked={selectedOption === "phone"}
                            />
                            <label className="radiolabel" htmlFor="phone">
                                Phone
                            </label>
                        </div>
                        <div className="mr-3 flex alignitem-center">
                            <RadioButton
                                style={{ zIndex: "0" }}
                                inputId="message"
                                name="message"
                                value="message"
                                onChange={(e) => {
                                    setSelectedOption(e.value);
                                    formik.setFieldValue("bestWayToReach", e.value);
                                    setParentDetails((prev) => ({
                                        ...prev,
                                        bestWayToReach: e.value,
                                    }));
                                }}
                                checked={selectedOption === "message"}
                            />
                            <label className="radiolabel" htmlFor="message">
                                SMS
                            </label>
                        </div>
                        <div className="mr-3 flex alignitem-center">
                            <RadioButton
                                style={{ zIndex: "0" }}
                                inputId="mail"
                                name="mail"
                                value="mail"
                                onChange={(e) => {
                                    setSelectedOption(e.value);
                                    formik.setFieldValue("bestWayToReach", e.value);
                                    setParentDetails((prev) => ({
                                        ...prev,
                                        bestWayToReach: e.value,
                                    }));
                                }}
                                checked={selectedOption === "mail"}
                            />
                            <label className="radiolabel" htmlFor="mail">
                                Mail
                            </label>
                        </div>
                        <div className="mr-3 flex  ">
                            <RadioButton
                                style={{ zIndex: "0" }}
                                inputId="any"
                                name="any"
                                value="any"
                                onChange={(e) => {
                                    setSelectedOption(e.value);
                                    formik.setFieldValue("bestWayToReach", e.value);
                                    setParentDetails((prev) => ({
                                        ...prev,
                                        bestWayToReach: e.value,
                                    }));
                                }}
                                checked={selectedOption === "any"}
                            />
                            <label className="radiolabel" htmlFor="any">
                                Any
                            </label>
                        </div>
                    </div>
                </div>
                <div className="calendar_field  ">
                    <p style={{ fontFamily: "Inter", color: "#72728F", fontSize: "1.6rem", marginTop: "-5px", fontWeight: "500" }}>Required E-Sim?</p>
                    <div className="flex flex-wrap mt-2" style={{ gap: "1.5rem" }}>
                        <div className="mr-3 flex alignitem-center ">
                            <RadioButton
                                style={{ zIndex: "0" }}
                                id="ESIm"
                                value={true}
                                checked={eSim === true}
                                onChange={(e) => {
                                    handleESim(e);
                                    setParentDetails((prev) => ({
                                        ...prev,
                                        ESim: true,
                                    }));
                                }}
                            ></RadioButton>
                            <label className="radiolabel">Yes</label>
                            <div />
                        </div>
                        <div className="mr-3 flex align-items-center">
                            <RadioButton
                                style={{ zIndex: "0" }}
                                id="ESIm"
                                value={false}
                                checked={eSim === false}
                                onChange={(e) => {
                                    handleESim(e);
                                    setParentDetails((prev) => ({
                                        ...prev,
                                        ESim: false,
                                    }));
                                }}
                            ></RadioButton>
                            <label className="radiolabel -mt-1">No</label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap w-full justify-content-end" style={{ marginTop: "8rem" }}>
                    <Button
                        className=" nextbtn"
                        label="Next"
                        type="submit"
                        onClick={() => {
                            if (selectedDay === null || isNaN(selectedDay) || selectedMonth === null || isNaN(selectedDay) || selectedYear === null || isNaN(selectedDay)) {
                                if (selectedDay === null || isNaN(selectedDay)) {
                                    setDayError(true);
                                }
                                if (selectedYear === null || isNaN(selectedYear)) {
                                    setYearError(true);
                                }
                                if (selectedMonth === null || isNaN(selectedMonth)) {
                                    setMonthError(true);
                                }
                                setCheckDOBError(true);
                            }
                        }}
                        icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                        disabled={isLoading || isDuplicate}
                        style={{ marginRight: "3.2rem" }}
                    />
                </div>
            </form> */}

            <form className="personalInfoform" onSubmit={formik.handleSubmit}>
                <div className="personaldatadiv">
                    <h1 className="heading">Personal Details</h1>
                    <p style={{ marginTop: "1.2rem" }}>Please provide your basic information, including name, contact details, and email, to start the enrollment process.</p>
                    <div className="flex flex-column justify-content-left" style={{ height: "30.8rem", marginTop: "2.4rem" }}>
                        <div className="flex">
                            <div className="calendar_field ">
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label ">
                                        First Name
                                        <span className="steric ">*</span>
                                    </label>
                                    {getFormErrorMessage("firstName")}
                                </div>
                                <InputText
                                    id="firstName"
                                    placeholder="First Name"
                                    value={formik.values.firstName}
                                    onChange={(e) => {
                                        formik.setFieldValue("firstName", e.target.value?.toUpperCase());
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            firstName: e.target.value?.toUpperCase(),
                                        }));
                                    }}
                                    className={classNames({ "p-invalid": isFormFieldValid("firstName") }, "input_text w-full")}
                                    keyfilter={/^[a-zA-Z\s]*$/}
                                    minLength={3}
                                    maxLength={20}
                                    autoComplete="new-password"
                                />
                            </div>

                            <div className="calendar_field " style={{ marginLeft: "1.2rem" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">Middle Name</label>
                                </div>
                                <InputText
                                    id="middleName"
                                    placeholder="Middle Name"
                                    value={formik.values.middleName}
                                    onChange={(e) => {
                                        formik.setFieldValue("middleName", e.target.value?.toUpperCase());
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            middleName: e.target.value?.toUpperCase(),
                                        }));
                                    }}
                                    onBlur={formik.handleBlur}
                                    keyfilter={/^[a-zA-Z\s]*$/}
                                    maxLength={10}
                                    autoComplete="off"
                                    className="w-full input_text "
                                />
                            </div>

                            <div className="calendar_field " style={{ marginLeft: "1.2rem" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">
                                        Last Name
                                        <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                            *
                                        </span>
                                    </label>
                                    {getFormErrorMessage("lastName")}
                                </div>
                                <InputText
                                    id="lastName"
                                    placeholder="Last Name"
                                    value={formik.values.lastName}
                                    onChange={(e) => {
                                        formik.setFieldValue("lastName", e.target.value?.toUpperCase());
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            lastName: e.target.value?.toUpperCase(),
                                        }));
                                    }}
                                    onBlur={formik.handleBlur}
                                    className={classNames({ "p-invalid": isFormFieldValid("lastName") }, "input_text w-full")}
                                    keyfilter={/^[a-zA-Z\s]*$/}
                                    minLength={3}
                                    maxLength={20}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="flex" style={{ marginTop: "1.2rem" }}>
                            <div className="calendar_field ">
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">Suffix</label>
                                </div>
                                <Dropdown
                                    id="suffix"
                                    placeholder="Sr"
                                    options={options}
                                    value={formik.values.suffix}
                                    onChange={(e) => {
                                        formik.setFieldValue("suffix", e.value);
                                        formik.handleChange(e);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            suffix: e.value,
                                        }));
                                    }}
                                    onBlur={formik.handleBlur}
                                    className={`dropdown w-full ${formik.values.suffix ? "has-value" : "no-value"}`}
                                />
                            </div>

                            <div className="calendar_field " style={{ marginLeft: "1.2rem" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">
                                        SSN
                                        <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                            *
                                        </span>{" "}
                                        <small style={{ color: "#CCCBCB", fontSize: "1.0rem" }}>(Last 4 Digits)</small>
                                    </label>
                                    {getFormErrorMessage("SSN")}
                                </div>
                                <InputText
                                    type="text"
                                    id="SSN"
                                    placeholder="SSN"
                                    value={formik.values.SSN}
                                    onChange={(e) => {
                                        formik.setFieldValue("SSN", e.target.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            SSN: e.target.value,
                                        }));
                                    }}
                                    onBlur={formik.handleBlur}
                                    className={classNames({ "p-invalid": isFormFieldValid("SSN") }, "input_text w-full")}
                                    keyfilter={/^\d{0,4}$/}
                                    maxLength={4}
                                    minLength={4}
                                />
                            </div>
                            <div className="calendar_field " style={{ marginLeft: "1.2rem" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">Mother's Maiden Name</label>
                                </div>{" "}
                                <InputText
                                    id="maidenMotherName"
                                    value={formik.values.maidenMotherName}
                                    placeholder="Mother Name"
                                    onChange={(e) => {
                                        formik.setFieldValue("maidenMotherName", e.target.value?.toUpperCase());
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            maidenMotherName: e.target.value?.toUpperCase(),
                                        }));
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="w-full input_text "
                                />
                            </div>
                        </div>
                        <div className="flex" style={{ marginTop: "1.2rem" }}>
                            <div className="flex flex-wrap flex-row justify-content-between calendarmainpersonalinfo">
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">
                                        DOB
                                        <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                            *
                                        </span>
                                        <small>(MM/DD/YYYY)</small>
                                    </label>
                                    {checkdoberror ? (
                                        <p className="p-error" style={{ color: "#e24c4c" }}>
                                            This is required
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="flex flex-wrap  w-full  justify-content-between flex-row ">
                                    <Dropdown
                                        placeholder="MM"
                                        value={formik.values.month}
                                        id="month"
                                        filter
                                        onChange={(e) => {
                                            if (selectedYear !== null && selectedDay !== null) {
                                                setCheckDOBError(false);
                                            }
                                            setMonthError(false);
                                            formik.handleChange(e);

                                            formik.handleChange(e);

                                            setSelectedMonth(e.value);
                                        }}
                                        options={monthOptions}
                                        className={classNames(
                                            "dropdowndate w-full input_text calendar_field_date", // Common classes
                                            {
                                                "has-value": formik.values.month,
                                                "no-value": !formik.values.month,
                                                "p-invalid": dayerror,
                                                "dropdown calendar_field_date": formik.values.month,
                                            },
                                        )}
                                    />
                                    <Dropdown
                                        placeholder="DD"
                                        value={formik.values.day}
                                        id="day"
                                        filter
                                        onChange={(e) => {
                                            if (selectedYear !== null && selectedMonth !== null) {
                                                setCheckDOBError(false);
                                            }
                                            setDayError(false);
                                            formik.handleChange(e);
                                            setSelectedDay(e.value);
                                            if (formik.values.month !== null && !isNaN(formik.values.month) && formik.values.year !== null && !isNaN(formik.values.year)) {
                                                const dateObject = new Date(formik.values.year, formik.values.month - 1, e.value);
                                                const isoString = dateObject.toISOString();
                                                formik.setFieldValue("DOB", isoString);
                                                setParentDetails((prev) => ({
                                                    ...prev,
                                                    DOB: isoString,
                                                }));
                                            }
                                        }}
                                        options={dayoption}
                                        className={classNames("dropdowndate w-full input_text calendar_field_date", {
                                            "has-value": formik.values.day,
                                            "no-value": !formik.values.day,
                                            "p-invalid": dayerror,
                                            "dropdown calendar_field_date": formik.values.day,
                                        })}
                                    />
                                    <Dropdown
                                        placeholder="YYYY"
                                        filter
                                        className={classNames("dropdowndate w-full input_text calendar_field_date", {
                                            "has-value": formik.values.year,
                                            "no-value": !formik.values.year,
                                            "p-invalid": yearerror,
                                            "dropdown calendar_field_date": formik.values.year,
                                        })}
                                        value={formik.values.year}
                                        name="year"
                                        itemTemplate={customItemTemplate}
                                        onChange={(e) => {
                                            if (selectedMonth !== null && selectedDay !== null) {
                                                setCheckDOBError(false);
                                            }
                                            setYearError(false);
                                            setSelectedYear(e.value);
                                            if (formik.values.month !== null && !isNaN(formik.values.month) && formik.values.day !== null && !isNaN(formik.values.day)) {
                                                if (isLeapYear(e.value)) {
                                                    if (formik.values.month === 2) {
                                                        if (formik.values.day <= 29) {
                                                            const dateObject = new Date(e.value, formik.values.month - 1, formik.values.day);
                                                            const isoString = dateObject.toISOString();

                                                            formik.setFieldValue("DOB", isoString);
                                                            setParentDetails((prev) => ({
                                                                ...prev,
                                                                DOB: isoString,
                                                            }));
                                                        } else {
                                                            formik.setFieldValue("day", 29);
                                                            setSelectedDay(e.value);
                                                            const dateObject = new Date(e.value, formik.values.month - 1, 29);
                                                            const isoString = dateObject.toISOString();

                                                            formik.setFieldValue("DOB", isoString);
                                                            setParentDetails((prev) => ({
                                                                ...prev,
                                                                DOB: isoString,
                                                            }));
                                                        }
                                                    }
                                                } else {
                                                    if (formik.values.month === 2) {
                                                        if (formik.values.day > 28) {
                                                            formik.setFieldValue("day", 28);
                                                            setSelectedDay(28);
                                                            const dateObject = new Date(e.value, formik.values.month - 1, 28);
                                                            const isoString = dateObject.toISOString();

                                                            formik.setFieldValue("DOB", isoString);
                                                            setParentDetails((prev) => ({
                                                                ...prev,
                                                                DOB: isoString,
                                                            }));
                                                        } else {
                                                            const dateObject = new Date(e.value, formik.values.month - 1, formik.values.day);
                                                            const isoString = dateObject.toISOString();

                                                            formik.setFieldValue("DOB", isoString);
                                                            setParentDetails((prev) => ({
                                                                ...prev,
                                                                DOB: isoString,
                                                            }));
                                                        }
                                                    }
                                                }
                                                formik.handleChange(e);
                                            } else {
                                                formik.handleChange(e);
                                            }
                                        }}
                                        options={yearOptions}
                                    />
                                </div>
                            </div>

                            <div className="calendar_field" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">
                                        Email
                                        <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                            *
                                        </span>
                                    </label>
                                    {getFormErrorMessage("email")}
                                </div>
                                <InputText
                                    id="email"
                                    value={formik.values.email}
                                    placeholder="Email"
                                    onChange={(e) => {
                                        formik.setFieldValue("email", e.target.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }));
                                    }}
                                    onBlur={formik.handleBlur}
                                    className={classNames({ "p-invalid": isFormFieldValid("email") }, "input_text w-full")}
                                />
                            </div>
                            <div className="calendar_field" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label" htmlFor="contact">
                                        Contact Number
                                        <span className="steric " style={{ marginLeft: "-.1rem" }}>
                                            *
                                        </span>
                                    </label>
                                    {getFormErrorMessage("contact")}
                                </div>

                                <InputText
                                    onChange={(e) => {
                                        formik.setFieldValue("contact", e.target.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            contact: e.target.value,
                                        }));
                                    }}
                                    id="contact"
                                    value={formik.values.contact}
                                    placeholder="+1 ** *** ****"
                                    onBlur={formik.handleBlur}
                                    className={classNames({ "p-invalid": isFormFieldValid("contact") }, "input_text w-full")}
                                    minLength={10}
                                    maxLength={10}
                                    keyfilter={/^[0-9]*$/}
                                    pattern="^(?!1|0|800|888|877|866|855|844|833).*$"
                                />
                            </div>
                        </div>
                        <div className="flex" style={{ marginTop: "1.2rem" }}>
                            <div className="calendar_field ">
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label">Alternate Contact</label>
                                </div>

                                <InputText
                                    onChange={(e) => {
                                        formik.setFieldValue("alternateContact", e.target.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            alternateContact: e.target.value,
                                        }));
                                    }}
                                    placeholder="+1 ** *** ****"
                                    id="alternateContact"
                                    value={formik.values.alternateContact}
                                    onBlur={formik.handleBlur}
                                    minLength={10}
                                    maxLength={10}
                                    keyfilter={/^[0-9]*$/}
                                    pattern="^(?!1|0|800|888|877|866|855|844|833).*$"
                                    className="w-full input_text "
                                    style={{ width: "49%" }}
                                />
                            </div>
                            <div className="calendar_field" style={{ marginLeft: "12px" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label" htmlFor="sales">
                                        Sales Channel
                                        <span className="steric ">*</span>
                                    </label>
                                    {getFormErrorMessage("salesChannel")}
                                </div>
                                <Dropdown
                                    style={{ width: "100%" }}
                                    placeholder="Sales Channel"
                                    id="salesChannel"
                                    className={classNames(
                                        "dropdown w-full input_text", // Common classes
                                        {
                                            "has-value": formik.values.salesChannel,
                                            "no-value": !formik.values.salesChannel,
                                            "p-invalid": isFormFieldValid("salesChannel"),
                                            calendar_field_date: formik.values.salesChannel,
                                        },
                                    )}
                                    options={salesChannelOptions}
                                    value={formik.values.salesChannel}
                                    onChange={(e) => {
                                        formik.setFieldValue("salesChannel", e.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            salesChannel: e.value,
                                        }));
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="calendar_field" style={{ marginLeft: "1.2rem" }}>
                                <div className="flex w-full justify-content-between">
                                    <label className="field_label" htmlFor="sales">
                                        Relation With Parent
                                        <span className="steric ">*</span>
                                    </label>
                                    {formik.touched.relationWithParent && formik.errors.relationWithParent ? <span className="p-error">This is Required</span> : ""}
                                </div>
                                <Dropdown
                                    options={relationWithParentOptions}
                                    placeholder="Relation With Parent"
                                    id="relationWithParent"
                                    className={`dropdown w-full ${formik.values.relationWithParent ? "has-value" : "no-value"} ${formik.touched.relationWithParent && formik.errors.relationWithParent ? "error" : ""} `}
                                    value={formik.values.relationWithParent}
                                    onChange={(e) => {
                                        formik.setFieldValue("relationWithParent", e.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            relationWithParent: e.value,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="personalsocial">
                    <div className="calendar_field" style={{ height: "5.6rem" }}>
                        <p>What is the Best way to reach you?</p>
                        <div className="radiobtns w-full" style={{ marginTop: "1.6rem" }}>
                            <div className="flex align-items-center " style={{ gap: ".55rem" }}>
                                <RadioButton
                                    style={{ zIndex: "0" }}
                                    inputId="email"
                                    name="email"
                                    value="email"
                                    onChange={(e) => {
                                        setSelectedOption(e.value);
                                        formik.setFieldValue("bestWayToReach", e.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            bestWayToReach: e.value,
                                        }));
                                    }}
                                    checked={selectedOption === "email"}
                                />
                                <label className="radiolabel" htmlFor="email">
                                    Email
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ marginLeft: "2.4rem", gap: ".55rem" }}>
                                <RadioButton
                                    style={{ zIndex: "0" }}
                                    inputId="Phone"
                                    name="phone"
                                    value="phone"
                                    onChange={(e) => {
                                        setSelectedOption(e.value);
                                        formik.setFieldValue("bestWayToReach", e.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            bestWayToReach: e.value,
                                        }));
                                    }}
                                    checked={selectedOption === "phone"}
                                />
                                <label className="radiolabel" htmlFor="phone">
                                    Phone
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ marginLeft: "2.4rem", gap: ".55rem" }}>
                                <RadioButton
                                    style={{ zIndex: "0" }}
                                    inputId="message"
                                    name="message"
                                    value="message"
                                    onChange={(e) => {
                                        setSelectedOption(e.value);
                                        formik.setFieldValue("bestWayToReach", e.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            bestWayToReach: e.value,
                                        }));
                                    }}
                                    checked={selectedOption === "message"}
                                />
                                <label className="radiolabel" htmlFor="message">
                                    SMS
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ marginLeft: "2.4rem", gap: ".55rem" }}>
                                <RadioButton
                                    style={{ zIndex: "0" }}
                                    inputId="mail"
                                    name="mail"
                                    value="mail"
                                    onChange={(e) => {
                                        setSelectedOption(e.value);
                                        formik.setFieldValue("bestWayToReach", e.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            bestWayToReach: e.value,
                                        }));
                                    }}
                                    checked={selectedOption === "mail"}
                                />
                                <label className="radiolabel" htmlFor="mail">
                                    Mail
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ marginLeft: "2.4rem", gap: ".55rem" }}>
                                <RadioButton
                                    style={{ zIndex: "0" }}
                                    inputId="any"
                                    name="any"
                                    value="any"
                                    onChange={(e) => {
                                        setSelectedOption(e.value);
                                        formik.setFieldValue("bestWayToReach", e.value);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            bestWayToReach: e.value,
                                        }));
                                    }}
                                    checked={selectedOption === "any"}
                                />
                                <label className="radiolabel" htmlFor="any">
                                    Any
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="calendar_field" style={{ marginTop: "3.2rem" }}>
                        <p>Required E-Sim?</p>
                        <div className="radiobtns w-full" style={{ marginTop: "1.6rem" }}>
                            <div className="flex align-items-center " style={{ gap: ".55rem" }}>
                                <RadioButton
                                    style={{ zIndex: "0" }}
                                    id="ESIm"
                                    value={true}
                                    checked={eSim === true}
                                    onChange={(e) => {
                                        handleESim(e);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            ESim: true,
                                        }));
                                    }}
                                ></RadioButton>
                                <label className="radiolabel">Yes</label>
                                <div />
                            </div>
                            <div className="flex align-items-center" style={{ marginLeft: "2.4rem", gap: ".55rem" }}>
                                <RadioButton
                                    style={{ zIndex: "0" }}
                                    id="ESIm"
                                    value={false}
                                    checked={eSim === false}
                                    onChange={(e) => {
                                        handleESim(e);
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            ESim: false,
                                        }));
                                    }}
                                ></RadioButton>
                                <label className="radiolabel -mt-1">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="flex flex-wrap  justify-content-end" style={{ marginTop: "11.6rem", marginBottom: "3.2rem", width: "92.4rem" }}>
                {" "}
                <Button
                    className=" nextbtn"
                    label="Next"
                    type="submit"
                    onClick={() => {
                        if (selectedDay === null || isNaN(selectedDay) || selectedMonth === null || isNaN(selectedDay) || selectedYear === null || isNaN(selectedDay)) {
                            if (selectedDay === null || isNaN(selectedDay)) {
                                setDayError(true);
                            }
                            if (selectedYear === null || isNaN(selectedYear)) {
                                setYearError(true);
                            }
                            if (selectedMonth === null || isNaN(selectedMonth)) {
                                setMonthError(true);
                            }
                            setCheckDOBError(true);
                        }
                        formik.handleSubmit();
                    }}
                    icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                    disabled={isLoading || isDuplicate}
                    style={{ marginRight: "3.2rem" }}
                />
            </div>
        </>
    );
};

export default PersonalInfo;
