import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../../../../../utility/AxiosInceptor";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Toast } from "primereact/toast";

const EcheckAuthPayment = ({ setPayinvoicesVisible, setRefresh, cpData, selectedInvoices, amount }) => {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const toast = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        totalAmount: Yup.string().required("Please Select Amount"),
        routingNumber: Yup.string().required("This is required"),
        accountNumber: Yup.string().required("This is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            totalAmount: "",
            amount: amount,
            routingNumber: "",
            accountNumber: "",
            nameOnAccount: "",
            customerId: cpData?._id,
            invoices: [],
        },

        onSubmit: async (values, actions) => {
            setIsLoading(true);

            const idArray = selectedInvoices.map((obj) => obj._id);
            formik.values.invoices = idArray;

            api.post(`/api/web/invoices/echeckpayment`, formik.values)
                .then((res) => {
                    let response = res;
                    if (response?.data?.data?.responseCode == "1") {
                        Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                            invoices: formik.values.invoices,
                            amountPaid: formik.values.totalAmount,
                            invoicePaymentMethod: "E Check",
                            customerId: formik.values.customerId,
                            userId: parseLoginRes?._id,

                            transactionId: res?.data?.transId,
                            isWallet: false,
                        })
                            .then(() => {
                                //toast.current.show({ severity: "success", summary: "Invoices Updation", detail: "Invoices Updated Successfully" });

                                setIsLoading(false);
                                //  setRefresh(prev=>!prev)
                            })
                            .catch((err) => {
                                setIsLoading(false);
                                // toast.current.show({ severity: "error", summary: "Invoices Updation", detail: "Invoice Updation Failed" });
                            });
                        toast.current.show({ severity: "success", summary: "Payment Processed", detail: response?.data?.data?.messages[0]?.description });
                        setTimeout(() => {
                            setRefresh((prev) => !prev);
                            setPayinvoicesVisible(false);
                        }, 500);
                    } else {
                        if (response?.data?.data?.responseCode === "2") {
                            toast.current.show({ severity: "error", summary: "Payment Proceed", detail: response?.data?.data?.errors[0]?.errorText });
                        }
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Payment Proceed", detail: "Payment Proceeding Failed" });
                    setIsLoading(false);
                });
        },
    });
    formik.values.amount = amount;
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-12  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>
                                        <tr className="text-lg">
                                            <td>
                                                Total Amount: <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="amount" value={formik.values.amount} onChange={formik.handleChange} disabled />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Amount <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="totalAmount" value={formik.values.totalAmount} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("totalAmount") }, "input_text")} />
                                                {getFormErrorMessage("totalAmount")}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-lg">
                                                Account Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("accountNumber") }, "input_text")} type="text" id="accountNumber" value={formik.values.accountNumber} maxLength={16} onChange={formik.handleChange} />
                                                {getFormErrorMessage("accountNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Routing Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("routingNumber") }, "input_text")} type="text" id="routingNumber" maxLength={9} value={formik.values.routingNumber} onChange={formik.handleChange} />
                                                {getFormErrorMessage("routingNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Name On Account <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("nameOnAccount") }, "input_text")} type="text" id="nameOnAccount" value={formik.values.nameOnAccount} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Account Type <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("accountType") }, "input_text")} type="text" id="accountType" value={formik.values.accountType} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <div className="mt-2">
                                            <Button label="Submit" className="btn" type="submit" disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={toast} />
            </form>
        </>
    );
};
export default EcheckAuthPayment;
