import React, { useRef, useState } from "react";
import "./DownloadInvoice.css";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../../../components/Loader/LoaderBlue";
import DownloadInvoiceTemplate from "./Components/DownloadInvoiceTemplate";
export const DownloadInvoice = ({ setSelectedAction, cpData, currentInvoice, setCurrentActiveDialog }) => {
    let toast = useRef();
    const LoginRes = JSON.parse(localStorage.getItem("userData"));
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({});
    const [singleInvoiceData, setInvoiceData] = useState();

    const [invoiceDownload, setInvoiceDownload] = useState(false);

    const formik = useFormik({
        //validationSchema: validationSchema,
        initialValues: {
            reason: "",
            discountedAmount: "",
            customerId: cpData?._id,
            userId: LoginRes?._id,
            invoiceId: currentInvoice?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            api.get(`/api/web/invoices/getinvoicebyid?invoiceId=${currentInvoice?._id}`)
                .then((res) => {
                    setInvoiceData(res?.data?.data);
                    setInvoiceDownload((prev) => !prev);
                })
                .catch((err) => {});
        },
    });
    return (
        <>
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
                id="invoice-download-main"
                className="invoice-download-main flex flex-wrap flex-row justify-content-center"
            >
                <div className="main-download-invoice">
                    <div className="top-header-downloadinvoice">
                        <h1>Confirm Invoice Download</h1>
                        <p>Are you sure you want to download this invoice? Click Download to proceed.</p>
                    </div>
                    <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                setSelectedAction("");
                                setCurrentActiveDialog({
                                    void: false,
                                    lateFee: false,
                                    adjustInvoice: false,
                                    download: false,
                                });
                            }}
                            label="Cancel"
                        />
                        <Button
                            type="Submit"
                            label={
                                isLoading ? (
                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                    </div>
                                ) : (
                                    "Download"
                                )
                            }
                        />
                    </div>
                </div>
                <Toast ref={toast} />
            </form>
            <DownloadInvoiceTemplate setCurrentActiveDialog={setCurrentActiveDialog} userDetails={cpData} invoiceDownload={invoiceDownload} invoiceData={singleInvoiceData} setIsLoading={setIsLoading} />
        </>
    );
};
