import React, { useEffect, useRef, useState } from "react";
import "../css/NoteDetails.css";
import moment from "moment";
import { Button } from "primereact/button";
import CommonLoaderBlue from "../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
export const NoteDetails = ({ setRefreshNoteOnCaseAdded, setIsViewNote, noteAllData }) => {
    const [isVoidLoading, setIsVoidLoading] = useState(false);
    const toast = useRef();
    function markVoidNote() {
        setIsVoidLoading(true);
        api.put(`/api/web/notes/markVoid`, { noteId: noteAllData._id, markVoid: true })
            .then(() => {
                toast.current.show({ severity: "success", summary: "Info", detail: "Note was successfully voided." });
                setIsVoidLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error?.response?.data?.msg || "Failed to void the note" });
                setIsVoidLoading(false);
            });
    }
    useEffect(() => {
        return () => {
            setRefreshNoteOnCaseAdded((prev) => !prev);
        };
    }, []);
    return (
        <div className="viewNote-main">
            <div
                onClick={() => {
                    //setIsAddNote((prev) => !prev);
                    setIsViewNote(false);
                }}
                className="goback-viewNote"
            >
                <i className="pi pi-angle-left" />
            </div>
            <div className="header-add-note">
                <h1 className="header-top-header">Notes</h1>
                <p className="header-details-header">Add, view, and manage agent notes for a complete record of customer interactions.</p>
            </div>
            <div className="note-fields-view">
                <div className="flex flex-wrap flex-row justify-content-between align-items-center field-wrapper">
                    <p>Customer ID</p>
                    <p>{noteAllData?.customerId?.accountId || "NIL"}</p>
                </div>
                <div className="flex flex-wrap flex-row justify-content-between align-items-center field-wrapper">
                    <p>Note type</p>
                    <p>{noteAllData?.noteType || "NIL"}</p>
                </div>
                <div className="flex flex-wrap flex-row justify-content-between align-items-center field-wrapper">
                    <p>Priority</p>
                    <p>{noteAllData?.priority || "NIL"}</p>
                </div>
                <div className="flex flex-wrap flex-row justify-content-between align-items-center field-wrapper">
                    <p>Posted By</p>
                    <p>{noteAllData?.user?.name || "NIL"}</p>
                </div>
                <div className="flex flex-wrap flex-row justify-content-between align-items-center field-wrapper">
                    <p>Date & Time</p>
                    <p>{noteAllData?.createdAt ? moment.utc(noteAllData.createdAt).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss") : "NIL"}</p>
                </div>
                <div className="flex flex-wrap flex-row justify-content-between align-items-center field-wrapper">
                    <p>Assign To</p>
                    <p>{noteAllData?.assignTo?.name || "NIL"}</p>
                </div>
            </div>
            <div className="main-note-details">
                <p>{noteAllData.note || ""}</p>
            </div>
            <div className="flex flex-wrap flex-row justify-content-end align-items-center">
                <Button
                    onClick={markVoidNote}
                    disabled={isVoidLoading || noteAllData.void}
                    label={
                        isVoidLoading ? (
                            <div className="flex flex-wrap flex-row justify-content-center w-full">
                                <CommonLoaderBlue customSize={12} />{" "}
                            </div>
                        ) : (
                            `Mark Void`
                        )
                    }
                    className="markvoidbutton"
                />
            </div>
            <Toast ref={toast} />
        </div>
    );
};
