export const detectCardType = (number) => {
    console.log(number);

    const visaRegex = /^4[0-9]{12,18}$/;
    const masterCardRegex = /^(5[1-5][0-9]{14}|2[2-7][0-9]{14})$/;
    const amexRegex = /^3[47][0-9]{13}$/;
    const discoverRegex = /^(6011[0-9]{12}|6221[2-9][0-9]{10}|622[2-8][0-9]{12}|64[4-9][0-9]{13}|65[0-9]{14})$/;
    const dinersClubRegex = /^(30[0-5][0-9]{11}|36[0-9]{12}|38[0-9]{12}|5[45][0-9]{14})$/;
    const jcbRegex = /^(35[2-8][0-9]{13})$/;
    const unionPayRegex = /^(62[0-9]{14,17}[0-9]?)$/; // Now supports 19-digit UnionPay cards
    const maestroRegex = /^(50|5[6-9]|6[0-9])[0-9]{10,17}$/;
    const mirRegex = /^220[0-4][0-9]{12}$/;

    if (visaRegex.test(number)) return "Visa";
    if (masterCardRegex.test(number)) return "MasterCard";
    if (amexRegex.test(number)) return "American Express";
    if (discoverRegex.test(number)) return "Discover";
    if (dinersClubRegex.test(number)) return "Diners Club";
    if (jcbRegex.test(number)) return "JCB";
    if (unionPayRegex.test(number)) return "UnionPay"; // Now detects 19-digit UnionPay cards
    if (maestroRegex.test(number)) return "Maestro";
    if (mirRegex.test(number)) return "Mir";

    return "";
};
