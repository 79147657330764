import React from "react";
export const StatusIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9911 6.00664C13.6445 7.66002 13.6445 10.3407 11.9911 11.9941C10.3378 13.6475 7.65709 13.6475 6.00371 11.9941C4.35033 10.3407 4.35033 7.66002 6.00371 6.00664C7.65709 4.35326 10.3378 4.35326 11.9911 6.00664"
                stroke="currentColor" // Changed to currentColor
                stroke-width="1.125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M6.45702 15.2776C5.10327 14.7331 3.91527 13.7513 3.12852 12.3893C2.35827 11.0558 2.10027 9.57082 2.28252 8.15332" stroke="currentColor" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.83398 3.6592C5.98223 2.7592 7.42673 2.2207 8.99948 2.2207C10.5392 2.2207 11.9545 2.7397 13.0907 3.60595" stroke="currentColor" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.543 15.2776C12.8967 14.7331 14.0847 13.7513 14.8715 12.3893C15.6417 11.0558 15.8997 9.57082 15.7175 8.15332" stroke="currentColor" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
