import React, { useRef, useState } from "react";
import "./css/RowExpansionTemplate.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { api } from "../../../../../../../../../../utility/AxiosInceptor";
import { PlanEditIcon } from "../../../../../../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
import { toast } from "react-toastify";

export const RowExpansionTemplate = ({ PaymentLogExpansionRowData, cpData }) => {
    const op = useRef(null);
    const OverlayRef = useRef(null);

    const [overlayContent, setOverlayContent] = useState("");

    const [rowData, setRowData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefersh] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const Refund = async () => {
        const dataToSend1 = {
            paymentIntentId: rowData?.transactionId,
            customerId: cpData?._id,
            userId: parseLoginRes?._id,
            amount: rowData?.amount,
        };
        try {
            const response = await api.post(`/api/web/billing/refund`, dataToSend1);
            if (response?.status === 200 || response?.status === 201) {
                toast.success(response?.data?.msg);
                setIsLoading(false);
                setRefersh(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };
    const Void = async () => {
        const dataToSend1 = {
            transactionId: rowData?.transactionId,
            customerId: cpData?._id,
            userId: parseLoginRes?._id,
        };
        try {
            const response = await api.post(`/api/web/billing/voidCharge`, dataToSend1);
            if (response?.status === 200 || response?.status === 201) {
                toast.success("Void Successfully");
                setRefersh(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    return (
        <div className="row-expansion-container">
            <DataTable showHeaders={false} value={PaymentLogExpansionRowData[0].remaining}>
                <Column
                    field="invoiceNo"
                    header="Invoice No"
                    body={(rowData) => (
                        <div className="expander-row-invoiceno">
                            <i style={{ border: "none !important" }} />
                            <p>{rowData?.invoiceNo}</p>
                        </div>
                    )}
                />

                <Column
                    header="Created Date"
                    body={(rowData) => {
                        return (
                            <span>
                                {moment(rowData.date)
                                    .tz("America/New_York") // Convert to New York time (Eastern Time Zone)
                                    .format("MM-DD-YYYY")}
                            </span>
                        );
                    }}
                />
                <Column
                    field="invoiceType"
                    header="Type"
                    body={(rowData) => (
                        <>
                            <p
                                onMouseEnter={(e) => {
                                    if (rowData?.invoiceType[0].length > 15) {
                                        setOverlayContent(rowData?.invoiceType[0]);
                                        OverlayRef.current.show(e);
                                    }
                                }}
                                onMouseLeave={() => OverlayRef.current.hide()}
                            >
                                {rowData?.invoiceType[0].length > 15 ? rowData?.invoiceType[0]?.substring(0, 12) + "..." : rowData.type}
                            </p>
                            <OverlayPanel ref={OverlayRef}>
                                <div style={{ padding: "5px 10px" }}>
                                    <p
                                        style={{
                                            fontWeight: 400,
                                            fontSize: "1.4rem",
                                            overflow: "hidden",
                                            border: "none",
                                            margin: "0px",
                                            lineHeight: "1.8rem",
                                            color: "#72728f",
                                        }}
                                    >
                                        {" "}
                                        {overlayContent}
                                    </p>
                                </div>
                            </OverlayPanel>
                        </>
                    )}
                />

                <Column field="paymentStatus" body={(rowData) => <p className={`${rowData.paymentStatus === "failure" ? "status-failure" : "status-success"}`}>{rowData.paymentStatus.charAt(0).toUpperCase() + rowData.paymentStatus.slice(1)}</p>} header="Status" />
                <Column
                    field="cardDetails.cardNumber"
                    header="Card Details"
                    body={(rowData) => {
                        return (
                            <>
                                <p
                                    onMouseEnter={(e) => {
                                        if (rowData?.cardDetails?.cardNumber?.length > 4) {
                                            setOverlayContent(rowData?.cardDetails?.cardNumber);
                                            OverlayRef.current.show(e);
                                        }
                                    }}
                                    onMouseLeave={() => OverlayRef.current.hide()}
                                >
                                    {rowData?.cardDetails?.cardNumber ? (rowData?.cardDetails?.cardNumber?.length > 4 ? rowData?.cardDetails?.cardNumber?.substring(0, 4) + "..." : rowData?.cardDetails?.cardNumber) : "NIL"}
                                </p>
                                <OverlayPanel ref={OverlayRef}>
                                    <div style={{ padding: "5px 10px" }}>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: "1.4rem",
                                                overflow: "hidden",
                                                border: "none",
                                                margin: "0px",
                                                lineHeight: "1.8rem",
                                                color: "#72728f",
                                            }}
                                        >
                                            {" "}
                                            {overlayContent}
                                        </p>
                                    </div>
                                </OverlayPanel>
                            </>
                        );
                    }}
                />

                <Column field="amount" header="Amount" body={(rowData) => (rowData?.amount !== undefined && rowData?.amount !== "NaN" && rowData?.amount !== "" ? parseFloat(rowData?.amount).toFixed(2) : "0.00")} />
                <Column field="currentBalance" header="Current Bill" body={(rowData) => (rowData?.currentBalance !== undefined && rowData?.currentBalance !== "NaN" && rowData?.currentBalance !== "" ? parseFloat(rowData?.currentBalance).toFixed(2) : "0.00")} />
                <Column field="previousBalance" body={(rowData) => (rowData?.previousBalance !== undefined && rowData?.previousBalance !== "NaN" && rowData?.previousBalance !== "" ? parseFloat(rowData?.previousBalance).toFixed(2) : "0.00")} header="Previous Balance" />

                <Column
                    field="transactionId"
                    header="Transaction ID"
                    body={(rowData) => {
                        return (
                            <>
                                <p
                                    onMouseEnter={(e) => {
                                        if (rowData?.transactionId?.length > 23) {
                                            setOverlayContent(rowData?.transactionId);
                                            OverlayRef.current.show(e);
                                        }
                                    }}
                                    onMouseLeave={() => OverlayRef.current.hide()}
                                >
                                    {rowData?.transactionId ? (rowData?.transactionId?.length > 23 ? rowData?.transactionId?.substring(0, 22) + "..." : rowData?.transactionId) : "NIL"}
                                </p>
                                <OverlayPanel ref={OverlayRef}>
                                    <div style={{ padding: "5px 10px" }}>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: "1.4rem",
                                                overflow: "hidden",
                                                border: "none",
                                                margin: "0px",
                                                lineHeight: "1.8rem",
                                                color: "#72728f",
                                            }}
                                        >
                                            {overlayContent}
                                        </p>
                                    </div>
                                </OverlayPanel>
                            </>
                        );
                    }}
                />
                <Column
                    field="Actions"
                    header="Actions"
                    body={() => {
                        return (
                            <div className="flex flex-wrap flex-row justify-content-end  w-full h-full align-items-center main-action-wrapper actionbtnmain">
                                <div
                                    className="flex flex-wrap flex-row justify-content-end  w-full h-full align-items-center main-action-wrapper actionbtnmain"
                                    onClick={(e) => {
                                        op.current.toggle(e);
                                        setRowData(rowData);
                                    }}
                                >
                                    <PlanEditIcon />
                                </div>
                                <OverlayPanel ref={op} style={{ width: "10.9rem", height: "8rem" }} className="flex align-items-center billingconfig">
                                    <div className="flex flex-column w-full overlay">
                                        <div
                                            className="flex justify-content-start align-items-center prospectoverlay"
                                            style={{ height: "32px", width: "10.7rem", paddingLeft: "1.2rem" }}
                                            onClick={() => {
                                                setRowData(rowData);
                                                Void();
                                            }}
                                        >
                                            <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Void</label>
                                        </div>
                                        <div
                                            className="flex justify-content-start align-items-center prospectoverlay"
                                            style={{ height: "32px", width: "10.7rem", paddingLeft: "1.2rem" }}
                                            onClick={() => {
                                                setRowData(rowData);
                                                Refund();
                                            }}
                                        >
                                            <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Refund</label>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                        );
                    }}
                />
            </DataTable>
        </div>
    );
};
