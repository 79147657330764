import React, { useEffect, useRef, useCallback, useState } from "react";
import "../All-Customers/AllCustomers.css";
import { InputText } from "primereact/inputtext";
import { Calendar, DownloadIcon, NextIcon, PrevIcon, Search, SelectorIcon, Status } from "../../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
import { useFormik } from "formik";
import moment from "moment";
import Calendardropdown from "./Calendar/Calendardropdown";
import { debounce } from "lodash";
import { api } from "../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact";
import CommonLoaderBlue from "../../../../../components/Loader/LoaderBlue";
import { Button } from "primereact/button";
import { error } from "highcharts";
const AllCustomers = ({ setRefreshCustomerData, setShowAllCustomers }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const toast = useRef();
    const [floatPagesLeft, setFloatPagesLeft] = useState([]);
    const [floatPagesRight, setFloatPagesRight] = useState([]);
    const [secondTimeCalled, setSecondTimeCalled] = useState(false);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [hiddenPages, setHiddenPages] = useState([]);
    const [allUserList, setAllUserList] = useState([]);
    const [isFirstTimeCalledApi, setIsFirstTimeCalledApi] = useState(false);
    const [listLimit, setListLimit] = useState(18);
    const [currentPagination, setCurrentPagination] = useState({});
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const [value, setValue] = useState("");
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [shortSelection, setShortSelection] = useState();
    const op = useRef(null);
    const stat = useRef(null);
    const calendar = useRef(null);

    const formik = useFormik({
        initialValues: {
            prepaid: false,
            postpaid: false,
            acp: false,
            active: false,
            prospect: false,
            suspended: false,
            disconnected: false,
            cancelled: false,
            all: false,
        },
        onSubmit: (values) => {
            console.log(values);
        },
    });
    const handleAllChange = (checked) => {
        formik.setValues(() => ({
            all: checked,
            active: checked,
            prospect: checked,
            suspended: checked,
            disconnected: checked,
            cancelled: checked,
        }));
    };

    const handleIndividualChange = (field, checked) => {
        formik.setFieldValue(field, checked);
        if (!checked) {
            formik.setFieldValue("all", false);
        } else {
            const allChecked = ["active", "prospect", "suspended", "disconnected", "cancelled"].every((key) => (key === field ? checked : formik.values[key]));
            if (allChecked) {
                formik.setFieldValue("all", true);
            }
        }
    };
    useEffect(() => {
        setCurrentPage(1);
    }, [formik.values, selectedRange, value]);
    const fetchUsers = useCallback(
        debounce(() => {
            let accountTypeSelected = [];
            if (formik.values.prepaid) accountTypeSelected.push("Prepaid");
            if (formik.values.postpaid) accountTypeSelected.push("Postpaid");
            if (formik.values.acp) accountTypeSelected.push("Acp");

            let statusSelected = [];
            if (formik.values.active) statusSelected.push("active");
            if (formik.values.disconnected) statusSelected.push("disconnected");
            if (formik.values.prospect) statusSelected.push("prospect");
            if (formik.values.suspended) statusSelected.push("suspended");
            if (formik.values.cancelled) statusSelected.push("cancelled");
            api.get(
                `/api/user/all?serviceProvider=${loginRes?.company}&endDate=${selectedRange[1] ? moment(new Date(selectedRange[1])).format("MM-DD-YYYY") : ""}&startDate=${selectedRange[0] ? moment(new Date(selectedRange[0])).format("MM-DD-YYYY") : ""}&page=${currentPage}&search=${value}&limit=${listLimit}&accountType=${accountTypeSelected.join(",")}&status=${statusSelected.join(",")}`,
            )
                .then((res) => {
                    setAllUserList(res?.data?.data);
                    setCurrentPagination(res?.data?.pagination);

                    if (res?.data?.pagination.totalPages > 6) {
                        if (floatPagesLeft.length === 0 || res?.data?.pagination?.totalPages !== currentPagination.totalPages) {
                            setFloatPagesLeft([1, 2, 3]);
                            setHiddenPages([...Array.from({ length: res?.data?.pagination.totalPages - 5 }, (_, index) => 4 + index)]);
                            setFloatPagesRight([res?.data?.pagination.totalPages - 1, res?.data?.pagination.totalPages]);
                        }
                    }
                    if (isFirstTimeCalledApi) {
                        setSecondTimeCalled(true);
                    }
                    setIsFirstTimeCalledApi(true);
                })
                .catch((error) => {
                    if (isFirstTimeCalledApi) {
                        setSecondTimeCalled(true);
                    }
                    setIsFirstTimeCalledApi(true);
                    toast.current.show({ severity: "error", summary: "info", detail: error?.response?.data?.message || "Error Fetch Customer List" });
                });
        }, 500), // 500ms debounce time
        [formik.values, selectedRange, currentPage, value],
    );

    useEffect(() => {
        fetchUsers();
        return () => fetchUsers.cancel(); // Cleanup function to avoid unnecessary calls
    }, [fetchUsers]);
    return (
        <div className="allcustomersmain">
            <Toast ref={toast} />
            <div className="flex w-full justify-content-between align-items-center nav">
                <div className="flex align-items-center">
                    <h1 style={{ marginTop: "10px" }}>Customer Profile</h1>
                </div>
                {((isFirstTimeCalledApi && allUserList?.length > 0) || secondTimeCalled) && (
                    <div className="flex lign-items-center" style={{ gap: "20px", height: "40px" }}>
                        <div className="inputouter">
                            <span>
                                <Search />
                            </span>
                            <InputText className="input" type="search" placeholder="Search Here" value={value} onChange={(e) => setValue(e.target.value)} />
                        </div>
                        <div>
                            <button className="calendar" onClick={(e) => calendar.current.toggle(e)}>
                                <Calendar />
                            </button>
                            <OverlayPanel ref={calendar} className="calendaroverlay">
                                <Calendardropdown calendar={calendar} setSelectedRange={setSelectedRange} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} />
                            </OverlayPanel>
                        </div>
                        <div>
                            <button className="selector" onClick={(e) => op.current.toggle(e)}>
                                <SelectorIcon />
                            </button>
                            <OverlayPanel ref={op}>
                                <div className="flex flex-column moduleselectorallcustomer  overlay">
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "36px" }}>
                                        <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("prepaid", e.target.checked)} checked={formik.values.prepaid} />
                                        <label>Prepaid</label>
                                    </div>
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "36px" }}>
                                        <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("postpaid", e.target.checked)} checked={formik.values.postpaid} />
                                        <label>Postpaid</label>
                                    </div>
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "36px" }}>
                                        <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("acp", e.target.checked)} checked={formik.values.acp} />
                                        <label>ACP</label>
                                    </div>
                                </div>
                            </OverlayPanel>
                        </div>
                        <div>
                            <button className="statusselect" onClick={(e) => stat.current.toggle(e)}>
                                <Status />
                            </button>
                            <OverlayPanel ref={stat} className="overlaystatus">
                                <div className="flex flex-column enrollmentallcustomerselector w-full ">
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "3.6rem" }}>
                                        <input id="active" name="active" className="check" type="checkbox" onChange={(e) => handleIndividualChange("active", e.target.checked)} checked={formik.values.active} />
                                        <label>Active</label>
                                    </div>
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "3.6rem" }}>
                                        <input id="prospect" name="prospect" className="check" type="checkbox" onChange={(e) => handleIndividualChange("prospect", e.target.checked)} checked={formik.values.prospect} />
                                        <label>Prospect</label>
                                    </div>
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "3.6rem" }}>
                                        <input id="suspended" name="suspended" className="check" type="checkbox" onChange={(e) => handleIndividualChange("suspended", e.target.checked)} checked={formik.values.suspended} />
                                        <label>Suspended</label>
                                    </div>
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "3.6rem" }}>
                                        <input id="disconnected" name="disconnected" className="check" type="checkbox" onChange={(e) => handleIndividualChange("disconnected", e.target.checked)} checked={formik.values.disconnected} />
                                        <label>Disconnected</label>
                                    </div>
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "3.6rem" }}>
                                        <input id="cancelled" name="cancelled" className="check" type="checkbox" onChange={(e) => handleIndividualChange("cancelled", e.target.checked)} checked={formik.values.cancelled} />
                                        <label>Cancelled</label>
                                    </div>
                                    <div className="flex justify-content-start align-items-center" style={{ gap: "0.8rem", height: "3.6rem" }}>
                                        <input id="all" name="all" className="check" type="checkbox" onChange={(e) => handleAllChange(e.target.checked)} checked={formik.values.all} />
                                        <label>ALL</label>
                                    </div>
                                </div>
                            </OverlayPanel>
                        </div>
                        <button
                            onClick={async () => {
                                setIsExportLoading(true);
                                let accountTypeSelected = [];
                                if (formik.values.prepaid) accountTypeSelected.push("Prepaid");
                                if (formik.values.postpaid) accountTypeSelected.push("Postpaid");
                                if (formik.values.acp) accountTypeSelected.push("Acp");

                                let statusSelected = [];
                                if (formik.values.active) statusSelected.push("active");
                                if (formik.values.disconnected) statusSelected.push("disconnected");
                                if (formik.values.prospect) statusSelected.push("prospect");
                                if (formik.values.suspended) statusSelected.push("suspended");
                                if (formik.values.cancelled) statusSelected.push("cancelled");
                                api.get(
                                    `/api/user/exportCustomerData?serviceProvider=${loginRes?.company}&endDate=${selectedRange[1] ? moment(new Date(selectedRange[1])).format("MM-DD-YYYY") : ""}&startDate=${selectedRange[0] ? moment(new Date(selectedRange[0])).format("MM-DD-YYYY") : ""}&page=${currentPage}&search=${value}&limit=${listLimit}&accountType=${accountTypeSelected.join(",")}&status=${statusSelected.join(",")}`,
                                    { responseType: "blob" }, // Ensure binary response
                                )
                                    .then((response) => {
                                        const contentDisposition = response.headers["content-disposition"];
                                        let filename = "CustomerData.xlsx";
                                        if (contentDisposition) {
                                            const match = contentDisposition.match(/filename\*=UTF-8''([^;]+)|filename="?([^";]+)"?/);
                                            if (match) {
                                                filename = decodeURIComponent(match[1] || match[2]);
                                            }
                                        }

                                        const blob = new Blob([response.data], {
                                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        });

                                        const url = window.URL.createObjectURL(blob);
                                        const a = document.createElement("a");
                                        a.href = url;
                                        a.download = filename;
                                        document.body.appendChild(a);
                                        a.click();
                                        window.URL.revokeObjectURL(url);
                                        document.body.removeChild(a);

                                        setIsExportLoading(false);
                                    })
                                    .catch((error) => {
                                        setIsExportLoading(false);
                                        toast.current.show({ severity: "error", summary: "Export Customer", detail: error?.response?.data?.message || "Exporting Customers" });
                                    });
                            }}
                            className="export backbtn flex justify-content-center align-items-center"
                        >
                            {" "}
                            {!isExportLoading ? (
                                <div className="flex flex-wrap flex-row justify-content-start" style={{ marginTop: "-6px", height: "1.6rem", width: "6.9rem", gap: "0.8rem" }}>
                                    <p style={{ padding: "0px", margin: "0px" }}>Export</p>
                                    <div style={{ marginTop: "3px", marginLeft: "8px" }}>
                                        <DownloadIcon />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                    <CommonLoaderBlue color={"royalblue"} sizehere={14} />
                                </div>
                            )}
                        </button>
                    </div>
                )}
            </div>
            <div className="flex flex-wrap justify-content-start" style={{ gap: "0.8rem", width: "108.4rem", marginTop: "2.4rem" }}>
                {!isFirstTimeCalledApi ? (
                    <div className="flex mt-4  flex-wrap flex-row justify-content-center align-items-center w-full">
                        <CommonLoaderBlue sizehere={18} />
                    </div>
                ) : (
                    allUserList.map((customer) => (
                        <div
                            onClick={() => {
                                console.log("customer id is", customer);
                                localStorage.setItem("selectedId", JSON.stringify(customer._id));
                                setShowAllCustomers(false);
                                setRefreshCustomerData((prev) => !prev);
                            }}
                            className="cust cursor-pointer flex justify-content-evenly align-items-center"
                        >
                            <div className="flex flex-column justify-content-between align-items-start" style={{ width: "13.5rem", height: "7.2rem" }}>
                                <button className="custbtn cursor-pointer">
                                    {customer?.firstName?.substring(0, 1)?.toUpperCase()}
                                    {customer?.lastName?.substring(0, 1)?.toUpperCase()}
                                </button>
                                <p>
                                    {customer?.firstName} {customer?.lastName}
                                </p>
                            </div>
                            <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "18.1rem", height: "6.6rem", borderLeft: "1px solid #EFF2F8" }}>
                                <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                                    <h1
                                        style={{
                                            color:
                                                customer?.status === "prospect"
                                                    ? "#4A90E2"
                                                    : customer.status === "disconnected"
                                                      ? "#E74C3C"
                                                      : customer.status === "active"
                                                        ? "#27AE60"
                                                        : customer.status === "inActive"
                                                          ? "#E74C3C"
                                                          : customer.status === "rejected"
                                                            ? "#72728F"
                                                            : customer.status === "cancelled"
                                                              ? "#72728F"
                                                              : customer.status === "suspended"
                                                                ? "#F39C12"
                                                                : "",
                                        }}
                                    >
                                        {" "}
                                        {customer?.status === "prospect"
                                            ? "Prospect"
                                            : customer.status === "disconnected"
                                              ? "Disconnected"
                                              : customer.status === "active"
                                                ? "Active"
                                                : customer.status === "inActive"
                                                  ? "InActive"
                                                  : customer.status === "rejected"
                                                    ? "Rejected"
                                                    : customer.status === "cancelled"
                                                      ? "Cancelled"
                                                      : customer.status === "suspended"
                                                        ? "Suspended"
                                                        : ""}
                                    </h1>
                                    <label>{customer?.enrollmentId}</label>
                                    <h6>{customer?.contact}</h6>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <div style={{ marginTop: "2.4rem" }} className="flex flex-wrap w-full  flex-row justify-content-between align-items-center ">
                {isFirstTimeCalledApi && (
                    <h1 className="infoenrollmentsall">
                        {currentPagination?.total ? (currentPagination?.page * currentPagination.limit > currentPagination.total ? currentPagination.total : currentPagination?.page * currentPagination.limit) : "0"}/{currentPagination?.total ? currentPagination?.total : "0"}
                    </h1>
                )}
                {currentPagination?.totalPages > 1 && isFirstTimeCalledApi && (
                    <div style={{}} className="flex flex flex-wrap flex-row justify-content-between pages">
                        <button
                            disabled={currentPage === 1}
                            onClick={() => {
                                let pagetocheck = currentPage;
                                pagetocheck--;
                                if (currentPagination.totalPages <= 6 && pagetocheck > 0) {
                                    setCurrentPage(pagetocheck);
                                } else if (floatPagesLeft.includes(pagetocheck) || floatPagesRight.includes(pagetocheck)) {
                                    setCurrentPage(pagetocheck);
                                } else if (pagetocheck > 0) {
                                    if (pagetocheck === floatPagesLeft[0] - 1) {
                                        let arr = floatPagesLeft;

                                        arr.pop();
                                        arr.unshift(floatPagesLeft[0] - 1);
                                        setFloatPagesLeft(arr);
                                        setCurrentPage(pagetocheck);
                                    } else if (pagetocheck === floatPagesRight[0] - 1) {
                                        let arr = floatPagesRight;

                                        arr.pop();
                                        arr.unshift(floatPagesRight[0] - 1);
                                        setFloatPagesRight(arr);
                                        setCurrentPage(pagetocheck);
                                    }
                                }
                            }}
                            className="cursor-pointer backbtn"
                            style={{ opacity: currentPage === 1 && "0.6", backgroundColor: "#FFFFFF33", width: "10.6rem", height: "3.8rem", margin: "0rem" }}
                        >
                            <span>
                                <PrevIcon /> &nbsp; Previous
                            </span>
                        </button>
                        <div style={{ maxWidth: "26.9rem", gap: "1rem" }} className="flex flex-wrap flex-row justify-content-start align-items-start">
                            {currentPagination.totalPages <= 6 ? (
                                [...Array(currentPagination.totalPages)].map((_, index) => (
                                    <Button
                                        onClick={() => {
                                            setCurrentPage(index + 1);
                                        }}
                                        style={{ width: "3.1rem", height: "3.4rem" }}
                                        key={index + 1}
                                        className={currentPagination.page === index + 1 ? "pagecounteractive" : "pagecounter"}
                                        label={index + 1}
                                    />
                                ))
                            ) : (
                                <>
                                    {floatPagesLeft.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={() => {
                                                setCurrentPage(page);
                                            }}
                                            style={{ width: "3.1rem", height: "3.4rem" }}
                                            className={currentPagination.page === page ? "pagecounteractive" : "pagecounter"}
                                            label={page}
                                        />
                                    ))}
                                    {floatPagesLeft[floatPagesLeft.length - 1] + 1 !== floatPagesRight[0] && <Button className="pagecounter" label={"..."} />}

                                    {floatPagesRight.map((page) => (
                                        <Button
                                            onClick={() => {
                                                setCurrentPage(page);
                                            }}
                                            key={page}
                                            className={currentPagination.page === page ? "pagecounteractive" : "pagecounter"}
                                            label={page}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                        <button
                            disabled={currentPage === currentPagination.totalPages}
                            onClick={() => {
                                console.log(currentPagination);
                                let pagetocheck = currentPage;
                                pagetocheck++;
                                if (currentPagination.totalPages <= 6 && pagetocheck > 0 && pagetocheck <= currentPagination.totalPages) {
                                    setCurrentPage(pagetocheck);
                                } else if (floatPagesLeft.includes(pagetocheck) || floatPagesRight.includes(pagetocheck)) {
                                    setCurrentPage(pagetocheck);
                                } else if (pagetocheck <= currentPagination.totalPages) {
                                    if (pagetocheck === floatPagesLeft[floatPagesLeft.length - 1] + 1) {
                                        let arr = floatPagesLeft;

                                        arr.shift();
                                        arr.push(pagetocheck);
                                        setFloatPagesLeft(arr);
                                        setCurrentPage(pagetocheck);
                                    } else {
                                        if (pagetocheck === floatPagesRight[floatPagesRight.length - 1] + 1 && pagetocheck <= currentPagination.totalPages) {
                                            let arr = floatPagesRight;

                                            arr.shift();
                                            arr.push(pagetocheck);
                                            setFloatPagesRight(arr);
                                            setCurrentPage(pagetocheck);
                                        }
                                    }
                                }
                            }}
                            className="cursor-pointer backbtn"
                            style={{ opacity: currentPage === currentPagination.totalPages && "0.6", backgroundColor: "#FFFFFF33", width: "8rem", height: "3.8rem" }}
                        >
                            <span className="flex align-items-center">
                                Next &nbsp;
                                <NextIcon />
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllCustomers;
