import { api } from "../../../../../../../utility/AxiosInceptor.js";
import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { CarrierDisableIcon, Error, Falsebtn, PlanEmpty, Checked } from "../../../../../../../utility";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ToastContainer, toast } from "react-toastify";
import CardAuthPayment from "./dialog/CardAuthPayment";
import EcheckAuthPayment from "./dialog/EcheckAuthPayment";
import Payment from "./dialog/Stripe_Updated";
import "./PaymentScreen.css";
import { RadioButton } from "primereact/radiobutton";

import Square from "./dialog/squarefolder/Square.js";
import BankOfAmericaPaymentForm from "../../../../BankOfAmericaNew/BankOfAmericaNew.js";
import CommonLoaderBlue from "./dialog/components/Loader/LoaderBlue.js";
export default function PaymentScreen({
    isLinkedAccountIncomplete,
    setAllPaymentInfo,
    setCurrentAllPlans,
    currentAllPlans,
    setCurrentAllBillingConfig,
    setAllpaymentDone,
    setDataSendForInvoice,
    paymentDone,
    paymentScreenDetails,
    setPaymentDone,
    additionalsDiscounts,
    setAdditionalDiscounts,
    setPaymentScreenDetails,
    initiationData,
    billingModelSelected,
    inventoryIdSelected,
    setInventoryIdSelected,
    checked,
    setChecked,
    setPreviewScreenActive,
    currentBillingModel,
    setPaymentScreenActive,
    toast,
    // setConfirmationActive,
    setAddressActive,
    parentDetails,
    carrierVal,
    setCarrierVal,
    servicesVal,
    setServicesVal,
    deliveryVal,
    setDeliveryVal,
    checkLabelVal,
    setCheckLabelVal,
    generate,
    setGenerate,
    currentPlanSelect,
    setCurrentPlanSelect,
    current,
    inventoryTypeSelected,
    setInventoryTypeSelected,
    setCurrentSelect,
    amount,
    prospectCases,
    setProspectCases,
    setAmount,
    setSubscriptionActive,
    setDeliveryActive,
    setBillingActive,
    currentScreen,
    setCurrentScreen,
    payingAmount,
    setPayingAmount,
    setProductActive,
    setInventoryData,
    isGenerateInvoiceWithParent,
    setIsGenerateInvoiceWithParent,
}) {
    const [inventory, setInventory] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    let navigate = useNavigate();
    const [paymentmethoderror, setpaymentmethoderror] = useState(false);
    const [currentPlanName, setCurrentPlanName] = useState("");
    const [currentBillingConfig, setCurrentBillingConfig] = useState();
    const [dialogForCardAuth, setdialogForCardAuth] = useState(false);
    const [dialogForEcheck, setDialogForEcheck] = useState(false);
    const [additional, setAdditional] = useState([]);
    const [paidAmountRequired, setPaidAmountRequired] = useState(false);
    const [previousplanprice, setPreviousPlanPrice] = useState(0);
    const [discount, setDiscount] = useState([]);
    const [automate, setAutomate] = useState(false);
    //const [checked, setChecked] = useState(false);
    const _id = initiationData?._id;
    const [isLoading, setIsLoading] = useState(false);
    const [propectWithInvoice, setProspectWithInvoice] = useState(prospectCases?.prospectWithInvoice);
    const [propectWithOutInvoice, setProspectWithOutInvoice] = useState(prospectCases?.prospectWithoutInvoice);

    // const shippingLabelDiscount = parseLabelSubmissionVal?.data?.shippingLabelDiscount;
    const [carrier, setCarrier] = useState([]);
    const [expectedCost, setExpectedCost] = useState(false);
    const [services, setServices] = useState([]);
    const [rates, setRates] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [ratesLoading, setRatesLoading] = useState(false);
    const [previousRate, setPreviousRate] = useState();
    localStorage.setItem("generatelabel", generate);
    const deliveryFedEx = [{ name: "none" }, { name: "delivery" }, { name: "signature" }, { name: "direct_signature" }, { name: "adult_signature" }];

    localStorage.setItem("labelCheck", checkLabelVal);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const companyName = parseLoginRes?.companyName;
    const toCapitalCompanyName = companyName.toUpperCase();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // const [currentPlanSelect, setCurrentPlanSelect] = useState(paymentDone ? paymentScreenDetails?.currentPlanSelected : "");
    // Formik validation
    const validationSchema = Yup.object().shape({
        billId: Yup.string().required("Product is required"),
        plan: Yup.string().required("Plan is required"),
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error block">{formik.errors[name]}</small>;
    };
    // const [current, setCurrentSelect] = useState(paymentDone ? paymentScreenDetails?.inventorySelected : "");
    const [plan, setPlans] = useState([]);
    const [tmbPlans, setTmbPlans] = useState([]);
    const [attPlans, setAttPlans] = useState([]);
    const [attPlanSelect, setAttPlanSelect] = useState(true);
    const [expandedPlans, setExpandedPlans] = useState([]);
    const [tmbPlanSelect, setTmbPlanSelect] = useState(false);
    function capitalizeSentence(sentence) {
        // Split the sentence into words
        const words = sentence.split(" ");

        // Capitalize the first letter of each word and convert the rest to lowercase
        const capitalizedWords = words.map((word) => {
            // Convert the first letter to uppercase and the rest to lowercase
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });

        // Join the words back into a sentence
        return capitalizedWords.join(" ");
    }
    useEffect(() => {
        setAttPlanSelect(attPlans?.length > tmbPlans?.length ? true : tmbPlans.length < 1 && attPlans.length < 1 ? true : false);
        setTmbPlanSelect(tmbPlans?.length > attPlans.length ? true : false);
    }, [attPlans, tmbPlans]);

    const [paymentDialogVisibility, setPaymentDialogVisibility] = useState(false);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            plan: currentPlanSelect ? currentPlanSelect : paymentDone ? paymentScreenDetails?.plan : "",
            billId: current ? current : paymentDone ? paymentScreenDetails?.billId : "",
            paymentMode: paymentDone ? paymentScreenDetails?.paymentMode : isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.paymentMethod ? isLinkedAccountIncomplete?.paymentMethod : "",
            discount: additionalsDiscounts.discount,
            additional: additionalsDiscounts.additional,
            totalamount: "",
            paid: payingAmount,
            customerid: paymentDone ? paymentScreenDetails?.customerid : _id,
            type: "Sign Up ",
            inventorySelected: paymentDone ? paymentScreenDetails?.inventorySelected : "",
            currentPlanSelected: paymentDone ? paymentScreenDetails?.currentPlanSelected : "",
            isGenerateInvoiceWithParent: paymentDone ? paymentScreenDetails?.isGenerateInvoiceWithParent : isGenerateInvoiceWithParent,
            discounts: paymentDone ? paymentScreenDetails?.discounts : "",
            prospectwithinvoice: prospectCases?.prospectWithInvoice,
            prospectwithoutinvoice: prospectCases?.prospectWithoutInvoice,
        },

        onSubmit: async (values, actions) => {
            if (formik.values.prospectwithoutinvoice || formik.values.prospectwithinvoice) {
                setPaymentScreenDetails(formik.values);
                setPaymentScreenActive(false);
                setPreviewScreenActive(true);
            } else {
                // if (formik.values.paymentMode === "Skip Payment" && parentDetails?.accountType === "Postpaid") {
                //     setPaymentScreenDetails(formik.values);
                //     if (paymentDone) {
                //         setPaymentScreenActive(false);
                //         setPreviewScreenActive(true);
                //     } else {
                //         setPaymentDone(true);
                //         setIsLoading(true);
                //         let planCharges = 0;
                //         let planName = "";
                //         for (let i = 0; i < plan?.length; i++) {
                //             if (formik.values.plan === plan[i]?._id) {
                //                 planName = plan[i]?.name;
                //                 planCharges = plan[i]?.price;
                //             }
                //         }
                //         const dataToSend = {
                //             invoiceType: "Sign Up",
                //             customerId: formik.values.customerid,
                //             planId: formik.values.plan,
                //             planCharges: planCharges,
                //             additionalCharges: formik.values.additional,
                //             discount: formik.values.discount,
                //             totalAmount: formik.values.totalamount,
                //             amountPaid: "0",
                //             invoiceDueDate: currentBillingConfig?.dueDate,
                //             billingPeriod: {
                //                 from: "onActivation",
                //                 to: "onActivation",
                //             },
                //             invoiceStatus: "Pending",
                //             paymentMethod: "Skip Payment",
                //             invoiceOneTimeCharges: currentBillingConfig?.oneTimeCharge,
                //             lateFee: currentBillingConfig?.applyLateFee,
                //             planName: planName,
                //             chargingType: "Monthly",
                //             printSetting: "Both",
                //             productName: "",
                //             selectProduct: formik.values.billId,
                //         };
                //         try {
                //             const response = await api.post(`/api/user/postpaidpaymentDetails`, dataToSend);
                //             if (response?.status === 201 || response?.status === 200) {
                //                 setDataSendForInvoice(dataToSend);
                //                 setAllPaymentInfo(response?.data);

                //                 setIsLoading(false);
                //                 setPaymentScreenActive(false);
                //                 setPreviewScreenActive(true);
                //             }
                //         } catch (error) {
                //             toast.current.show({ severity: "error", summary: "Payment Details", detail: error?.response?.data?.msg });

                //             setIsLoading(false);
                //         }
                //     }
                // } else
                if ((formik.values.paymentMode === "Skip Payment" && parentDetails?.accountType === "Prepaid") || (formik.values.paymentMode === "Skip Payment" && parentDetails?.accountType === "Postpaid")) {
                    setPaymentScreenDetails(formik.values);
                    if (paymentDone) {
                        setPaymentScreenActive(false);
                        setPreviewScreenActive(true);
                    } else {
                        let object = formik.values;
                        let checkLabel;
                        try {
                            const storedItem = localStorage.getItem("checked");
                            checkLabel = storedItem ? JSON.parse(storedItem) : false;
                        } catch (error) {
                            console.error("Error parsing checkLabel from localStorage", error);
                            checkLabel = false;
                        }

                        const shipmentName = localStorage.getItem("shipmentName");
                        const shipmentCost = localStorage.getItem("shipmentCost");
                        let additionalFeature = [];
                        let discounts = [];
                        setPaymentDone(true);
                        let dueDate = "";
                        let applyLateFee = "";
                        let oneTimeCharge = "";
                        let planName = "";
                        let planId = "";
                        let planCharges = "";
                        let selectedDiscounts = formik.values.discount;
                        dueDate = currentBillingConfig?.dueDate;
                        oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                        applyLateFee = currentBillingConfig?.applyLateFee;
                        for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                            for (let k = 0; k < selectedDiscounts?.length; k++) {
                                if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                    let obj = {
                                        name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                        amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                    };
                                    discounts.push(obj);
                                }
                            }
                        }
                        for (let i = 0; i < plan?.length; i++) {
                            if (object.plan === plan[i]?._id) {
                                planName = plan[i]?.name;
                                planCharges = plan[i]?.price;

                                planId = plan[i]?._id;
                            }
                        }

                        let additionalFeatures = formik.values.additional;
                        for (let k = 0; k < additionalFeatures?.length; k++) {
                            for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                    let obj = {
                                        name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                        amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                    };
                                    additionalFeature.push(obj);
                                }
                            }
                        }

                        const loginRes = localStorage.getItem("userData");
                        const parseLoginRes = JSON.parse(loginRes);

                        let dataToSend = {
                            isAutopay: false,
                            customerId: object.customerid,
                            invoiceType: "Sign Up",
                            totalAmount: object.totalamount,
                            additionalCharges: additionalFeature,
                            discount: discounts,
                            amountPaid: "0",
                            selectProduct: object?.billId,
                            invoiceDueDate: dueDate,
                            lateFee: applyLateFee,
                            invoiceOneTimeCharges: oneTimeCharge,
                            invoiceStatus: "Unpaid",
                            planId: formik.values.plan,
                            planName: planName,
                            planCharges: planCharges,
                            chargingType: "monthly",
                            invoicePaymentMethod: "skip",
                            printSetting: "Both",
                            isInvoice: true,
                            isWithInvoice: true,
                            userId: parseLoginRes?._id,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };

                        setDataSendForInvoice(dataToSend);

                        api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                            .then((response) => {
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            })
                            .catch((err) => {});
                    }
                } else if (formik.values.paymentMode === "Credit Card (Authorize)" || formik.values.paymentMode === "Credit Card" || formik.values.paymentMode === "E-Check (Authorize)") {
                    setPaymentScreenDetails(formik.values);
                    if (paymentDone) {
                        setPaymentScreenActive(false);
                        setPreviewScreenActive(true);
                    } else {
                        if (formik.values.paymentMode === "E-Check (Authorize)") {
                            setDialogForEcheck(true);
                        } else if (formik.values.paymentMode == "Credit Card (Authorize)") {
                            setdialogForCardAuth(true);
                        } else if (formik.values.paymentMode == "Credit Card" && parseLoginRes?.companyName?.toUpperCase()?.includes("TALKDAILY")) {
                            setPaymentDialogVisibility(true);
                        } else if (formik.values.paymentMode == "Credit Card") {
                            if (formik.values.paid !== "") {
                                setPaymentDialogVisibility(true);
                            } else {
                                setPaidAmountRequired(true);
                            }
                        } else {
                            setdialogForCardAuth(true);
                        }
                    }
                } else {
                    setpaymentmethoderror(true);
                }
            }
        },
    });

    useEffect(() => {
        formik.setFieldValue("totalamount", parseFloat(formik.values.totalamount)?.toFixed(2));
        setAmount(parseFloat(formik.values.totalamount)?.toFixed(2));
    }, [formik.values.totalamount]);
    useEffect(() => {
        if (carrierVal !== "") {
            const getServices = async () => {
                const response = await api.get(`/api/web/shipment/carrier/listServices?carrierCode=${carrierVal}`);
                const data = response?.data?.data;
                const filteredServices = data.filter((item) => item.domestic && !item.international && (item?.code?.includes("usps") || item?.code?.includes("USPS") || item?.name?.includes("usps") || item?.name?.includes("USPS")));
                setServices((prev) => {
                    return [...prev, ...filteredServices];
                });
            };
            if (carrierVal !== undefined) {
                getServices();
            }
        }
    }, [carrierVal]);

    useEffect(() => {
        const delivery = async () => {
            const response = await api.get(`/api/web/shipment/carrier/getDeliveryConfirmation`);
            const data = response?.data;
            setDelivery(data);
        };
        delivery();
        const getCarrier = async () => {
            const response = await api.get(`/api/web/shipment/carrier/carriersList`);
            const data = response?.data?.data;

            setCarrier(data);
        };
        getCarrier();
    }, []);

    useEffect(() => {
        if (currentScreen !== 3) return;
        if (carrierVal !== "" && servicesVal !== "" && deliveryVal !== "") {
            setRatesLoading(true);
            const dataToSend = {
                serviceprovider: parentDetails?.serviceProvider,
                carrierCode: carrierVal,
                serviceCode: servicesVal,
                //  toPostalCode: parentDetails?.zip,
                toPostalCode: parentDetails?.isSameServiceAddress ? parentDetails?.zip : parentDetails?.isNotSameServiceAddress ? parentDetails?.mailingZip : parentDetails?.isPoBoxAddress ? parentDetails?.poBoxZip : parentDetails?.zip,
                toState: parentDetails?.isSameServiceAddress ? parentDetails?.state?.toUpperCase() : parentDetails?.isNotSameServiceAddress ? parentDetails?.mailingState?.toUpperCase() : parentDetails?.isPoBoxAddress ? parentDetails?.poBoxState?.toUpperCase() : parentDetails?.state?.toUpperCase(),
                toCity: parentDetails?.isSameServiceAddress ? parentDetails?.city?.toUpperCase() : parentDetails?.isNotSameServiceAddress ? parentDetails?.mailingCity?.toUpperCase() : parentDetails?.isPoBoxAddress ? parentDetails?.poBoxCity?.toUpperCase() : parentDetails?.city?.toUpperCase(),
                toCountry: "US",
                // toState: parentDetails?.state,
                // toCity: parentDetails?.city,
                packageCode: "",
                inventoryType: current,
                confirmation: deliveryVal,
                inventoryType: inventoryIdSelected,
                residential: "true",
            };

            const getRates = async () => {
                if (checkLabelVal) {
                    try {
                        if (servicesVal === "usps_first_class_mail" && deliveryVal === "adult_signature") {
                            toast.current.show({ severity: "error", summary: "Shipment Cost", detail: "Adult signature is not valid for First Class Mail" });
                            setRates([]);
                            setRatesLoading(false);
                        } else {
                            const response = await api.post(`/api/web/shipment/getRates`, dataToSend);
                            if (response?.data?.msg && response?.data?.data === undefined) {
                                toast.current.show({ severity: "error", summary: "Shipment Cost", detail: response?.data?.msg });
                                setExpectedCost(false);
                                setRatesLoading(false);
                                return;
                            }
                            const data = response?.data?.data;

                            if (Array.isArray(data) && data?.length > 0) {
                                setRates(data);
                                setRatesLoading(false);
                                setExpectedCost(true);
                                localStorage.setItem("checked", false);
                            } else {
                                setRatesLoading(false);
                            }
                        }
                    } catch (error) {
                        toast.current.show({ severity: "error", summary: "Rates", detail: "Rates Not Found" });
                    }
                }
            };

            getRates();
        } else {
        }
    }, [carrierVal && servicesVal, deliveryVal, current, currentScreen === 3]);
    useEffect(() => {
        //
        api.get(`/api/inventoryType/getInventorybymodel?billingModel=66fc0e96fcd60c4a4acedc8d&serviceProvider=65142a7ed74a5a9ef93ba53b`);

        api.get(`/api/inventoryType/getInventorybymodel?billingModel=${billingModelSelected}&serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                let arrayofbillingmodel = [];
                for (let i = 0; i < res?.data.data?.length; i++) {
                    let obj = {};
                    obj.label = res?.data?.data[i]?.inventoryType;
                    obj.value = res?.data?.data[i]?.inventoryType;
                    obj.image = res?.data?.data[i]?.image;
                    arrayofbillingmodel.push(obj);
                }

                setInventory(arrayofbillingmodel);
                setLoader(false);
            })
            .catch((err) => {});
    }, []);
    useEffect(() => {
        if (current !== "") {
            api.get(`/api/user/BillingConfiguration?inventoryType=${inventoryTypeSelected}&make=${current?.substring(0, current.indexOf("-") - 1)}&model=${current?.substring(current.lastIndexOf("-") + 2)}&billingmodel=${currentBillingModel}&ServiceProvider=${parseLoginRes?.company}`)
                .then((res1) => {
                    setPlans(res1?.data);
                    setCurrentAllPlans(res1?.data);

                    api.get(`/api/user/getBillingConfig?inventoryType=${inventoryTypeSelected}&make=${current?.substring(0, current.indexOf("-") - 1)}&model=${current?.substring(current.lastIndexOf("-") + 2)}&billingModel=${currentBillingModel}&ServiceProvider=${parseLoginRes?.company}`)
                        .then((res) => {
                            setCurrentBillingConfig(res?.data);
                            setCurrentAllBillingConfig(res?.data);
                            formik.setFieldValue("billId", res?.data?._id);
                            formik.setFieldValue("totalamount", res?.data?.oneTimeCharge);
                            formik.values.totalamount = res?.data?.oneTimeCharge;

                            if (!additionalsDiscounts.setted) {
                                let arrayofadditional = [];
                                let totalAdditionalPrice = 0;
                                for (let i = 0; i < res?.data?.additionalFeature.length; i++) {
                                    arrayofadditional.push(res?.data?.additionalFeature[i]._id);
                                    totalAdditionalPrice += parseFloat(res?.data?.additionalFeature[i].featureAmount);
                                }
                                let totaldiscountprice = 0;
                                let arrayofdiscounts = [];
                                for (let i = 0; i < res?.data?.selectdiscount.length; i++) {
                                    arrayofdiscounts.push(res?.data?.selectdiscount[i]._id);
                                    totaldiscountprice += parseFloat(res?.data?.selectdiscount[i].amount);
                                }
                                let calclatedAmount = (totalAdditionalPrice + parseFloat(res?.data?.oneTimeCharge) - totaldiscountprice).toFixed(2);
                                formik.setFieldValue("totalamount", calclatedAmount);
                                formik.values.totalamount = calclatedAmount;
                                formik.setFieldValue("additional", arrayofadditional);
                                formik.setFieldValue("discount", arrayofdiscounts);
                                setAdditionalDiscounts((prev) => ({ setted: true, additional: arrayofadditional, discounts: arrayofdiscounts }));
                                setAdditional(res?.data?.additionalFeature);
                                setDiscount(res?.data?.selectdiscount);
                            } else {
                                let totalAdditionalPrice = 0;

                                for (let i = 0; i < res?.data?.additionalFeature.length; i++) {
                                    for (let k = 0; k < additionalsDiscounts?.additional.length; k++) {
                                        if (additionalsDiscounts?.additional[k] === res?.data?.additionalFeature[i]._id) {
                                            totalAdditionalPrice += parseFloat(res?.data?.additionalFeature[i].featureAmount);
                                        }
                                    }
                                }
                                let totaldiscountprice = 0;
                                for (let i = 0; i < res?.data?.selectdiscount.length; i++) {
                                    for (let k = 0; k < additionalsDiscounts?.discounts?.length; k++) {
                                        if (additionalsDiscounts?.discounts[k] === res?.data?.selectdiscount[i]._id) {
                                            totaldiscountprice += parseFloat(res?.data?.selectdiscount[i].amount);
                                        }
                                    }
                                }
                                let calclatedAmount = (totalAdditionalPrice + parseFloat(res?.data?.oneTimeCharge) - totaldiscountprice).toFixed(2);
                                formik.setFieldValue("totalamount", calclatedAmount);
                                formik.values.totalamount = calclatedAmount;
                                formik.setFieldValue("additional", additionalsDiscounts.additional);
                                formik.setFieldValue("discount", additionalsDiscounts.discounts);
                                // setAdditionalDiscounts(prev=>({setted:true,additional:arrayofadditional,discount:arrayofdiscounts}))
                                setAdditional(res?.data?.additionalFeature);
                                setDiscount(res?.data?.selectdiscount);
                            }

                            let paymentobj = [];
                            for (let i = 0; i < res?.data?.paymentMethod?.length; i++) {
                                let obj = { name: res?.data?.paymentMethod[i] };
                                paymentobj.push(obj);
                            }
                            setPaymentMethods(paymentobj);
                            if (currentPlanSelect) {
                                let total = parseFloat(formik.values.totalamount);
                                let planPresent = false;
                                for (let i = 0; i < res1?.data?.length; i++) {
                                    if (currentPlanSelect === res1?.data[i]?._id) {
                                        formik.setFieldValue("totalamount", (total + res1?.data[i]?.price).toFixed(2));
                                        //onPlanSelect(item);
                                        setPreviousPlanPrice(res1?.data[i]?.price);

                                        formik.setFieldValue("plan", res1?.data[i]?._id);
                                        setCurrentPlanName(res1?.data[i]?.name);
                                        setCurrentPlanSelect(res1?.data[i]?._id);
                                        formik.setFieldValue("currentPlanSelected", res1?.data[i]?._id);
                                    }
                                }
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => {});
        }
    }, [current]);

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (!checkLabelVal) {
            setCarrierVal("");
            setServicesVal("");
            setDeliveryVal("");
            setChecked("");
            localStorage.removeItem("shipmentName");
            localStorage.removeItem("shipmentCost");
            localStorage.removeItem("checked");
        }
        const dataToSend = {
            customerId: initiationData?._id,
            carrierVal: !checkLabelVal ? "" : carrierVal,
            servicesVal: !checkLabelVal ? "" : servicesVal,
            deliveryVal: !checkLabelVal ? "" : deliveryVal,
            labelSubmissionval: checkLabelVal,
            shippingLabelDiscount: checked,
        };

        api.post(`/api/user/storeServices`, dataToSend)
            .then((res) => {})
            .catch((err) => {});
    }, [carrierVal, servicesVal, deliveryVal, checkLabelVal, checked]);
    useEffect(() => {
        let cost = 0;
        rates.map((item) => {
            if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                cost = item?.shipmentCost + item?.otherCost;
            }
        });
        let currentamount = parseFloat(formik.values.totalamount);
        currentamount -= previousRate;
        currentamount += cost;
        formik.setFieldValue("totalamount", currentamount);
        setPreviousRate(cost);
    }, [checked, rates]);
    const [makeModelAgainstInventory, setMakeModelAgainstInventory] = useState([]);
    useEffect(() => {
        if (inventoryTypeSelected !== "") {
            setMakeModelAgainstInventory([]);
            setLoader(true);

            api.get(`/api/inventoryType/getInvetotyByModel?serviceProvider=${parseLoginRes?.company}&inventoryType=${inventoryTypeSelected}&billing=${currentBillingModel}`)
                .then((res) => {
                    setMakeModelAgainstInventory(res?.data?.data);
                    setInventoryData(res?.data?.data);
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
        }
    }, [inventoryTypeSelected]);
    const toggleViewAll = (planId) => {
        setExpandedPlans((prev) => {
            if (prev.includes(planId)) {
                return prev.filter((id) => id !== planId);
            } else {
                return [...prev, planId];
            }
        });
    };
    useEffect(() => {
        if (plan && plan.length > 0) {
            const tmb = plan.filter((plan) => plan.carrier?.name === "TMB");
            setTmbPlans(tmb);
            const att = plan.filter((plan) => plan.carrier?.name === "AT&T");
            setAttPlans(att);
        }
    }, [plan]);
    useEffect(() => {
        if (formik.values.plan) {
            const planId = String(formik.values.plan).trim();
            const isAttPlan = attPlans.some((attPlan) => String(attPlan._id) === planId);
            const isTmbPlan = tmbPlans.some((tmbPlan) => String(tmbPlan._id) === planId);
            if (isAttPlan) {
                setAttPlanSelect(true);
                setTmbPlanSelect(false);
            } else if (isTmbPlan) {
                setAttPlanSelect(false);
                setTmbPlanSelect(true);
            } else {
                setAttPlanSelect(true);
                setTmbPlanSelect(false);
            }
        }
    }, [formik.values.plan, attPlans, tmbPlans]);

    return (
        <div>
            <div className="paymentmain">
                <ToastContainer />
                <div className=" flex flex-wrap flex-row justify-content-around ">
                    {currentScreen === 1 ? (
                        <div className="w-full flex flex-wrap flex-row justify-content-start">
                            <div className="productheader">
                                <h1 className="flex flex-wrap justify-content-start heading w-full heading">Product</h1>
                                <p className="para" style={{ marginTop: "1.2rem" }}>
                                    Select the products you wish to enroll for, including SIMs, wireless devices, or bundled packages like cell phones with SIMs or tablets.
                                </p>
                                <div className="w-full flex flex-wrap flex-row justify-content-start calendar_field" style={{ marginTop: "2.4rem" }}>
                                    <label className="flex w-full">Select Products</label>
                                    <Dropdown
                                        style={{ marginTop: ".2rem" }}
                                        placeholder="Select Product"
                                        options={inventory}
                                        disabled={paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                        optionLabel="label"
                                        value={inventoryTypeSelected}
                                        className={inventoryTypeSelected ? "dropdownactive" : "dropdown"}
                                        onChange={(e) => {
                                            setInventoryTypeSelected(e.value);
                                            setCurrentSelect("");
                                        }}
                                        optionValue="label"
                                    />
                                </div>
                            </div>
                            <div className="flex" style={{ gap: "1.6rem" }}>
                                {currentScreen === 1 && !loader && makeModelAgainstInventory?.length > 0 ? (
                                    makeModelAgainstInventory?.map((item) => {
                                        return (
                                            <div className="inventorymainpayment" style={{ marginTop: "2.4rem" }}>
                                                <div
                                                    onClick={() => {
                                                        if (paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)) {
                                                        } else {
                                                            setInventoryIdSelected(item?._id);
                                                            formik.setFieldValue("inventorySelected", item?.make + " - " + item?.model);
                                                            formik.setFieldValue("plan", "");
                                                            setCurrentPlanName("");
                                                            setCurrentPlanSelect("");
                                                            formik.setFieldValue("currentPlanSelected", "");
                                                            formik.setFieldValue("additional", []);
                                                            formik.setFieldValue("discount", []);
                                                            setAdditionalDiscounts((prev) => ({ discounts: [], additional: [], setted: false }));
                                                            formik.setFieldValue("totalamount", "");
                                                            setPreviousPlanPrice(0);
                                                            formik.setFieldValue("plan", "");
                                                            setCurrentSelect(item?.make + " - " + item?.model);
                                                        }
                                                    }}
                                                    className={item?.make + " - " + item?.model === current ? "inventoryouterselected flex flex-column justify-content-evenly align-items-center " : "inventoryy flex flex-column justify-content-evenly align-items-center "}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <div className="flex " style={{ gap: "1.2rem" }}>
                                                        <div className={item?.label === current || (item?.make + " - " + item?.model === current) === current || paymentDone ? "inventoryselected" : "inventoryinner"} style={{ borderRadius: "1.15rem" }}>
                                                            <div className="flex flex-wrap w-full flex-row justify-content-center imagediv ">
                                                                {item?.label === current || (item?.make + " - " + item?.model === current) === current || paymentDone ? (
                                                                    <img
                                                                        onError={(e) => {
                                                                            e.target.src = "./placeholderimage.jpg";
                                                                        }}
                                                                        className="inventoryimage"
                                                                        src={`${BASE_URL}/inventory/${item?.image}`}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        onError={(e) => {
                                                                            e.target.src = "./placeholderimage.jpg";
                                                                        }}
                                                                        className="inventoryimage"
                                                                        src={`${BASE_URL}/inventory/${item?.image}`}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <h1 className="flex  flex-col justify-content-center selectedinventoryname">{capitalizeSentence(item?.make + " - " + item?.model)}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : loader ? (
                                    // <div className="w-full">
                                    //     <div style={{ marginTop: "40.0rem", height: "19.4rem" }} className="mt-4 flex justify-content-center">
                                    //         <CommonLoaderBlue customSize={16} />
                                    //     </div>
                                    // </div>
                                    ""
                                ) : inventoryTypeSelected !== "" ? (
                                    <p className="mt-4 p-error">Billing Configuration is not setup for this Inventory Type. Admin can complete this in the setting module. </p>
                                ) : undefined}
                            </div>
                        </div>
                    ) : currentScreen === 1 ? (
                        <div>
                            <div style={{ marginTop: "20.0rem" }} className="mt-4 flex justify-content-center">
                                <CommonLoaderBlue />
                            </div>
                        </div>
                    ) : undefined}
                    {currentScreen === 2 ? (
                        <div className="flex flex-column w-full justify-content-start billinginfo ">
                            <div className="billinginfoheader">
                                <h1 className="flex flex-wrap justify-content-start heading  w-full">Subscription</h1>
                                <p className="flex w-full para" style={{ marginTop: "1.2rem" }}>
                                    Select a subscription plan that suits your client's needs. Review the options to find the best features and benefits.
                                </p>
                            </div>
                            <div className="plantypes">
                                {!companyName.includes("Magnet") && (
                                    <div
                                        className="flex flex-column justify-content-center align-items-center attplandiv"
                                        style={{ borderBottom: attPlanSelect ? "1px solid #475CED" : "1px solid #CDCFE5" }}
                                        onClick={() => {
                                            if (!companyName.includes("Magnet")) {
                                                setAttPlanSelect(true);
                                                setTmbPlanSelect(false);
                                            } else {
                                            }
                                        }}
                                    >
                                        <label className={attPlanSelect ? "plantypeselect " : "plantype"}>AT&T</label>
                                    </div>
                                )}
                                <div
                                    className="flex flex-column justify-content-center align-items-center tmbplandiv"
                                    style={{ borderBottom: tmbPlanSelect ? "1px solid #475CED" : "1px solid #CDCFE5" }}
                                    onClick={() => {
                                        setAttPlanSelect(false);
                                        setTmbPlanSelect(true);
                                    }}
                                >
                                    <label className={tmbPlanSelect ? "plantypeselect" : "plantype"}>T-Mobile</label>
                                </div>
                            </div>
                            <div className="flex w-full justify-content-between flex-wrap plancontainer" style={{ marginTop: "2.4rem", height: "51rem", overflow: "auto" }}>
                                {attPlanSelect && attPlans.length > 0 ? (
                                    <div className="flex w-full" style={{ gap: "1.2rem" }}>
                                        {/* Left Plans */}
                                        <div className="leftplans">
                                            {attPlans
                                                .filter((_, index) => index % 2 === 0) // Filter odd-indexed plans (0, 2, 4,...)
                                                .map((item) => (
                                                    <div
                                                        key={item._id}
                                                        className={`${item._id === currentPlanSelect ? "planouterselected" : "planouter"}`}
                                                        style={{
                                                            height: expandedPlans.includes(item._id) ? "auto" : "auto",
                                                        }}
                                                    >
                                                        <div style={{ borderRadius: "1.27rem" }} className={item._id === currentPlanSelect || paymentDone ? "planselected" : "planinner"}>
                                                            {/* Plan Content */}
                                                            <div className="planname">
                                                                <div className="name" style={{ backgroundColor: "#009DD7 " }}>
                                                                    <h1>{item?.name?.toUpperCase()}</h1>
                                                                </div>
                                                                <div className="paragraph w-full justify-content-start">
                                                                    <p>{item?.description?.charAt(0).toUpperCase() + item?.description?.slice(1).toLowerCase()}</p>
                                                                </div>
                                                            </div>
                                                            {/* Plan Data */}

                                                            <div className="plandata">
                                                                {expandedPlans.includes(item._id) ? (
                                                                    // <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                    //     <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Voice Allowance {item?.voiceAllowance} <span> {item.voiceAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Data Allowance {item?.dataAllowance} <span> {item.dataAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Text Allowance {item?.textAllowance} <span> {item.textAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     {item?.planFeature.map((featureItem, index) => {
                                                                    //         return featureItem?.isSelected ? (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Checked />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         ) : (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Falsebtn />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         );
                                                                    //     })}
                                                                    //     <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                    //         View Less
                                                                    //     </p>
                                                                    // </div>
                                                                    <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                        <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Voice Allowance{" "}
                                                                                {item?.voiceAllowance
                                                                                    ?.split(" ")
                                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                    .join(" ")}{" "}
                                                                                <span>
                                                                                    {item?.voiceAllowanceUnit
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Data Allowance{" "}
                                                                                {item?.dataAllowance
                                                                                    ?.split(" ")
                                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                    .join(" ")}{" "}
                                                                                <span>
                                                                                    {item?.dataAllowanceUnit
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Text Allowance{" "}
                                                                                {item?.textAllowance
                                                                                    ?.split(" ")
                                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                    .join(" ")}{" "}
                                                                                <span>
                                                                                    {item?.textAllowanceUnit
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                        {item?.planFeature.map((featureItem, index) => (
                                                                            <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                                {featureItem?.isSelected ? <Checked /> : <Falsebtn />}
                                                                                <span className="ml-2 allowancespan">
                                                                                    {featureItem?.name
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </p>
                                                                        ))}
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            View Less
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="seemore">
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            See More
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* Plan Price */}
                                                            <div className="planprice">
                                                                <div className="flex flex-row align-items-center w-full amountval">
                                                                    <span className="price">{item?.price}</span>
                                                                    <span className="month"> &nbsp; /per month</span>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className="planbtn"
                                                                        style={{ width: "8.8rem", height: "4.2rem" }}
                                                                        label="Buy"
                                                                        onClick={() => {
                                                                            if (paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)) {
                                                                            } else {
                                                                                let total = parseFloat(formik.values.totalamount);
                                                                                if (previousplanprice !== 0) {
                                                                                    total = total - previousplanprice;
                                                                                }
                                                                                formik.setFieldValue("totalamount", (total + item?.price).toFixed(2));
                                                                                //onPlanSelect(item);
                                                                                setPreviousPlanPrice(item?.price);
                                                                                formik.setFieldValue("plan", item?._id);
                                                                                setCurrentPlanName(item?.name);
                                                                                setCurrentPlanSelect(item?._id);
                                                                                formik.setFieldValue("currentPlanSelected", item?._id);

                                                                                api.post(`/api/user/plan`, { csr: parseLoginRes?._id, customerId: initiationData._id, plan: item?._id, inventoryId: inventoryIdSelected, carrier: item?.carrier?._id })
                                                                                    .then(() => {})
                                                                                    .catch((err) => {});
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>

                                        {/* Right Plans */}
                                        <div className="rightplans">
                                            {attPlans
                                                .filter((_, index) => index % 2 !== 0) // Filter even-indexed plans (1, 3, 5,...)
                                                .map((item) => (
                                                    <div
                                                        key={item._id}
                                                        className={`${item._id === currentPlanSelect ? "planouterselected" : "planouter"}`}
                                                        style={{
                                                            height: expandedPlans.includes(item._id) ? "auto" : "auto",
                                                        }}
                                                    >
                                                        <div style={{ borderRadius: "1.27rem" }} className={item._id === currentPlanSelect || paymentDone ? "planselected" : "planinner"}>
                                                            {/* Plan Content */}
                                                            <div className="planname">
                                                                <div className="name" style={{ backgroundColor: "#009DD7 " }}>
                                                                    <h1>{item?.name?.toUpperCase()}</h1>
                                                                </div>
                                                                <div className="paragraph w-full justify-content-start">
                                                                    <p>{item?.description?.charAt(0).toUpperCase() + item?.description?.slice(1).toLowerCase()}</p>
                                                                </div>
                                                            </div>
                                                            {/* Plan Data */}

                                                            <div className="plandata">
                                                                {expandedPlans.includes(item._id) ? (
                                                                    // <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                    //     <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Voice Allowance {item?.voiceAllowance} <span> {item.voiceAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Data Allowance {item?.dataAllowance} <span> {item.dataAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Text Allowance {item?.textAllowance} <span> {item.textAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     {item?.planFeature.map((featureItem, index) => {
                                                                    //         return featureItem?.isSelected ? (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Checked />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         ) : (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Falsebtn />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         );
                                                                    //     })}
                                                                    //     <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                    //         View Less
                                                                    //     </p>
                                                                    // </div>
                                                                    <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                        <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Voice Allowance{" "}
                                                                                {item?.voiceAllowance
                                                                                    ?.split(" ")
                                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                    .join(" ")}{" "}
                                                                                <span>
                                                                                    {item?.voiceAllowanceUnit
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Data Allowance{" "}
                                                                                {item?.dataAllowance
                                                                                    ?.split(" ")
                                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                    .join(" ")}{" "}
                                                                                <span>
                                                                                    {item?.dataAllowanceUnit
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Text Allowance{" "}
                                                                                {item?.textAllowance
                                                                                    ?.split(" ")
                                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                    .join(" ")}{" "}
                                                                                <span>
                                                                                    {item?.textAllowanceUnit
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                        {item?.planFeature.map((featureItem, index) => (
                                                                            <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                                {featureItem?.isSelected ? <Checked /> : <Falsebtn />}
                                                                                <span className="ml-2 allowancespan">
                                                                                    {featureItem?.name
                                                                                        ?.split(" ")
                                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                                        .join(" ")}
                                                                                </span>
                                                                            </p>
                                                                        ))}
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            View Less
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="seemore">
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            See More
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* Plan Price */}
                                                            <div className="planprice">
                                                                <div className="flex flex-row align-items-center w-full amountval">
                                                                    <span className="price">{item?.price}</span>
                                                                    <span className="month"> &nbsp; /per month</span>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className="planbtn"
                                                                        style={{ width: "8.8rem", height: "4.2rem" }}
                                                                        label="Buy"
                                                                        onClick={() => {
                                                                            if (paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)) {
                                                                            } else {
                                                                                let total = parseFloat(formik.values.totalamount);
                                                                                if (previousplanprice !== 0) {
                                                                                    total = total - previousplanprice;
                                                                                }
                                                                                formik.setFieldValue("totalamount", (total + item?.price).toFixed(2));
                                                                                //onPlanSelect(item);
                                                                                setPreviousPlanPrice(item?.price);
                                                                                formik.setFieldValue("plan", item?._id);
                                                                                setCurrentPlanName(item?.name);
                                                                                setCurrentPlanSelect(item?._id);
                                                                                formik.setFieldValue("currentPlanSelected", item?._id);

                                                                                api.post(`/api/user/plan`, { csr: parseLoginRes?._id, customerId: initiationData._id, plan: item?._id, inventoryId: inventoryIdSelected, carrier: item?.carrier?._id })
                                                                                    .then(() => {})
                                                                                    .catch((err) => {});
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ) : tmbPlanSelect && tmbPlans.length > 0 ? (
                                    <div className="flex w-full" style={{ gap: "1.2rem" }}>
                                        {/* Left Plans */}
                                        <div className="leftplans">
                                            {tmbPlans
                                                .filter((_, index) => index % 2 === 0) // Filter odd-indexed plans (0, 2, 4,...)
                                                .map((item) => (
                                                    <div
                                                        key={item._id}
                                                        className={`${item._id === currentPlanSelect ? "planouterselected" : "planouter"}`}
                                                        style={{
                                                            height: expandedPlans.includes(item._id) ? "auto" : "auto",
                                                        }}
                                                    >
                                                        <div style={{ borderRadius: "1.27rem" }} className={item._id === currentPlanSelect || paymentDone ? "planselected" : "planinner"}>
                                                            {/* Plan Content */}
                                                            <div className="planname">
                                                                <div className="name" style={{ backgroundColor: "#e20074" }}>
                                                                    <h1>{item?.name?.toUpperCase()}</h1>
                                                                </div>
                                                                <div className="paragraph w-full justify-content-start">
                                                                    <p>{item?.description?.charAt(0).toUpperCase() + item?.description?.slice(1).toLowerCase()}</p>
                                                                </div>
                                                            </div>
                                                            {/* Plan Data */}

                                                            <div className="plandata">
                                                                {expandedPlans.includes(item._id) ? (
                                                                    // <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                    //     <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Voice Allowance {item?.voiceAllowance} <span> {item.voiceAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Data Allowance {item?.dataAllowance} <span> {item.dataAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Text Allowance {item?.textAllowance} <span> {item.textAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     {item?.planFeature.map((featureItem, index) => {
                                                                    //         return featureItem?.isSelected ? (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Checked />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         ) : (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Falsebtn />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         );
                                                                    //     })}
                                                                    //     <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                    //         View Less
                                                                    //     </p>
                                                                    // </div>
                                                                    <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                        <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Voice Allowance {item?.voiceAllowance?.toString().replace(/\b\w/g, (char) => char.toUpperCase())} <span>{item?.voiceAllowanceUnit?.toString().replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Data Allowance {item?.dataAllowance?.toString().replace(/\b\w/g, (char) => char.toUpperCase())} <span>{item?.dataAllowanceUnit?.toString().replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Text Allowance {item?.textAllowance?.toString().replace(/\b\w/g, (char) => char.toUpperCase())} <span>{item?.textAllowanceUnit?.toString().replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                                                                            </span>
                                                                        </p>
                                                                        {item?.planFeature.map((featureItem, index) => (
                                                                            <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                                {featureItem?.isSelected ? <Checked /> : <Falsebtn />}
                                                                                <span className="ml-2 allowancespan">{featureItem?.name?.replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                                                                            </p>
                                                                        ))}
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            View Less
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="seemore">
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            See More
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* Plan Price */}
                                                            <div className="planprice">
                                                                <div className="flex flex-row align-items-center w-full amountval">
                                                                    <span className="price">{item?.price}</span>
                                                                    <span className="month"> &nbsp; /per month</span>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className="planbtn"
                                                                        style={{ width: "8.8rem", height: "4.2rem" }}
                                                                        label="Buy"
                                                                        onClick={() => {
                                                                            if (paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)) {
                                                                            } else {
                                                                                let total = parseFloat(formik.values.totalamount);
                                                                                if (previousplanprice !== 0) {
                                                                                    total = total - previousplanprice;
                                                                                }
                                                                                formik.setFieldValue("totalamount", (total + item?.price).toFixed(2));
                                                                                //onPlanSelect(item);
                                                                                setPreviousPlanPrice(item?.price);
                                                                                formik.setFieldValue("plan", item?._id);
                                                                                setCurrentPlanName(item?.name);
                                                                                setCurrentPlanSelect(item?._id);
                                                                                formik.setFieldValue("currentPlanSelected", item?._id);

                                                                                api.post(`/api/user/plan`, { csr: parseLoginRes?._id, customerId: initiationData._id, plan: item?._id, inventoryId: inventoryIdSelected, carrier: item?.carrier?._id })
                                                                                    .then(() => {})
                                                                                    .catch((err) => {});
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>

                                        {/* Right Plans */}
                                        <div className="rightplans">
                                            {tmbPlans
                                                .filter((_, index) => index % 2 !== 0) // Filter even-indexed plans (1, 3, 5,...)
                                                .map((item) => (
                                                    <div
                                                        key={item._id}
                                                        className={`${item._id === currentPlanSelect ? "planouterselected" : "planouter"}`}
                                                        style={{
                                                            height: expandedPlans.includes(item._id) ? "auto" : "auto",
                                                        }}
                                                    >
                                                        <div style={{ borderRadius: "1.27rem" }} className={item._id === currentPlanSelect || paymentDone ? "planselected" : "planinner"}>
                                                            {/* Plan Content */}
                                                            <div className="planname">
                                                                <div className="name" style={{ backgroundColor: "#e20074" }}>
                                                                    <h1>{item?.name?.toUpperCase()}</h1>
                                                                </div>
                                                                <div className="paragraph w-full justify-content-start">
                                                                    <p>{item?.description?.charAt(0).toUpperCase() + item?.description?.slice(1).toLowerCase()}</p>
                                                                </div>
                                                            </div>
                                                            {/* Plan Data */}

                                                            <div className="plandata">
                                                                {expandedPlans.includes(item._id) ? (
                                                                    // <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                    //     <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Voice Allowance {item?.voiceAllowance} <span> {item.voiceAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Data Allowance {item?.dataAllowance} <span> {item.dataAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //         <Checked />
                                                                    //         <span className="ml-2 allowancespan">
                                                                    //             Text Allowance {item?.textAllowance} <span> {item.textAllowanceUnit}</span>
                                                                    //         </span>
                                                                    //     </p>
                                                                    //     {item?.planFeature.map((featureItem, index) => {
                                                                    //         return featureItem?.isSelected ? (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Checked />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         ) : (
                                                                    //             <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                    //                 <Falsebtn />
                                                                    //                 <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                    //             </p>
                                                                    //         );
                                                                    //     })}
                                                                    //     <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                    //         View Less
                                                                    //     </p>
                                                                    // </div>
                                                                    <div className="flex flex-column" style={{ marginTop: "1.2rem" }}>
                                                                        <p className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Voice Allowance {item?.voiceAllowance} <span>{item?.voiceAllowanceUnit?.toUpperCase()}</span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Data Allowance {item?.dataAllowance} <span>{item?.dataAllowanceUnit?.toUpperCase()}</span>
                                                                            </span>
                                                                        </p>
                                                                        <p className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                            <Checked />
                                                                            <span className="ml-2 allowancespan">
                                                                                Text Allowance {item?.textAllowance} <span>{item?.textAllowanceUnit?.toUpperCase()}</span>
                                                                            </span>
                                                                        </p>
                                                                        {item?.planFeature.map((featureItem, index) => (
                                                                            <p key={index} className="flex flex-wrap flex-row mt-2" style={{ alignItems: "center" }}>
                                                                                {featureItem?.isSelected ? <Checked /> : <Falsebtn />}
                                                                                <span className="ml-2 allowancespan">{featureItem?.name?.charAt(0).toUpperCase() + featureItem?.name?.slice(1).toLowerCase()}</span>
                                                                            </p>
                                                                        ))}
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            View Less
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="seemore">
                                                                        <p className="view" onClick={() => toggleViewAll(item._id)}>
                                                                            See More
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* Plan Price */}
                                                            <div className="planprice">
                                                                <div className="flex flex-row align-items-center w-full amountval">
                                                                    <span className="price">{item?.price}</span>
                                                                    <span className="month"> &nbsp; /per month</span>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className="planbtn"
                                                                        style={{ width: "8.8rem", height: "4.2rem" }}
                                                                        label="Buy"
                                                                        onClick={() => {
                                                                            if (paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)) {
                                                                            } else {
                                                                                let total = parseFloat(formik.values.totalamount);
                                                                                if (previousplanprice !== 0) {
                                                                                    total = total - previousplanprice;
                                                                                }
                                                                                formik.setFieldValue("totalamount", (total + item?.price).toFixed(2));
                                                                                //onPlanSelect(item);
                                                                                setPreviousPlanPrice(item?.price);
                                                                                formik.setFieldValue("plan", item?._id);
                                                                                setCurrentPlanName(item?.name);
                                                                                setCurrentPlanSelect(item?._id);
                                                                                formik.setFieldValue("currentPlanSelected", item?._id);

                                                                                api.post(`/api/user/plan`, { csr: parseLoginRes?._id, customerId: initiationData._id, plan: item?._id, inventoryId: inventoryIdSelected, carrier: item?.carrier?._id })
                                                                                    .then(() => {})
                                                                                    .catch((err) => {});
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex w-full  justify-content-center " style={{ marginTop: "5rem" }}>
                                        <div className="emptystatediv">
                                            <div className="emptyicon">
                                                <span>
                                                    <PlanEmpty />
                                                </span>
                                            </div>
                                            <div className="emptystatedata">
                                                <h1>No Plans Available</h1>
                                                <p>It seems there are no plans uploaded yet. Add new plans to get started and make them available for selection.</p>
                                                <div className="flex w-full justify-content-center">
                                                    <Button disabled={parseLoginRes?.role?.role !== "Admin"} className="emptystatebtn" label="Add Plans" onClick={() => navigate("/plan-configurations")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : undefined}
                    {currentScreen === 3 ? (
                        <div className="flex flex-column flex-wrap w-full billinginfo">
                            <div className="labelheader">
                                <h1 className="heading justify-content-start w-full">Delivery</h1>
                                <p className="para" style={{ marginTop: "1.2rem" }}>
                                    Confirm your delivery preferences and provide accurate details to ensure timely delivery of your selected products.
                                </p>
                            </div>
                            <div style={{ height: "9.9rem", marginTop: "2.4rem" }}>
                                <h1 className="heading justify-content-start w-full " style={{ lineHeight: "2.9rem" }}>
                                    Label
                                </h1>
                                <p className="w-full para" style={{ marginTop: "1.2rem" }}>
                                    If you want to generate a shipping label upon submission of this enrollment, please select the below option
                                </p>
                                <div className="flex align-items-center labelcheckbox">
                                    <Checkbox
                                        checked={checkLabelVal}
                                        onChange={(e) => {
                                            setCheckLabelVal(e.checked);
                                            setGenerate(e.checked);

                                            localStorage.setItem("generatelabel", generate);

                                            setProspectWithInvoice(false);
                                            setProspectWithOutInvoice(false);
                                            formik.setFieldValue("prospectwithinvoice", false);
                                            setProspectCases({ prospectWithInvoice: false, propectWithOutInvoice: false });
                                            formik.setFieldValue("prospectwithoutinvoice", false);
                                        }}
                                    />
                                    <label className="generatelabel">&nbsp;Generate Label on Submission</label>
                                </div>
                            </div>
                            <div className="flex  w-full generatelabelfields">
                                <div className=" flex flex-column calendar_field">
                                    <label>
                                        Carrier <span className="steric">*</span>
                                    </label>
                                    <Dropdown
                                        disabled={!checkLabelVal || paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                        value={carrierVal}
                                        id="name"
                                        onChange={(e) => {
                                            setCarrierVal(e.value);
                                            setServicesVal("");
                                            setServices([]);
                                            setExpectedCost(false);
                                        }}
                                        options={carrier}
                                        optionLabel="name"
                                        placeholder="Select Carrier"
                                        optionValue="code"
                                        className={carrierVal ? "dropdown" : "input_text"}
                                    />
                                </div>
                                <div className="flex flex-column calendar_field ">
                                    <label>
                                        Services <span className="steric">*</span>
                                    </label>

                                    <Dropdown
                                        value={servicesVal}
                                        id="name"
                                        disabled={!checkLabelVal || paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                        onChange={(e) => {
                                            setServicesVal(e.value);
                                            setExpectedCost(false);
                                        }}
                                        options={services}
                                        optionLabel="name"
                                        optionValue="code"
                                        placeholder="Select Service"
                                        className={servicesVal ? "dropdown" : "input_text"}
                                    />
                                </div>
                                <div className="flex flex-column calendar_field">
                                    <label>
                                        Delivery Confirmation <span className="steric">*</span>
                                    </label>

                                    {carrierVal === "FedEx" ? (
                                        <Dropdown
                                            disabled={!checkLabelVal || paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                            value={deliveryVal}
                                            onChange={(e) => {
                                                setDeliveryVal(e.value);
                                                setExpectedCost(false);
                                            }}
                                            options={deliveryFedEx}
                                            optionLabel="name"
                                            optionValue="name"
                                            placeholder="Select Delivery Option"
                                            className={servicesVal ? "dropdown " : " input_text"}
                                        />
                                    ) : (
                                        <Dropdown
                                            disabled={!checkLabelVal || paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                            value={deliveryVal}
                                            onChange={(e) => {
                                                setDeliveryVal(e.value);
                                                setExpectedCost(false);
                                            }}
                                            options={delivery}
                                            optionLabel="type"
                                            optionValue="type"
                                            placeholder="Select Delivery Option"
                                            className={deliveryVal ? "dropdown " : " input_text "}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="expectedcostdiv">
                                <div className="labelexpectedcost">
                                    <p className="expectedcosttext">
                                        Expected Cost :
                                        <span>
                                            {ratesLoading ? (
                                                <span>
                                                    &nbsp; <i className="pi pi-spin pi-spinner" style={{ color: "black" }}></i>
                                                </span>
                                            ) : (
                                                rates?.map((item) => {
                                                    try {
                                                        if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                                                            let rate = item?.shipmentCost + item?.otherCost;
                                                            rate = rate?.toFixed(2);
                                                            localStorage.setItem("shipmentCost", rate);
                                                            localStorage.setItem("shipmentName", item?.serviceName);

                                                            return <span key={item._id}> $ {rate}</span>;
                                                        }
                                                    } catch (e) {}
                                                })
                                            )}
                                        </span>
                                    </p>
                                    <p className="flex para" style={{ width: "80.0rem", marginTop: "1.2rem" }}>
                                        if you want the label cost to be covered by the company, Please select the option below
                                    </p>
                                </div>
                                <div className="flex flex-wrap w-full" style={{ marginTop: "1.6rem", marginLeft: "0.3rem" }}>
                                    <Checkbox
                                        disabled={!checkLabelVal || paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                        checked={checked}
                                        onChange={(e) => {
                                            setChecked(e.checked);
                                            localStorage.setItem("checked", e.checked);
                                        }}
                                    />
                                    <p style={{ opacity: !checkLabelVal ? "0.5" : "" }} className="shippinglabel">
                                        &nbsp;Add Shipping Label as Discount
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {currentScreen === 4 ? (
                        <div className="w-full flex flex-wrap flex-row justify-content-left prospdiv">
                            <div className="deliveryheader">
                                <h1 className="heading justify-content-start w-full">Billing</h1>
                                <p className="w-full para" style={{ marginTop: "1.2rem" }}>
                                    Select your billing method and provide necessary details for a smooth enrollment and payment process.{" "}
                                </p>
                            </div>
                            <div className="flex flex-column w-full deliveryradiobtn" style={{ marginTop: "2.4rem" }}>
                                <h3>Enrollment Save Options</h3>
                                <div className="flex w-full radiobtns">
                                    <div className="flex align-items-center" style={{ gap: ".55rem" }}>
                                        <RadioButton
                                            name="prospectwithinvoice"
                                            value="prospectwithinvoice"
                                            checked={formik.values.prospectwithinvoice}
                                            disabled={checkLabelVal}
                                            onChange={() => {
                                                if (!checkLabelVal) {
                                                    formik.setFieldValue("paymentMode", "");
                                                    formik.setFieldValue("prospectwithoutinvoice", false);
                                                    setpaymentmethoderror(false);
                                                    setProspectWithOutInvoice(false);
                                                    setProspectWithInvoice(true);
                                                    formik.setFieldValue("prospectwithinvoice", true);
                                                    setProspectCases({ prospectWithoutInvoice: false, prospectWithInvoice: true });
                                                }
                                            }}
                                        />

                                        <p>Prospect With Invoice</p>
                                    </div>
                                    <div className="flex align-items-center " style={{ gap: ".55rem" }}>
                                        <RadioButton
                                            name="prospectwithinvoice"
                                            value="prospectwithinvoice"
                                            checked={formik.values.prospectwithoutinvoice}
                                            onChange={() => {
                                                if (!checkLabelVal && !paymentDone && !(isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)) {
                                                    formik.setFieldValue("paymentMode", "");
                                                    setpaymentmethoderror(false);
                                                    formik.setFieldValue("prospectwithinvoice", false);
                                                    if (propectWithOutInvoice) {
                                                        setProspectWithOutInvoice(false);
                                                        formik.setFieldValue("prospectwithoutinvoice", false);
                                                        setProspectWithInvoice(false);
                                                    } else {
                                                        setProspectWithOutInvoice(true);
                                                        formik.setFieldValue("paid", "");
                                                        formik.setFieldValue("prospectwithoutinvoice", true);
                                                        setProspectCases({ prospectWithoutInvoice: true, prospectWithInvoice: false });
                                                    }
                                                }
                                            }}
                                        />
                                        <p> Prospect Without Invoice </p>
                                    </div>
                                    <div className="flex align-items-center" style={{ gap: ".55rem" }}>
                                        <RadioButton
                                            name="prospectwithinvoice"
                                            value="prospectwithinvoice"
                                            checked={!formik.values.prospectwithinvoice && !formik.values.prospectwithoutinvoice ? true : false}
                                            onChange={(e) => {
                                                formik.setFieldValue("prospectwithoutinvoice", false);
                                                formik.setFieldValue("prospectwithinvoice", false);
                                                setProspectWithOutInvoice(false);
                                                setProspectWithInvoice(false);
                                                setAutomate(e.value);
                                            }}
                                        />
                                        <p>Automate </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-column flex-wrap w-full" style={{ marginTop: "2.4rem" }}>
                                <div className="flex calendar_field" style={{ gap: "1.2rem" }}>
                                    <div className="flex flex-column calendar_field">
                                        <label className="field_label"> Additional Feature</label>
                                        <MultiSelect
                                            disabled={paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                            // className={formik.values.additional ? "dropdown w-full mt-2  discountmultiselect .p-multiselect-trigger" : "w-full mt-2 discountmultiselect .p-multiselect-trigger input_text"}
                                            className={formik.values.additional !== "" ? "w-full dropdown" : "w-full  input_text"}
                                            id="additional"
                                            placeholder="Select Additional Feature"
                                            optionLabel="featureName"
                                            options={additional}
                                            optionValue="_id"
                                            value={formik.values.additional}
                                            onChange={(e) => {
                                                let previousadditional = formik.values.additional;
                                                let previousadditionaltotal = 0;
                                                let newadditionaltotal = 0;
                                                for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                                    if (previousadditional.includes(currentBillingConfig?.additionalFeature[i]._id)) {
                                                        previousadditionaltotal += parseFloat(currentBillingConfig?.additionalFeature[i]?.featureAmount);
                                                    }
                                                }
                                                for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                                    if (e.value.includes(currentBillingConfig?.additionalFeature[i]._id)) {
                                                        newadditionaltotal += parseFloat(currentBillingConfig?.additionalFeature[i]?.featureAmount);
                                                    }
                                                }
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                totalamount = totalamount - previousadditionaltotal + newadditionaltotal;
                                                formik.setFieldValue("totalamount", totalamount.toFixed(2));
                                                formik.setFieldValue("additional", e.value);
                                                setAdditionalDiscounts((prev) => ({ ...prev, additional: e.value }));
                                            }}
                                        />
                                        {getFormErrorMessage("additional")}
                                    </div>
                                    <div className="flex flex-column calendar_field ">
                                        <label className="field_label"> Discounts</label>
                                        <MultiSelect
                                            disabled={paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                            className={formik.values.discount !== "" ? "w-full dropdown" : "w-full  input_text"}
                                            id="discount"
                                            placeholder="Select Discounts"
                                            optionLabel="discountname"
                                            optionValue="_id"
                                            options={discount}
                                            value={formik.values.discount}
                                            onChange={(e) => {
                                                let previousdiscount = formik.values.discount;
                                                let previousdiscounttotal = 0;
                                                let newdiscounttotal = 0;
                                                for (let i = 0; i < currentBillingConfig?.selectdiscount.length; i++) {
                                                    if (previousdiscount.includes(currentBillingConfig?.selectdiscount[i]._id)) {
                                                        previousdiscounttotal += parseFloat(currentBillingConfig?.selectdiscount[i]?.amount);
                                                    }
                                                }
                                                for (let i = 0; i < currentBillingConfig?.selectdiscount.length; i++) {
                                                    if (e.value.includes(currentBillingConfig?.selectdiscount[i]._id)) {
                                                        newdiscounttotal += parseFloat(currentBillingConfig?.selectdiscount[i]?.amount);
                                                    }
                                                }
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                totalamount = totalamount + previousdiscounttotal - newdiscounttotal;
                                                formik.setFieldValue("totalamount", totalamount.toFixed(2));
                                                formik.setFieldValue("discount", e.value);
                                                setAdditionalDiscounts((prev) => ({ ...prev, discounts: e.value }));
                                            }}
                                        />
                                        {getFormErrorMessage("discount")}
                                    </div>
                                    <div className="flex flex-column calendar_field">
                                        <label className="field_label">Net Amount</label>
                                        <InputText
                                            disabled
                                            className="w-full input_text"
                                            id="totalamount"
                                            value={formik.values.totalamount}
                                            onChange={(e) => {
                                                formik.setFieldValue("totalpayment", e.value?.toFixed(2));
                                                formik.handleChange(e);
                                            }}
                                            style={{ fontSize: "1.2rem" }}
                                        />
                                        {getFormErrorMessage("totalpayment")}
                                    </div>
                                </div>
                                <div className="flex" style={{ gap: "1.2rem", marginTop: "1.2rem" }}>
                                    <div className="flex flex-column calendar_field paymentmethod" style={{ width: "30rem" }}>
                                        <div className="flex justify-content-between">
                                            <label className="field_label"> Payment Method</label>
                                            {paymentmethoderror && <p className="p-error">Payment Method Is Required</p>}
                                        </div>
                                        <Dropdown
                                            disabled={
                                                paymentDone
                                                    ? true
                                                    : formik.values.prospectwithinvoice || formik.values.prospectwithoutinvoice
                                                      ? true
                                                      : isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0
                                            }
                                            className={`dropdown w-full ${formik.values.paymentMode ? "has-value" : "no-value"}`}
                                            // className="w-full mt-2 p-dropdown .p-dropdown-trigger input_text"
                                            id="paymentMode"
                                            optionValue="name"
                                            optionLabel="name"
                                            placeholder="Select Payment Method"
                                            options={paymentMethods}
                                            value={formik.values.paymentMode}
                                            onChange={(e) => {
                                                formik.setFieldValue("paid", 0);
                                                formik.setFieldValue("paymentMode", e.value);
                                                formik.handleChange(e);
                                                setpaymentmethoderror(false);
                                                setProspectWithOutInvoice(false);
                                                formik.setFieldValue("prospectwithoutinvoice", false);

                                                formik.setFieldValue("prospectwithinvoice", false);
                                                setProspectWithInvoice(false);
                                            }}
                                        />
                                    </div>
                                    {formik.values.paymentMode === "Credit Card" ? (
                                        <div className="flex flex-column calendar_field ">
                                            <div className="flex justify-content-between" style={{ width: "30rem" }}>
                                                <label className="field_label">Paying Amount</label>
                                                {paidAmountRequired ? <p className="p-error">Paying Amount Is Required</p> : ""}
                                                {/* {getFormErrorMessage("paid")} */}
                                            </div>
                                            <InputText
                                                disabled={paymentDone || (isLinkedAccountIncomplete && isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration)?.length > 0)}
                                                className="w-full input_text"
                                                id="paid"
                                                value={formik.values.paid}
                                                onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        setPaidAmountRequired(true);
                                                    } else {
                                                        setPaidAmountRequired(false);
                                                    }
                                                    formik.setFieldValue("paid", e.target.value);
                                                    setPayingAmount(e.target.value);
                                                    // formik.handleChange(e);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : undefined}
                    {/* 
                    <>
                        <Dialog style={{ textAlign: "center" }} className="stripe-dialog-width" header="Stripe Payment" visible={paymentDialogVisibility} setPaymentDialogVisibility={setPaymentDialogVisibility} onHide={() => setPaymentDialogVisibility(false)}>
                            <BankOfAmericaPaymentForm from="Prepaid Enrollment" amount={formik.values.paid} userId={parentDetails?._id} />
                        </Dialog>
                    </> */}
                </div>
                {false /*!companyName.toUpperCase().includes("MAGNET")*/ ? (
                    <Dialog style={{ textAlign: "center" }} className="stripe-dialog-width" header="Stripe Payment" visible={paymentDialogVisibility} setPaymentDialogVisibility={setPaymentDialogVisibility} onHide={() => setPaymentDialogVisibility(false)}>
                        <BankOfAmericaPaymentForm from="Prepaid Enrollment" amount={formik.values.paid} userId={parentDetails?._id} />
                    </Dialog>
                ) : (
                    <Dialog style={{ textAlign: "center" }} className="stripe-dialog-prepaid" header="Stripe Payment" visible={paymentDialogVisibility} setPaymentDialogVisibility={setPaymentDialogVisibility} onHide={() => setPaymentDialogVisibility(false)}>
                        <Payment
                            setAllPaymentInfo={setAllPaymentInfo}
                            parentDetails={parentDetails}
                            setAllpaymentDone={setAllpaymentDone}
                            setPreviewScreenActive={setPreviewScreenActive}
                            setPaymentScreenActive={setPaymentScreenActive}
                            setDataSendForInvoice={setDataSendForInvoice}
                            paymentScreenDetails={paymentScreenDetails}
                            currentBillingConfig={currentBillingConfig}
                            paid={formik.values.paid}
                            plan={formik.values.plan}
                            paymentDone={paymentDone}
                            setPaymentDone={setPaymentDone}
                            setPaymentScreenDetails={setPaymentScreenDetails}
                            setPaymentDialogVisibility={setPaymentDialogVisibility}
                            amount={formik.values.totalamount}
                            initiationData={initiationData}
                            object={formik.values}
                        />
                    </Dialog>
                )}
                <Dialog className="stripe-dialog-width" header={null} closable={false} visible={dialogForCardAuth} onHide={() => setdialogForCardAuth(false)} style={{ width: "58.8rem", height: "auto", ".p-dialog-draggable .p-dialog-header": { display: "none" } }}>
                    <CardAuthPayment
                        initiationData={initiationData}
                        setAllPaymentInfo={setAllPaymentInfo}
                        parentDetails={parentDetails}
                        setAllpaymentDone={setAllpaymentDone}
                        setPreviewScreenActive={setPreviewScreenActive}
                        setPaymentScreenActive={setPaymentScreenActive}
                        setDataSendForInvoice={setDataSendForInvoice}
                        paymentScreenDetails={paymentScreenDetails}
                        currentBillingConfig={currentBillingConfig}
                        paid={formik.values.paid}
                        allplans={plan}
                        toast={toast}
                        plan={formik.values.plan}
                        paymentDone={paymentDone}
                        setPaymentDone={setPaymentDone}
                        setPaymentScreenDetails={setPaymentScreenDetails}
                        setPaymentDialogVisibility={setPaymentDialogVisibility}
                        amount={formik.values.totalamount}
                        currentBillingModel={currentBillingModel}
                        object={formik.values}
                        setdialogForCardAuth={setdialogForCardAuth}
                    />
                </Dialog>
                <Dialog className="stripe-dialog-width" header="Authorize E-Check Payment" visible={dialogForEcheck} onHide={() => setDialogForEcheck(false)}>
                    <EcheckAuthPayment
                        initiationData={initiationData}
                        setAllPaymentInfo={setAllPaymentInfo}
                        parentDetails={parentDetails}
                        setAllpaymentDone={setAllpaymentDone}
                        allplans={plan}
                        setPreviewScreenActive={setPreviewScreenActive}
                        setPaymentScreenActive={setPaymentScreenActive}
                        setDataSendForInvoice={setDataSendForInvoice}
                        paymentScreenDetails={paymentScreenDetails}
                        currentBillingConfig={currentBillingConfig}
                        paid={formik.values.paid}
                        plan={formik.values.plan}
                        paymentDone={paymentDone}
                        setPaymentDone={setPaymentDone}
                        setPaymentScreenDetails={setPaymentScreenDetails}
                        setPaymentDialogVisibility={setPaymentDialogVisibility}
                        amount={formik.values.totalamount}
                        currentBillingModel={currentBillingModel}
                        object={formik.values}
                    />
                </Dialog>
            </div>
            <div className="flex flex-wrap flex-row justify-content-left paymentbtns">
                <Button
                    className="backbtn"
                    label="Back"
                    type="button"
                    onClick={() => {
                        if (currentScreen > 1) {
                            setTimeout(() => {
                                setCurrentScreen((prev) => (prev = prev - 1));
                            }, 100);
                            if (currentScreen === 1) {
                                setAddressActive(false);
                                setProductActive(true);
                                setSubscriptionActive(false);
                            } else if (currentScreen === 2) {
                                setProductActive(true);
                                setSubscriptionActive(false);
                            } else if (currentScreen === 3) {
                                setProductActive(false);
                                setSubscriptionActive(true);
                                setDeliveryActive(false);
                            } else if (currentScreen === 4) {
                                setSubscriptionActive(false);
                                setDeliveryActive(true);
                                setBillingActive(false);
                            }
                        } else {
                            setProductActive(false);
                            setPaymentScreenActive(false);
                            setAddressActive(true);
                        }
                    }}
                />
                <Button
                    className="nextbtn"
                    label={currentScreen === 3 ? "Next / Skip" : "Next"}
                    type="button"
                    onClick={() => {
                        if (currentScreen !== 4) {
                            if (currentScreen === 3) {
                                if (!checked && !checkLabelVal) {
                                    let currentamount = parseFloat(formik.values.totalamount);
                                    currentamount -= previousRate;
                                    setPreviousRate(0);
                                    formik.setFieldValue("totalamount", currentamount);
                                } else if (checked && checkLabelVal) {
                                    let currentamount = parseFloat(formik.values.totalamount);
                                    currentamount -= previousRate;
                                    setPreviousRate(0);
                                    formik.setFieldValue("totalamount", currentamount);
                                }
                            }
                            if (currentScreen === 1) {
                                setSubscriptionActive(true);
                                setProductActive(false);
                                setIsLoading(true);

                                api.put(`/api/user/storeInventory`, {
                                    inventoryId: inventoryIdSelected,
                                    serviceProvider: parseLoginRes?.company,
                                    customerId: initiationData?._id,
                                })
                                    .then((response) => {
                                        setIsLoading(false);
                                    })
                                    .catch((err) => {
                                        setIsLoading(false);
                                    });
                            } else if (currentScreen === 2) {
                                setProductActive(false);
                                setSubscriptionActive(false);
                                setDeliveryActive(true);
                            } else if (currentScreen === 3) {
                                setDeliveryActive(false);
                                setBillingActive(true);
                            }
                            setTimeout(() => {
                                setCurrentScreen((prev) => (prev = prev + 1));
                            }, 100);
                        } else {
                            setIsLoading(true);

                            api.put(`/api/user/saveData`, {
                                serviceProvider: parseLoginRes?.company,
                                customerId: initiationData?._id,
                                netAmount: formik.values.totalamount,
                                isProspect: formik.values.prospectwithinvoice,
                                isWithoutProspect: formik.values.prospectwithoutinvoice,
                                payingAmount: formik.values.amountPaid,
                                payingAmount: formik.values.paid,
                                paymentMethod: formik.values.paymentMode,
                                additionalCharges: formik.values.additional,
                                discount: formik.values.discount,
                            })
                                .then((response) => {
                                    setIsLoading(false);
                                    if (isLinkedAccountIncomplete && isLinkedAccountIncomplete?.activeBillingConfiguration && Object.keys(isLinkedAccountIncomplete?.activeBillingConfiguration?.length > 0)) {
                                        setPaymentScreenActive(false);
                                        setPreviewScreenActive(true);
                                    } else {
                                        formik.handleSubmit();
                                    }
                                })
                                .catch((err) => {
                                    setIsLoading(false);
                                });
                        }
                    }}
                    icon={isLoading ? "pi pi-spin pi-spinner " : ""}
                    disabled={
                        isLoading ||
                        (currentScreen === 1 && current === "") ||
                        (currentScreen === 2 && currentPlanSelect === "") ||
                        (currentScreen === 3 && checkLabelVal && ((carrierVal === "" && checkLabelVal) || !expectedCost || (servicesVal === "" && checkLabelVal) || !expectedCost || (deliveryVal === "" && checkLabelVal) || !expectedCost))
                    }
                />
            </div>
        </div>
    );
}
