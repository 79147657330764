import React, { useEffect, useRef, useState } from "react";
import "./css/PayInvoiceDialog.css";
import { PayInvoiceCheckIcon } from "./assets/PayInvoiceAsset";
import { Button } from "primereact/button";
import { PayInvoiceStripeCardPayment } from "./components/StripeCardPayment/StripeCardpayment";
import CommonLoaderBlue from "../../../../../../../../../../components/Loader/LoaderBlue";
import { api } from "../../../../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
export const PayInvoiceDialog = ({ setRefreshPayInvoice, setShowPayInvoiceDialog, selectedInvoices, cpData }) => {
    const [currentSelectedPaymentType, setCurrentSelectedPaymentType] = useState("card");
    const [amountTopaid, setAmountTopaid] = useState();
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const toast = useRef();
    const [currentNumber, setNumber] = useState();
    const parseLoginRes = JSON.parse(localStorage.getItem("userData"));

    const [funcToCall, setFucToCallOnPay] = useState();
    const [amountObject, setAmountObject] = useState({
        processingFee: 0.0,
        totalAmount: 0.0,
        totalSumAmount: 0.0,
    });
    useEffect(() => {
        if (!selectedInvoices || selectedInvoices.length === 0) {
            setAmountObject({
                processingFee: "0.00",
                totalAmount: "0.00",
                totalSumAmount: "0.00",
            });
            return;
        }

        const { processingFee, totalAmount } = selectedInvoices.reduce(
            (acc, { currentBalance = 0, processingFee = 0 }) => ({
                processingFee: acc.processingFee + parseFloat(processingFee || 0),
                totalAmount: acc.totalAmount + parseFloat(currentBalance || 0),
            }),
            { processingFee: 0, totalAmount: 0 },
        );

        setAmountObject({
            processingFee: (processingFee || 0).toFixed(2),
            totalAmount: (totalAmount || 0).toFixed(2),
            totalSumAmount: ((totalAmount || 0) + (processingFee || 0)).toFixed(2),
        });
    }, [selectedInvoices]);
    const [showNumberError, setShowNumberError] = useState(false);
    const [showAmountError, setShowAmountError] = useState(false);

    return (
        <div style={{ width: "108.4rem" }} className="flex flex-wrap flex-row justify-content-center ">
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
            <div className="payinvoice-paydialog-main">
                <div className="main-tag-container">
                    <label className="top-header">Pay invoice</label>
                    <label className="top-header-title">Any additional amount greater than total due amount will be added to customer wallet instantly.</label>
                </div>
                <div className="payment-field-container">
                    <div className="payment-field-item">
                        <p>Amount</p>
                        <p>${amountObject.totalAmount}</p>
                    </div>
                    <div className="payment-field-item">
                        <p>Processing Fee</p>
                        <p>${amountObject.processingFee}</p>
                    </div>
                    <div className="payment-field-item">
                        <p>Total Amount</p>
                        <p>${amountObject.totalSumAmount}</p>
                    </div>
                </div>
                <div className="payment-type-container">
                    <label className="top-header-payment-type">Payment Type</label>
                    <div className="payment-type-selector-container">
                        <div className="payment-type-item">
                            <div className="main-check-container">
                                {currentSelectedPaymentType === "card" ? (
                                    <PayInvoiceCheckIcon />
                                ) : (
                                    <div
                                        className="main-check-wrapper"
                                        onClick={() => {
                                            setCurrentSelectedPaymentType("card");
                                        }}
                                    >
                                        <div className={`${currentSelectedPaymentType === "card" ? "active-check-button" : ""}`}></div>
                                    </div>
                                )}
                            </div>

                            <label>Credit Card</label>
                        </div>
                        <div className="payment-type-item">
                            <div className="main-check-container">
                                {currentSelectedPaymentType === "echeck" ? (
                                    <PayInvoiceCheckIcon />
                                ) : (
                                    <div
                                        className="main-check-wrapper"
                                        onClick={() => {
                                            setCurrentSelectedPaymentType("echeck");
                                        }}
                                    >
                                        <div className={`${currentSelectedPaymentType === "echeck" ? "active-check-button" : ""}`}></div>
                                    </div>
                                )}
                            </div>

                            <label>E- Check</label>
                        </div>
                        <div className="payment-type-item">
                            <div className="main-check-container">
                                {currentSelectedPaymentType === "cash" ? (
                                    <PayInvoiceCheckIcon />
                                ) : (
                                    <div
                                        className="main-check-wrapper"
                                        onClick={() => {
                                            setCurrentSelectedPaymentType("cash");
                                        }}
                                    >
                                        <div className={`${currentSelectedPaymentType === "cash" ? "active-check-button" : ""}`}></div>
                                    </div>
                                )}
                            </div>

                            <label>Cash</label>
                        </div>
                        <div className="payment-type-item">
                            <div className="main-check-container">
                                {currentSelectedPaymentType === "walletbalance" ? (
                                    <PayInvoiceCheckIcon />
                                ) : (
                                    <div
                                        className="main-check-wrapper"
                                        onClick={() => {
                                            setCurrentSelectedPaymentType("walletbalance");
                                        }}
                                    >
                                        <div className={`${currentSelectedPaymentType === "walletbalance" ? "active-check-button" : ""}`}></div>
                                    </div>
                                )}
                            </div>

                            <label>Wallet Balance</label>
                        </div>
                        <div className="payment-type-item">
                            <div className="main-check-container">
                                {currentSelectedPaymentType === "moneygram" ? (
                                    <PayInvoiceCheckIcon />
                                ) : (
                                    <div
                                        className="main-check-wrapper"
                                        onClick={() => {
                                            setCurrentSelectedPaymentType("moneygram");
                                            setNumber();
                                        }}
                                    >
                                        <div className={`${currentSelectedPaymentType === "moneygram" ? "active-check-button" : ""}`}></div>
                                    </div>
                                )}
                                :
                            </div>

                            <label>Money Gram </label>
                        </div>
                        <div className="payment-type-item">
                            <div className="main-check-container">
                                {currentSelectedPaymentType === "moneyorder" ? (
                                    <PayInvoiceCheckIcon />
                                ) : (
                                    <div
                                        className="main-check-wrapper"
                                        onClick={() => {
                                            setCurrentSelectedPaymentType("moneyorder");
                                            setNumber();
                                        }}
                                    >
                                        <div className={`${currentSelectedPaymentType === "moneyorder" ? "active-check-button" : ""}`}></div>
                                    </div>
                                )}
                            </div>

                            <label>Money Order</label>
                        </div>

                        <div className="payment-type-item">
                            <div className="main-check-container">
                                {currentSelectedPaymentType === "cheque" ? (
                                    <PayInvoiceCheckIcon />
                                ) : (
                                    <div
                                        className="main-check-wrapper"
                                        onClick={() => {
                                            setCurrentSelectedPaymentType("cheque");
                                            setNumber();
                                        }}
                                    >
                                        <div className={`${currentSelectedPaymentType === "cheque" ? "active-check-button" : ""}`}></div>
                                    </div>
                                )}
                            </div>
                            <label>Cheque</label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap flex-row justify-content-between align-items-center">
                    <div className="payingamount-container">
                        <div className="flex w-full flex-wrap flex-row justify-content-between align-items-center">
                            <label>Amount to be Paid</label>
                            {showAmountError && <label>Field Is Required</label>}
                        </div>
                        <input
                            placeholder="Amount to be Paid"
                            value={amountTopaid}
                            type="number"
                            className={`${showAmountError && "p-invalid"}`}
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setShowAmountError(true);
                                } else {
                                    setShowAmountError(false);
                                }
                                setAmountTopaid(e.target.value);
                            }}
                        />
                    </div>
                    {(currentSelectedPaymentType === "moneyorder" || currentSelectedPaymentType === "moneygram" || currentSelectedPaymentType === "cheque") && (
                        <div className="payingamount-container">
                            <div className="flex w-full flex-wrap flex-row justify-content-between align-items-center">
                                <label>{currentSelectedPaymentType === "moneyorder" || currentSelectedPaymentType === "moneygram" ? "Receipt Number" : "Cheque Number"}</label>
                                {showNumberError && <label>Field Is Required</label>}
                            </div>
                            <input
                                placeholder={`${currentSelectedPaymentType === "moneyorder" || currentSelectedPaymentType === "moneygram" ? "Receipt Number" : "Cheque Number"}`}
                                value={currentNumber}
                                type="number"
                                className={`${showNumberError && "p-invalid"}`}
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setShowNumberError(true);
                                    } else {
                                        setShowNumberError(false);
                                    }
                                    setNumber(e.target.value);
                                }}
                            />
                        </div>
                    )}
                </div>
                {currentSelectedPaymentType === "card" && (
                    <PayInvoiceStripeCardPayment
                        setShowAmountError={setShowAmountError}
                        setRefreshPayInvoice={setRefreshPayInvoice}
                        setShowPayInvoiceDialog={setShowPayInvoiceDialog}
                        funcToCall={funcToCall}
                        setFucToCallOnPay={setFucToCallOnPay}
                        cpData={cpData}
                        selectedInvoices={selectedInvoices}
                        amount={amountTopaid}
                    />
                )}
                {currentSelectedPaymentType !== "card" && (
                    <div className="action-buttons-container flex flex-wrap flex-row justify-content-end">
                        <Button
                            disabled={isPaymentLoading}
                            onClick={() => {
                                setShowPayInvoiceDialog(false);
                            }}
                            label="Cancel"
                        />
                        <Button
                            onClick={(e) => {
                                if (
                                    (currentSelectedPaymentType === "moneygram" || currentSelectedPaymentType === "moneyorder" || currentSelectedPaymentType === "cheque") &&
                                    (currentNumber === undefined || currentNumber !== "" || amountTopaid === "" || amountTopaid === undefined || amountTopaid === 0)
                                ) {
                                    if ((currentSelectedPaymentType === "moneygram" || currentSelectedPaymentType === "moneyorder" || currentSelectedPaymentType === "cheque") && (currentNumber === undefined || currentNumber !== "")) {
                                        setShowNumberError(true);
                                    }
                                    if (amountTopaid === "" || amountTopaid === undefined || amountTopaid === 0) {
                                        setShowAmountError(true);
                                    }
                                } else {
                                    setIsPaymentLoading(true);
                                    api.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                                        invoices: selectedInvoices.map((obj) => obj._id),
                                        invoicePaymentMethod:
                                            currentSelectedPaymentType === "walletbalance"
                                                ? "Wallet"
                                                : currentSelectedPaymentType === "cash"
                                                  ? "Cash"
                                                  : currentSelectedPaymentType === "moneyorder"
                                                    ? "Money Order"
                                                    : currentSelectedPaymentType === "moneygram"
                                                      ? "Money Gram"
                                                      : currentSelectedPaymentType === "cheque"
                                                        ? "Cheque"
                                                        : " ",
                                        amountPaid: amountTopaid,
                                        customerId: cpData?._id,
                                        userId: parseLoginRes?._id,
                                        isWallet: currentSelectedPaymentType === "walletbalance" ? true : false,
                                        isCash: currentSelectedPaymentType === "cash" ? true : false,
                                        ismoneyGram: currentSelectedPaymentType === "moneygram" ? true : false,
                                        ismoneyOrder: currentSelectedPaymentType === "moneyorder" ? true : false,
                                        receiptNumber: currentSelectedPaymentType === "moneyorder" || currentSelectedPaymentType === "moneygram" ? currentNumber : undefined,
                                        checkNumber: currentSelectedPaymentType === "cheque" ? currentNumber : undefined,
                                    })
                                        .then((res) => {
                                            toast.current.show({ severity: "success", summary: "Info", detail: res?.data?.message || "Invoice Updated Succesfully" });
                                            setTimeout(() => {
                                                setShowPayInvoiceDialog(false);
                                                setRefreshPayInvoice((prev) => !prev);
                                            }, 500);
                                        })
                                        .catch((err) => {
                                            setIsPaymentLoading(false);
                                            toast.current.show({ severity: "success", summary: "Info", detail: err?.response?.data?.message || "Invoice Updatdation Failed" });
                                        });
                                }
                            }}
                            disabled={isPaymentLoading}
                            label={
                                isPaymentLoading ? (
                                    <div className="w-full flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue sizehere={12} color={"white"} />
                                    </div>
                                ) : (
                                    "Pay Now"
                                )
                            }
                        />
                    </div>
                )}
                <Toast ref={toast} />
            </div>
        </div>
    );
};
