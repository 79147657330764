import { api } from "../../../../utility/AxiosInceptor";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./BankOfAmerica.css";
import CommonLoaderBlue from "../../../components/Loader/LoaderBlue";
import CommonLoader from "../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/Loader";
const BankOfAmericaPaymentForm = ({ from, amount, userId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        transaction_type: "sale",
        reference_number: "enrollment",
        amount: amount,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let formDataNew = new FormData();

        Object.keys(formData).forEach((item) => {
            formDataNew.append(item, formData[item]);
        });
        try {
            const { data } = await api.post(`/api/web/invoices/boa-checkout`, formData);

            console.log(data.signedData);

            // Redirect to the CyberSource payment URL
            // Redirect with form data using a dynamically created form
            const form = document.createElement("form");
            form.method = "POST";
            form.action = data.paymentUrl; // Backend should return the CyberSource payment URL
            // Add form data as hidden inputs
            Object.entries(data.signedData).forEach(([key, value]) => {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value || ""; // Ensure no field is undefined
                form.appendChild(input);
            });

            console.log(form);

            sessionStorage.setItem("Payment Initiation", from);
            sessionStorage.setItem("transaction_uuid", data.signedData.transaction_uuid);
            sessionStorage.setItem("Refrerence Number", formData.reference_number);
            sessionStorage.setItem("checkLabel", localStorage.getItem("checkLabel"));
            sessionStorage.setItem("shipmentName", localStorage.getItem("shipmentName"));
            sessionStorage.setItem("shipmentCost", localStorage.getItem("shipmentCost"));
            sessionStorage.setItem("generatelabel", localStorage.getItem("generatelabel"));
            if (from === "Prepaid Enrollment") {
                sessionStorage.setItem("userId", userId);
            }
            document.body.appendChild(form);
            // return 0;
            form.submit();
        } catch (error) {
            setIsLoading(false);
            console.error("Error processing payment:", error);
        }
    };

    return (
        <form className="main-form" onSubmit={handleSubmit}>
            <fieldset>
                <div>
                    <label>Transaction Type:</label>
                    <input type="text" name="transaction_type" value={formData.transaction_type} onChange={handleInputChange} />
                </div>
                <div>
                    <label>Reference Number:</label>
                    <input type="text" name="reference_number" value={formData.reference_number} onChange={handleInputChange} />
                </div>
                <div>
                    <label>Amount:</label>
                    <input type="text" name="amount" value={formData.amount} onChange={handleInputChange} />
                </div>
                <div>
                    <label>Currency:</label>
                    <input type="text" name="currency" value={formData.currency} onChange={handleInputChange} />
                </div>
            </fieldset>

            <button type={`${isLoading ? "button" : "submit"}`}>
                {isLoading ? (
                    <div className="flex flex-wrap flex-row justify-content-center w-full">
                        <CommonLoader />
                    </div>
                ) : (
                    "Submit"
                )}
            </button>
        </form>
    );
};

export default BankOfAmericaPaymentForm;
