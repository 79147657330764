import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "./app/store/auth/AuthSlice";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";
import "./apptopbar.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "../public/setting.svg";
import "../public/user.svg";
import "./app.css";
import "primeicons/primeicons.css";
import { Sidebar } from "primereact/sidebar";
import { AppButton, DarkTheme, LanguageButton, LightTheme, LogoutIcon, LogoutIconActive, NotificationIcon, PrivateIcon, PrivateIconActive, ProfileIcon, ProfileIconActive, SettingIcon, SettingIconActive } from "./utility";
import Axios from "axios";
import io from "socket.io-client"; // Add this import for socket.io-client
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AppTopbar = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const location = useLocation();
    const [counter, setCounter] = useState("");
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [profileActive, setProfileActive] = useState(false);
    const [settingActive, setSettingActive] = useState(false);
    const [privateActive, setPrivateActive] = useState(false);
    const [logoutActive, setLogoutActive] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [CustomerProfileIcon, setCustomerProfileIcon] = useState(false);
    const [subsidiedIcon, setSubsidizedIcon] = useState(false);
    const [prepaidIcon, setPrepaidIcon] = useState(false);
    const [postpaidIcon, setPostpaidIcon] = useState(false);
    const [inventoryIcon, setInventoryIcon] = useState(false);
    const [bulkIcon, setBulkIcon] = useState(false);
    const [batchIcon, setBatchIcon] = useState(false);
    const [settingsIcon, setSettingsIcon] = useState(false);
    const [userIcon, setUserIcon] = useState(false);
    const [reportsIcon, setReportsIcon] = useState(false);

    useEffect(() => {
        const handleRouteChange = (newLocation) => {
            if (newLocation.pathname !== currentLocation) {
                // if(props.searchByValueClick || props.searchBy){
                props.setSearchBy(null);
                props.setSearchByValueClick(false);
                setCurrentLocation(newLocation.pathname);
                //  }
            }
        };
        handleRouteChange(location);
        window.addEventListener("popstate", handleRouteChange);
        return () => {
            window.removeEventListener("popstate", handleRouteChange);
        };
    }, [location]);
    useEffect(() => {
        document.addEventListener("click", docOnClick, false);
    });
    const socket = useRef(null);

    useEffect(() => {
        socket.current = io(BASE_URL, {
            transports: ["websocket"],
        });
        socket.current.on("connect", () => {});
        socket.current.on("newNote", () => {
            fetchNotifications();
        });

        socket.current.on("error", (err) => {});
        socket.current.on("error", (err) => {});

        // Fetch notifications when the component mounts (for login and refresh scenarios)
        fetchNotifications();

        return () => {
            socket.current.disconnect();
        };
    }, []);
    useEffect(() => {
        if (props.showApps) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [props.showApps]);
    const fetchNotifications = async () => {
        try {
            const response = await Axios.get(`${BASE_URL}/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
            const data = response?.data?.unreadCount;
            const note = response?.data?.notifications;
            setNotification(note);
            setCounter(data);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const [visibleSearch, setVisibleSearch] = useState(false);
    const [notification, setNotification] = useState([]);
    function docOnClick(e) {
        setVisibleSearch(false);
    }
    //Dialogues for Advance Search
    const countries = [
        { name: "Inventory Search", code: "inventorysearch" },
        // { name: "Payment Search", code: "paymentsearch" },
        { name: "Recent Searches", code: "recentsearches" },
        { name: "Advance Search", code: "advance search" },
    ];

    const countryTemplate = (option) => {
        return (
            <div className="flex listbox-container align-items-center">
                {option.name === "Payment Search" ? (
                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/images/Dashboard-Search/payment-search.png" alt="none" />
                ) : option.name === "Recent Searches" ? (
                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/images/Dashboard-Search/recent-search.png" alt="none" />
                ) : option.name === "Advance Search" ? (
                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/images/Dashboard-Search/advance-search.png" alt="none" />
                ) : (
                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/images/Dashboard-Search/inventory-search.png" alt="none" />
                )}
                <div>{option.name}</div>
            </div>
        );
    };
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Get user data from localStorage
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const handleLogout = () => {
        props.setSearchValue("");
        props.setSearchByValueClick(false);
        props.setSearchBy(null);
        dispatch(logout());
        navigate("/login");
    };
    // const CustomMessage = () => (
    //     <div
    //         className="flex flex-column w-15rem"
    //         onClick={(e) => {
    //             e.stopPropagation();
    //         }}
    //     >
    //         <i className="pi pi-user p-mr-2 text-center" style={{ fontSize: "2rem" }}></i>
    //         <p className="text-center mt-2" style={{ fontSize: "1.5rem" }}>
    //             {parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}
    //         </p>
    //         <p className="flex flex-wrap justify-content-center" style={{ fontSize: "1rem" }}>
    //             {parseLoginRes?.role?.role}
    //         </p>
    //     </div>
    // );
    // useEffect(() => {
    //     const getCounter = async () => {
    //         setInterval(async () => {
    //             try {
    //                 const response = await Axios.get(`${BASE_URL}/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
    //                 const data = response?.data?.unreadCount;
    //                 const note = response?.data?.notifications;
    //                 setNotification(note);
    //                 setCounter(data);
    //             } catch (error) {
    //                 toast.error(error?.response?.data?.msg);
    //             }
    //         }, 15000);
    //     };
    //     getCounter();
    // }, [props.refreshBell]);

    const handleReadNotification = async (notificationId) => {
        try {
            await Axios.put(`${BASE_URL}/api/web/notes/markReadnotifications?notificationId=${notificationId}&userId=${parseLoginRes?._id}`);
            // const response = await Axios.put(`${BASE_URL}/api/web/notes/markReadnotifications?notificationId=${notificationId}&userId=${parseLoginRes?._id}`);

            const res = await Axios.get(`${BASE_URL}/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
            const { unreadCount, notifications } = res?.data;
            setNotification(notifications);
            setCounter(unreadCount);
        } catch (error) {
            toast(error?.response?.data?.msg);
        }
    };
    const handleNavigate = (customerId) => {
        if (customerId !== undefined) {
            localStorage.setItem("selectedId", JSON.stringify(customerId));
            navigate("/customer-profile", { state: { selectedId: customerId } });
            if (location.pathname === "/customer-profile") {
                props.setRefreshNotificationComponent((prev) => !prev);
            }
        }
    };

    return (
        <div>
            <ToastContainer />

            {!props.showApps ? (
                <div className="notshowapps-main">
                    <div className="hamburgur">
                        <button type="button" className="layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                            <i
                                style={{
                                    fontSize: "1.25em",
                                    marginTop: "-5px",
                                    // Adjust the font size as needed
                                    // Optional: Make it bold
                                    display: "inline-block",
                                    color: "rgb(112, 134, 253)",
                                }}
                                className="pi pi-bars"
                            />
                        </button>
                    </div>
                    <div
                        className="layout-topbar"
                        onClick={(e) => {
                            setVisibleSearch(false);
                            e.stopPropagation();
                        }}
                    >
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-start">
                            <div className="search-customer">
                                <InputText
                                    onChange={(e) => {
                                        props.setSearchValue(e.target.value);
                                    }}
                                    value={props.searchValue}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.stopPropagation();
                                            if (props.searchValue !== "") {
                                                props.setSearchByValueClick(true);
                                                props.setCallSearchApi((prev) => !prev);
                                            }
                                            setVisibleSearch(false);
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (props.searchValue !== "") {
                                            props.setSearchByValueClick(true);
                                            props.setCallSearchApi((prev) => !prev);
                                        }
                                        setVisibleSearch(true);
                                    }}
                                    placeholder="Search Customer"
                                    style={{ border: "none", backgroundColor: "transparent" }}
                                />
                                {/* <i
                        className="pi pi-search search-toggle"
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibleSearch(false);
                            props.setSearchBy(null);
                            props.setSearchByValueClick(true);
                            if (props.searchByValueClick === true) {
                                props.setCallSearchApi((prev) => !prev);
                            }
                        }}
                        style={{ border: "none" }}
                    /> */}
                            </div>
                            <div className="buttonsdiv align-items-start">
                                <div className="appbutton" onClick={() => props.setShowApps(true)} style={{ cursor: "pointer" }}>
                                    <span className="app-span flex justify-content-center align-items-center gradient-text">
                                        <AppButton /> Apps
                                    </span>
                                </div>
                                <div className="themebutton">
                                    <span className="theme-span flex justify-content-center align-items-center gradient-text">
                                        <DarkTheme /> <LightTheme />
                                    </span>
                                </div>
                                <div className="notificationbutton" style={{ cursor: "pointer" }} onClick={() => setVisibleRight(true)}>
                                    <div>
                                        <span className="notification-span flex justify-content-center align-items-center gradient-text">
                                            <NotificationIcon />
                                        </span>
                                    </div>
                                    <span className="gradient-text notification-count">{counter <= 9 ? counter : "9+"}</span>
                                </div>
                                <div className="languagebutton ">
                                    <div className="language-span flex justify-content-center align-items-center gradient-text">
                                        <LanguageButton /> EN
                                    </div>
                                </div>

                                {showProfileMenu ? (
                                    <div
                                        onMouseLeave={() => {
                                            setShowProfileMenu(false);
                                        }}
                                        className="userloginexpand "
                                    >
                                        <div className="main-p flex flex-wrap justify-content-start align-items-center mt-1">
                                            <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/profileicon.png" alt="" />
                                            <p className="gradient-text" style={{ fontWeight: "500", paddingLeft: "5px" }}>
                                                {parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}
                                            </p>
                                        </div>
                                        <div className="userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setProfileActive(true)} onMouseLeave={() => setProfileActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {profileActive ? <ProfileIconActive /> : <ProfileIcon />}
                                            <span>View Profile</span>
                                        </div>
                                        <div className=" userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setSettingActive(true)} onMouseLeave={() => setSettingActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {settingActive ? <SettingIconActive /> : <SettingIcon />}
                                            <span>Setting</span>
                                        </div>
                                        <div className="userexpand-list flex  cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setPrivateActive(true)} onMouseLeave={() => setPrivateActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {privateActive ? <PrivateIconActive /> : <PrivateIcon />}
                                            <span>Private</span>
                                        </div>
                                        <div
                                            className=" userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2"
                                            onMouseEnter={() => setLogoutActive(true)}
                                            onMouseLeave={() => setLogoutActive(false)}
                                            onClick={() => {
                                                setShowProfileMenu(false);
                                                handleLogout();
                                            }}
                                        >
                                            {logoutActive ? <LogoutIconActive /> : <LogoutIcon />}
                                            <span>Sign Out</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="userlogin cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowProfileMenu(true);
                                        }}
                                    >
                                        <p className="main-p flex justify-content-start w-full align-items-center gradient-text" onClick={() => setVisible(true)}>
                                            <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/profileicon.png" alt="" />

                                            <p style={{ cursor: "pointer", textAlign: "center", paddingLeft: "5px" }} onClick={() => setVisible(true)}>
                                                {parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}
                                            </p>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className="flex justify-content-center listbox"
                        >
                            <ListBox
                                value={props.searchBy}
                                style={{ display: `${visibleSearch === true ? "block" : "none"}`, backgroundColor: "#e0edfe" }}
                                onChange={(e) => {
                                    if (e.value !== null) {
                                        props.setSearchByValueClick(false);
                                        props.setSearchBy(e.value);
                                        setVisibleSearch(false);
                                    }
                                }}
                                options={countries}
                                optionLabel="name"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (e.target.textContent === "Advance Search") {
                                    }
                                }}
                                itemTemplate={countryTemplate}
                                className="w-full "
                                listStyle={{ maxHeight: "250px" }}
                            />
                        </div>
                        {/* <ConfirmPopup target={document.getElementById("li")} visible={visible} onHide={() => setVisible(false)} message={<CustomMessage />} acceptLabel="Logout" accept={handleLogout} /> */}
                        <div className="flex  ">
                            <Sidebar className="notification" style={{ width: "35rem" }} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                                <h3>Notifications</h3>
                                <hr />
                                {notification.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            handleNavigate(item?.customerId);
                                            setVisibleRight(false);
                                            handleReadNotification(item?._id);
                                            props.setHandleHighlight(item?.noteId);
                                        }}
                                    >
                                        <h5>{item?.sender?.name}</h5>
                                        <p>{item.message}</p>

                                        <span style={{ cursor: "pointer" }}>
                                            <h5
                                                style={{ fontSize: "1rem", marginLeft: "24rem" }}
                                                onClick={() => {
                                                    handleReadNotification(item?._id);
                                                }}
                                            >
                                                {item.read ? "" : "Mark as read"}
                                            </h5>
                                        </span>
                                        <hr />
                                    </div>
                                ))}
                            </Sidebar>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="appdialog">
                    <div
                        className="layout-topbar"
                        onClick={(e) => {
                            setVisibleSearch(false);
                            e.stopPropagation();
                        }}
                    >
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-start">
                            <div className="search-customer">
                                <InputText
                                    onChange={(e) => {
                                        props.setSearchValue(e.target.value);
                                    }}
                                    value={props.searchValue}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.stopPropagation();
                                            if (props.searchValue !== "") {
                                                props.setSearchByValueClick(true);
                                                props.setCallSearchApi((prev) => !prev);
                                            }
                                            setVisibleSearch(false);
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (props.searchValue !== "") {
                                            props.setSearchByValueClick(true);
                                            props.setCallSearchApi((prev) => !prev);
                                        }
                                        setVisibleSearch(true);
                                    }}
                                    placeholder="Search Customer"
                                />
                                {/* <i
                        className="pi pi-search search-toggle"
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibleSearch(false);
                            props.setSearchBy(null);
                            props.setSearchByValueClick(true);
                            if (props.searchByValueClick === true) {
                                props.setCallSearchApi((prev) => !prev);
                            }
                        }}
                        style={{ border: "none" }}
                    /> */}
                            </div>
                            <div className="buttonsdiv">
                                <div className="themebutton">
                                    <span className="flex theme-span justify-content-center align-items-center gradient-text">
                                        <DarkTheme /> <LightTheme />
                                    </span>
                                </div>
                                <div className="notificationbutton" style={{ cursor: "pointer" }} onClick={() => setVisibleRight(true)}>
                                    <div>
                                        <span className="flex justify-content-center align-items-center gradient-text notification-span">
                                            <NotificationIcon />
                                        </span>
                                    </div>
                                    <span className="gradient-text notification-count">{counter <= 9 ? counter : "9+"}</span>
                                </div>
                                <div className="languagebutton ">
                                    <div className="flex language-span justify-content-center align-items-center gradient-text">
                                        <LanguageButton /> EN
                                    </div>
                                </div>
                                {showProfileMenu ? (
                                    <div
                                        onMouseLeave={() => {
                                            setShowProfileMenu(false);
                                        }}
                                        className="userloginexpand pl-2 cursor-pointer"
                                    >
                                        <div className="main-p flex flex-wrap justify-content-start align-items-center mt-1">
                                            <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/profileicon.png" alt="" />
                                            <p className="gradient-text pl-1">{parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}</p>
                                        </div>
                                        <div className="flex userexpand-list flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setProfileActive(true)} onMouseLeave={() => setProfileActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {profileActive ? <ProfileIconActive /> : <ProfileIcon />}
                                            <span>View Profile</span>
                                        </div>
                                        <div className="flex flex-wrap userexpand-list justify-content-center align-items-center pl-2" onMouseEnter={() => setSettingActive(true)} onMouseLeave={() => setSettingActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {settingActive ? <SettingIconActive /> : <SettingIcon />}
                                            <span>Setting</span>
                                        </div>
                                        <div className="flex userexpand-list flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setPrivateActive(true)} onMouseLeave={() => setPrivateActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {privateActive ? <PrivateIconActive /> : <PrivateIcon />}
                                            <span>Private</span>
                                        </div>
                                        <div
                                            className="flex userexpand-list flex-wrap justify-content-center align-items-center pl-2"
                                            onMouseEnter={() => setLogoutActive(true)}
                                            onMouseLeave={() => setLogoutActive(false)}
                                            onClick={() => {
                                                setShowProfileMenu(false);
                                                handleLogout();
                                            }}
                                        >
                                            {logoutActive ? <LogoutIconActive /> : <LogoutIcon />}
                                            <span>Sign Out</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="userlogin"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowProfileMenu(true);
                                        }}
                                    >
                                        <p className="flex main-p justify-content-start w-full align-items-center gradient-text" onClick={() => setVisible(true)}>
                                            <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src="/profileicon.png" alt="" />

                                            <p onClick={() => setVisible(true)}>{parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}</p>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className="flex justify-content-center listbox"
                        >
                            <ListBox
                                value={props.searchBy}
                                style={{ display: `${visibleSearch === true ? "block" : "none"}`, backgroundColor: "#e0edfe" }}
                                onChange={(e) => {
                                    if (e.value !== null) {
                                        props.setSearchByValueClick(false);
                                        props.setSearchBy(e.value);
                                        setVisibleSearch(false);
                                    }
                                }}
                                options={countries}
                                optionLabel="name"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (e.target.textContent === "Advance Search") {
                                    }
                                }}
                                itemTemplate={countryTemplate}
                                className="w-full "
                                listStyle={{ maxHeight: "250px" }}
                            />
                        </div>
                        {/* <ConfirmPopup target={document.getElementById("li")} visible={visible} onHide={() => setVisible(false)} message={<CustomMessage />} acceptLabel="Logout" accept={handleLogout} /> */}
                        {/* <ul className={classNames("layout-topbar-menu   lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                            <div className="flex  ">
                                { <i className="pi pi-bell"></i> }
                                <Sidebar className="notification" style={{ width: "35rem" }} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                                    <h3>Notifications</h3>
                                    <hr />
                                    {notification.map((item, index) => (
                                        <div
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handleNavigate(item?.customerId);
                                                setVisibleRight(false);
                                                handleReadNotification(item?._id);
                                                props.setHandleHighlight(item?.noteId);
                                            }}
                                        >
                                            <h5>{item?.sender?.name}</h5>
                                            <p>{item.message}</p>

                                            <span style={{ cursor: "pointer" }}>
                                                <h5
                                                    style={{ fontSize: "1rem", marginLeft: "24rem" }}
                                                    onClick={() => {
                                                        handleReadNotification(item?._id);
                                                    }}
                                                >
                                                    {item.read ? "" : "Mark as read"}
                                                </h5>
                                            </span>
                                            <hr />
                                        </div>
                                    ))}
                                </Sidebar>
                            </div>
                        </ul>  */}
                    </div>
                    <div className="appsicons">
                        <div className="mainappicons">
                            <div className="iconcontainer"   
                               onClick={()=>{   
                                 props.setShowApps(false)
                                setCustomerProfileIcon(false)
                                navigate("/customer-profile")
                               }}
                             onMouseEnter={() => setCustomerProfileIcon(true)} onMouseLeave={() => setCustomerProfileIcon(false)}>
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={CustomerProfileIcon ? "./second/customerprofile2.svg" : "./first/customerprofile.svg"} alt="" />
                            </div>
                            <div className="iconcontainer" onMouseEnter={() => setSubsidizedIcon(true)} onMouseLeave={() => setSubsidizedIcon(false)}>
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={subsidiedIcon ? "./second/subsidized2.svg" : "./first/subsidized.svg"} alt="" />
                            </div>
                            <div
                                className="iconcontainer"
                                onMouseEnter={() => setPrepaidIcon(true)}
                                onMouseLeave={() => setPrepaidIcon(false)}
                                onClick={() => {
                                    props.setShowApps(false);  
                                    setPrepaidIcon(false);
                                    navigate("/prepaid-allenrollment");
                                  
                                }}
                            >
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={prepaidIcon ? "./second/prepaid2.svg" : "./first/prepaid.svg"} alt="" />
                            </div>
                            <div
                                className="iconcontainer"
                                onMouseEnter={() => setPostpaidIcon(true)}
                                onMouseLeave={() => setPostpaidIcon(false)}
                                onClick={() => {
                                    props.setShowApps(false);
                         
                                    setPostpaidIcon(false);  
                                    navigate("/postpaid-allenrollment")
                                }}
                            >
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }} 
    
  className="appimages" src={postpaidIcon ? "./second/postpaid2.svg" : "./first/postpaid.svg"} alt="" />
                            </div>
                            <div className="iconcontainer"          
                            onClick={()=>{             
                                props.setShowApps(false);
                         
                                setInventoryIcon(false);  
                                navigate("/manageinventory")
                            }}
                             onMouseEnter={() => setInventoryIcon(true)} onMouseLeave={() => setInventoryIcon(false)}>
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={inventoryIcon ? "./second/inventory2.svg" : "./first/inventory.svg"} alt="" />
                            </div>
                            <div className="iconcontainer" onMouseEnter={() => setBulkIcon(true)} onMouseLeave={() => setBulkIcon(false)}>
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={bulkIcon ? "./second/bulk2.svg" : "./first/bulk.svg"} alt="" />
                            </div>
                            <div className="iconcontainer" onMouseEnter={() => setBatchIcon(true)} onMouseLeave={() => setBatchIcon(false)}>
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={batchIcon ? "./second/batch2.svg" : "./first/batch.svg"} alt="" />
                            </div>
                            <div className="iconcontainer"   
                              onClick={()=>{  
                                setSettingsIcon(false) 
                                props.setShowApps(false)
                                navigate("/billingconfiguration") 
                              }}
                             onMouseEnter={() => setSettingsIcon(true)} onMouseLeave={() => setSettingsIcon(false)}>
                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={settingsIcon ? "./second/settings2.svg" : "./first/settings.svg"} alt="" />
                            </div>
                            <div className="iconcontainer" onMouseEnter={() => setUserIcon(true)} onMouseLeave={() => setUserIcon(false)}>
                                <img      
                                  onClick={()=>{ 
                                    props.setShowApps(false) 
                                    setUserIcon(false) 
                                    navigate("/managerolesandrights")
                                  }}
                                  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={userIcon ? "./second/user2.svg" : "./first/users.svg"} alt="" />
                            </div>
                            <div className="iconcontainer" onMouseEnter={() => setReportsIcon(true)} onMouseLeave={() => setReportsIcon(false)}>
                                <img      
                                  onClick={()=>{ 
                                    props.setShowApps(false) 
                                    setReportsIcon(false) 
                                    navigate("/reports-downloads")
                                  }}
                                 onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="appimages" src={reportsIcon ? "./second/report2.svg" : "./first/reports.svg"} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
