import { api } from "../../../../utility/AxiosInceptor";
import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Toast } from "primereact/toast";
import { Loginbg, Sniptel } from "../../../../utility";
import { useNavigate } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const ForgotPass = ({ passToken }) => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [decodedToken, setDecodedToken] = useState(null);
    // Decode JWT Token
    const decodeJWT = (token) => {
        const [header, payload, signature] = token.split(".");
        const base64Url = payload.replace(/-/g, "+").replace(/_/g, "/");
        const decodedPayload = JSON.parse(Buffer.from(base64Url, "base64").toString("utf8"));
        return decodedPayload;
    };
    console.log("decodedPayload", decodedToken);

    useEffect(() => {
        if (passToken) {
            const token = passToken.toString();
            const decoded = decodeJWT(token);
            setDecodedToken(decoded);
        }
    }, [passToken]);

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required("Password is required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Password doesn't match")
                .required("Confirm Password is required"),
        }),
        onSubmit: (values) => {
            const dataToSend = {
                email: decodedToken?.email,
                newPassword: formik.values.password,
                reEnterPassword: formik.values.confirmPassword,
            };

            api.post(`/api/web/user/resetPassword`, dataToSend)
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        toast.current.show({ severity: "success", summary: "Reset Password", detail: res?.data?.msg });
                        navigate("/loginpage");
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Reset Password", detail: err?.response?.data?.msg });
                });
        },
    });
    return (
        <div className="flex w-full  justify-content-center align-items-center" style={{ background: "white", height: "100vh" }}>
            <Toast ref={toast} />
            <div className="bggradient flex w-full ">
                <Loginbg />
            </div>
            <form onSubmit={formik.handleSubmit} className="flex flex-column justify-content-center align-items-center" style={{ zIndex: "1", width: "483px", height: "590px" }}>
                <div className="mb-6">
                    <span>
                        <Sniptel />
                    </span>
                </div>
                <div className="flex flex-column justify-content-start align-items-center resetpass">
                    <div style={{ width: "355px", zIndex: "1" }}>
                        <h1>Create a New Password</h1>
                        <p className="flex mt-4">Enter a strong password to secure your account.</p>
                    </div>
                    <div className="flex flex-column" style={{ width: "355px", marginTop: "-38px" }}>
                        <div className="flex justify-content-between">
                            <label className={formik.touched.password && formik.errors.password ? "emaillabelerr" : "emaillabel "} style={{ marginTop: "50px" }}>
                                Password
                            </label>
                            {formik.touched.password && formik.errors.password ? (
                                <span className="err" style={{ marginTop: "50px" }}>
                                    {formik.errors.password}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                        <InputText id="password" name="password" className={formik.touched.password && formik.errors.password ? "emailinputerr mt-2" : "emailinput mt-2"} placeholder="Enter your password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                        <div className="flex justify-content-between">
                            <label className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "emaillabelerr mt-5" : "emaillabel mt-5"}>Confirm Password</label>
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <span className="err" style={{ marginTop: "30px" }}>
                                    {formik.errors.confirmPassword}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                        <InputText
                            id="confirmPassword"
                            name="confirmPassword"
                            className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "emailinputerr mt-2 " : "emailinput mt-2"}
                            placeholder="Re-enter your password"
                            value={formik.values.confirmPassword}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        <div className="flex w-full" style={{ marginTop: "28px" }}>
                            <Button type="submit" loading={loading} disabled={formik.values.password === "" || formik.errors.confirmPassword ? true : false} className="forgetbtn" label="Reset Password" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ForgotPass;
