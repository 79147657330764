import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./css/billingmodelconfig.css";
import Axios from "axios";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { api } from "../../../../../utility/AxiosInceptor";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import AddBillingModel from "./components/add_billing_model";
import { DeActive, InActive } from "../assets/svg";
import InventoryList from "./components/InventoryList/InventoryList";
import CommonLoaderBlue from "./Loaders/components/Loader/LoaderBlue";
import { InputSwitch } from "primereact/inputswitch";
import UpdateBillingModel from "./components/UpdateBillingModel/UpdateBillingModel";
import { PlanEditIcon } from "../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";

import AddInventoryAgainstBillingmode from "./components/InventoryList/AddInventory/AddInventory";
import UpdateInventoryAgainstBillingmode from "./components/InventoryList/UpdateInventory/UpdateInventory";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function BillingModelConfigurations() {
    const loginRes = localStorage.getItem("userData");
    const [loader, setLoader] = useState(true);
    const [statusChangeVisibility, setStatusChangeVisibility] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const parseLoginRes = JSON.parse(loginRes);
    const [billingModelList, setBillingModelList] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [addBillingModelVisibility, setAddBillingModelVisibility] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [updateBillingVisibility, setUpdateBillingVisibility] = useState(false);
    const [inventoryListVisibility, setInventoryListVisibility] = useState(false);
    const [billingSelect, setBillingSelect] = useState(true);
    const [inventorySelect, setInventorySelect] = useState(false);
    const [addInventory, setAddInventory] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const [status, setStatus] = useState(false);
    const [billingModelSelected, setBillingModelSelected] = useState();
    const [updateInventory, setUpdateInventory] = useState(false);
    const [inventorySelected, setInventorySelected] = useState();
    const [billingModelFromList, setBillingModelFromList] = useState();
    const [refreshList, setRefreshList] = useState(false);

    const op = useRef(null);
    useEffect(() => {
        api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setBillingModelList(res?.data?.data);
                setLoader(false);
            })
            .catch((err) => {});
    }, [refresh]);
    const updateBillingModelStatus = () => {
        setStatusLoading(true);
        const dataToSend = {
            active: rowData?.active ? false : true,
            billingModelId: rowData?._id,
        };
        api.put(`/api/billingModel/statusUpdate`, dataToSend)
            .then((res) => {
                toast.success(res?.data?.msg !== undefined ? res?.data?.msg : `Billing Modlel ${rowData?.active ? "Deactivated" : "Activated"} Successfully`);
                setStatusLoading(false);
                setStatusChangeVisibility(false);
                setRefresh((prev) => !prev);
                op.current.hide();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.msg !== undefined ? err?.response?.data?.msg : `Billing Modlel ${rowData?.active ? "Deactivation" : "Activation"} Successfully`);
                op.current.hide();
            });
    };

    const BillingModelName = (rowData) => {
        return (
            <>
                <p
                    onClick={() => {
                        setRowData(rowData);
                        setBillingModelSelected(rowData?._id);
                        setInventorySelect(true);
                        // setRowData(null);
                        setBillingSelect(false);
                    }}
                    className="billingmodelclick"
                >
                    {rowData?.billingModel}
                </p>
            </>
        );
    };
    return (
        <>
            {addInventory ? (
                <AddInventoryAgainstBillingmode setAddInventory={setAddInventory} />
            ) : addBillingModelVisibility ? (
                <AddBillingModel setRefresh={setRefresh} setAddBillingModelVisibility={setAddBillingModelVisibility} />
            ) : updateInventory ? (
                <UpdateInventoryAgainstBillingmode
                    billingModelSelect={billingModelSelected}
                    setBillingModelSelected={setBillingModelSelected}
                    inventorySelected={inventorySelected}
                    setInventorySelected={setInventorySelected}
                    billingModelFromList={billingModelFromList}
                    setBillingModelFromList={setBillingModelFromList}
                    setUpdateInventory={setUpdateInventory}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            ) : (
                <div className="w-full main-billingconfig">
                    <ToastContainer />
                    <div className="billingmodelheader">
                        <h1>Settings</h1>
                        <p>Manage billing models and inventories with ease—view, update, or customize details for streamlined operations.</p>
                    </div>
                    <div className="billingmodelinner">
                        <div className="billingiventory">
                            <div className={billingSelect ? "billingmodelselect" : "billingmodel"}>
                                <h3
                                    onClick={() => {
                                        setBillingSelect(true);
                                        setInventorySelect(false);
                                    }}
                                >
                                    Billing Models
                                </h3>
                            </div>
                            <div className={inventorySelect ? "inventoryselect" : "inventory"}>
                                <h3
                                    onClick={() => {
                                        setInventorySelect(true);
                                        setBillingSelect(false);
                                        setBillingModelSelected("");
                                        setRowData(null);
                                    }}
                                >
                                    Inventory
                                </h3>
                            </div>
                        </div>
                        <div className="billingmodelbtns flex" style={{ gap: "1.6rem" }}>
                            <Button
                                className="backbtn"
                                label="Add Inventory"
                                onClick={() => {
                                    setAddInventory(true);
                                }}
                                style={{ width: "12.8rem", height: "4.2rem", backgroundColor: "transparent" }}
                            />
                            <Button
                                onClick={() => {
                                    setAddBillingModelVisibility(true);
                                }}
                                label="Add Billing Model"
                                className="nextbtn"
                                style={{ width: "15rem", height: "4.2rem" }}
                            />
                        </div>
                    </div>

                    {/* <Dialog
                        header="Add Billing Model"
                        visible={addBillingModelVisibility}
                        style={{ width: "85vw" }}
                        onHide={() => {
                            setAddBillingModelVisibility(false);
                            setRefresh((prev) => !prev);
                        }}
                    >
                        <AddBillingModel setRefresh={setRefresh} setAddBillingModelVisibility={setAddBillingModelVisibility} />
                    </Dialog> */}

                    {inventorySelect ? (
                        <InventoryList
                            billingModelSelect={billingModelSelected}
                            setBillingModelSelected={setBillingModelSelected}
                            inventorySelect={inventorySelect}
                            setUpdateInventory={setUpdateInventory}
                            updateInventory={updateInventory}
                            inventorySelected={inventorySelected}
                            setInventorySelected={setInventorySelected}
                            billingModelFromList={billingModelFromList}
                            setBillingModelFromList={setBillingModelFromList}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            data={rowData}
                        />
                    ) : (
                        <div className="billingconfigdatatable">
                            <DataTable
                                value={billingModelList}
                                size="small"
                                emptyMessage={
                                    loader ? (
                                        <div className="w-full flex flex-wrap flex-row justify-content-center align-items-center ">
                                            <CommonLoaderBlue customSize={15} />
                                        </div>
                                    ) : (
                                        "Billing Models Not Found"
                                    )
                                }
                                stripedRows
                                resizableColumns
                                style={{ marginTop: "2.4rem" }}
                            >
                                <Column
                                    header="Id"
                                    field="_id"
                                    body={(rowData) => {
                                        let id = rowData._id.toUpperCase();
                                        return (
                                            <p
                                                onClick={() => {
                                                    setRowData(rowData);
                                                    setInventoryListVisibility(true);
                                                }}
                                                className="cursor-pointer"
                                            >
                                                {id.substring(Math.max(0, id.length - 4))}
                                            </p>
                                        );
                                    }}
                                />
                                <Column header="Billing Model" body={BillingModelName} />
                                <Column header="No of Inventroies" field="totalInventoryCount" />
                                <Column header="Status" field="active" body={(rowData) => <label className={rowData.active ? "active" : "deactive"}> {rowData.active ? "Active" : "Deactive"}</label>} />
                                <Column
                                    header="Action"
                                    headerClassName="p-3"
                                    body={(rowData) => {
                                        return (
                                            <>
                                                <div
                                                    onClick={(e) => {
                                                        setRowData(rowData);
                                                        op.current.toggle(e);
                                                        setBillingModelSelected(rowData?._id);
                                                    }}
                                                    className="actionbtnmain"
                                                >
                                                    <PlanEditIcon />
                                                </div>
                                            </>
                                        );
                                    }}
                                    field="Edit"
                                />
                            </DataTable>
                            <OverlayPanel ref={op} style={{ width: "11rem", height: "14.4rem" }} className="flex align-items-center billingmodel">
                                <div className="flex flex-column w-full overlay">
                                    <div
                                        className={rowData?.active ? "flex justify-content-start align-items-center prospectoverlay" : "flex justify-content-start align-items-center prospectoverlayactive"}
                                        style={{ height: "32px" }}
                                        onClick={() => {
                                            if (rowData?.active) {
                                                updateBillingModelStatus();
                                            } else {
                                            }
                                        }}
                                    >
                                        <label style={{ fontSize: "1.2rem", fontWeight: "500", color: !rowData?.active ? "#2627CF" : "" }}>Deactive</label>
                                    </div>

                                    <div
                                        className={!rowData?.active ? "flex justify-content-start align-items-center prospectoverlay" : "flex justify-content-start align-items-center prospectoverlayactive"}
                                        style={{ height: "32px" }}
                                        onClick={() => {
                                            if (!rowData?.active) {
                                                updateBillingModelStatus();
                                            } else {
                                            }
                                        }}
                                    >
                                        <label style={{ fontSize: "1.2rem", fontWeight: "500", color: rowData?.active ? "#2627CF" : "" }}>Active</label>
                                    </div>

                                    <div
                                        className="flex justify-content-start align-items-center prospectoverlay"
                                        style={{ height: "32px" }}
                                        onClick={() => {
                                            setUpdateBillingVisibility(true);
                                            op.current.hide();
                                        }}
                                    >
                                        <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Rename</label>
                                    </div>
                                    <div
                                        className="flex justify-content-start align-items-center prospectoverlay"
                                        style={{ height: "32px" }}
                                        onClick={() => {
                                            // setInventoryListVisibility(true);
                                            setInventorySelect(true);
                                            setRowData(rowData);
                                            // setRowData(null);
                                            setBillingSelect(false);
                                        }}
                                    >
                                        <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>View Inventory</label>
                                    </div>
                                </div>
                            </OverlayPanel>
                        </div>
                    )}

                    <Dialog
                        header={null}
                        closable={false}
                        showHeader={false}
                        visible={updateBillingVisibility}
                        onHide={() => {
                            setUpdateBillingVisibility(false);
                        }}
                        className="remanedialog"
                    >
                        <UpdateBillingModel setRefresh={setRefresh} setUpdateBillingVisibility={setUpdateBillingVisibility} rowData={rowData} />
                    </Dialog>
                    <Dialog
                        header={rowData?.active ? "Billing Model Deactivation" : "Billing Model Activation"}
                        headerClassName="font-poppins text-main-color"
                        visible={statusChangeVisibility}
                        onHide={() => {
                            setStatusChangeVisibility((prev) => !prev);
                        }}
                    >
                        <p className="text-main-color font-poppins">Do You Want To {rowData?.active ? "Deactivate" : "Activate"} the Billing Model ?</p>
                        <div className="mt-4  changestatusdialogbutton flex font-poppins flex-wrap flex-row ">
                            <Button onClick={updateBillingModelStatus} loading={statusLoading} disabled={statusLoading} label="Yes" />

                            <Button
                                label="No"
                                onClick={() => {
                                    setStatusChangeVisibility(false);
                                }}
                            />
                        </div>
                    </Dialog>
                </div>
            )}
        </>
    );
}
