import React, { useRef, useState } from "react";
import "./css/validateImeiDialogMain.css";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
export const ValidateImeiDialogMain = ({ cpData, setShowImeiValidateDialog }) => {
    const [isValidateLoading, setIsValidateLoading] = useState(false);
    let toast = useRef();
    const [imeiData, setImeiData] = useState("");
    const formik = useFormik({
        initialValues: {
            imei: "",
        },
        validationSchema: Yup.object({
            imei: Yup.string()
                .matches(/^\d{15}$/, "IMEI must be exactly 15 digits")
                .required("Field Is Required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setIsValidateLoading(true);

            api.get(`/api/user/validateImei?imei=${formik.values.imei}`)
                .then((response) => {
                    setIsValidateLoading(false);
                    setImeiData(response?.data?.status);
                    toast.current.show({ severity: "success", summary: "IMEI Validation", detail: <p>IMEI Validated Successfully</p> });
                })
                .catch((err) => {
                    setIsValidateLoading(false);
                    toast.current.show({ severity: "error", summary: "IMEI Validation", detail: <p>{err?.response?.data?.status?.description}</p> });
                });
        },
    });
    function validateImei() {}
    return (
        <form onSubmit={formik.handleSubmit} className="validateimei-dialog-main">
            <div className="validateimei-dialog-container">
                <div className="top-header">
                    <h1>IMEI Validation</h1>
                    <p>Would you like to proceed with the IMEI Validation ?</p>
                </div>
                <div className="field-input-imei">
                    <div className="flex flex-wrap flex-row w-full  justify-content-between align-item-center">
                        <label>IMEI</label>
                        {formik.touched.imei && formik.errors.imei && <label>{formik.errors.imei}</label>}
                    </div>
                    <input
                        type="number"
                        value={formik.values.imei}
                        onChange={(e) => {
                            if (e.target.value.length <= 15) formik.handleChange(e);
                        }}
                        className={`${formik.touched.imei && formik.errors.imei ? "p-invalid" : ""} `}
                        name="imei"
                    />
                </div>
            </div>
            <div className="w-full flex flex-wrap flex-row justify-content-end  button-validateimei-container">
                <Button
                    onClick={() => {
                        setShowImeiValidateDialog(false);
                    }}
                    label="Cancel"
                    disabled={isValidateLoading}
                />

                <Button type="submit" onClick={validateImei} label="Submit " disabled={isValidateLoading} icon={isValidateLoading ? "pi pi-spin pi-spinner " : ""} />
            </div>
            <Toast ref={toast} />
        </form>
    );
};
