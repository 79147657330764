import React from "react";
import "./css/AutoPayMain.css";
import { DetailsAutoPay } from "./components/DetailsAutoPay/DetailsAutoPay";
import { SetAutoPay } from "./components/SetAutoPay/SetAutoPay";
export const MainAutoPay = ({ setRefreshCustomerData, cpData }) => {
    return (
        <div className="main-autopay-container">
            <SetAutoPay setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} />
            <DetailsAutoPay cpData={cpData} />
        </div>
    );
};
