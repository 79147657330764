import Axios from "axios";

export const RefreshAccessToken = async () => {
    const userData = localStorage.getItem("userData");
    const token = userData ? JSON.parse(userData).refreshToken : null; // If userData exists, get the token

    if (!token) {
        console.error("No refresh token available.");
        return null; // No refresh token found, can't refresh the access token
    }

    try {
        // Call the API to refresh the token
        const response = await Axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/web/user/NewAccesstoken`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        if (response.data?.accessToken) {
            // Successfully got a new access token
            const newAccessToken = response.data.accessToken;

            // Update the localStorage with the new access token
            const updatedUserData = JSON.parse(userData);
            updatedUserData.token = newAccessToken;
            localStorage.setItem("userData", JSON.stringify(updatedUserData));

            return newAccessToken; // Return the new access token
        } else {
            console.error("No access token received in response.");
            return null;
        }
    } catch (err) {
        console.error("Error refreshing access token:", err);
        return null; // Return null in case of error
    }
};
