import { api } from "../../../../../../../utility/AxiosInceptor";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import Preview_Final_component from "./Preview_Final_component";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import "./Preview.css";
import { Dot, FalseDialog } from "../../../../../../../utility";
import { useNavigate } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Preview = ({ initiationData, parentDetails, currentBillingModel, isPostpaidIncomplete, prospectCases, currentAllBillingConfig, currentAllPlans, paymentScreenDetails, paymentDone, currentPlanSelect, inventoryData, inventoryIdSelected }) => {
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const parseLoginRes = loginRes;
    const _id = initiationData?._id;
    const csr = loginRes?._id;
    console.log("preview is calling");
    const [bankOfAmericaCustomerData, setBankOfAmericaCustomer] = useState({});
    const companyName = loginRes?.companyName;
    const inventoryImage = inventoryData?.find((item) => item?._id === inventoryIdSelected);
    const [visible, setVisible] = useState(false);
    const [dialog, setDialog] = useState(false);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }

    const [checked, setChecked] = useState(false);
    const [fromIncomplete, setFromIncomplete] = useState(false);
    //get preview  information from local storage
    const zipRes = localStorage.getItem("prepaidzipData");
    //check that user come from incomplete or not
    const fromIncompl = localStorage.getItem("comingfromincomplete");
    const parsefromIncompl = JSON.parse(fromIncompl);
    const [additionalFeatureInCasePayment, setAdditionalFeatureInCasePayment] = useState("");
    const [discountInCasePayment, setDiscountInCasePayment] = useState("");
    const [totalAmountIncasPaymentincomplete, setTotalAmountIncasepaymentincomplete] = useState("");
    const [amountPaidIncasepayment, setAmountpaidincasepayment] = useState("");
    const selectedPlan = currentAllPlans?.find((plan) => plan._id === currentPlanSelect);

    useEffect(() => {
        console.log("it is calling");
        if ((isPostpaidIncomplete && isPostpaidIncomplete?.activeBillingConfiguration) || (isPostpaidIncomplete && isPostpaidIncomplete?.bankOfamericacustomer)) {
            if (isPostpaidIncomplete && isPostpaidIncomplete?.activeBillingConfiguration) {
                if (Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0) {
                    let additionalincasincompletepaymentdone = "";
                    if (isPostpaidIncomplete.activeBillingConfiguration?.additionalFeature) {
                        for (let i = 0; i < isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature?.length; i++) {
                            if (i + 1 === isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature?.length) {
                                additionalincasincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature[i]?.name}`;
                            } else {
                                additionalincasincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature[i]?.name},`;
                            }
                        }
                        setAdditionalFeatureInCasePayment(additionalincasincompletepaymentdone);
                    }
                    let discountincaseincompletepaymentdone = "";
                    if (isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount) {
                        for (let i = 0; i < isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount?.length; i++) {
                            if (i + 1 === isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount?.length) {
                                discountincaseincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount[i]?.name}`;
                            } else {
                                discountincaseincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount[i]?.name},`;
                            }
                        }
                        setDiscountInCasePayment(discountincaseincompletepaymentdone);
                    }
                    if (isPostpaidIncomplete?.invoice?.length > 0) {
                        api.get(`/api/web/invoices/getinvoicebyid?invoiceId=${isPostpaidIncomplete?.invoice[0]}`)
                            .then((res) => {
                                setTotalAmountIncasepaymentincomplete(res?.data?.data?.totalAmount);
                                setAmountpaidincasepayment(res?.data?.data?.amountPaid);
                            })
                            .catch((err) => {});
                    }
                } else {
                    console.log("here");
                }
            } else {
                ///fetch all plans and also inventory too
                console.log(isPostpaidIncomplete);
                Axios.get(
                    `${BASE_URL}/api/user/BillingConfiguration?inventoryType=${isPostpaidIncomplete?.inventoryId?.inventoryType}&make=${isPostpaidIncomplete?.inventoryId?.make}&model=${isPostpaidIncomplete?.inventoryId?.model}&billingmodel=${currentBillingModel}&ServiceProvider=${parseLoginRes?.company}`,
                ).then((res1) => {
                    let currentplans = res1?.data;
                    console.log("all plans inside bnkof america", currentplans);
                    let selectedone = currentplans.filter((item) => item._id === isPostpaidIncomplete?.plan._id);
                    setBankOfAmericaCustomer({
                        plan: selectedone,
                    });
                    api.get(`/api/user/getBillingConfig?inventoryType=${isPostpaidIncomplete?.inventoryId?.inventoryType}&make=${isPostpaidIncomplete?.inventoryId?.make}&model=${isPostpaidIncomplete?.inventoryId?.model}&billingModel=${currentBillingModel}&ServiceProvider=${parseLoginRes?.company}`)
                        .then((res) => {
                            let featureObjectSendInGenerateInvoice = res?.data?.additionalFeature
                                .map((item, index) => {
                                    if (isPostpaidIncomplete?.selectedAdditional.includes(item?._id)) {
                                        return { name: item?.featureName, amount: item.featureAmount };
                                    }
                                })
                                .filter((feature) => feature !== undefined);
                            let discountObjectSendInGenerateInvoice = res?.data?.selectdiscount
                                .map((item, index) => {
                                    if (isPostpaidIncomplete?.selectedDiscount.includes(item?._id)) {
                                        return { name: item?.discountname, amount: item.amount };
                                    }
                                })
                                .filter((feature) => feature !== undefined);
                            let discounts = res?.data?.selectdiscount
                                .map((item, index) => {
                                    if (isPostpaidIncomplete?.selectedDiscount.includes(item?._id)) {
                                        return item?.discountname;
                                    }
                                })
                                .filter((feature) => feature !== undefined);
                            let Features = res?.data?.additionalFeature
                                .map((item, index) => {
                                    console.log("item is", item, "inex iss", index);
                                    if (isPostpaidIncomplete?.selectedAdditional.includes(item?._id)) {
                                        return item?.featureName;
                                    }
                                })
                                .filter((feature) => feature !== undefined);
                            console.log("features here", Features);
                            let discountstr = "";
                            discounts.map((item, index) => {
                                console.log(item);
                                if (index + 1 === discounts.length) {
                                    discountstr += `${item}`;
                                } else {
                                    discountstr += `${item}, `;
                                }
                            });
                            let featurestr = "";

                            Features.map((item, index) => {
                                console.log(item);
                                console.log(index);
                                if (index + 1 === Features.length) {
                                    featurestr += `${item}`;
                                } else {
                                    featurestr += `${item}, `;
                                }
                            });

                            console.log("discount object send in", discountObjectSendInGenerateInvoice);
                            console.log("feature object send in", featureObjectSendInGenerateInvoice);
                            setBankOfAmericaCustomer((prev) => {
                                return { ...prev, discountstring: discountstr, featurestring: featurestr, allBillConfig: res?.data, featureObjectSendInGenerateInvoice: featureObjectSendInGenerateInvoice, discountObjectSendInGenerateInvoice: discountObjectSendInGenerateInvoice };
                            });
                        })
                        .catch((err) => {});
                    setTimeout(() => {
                        console.log("selected one is", selectedone);
                        console.log("bankof america customer here here", bankOfAmericaCustomerData);
                    });
                });
            }
        } else {
            console.log(isPostpaidIncomplete !== "");
            console.log(isPostpaidIncomplete);
            console.log(isPostpaidIncomplete?.bankOfamericacustomer);
        }
    }, [isPostpaidIncomplete]);
    const PostpaidHandover = async () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
        };
        try {
            const response = await api.post(`/api/user/handOverEnrollment`, dataToSend);
            setIsLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
        setDialog(true);
    };
    const postData = async () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
            isWithInvoice: paymentScreenDetails?.prospectwithinvoice,
            isWithoutInvoice: paymentScreenDetails?.prospectwithoutinvoice,
        };

        api.post(`/api/user/esnAssingment`, dataToSend)
            .then(() => {
                toast.success("Esn Successfully Assigned");

                api.post(`/api/user/prepaidHandOver`, dataToSend)
                    .then((res) => {
                        api.post(`/api/web/order`, { orderNumber: initiationData?.enrollmentId })
                            .then((response) => {
                                toast.success("Order Placed Successfully");
                                let generate = localStorage.getItem("generatelabel");
                                generate = generate === "true"; // convering to boolean
                                if (generate) {
                                    api.post(`/api/web/order/createLable`, { orderId: response.data.data.orderId.toString(), userId: _id, testLabel: true })
                                        .then(() => {
                                            toast.success("Label created Successfully");
                                            setIsLoading(false);

                                            setDialog(true);
                                            setFromIncomplete(false);
                                        })
                                        .catch((err) => {
                                            setIsLoading(false);

                                            setDialog(true);
                                            setFromIncomplete(false);
                                        });
                                } else {
                                    setDialog(true);
                                    setFromIncomplete(false);
                                }
                            })
                            .catch((err) => {
                                toast.error("Order Placing Failed");
                            });
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data?.msg);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.response?.data?.msg);
            });
    };
    useEffect(() => {
        if (!zipRes && parsefromIncompl == false) {
            setIsChecked(true);
        } else if (!zipRes && parsefromIncompl == true) {
            setIsChecked(false);
        }
    }, []);
    let inventory;
    let oneTimeCharge;
    let discount = "";
    let additional = "";
    let discountobjectsendin = [];
    let additionalobjectsendin = [];
    let productName;
    let dueDate;
    let applyLateFee;
    let planname;
    let plancharges;

    for (let i = 0; i < currentAllPlans?.length; i++) {
        if (paymentScreenDetails?.plan === currentAllPlans[i]?._id) {
            planname = currentAllPlans[i]?.name;
            plancharges = currentAllPlans[i]?.price;
            inventory = currentAllPlans[i]?.inventoryType;
            //planId = currentAllPlans[i]?._id;
        }
    }

    productName = "SIM";
    let selectdiscount = paymentScreenDetails?.discount;
    let alldiscounts = currentAllBillingConfig?.selectdiscount;
    applyLateFee = currentAllBillingConfig?.applyLateFee;
    dueDate = currentAllBillingConfig?.dueDate;
    oneTimeCharge = currentAllBillingConfig?.oneTimeCharge;
    let simalladditional = currentAllBillingConfig?.additionalFeature;
    let additionallocal = paymentScreenDetails?.additional;
    for (let i = 0; i < additionallocal?.length; i++) {
        for (let k = 0; k < simalladditional?.length; k++) {
            if (additionallocal[i] === simalladditional[k]._id) {
                let obj = {
                    name: simalladditional[k]?.featureName,
                    amount: simalladditional[k]?.featureAmount,
                };
                additionalobjectsendin.push(obj);
                if (i + 1 === additionallocal?.length) {
                    additional += `${simalladditional[k].featureName}`;
                } else {
                    additional += `${simalladditional[k].featureName},`;
                }
            }
        }
    }

    for (let k = 0; k < selectdiscount?.length; k++) {
        for (let i = 0; i < alldiscounts?.length; i++) {
            if (selectdiscount[k] === alldiscounts[i]._id) {
                let obj = {
                    name: alldiscounts[i]?.discountname,
                    amount: alldiscounts[i]?.amount,
                };
                discountobjectsendin.push(obj);
                if (k + 1 === selectdiscount?.length) {
                    discount += `${alldiscounts[i].discountname}`;
                } else {
                    discount += `${alldiscounts[i].discountname},`;
                }
            }
        }
    }

    const handleSign = () => {
        setChecked(true);
    };
    const postDataWithinvoice = () => {
        console.log("postdatawithinvoice called");
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
            isWithInvoice: prospectCases.prospectWithInvoice,
            isWithoutInvoice: prospectCases.prospectWithoutInvoice,
        };

        const shipmentName = localStorage.getItem("shipmentName");
        const shipmentCost = localStorage.getItem("shipmentCost");

        api.post(`/api/user/prepaidHandOver`, dataToSend)
            .then(() => {
                let dataToSend = {
                    customerId: paymentScreenDetails.customerid,
                    invoiceType: "Sign Up",
                    totalAmount: paymentScreenDetails.totalamount,
                    additionalCharges: additionalobjectsendin,
                    discount: discountobjectsendin,
                    amountPaid: 0,
                    selectProduct: paymentScreenDetails.billId,
                    invoiceDueDate: dueDate,
                    lateFee: applyLateFee,
                    invoiceOneTimeCharges: oneTimeCharge,

                    invoicePaymentMethod: "Skip Payment",
                    invoiceStatus: "Unpaid",
                    planId: paymentScreenDetails?.plan,
                    planName: planname,
                    planCharges: plancharges,
                    chargingType: "monthly",
                    invoicePaymentMethod: "",
                    printSetting: "Both",
                    isWithInvoice: paymentScreenDetails?.prospectwithinvoice,
                    isWithoutInvoice: paymentScreenDetails?.prospectwithoutinvoice,
                    isInvoice: paymentScreenDetails.prospectwithinvoice,
                    billingPeriod: {
                        from: "onActivation",
                        to: "onActivation",
                    },
                    isShipmentCostDiscounted: checkLabel,
                    shipmentCost: {
                        name: shipmentName,
                        amount: shipmentCost,
                    },
                    userId: parseLoginRes?._id,
                };

                api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend).then(() => {
                    // toast.success("Label created Successfully");

                    setIsLoading(false);
                    setDialog(true);
                    setFromIncomplete(false);
                });
            })
            .catch((error) => {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            });
    };
    function ChangeIsoDateToECT(date) {
        // Given ISO formatted date/time
        const isoDate = date;

        // Convert ISO string to Date object
        const utcDate = new Date(isoDate);

        // Format the date according to Eastern Time Zone (EST/EDT)
        const estTimeString = utcDate.toLocaleString("en-US", {
            timeZone: "America/New_York",
        });
        return estTimeString;
    }
    const movepageToAll = () => {
        if (parentDetails?.accountType === "Postpaid") {
            navigate("/postpaid-allenrollment");
        }
    };

    const handleNavigate = () => {
        navigate("/customer-profile", { state: { selectedId: initiationData?._id } });
        localStorage.setItem("selectedId", JSON.stringify(initiationData?._id));
    };
    const handleBankOfamericaCustomer = () => {
        const dataToSend = {
            csr: csr,
            userId: isPostpaidIncomplete?._id,
            isWithInvoice: isPostpaidIncomplete?.isProspect,
            isWithoutInvoice: isPostpaidIncomplete?.isWithoutProspect,
        };

        api.post(`/api/user/esnAssingment`, dataToSend)
            .then(() => {
                toast.success("Esn Successfully Assigned");

                api.post(`/api/user/prepaidHandOver`, dataToSend)
                    .then((res) => {
                        api.post(`/api/web/order`, { orderNumber: isPostpaidIncomplete?.enrollmentId })
                            .then((response) => {
                                toast.success("Order Placed Successfully");
                                let generate = sessionStorage.getItem("generatelabel");
                                generate = generate === "true"; // convering to boolean

                                if (generate) {
                                    api.post(`/api/web/order/createLable`, { orderId: response.data.data.orderId.toString(), userId: _id, testLabel: true })
                                        .then(() => {
                                            toast.success("Label created Successfully");
                                            setIsLoading(false);

                                            setDialog(true);
                                            setFromIncomplete(false);
                                        })
                                        .catch((err) => {
                                            setIsLoading(false);

                                            setDialog(true);
                                            setFromIncomplete(false);
                                        });
                                } else {
                                    setDialog(true);
                                    setFromIncomplete(false);
                                }
                                console.log("bank of america customer will handle here");
                                console.log("bank of america customer all data", bankOfAmericaCustomerData);

                                const loginRes = localStorage.getItem("userData");
                                const parseLoginRes = JSON.parse(loginRes);

                                let dataToSend = {
                                    transactionId: sessionStorage.getItem("transaction_uuid"),
                                    isAutopay: false,
                                    customerId: isPostpaidIncomplete?._id,
                                    invoiceType: "Sign Up",
                                    totalAmount: isPostpaidIncomplete?.netAmount,
                                    additionalCharges: bankOfAmericaCustomerData?.featureObjectSendInGenerateInvoice,
                                    discount: bankOfAmericaCustomerData.discountObjectSendInGenerateInvoice,
                                    amountPaid: isPostpaidIncomplete?.payingAmount,
                                    selectProduct: bankOfAmericaCustomerData?.allBillConfig._id,
                                    billId: bankOfAmericaCustomerData.allBillConfig?._id,
                                    invoiceDueDate: bankOfAmericaCustomerData?.allBillConfig.dueDate,
                                    isGenerateInvoiceWithParent: isPostpaidIncomplete?.generateWithParent,
                                    lateFee: bankOfAmericaCustomerData?.allBillConfig?.applyLateFee,
                                    invoiceOneTimeCharges: bankOfAmericaCustomerData?.allBillConfig?.oneTimeCharge,
                                    invoiceStatus: parseFloat(isPostpaidIncomplete.netAmount) === parseFloat(isPostpaidIncomplete.payingAmount) ? "Paid" : "Partial",
                                    planId: bankOfAmericaCustomerData?.plan[0]._id,
                                    planName: bankOfAmericaCustomerData?.plan[0].description,
                                    planCharges: bankOfAmericaCustomerData?.plan[0].price,
                                    chargingType: "monthly",
                                    invoicePaymentMethod: "Bank Of America",
                                    printSetting: "Both",
                                    isWithInvoice: true,
                                    isInvoice: true,
                                    userId: parseLoginRes?._id,
                                    billingPeriod: {
                                        from: "onActivation",
                                        to: "onActivation",
                                    },
                                };

                                // Add shipment cost discount if it exists in sessionStorage
                                if (generate) {
                                    console.log("inside here");
                                    let checkLabel = sessionStorage.getItem("checkLabel");
                                    checkLabel = checkLabel === "true"; // convering to boolean
                                    dataToSend.isShipmentCostDiscounted = checkLabel;
                                    dataToSend.shipmentCost = {
                                        name: sessionStorage.getItem("shipmentName"),
                                        amount: sessionStorage.getItem("shipmentCost"),
                                    };
                                }

                                // Initiate API call to generate invoice

                                api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                                    .then((response) => {
                                        // On success, update the payment log

                                        api.post(`/api/web/billing/updatePaymentLog`, {
                                            customerId: isPostpaidIncomplete?._id,
                                            paymentMethod: "Bank Of America Credit Card",
                                            stripeId: dataToSend.paymentId,
                                            action: "success",
                                            reason: "success",
                                            transactionStatus: "succeeded",
                                            paymentStatus: "success",
                                            invoiceNo: response?.data?.data?.invoice,
                                            amount: isPostpaidIncomplete?.payingAmount,
                                            userId: parseLoginRes?._id,
                                        })
                                            .then((res) => {
                                                console.log("Payment log updated successfully");
                                                setIsLoading(false); // Set loading state to false after both API calls
                                            })
                                            .catch((error) => {
                                                console.error("Error updating payment log", error);
                                                setIsLoading(false); // Set loading state to false in case of an error
                                            });
                                    })
                                    .catch((error) => {
                                        console.error("Error generating invoice", error);
                                        setIsLoading(false); // Set loading state to false in case of an error
                                    });
                                sessionStorage.removeItem("Payment Initiation");
                                sessionStorage.removeItem("transaction_uuid");
                                sessionStorage.removeItem("Refrerence Number");
                                sessionStorage.removeItem("checkLabel");
                                sessionStorage.removeItem("shipmentName");
                                sessionStorage.removeItem("shipmentCost");
                                sessionStorage.removeItem("generatelabel");
                            })
                            .catch((err) => {
                                toast.error("Order Placing Failed");
                            });
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data?.msg);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.response?.data?.msg);
            });
    };
    return (
        <div className="preview-screen">
            <ToastContainer />
            <div className="previewmain">
                <div className="enrollmentid">
                    <div>
                        <h1>{parentDetails?.enrollmentId}</h1>
                    </div>
                    <div>
                        <h2> {parentDetails?.createdAt ? new Date(parentDetails.createdAt).toLocaleDateString("en-GB") : "-"}</h2>
                    </div>
                </div>
                <div className="personaldata">
                    <div className="personaldataleft">
                        <div className="name">
                            <h1>Name</h1>
                            <p>
                                {parentDetails?.firstName?.toUpperCase()} {parentDetails?.lastName?.toUpperCase()}{" "}
                            </p>
                        </div>
                        <div className="email">
                            <h1>Email</h1>
                            <p>{parentDetails?.email?.toUpperCase()}</p>
                        </div>
                    </div>
                    <div className="personaldataright">
                        <div className="address">
                            <h1>Address</h1>
                            <div className="flex">
                                <p>{parentDetails?.address1?.toUpperCase() || ""}</p>
                                <p>{`, ${parentDetails?.state?.toUpperCase()}` || " "}</p>
                                <p>{`, ${parentDetails?.city?.toUpperCase()}` || " "}</p>
                                <p>{`, ${parentDetails?.zip?.toUpperCase()}` || " "}</p>
                            </div>
                        </div>
                        <div className="mailingaddress">
                            <h1>Mailing Address</h1>
                            <div className="flex">
                                {parentDetails?.isNotSameServiceAddress ? <p>{parentDetails?.mailingAddress1 ? parentDetails.mailingAddress1.toUpperCase() : "-"}</p> : parentDetails?.isPoBoxAddress ? <p>{parentDetails?.PoBoxAddress ? parentDetails.PoBoxAddress.toUpperCase() : "-"}</p> : "-"}
                                {parentDetails?.isNotSameServiceAddress ? (
                                    <div className="flex">
                                        {parentDetails?.mailingState && <p>{`, ${parentDetails.mailingState.toUpperCase()}`}</p>}
                                        {parentDetails?.mailingCity && <p>{`, ${parentDetails.mailingCity.toUpperCase()}`}</p>}
                                        {parentDetails?.mailingZip && <p>{`, ${parentDetails.mailingZip.toUpperCase()}`}</p>}
                                    </div>
                                ) : parentDetails?.isPoBoxAddress ? (
                                    <div className="flex">
                                        {parentDetails?.poBoxState && <p>{`, ${parentDetails.poBoxState.toUpperCase()}`}</p>}
                                        {parentDetails?.poBoxCity && <p>{`, ${parentDetails.poBoxCity.toUpperCase()}`}</p>}
                                        {parentDetails?.poBoxZip && <p>{`, ${parentDetails.poBoxZip.toUpperCase()}`}</p>}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="infoprivate">
                    <div className="createdby">
                        <h1>Created By</h1>
                        <p>{parseLoginRes?.userName?.toUpperCase() || "-"}</p>
                        {/* <p>{parentDetails?.createdBy?.name?.toUpperCase() || "-"}</p> */}
                    </div>
                    <div className="dob">
                        <h1>DOB</h1>
                        <p> {parentDetails?.DOB ? moment(parentDetails.DOB).format("MM/DD/YYYY") : "/"}</p>
                    </div>
                    <div className="contact">
                        <h1>Contact</h1>
                        <p>{parentDetails?.contact?.toUpperCase() || "-"}</p>
                    </div>
                    <div className="ssn">
                        <h1>SSN</h1>
                        <p>{parentDetails?.SSN?.toUpperCase() || "-"}</p>
                    </div>
                </div>
                <div className="discount">
                    <div className="discountleft">
                        <h1>Discounts</h1>
                        <p>
                            {isPostpaidIncomplete && isPostpaidIncomplete.bankOfamericacustomer
                                ? bankOfAmericaCustomerData.discountstring?.toUpperCase()
                                : isPostpaidIncomplete && !isPostpaidIncomplete.bankOfamericacustomer && Object.keys(isPostpaidIncomplete)?.length > 0 && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0
                                  ? discountInCasePayment?.toUpperCase()
                                  : isPostpaidIncomplete && isPostpaidIncomplete.bankOfamericacustomer
                                    ? ""
                                    : discount?.toUpperCase() || "-"}
                        </p>
                    </div>
                    <div className="additional">
                        <h1>Additional Features</h1>
                        <p>
                            {isPostpaidIncomplete && isPostpaidIncomplete.bankOfamericacustomer
                                ? bankOfAmericaCustomerData.featurestring?.toUpperCase()
                                : isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0
                                  ? additionalFeatureInCasePayment?.toUpperCase()
                                  : additional?.toUpperCase()?.toUpperCase() || "-"}
                        </p>
                    </div>
                </div>
                <div className="productdetail">
                    <div className="detail">
                        <h1>Product:</h1>
                        <p>{isPostpaidIncomplete && isPostpaidIncomplete.bankOfamericacustomer ? `${isPostpaidIncomplete?.inventoryId?.inventoryType?.toUpperCase()}` : `${inventoryImage?.inventoryType?.toUpperCase()}`}</p>
                    </div>
                    <div className="detail">
                        <h1>Make and Model:</h1>
                        <p>
                            {isPostpaidIncomplete && isPostpaidIncomplete.bankOfamericacustomer ? `${isPostpaidIncomplete?.inventoryId?.make?.toUpperCase()}` : `${inventoryImage?.make?.toUpperCase()}`}-
                            {isPostpaidIncomplete && isPostpaidIncomplete.bankOfamericacustomer ? `${isPostpaidIncomplete?.inventoryId?.model?.toUpperCase()}` : `${inventoryImage?.model?.toUpperCase()}`}
                        </p>
                    </div>
                    <div className="detail">
                        <h1>Plan ({isPostpaidIncomplete && isPostpaidIncomplete?.bankOfamericacustomer && bankOfAmericaCustomerData?.plan ? bankOfAmericaCustomerData?.plan[0]?.name : selectedPlan?.name})</h1>
                        <p>{`$${isPostpaidIncomplete && isPostpaidIncomplete?.bankOfamericacustomer && bankOfAmericaCustomerData?.plan ? bankOfAmericaCustomerData?.plan[0]?.price : selectedPlan?.price || "0"}`}</p>
                    </div>
                    <div className="detail">
                        <h1>Due Amount:</h1>
                        {/* <p>
                            $
                            {isPostpaidIncomplete || isPostpaidIncomplete.bankOfamericacustomer
                                ? parseFloat(isPostpaidIncomplete?.netAmount) - parseFloat(isPostpaidIncomplete?.payingAmount) > 0
                                    ? parseFloat(isPostpaidIncomplete?.netAmount) - parseFloat(isPostpaidIncomplete?.payingAmount)
                                    : "0"
                                : paymentScreenDetails?.totalamount - paymentScreenDetails?.paid}
                        </p> */}
                        <p>${isPostpaidIncomplete || isPostpaidIncomplete.bankOfamericacustomer ? parseFloat(isPostpaidIncomplete?.netAmount) - parseFloat(isPostpaidIncomplete?.payingAmount) : paymentScreenDetails?.totalamount - paymentScreenDetails?.paid}</p>
                    </div>
                    <div className="detail">
                        <h1>Amount Paid:</h1>
                        <p>
                            $
                            {isPostpaidIncomplete || isPostpaidIncomplete.bankOfamericacustomer
                                ? isPostpaidIncomplete.payingAmount
                                : isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0
                                  ? amountPaidIncasepayment
                                  : paymentScreenDetails?.paid
                                    ? `$${paymentScreenDetails.paid}`
                                    : "-"}
                        </p>
                    </div>
                    <div className="detail">
                        <h1>Remaining Amount:</h1>
                        {/* <p>
                            $
                            {isPostpaidIncomplete || isPostpaidIncomplete.bankOfamericacustomer
                                ? parseFloat(isPostpaidIncomplete?.netAmount) - parseFloat(isPostpaidIncomplete?.payingAmount) > 0
                                    ? parseFloat(isPostpaidIncomplete?.netAmount) - parseFloat(isPostpaidIncomplete?.payingAmount)
                                    : "0"
                                : paymentScreenDetails?.totalamount - paymentScreenDetails?.paid}
                        </p> */}
                        <p>${isPostpaidIncomplete || isPostpaidIncomplete.bankOfamericacustomer ? parseFloat(isPostpaidIncomplete?.netAmount) - parseFloat(isPostpaidIncomplete?.payingAmount) : paymentScreenDetails?.totalamount - paymentScreenDetails?.paid}</p>
                    </div>
                </div>
                <div className="terms">
                    <Checkbox onChange={(e) => setIsChecked(e.checked)} checked={isChecked} onClick={handleSign} />
                    <p onClick={() => setVisible(true)} style={{ cursor: "pointer", textDecoration: "underline" }} className="view">
                        Terms and Conditions
                    </p>
                </div>
            </div>
            {/* Comment */}
            <div className="previewbtn">
                <Button
                    label="Submit"
                    loading={isLoading}
                    onClick={
                        isPostpaidIncomplete && isPostpaidIncomplete?.bankOfamericacustomer
                            ? handleBankOfamericaCustomer
                            : paymentDone
                              ? postData
                              : isPostpaidIncomplete && isPostpaidIncomplete !== "" && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0
                                ? postData
                                : (paymentDone && parentDetails?.accountType === "Postpaid") || (isPostpaidIncomplete && isPostpaidIncomplete !== "" && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0)
                                  ? PostpaidHandover
                                  : postDataWithinvoice
                    }
                    disabled={!isChecked}
                    className="nextbtn"
                    style={{ width: "16rem", height: "5rem" }}
                />

                <Dialog
                    className="confirmationdialog"
                    headerClassName="confirmationheader"
                    closable={false}
                    visible={visible}
                    style={{
                        width: "98.8rem",
                        height: "78,2rem",
                        borderRadius: "2.5rem",
                        overflow: "hidden",
                        border: "1px solid #C9D0EF",
                        boxShadow: `0px 2px 6px 2px #475CED26,0px 1px 2px 0px #475CED4D`,
                    }}
                >
                    <div className="flex flex-wrap flex-column justify-content-start termsdialog" style={{ padding: "1rem 3.0rem" }}>
                        <div className="flex justify-content-between" style={{ paddingTop: "1rem" }}>
                            <h1>Terms and Conditions</h1>
                            <div onClick={() => setVisible(false)} style={{ cursor: "pointer", marginTop: "-0.6rem" }} className="termsicon">
                                <FalseDialog />
                            </div>
                        </div>
                        <p>Review the terms below to understand your rights and responsibilities before proceeding.</p>
                        <hr style={{ border: "1px solid #EFF2F8", marginTop: "-0.5rem" }} />
                        <div className="mt-3">
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span> {companyName}, Inc agrees to provide the selected equipment and services to the customer at the rates discussed with customer</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>The services provided is pre-paid and the customer agress to pay the monthly bill at the start of each services month.</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>The customer agrees to switch their home phone or cell phone services to {companyName},Inc from their current service provider. </span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>{companyName}, inc will make responsible efforts to ensure that the customer retains their existing phone number. In cases where number portability is not possible, the customer will be notified.</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>If the customer choose not to utilize the selected services and equipment , without encountering any technical issues, the customer agrees to pay the specified monthly bill, including taxes.</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>
                                    The customer can choose any desired date for the monthly bill within the days provided by {companyName}, Inc for the due date. In the event that the customer is unable to make the monthly payment on time, late fees, if applicable, will be applied to the bill.
                                </span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>{companyName}, Inc will proivide customer support to the customer for any service related quries or technical issues</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>By enrolling with {companyName}, Inc. the customer consents to receive communications, including bills, notices and updates, electronically.</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>{companyName}, Inc reserve the rights to terminate the services in the event of non-payment or violation of the terms and conditions.</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>{companyName}, Inc will take reasonable measures to project customer information and maintain data security</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>This ageerement shall be governed by and construed by in accordance with the law of the applicable jurisdiction</span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>
                                    I further consent to receive calls, emails and/or text messages that may deliver auto-dialed or pre-recorder messages from {companyName}, Inc or its duly appointed agent, either using my telephone number assigned by {companyName}, Inc or provided by me here in or
                                    later. i understand this is not a condition of purchase
                                </span>
                            </div>
                            <br />
                            <div className="flex justify-content-start align-items-center dialogdata" style={{ gap: "1rem" }}>
                                <Dot />
                                <span>
                                    I hereby give my informed consent to electronically sign this form and i acknowledge that this electronic signature has the same legal effect as handwritten Signature. I understand that this action My Agreements to the Term and Conditions outlined in this form and
                                    my related documents
                                </span>
                            </div>
                        </div>
                        <br />
                    </div>
                </Dialog>
                <Dialog headerClassName="successdialog" visible={dialog} style={{ width: "68rem", height: "24.8rem", borderRadius: "1.0rem", border: "1px solid #C9D0EF", boxShadow: `0px 2px 6px 2px #475CED26,0px 1px 2px 0px #475CED4D` }} header={null} closable={false} className="custom-dialog">
                    <div className="flex flex-column justify-content-evenly align-items-center" style={{ height: "100%" }}>
                        <div className="flex flex-column justify-content-start" style={{ width: "60rem" }}>
                            <div>
                                <h1 className="successdialogh1">Enrollment Successfully Created!</h1>
                            </div>
                            <div style={{ marginTop: "1.2rem" }}>
                                <span onClick={handleNavigate} className="successdialogspan">
                                    Your Enrollment Number: <span className="enrollno"> {initiationData?.enrollmentId}</span>
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-content-end" style={{ width: "60rem", marginTop: "1.8rem" }}>
                            <Button label="Next" onClick={movepageToAll} className="flex  justify-content-center nextbtn" />
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default Preview;
