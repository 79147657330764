import React, { useEffect, useRef, useState } from "react";
import "./LineInfoMain.css";
import moment from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import { api } from "../../../../../../../utility/AxiosInceptor";
export const CustomerLineInfoMain = ({ cpData, planData }) => {
    console.log("planData ", planData);
    const [overlayContent, setOverlayContent] = useState("");
    const statusOverlayRef = useRef(null);
    const socOverlayRef = useRef(null);
    return (
        <div className="line-info-wrapper">
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>MDN</p>
                <p>{cpData?.phoneNumber || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>SIM/ESN</p>
                <p>{cpData?.esn ?? cpData?.esnId?.esn ?? "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Activation IMEI</p>
                <p>{cpData?.IMEI2 ?? cpData?.esnId?.IMEI2 ?? "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Inventory IMEI</p>
                <p>{cpData?.IMEI && cpData?.IMEI?.length > 0 ? cpData?.IMEI : "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Inventory Type</p>
                <p>{cpData?.plan?.inventoryType || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Make</p>
                <p>{cpData?.plan?.make || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Model</p>
                <p>{cpData?.plan?.deviceModel || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>E-SIM</p>
                <p>{cpData?.ESim ? "YES" : "NO"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Ported MDN</p>
                <p>{cpData?.portedMDN ? "YES" : "NO"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Acc. Type</p>
                <p>{cpData?.accountType || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Plan Name</p>
                <p>{cpData?.currentPlan?.planName ?? "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Plan Details</p>
                <p
                    className="cursor-pointer"
                    onMouseEnter={(e) => {
                        if (cpData?.plan?.description?.length > 23) {
                            setOverlayContent(cpData?.plan?.description);
                            statusOverlayRef.current.show(e);
                        }
                    }}
                    onMouseLeave={() => statusOverlayRef.current.hide()}
                >
                    {cpData?.plan?.description?.length > 60 ? `${cpData.plan.description.slice(0, 60)}...` : cpData?.plan?.description && cpData?.plan?.description !== "" ? cpData?.plan.description : "NIL"}
                </p>
                <OverlayPanel ref={statusOverlayRef}>
                    <div style={{ maxWidth: "42rem", padding: "5px 10px" }}>
                        <p
                            style={{
                                fontWeight: 400,
                                fontSize: "1.4rem",
                                overflow: "hidden",
                                border: "none",
                                margin: "0px",
                                lineHeight: "1.8rem",
                                color: "#72728f",
                            }}
                        >
                            {" "}
                            {overlayContent}
                        </p>
                    </div>
                </OverlayPanel>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Plan Price</p>
                <p>{cpData?.plan?.price || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Plan Activation Date</p>
                <p>{cpData?.activatedAt ? moment(cpData.activatedAt).format("YYYY-MM-DD HH:mm:ss") : "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Carrier Live Status</p>
                <p>{cpData?.serviceStatus || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>OCS Live Status</p>
                <p>{cpData?.ocsStatus || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Talk Balance</p>
                <p>{planData?.talkBalance || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>SMS Balance</p>
                <p>{planData?.textBalance || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Data Balance</p>
                <p>{planData?.dataBalance || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Plan ID</p>
                <p>{cpData?.plan?.planId || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>Plan Expiration Date</p>
                <p>{cpData?.planExpirationDate ? moment(cpData?.planExpirationDate).format("YYYY-MM-DD HH:mm:ss") : "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>SOC</p>
                <p
                    onMouseEnter={(e) => {
                        if (cpData?.socs?.map(String).join(",").length > 60) {
                            setOverlayContent(cpData?.socs?.map(String).join(", "));
                            socOverlayRef.current.show(e);
                        }
                    }}
                    onMouseLeave={() => socOverlayRef.current.hide()}
                >
                    {cpData?.socs && cpData?.socs.length > 0 && cpData?.socs?.map(String).join(",").length > 60 ? cpData?.socs?.map(String).join(",").substring(0, 57) + "..." : cpData?.socs && cpData?.socs.length > 0 ? cpData?.socs?.map(String).join(",") : "NIL"}
                </p>
                <OverlayPanel ref={socOverlayRef}>
                    <div style={{ maxWidth: "48rem", padding: "5px 10px" }}>
                        <p
                            style={{
                                fontWeight: 400,
                                fontSize: "1.4rem",
                                overflow: "hidden",
                                border: "none",
                                margin: "0px",
                                lineHeight: "1.8rem",
                                color: "#72728f",
                            }}
                        >
                            {" "}
                            {overlayContent}
                        </p>
                    </div>
                </OverlayPanel>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>{cpData?.carrier?.name === "AT&T" ? "BAN" : cpData?.carrier?.name === "TMB" ? "PUK 1" : "NIL"}</p>
                <p>{cpData?.BAN || cpData?.PUK1 || "NIL"}</p>
            </div>
            <div className="detail-item flex flex-wrap flex-row justify-content-between align-items-center">
                <p>{cpData?.carrier?.name === "AT&T" ? "FAN" : cpData?.carrier?.name === "TMB" ? "PUK 2" : "NIL"}</p>
                <p>{cpData?.FAN || cpData?.PUK2 || "NIL"}</p>
            </div>
        </div>
    );
};
