import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./AppMenu.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
export const AppMenu = ({ setMobileMenuActive, setSearchByValueClick, setSearchBy, model }) => {
    const location = useLocation();
    const [active, setActive] = useState(false);
    useEffect(() => {
        if (model && model.length > 0 && model[0]?.items) {
            model[0].items.forEach((element, index) => {
                element.subModules.forEach((element2) => {
                    if (element2?.route === location?.pathname) {
                        setCurrentActive(index);
                        setCurrentLocation(element2?.route);
                    }
                });
            });
        }
        if (location.pathname === "/") {
            setCurrentActive();
            setCurrentLocation();
        }
    }, [location, model]);

    let navigate = useNavigate();
    const [currentLocation, setCurrentLocation] = useState();
    const [prevLocation, setPrevLocation] = useState();
    const [currentActive, setCurrentActive] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [nextPath, setNextPath] = useState();
    console.log("prev path", prevLocation);
    console.log("curr location", currentLocation);
    return (
        <div className="layout-menu-container sidebar-wrapper ">
            <div className="w-full main-menu  flex flex-wrap flex-row justify-content-center align-content-start align-items-start" style={{ marginTop: "60px" }}>
                {model[0]?.items?.map((element, index) => {
                    return (
                        <div className="cursor-pointer sidebar-container w-full flex flex-wrap flex-row align-items-center justify-content-between">
                            <div
                                id={`${element.moduleName}`}
                                onClick={() => {
                                    if (currentActive !== index) {
                                        setCurrentActive(index);
                                        setActive(true);
                                        setTimeout(() => {
                                            //     window.scrollTo(0, -20);
                                            var element2 = document.getElementById(element.moduleName);

                                            // Scroll the element into view
                                            element2.scrollIntoView({
                                                behavior: "smooth", // Smooth scroll
                                                block: "center", // Scroll to the top of the element
                                            });
                                            // Adjust by 40px from the top
                                        }, 100);
                                    } else {
                                        setCurrentActive();
                                        setCurrentLocation();
                                    }
                                }}
                                className={
                                    currentActive === index && active
                                        ? "notactivemain  w-full flex flex-wrap flex-row align-items-center justify-content-between"
                                        : currentActive === index
                                          ? "activemenu  w-full flex flex-wrap flex-row align-items-center justify-content-between"
                                          : "main-sidebar-item-label  w-full flex flex-wrap flex-row align-items-center justify-content-between"
                                }
                            >
                                <div className=" main-sidebar-item-container wifull flex flex-wrap flex-row align-items-center justify-content-start">
                                    <div dangerouslySetInnerHTML={{ __html: element?.icon }} />
                                    <span>{element.moduleName}</span>
                                </div>
                                {/* <div>{element.items && <i className=" dropdown-svg pi pi-fw pi-angle-down menuitem-toggle-icon"></i>}</div> */}
                                <div>{<i className=" dropdown-svg pi pi-fw pi-angle-down menuitem-toggle-icon"></i>}</div>
                            </div>
                            {currentActive === index ? (
                                <div className="w-full flex  flex-wrap flex-row justify-content-center" style={{ position: "relative" }}>
                                    {element.subModules?.map((element2) => {
                                        return (
                                            <>
                                                {element2.subModuleName !== "Wifi Calling" &&
                                                    element2.subModuleName !== "Query Network" &&
                                                    element2.subModuleName !== "Query Sim" &&
                                                    element2.subModuleName !== "Imei Validation" &&
                                                    element2.subModuleName !== "Linked Accounts" &&
                                                    element2.subModuleName !== "Add Services" &&
                                                    element2.subModuleName !== "Usage" &&
                                                    element2.subModuleName !== "Files" &&
                                                    element2.subModuleName !== "Billing And Invoice" && (
                                                        <div className="main-dropdown-list w-full flex flex-wrap flex-row align-items-center justify-content-between">
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (window.innerWidth < 978) {
                                                                        setMobileMenuActive(false);
                                                                    }
                                                                    if (currentLocation === "/prepaid-newenrollment" || currentLocation === "/postpaid-newenrollment") {
                                                                        setShowDialog(true);
                                                                        setNextPath(element2?.route);
                                                                    } else {
                                                                        navigate(element2?.route);
                                                                    }
                                                                    setCurrentLocation(element2?.route);
                                                                    setPrevLocation(currentLocation);
                                                                    setActive(false);
                                                                }}
                                                                style={{ gap: "10px" }}
                                                                className="flex flex-wrap flex-row align-items-center justify-content-start"
                                                            >
                                                                <span className={`${currentLocation === element2.route ? "selected-span-sidebar" : "sidebar-dropdown-menu-span"} `}>{element2.subModuleName}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                            </>
                                        );
                                    })}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                })}
            </div>

            <Dialog
                header={null}
                visible={showDialog}
                headerClassName="header-dialog"
                style={{ width: "68rem", height: "26.6rem" }}
                onHide={() => {
                    if (!showDialog) return;
                    setShowDialog(false);
                }}
            >
                <div className="flex w-full h-full flex-column justify-content-evenly align-items-center dialogcontent">
                    <div className="flex flex-column justify-content-center" style={{ width: "60rem" }}>
                        <h1>Are You Sure You Want to Leave?</h1>
                        <p>You are currently on the New Enrollment page. Do you want to leave? This enrollment will be saved as incomplete.</p>
                    </div>
                    <div className="flex justify-content-end" style={{ width: "60rem", gap: "1.6rem" }}>
                        <Button
                            className="backbtn"
                            label="Stay on Page"
                            onClick={() => {
                                if (currentLocation === "/prepaid-newenrollment") {
                                    setShowDialog(false);
                                } else if (currentLocation === "/postpaid-newenrollment") {
                                    setShowDialog(false);
                                } else {
                                    setShowDialog(false);
                                    setCurrentLocation(prevLocation);
                                }
                            }}
                        />
                        <Button
                            className="nextbtn"
                            label="Leave Page"
                            onClick={() => {
                                navigate(nextPath);
                                setShowDialog(false);
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
