import React, { useEffect, useRef, useState } from "react";
import "../css/AddNote.css";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import CommonLoaderBlue from "../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { AddNoteType } from "./AddNoteType";
export const AddNote = ({ setRefreshNoteOnCaseAdded, cpData, setIsAddNote }) => {
    const loginRes = localStorage.getItem("userData");
    const toast = useRef();
    const [noteAdded, setNotesAdded] = useState(0);
    const [isAddNoteTypeActive, setIsAddNoteTypeActive] = useState(false);
    const parseLoginRes = JSON.parse(loginRes);
    const [isAddNoteLoading, setIsAddNoteLoading] = useState(false);
    const [notesType, setAllNotesTypes] = useState([]);
    const [allAgents, setAllAgents] = useState([]);
    const [priorityNotes, setAllPriorityNote] = useState([
        { label: "Priority", value: "" },
        { label: "Highest", value: "highest" },
        { label: "High", value: "high" },
        { label: "Medium", value: "medium" },
        { label: "Low", value: "low" },
        { label: "Lowest", value: "lowest" },
    ]);
    const formik = useFormik({
        validationSchema: Yup.object().shape({
            noteType: Yup.string().required("Field Is Required"),
            note: Yup.string().required("Field Is Required"),
            priority: Yup.string().required("Field Is Required"),
            //AgentName: Yup.string().required("Agent Name is required"),
        }),
        initialValues: {
            noteType: "",
            note: "",
            priority: "low",
            AgentName: "",
            assignTo: "",
            serviceProvider: parseLoginRes?.company,
            userId: parseLoginRes?._id,
            customerId: cpData?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            setIsAddNoteLoading(true);
            const filteredValues = { ...values };
            if (!filteredValues.assignTo) {
                delete filteredValues.assignTo;
            }
            try {
                const response = await api.post(`/api/web/notes/addnotifcationNote`, filteredValues);
                toast.current.show({ severity: "success", summary: "Info", detail: response?.data?.msg });
                setIsAddNoteLoading(false);
                setNotesAdded((prev) => prev + 1);
                resetForm();
            } catch (error) {
                setIsAddNoteLoading(false);
                toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg });
            }
            //setRefreshBell((prev) => !prev);
        },
    });
    const getNotesType = async () => {
        try {
            const res = await api.get(`/api/noteType/all?serviceProvider=${parseLoginRes?.company}`);
            setAllNotesTypes(res?.data?.data || []);
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Error", detail: error?.response?.data?.msg });
        }
    };
    const fetchAgents = async () => {
        try {
            const response = await api.get(`/api/web/user/all?company=${parseLoginRes.company}&limit=4000`);
            const users = response?.data?.data?.users;
            const agentNames = users.map((user) => ({ label: user.name, value: user._id }));
            setAllAgents(agentNames);
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.current.show({ severity: "error", summary: "Error", detail: error?.response?.data?.msg });
            }
        }
    };
    const [refreshNoteType, setRefreshNoteType] = useState(false);
    useEffect(() => {
        fetchAgents();
        return () => {
            setRefreshNoteOnCaseAdded((prev) => !prev);
        };
    }, []);
    useEffect(() => {
        getNotesType();
    }, [refreshNoteType]);
    return (
        <form onSubmit={formik.handleSubmit} className="addNote-main">
            <div
                onClick={() => {
                    setIsAddNote((prev) => !prev);
                }}
                className="goback-addNote"
            >
                <i className="pi pi-angle-left" />
            </div>
            <div className="header-add-note">
                <h1 className="header-top-header">Notes</h1>
                <p className="header-details-header">Add, view, and manage agent notes for a complete record of customer interactions.</p>
            </div>
            {!isAddNoteTypeActive && (
                <div
                    onClick={() => {
                        setIsAddNoteTypeActive((prev) => !prev);
                    }}
                    className="add-note-type-field"
                >
                    <p>Add Note Type</p>
                </div>
            )}
            {isAddNoteTypeActive && <AddNoteType toast={toast} setRefreshNoteType={setRefreshNoteType} setIsAddNoteTypeActive={setIsAddNoteTypeActive} />}
            <div className="main-addNote-field">
                <div className="dropdown-container-add-note">
                    <div className="flex flex-wrap w-full flex-row justify-content-between align-items-center">
                        <label>Notes Type</label>
                        <label className="p-error">{formik.touched.noteType && formik.errors.noteType ? formik.errors.noteType : ""}</label>
                    </div>
                    <Dropdown
                        options={notesType}
                        name="noteType"
                        onChange={formik.handleChange}
                        optionLabel="noteType"
                        value={formik.values.noteType}
                        optionValue="noteType"
                        // options={suspendStatusOptions}
                        className={`dropdown-main ${formik.touched.noteType && formik.errors.noteType ? "p-invalid" : ""}`}
                        placeholder="Select Note Type"
                    />
                </div>
                <div className="dropdown-container-add-note">
                    <div className="flex flex-wrap w-full flex-row justify-content-between align-items-center">
                        <label>Assign To</label>
                        <label className="p-error">{formik.touched.assignTo && formik.errors.assignTo ? formik.errors.assignTo : ""}</label>
                    </div>
                    <Dropdown
                        name="assignTo"
                        options={allAgents}
                        //value={statusTo}
                        onChange={formik.handleChange}
                        value={formik.values.assignTo}
                        optionLabel="label"
                        optionValue="value"
                        // options={suspendStatusOptions}
                        className={`dropdown-main ${formik.touched.assignTo && formik.errors.assignTo ? "p-invalid" : ""}`}
                        placeholder="Assign To"
                    />
                </div>
                <div className="dropdown-container-add-note">
                    <div className="flex flex-wrap w-full flex-row justify-content-between align-items-center">
                        <label>Priority Type</label>
                        <label className="p-error">{formik.touched.priority && formik.errors.priority ? formik.errors.priority : ""}</label>
                    </div>
                    <Dropdown
                        // value={statusTo}
                        name="priority"
                        value={formik.values.priority}
                        onChange={formik.handleChange}
                        options={priorityNotes}
                        className={`dropdown-main ${formik.touched.priority && formik.errors.priority ? "p-invalid" : ""}`}
                        placeholder="Priority Type"
                    />
                </div>
            </div>
            <div className="note-error-field w-full flex flex-wrap flex-row justify-content-end ">
                <label className="p-error">{formik.touched.note && formik.errors.note ? formik.errors.note : ""}</label>
            </div>
            <InputTextarea autoResize value={formik.values.note} onChange={formik.handleChange} name="note" placeholder="Note Description" className={`main-note-description ${formik.touched.note && formik.errors.note ? "p-invalid" : ""}`} />
            <div className="flex flex-wrap flex-row justify-content-end align-items-center">
                <Button
                    type="submit"
                    disabled={isAddNoteLoading}
                    className="addnotesubmitbutton"
                    icon={isAddNoteLoading ? <CommonLoaderBlue customSize={14} /> : ""}
                    label={isAddNoteLoading ? "" : "Add Note"}
                    //    icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                />
            </div>
            <Toast ref={toast} />
        </form>
    );
};
