import React from "react";
import "./css/NotFound.css";
import { DataNotFounfInvoicePaymentLogs, InvoiceNotFoundIcon } from "../assets/Assets";
export default function NotFoundData({ isCarrierTmb, currentNoFoundData }) {
    return (
        <div className="main-notfounddata">
            <div className="icon-main">{currentNoFoundData === "invoice" || currentNoFoundData === "childaccount" ? <InvoiceNotFoundIcon /> : currentNoFoundData === "payment-logs" || currentNoFoundData === "files" || currentNoFoundData === "usage" ? <DataNotFounfInvoicePaymentLogs /> : ""}</div>
            <p className="main-header-tag">{currentNoFoundData === "invoice" ? "No Invoice Found" : currentNoFoundData === "payment-logs" ? "No Payment Logs Found" : ""}</p>
            <p className="main-description-tag">
                {currentNoFoundData === "invoice"
                    ? "Once invoices are generated, they will be displayed here."
                    : currentNoFoundData === "payment-logs"
                      ? "It looks like your customer payment log is currently empty. Once payments are recorded, they will appear here."
                      : currentNoFoundData === "usage" && !isCarrierTmb
                        ? "Once the customer starts using the service, usage details for Data, SMS, and Voice will appear here."
                        : currentNoFoundData === "usage" && isCarrierTmb
                          ? "This Customer Carrier is other than AT&T Usage is available for AT&T Customers "
                          : currentNoFoundData === "childaccount"
                            ? "Once Child Accounts are generated, they will be displayed here."
                            : currentNoFoundData === "files"
                              ? "Once Files are uploaded, they will be displayed here."
                              : ""}
            </p>
        </div>
    );
}
