import { api } from "../../../../../../utility/AxiosInceptor";
import { InputText } from "primereact/inputtext";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ShowAdjustAmount = ({ setSelectManage, singleInvoiceData, setShowAdjustAmount, setRefresh, userDetails }) => {
    const [isLoading, setIsLoading] = useState(false);
    const userData = localStorage.getItem("userData");
    const parseuserData = userData ? JSON.parse(userData) : {};

    const [showDialog, setShowDialog] = useState(false);
    const validationSchema = Yup.object().shape({
        reason: Yup.string().required("This is Required"),
        discountedAmount: Yup.string().required("This is Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            reason: "",
            discountedAmount: "",
        },
        onSubmit: async () => {
            const rmSpace = formik.values.reason.trim();
            if (rmSpace.length > 1) {
                setShowDialog(true);
            } else {
                toast.error("Please Enter Valid Reason");
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleYesClick = async () => {
        setIsLoading(true);
        const data = {
            invoiceId: singleInvoiceData?._id,
            discountedAmount: formik.values.discountedAmount,
            customerId: userDetails?._id,
            userId: parseuserData?._id,
            reason: formik.values.reason,
        };
        try {
            const resp = await api.post(`/api/web/invoices/adjustDiscount`, data);
            if (resp?.status === 200 || resp?.status === 201) {
                toast.success(resp?.data?.msg);
                setIsLoading(false);
                setTimeout(() => {
                    setSelectManage("");
                    setShowDialog(false);
                    setShowAdjustAmount(false);
                    setRefresh(true);
                }, 2000);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
            setTimeout(() => {
                setShowDialog(false);
            }, 2000);
        }
        setIsLoading(false);
    };
    const handleNoClick = () => {
        setShowDialog(false);
        setShowAdjustAmount(false);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <div className="invoiceNum">
                        <p>
                            Invoice NO <span className="inovice-cln">:</span>{" "}
                        </p>
                        <p className="ml-5">{singleInvoiceData?.invoiceNo}</p>
                    </div>
                    <div className="invoiceNum">
                        <p>Total Amount Due : </p>
                        <p className="ml-5">{`$${parseFloat(singleInvoiceData?.dueAmount).toFixed(2)}`}</p>
                    </div>
                    <div className="reason">
                        <p>
                            Adjust Amount <span className="ml-5">:</span>
                        </p>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("discountedAmount") }, " w-21rem ")} placeholder="Enter Amount" value={formik.values.discountedAmount} id="discountedAmount" onChange={formik.handleChange} />
                        {getFormErrorMessage("discountedAmount")}
                    </div>
                    <div className="reason">
                        <p>
                            Reason <span className="reason-cln">:</span>
                        </p>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("reason") }, " w-21rem reason_text")} placeholder="Enter Reason" value={formik.values.reason} id="reason" onChange={formik.handleChange} />
                        {getFormErrorMessage("reason")}
                    </div>
                    <div className="void-btn mt-2">
                        <Button label="Adjust Amount" type="submit" className="btn-p" />
                    </div>
                </div>
            </form>
            <Dialog
                header="Warning"
                visible={showDialog}
                style={{ width: "30vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleYesClick} disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => setShowDialog(false)}
            >
                <p>This action will adjust the invoice to the new amount. Are you sure you want to proceed? </p>
            </Dialog>
        </>
    );
};
export default ShowAdjustAmount;
