import { api } from "../../../../utility/AxiosInceptor";
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/create_role.css";
import { Toast } from "primereact/toast";
import "react-toastify/dist/ReactToastify.css";
import CommonLoaderBlue from "../../../components/Loader/LoaderBlue";
const CreateRole = ({ setRefreshManageUser, setUserScreen, roledata, isEdit }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    let toast = useRef(null);
    const [rolePermissions, setRolePermissions] = useState([]);
    const location = useLocation();
    const { rowData } = location.state || {};
    const [moduleData, setModuleData] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [isLoadingFormSubmit, setIsLoadingFormSubmit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    useEffect(() => {
        if (isEdit) {
            let arr = [];
            if (roledata && roledata.permissions) {
                roledata.permissions.forEach((item) => {
                    if (item.module && item.module.subModules) {
                        let obj = {
                            module: item.module._id,
                            subModules: item.module.subModules.map((item2) => ({
                                submodule: item2?._id,
                                categories: [],
                            })),
                        };
                        arr.push(obj);
                    }
                });
            }

            setPermissionCompleteObjectArray(arr);
        }
    }, []);
    // Validation Schema
    const validationSchema = Yup.object().shape({
        role: Yup.string().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            role: roledata?.role,
            description: "",
        },
        onSubmit: async () => {
            setIsLoadingFormSubmit(true);
            if (!isEdit) {
                const data = {
                    serviceProvider: parseLoginRes?.company,
                    permissions: permissionCompleteObjectArray,
                    role: formik.values.role,
                    createdBy: parseLoginRes?._id,
                };

                api.post(`/api/web/role`, data)
                    .then((response) => {
                        toast.current.show({ severity: "success", summary: "Info", detail: "Role Added Successfully" });
                        formik.resetForm();
                        setIsLoadingFormSubmit(false);
                    })
                    .catch((error) => {
                        setErrorMessage(error?.response?.data?.msg);

                        toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg });
                        setIsLoadingFormSubmit(false);
                    });
            } else {
                const data = {
                    roleId: roledata._id,
                    permissions: permissionCompleteObjectArray,
                };
                const data2 = {
                    roleId: roledata._id,
                    role: formik.values.role,
                    description: "",
                };
                api.patch(`/api/web/role`, data2)
                    .then((res) => {})
                    .catch((err) => {});
                api.patch(`/api/web/role/permissions`, data)
                    .then((response) => {
                        toast.current.show({ severity: "success", summary: "Info", detail: "Role Permission Updated Successfully" });
                        if (roledata.role === parseLoginRes.role.role) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            setIsLoadingFormSubmit(false);
                        }
                    })
                    .catch((error) => {
                        setErrorMessage(error?.response?.data?.msg);

                        toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg });
                        setIsLoadingFormSubmit(false);
                    });
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const [isModuleFetched, setIsModuleFetched] = useState(false);
    const getModules = async () => {
        try {
            const res = await api.get(`/api/web/module`);
            let module = res?.data?.data.map((item) => {
                if (isEdit) {
                    return { ...item, show: true };
                } else {
                    return { ...item, show: false };
                }
            });
            setModuleData(module || []);
            setTimeout(() => {
                setIsLoading(false);
                setTimeout(() => {
                    setIsModuleFetched(true);
                }, 200);
            }, 200);
        } catch (error) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getModules();
    }, []);
    function ShowHidePermissions(index, module, moduleId) {
        const newmoduledataobject = moduleData;
        const moduleIndex = newmoduledataobject.findIndex((moduleinside) => moduleinside._id === moduleId);
        if (newmoduledataobject[moduleIndex].show) {
            newmoduledataobject[moduleIndex].show = false;
            setModuleData(newmoduledataobject);
            setRefresh((prev) => !prev);
        } else {
            newmoduledataobject.map((item) => {
                item.show = false;
            });
            newmoduledataobject[moduleIndex].show = true;
            setModuleData(newmoduledataobject);
            setRefresh((prev) => !prev);
        }
        setTimeout(() => {
            var element = document.getElementById(`${module.name}${index}`);
            console.log(element);
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }, 600);
    }
    const [permissionCompleteObjectArray, setPermissionCompleteObjectArray] = useState([]);
    const handleModuleCheckboxChange = (moduleId) => {
        const moduleIndex = permissionCompleteObjectArray.findIndex((permissionmodule) => permissionmodule.module === moduleId);
        let copyPermissionObject = permissionCompleteObjectArray;
        const moduleDataIndex = moduleData.findIndex((item) => item._id === moduleId);
        if (moduleIndex !== -1 && copyPermissionObject[moduleIndex].subModules.length === moduleData[moduleDataIndex].submodule.length) {
            let arrayofpermissions = copyPermissionObject.filter((item) => item.module !== moduleId);
            setPermissionCompleteObjectArray(arrayofpermissions);
            setRefresh((prev) => !prev);
        } else if (moduleIndex !== -1 && copyPermissionObject[moduleIndex].subModules.length !== moduleData[moduleDataIndex].submodule.length) {
            let arrayWithoutcurrentobj = copyPermissionObject.filter((item) => item.module !== moduleId);
            const objecttopush = { module: moduleId };
            let submodulesarray = [];
            const indexOfmoduleinmoduledata = moduleData.findIndex((item) => item._id === moduleId);
            moduleData[indexOfmoduleinmoduledata].submodule.map((item) => {
                let obj = {
                    submodule: item._id,
                    categories: [],
                };
                submodulesarray.push(obj);
            });
            objecttopush.subModules = submodulesarray;

            setPermissionCompleteObjectArray((prev) => [...arrayWithoutcurrentobj, objecttopush]);
        } else {
            if (moduleIndex === -1) {
                const objecttopush = { module: moduleId };
                let submodulesarray = [];
                const indexOfmoduleinmoduledata = moduleData.findIndex((item) => item._id === moduleId);
                moduleData[indexOfmoduleinmoduledata].submodule.map((item) => {
                    let obj = {
                        submodule: item._id,
                        categories: [],
                    };
                    submodulesarray.push(obj);
                });
                objecttopush.subModules = submodulesarray;
                setPermissionCompleteObjectArray((prev) => [...prev, objecttopush]);
            }
        }
    };

    const handleSubmoduleCheckboxChange = (module, submoduleId) => {
        const moduleIndex = permissionCompleteObjectArray.findIndex((item) => item?.module === module._id);
        if (moduleIndex !== -1) {
            const currentPermissions = permissionCompleteObjectArray;
            if (currentPermissions[moduleIndex].subModules && currentPermissions[moduleIndex].subModules?.some((item) => item.submodule === submoduleId)) {
                const submodulearray = currentPermissions[moduleIndex].subModules?.filter((item) => item.submodule !== submoduleId);
                currentPermissions[moduleIndex].subModules = submodulearray;
            } else {
                if (currentPermissions[moduleIndex].subModules && currentPermissions[moduleIndex].subModules.length > 0) {
                    currentPermissions[moduleIndex].subModules.push({ submodule: submoduleId, categories: [] });
                } else {
                    currentPermissions[moduleIndex].subModules = [];
                    currentPermissions[moduleIndex].subModules.push({ submodule: submoduleId, categories: [] });
                }
            }
            setPermissionCompleteObjectArray(currentPermissions);
            setRefresh((prev) => !prev);
        } else {
            const currentPermissions = permissionCompleteObjectArray;
            currentPermissions.push({
                module: module._id,
            });
            const moduleIndex = currentPermissions.findIndex((item) => item?.module === module._id);
            if (currentPermissions[moduleIndex].subModules && currentPermissions[moduleIndex].subModules?.some((item) => item.submodule === submoduleId)) {
                const submodulearray = currentPermissions[moduleIndex].subModules?.filter((item) => item.submodule !== submoduleId);
                currentPermissions[moduleIndex].subModules = submodulearray;
            } else {
                if (currentPermissions[moduleIndex].subModules && currentPermissions[moduleIndex].subModules.length > 0) {
                    currentPermissions[moduleIndex].subModules.push({ submodule: submoduleId, categories: [] });
                } else {
                    currentPermissions[moduleIndex].subModules = [];
                    currentPermissions[moduleIndex].subModules.push({ submodule: submoduleId, categories: [] });
                }
            }
            setPermissionCompleteObjectArray(currentPermissions);
            setRefresh((prev) => !prev);
        }
    };
    const getPermissionsByRoleId = async () => {
        try {
            const res = await api.get(`/api/web/role/roleDetails?roleId=${rowData?.role?._id}`);
            if (res?.status === 200 || res?.status === 201) {
                setRolePermissions(res?.data?.data?.permissions);
            }
        } catch (error) {}
    };
    useEffect(() => {
        if (rowData) {
            getPermissionsByRoleId();
        }
    }, [rowData]);

    function calculateSubmoduleName(module) {
        let str = "";
        module.submodule.forEach((item, index) => {
            //  console.log(item);
            if (index < 3) {
                if (index === 2) {
                    str += item.name + "...";
                } else {
                    str += item.name + " ,";
                }
            }
        });

        return str;
    }
    function calculateSubmoduleNameCaseLessthan3(module) {
        let str = "";
        if (module.submodule.length === 3) {
            module.submodule.forEach((item, index) => {
                //  console.log(item);

                if (index === 2) {
                    str += item.name;
                } else {
                    str += item.name + " ,";
                }
            });
        } else if (module.submodule.length === 2) {
            module.submodule.forEach((item, index) => {
                //  console.log(item);

                if (index === 1) {
                    str += item.name;
                } else {
                    str += item.name + " ,";
                }
            });
        } else {
            module.submodule.forEach((item, index) => {
                //  console.log(item);

                str += item.name;
            });
        }
        //console.log(str);
        return str;
    }

    return (
        <div className="main-new-role">
            {isEdit && (
                <i
                    className="go-back pi pi-angle-left "
                    onClick={() => {
                        if (!isLoadingFormSubmit) {
                            if (location.pathname === "/createrole") {
                                setRefreshManageUser((prev) => !prev);
                                navigate("/manage-user");
                            } else {
                                setRefreshManageUser((prev) => !prev);
                                setUserScreen(false);
                            }
                        }
                    }}
                    style={{ cursor: "pointer" }}
                />
            )}
            <h3 className="header ">{isEdit ? "Update Role Permissions" : "Add New Role"}</h3>
            <div className="addrole-main-container">
                <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                    <label>Role</label>
                    <p>{getFormErrorMessage("role")}</p>
                </div>
                <InputText
                    id="role"
                    name="role"
                    placeholder="Enter Role Name"
                    value={formik.values.role ? formik.values.role : ""}
                    onChange={formik.handleChange}
                    className={`${formik.touched.role && formik.errors.role ? "emailinputerr" : "role_input"} Input__Round  w-full`}
                    keyfilter={/^[A-Za-z\s]+$/}
                    maxLength={30}
                />
            </div>

            <Toast ref={toast} />
            <div className="permission-module-container">
                {isLoading ? (
                    <div style={{ marginTop: "90px" }} className="w-full  flex flex-wrap flex-row justify-content-center">
                        <CommonLoaderBlue sizehere={24} />
                    </div>
                ) : moduleData.length > 0 ? (
                    moduleData.map((module, index) => {
                        let isChecked = false;
                        const permissionCompleteIndex = permissionCompleteObjectArray.findIndex((permissionobj) => permissionobj.module === module._id);
                        if (permissionCompleteObjectArray[permissionCompleteIndex]?.subModules?.length === module?.submodule?.length) {
                            isChecked = true;
                        }
                        return (
                            <ul key={index} className="main-module">
                                <li
                                    onClick={() => {
                                        ShowHidePermissions(index, module, module._id);
                                    }}
                                    id={`${module.name}${index}`}
                                    className="w-full main-module-list-item cursor-pointer flex flex-wrap flex-row justify-content-between"
                                >
                                    <div>
                                        <p>{module.name}</p>
                                        <p>{module.submodule.length > 3 ? calculateSubmoduleName(module) : calculateSubmoduleNameCaseLessthan3(module)}</p>
                                    </div>
                                    <InputSwitch
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        type="checkbox"
                                        id={`${module._id}`}
                                        checked={isChecked}
                                        onChange={() => {
                                            handleModuleCheckboxChange(module._id);
                                            setTimeout(() => {
                                                var element = document.getElementById(`${module.name}${index}`);
                                                console.log(element);
                                                // Scroll the element into view
                                                element.scrollIntoView({
                                                    behavior: "smooth", // Smooth scroll
                                                    block: "center", // Scroll to the top of the element
                                                });
                                                // Adjust by 40px from the top
                                            }, 600);
                                        }}
                                    />
                                </li>
                                {module.show &&
                                    module.submodule.map((submodule) => (
                                        <ul key={module._id || index} className="sub-module">
                                            <li className="w-full sub-module-list-item flex flex-wrap flex-row justify-content-between">
                                                <p>{submodule.name}</p>
                                                <InputSwitch
                                                    style={{ cursor: !(isCreate || isManage) ? "not-allowed" : "pointer" }}
                                                    type="checkbox"
                                                    checked={permissionCompleteObjectArray.some((topmodule) => topmodule.module === module._id && topmodule.subModules?.some((submoduleinside) => submoduleinside.submodule === submodule._id))}
                                                    onChange={() => handleSubmoduleCheckboxChange(module, submodule._id)}
                                                />
                                            </li>
                                        </ul>
                                    ))}
                            </ul>
                        );
                    })
                ) : (
                    <p className="no-datato-show">No Data To Show</p>
                )}
            </div>
            {isModuleFetched && (
                <div className="flex actions-button w-full flex-wrap flex-row justify-content-end ">
                    <button
                        onClick={() => {
                            if (location.pathname === "/createrole") {
                                navigate("/manage-user");
                            } else {
                                setUserScreen(false);
                            }
                        }}
                    >
                        Cancel
                    </button>
                    <button onClick={formik.handleSubmit}>
                        {isLoadingFormSubmit ? (
                            <div className="flex flex-wrap flex-row justify-content-center w-full">
                                <CommonLoaderBlue color={"white"} sizehere={14} />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};
export default CreateRole;
