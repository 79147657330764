import { api } from "../../../../utility/AxiosInceptor";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Card } from "primereact/card";
import { ContinuousColorLegend } from "react-vis";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CreateUser = ({ setShowAddUser, setRefresh }) => {
    const [allRoles, setAllRoles] = useState([]);
    const [allDepartment, setAllDepartment] = useState([]);
    const [allReporting, setAllReporting] = useState([]);
    const [allState, setAllState] = useState([]);
    const [allCity, setAllCity] = useState([]);
    const [checkDeprt, setCheckDeprt] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // Get user data from ls
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    // Validation Schema
    const validationSchema = Yup.object().shape({
        role: Yup.string().required("Required"),
        name: Yup.string().required("Incorrect Name"),
        email: Yup.string().email("Incorrect Email").required("Email is Required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            role: "",
            name: "",
            email: "",
        },
        onSubmit: async (values) => {
            // Prepare the data to send to the server
            const dataToSend = {
                company: parseLoginRes?.company,
                createdBy: parseLoginRes?._id,
                roleId: formik.values.role,
                name: values.name,
                email: values.email,
            };

            // Send the data to the server using Axios POST request

            api.post(`/api/web/user`, dataToSend)
                .then((response) => {
                    toast.success("successfully Added. Please Check Your Email For Password");
                    // navigate("/manage-user");
                    setShowAddUser(false);
                    setRefresh((prev) => !prev);
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.msg);
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        const getRoles = async () => {
            try {
                const res = await api.get(`/api/web/role/all?serviceProvider=${parseLoginRes?.company}`);
                setAllRoles(res?.data?.data || []);
            } catch (error) {
                toast.error(`Error fetching roles : ${error?.response?.data?.msg}`);
            }
        };
        getRoles();
    }, []);

    // useEffect(() => {
    //     if (formik.values.role) {
    //         const roleId = formik.values.role;
    //         const getReportingTo = async () => {
    //             try {
    //                 const res = await api.get(`/api/web/user/getReportingTo?roleId=${roleId}`);
    //                 setAllReporting(res?.data?.result || []);
    //             } catch (error) {
    //                 toast.error(`Error fetching users : ${error?.response?.data?.msg}`);
    //             }
    //         };
    //         getReportingTo();
    //     }
    // }, [formik.values.role]);

    // useEffect(() => {
    //     const getStates = async () => {
    //         try {
    //             const res = await api.get(`/api/zipCode/getAllStates`);
    //             setAllState(res?.data?.data || []);
    //         } catch (error) {
    //             toast.error(`${error?.response?.data?.msg}`);
    //         }
    //     };
    //     getStates();
    // }, []);

    useEffect(() => {
        if (formik.values.state) {
            const selectedState = formik.values.state;
            const getCities = async () => {
                try {
                    const res = await api.get(`/api/zipCode/getcitiesByState?state=${selectedState}`);
                    setAllCity(res?.data?.data || []);
                } catch (error) {
                    toast.error(`${error?.response?.data?.msg}`);
                }
            };
            getCities();
        }
    }, [formik.values.state]);

    return (
        <form onSubmit={formik.handleSubmit} className="edituserdialogmain">
            <div className="edituserdialog">
                <div className="edituserform">
                    <div className="edituserhead">
                        <h1>Add User</h1>
                        <p className="para">Enter the full name, email, and role for the new user.</p>
                    </div>
                    <div className="flex flex-column" style={{ gap: "1.2rem", marginTop: "2.4rem" }}>
                        <div>
                            <div className="flex justify-content-between w-full">
                                <label htmlFor="name" className="labeltext ">
                                    Full Name
                                </label>
                                {formik.touched.name && formik.errors.name && <span className="p-error">{formik.errors.name}</span>}
                            </div>
                            <InputText
                                className={`w-full  ${formik.touched.name && formik.errors.name ? "input_texterror" : "input_text"}`}
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                keyfilter={/^[A-Za-z\s]+$/}
                                placeholder="Eleanor Pena"
                            />
                        </div>
                        <div>
                            <div className="flex justify-content-between w-full">
                                <label htmlFor="email" className="labeltext ">
                                    Email
                                </label>
                                {formik.touched.email && formik.errors.email && <span className="p-error">{formik.errors.email}</span>}
                            </div>
                            <InputText
                                className={`w-full  ${formik.touched.email && formik.errors.email ? "input_texterror" : "input_text"}`}
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="email"
                                keyfilter={/^[a-zA-Z0-9_.@]*$/}
                                placeholder="nathan.roberts@example.com"
                            />
                        </div>

                        <div>
                            <div className="flex justify-content-between w-full">
                                <label htmlFor="email" className="labeltext ">
                                    Role
                                </label>
                                {formik.touched.role && formik.errors.role && <span className="p-error">{formik.errors.role}</span>}
                            </div>
                            <Dropdown
                                className={`w-full  ${formik.touched.role && formik.errors.role ? "input_texterror" : "input_text"}`}
                                id="role"
                                options={allRoles}
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                optionLabel="role"
                                optionValue="_id"
                                keyfilter={/^[A-Za-z\s]+$/}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex  justify-content-end" style={{ height: "5rem", width: "63.6rem", marginTop: "4.8rem", gap: "1.6rem" }}>
                    <Button className=" backbtn" type="button" label="Cancel" onClick={() => setShowAddUser(false)} />
                    <Button type="submit" className="submitbtn" label="Submit" loading={loading} />
                </div>
            </div>
        </form>
    );
};

export default CreateUser;
