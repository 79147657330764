import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../../../../../utility/AxiosInceptor";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import Axios from "axios";
import "./service_availability.css";
import { BillingEmpty, Error } from "../../../../../../../utility";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function InitiateEnrollment({ setParentDetails, setInitiationData, parentDetails, setPersonalInfoActive, setInitiateActive, billingModelSelected, setCurrentBillingModel, setBillingModelSelected, setZipVerified }) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // const [isCreate, setIsCreate] = useState(false);
    const [pwgApiCheck, setPwgApiCheck] = useState("pending");
    const [pwgApiCheckFound, setPwgApiCheckFound] = useState(false);
    const [pwgDbCheck, setPwgDbCheck] = useState("pending");
    const [pwgDbCheckFound, setPwgDbCheckFound] = useState(false);
    const [uspsCheck, setUspsCheck] = useState("pending");
    const [uspsCheckFound, setUspsCheckFound] = useState(false);
    const [showCheckCoverage, setShowCheckCoverage] = useState(false);
    // const [isManage, setIsManage] = useState(false);
    const [showZipScreen, setShowZipScreen] = useState(false);
    const [data, setData] = useState(false);
    const role = JSON.parse(localStorage.getItem("userData"));
    const location = useLocation();
    const currentPath = location?.pathname;
    const navigate = useNavigate();
    // const actionBasedChecks = () => {
    //     const loginPerms = localStorage.getItem("permissions");
    //     const parsedLoginPerms = JSON.parse(loginPerms);
    //     const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
    //     setIsCreate(isCreate);

    //     const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
    //     setIsManage(isManage);
    // };

    useEffect(() => {
        //actionBasedChecks();

        api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                let billingmodels = res?.data?.data;
                for (let i = 0; i < billingmodels.length; i++) {
                    if (billingmodels[i].billingModel === "PREPAID") {
                        setCurrentBillingModel(billingmodels[i].billingModel);
                        setBillingModelSelected(billingmodels[i]._id);
                    } else if (billingmodels[i].billingModel === "POSTPAID") {
                        setCurrentBillingModel(billingmodels[i].billingModel);
                        setBillingModelSelected(billingmodels[i]._id);
                    }
                }
            })
            .catch((err) => {});
    }, []);

    // Get user data from localStorage
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const validationSchema = Yup.object().shape({
        zipCode: Yup.string().required("Please enter Zip code"),
    });
    useEffect(() => {
        api.get(`api/web/billing/getConfigurationByBillingModel?billingModel=POSTPAID&serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                const fetchedData = res?.data;
                setData(false);
            })
            .catch((err) => {
                console.log("API Error:", err);
                setData(true);
            });
    }, []);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            zipCode: "",
        },
        onSubmit: async (values, actions) => {
            const serviceProvider = parseLoginRes?.company;
            const department = parseLoginRes?.department;
            const csr = parseLoginRes?._id;
            const dataToSend = { serviceProvider, csr, department, ...values, accountType: "Postpaid" };
            setIsLoading(true);
            setShowCheckCoverage(true);

            api.post(`/api/user/PWGverifyZip`, dataToSend)
                .then((res) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(true);
                    setParentDetails({
                        ...res?.data?.data,
                        accountType: "Postpaid",
                    });
                    setInitiationData({
                        ...res?.data?.data,
                        accountType: "Postpaid",
                    });

                    api.post(`/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);

                            api.post(`/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setInitiateActive(false);
                                        setPersonalInfoActive(true);
                                    }, 500);
                                    setZipVerified(true); // Only set to true if this API succeeds
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                    // Do not set zipVerified here
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);

                            api.post(`/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setTimeout(() => {
                                        setInitiateActive(false);
                                        setPersonalInfoActive(true);
                                    }, 500);
                                    setZipVerified(true); // Only set to true if this API succeeds
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                    // Do not set zipVerified here
                                });
                        });
                })
                .catch((err) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(false);

                    api.post(`/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);

                            api.post(`/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setInitiateActive(false);
                                        setPersonalInfoActive(true);
                                    }, 500);
                                    setZipVerified(true); // Only set to true if this API succeeds
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                    // Do not set zipVerified here
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);

                            api.post(`/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setInitiateActive(false);
                                        setPersonalInfoActive(true);
                                    }, 500);
                                    setZipVerified(true); // Only set to true if this API succeeds
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                    // If all three APIs fail, set zipVerified to false
                                    setZipVerified(false);
                                });
                        });
                });
        },
    });

    return (
        <>
            <div className="flex flex-column  personalinfomain">
                <div className="justify-content-start ">
                    <div className=" personalInfo" style={{ overflow: "hidden", width: "92.4rem", height: "35.1rem" }}>
                        <form className="form" onSubmit={formik.handleSubmit}>
                            <div style={{ height: "25.3rem" }}>
                                <h1 className="heading">Service Availability</h1>
                                <p className="paragraph" style={{ marginTop: "1.2rem" }}>
                                    Please Provide the Address at which you will receive services, P.O. Box addresses are not acceptable
                                </p>

                                <div className="flex flex-column " style={{ marginTop: "2.4rem", height: "6.8rem" }}>
                                    <label className="ziplabel">Zipcode</label>
                                    <InputText
                                        type="text"
                                        name="zipCode"
                                        className="calendar_field"
                                        placeholder="Zipcode"
                                        value={formik.values.zipCode}
                                        onChange={(e) => {
                                            formik.setFieldValue("zipCode", e.target.value);
                                            if (pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending") {
                                                formik.setFieldValue("zipCode", e.value);
                                                setShowCheckCoverage(false);
                                                setIsLoading(false);
                                                setPwgApiCheck("pending");
                                                setPwgApiCheckFound(true);
                                                setPwgDbCheck("pending");
                                                setPwgDbCheckFound(true);
                                                setUspsCheck("pending");
                                                setUspsCheckFound(true);
                                            }
                                        }}
                                        keyfilter={/^\d{0,5}$/}
                                        minLength={5}
                                        maxLength={5}
                                        disabled={isLoading}
                                        style={{ marginTop: ".4rem" }}
                                    />
                                </div>
                                <div className="coveragemain">
                                    {showCheckCoverage ? (
                                        <div className="checkcoverageapi">
                                            <div className="flex align-items-center">
                                                <span style={{ width: "16px", height: "16px", fontSize: "16px", color: "#98ADF8" }} className={`pi ${pwgApiCheck === "pending" ? "pi-spin pi-spinner" : pwgApiCheckFound ? "pi-check trueicoin " : "pi-times falseicon"}`}></span>
                                                <p
                                                    style={{
                                                        color: "#414064",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        paddingLeft: "1rem",
                                                    }}
                                                >
                                                    Checking through PWG API
                                                </p>
                                            </div>

                                            <div className="flex align-items-center">
                                                <span style={{ width: "16px", height: "16px", fontSize: "16px", color: "#98ADF8" }} className={`pi ${pwgDbCheck === "pending" ? "pi-spin pi-spinner" : pwgDbCheckFound ? "pi-check trueicoin " : "pi-times falseicon"}`}></span>
                                                <p
                                                    style={{
                                                        color: "#414064",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        paddingLeft: "1rem",
                                                    }}
                                                >
                                                    Checking through PWG Database Data
                                                </p>
                                            </div>
                                            <div className="flex align-items-center">
                                                <span style={{ width: "16px", height: "16px", fontSize: "16px", color: "#98ADF8" }} className={`pi ${uspsCheck === "pending" ? "pi-spin pi-spinner" : uspsCheckFound ? "pi-check trueicoin  " : "pi-times falseicon"}`}></span>
                                                <p
                                                    style={{
                                                        color: "#414064",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        paddingLeft: "1rem",
                                                    }}
                                                >
                                                    Checking through USPS
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                            {!pwgApiCheckFound && !pwgDbCheckFound && !uspsCheckFound && pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending" ? (
                                <div className="flex w-full justify-content-between align-items-center" style={{ marginTop: "4.8rem" }}>
                                    <div className="flex" style={{ width: "46.4rem", height: "5rem" }}>
                                        <div style={{ marginTop: ".2rem" }}>
                                            <Error />
                                        </div>
                                        <p style={{ fontSize: "1.4rem", fontWeight: "400", color: "#B3261E", paddingLeft: "1.2rem" }}>Service is currently unavailable in this area. Would you like to proceed anyway?</p>
                                    </div>
                                    <Button
                                        label="Next"
                                        type="button"
                                        onClick={() => {
                                            if (pwgApiCheckFound || pwgDbCheckFound || uspsCheckFound) {
                                                // setInitiateActive(false);
                                                setPersonalInfoActive(true);
                                                setZipVerified(true);
                                            } else {
                                                const serviceProvider = parseLoginRes?.company;
                                                const department = parseLoginRes?.department;
                                                const csr = parseLoginRes?._id;
                                                const carrier = "6455532566d6fad6eac59e34";
                                                const dataToSend = { serviceProvider, csr, department, carrier, ...formik.values, accountType: "Postpaid" };

                                                api.post(`/api/user/withoutzip`, dataToSend)
                                                    .then((res) => {
                                                        setParentDetails({
                                                            ...res?.data?.data,
                                                            accountType: "Postpaid",
                                                        });
                                                        setInitiationData({
                                                            ...res?.data?.data,
                                                            accountType: "Postpaid",
                                                        });

                                                        //localStorage.setItem("izZipVerified", "no");
                                                        // setZipVerified(true);
                                                        setPersonalInfoActive(true);
                                                        setInitiateActive(false);
                                                    })
                                                    .catch((err) => {});
                                            }
                                        }}
                                        className="checkcoverageactive"
                                        style={{ width: "16rem", height: "5rem" }}
                                    />
                                </div>
                            ) : (
                                <div className="vlidatebtndiv">
                                    <Button label="Validate" type="submit" className={formik.values.zipCode?.length < 5 || isLoading ? "checkcoverage" : "checkcoverageactive"} disabled={isLoading} />
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            {data && (
                <div className="billingdialog ">
                    <Dialog
                        header={null} // No header
                        draggable={false}
                        headerClassName="billingheader"
                        closable={false} // Ensure the close button is hidden
                        visible={data}
                        style={{ width: "53.2rem", height: "27rem", marginLeft: "29.2rem" }}
                        onHide={() => {
                            setData(false);
                        }}
                    >
                        <div className="billingdialoginner" style={{ marginLeft: "2.4rem" }}>
                            <span>
                                <BillingEmpty />
                            </span>
                            <h1>Billing Model Not Configured</h1>
                            <p>The billing model for this enrollment does not exist. Please configure the billing model first before proceeding.</p>
                            <div className="flex w-full justify-content-center" style={{ marginTop: "3.2rem" }}>
                                <Button
                                    label="Add Billing Model"
                                    disabled={role?.role?.role !== "Admin"}
                                    className="nextbtn"
                                    style={{ width: "21.4rem", height: "5rem" }}
                                    onClick={() => {
                                        navigate("/billing-model-configuration");
                                    }}
                                />
                            </div>
                        </div>
                    </Dialog>
                </div>
            )}
        </>
    );
}
