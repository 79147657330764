import React, { useState, useRef } from "react";
import "./css/QuerySIM.css";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { Button } from "primereact/button";
import CommonLoaderBlue from "../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import moment from "moment";
export const QuerySim = ({ cpData }) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const toast = useRef();
    const [isQuerySimLoading, setIsQuerySimLoading] = useState(false);
    const [simDetailsData, setsimDetailsData] = useState({});
    const fetcQuerySIMkDetails = () => {
        setIsQuerySimLoading(true);

        api.post(`/api/user/querySim`, { enrollmentId: cpData?._id, userId: userData?._id, esn: cpData?.esn })
            .then((res) => {
                setsimDetailsData(res?.data?.data);
                toast.current.show({ severity: "success", summary: "Query Network", detail: res?.data?.msg || "Network Query Fetched Successfully" });
                setIsQuerySimLoading(false);
            })
            .catch((error) => {
                console.log(error?.response?.data?.status);
                toast.current.show({ severity: "error", summary: "Query Network", detail: error?.response?.data?.status?.errors?.error?.message || "Network Query Failed" });
                setIsQuerySimLoading(false);
            });
    };

    return (
        <div className={`main-querysim ${Object.keys(simDetailsData).length > 2 ? "main-simnetwork-width710" : "                  "}`}>
            <div className="top-header-inputfield-head">
                <div className="header-main-querysim">
                    <h1>Query SIM</h1>
                    <p>Enter the ESN to retrieve customer data.</p>
                </div>
                <div className="query-sim-inputcontainer">
                    <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                        <label>ESN</label>
                    </div>
                    <input placeholder="ESN" value={cpData?.esn || cpData?.esnId?.esn || ""} />
                </div>
            </div>
            <div className="flex w-full flex-wrap flex-row justify-content-end align-items-center">
                <Button
                    onClick={() => {
                        fetcQuerySIMkDetails();
                    }}
                    disabled={!cpData?.esn || isQuerySimLoading}
                    label={
                        isQuerySimLoading ? (
                            <div className="w-full flex flex-wrap flex-row justify-content-center align-items-center">
                                <CommonLoaderBlue customSize={12} />
                            </div>
                        ) : (
                            `View Details`
                        )
                    }
                />
            </div>
            {Object.keys(simDetailsData).length > 2 ? (
                <div className="querysim-details">
                    <div className="querynetwork-detail-item">
                        <p>ESN</p>
                        <p>{simDetailsData?.SIM || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>Activation Eligibile</p>
                        <p>{simDetailsData?.ACTIVATIONELIGIBLE === "FALSE" ? "No" : simDetailsData.ACTIVATIONELIGIBLE === "TRUE" ? "Yes" : "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>WFC Eligibible</p>
                        <p>{simDetailsData?.WFCEligible === "FALSE" ? "No" : simDetailsData.WFCEligible === "TRUE" ? "Yes" : "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>PUK 1</p>
                        <p>{simDetailsData?.PUK1 || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>PUK2</p>
                        <p>{simDetailsData?.PUK2 || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>Create Date</p>
                        <p>
                            {simDetailsData?.CREATEDATE
                                ? moment(simDetailsData?.CREATEDATE)
                                      .tz("America/New_York") // Change this to the specific American timezone you need
                                      .format("MM/DD/YYYY hh:mm A")
                                : "NIL"}
                        </p>
                    </div>
                    <div className="querynetwork-detail-item soc-field">
                        <p>Expiration Date</p>
                        <p>
                            {simDetailsData?.EXPIRATIONDATE
                                ? moment(simDetailsData?.EXPIRATIONDATE)
                                      .tz("America/New_York") // Change this to the specific American timezone you need
                                      .format("MM/DD/YYYY hh:mm A")
                                : "NIL"}
                        </p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>ICC ID Status</p>
                        <p>{simDetailsData?.ICCIDSTATUS || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>Status Code</p>
                        <p>{simDetailsData?.statusCode || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>ICC ID Status</p>
                        <p>{simDetailsData?.ICCIDSTATUS || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>Description</p>
                        <p>{simDetailsData?.description || "NIL"}</p>
                    </div>
                </div>
            ) : undefined}
            <Toast ref={toast} />
        </div>
    );
};
