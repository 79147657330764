import { Button } from "primereact/button";
import "./css/QueryNetwork.css";
import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import { OverlayPanel } from "primereact/overlaypanel";
export const QueryNetwork = ({ cpData }) => {
    const toast = useRef();
    const [overlayContent, setOverlayContent] = useState("");
    const socOverlayRef = useRef(null);
    const [isQueryNetworkLoading, setIsQueryNetworkLoading] = useState(false);
    const [mdnDetailsData, setMdnDetailsData] = useState({});
    const fetcQueryNetworkDetails = () => {
        setIsQueryNetworkLoading(true);
        api.get(`/api/user/QueryNetwork?enrollmentId=${cpData?._id || ""}&mdn=${cpData?.phoneNumber}`)
            .then((res) => {
                setMdnDetailsData(res?.data?.data);
                toast.current.show({ severity: "success", summary: "Query Network", detail: res?.data?.msg || "Network Query Fetched Successfully" });
                setIsQueryNetworkLoading(false);
            })
            .catch((error) => {
                console.log(error?.response?.data?.status);
                toast.current.show({ severity: "error", summary: "Query Network", detail: error?.response?.data?.status?.errors?.error?.message || "Network Query Failed" });
                setIsQueryNetworkLoading(false);
            });
    };
    return (
        <div className={`main-querynetwork ${Object.keys(mdnDetailsData).length > 2 ? "main-querynetwork-width710" : "                  "}`}>
            <div className="top-header-inputfield-head">
                <div className="header-main-querynetwork">
                    <h1>Query Network</h1>
                    <p>Enter the MDN to retrieve customer data.</p>
                </div>
                <div className="query-network-inputcontainer">
                    <div className="flex flex-wrap flex-row justify-content-between align-items-center w-full ">
                        <label>MDN</label>
                    </div>
                    <input disabled type="number" placeholder="MDN" value={cpData?.phoneNumber || ""} />
                </div>
            </div>
            <div className="flex w-full flex-wrap flex-row justify-content-end align-items-center">
                <Button
                    onClick={() => {
                        fetcQueryNetworkDetails();
                    }}
                    disabled={!cpData?.phoneNumber || isQueryNetworkLoading}
                    label={
                        isQueryNetworkLoading ? (
                            <div className="w-full flex flex-wrap flex-row justify-content-center align-items-center">
                                <CommonLoaderBlue customSize={12} />
                            </div>
                        ) : (
                            `View Details`
                        )
                    }
                />
            </div>
            {Object.keys(mdnDetailsData).length > 2 ? (
                <div className="querynetwork-details">
                    <div className="querynetwork-detail-item">
                        <p>MDN</p>
                        <p>{mdnDetailsData?.mdn || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>ESN</p>
                        <p>{mdnDetailsData?.sim || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>IMSI</p>
                        <p>{mdnDetailsData?.IMSI || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>IMEI</p>
                        <p>{mdnDetailsData?.IMEI || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>SIM Status</p>
                        <p>{mdnDetailsData?.SIMSTATUS || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>MS Status</p>
                        <p>{mdnDetailsData?.MS_STATUS || "NIL"}</p>
                    </div>
                    <div className="querynetwork-detail-item soc-field">
                        <p>SOC</p>

                        <p
                            onMouseEnter={(e) => {
                                const socsString = mdnDetailsData?.socs?.map((item) => item.soc).join(", ");
                                if (socsString.length > 50) {
                                    setOverlayContent(socsString);
                                    socOverlayRef.current.show(e);
                                }
                            }}
                            onMouseLeave={() => socOverlayRef.current.hide()}
                        >
                            {mdnDetailsData?.socs && mdnDetailsData?.socs.length > 0
                                ? (() => {
                                      const socsString = mdnDetailsData.socs.map((item) => item.soc).join(", ");
                                      return socsString.length > 50 ? socsString.substring(0, 47) + "..." : socsString;
                                  })()
                                : "NIL"}
                        </p>

                        <OverlayPanel ref={socOverlayRef}>
                            <div style={{ maxWidth: "36.5rem", padding: "5px 10px" }}>
                                <p
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "1.4rem",
                                        overflow: "hidden",
                                        border: "none",
                                        margin: "0px",
                                        lineHeight: "1.8rem",
                                        color: "#72728f",
                                    }}
                                >
                                    {" "}
                                    {overlayContent}
                                </p>
                            </div>
                        </OverlayPanel>
                    </div>
                    <div className="querynetwork-detail-item">
                        <p>APNs</p>
                        <p>
                            {" "}
                            {mdnDetailsData.apn
                                ? mdnDetailsData.apn
                                      .map((item) => item.value)
                                      .join(", ")
                                      .slice(0, 40) + (mdnDetailsData.apn.map((item) => item.value).join(", ").length > 40 ? "..." : "")
                                : ""}
                        </p>
                    </div>
                </div>
            ) : undefined}
            <Toast ref={toast} />
        </div>
    );
};
