import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { api } from "./utility/AxiosInceptor";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "./app/store/auth/AuthSlice";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";
import "./apptopbar.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../public/setting.svg";
import "../public/user.svg";
import { Toast } from "primereact/toast";
import "./app.css";
import { InputSwitch } from "primereact/inputswitch";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { DarkTheme, Edit, EditHover, Home, Loginbg, LogoutIcon, NotificationIcon, Prepaidnotification, ProfilesettingIcon, SearchIco, SinptelNotification } from "./utility";
import io from "socket.io-client";
import moment from "moment";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AppTopbar = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const location = useLocation();
    const [counter, setCounter] = useState("");
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [CustomerProfileIcon, setCustomerProfileIcon] = useState(false);
    const [allNotification, setAllNotification] = useState(true);
    const [unreadNotification, setUnreadNotification] = useState(false);
    const [visibleSearch, setVisibleSearch] = useState(false);
    const [notification, setNotification] = useState([]);
    const [profileSetting, setProfileSetting] = useState(false);
    const [edit, setEdit] = useState(false);
    const [showeditprofile, setShoweditprofile] = useState(false);
    const [checked, setChecked] = useState(false);
    const [userdropdown, setUserDropdown] = useState(false);
    const [uploadedFilePath, setUploadedFilePath] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [department, setDepartment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [userData1, setUserData1] = useState("");
    const [upload, setUpload] = useState(false);
    const unreadNotes = notification.filter((item) => !item?.read);
    const [uploadedFileName, setUploadedFileName] = useState("Upload");
    const [notesidebar, setnotesidebar] = useState(false);
    const [monthlyNote, setMonthlyNotes] = useState(false);
    const [weeklyNotes, setWeeklyNotes] = useState(false);
    const [active, setActive] = useState(false);
    let userData;
    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required("This is Required"),
    });
    userData = JSON.parse(localStorage.getItem("userData"));
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            fullName: userData?.userName,
            // lastName: userData1?.name,
        },
        onSubmit: (values) => {
            handleUpload();
        },
    });
    const handleUpload = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("UserId", userData?._id);
            formData.append("profileImage", uploadedFilePath);
            formData.append("name", formik.values.fullName);
            const response = await api.put(`/api/web/user/updateProfileImage`, formData);
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.msg);
                const updatedUserData = { ...userData, userName: formik.values.fullName };
                localStorage.setItem("userData", JSON.stringify(updatedUserData));
                userData = JSON.parse(localStorage.getItem("userData"));
                userdetail();

                setProfileSetting(false);
            }
        } catch (e) {
            toast.error(e?.response?.data?.msg || "Failed to update profile.");
        } finally {
            setIsLoading(false);
        }
    };
    const handleDelete = async () => {
        try {
            const res = await api.delete(`/api/web/user/deleteProfileImage?UserId=${userData?._id}`);
            if (res.status === 200 || res.status === 201) {
                toast.success(res?.data?.msg);
                userdetail();
                setProfileSetting(false);
                setUploadedFileName("Upload");
                setPreviewImage(null);
                setUploadedFilePath(null);
            }
        } catch (error) {
            toast.error(error.response?.data?.msg);
            setProfileSetting(false);
        }
    };
    const userdetail = async () => {
        try {
            const res = await api.get(`/api/web/user/userDetails?userId=${userData?._id}`);
            setUserData1(res?.data?.data);
        } catch (e) {}
    };
    useEffect(() => {
        api.get(`/api/web/user/userDetails?userId=${userData?._id}`)
            .then((response) => {
                setUserData1(response?.data?.data);
            })
            .catch((error) => {});
    }, []);

    useEffect(() => {
        api.get(`/api/deparments/getDepartmentById?departmentId=${userData?.department}`)
            .then((response) => {
                setDepartment(response?.data?.data?.department);
            })
            .catch((error) => {});
    }, []);
    useEffect(() => {
        const handleRouteChange = (newLocation) => {
            if (newLocation.pathname !== currentLocation) {
                // if(props.searchByValueClick || props.searchBy){
                props.setSearchBy(null);
                props.setSearchByValueClick(false);
                setCurrentLocation(newLocation.pathname);
                //  }
            }
        };
        handleRouteChange(location);
        window.addEventListener("popstate", handleRouteChange);
        return () => {
            window.removeEventListener("popstate", handleRouteChange);
        };
    }, [location]);
    useEffect(() => {
        document.addEventListener("click", docOnClick, false);
    });
    const socket = useRef(null);

    useEffect(() => {
        socket.current = io(BASE_URL, {
            transports: ["websocket"],
        });
        socket.current.on("connect", () => {});
        socket.current.on("newNote", () => {
            fetchNotifications();
        });

        socket.current.on("error", (err) => {});
        socket.current.on("error", (err) => {});

        // Fetch notifications when the component mounts (for login and refresh scenarios)
        fetchNotifications();

        return () => {
            socket.current.disconnect();
        };
    }, []);
    useEffect(() => {
        if (props.showApps) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [props.showApps]);
    const fetchNotifications = async () => {
        try {
            const response = await api.get(`/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
            const data = response?.data?.unreadCount;
            const note = response?.data?.notifications;
            setNotification(note);
            setCounter(data);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    function docOnClick(e) {
        setVisibleSearch(false);
    }
    //Dialogues for Advance Search
    const countries = [
        { name: "Inventory Search", code: "inventorysearch" },
        // { name: "Payment Search", code: "paymentsearch" },
        { name: "Recent Searches", code: "recentsearches" },
        { name: "Advance Search", code: "advance search" },
    ];
    const countryTemplate = (option) => {
        return (
            <div className="flex listbox-container align-items-center">
                {option.name === "Payment Search" ? (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/payment-search.png"
                        alt="none"
                    />
                ) : option.name === "Recent Searches" ? (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/recent-search.png"
                        alt="none"
                    />
                ) : option.name === "Advance Search" ? (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/advance-search.png"
                        alt="none"
                    />
                ) : (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/inventory-search.png"
                        alt="none"
                    />
                )}
                <p style={{ padding: "0px", margin: "0px", color: "#72728f", fontWeight: "500", fontFamily: "Inter" }}>{option.name}</p>
            </div>
        );
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Get user data from localStorage
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const capitalCompanyName = parseLoginRes?.companyName?.toUpperCase();
    const handleLogout = () => {
        props.setSearchValue("");
        props.setSearchByValueClick(false);
        props.setSearchBy(null);
        dispatch(logout());
        navigate("/login");
    };
    const handleReadNotification = async (notificationId) => {
        try {
            await api.put(`/api/web/notes/markReadnotifications?notificationId=${notificationId}&userId=${parseLoginRes?._id}`);
            // const response = await
            api.put(`/api/web/notes/markReadnotifications?notificationId=${notificationId}&userId=${parseLoginRes?._id}`);

            const res = await api.get(`/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
            const { unreadCount, notifications } = res?.data;
            setNotification(notifications);
            setCounter(unreadCount);
        } catch (error) {
            toast(error?.response?.data?.msg);
        }
    };
    const handleNavigate = (customerId) => {
        console.log(customerId);
        if (customerId !== undefined) {
            if (location.pathname === "/customer-profile") {
                localStorage.setItem("selectedId", JSON.stringify(customerId));
                props.setRefreshOnNotification(customerId);
            } else {
                localStorage.setItem("selectedId", JSON.stringify(customerId));
                navigate("/customer-profile", { state: { selectedId: customerId } });
            }
        }
    };
    const customBase64Uploader = (event) => {
        const file = event.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64Url = reader.result;
            setUploadedFileName(file.name);
            setPreviewImage(base64Url);
            setUploadedFilePath(file);
        };
        reader.readAsDataURL(file);
    };
    useEffect(() => {
        document.addEventListener(
            "click",
            (e) => {
                setUserDropdown(false);
            },
            false,
        );
    }, []);
    return (
        <div>
            <ToastContainer />
            <Toast ref={toast} />
            {!props.showApps ? (
                <div className="notshowapps-main">
                    <div className="hamburgur">
                        <button type="button" className="layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                            <i
                                style={{
                                    fontSize: "1.25em",
                                    marginTop: "-5px",
                                    display: "inline-block",
                                    color: "rgb(112, 134, 253)",
                                }}
                                className="pi pi-bars"
                            />
                        </button>
                    </div>
                    <div
                        className="layout-topbar"
                        onClick={(e) => {
                            setVisibleSearch(false);
                            e.stopPropagation();
                        }}
                    >
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-start">
                            <div style={{ width: "72.5rem" }} className="flex logo-search flex-wrap flex-row justify-content-between align-items-center">
                                <div style={{ marginTop: capitalCompanyName.includes("MAGNET") && "-2rem" }} className="sidebar-company-logo flex flex-wrap flex-row justify-content-center">
                                    <Link
                                        to="/"
                                        className=""
                                        onClick={(e) => {
                                            props.setShowApps(false);
                                            e.stopPropagation();
                                            props.setSearchBy(null);
                                            props.setSearchByValueClick(false);
                                        }}
                                    >
                                        <img
                                            onError={(e) => {
                                                e.target.src = "./placeholderimage.jpg";
                                            }}
                                            style={{ height: capitalCompanyName.includes("MAGNET") && "150%", marginLeft: capitalCompanyName?.includes("MAGNET") && "1.4rem" }}
                                            src={`${capitalCompanyName.includes("IJ") ? "/companyLogo1.png" : capitalCompanyName.includes("ZISFONE") ? "/zisfonelogo.png" : capitalCompanyName.includes("MAGNET") ? "/magnetlogo.png" : "/Talkdaily.png"}`}
                                            alt="Logo"
                                        />
                                    </Link>
                                </div>

                                <div className="flex flex-wrap flex-row justify-content-center align-items-center topbarsearchmain">
                                    <div style={{ position: "relative", width: "100%" }}>
                                        <span style={{ position: "absolute", left: "1rem", top: "55%", transform: "translateY(-50%)", zIndex: 1 }}>
                                            <SearchIco />
                                        </span>
                                        <InputText
                                            className="topbarsearch"
                                            onChange={(e) => {
                                                props.setSearchValue(e.target.value);
                                            }}
                                            value={props.searchValue}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.stopPropagation();
                                                    if (props.searchValue !== "") {
                                                        props.setSearchByValueClick(true);
                                                        props.setCallSearchApi((prev) => !prev);
                                                    }
                                                    setVisibleSearch(false);
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (props.searchValue !== "") {
                                                    props.setSearchByValueClick(true);
                                                    props.setCallSearchApi((prev) => !prev);
                                                }
                                                setVisibleSearch(true);
                                            }}
                                            placeholder="Search Customer"
                                            style={{
                                                backgroundColor: "transparent",
                                                paddingLeft: "4rem",
                                                width: "40.8rem",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="buttonsdiv align-items-start">
                                <div
                                    className="appbutton"
                                    onClick={() => {
                                        props.setShowApps(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <span className="app-span flex justify-content-center align-items-center gradient-text">
                                        <Home />
                                    </span>
                                </div>
                                <div
                                    className="appbutton "
                                    style={{ cursor: "pointer", background: "white" }}
                                    onClick={() => setVisibleRight(true)}
                                    onMouseEnter={() => {
                                        setActive(true);
                                    }}
                                    onMouseLeave={() => {
                                        setActive(false);
                                    }}
                                >
                                    <div>
                                        <span className="notification-span flex justify-content-center align-items-center gradient-text">
                                            <NotificationIcon />
                                        </span>
                                    </div>
                                    <span className=" notification-count " style={{ backgroundColor: active ? "white" : "transparent" }}>
                                        {counter <= 99 ? counter : "99+"}
                                    </span>
                                </div>
                                <div
                                    className="userlogin cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowProfileMenu(true);
                                        setUserDropdown(true);
                                    }}
                                >
                                    {userData1?.picture ? (
                                        <img
                                            style={{ width: "100%", height: "100%", borderRadius: "4px" }}
                                            src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                            alt={userData1?.name || "User"}
                                            onError={(e) => {
                                                e.target.style.display = "none";
                                            }}
                                        />
                                    ) : (
                                        <span
                                            className="profilename"
                                            onClick={() => {
                                                setUserDropdown(true);
                                            }}
                                        >
                                            {userData1?.name
                                                ? userData1.name
                                                      .split(" ") // Split the name into words
                                                      .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                      .join("") // Join the letters
                                                : "NA"}{" "}
                                            {/* Default fallback */}
                                        </span>
                                    )}
                                </div>
                            </div>
                            {userdropdown && (
                                <div
                                    className="profilemenu"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className="profilemenuinner">
                                        <div className="info w-full">
                                            <div className="userinfo">
                                                <div className="profuser">
                                                    {userData1?.picture ? (
                                                        <img
                                                            className="imgmargin"
                                                            src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                                            alt={userData1?.name || "User"}
                                                            onError={(e) => {
                                                                e.target.style.display = "none";
                                                            }}
                                                        />
                                                    ) : (
                                                        <div className="flex justify-content-center align-items-center imgmargin">
                                                            <span
                                                                className="profilename"
                                                                onClick={() => {
                                                                    setUserDropdown(true);
                                                                }}
                                                            >
                                                                {userData1?.name
                                                                    ? userData1.name
                                                                          .split(" ") // Split the name into words
                                                                          .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                                          .join("") // Join the letters
                                                                    : "NA"}{" "}
                                                                {/* Default fallback */}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="userinfo">
                                                <h1 style={{ paddingTop: "1px", height: "13px" }}>{userData1?.name}</h1>
                                                <p>{userData?.email}</p>
                                            </div>
                                            <div style={{ marginTop: "-24px" }} className="userbtn">
                                                {userData?.role?.role}
                                            </div>
                                        </div>
                                        <div
                                            className="data w-full"
                                            onClick={() => {
                                                setProfileSetting(true);
                                                setUserDropdown(false);
                                            }}
                                        >
                                            <div>
                                                <ProfilesettingIcon />
                                            </div>
                                            <div>
                                                <span style={{ marginLeft: "5px" }}> Profile Settings</span>
                                            </div>
                                        </div>

                                        <div className="data1 w-full">
                                            <div>
                                                <DarkTheme />
                                            </div>
                                            <div>
                                                <span style={{ marginLeft: "5px" }}> Dark Mode</span>
                                            </div>
                                            <div className="flex justify-content-center align-items-center" style={{ marginLeft: "32%" }}>
                                                <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                                            </div>
                                        </div>

                                        <div
                                            className="data2 w-full"
                                            onClick={() => {
                                                handleLogout();
                                                setUserDropdown(false);
                                            }}
                                        >
                                            <div>
                                                <LogoutIcon />
                                            </div>
                                            <div>
                                                <span style={{ marginLeft: "5px" }}> Logout</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={{ background: "#FFFFFF" }}
                            className="flex justify-content-center listbox"
                        >
                            <ListBox
                                value={props.searchBy}
                                style={{ display: `${visibleSearch === true ? "block" : "none"}`, backgroundColor: "#FFFFFF" }}
                                onChange={(e) => {
                                    if (e.value !== null) {
                                        props.setSearchByValueClick(false);
                                        props.setSearchBy(e.value);
                                        setVisibleSearch(false);
                                    }
                                }}
                                options={countries}
                                optionLabel="name"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (e.target.textContent === "Advance Search") {
                                    }
                                }}
                                itemTemplate={countryTemplate}
                                className="w-full "
                                listStyle={{ maxHeight: "250px" }}
                            />
                        </div>
                        <div className="flex ">
                            <Sidebar closeable={false} style={{ width: "408px" }} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                                <div className="flex justify-content-start align-items-center" style={{ height: "25px", width: "116px", marginTop: "15px" }}>
                                    <button
                                        className={allNotification ? "allbtnactive" : "allbtn"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setUnreadNotification(false);
                                            setAllNotification(true);
                                        }}
                                    >
                                        ALL
                                    </button>
                                    <button
                                        className={unreadNotification ? "unreadbtnactive" : "unreadbtn"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setUnreadNotification(true);
                                            setAllNotification(false);
                                        }}
                                    >
                                        Unread
                                    </button>
                                </div>
                                <hr />

                                {/* <div style={{ cursor: "pointer" }} className="notificationunread">
                                    <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                        <div className="tooltip-container mt-2">
                                            <SinptelNotification />
                                            <span className="custom-tooltip"> ToolTip</span>
                                        </div>
                                        <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                            <h2>Monthly Summary for Nov, 2024</h2>
                                            <p>November summary is ready! Check key stats across modules.</p>
                                            <div className="flex w-full justify-content-between" style={{ cursor: "pointer" }}>
                                                <h5>Thursday Nov, 14</h5>
                                                <span onClick={() => setnotesidebar(true)} style={{ fontSize: "12px", color: "#475CED", fontWeight: "600" }}>
                                                    View Full Summary
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {unreadNotification
                                    ? unreadNotes.map((item, index) => (
                                          <div
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                  handleNavigate(item?.customerId);
                                                  setVisibleRight(false);
                                                  handleReadNotification(item?._id);
                                                  props.setHandleHighlight(item?.noteId);
                                              }}
                                              className={item?.read ? "notificationread" : "notificationunread"}
                                          >
                                              <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                  {/* <div className="tooltip-container">
                                                      <Prepaidnotification />
                                                      <span className="custom-tooltip"> ToolTip</span>
                                                  </div> */}
                                                  <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                      {/* <h2>New Prepaid SIM Activation Request</h2> */}
                                                      <p>{item.message}</p>
                                                      <span style={{ cursor: "pointer" }}>
                                                          <h5
                                                              style={{ fontSize: "12px" }}
                                                              onClick={() => {
                                                                  handleReadNotification(item?._id);
                                                              }}
                                                          >
                                                              {item?.timestamp &&
                                                                  moment
                                                                      .utc(item?.timestamp) // Convert UTC to Moment object
                                                                      .tz("America/New_York") // Convert to Eastern Time (ET)
                                                                      .format("dddd, MMM D")}
                                                          </h5>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                    : notification.map((item, index) => (
                                          <div
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                  handleNavigate(item?.customerId);
                                                  setVisibleRight(false);
                                                  handleReadNotification(item?._id);
                                                  props.setHandleHighlight(item?.noteId);
                                              }}
                                              className={item?.read ? "notificationread" : "notificationunread"}
                                          >
                                              <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                  {/* <div className="tooltip-container">
                                                      <Prepaidnotification />
                                                      <span className="custom-tooltip"> ToolTip</span>
                                                  </div> */}
                                                  <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                      {/* <h2>New Prepaid SIM Activation Request</h2> */}
                                                      <p>{item.message}</p>
                                                      <span style={{ cursor: "pointer" }}>
                                                          <h5
                                                              style={{ fontSize: "12px" }}
                                                              onClick={() => {
                                                                  handleReadNotification(item?._id);
                                                              }}
                                                          >
                                                              {item?.timestamp &&
                                                                  moment
                                                                      .utc(item?.timestamp) // Convert UTC to Moment object
                                                                      .tz("America/New_York") // Convert to Eastern Time (ET)
                                                                      .format("dddd, MMM D")}
                                                          </h5>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                            </Sidebar>
                        </div>

                        {/* Userprofile sidebar */}

                        <div className="flex justify-content-center align-items-center  ">
                            <Sidebar className="flex justify-content-center align-items-center " closeable={false} style={{ width: "808px" }} visible={profileSetting} position="right" onHide={() => setProfileSetting(false)}>
                                {showeditprofile ? (
                                    <div>
                                        <div className="userprofilemain">
                                            <div className="profileinfo">
                                                <div className="profilename">
                                                    <div>
                                                        <button>
                                                            {userData1?.picture ? (
                                                                <img
                                                                    style={{ width: "80px", height: "80px", marginleft: "-10px" }}
                                                                    src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                                                    alt={userData1?.name || "User"}
                                                                    onError={(e) => {
                                                                        e.target.style.display = "none";
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span className="profilename" onClick={() => setUserDropdown(true)} style={{ fontSize: "28px", fontWeight: "500", color: "#9C9EB6" }}>
                                                                    {userData1?.name
                                                                        ? userData1.name
                                                                              .split(" ") // Split the name into words
                                                                              .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                                              .join("") // Join the letters
                                                                        : "NA"}{" "}
                                                                    {/* Default fallback */}
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                    <div className="mailinfoedit">
                                                        <button className="deletepic" onClick={handleDelete}>
                                                            Delete Picture
                                                        </button>
                                                        <div>
                                                            <div>
                                                                <FileUpload mode="basic" name="demo[]" accept="image/*" customUpload uploadHandler={customBase64Uploader} chooseLabel={uploadedFileName} auto />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="profileeditbtn"
                                                    onMouseEnter={() => setEdit(true)}
                                                    onMouseLeave={() => setEdit(false)}
                                                    onClick={() => {
                                                        setShoweditprofile(true);
                                                    }}
                                                >
                                                    <span>{edit ? <EditHover /> : <Edit />}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="userprofiledetails">
                                            <div className="userprofiledetailsinner">
                                                <div className="flex flex-row w-full justify-content-between">
                                                    <div>
                                                        <h1>Details</h1>
                                                    </div>
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-2">
                                                    <div className="flex w-full justify-content-between">
                                                        <label className={formik.touched.fullName && formik.errors.fullName ? "labelerr" : " "}>Full Name</label>
                                                        {formik.touched.fullName && formik.errors.fullName ? <span className="err">{formik.errors.fullName}</span> : ""}
                                                    </div>
                                                    <InputText id="fullName" className={formik.touched.fullName && formik.errors.fullName ? "inputfielderr mt-2" : "inputfield mt-2"} type="text" value={formik.values.fullName} onChange={formik.handleChange} onBlur={formik.handleBlur}></InputText>
                                                </div>

                                                <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label> Company Name</label>
                                                    <InputText className="inputfield mt-2" type="text" value={userData?.companyName} disabled></InputText>
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label>Email</label>
                                                    <InputText className="inputfield mt-2" type="text" value={userData?.email} disabled></InputText>
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label>Role</label>
                                                    <InputText className="inputfield mt-2" type="text" value={userData?.role?.role} disabled></InputText>
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-5" style={{ marginBottom: "40px" }}>
                                                    <label> Department</label>
                                                    <InputText className="inputfield mt-2" type="text" value={department} disabled></InputText>
                                                </div>
                                                <div className="flex w-full justify-content-end">
                                                    <button
                                                        className="backbtn"
                                                        onClick={() => {
                                                            formik.setFieldValue("fullName", userData.userName);
                                                            setShoweditprofile(false);
                                                        }}
                                                    >
                                                        Back
                                                    </button>
                                                    <div className=" flex justify-content-center" style={{ marginLeft: "10px" }}>
                                                        <Button
                                                            type="submit"
                                                            label="Update"
                                                            className="submitbtn"
                                                            onClick={() => {
                                                                formik.handleSubmit();
                                                                setUpload((prev) => !prev);
                                                                setProfileSetting(false);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="userprofilemain">
                                            <div className="profileinfo">
                                                <div className="profilename">
                                                    <div>
                                                        <button>
                                                            {userData1?.picture ? (
                                                                <img
                                                                    style={{ width: "80px", height: "80px", marginleft: "-10px" }}
                                                                    src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                                                    alt={userData1?.name || "User"}
                                                                    onError={(e) => {
                                                                        e.target.style.display = "none";
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span className="profilename" onClick={() => setUserDropdown(true)} style={{ fontSize: "28px", fontWeight: "500", color: "#9C9EB6" }}>
                                                                    {userData1?.name
                                                                        ? userData1.name
                                                                              .split(" ") // Split the name into words
                                                                              .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                                              .join("") // Join the letters
                                                                        : "NA"}{" "}
                                                                    {/* Default fallback */}
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                    <div className="mailinfo">
                                                        <h1>{userData1?.name}</h1>
                                                        <p>{userData?.email}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="profileeditbtn"
                                                    onMouseEnter={() => setEdit(true)}
                                                    onMouseLeave={() => setEdit(false)}
                                                    onClick={() => {
                                                        setShoweditprofile(true);
                                                    }}
                                                >
                                                    <span>{edit ? <EditHover /> : <Edit />}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="userprofiledetails">
                                            <div className="userprofiledetailsinner">
                                                <div className="flex flex-row w-full justify-content-between">
                                                    <div>
                                                        <h1>Details</h1>
                                                    </div>
                                                    {/* <div>
                                                        <h5>
                                                            User Id : <span> ETC12345</span>
                                                        </h5>
                                                    </div> */}
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label> Full Name</label>
                                                    <InputText className="inputfield mt-2" type="text" value={formik.values.fullName} contentEditable={false}></InputText>
                                                </div>
                                                {/* <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label> Last Name</label>
                                                    <InputText className="inputfield mt-2" type="text" value={"Wick"} contentEditable={false}></InputText>
                                                </div> */}
                                                <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label> Company Name</label>
                                                    <InputText className="inputfield mt-2" type="text" value={userData?.companyName} contentEditable={false}></InputText>
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label>Email</label>
                                                    <InputText className="inputfield mt-2" type="text" value={userData?.email} contentEditable={false}></InputText>
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label>Role</label>
                                                    <InputText className="inputfield mt-2" type="text" value={userData?.role?.role} contentEditable={false}></InputText>
                                                </div>
                                                <div className="flex flex-column w-full justify-content-between mt-5" style={{ marginBottom: "40px" }}>
                                                    <label> Department</label>
                                                    <InputText className="inputfield mt-2" type="text" value={department} contentEditable={false}></InputText>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Sidebar>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="appdialog" style={{ position: "relative" }}>
                        <div
                            className="layout-topbar"
                            onClick={(e) => {
                                setVisibleSearch(false);
                                e.stopPropagation();
                            }}
                        >
                            <div className="w-full flex flex-wrap flex-row justify-content-between align-items-start">
                                <div style={{ width: "72.5rem" }} className="flex logo-search flex-wrap flex-row justify-content-between align-items-center">
                                    <div style={{ marginTop: capitalCompanyName.includes("MAGNET") && "-2rem" }} className="sidebar-company-logo flex flex-wrap flex-row justify-content-center">
                                        <Link
                                            to="/"
                                            className=""
                                            onClick={(e) => {
                                                props.setShowApps(false);
                                                e.stopPropagation();
                                                props.setSearchBy(null);
                                                props.setSearchByValueClick(false);
                                            }}
                                        >
                                            <img
                                                onError={(e) => {
                                                    e.target.src = "./placeholderimage.jpg";
                                                }}
                                                style={{ height: capitalCompanyName.includes("MAGNET") && "150%", marginLeft: capitalCompanyName?.includes("MAGNET") && "1.4rem" }}
                                                src={`${capitalCompanyName.includes("IJ") ? "/companyLogo1.png" : capitalCompanyName.includes("ZISFONE") ? "/zisfonelogo.png" : capitalCompanyName.includes("MAGNET") ? "/magnetlogo.png" : "/Talkdaily.png"}`}
                                                alt="Logo"
                                            />
                                        </Link>
                                    </div>

                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center topbarsearchmain">
                                        <div style={{ position: "relative", width: "100%" }}>
                                            <span style={{ position: "absolute", left: "1rem", top: "55%", transform: "translateY(-50%)", zIndex: 1 }}>
                                                <SearchIco />
                                            </span>
                                            <InputText
                                                className="topbarsearch"
                                                onChange={(e) => {
                                                    props.setSearchValue(e.target.value);
                                                }}
                                                value={props.searchValue}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.stopPropagation();
                                                        if (props.searchValue !== "") {
                                                            props.setSearchByValueClick(true);
                                                            props.setCallSearchApi((prev) => !prev);
                                                        }
                                                        setVisibleSearch(false);
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (props.searchValue !== "") {
                                                        props.setSearchByValueClick(true);
                                                        props.setCallSearchApi((prev) => !prev);
                                                    }
                                                    setVisibleSearch(true);
                                                }}
                                                placeholder="Search Customer"
                                                style={{
                                                    backgroundColor: "transparent",
                                                    paddingLeft: "4rem",
                                                    width: "40.8rem",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="buttonsdiv align-items-start">
                                    <div className="appbutton" onClick={() => props.setShowApps(true)} style={{ cursor: "pointer" }}>
                                        <span className="app-span flex justify-content-center align-items-center gradient-text">
                                            <Home />
                                        </span>
                                    </div>
                                    <div className="appbutton" style={{ cursor: "pointer" }} onClick={() => setVisibleRight(true)}>
                                        <div>
                                            <span className="notification-span flex justify-content-center align-items-center gradient-text">
                                                <NotificationIcon />
                                            </span>
                                        </div>
                                        <span style={{ color: "black" }} className=" notification-count">
                                            {counter <= 9 ? counter : "9+"}
                                        </span>
                                    </div>

                                    <div
                                        className="userlogin cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowProfileMenu(true);
                                        }}
                                    >
                                        <div className="main-p  flex justify-content-center  align-items-center " onClick={() => setUserDropdown(true)}>
                                            {userData1?.picture ? (
                                                <img
                                                    src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                                    alt={userData1?.name || "User"}
                                                    onError={(e) => {
                                                        e.target.style.display = "none";
                                                    }}
                                                />
                                            ) : (
                                                <span className="profilename" onClick={() => setUserDropdown(true)}>
                                                    {userData1?.name
                                                        ? userData1.name
                                                              .split(" ") // Split the name into words
                                                              .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                              .join("") // Join the letters
                                                        : "NA"}{" "}
                                                    {/* Default fallback */}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {userdropdown && (
                                        <div className="profilemenu" onMouseLeave={() => setUserDropdown(false)}>
                                            <div className="profilemenuinner">
                                                <div className="info w-full">
                                                    <div className="userinfo">
                                                        <div className="profuser">
                                                            {userData1?.picture ? (
                                                                <img
                                                                    className="imgmargin"
                                                                    src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                                                    alt={userData1?.name || "User"}
                                                                    onError={(e) => {
                                                                        e.target.style.display = "none";
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div className="flex justify-content-center align-items-center imgmargin">
                                                                    <span className="profilename" onClick={() => setUserDropdown(true)}>
                                                                        {userData1?.name
                                                                            ? userData1.name
                                                                                  .split(" ") // Split the name into words
                                                                                  .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                                                  .join("") // Join the letters
                                                                            : "NA"}{" "}
                                                                        {/* Default fallback */}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="userinfo">
                                                        <h1 style={{ paddingTop: "1px", height: "13px" }}>{userData1?.name}</h1>
                                                        <p>{userData?.email}</p>
                                                    </div>
                                                    <div style={{ marginTop: "-24px" }} className="userbtn">
                                                        {userData?.role?.role}
                                                    </div>
                                                </div>
                                                <div
                                                    className="data w-full"
                                                    onClick={() => {
                                                        setProfileSetting(true);
                                                        setUserDropdown(false);
                                                    }}
                                                >
                                                    <div>
                                                        <ProfilesettingIcon />
                                                    </div>
                                                    <div>
                                                        <span style={{ marginLeft: "5px" }}> Profile Settings</span>
                                                    </div>
                                                </div>

                                                <div className="data1 w-full">
                                                    <div>
                                                        <DarkTheme />
                                                    </div>
                                                    <div>
                                                        <span style={{ marginLeft: "5px" }}> Dark Mode</span>
                                                    </div>
                                                    <div className="flex justify-content-center align-items-center" style={{ marginLeft: "32%" }}>
                                                        <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                                                    </div>
                                                </div>

                                                <div
                                                    className="data2 w-full"
                                                    onClick={() => {
                                                        handleLogout();
                                                        setUserDropdown(false);
                                                    }}
                                                >
                                                    <div>
                                                        <LogoutIcon />
                                                    </div>
                                                    <div>
                                                        <span style={{ marginLeft: "5px" }}> Logout</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                style={{ background: "#FFFFFF" }}
                                className="flex justify-content-center listbox"
                            >
                                <ListBox
                                    value={props.searchBy}
                                    style={{ display: `${visibleSearch === true ? "block" : "none"}`, backgroundColor: "#FFFFFF" }}
                                    onChange={(e) => {
                                        if (e.value !== null) {
                                            props.setSearchByValueClick(false);
                                            props.setSearchBy(e.value);
                                            setVisibleSearch(false);
                                        }
                                    }}
                                    options={countries}
                                    optionLabel="name"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (e.target.textContent === "Advance Search") {
                                        }
                                    }}
                                    itemTemplate={countryTemplate}
                                    className="w-full "
                                    listStyle={{ maxHeight: "250px" }}
                                />
                            </div>
                            <div className="flex  ">
                                <Sidebar closeable={false} style={{ width: "408px" }} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                                    <div className="flex justify-content-start align-items-center" style={{ height: "25px", width: "116px", marginTop: "15px" }}>
                                        <button
                                            className={allNotification ? "allbtnactive" : "allbtn"}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setUnreadNotification(false);
                                                setAllNotification(true);
                                            }}
                                        >
                                            ALL
                                        </button>
                                        <button
                                            className={unreadNotification ? "unreadbtnactive" : "unreadbtn"}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setUnreadNotification(true);
                                                setAllNotification(false);
                                            }}
                                        >
                                            Unread
                                        </button>
                                    </div>
                                    <hr />
                                    <div style={{ cursor: "pointer" }} className="notificationunread">
                                        <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                            <div className="tooltip-container mt-2">
                                                <SinptelNotification />
                                                <span className="custom-tooltip"> ToolTip</span>
                                            </div>
                                            <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                <h2>Monthly Summary for Nov, 2024</h2>
                                                <p>November summary is ready! Check key stats across modules.</p>
                                                <div className="flex w-full justify-content-between" style={{ cursor: "pointer" }}>
                                                    <h5>Thursday Nov, 14</h5>
                                                    <span onClick={() => setnotesidebar(true)} style={{ fontSize: "12px", color: "#475CED", fontWeight: "600" }}>
                                                        View Full Summary
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {unreadNotification
                                        ? unreadNotes.map((item, index) => (
                                              <div
                                                  key={index}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                      handleNavigate(item?.customerId);
                                                      setVisibleRight(false);
                                                      handleReadNotification(item?._id);
                                                      props.setHandleHighlight(item?.noteId);
                                                  }}
                                                  className={item?.read ? "notificationread" : "notificationunread"}
                                              >
                                                  <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                      <div className="tooltip-container">
                                                          <Prepaidnotification />
                                                          <span className="custom-tooltip"> ToolTip</span>
                                                      </div>
                                                      <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                          <h2>New Prepaid SIM Activation Request</h2>
                                                          <p>{item.message}</p>
                                                          <span style={{ cursor: "pointer" }}>
                                                              <h5
                                                                  style={{ fontSize: "12px" }}
                                                                  onClick={() => {
                                                                      handleReadNotification(item?._id);
                                                                  }}
                                                              >
                                                                  {new Date(item?.timestamp).toLocaleDateString("en-US", {
                                                                      weekday: "long",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  })}
                                                              </h5>
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))
                                        : notification.map((item, index) => (
                                              <div
                                                  key={index}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                      handleNavigate(item?.customerId);
                                                      setVisibleRight(false);
                                                      handleReadNotification(item?._id);
                                                      props.setHandleHighlight(item?.noteId);
                                                  }}
                                                  className={item?.read ? "notificationread" : "notificationunread"}
                                              >
                                                  <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                      <div className="tooltip-container">
                                                          <Prepaidnotification />
                                                          <span className="custom-tooltip"> ToolTip</span>
                                                      </div>
                                                      <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                          <h2>New Prepaid SIM Activation Request</h2>
                                                          <p>{item.message}</p>
                                                          <span style={{ cursor: "pointer" }}>
                                                              <h5
                                                                  style={{ fontSize: "12px" }}
                                                                  onClick={() => {
                                                                      handleReadNotification(item?._id);
                                                                  }}
                                                              >
                                                                  {new Date(item?.timestamp).toLocaleDateString("en-US", {
                                                                      weekday: "long",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  })}
                                                              </h5>
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))}
                                </Sidebar>
                            </div>
                            <div className="flex justify-content-center align-items-center  ">
                                <Sidebar className="flex justify-content-center align-items-center " closeable={false} style={{ width: "808px" }} visible={profileSetting} position="right" onHide={() => setProfileSetting(false)}>
                                    {showeditprofile ? (
                                        <div>
                                            <div className="userprofilemain">
                                                <div className="profileinfo">
                                                    <div className="profilename" onClick={() => setUserDropdown(true)}>
                                                        <div>
                                                            <button>
                                                                {userData1?.picture ? (
                                                                    <img
                                                                        style={{ width: "80px", height: "80px", marginleft: "-10px" }}
                                                                        src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                                                        alt={userData1?.name || "User"}
                                                                        onError={(e) => {
                                                                            e.target.style.display = "none";
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span className="profilename" onClick={() => setUserDropdown(true)} style={{ fontSize: "28px", fontWeight: "500", color: "#9C9EB6" }}>
                                                                        {userData1?.name
                                                                            ? userData1.name
                                                                                  .split(" ") // Split the name into words
                                                                                  .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                                                  .join("") // Join the letters
                                                                            : "NA"}{" "}
                                                                        {/* Default fallback */}
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                        <div className="mailinfoedit">
                                                            <button className="deletepic" onClick={handleDelete}>
                                                                Delete Picture
                                                            </button>
                                                            <div>
                                                                <div>
                                                                    <FileUpload mode="basic" name="demo[]" accept="image/*" customUpload uploadHandler={customBase64Uploader} chooseLabel={uploadedFileName} auto />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="profileeditbtn"
                                                        onMouseEnter={() => setEdit(true)}
                                                        onMouseLeave={() => setEdit(false)}
                                                        onClick={() => {
                                                            setShoweditprofile(true);
                                                        }}
                                                    >
                                                        <span>{edit ? <EditHover /> : <Edit />}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="userprofiledetails">
                                                <div className="userprofiledetailsinner">
                                                    <div className="flex flex-row w-full justify-content-between">
                                                        <div>
                                                            <h1>Details</h1>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-2">
                                                        <div className="flex w-full justify-content-between">
                                                            <label className={formik.touched.fullName && formik.errors.fullName ? "labelerr" : " "}>Full Name</label>
                                                            {formik.touched.fullName && formik.errors.fullName ? <span className="err">{formik.errors.fullName}</span> : ""}
                                                        </div>
                                                        <InputText id="fullName" className={formik.touched.fullName && formik.errors.fullName ? "inputfielderr mt-2" : "inputfield mt-2"} type="text" value={formik.values.fullName} onChange={formik.handleChange} onBlur={formik.handleBlur}></InputText>
                                                    </div>

                                                    <div className="flex flex-column w-full justify-content-between mt-5">
                                                        <label> Company Name</label>
                                                        <InputText className="inputfield mt-2" type="text" value={userData?.companyName} disabled></InputText>
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-5">
                                                        <label>Email</label>
                                                        <InputText className="inputfield mt-2" type="text" value={userData?.email} disabled></InputText>
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-5">
                                                        <label>Role</label>
                                                        <InputText className="inputfield mt-2" type="text" value={userData?.role?.role} disabled></InputText>
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-5" style={{ marginBottom: "40px" }}>
                                                        <label> Department</label>
                                                        <InputText className="inputfield mt-2" type="text" value={department} disabled></InputText>
                                                    </div>
                                                    <div className="flex w-full justify-content-end">
                                                        <button
                                                            className="backbtn"
                                                            onClick={() => {
                                                                formik.setFieldValue("fullName", userData.userName);

                                                                setShoweditprofile(false);
                                                            }}
                                                        >
                                                            Back
                                                        </button>
                                                        <div className=" flex justify-content-center" style={{ marginLeft: "10px" }}>
                                                            <Button
                                                                type="submit"
                                                                label="Update"
                                                                className="submitbtn"
                                                                onClick={() => {
                                                                    formik.handleSubmit();
                                                                    setUpload((prev) => !prev);
                                                                    setProfileSetting(false);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="userprofilemain">
                                                <div className="profileinfo">
                                                    <div className="profilename" onClick={() => setUserDropdown(true)}>
                                                        <div>
                                                            <button>
                                                                {userData1?.picture ? (
                                                                    <img
                                                                        style={{ width: "80px", height: "80px", marginleft: "-10px" }}
                                                                        src={`${BASE_URL}/${userData1.picture.replace(/^uploads\//, "")}`}
                                                                        alt={userData1?.name || "User"}
                                                                        onError={(e) => {
                                                                            e.target.style.display = "none";
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span className="profilename" style={{ fontSize: "28px", fontWeight: "500", color: "#9C9EB6" }}>
                                                                        {userData1?.name
                                                                            ? userData1.name
                                                                                  .split(" ") // Split the name into words
                                                                                  .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
                                                                                  .join("") // Join the letters
                                                                            : "NA"}{" "}
                                                                        {/* Default fallback */}
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                        <div className="mailinfo">
                                                            <h1>{userData1?.name}</h1>
                                                            <p>{userData?.email}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="profileeditbtn"
                                                        onMouseEnter={() => setEdit(true)}
                                                        onMouseLeave={() => setEdit(false)}
                                                        onClick={() => {
                                                            setShoweditprofile(true);
                                                        }}
                                                    >
                                                        <span>{edit ? <EditHover /> : <Edit />}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="userprofiledetails">
                                                <div className="userprofiledetailsinner">
                                                    <div className="flex flex-row w-full justify-content-between">
                                                        <div>
                                                            <h1>Details</h1>
                                                        </div>
                                                        {/* <div>
                                                        <h5>
                                                            User Id : <span> ETC12345</span>
                                                        </h5>
                                                    </div> */}
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-5">
                                                        <label> Full Name</label>
                                                        <InputText className="inputfield mt-2" type="text" value={formik.values.fullName} contentEditable={false}></InputText>
                                                    </div>
                                                    {/* <div className="flex flex-column w-full justify-content-between mt-5">
                                                    <label> Last Name</label>
                                                    <InputText className="inputfield mt-2" type="text" value={"Wick"} contentEditable={false}></InputText>
                                                </div> */}
                                                    <div className="flex flex-column w-full justify-content-between mt-5">
                                                        <label> Company Name</label>
                                                        <InputText className="inputfield mt-2" type="text" value={userData?.companyName} contentEditable={false}></InputText>
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-5">
                                                        <label>Email</label>
                                                        <InputText className="inputfield mt-2" type="text" value={userData?.email} contentEditable={false}></InputText>
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-5">
                                                        <label>Role</label>
                                                        <InputText className="inputfield mt-2" type="text" value={userData?.role?.role} contentEditable={false}></InputText>
                                                    </div>
                                                    <div className="flex flex-column w-full justify-content-between mt-5" style={{ marginBottom: "40px" }}>
                                                        <label> Department</label>
                                                        <InputText className="inputfield mt-2" type="text" value={department} contentEditable={false}></InputText>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Sidebar>
                            </div>
                        </div>
                        <div className="appsicons">
                            <div className="flex  w-full bggradapps" style={{ position: "fixed", zIndex: "0", height: "100%" }}>
                                <Loginbg />
                            </div>
                            <div className="flex flex-column justify-content-center align-items-center w-full" style={{ zIndex: "0" }}>
                                <h1>Effortless Telecom Navigation Simplified for You</h1>
                                <div className="mainappicons">
                                    {props.model[0]?.items?.map((element, index) => {
                                        return (
                                            <div
                                                className="iconcontainer"
                                                onClick={() => {
                                                    props.setShowApps(false);
                                                    setCustomerProfileIcon(false);
                                                    navigate(element.subModules[0].route);
                                                }}
                                                //  onMouseEnter={() => setCustomerProfileIcon(true)}
                                                // onMouseLeave={() => setCustomerProfileIcon(false)}
                                            >
                                                <div className={`main-app-container-top ${CustomerProfileIcon ? "appinnerhover" : "appinner"}`}>
                                                    <div className="main-app-container-icon" dangerouslySetInnerHTML={{ __html: element?.icon }} />
                                                    <h3>{element.moduleName}</h3>
                                                    <p>{element?.description}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Sidebar closeable={false} style={{ width: "808px" }} visible={notesidebar} position="right" onHide={() => setnotesidebar(false)}>
                <div className="monthlymain ">
                    <div className="monthlyinner">
                        <div className="header">
                            <div>
                                <img src="./monthlysniptel.png" alt="" />
                            </div>
                            <div className="flex flex-column">
                                <h1>Monthly & Weekly Insights</h1>
                                <p>Track module performance with Sniptel's weekly and monthly insights.</p>
                            </div>
                        </div>
                        <div className="count mt-5">
                            <div
                                className={monthlyNote ? "monthlyactive " : "monthly"}
                                style={{ cursor: "pointer", borderBottom: monthlyNote ? "1px solid #475CED" : "" }}
                                onClick={() => {
                                    setMonthlyNotes(true);
                                    setWeeklyNotes(false);
                                }}
                            >
                                <p>
                                    Monthly <span className="span">25</span>
                                </p>
                            </div>
                            <div
                                className={weeklyNotes ? "weeklyactive" : "weekly"}
                                style={{ cursor: "pointer", borderBottom: weeklyNotes ? "1px solid #475CED" : "" }}
                                onClick={() => {
                                    setMonthlyNotes(false);
                                    setWeeklyNotes(true);
                                }}
                            >
                                <p>
                                    Weekly <span className="span">25</span>
                                </p>
                            </div>
                        </div>

                        <div className="maindata ">
                            <div className="innerdata mt-3">
                                <div className="ml-4" style={{ marginTop: "12px" }}>
                                    <Prepaidnotification />
                                </div>
                                <div className="flex flex-column justify-content-center ml-4 notedata">
                                    <h1>Prepaid</h1>
                                    <p>In Prepaid, 10 new orders were added, 8 customers were activated, and 30 are pending activation</p>{" "}
                                </div>
                            </div>
                            <div className="innerdata mt-3">
                                <div className="ml-4" style={{ marginTop: "12px" }}>
                                    <Prepaidnotification />
                                </div>
                                <div className="flex flex-column justify-content-center ml-4 notedata">
                                    <h1>Prepaid</h1>
                                    <p>In Prepaid, 10 new orders were added, 8 customers were activated, and 30 are pending activation</p>{" "}
                                </div>
                            </div>
                            <div className="innerdata mt-3">
                                <div className="ml-4" style={{ marginTop: "12px" }}>
                                    <Prepaidnotification />
                                </div>
                                <div className="flex flex-column justify-content-center ml-4 notedata">
                                    <h1>Prepaid</h1>
                                    <p>In Prepaid, 10 new orders were added, 8 customers were activated, and 30 are pending activation</p>{" "}
                                </div>
                            </div>
                            <div className="innerdata mt-3">
                                <div className="ml-4" style={{ marginTop: "12px" }}>
                                    <Prepaidnotification />
                                </div>
                                <div className="flex flex-column justify-content-center ml-4 notedata">
                                    <h1>Prepaid</h1>
                                    <p>In Prepaid, 10 new orders were added, 8 customers were activated, and 30 are pending activation</p>{" "}
                                </div>
                            </div>
                            <div className="innerdata mt-3">
                                <div className="ml-4" style={{ marginTop: "12px" }}>
                                    <Prepaidnotification />
                                </div>
                                <div className="flex flex-column justify-content-center ml-4 notedata">
                                    <h1>Prepaid</h1>
                                    <p>In Prepaid, 10 new orders were added, 8 customers were activated, and 30 are pending activation</p>{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    );
};
