import React, { useEffect, useState } from "react";
import "./css/PayInvoicesMain.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../components/Loader/LoaderBlue";
import NotFoundData from "../../Common/Notfound/Notfound";
import { PayInvoiceDialog } from "./components/PayInvoiceDialog/PayInvoiceDialog";
import { CheckMark } from "./components/PayInvoiceDialog/assets/PayInvoiceAsset";
import { CancelPopup } from "../../../Files/components/CustomerFilesData/assets/Assets";
import { Button } from "primereact/button";
export const PayInvoicesMain = ({ refreshAllpayment, cpData }) => {
    const [allInvoicesData, setAllInvoicesData] = useState([]);
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
    const [isAPiCalled, setIsApiCalled] = useState(false);
    const [showPayInvoiceDialog, setShowPayInvoiceDialog] = useState(false);
    const [selectedInvoiceData, setSelectedInvoiceData] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [refreshPayInvoice, setRefreshPayInvoice] = useState(false);
    const getInvoice = async () => {
        api.get(`/api/web/invoices/unPaidInvoices?customerId=${cpData?._id}`)
            .then((invoiceResponse) => {
                let invoices = invoiceResponse?.data?.data;

                // Find if there is any "Sign Up" invoice
                const hasSignUpInvoice = invoices.some((invoice) => invoice.invoiceType === "SignUp" || invoice.invoiceType === "Sign Up");

                // Find the last "Monthly" invoice index
                const lastMonthlyIndex = invoices
                    .map((invoice, index) => ({ ...invoice, index }))
                    .filter((invoice) => invoice.invoiceType === "Monthly")
                    .pop()?.index;

                // Update invoices
                const updatedInvoices = invoices.map((invoice, index) => ({
                    ...invoice,
                    isDisabled: invoice.invoiceType === "Monthly" && (index !== lastMonthlyIndex || hasSignUpInvoice), // Disable last one too if "Sign Up" exists
                }));

                setAllInvoicesData(updatedInvoices);
                setIsApiCalled(true);
                setIsInvoiceLoading(false);
            })
            .catch((err) => {
                setAllInvoicesData([]);

                setIsApiCalled(true);
                setIsInvoiceLoading(false);
            });
    };
    useEffect(() => {
        if (cpData !== undefined) {
            getInvoice();
            setSelectedRows([]);
        }
    }, [refreshPayInvoice, refreshAllpayment]);
    return (
        <div className="main-pay-invoice-container">
            {!isInvoiceLoading && isAPiCalled && allInvoicesData.length === 0 ? (
                <NotFoundData currentNoFoundData={"invoice"} />
            ) : (
                <DataTable
                    onRowClick={(e) => {
                        if (!e.data.isDisabled) {
                            console.log("event is", e);
                            //setSelectedInvoiceData(e.data);
                            //setShowPayInvoiceDialog(true);
                            if (selectedRows.length < 1) {
                                setSelectedRows([e.data]);
                                setShowPayInvoiceDialog(true);
                            }
                        }
                    }}
                    value={allInvoicesData}
                >
                    <Column
                        field="invoiceNo"
                        header={() => {
                            return (
                                <div tooltip="hello" className="main-header-filetype flex flex-wrap flex-row  align-items-center">
                                    <div>
                                        {" "}
                                        <div
                                            onClick={() => {
                                                if (selectedRows.length < allInvoicesData.length) {
                                                    setSelectedRows(allInvoicesData.filter((invoice) => !invoice.isDisabled));
                                                } else {
                                                    setSelectedRows([]);
                                                }
                                            }}
                                            className={`${allInvoicesData.length > 0 && selectedRows.length === allInvoicesData.length ? "checked" : ""}`}
                                        >
                                            {allInvoicesData.length > 0 && selectedRows.length === allInvoicesData.length && <CheckMark />}
                                        </div>
                                    </div>
                                    <p>Invoice No</p>
                                </div>
                            );
                        }}
                        body={(rowData, { rowIndex }) => {
                            const ischecked = selectedRows.some((item) => rowData.invoiceNo === item.invoiceNo);

                            return (
                                <div className="main-row-filetype flex  flex-row   align-items-center">
                                    {rowData?.isDisabled && <div style={{ position: "absolute", marginLeft: "-8px", height: "4.2rem", width: "100%", backgroundColor: "rgba(255,255,255,0.5)" }}></div>}

                                    <div>
                                        <div
                                            className={`${ischecked ? "checked" : ""}`}
                                            onClick={(e) => {
                                                if (!rowData.isDisabled) {
                                                    e.stopPropagation();
                                                    if (selectedRows.some((item) => rowData.invoiceNo === item.invoiceNo)) {
                                                        setSelectedRows(selectedRows.filter((item) => item.invoiceNo !== rowData.invoiceNo));
                                                    } else {
                                                        setSelectedRows([...selectedRows, rowData]);
                                                    }
                                                }
                                            }}
                                        >
                                            {ischecked && <CheckMark />}
                                        </div>
                                    </div>
                                    <p>{rowData?.invoiceNo ? (rowData?.invoiceNo?.length > 13 ? rowData?.invoiceNo?.substring(0, 12) + "..." : rowData?.invoiceNo) : "NIL"}</p>
                                </div>
                            );
                        }}
                    />
                    <Column
                        header="Created Date"
                        body={(rowData) => {
                            return (
                                <span>
                                    {moment(rowData.createdAt)
                                        .tz("America/New_York") // Convert to New York time (Eastern Time Zone)
                                        .format("MM-DD-YYYY hh:mm:ss")}
                                </span>
                            );
                        }}
                    />
                    <Column
                        field="dueAmount"
                        body={(rowData) => {
                            return <p>{rowData?.currentBalance !== "NaN" && rowData?.currentBalance !== null && rowData?.currentBalance !== "" && rowData?.currentBalance !== undefined ? parseFloat(rowData?.currentBalance).toFixed(2) : "NIL"}</p>;
                        }}
                        header="Current Balance"
                    />
                    <Column
                        field="processingFee"
                        body={(rowData) => {
                            return <p>{rowData?.processingFee !== "NaN" && rowData?.processingFee !== null && rowData?.processingFee !== "" && rowData?.processingFee !== undefined ? rowData?.processingFee : "NIL"}</p>;
                        }}
                        header="Processing  Fee"
                    />
                </DataTable>
            )}
            {showPayInvoiceDialog && <PayInvoiceDialog selectedInvoices={selectedRows} setShowPayInvoiceDialog={setShowPayInvoiceDialog} setRefreshPayInvoice={setRefreshPayInvoice} cpData={cpData} setSelectedInvoiceData={setSelectedInvoiceData} />}
            {isInvoiceLoading ? (
                <div className="loader-wrapper">
                    <CommonLoaderBlue color="royalblue" sizehere={21} />
                </div>
            ) : (
                ""
            )}
            {selectedRows?.length > 0 && (
                <div className="flex flex-column justify-content-start align-items-center selecteddatapopup">
                    <div className="flex w-full justify-content-end">
                        <span onClick={() => setSelectedRows([])} style={{ cursor: "pointer" }}>
                            <CancelPopup />
                        </span>
                    </div>
                    <div className="flex justify-content-between w-full align-items-center selectedrowdata   ">
                        <div className="show-details-row flex flex-wrap fex-row justify-content-start align-items-center">
                            <p>Selected:</p>
                            <p>{selectedRows?.length}</p>
                        </div>

                        <Button
                            className="adduserbtn"
                            onClick={() => {
                                setShowPayInvoiceDialog(true);
                            }}
                            label="Pay Invoice"
                            style={{ width: "12rem" }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
