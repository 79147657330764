import React from "react";
export const DataNotFounfInvoicePaymentLogs = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 16.667V10.8337C5 8.53247 6.86548 6.66699 9.16667 6.66699H30.8333C33.1345 6.66699 35 8.53247 35 10.8337V15.8337" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M34.9999 24.167V29.167C34.9999 31.4682 33.1344 33.3337 30.8333 33.3337H18.3333" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.0001 31.6663V23.333" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.66675 26.6663L10.0001 23.333" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.3333 26.6663L10 23.333" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.4999 15.833H34.9999C35.9204 15.833 36.6666 16.5792 36.6666 17.4997V22.4997C36.6666 23.4201 35.9204 24.1663 34.9999 24.1663H32.4999C30.1987 24.1663 28.3333 22.3009 28.3333 19.9997V19.9997C28.3333 17.6985 30.1987 15.833 32.4999 15.833V15.833Z"
                stroke="#9C9EB6"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
export const InvoiceNotFoundIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6672 14.9977H28.3367" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.6633 14.5408L13.1956 15.9214L16.2803 13.1436" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M16.6652 33.3387H13.3305C8.72617 33.3387 4.99365 29.6062 4.99365 25.0019V11.663C4.99365 7.05869 8.72617 3.32617 13.3305 3.32617H26.6693C28.8804 3.32617 31.0009 4.20451 32.5644 5.76797C34.1278 7.33142 35.0062 9.45192 35.0062 11.663V16.6651"
                stroke="#9C9EB6"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M11.6633 22.8777L13.1956 24.2583L16.2803 21.4805" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.3347 23.167V32.6716C23.3364 33.9603 25.9471 35.0056 29.1696 35.0056C32.3922 35.0056 35.0029 33.9603 35.0046 32.6716V23.167" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.3364 27.9189C23.3364 29.2076 25.9488 30.2529 29.1714 30.2529C32.3939 30.2529 35.0063 29.2076 35.0063 27.9189" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M23.3364 23.167C23.3364 24.4557 25.9488 25.501 29.1713 25.501C32.3939 25.501 35.0046 24.4557 35.0046 23.167C35.0046 21.8766 32.3905 20.833 29.1696 20.833C25.9488 20.833 23.3364 21.8783 23.3347 23.167"
                stroke="#9C9EB6"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
export const FilesNotFoundIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 25L20 20" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20 20L15 25" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20 33.3333V20" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M13.3333 31.667H9.99998C6.31808 31.667 3.33331 28.6822 3.33331 25.0003C3.33331 21.3184 6.31808 18.3337 9.99998 18.3337L9.98331 18.2164C9.56849 15.3126 10.4342 12.3718 12.356 10.1558C14.2779 7.93991 17.0667 6.66701 20 6.66699C22.9332 6.66701 25.7221 7.93991 27.6439 10.1558C29.5658 12.3718 30.4315 15.3126 30.0166 18.2164L30 18.3337C33.6819 18.3337 36.6666 21.3184 36.6666 25.0003C36.6666 28.6822 33.6819 31.667 30 31.667H26.6666"
                stroke="#9C9EB6"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
export const UageNotFoundIcon = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 25.3337H12C11.0795 25.3337 10.3333 24.5875 10.3333 23.667V21.7519C10.3333 21.3734 10.4621 21.0063 10.6985 20.7107L13.6666 17.0003L10.6983 13.2881C10.462 12.9927 10.3333 12.6256 10.3333 12.2473V10.3337C10.3333 9.41318 11.0795 8.66699 12 8.66699H22C22.9205 8.66699 23.6666 9.41318 23.6666 10.3337V12.249C23.6666 12.6275 23.5378 12.9947 23.3014 13.2902L20.3333 17.0003L23.3014 20.7105C23.5378 21.006 23.6666 21.3732 23.6666 21.7516V23.667C23.6666 24.5875 22.9205 25.3337 22 25.3337Z"
                stroke="#9C9EB6"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect x="2" y="2" width="30" height="30" rx="8.33333" stroke="#9C9EB6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
