import React from "react";
import "./css/files.css";
import { CustomerFilesData } from "./components/CustomerFilesData/CustomerFilesData";
import { UploadCustomerFiles } from "./components/UploadFiles/UploadCustomerFiles";
export const MainCustomerFile = ({ cpData }) => {
    return (
        <div className="main-files">
            <CustomerFilesData cpData={cpData} />
            <UploadCustomerFiles cpData={cpData} />
        </div>
    );
};
