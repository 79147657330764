import React, { useEffect, useRef, useState } from "react";
import "./css/InventoryList.css";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { api } from "../../../../../../../utility/AxiosInceptor";
import { Dialog } from "primereact/dialog";
import AddInventoryAgainstBillingModel from "./AddInventory/AddInventory";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import { CancelPopup, PlanEditIcon } from "../../../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
import CommonLoaderBlue from "../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
export default function InventoryList({ data, billingModelSelect, inventorySelect, setUpdateInventory, inventorySelected, setBillingModelFromList, setInventorySelected, setRefresh, refresh }) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // let toast = useRef();
    const [statusLoading, setStatusLoading] = useState(false);
    const [statusChangeVisibility, setStatusChangeVisibility] = useState(false);
    const [addInventory, setAddInventory] = useState(false);
    // const [inventorySelected, setInventorySelected] = useState();
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const [loader, setLoader] = useState(true);
    // const [updateInventory, setUpdateInventory] = useState(false);
    const [InventoryList, setinventoryList] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [refreshUser, setRefreshUser] = useState(false);
    const op = useRef(null);

    const updateBillingModelStatus = () => {
        setStatusLoading(true);
        api.put(`/api/inventoryType/statusUpdate?inventoryTypeId=${inventorySelected._id}&active=${inventorySelected?.active ? false : true}`)
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Inventory Type Status Updation", detail: `Inventory Type ${inventorySelected?.active ? "Deactivated" : "Activated"} Successfully` });

                setTimeout(() => {
                    setStatusLoading(false);
                    setStatusChangeVisibility(false);
                    setRefresh((prev) => !prev);
                    if (op.current) {
                        op.current.hide();
                    }
                }, 500);
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Inventory Type Status Updation", detail: `Inventory Type ${inventorySelected?.active ? "Deactivation" : "Activation"} Failed` });
                setStatusLoading(false);
                if (op.current) {
                    op.current.hide();
                }
            });
    };
    useEffect(() => {
        api.get(`/api/inventoryType/all`, {
            params: {
                serviceProvider: parseLoginRes?.company,
                billingModel: data?._id,
            },
        })
            .then((res) => {
                setinventoryList(res?.data?.data);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
            });
    }, [inventorySelect, refreshUser]);
    const handleActive = () => {
        const inventoryTypeIds = selectedInventory.map((user) => user._id);
        const dataToSend = {
            inventoryTypeIds,
            active: true,
        };

        api.put(`/api/inventoryType/statusUpdateMultiple`, dataToSend)
            .then((res) => {
                toast.success(res?.data?.msg);
                setSelectedInventory([]);
                setRefreshUser((prev) => !prev);
            })
            .catch((err) => {
                toast.error(err?.res?.data?.msg);
            });
    };
    const handleDeactive = () => {
        const inventoryTypeIds = selectedInventory.map((user) => user._id);

        const dataToSend = {
            inventoryTypeIds,
            active: false,
        };

        api.put(`/api/inventoryType/statusUpdateMultiple`, dataToSend)
            .then((res) => {
                toast.success(res?.data?.msg);
                setSelectedInventory([]);
                setRefresh((prev) => !prev);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.msg || "Something went wrong!");
            });
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="inventorylisttable">
                {selectedInventory?.length > 0 && (
                    <div className="flex flex-column justify-content-start align-items-center selecteddatapopup">
                        <div className="flex w-full justify-content-end">
                            <span onClick={() => setSelectedInventory([])} style={{ cursor: "pointer" }}>
                                <CancelPopup />
                            </span>
                        </div>
                        <div className="flex justify-content-start w-full align-items-center selectedrowdata ">
                            <div className="flex showselectedroles" style={{ width: "12rem", marginLeft: "2.4rem" }}>
                                <p>
                                    Selected: <span>{selectedInventory?.length}</span>
                                </p>
                            </div>
                            <div className="flex" style={{ width: "25.6rem", marginLeft: "8.8rem", gap: "1.2rem" }}>
                                <Button onClick={handleDeactive} className="addnewrolebtn" label="Deactive" style={{ width: "12rem" }} />
                                <Button onClick={handleActive} className="adduserbtn" label="Active" style={{ width: "12rem" }} />
                            </div>
                        </div>
                    </div>
                )}
                <DataTable
                    value={InventoryList}
                    size="small"
                    emptyMessage={
                        <div style={{ position: "absolute", marginTop: "-1.4rem", width: "108.4rem" }} className="flex flex-wrap justify-content-center align-items-center">
                            <CommonLoaderBlue color="royalblue" customSize={14} />
                        </div>
                    }
                    selectionMode="checkbox"
                    selection={selectedInventory}
                    onSelectionChange={(e) => setSelectedInventory(e.value)}
                    className="inventoryListtable"
                    stripedRows
                    resizableColumns
                    // emptyMessage={
                    //     loader ? (
                    //         <div className="flex flex-wrap flex-row justify-content-center align-items-center ">
                    //             <CommonLoaderBlue customSize={15} />
                    //         </div>
                    //     ) : (
                    //         "Inventory Type Not Found"
                    //     )
                    // }
                    style={{ marginTop: "2.4rem" }}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: "1rem" }}></Column>
                    <Column header="Inventory Type" field="inventoryType" />
                    <Column header="Make" field="make" />
                    <Column header="Model" field="model" />
                    <Column header="Selling Price" field="sellingPrice" />
                    <Column header="Discount" field="discount" />
                    <Column header="Profit Margin" field="profitMargin" />
                    <Column header="Identifier" field="identifierType.identifier" />
                    <Column header="Status" field="active" body={(rowData) => <label className={rowData?.active ? "active" : "deactive"}> {rowData?.active ? "Active" : "Deactive"}</label>} />
                    <Column
                        header="Action"
                        headerClassName="p-3"
                        body={(rowData) => {
                            return (
                                <>
                                    <div
                                        onClick={(e) => {
                                            setInventorySelected(rowData);
                                            setRowData(rowData);
                                            setBillingModelFromList(rowData?.billingModel?._id);
                                            op.current.toggle(e);
                                        }}
                                        className="actionbtnmain flex justify-content-end"
                                    >
                                        <PlanEditIcon />
                                    </div>
                                </>
                            );
                        }}
                        field="Edit"
                    />

                    {/* <Column
                        header="Actions"
                        headerClassName="p-3"
                        body={(rowData) => {
                            return (
                                <div style={{ width: "250px" }} className="actionsbilling flex flex-wrap flex-row justify-content-left align-items-center gap-4">
                                    <Button
                                        label=""
                                        style={{ height: "25px", paddingTop: "0px !imprtant", paddingBottom: "0px !important" }}
                                        icon="pi pi-pencil"
                                        onClick={() => {
                                            setInventorySelected(rowData);
                                            setUpdateInventory(true);
                                        }}
                                    />

                                    {!rowData?.active ? (
                                        <InputSwitch
                                            checked={false}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setInventorySelected(rowData);
                                                setStatusChangeVisibility(true);
                                            }}
                                        />
                                    ) : (
                                        <InputSwitch
                                            checked={true}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setInventorySelected(rowData);
                                                setStatusChangeVisibility(true);
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        }}
                        field="Edit"
                    /> */}
                </DataTable>
                <OverlayPanel ref={op} style={{ width: "10.9em", height: "11.2rem" }} className="flex align-items-center inventorylist">
                    <div className="flex flex-column w-full overlay">
                        <div
                            className={rowData?.active ? "flex justify-content-start align-items-center prospectoverlay" : "flex justify-content-start align-items-center prospectoverlayactive"}
                            style={{ height: "32px" }}
                            onClick={() => {
                                if (rowData?.active) {
                                    updateBillingModelStatus();
                                    op.current.hide();
                                } else {
                                }
                            }}
                        >
                            <label style={{ fontSize: "1.2rem", fontWeight: "500", color: !rowData?.active ? "#2627CF" : "" }}>Deactive</label>
                        </div>
                        <div
                            className={!rowData?.active ? "flex justify-content-start align-items-center prospectoverlay" : "flex justify-content-start align-items-center prospectoverlayactive"}
                            style={{ height: "32px" }}
                            onClick={() => {
                                if (!rowData?.active) {
                                    updateBillingModelStatus();
                                    op.current.hide();
                                } else {
                                }
                            }}
                        >
                            <label style={{ fontSize: "1.2rem", fontWeight: "500", color: rowData?.active ? "#2627CF" : "" }}>Active</label>
                        </div>
                        <div
                            className="flex justify-content-start align-items-center prospectoverlay"
                            style={{ height: "32px" }}
                            onClick={() => {
                                setUpdateInventory(true);
                                if (op.current) {
                                    op.current.hide();
                                }
                            }}
                        >
                            <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Edit</label>
                        </div>
                    </div>
                </OverlayPanel>
            </div>

            {/* <Dialog
                style={{ width: "85vw" }}
                header="Update Inventory"
                visible={updateInventory}
                onHide={() => {
                    setUpdateInventory(false);
                    setRefresh((prev) => !prev);
                }}
            >
                <UpdateInventoryAgainstBilllingModel billingModelSelect={billingModelSelect} inventorySelected={inventorySelected} />
            </Dialog> */}
            <Dialog
                style={{ width: "85vw" }}
                header="Add Inventory"
                visible={addInventory}
                onHide={() => {
                    setAddInventory(false);
                    setRefresh((prev) => !prev);
                }}
            >
                <AddInventoryAgainstBillingModel setRefresh={setRefresh} setAddInventory={setAddInventory} billingModelSelect={billingModelSelect} />
            </Dialog>

            {/* <Dialog
                header={inventorySelected?.active ? "Inventory Type Deactivation" : "Inventory Type Activation"}
                headerClassName="font-poppins text-main-color"
                visible={statusChangeVisibility}
                onHide={() => {
                    setStatusChangeVisibility((prev) => !prev);
                }}
            >
                <p className="text-main-color font-poppins">Do You Want To {inventorySelected?.active ? "Deactivate" : "Activate"} the Inventory Type ?</p>
                <div className="mt-4  changestatusdialogbutton flex font-poppins flex-wrap flex-row ">
                    <Button onClick={updateBillingModelStatus} loading={statusLoading} disabled={statusLoading} label="Yes" />

                    <Button
                        label="No"
                        onClick={() => {
                            setStatusChangeVisibility(false);
                        }}
                    />
                </div>
                <Toast ref={toast} />
            </Dialog> */}
        </div>
    );
}
