import React, { useRef, useState } from "react";
import "./AdjustAmount.css";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../../../components/Loader/LoaderBlue";
export const AdjustAmount = ({ setSelectedAction, cpData, currentInvoice, setCurrentActiveDialog }) => {
    let toast = useRef();
    const LoginRes = JSON.parse(localStorage.getItem("userData"));
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        reason: Yup.string().required("Field Is Required"),
        discountedAmount: Yup.string().required("Field Is Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            reason: "",
            discountedAmount: "",
            customerId: cpData?._id,
            userId: LoginRes?._id,
            invoiceId: currentInvoice?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            api.post(`/api/web/invoices/adjustDiscount`, values)
                .then((res) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "success", summary: "Invoice Adjustment", detail: "Invoice Adjusted Successfully" });
                    setTimeout(() => {
                        setSelectedAction("");
                        setCurrentActiveDialog(false);
                    }, 1000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Invoice Adjustment", detail: err?.response?.data?.message || err?.response?.data?.msg || "Invoice Adjustment  Failed" });
                });
        },
    });
    console.log("curr invoice", currentInvoice);
    return (
        <>
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
                id="invoice-adjust-main"
                className="invoice-adjust-main flex flex-wrap flex-row justify-content-center"
            >
                <div className="main-void-invoice">
                    <div className="header-main-container-voidinvoice">
                        <div className="header-main-void-invoice">
                            <h1>Adjust Amount</h1>
                            <p>Enter the adjustment amount and provide a reason. Ensure accuracy before submitting, as this action affects the invoice balance.</p>
                        </div>
                        <div className="main-bottom-container">
                            <div className="header-bottom-void">
                                <div className="wrapper-info">
                                    <p>Invoice No:</p>
                                    <p>
                                        <span>{currentInvoice?.invoiceNo || "NIL"}</span>
                                    </p>
                                </div>
                                <div className="wrapper-info">
                                    <p>Total Amount Due:</p>
                                    <p>
                                        <span>$ {currentInvoice?.dueAmount || "NIL"}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="input-container-adjust">
                                <div className="void-reason-container ">
                                    <div className="flex flex-wrap w-full flex-row justify-content-between">
                                        <label>Adjust Amount</label>
                                        {formik.touched.discountedAmount && formik.errors.discountedAmount && <label>{formik.errors.discountedAmount}</label>}
                                    </div>
                                    <input type="number" name="discountedAmount" value={formik.values.discountedAmount} className={`${formik.touched.discountedAmount && formik.errors.discountedAmount && "p-invalid"}`} onChange={formik.handleChange} placeholder="$00.00" />
                                </div>
                                <div className="void-reason-container ">
                                    <div className="flex flex-wrap w-full flex-row justify-content-between">
                                        <label>Reason</label>
                                        {formik.touched.reason && formik.errors.reason && <label>{formik.errors.reason}</label>}
                                    </div>
                                    <input name="reason" value={formik.values.reason} className={`${formik.touched.reason && formik.errors.reason && "p-invalid"}`} onChange={formik.handleChange} placeholder="Write reason" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                setSelectedAction("");
                                setCurrentActiveDialog({
                                    void: false,
                                    lateFee: false,
                                    adjustInvoice: false,
                                    download: false,
                                });
                            }}
                            label="Cancel"
                        />
                        <Button
                            type="submit"
                            label={
                                isLoading ? (
                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                    </div>
                                ) : (
                                    "Submit"
                                )
                            }
                        />
                    </div>
                </div>
                <Toast ref={toast} />
            </form>
        </>
    );
};
