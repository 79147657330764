import React from "react";
import axios from "axios";
import { RefreshAccessToken } from "./RefreshAccessToken"; // Your token refresh function
import { toast } from "react-toastify";

// Create an Axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // Ensure the environment variable is set correctly
});

// Variable to keep track of whether the token is being refreshed
let isTokenRefreshing = false;
let failedQueue = [];

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        // Retrieve the token from localStorage
        const userData = localStorage.getItem("userData");
        const token = userData ? JSON.parse(userData).token : null;

        // If a token exists, attach it to the Authorization header
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle errors before the request is sent (although this is usually rare for the request)
        console.error("Request error:", error.message);
        return Promise.reject(error);
    },
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => {
        // Handle successful response
        return response;
    },
    async (error) => {
        const { config, response } = error;
        if (response && response.status === 401 && response?.message === "Session invalid. Please log in again.") {
            toast.error(response.message);
            localStorage.clear();
            window.location.href = "/login";
        } else if (response && response.status === 401) {
            const originalRequest = config;

            // If we are already refreshing the token, queue the failed request
            if (isTokenRefreshing) {
                return new Promise((resolve, reject) => {
                    // Add the original request to the queue to retry later
                    failedQueue.push({ resolve, reject, originalRequest });
                });
            }

            isTokenRefreshing = true;

            try {
                const refreshedToken = await RefreshAccessToken();
                originalRequest.headers["Authorization"] = `Bearer ${refreshedToken}`;
                const retryResponse = await axios(originalRequest);
                failedQueue.forEach(({ resolve }) => resolve(retryResponse));
                failedQueue = [];
                return retryResponse;
            } catch (err) {
                failedQueue.forEach(({ reject }) => reject(err));
                failedQueue = [];
                toast.error(err);
                return Promise.reject(err);
            } finally {
                isTokenRefreshing = false;
            }
        }
        if (response && response.status === 500) {
            console.error("Server error - Try again later");
        }

        return Promise.reject(error); // Reject the promise to propagate the error
    },
);

export { api };
