import Axios from "axios";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "./css/Change_customer.css";
import { api } from "../../../../../utility/AxiosInceptor";
import React, { useState } from "react";
import { TransferException, prospectStatusOptions, activeStatusOptions, suspendStatusOptions, disconnectStatusOptions, connection, connectionExternally } from "./dropdown_options/options";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function ChangeCustomerStatus({ cpData, setChangeCustomerStatus, setRefreshEsn }) {
    const [isLoading, setIsLoading] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [reconnectRestoreReason, setReconnectRestore] = useState();
    const [reconnectionRestoreRequired, setReconnectionRestoreRequired] = useState(false);
    //ConnectionType State
    const [connectionType, setConnectionType] = useState("");
    const [disconnectReason, setdisconnectReason] = useState("");
    const [discconectReasonEror, setdisconnectReasonError] = useState("");
    const [suspendReason, setSuspendReason] = useState("");
    const [suspendReasonError, setSuspendReasonError] = useState(false);
    //StatusType State
    const [statusTo, setStatusTo] = useState(cpData?.status);
    //Option for TransferException
    const transferExceptionOption = TransferException;

    //Options For Connection Type
    const connectionTypeOption = connection;
    const ATTDisconnect = [
        { label: "Non-Payment of Bills", value: "Non-Payment of Bills" },
        { label: "Fraudulent Activity", value: "Fraudulent Activity" },
        { label: "Violation of Legal Requirements", value: "Violation of Legal Requirements" },
        { label: "Request by Subscriber", value: "Request by Subscriber" },
        { label: "Service Termination by Provider", value: "Service Termination by Provider" },
        { label: "Subscriber Ported to Another Operator", value: "Subscriber Ported to Another Operator" },
        { label: "Inactive or Dormant Account", value: "Inactive or Dormant Account" },
        { label: "Service Unavailable", value: "Service Unavailable" },
    ];
    const ATTsuspendreasons = [
        { label: "Non-Payment of Bills (NPG)", value: "Non-PaymentofBills(NPG)" },
        { label: "Fraudulent Activity", value: "FraudulentActivity" },
        { label: "Violation of Terms and Conditions", value: "ViolationofTermsandConditions" },
        { label: "Request by Subscriber", value: "RequestbySubscriber" },
        { label: "Regulatory Compliance", value: "RegulatoryCompliance" },
        { label: "Network Abuse or Misuse", value: "NetworkAbuseorMisuse" },
        { label: "Inactive or Dormant Account", value: "InactiveorDormantAccount" },
    ];
    const ATTreconnecting_restoring = [
        { label: "Payment of Outstanding Bills", value: "PaymentofOutstandingBills" },
        { label: "Resolution of Fraud or Security Issues", value: "ResolutionofFraudorSecurityIssues" },
        { label: "Compliance with Terms and Conditions", value: "CompliancewithTermsandConditions" },
        { label: "Request by Subscriber", value: "RequestbySubscriber" },
        { label: "Completion of Identity Verification", value: "CompletionofIdentityVerification" },
        { label: "Resolution of Technical Issues", value: "ResolutionofTechnicalIssues" },
        { label: "Settlement of Legal or Regulatory Issues", value: "SettlementofLegalorRegulatoryIssues" },
    ];
    const UpdateStatus = async () => {
        if (statusTo === "labelPrinted") {
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };

            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Success label Printed";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "preShipment") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };

            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    setIsLoading(true);
                    const successMessage = response?.data?.msg || "Successfully Pre shipped";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "inTransit") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };

            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Success In ";

                    setTimeout(() => {
                        setRefreshEsn((prev) => !prev);
                        toast.success(successMessage);
                        setIsLoading(false);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "delivered") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };

            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully delivered";

                    setTimeout(() => {
                        setIsLoading(false);
                        toast.success(successMessage);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "active" && cpData?.status === "prospect" && connectionType == "Internally") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    setChangeCustomerStatus(false);
                    const successMessage = response?.data?.msg || "Successfully Active";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "labelCreated" && connectionType == "Externally") {
            setIsLoading(true);
            const dataToSend = {
                enrollmentId: cpData?._id,
                userId: parseLoginRes?._id,
            };

            try {
                const response = await api.post(`/api/user/activate`, dataToSend);
                setIsLoading(false);

                if (response?.status === 200 || response?.status === 201) {
                    const successMessage = response?.data?.msg || "Successfully active";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Activation Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "labelCreated" && connectionType == "Internally") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    setChangeCustomerStatus(false);
                    const successMessage = response?.data?.msg || "Successfully Active";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "prospect" && connectionType == "Internally") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    setChangeCustomerStatus(false);
                    const successMessage = response?.data?.msg || "Successfully Active";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "evaluation") {
            setIsLoading(true);
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            try {
                const response = await api.post(`/api/user/statusnonelectronically`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    const successMessage = response?.data?.msg || "Successfully evaluated";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                toast.error(error.response?.data?.msg || "Evaluation Failed");
                setIsLoading(false);
            }
        } else if (statusTo === "active" && cpData?.status === "prospect" && connectionType === "Externally") {
            setIsLoading(true);
            const dataToSend = {
                enrollmentId: cpData?._id,
                userId: parseLoginRes?._id,
            };

            try {
                const response = await api.post(`/api/user/activate`, dataToSend);
                setIsLoading(false);

                if (response?.status === 200 || response?.status === 201) {
                    const successMessage = response?.data?.msg || "Successfully active";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.msg || "Activation Failed";
                toast.error(errorMessage);
                setIsLoading(false);
            }
        } else if (statusTo === "disconnected" && cpData?.status === "suspended" && connectionType === "Externally") {
            setIsLoading(true);

            api.post(`/api/user/disconnectMdnByPwg`, {
                enrollmentId: cpData?._id,
                // disconnectReason: disconnectReason,
            })
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully disconnected";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "reconnect" && connectionType === "Externally") {
            if (cpData?.carrier.name === "AT&T") {
                if (reconnectRestoreReason !== "") {
                    setIsLoading(true);

                    api.post(`/api/user/reConnectMdnByPwg`, {
                        // reasonCode: reconnectRestoreReason,
                        enrollmentId: cpData?._id,
                    })
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Successfully Reconnected";

                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setRefreshEsn((prev) => !prev);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 50);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.error?.message ? error.response?.data?.error?.message : error.response?.data?.msg ? error.response?.data?.msg : "Reconnecting Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setReconnectionRestoreRequired(true);
                }
            } else {
                setIsLoading(true);

                api.post(`/api/user/reConnectMdnByPwg`, { enrollmentId: cpData?._id })
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Successfully Reconnected";

                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setRefreshEsn((prev) => !prev);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 50);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.error?.message ? error.response?.data?.error?.message : error.response?.data?.msg ? error.response?.data?.msg : "Reconnecting Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (statusTo === "reconnect" && connectionType === "Internally") {
            if (cpData?.carrier?.name === "AT&T") {
                if (reconnectRestoreReason !== "") {
                    setIsLoading(true);

                    api.post(`/api/user/reConnectMdnByPwg`, {
                        // reasonCode: reconnectRestoreReason,
                        enrollmentId: cpData?._id,
                    })
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Success reconnected";

                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setRefreshEsn((prev) => !prev);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 50);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setReconnectionRestoreRequired(true);
                }
            } else {
                setIsLoading(true);

                api.post(`/api/user/reConnectMdnByPwg`, { enrollmentId: cpData?._id })
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Success reconnected";

                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setRefreshEsn((prev) => !prev);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 50);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (statusTo === "disconnected" && connectionType === "Externally") {
            setIsLoading(true);

            api.post(`/api/user/disconnectMdnByPwg`, {
                enrollmentId: cpData?._id,
                // disconnectReason: disconnectReason,
            })
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully disconnected";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "disconnected" && connectionType === "Internally") {
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            setIsLoading(true);

            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully Disconnected";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (statusTo === "suspended" && connectionType === "Internally") {
            if (cpData?.carrier?.name === "AT&T") {
                if (suspendReason !== "") {
                    setIsLoading(true);
                    const dataToSend = {
                        // reasonCode: suspendReason,
                        enrollmentId: cpData?._id,
                    };

                    api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Successfully Suspended";
                            toast.success(successMessage);
                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setRefreshEsn((prev) => !prev);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 50);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setSuspendReasonError(true);
                }
            } else {
                setIsLoading(true);
                const dataToSend = {
                    enrollmentId: cpData?._id,
                };

                api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Successfully Suspended";
                        toast.success(successMessage);
                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setRefreshEsn((prev) => !prev);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 50);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (statusTo === "suspended" && connectionType === "Externally") {
            if (cpData?.carrier?.name === "AT&T") {
                if (suspendReason !== "") {
                    setIsLoading(true);
                    const dataToSend = {
                        reasonCode: suspendReason,
                        enrollmentId: cpData?._id,
                    };

                    api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                        .then((response) => {
                            const successMessage = response?.data?.msg || "Successfully suspended";
                            setTimeout(() => {
                                toast.success(successMessage);
                                setIsLoading(false);
                                setRefreshEsn((prev) => !prev);
                                setChangeCustomerStatus((prev) => !prev);
                            }, 10);
                        })
                        .catch((error) => {
                            const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                            toast.error(errorMessage);
                            setIsLoading(false);
                        });
                } else {
                    setSuspendReasonError(true);
                }
            } else {
                setIsLoading(true);
                const dataToSend = {
                    enrollmentId: cpData?._id,
                };

                api.post(`/api/user/HotlineMdnByPwg`, dataToSend)
                    .then((response) => {
                        const successMessage = response?.data?.msg || "Successfully suspended";
                        setTimeout(() => {
                            toast.success(successMessage);
                            setIsLoading(false);
                            setRefreshEsn((prev) => !prev);
                            setChangeCustomerStatus((prev) => !prev);
                        }, 10);
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                        toast.error(errorMessage);
                        setIsLoading(false);
                    });
            }
        } else if (cpData?.status === "suspended" && statusTo === "active" && connectionType === "Externally") {
            setIsLoading(true);
            const dataToSend = {
                enrollmentId: cpData?._id,
            };

            api.post(`/api/user/removeHotline`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully suspended";
                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (cpData?.status === "active" && connectionType === "Externally") {
            setIsLoading(true);

            api.post(`/api/user/disconnectMdnByPwg`, {
                enrollmentId: cpData?._id,
                // disconnectReason: disconnectReason,
            })
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully disconnected";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else if (cpData?.status === "active" && connectionType === "Internally") {
            const dataToSend = {
                customerId: cpData?._id,
                status: statusTo,
            };
            setIsLoading(true);

            api.post(`/api/user/statusnonelectronically`, dataToSend)
                .then((response) => {
                    const successMessage = response?.data?.msg || "Successfully Disconnected";

                    setTimeout(() => {
                        toast.success(successMessage);
                        setIsLoading(false);
                        setRefreshEsn((prev) => !prev);
                        setChangeCustomerStatus((prev) => !prev);
                    }, 50);
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.msg || "Disconnection Failed";
                    toast.error(errorMessage);
                    setIsLoading(false);
                });
        } else {
            toast.error("Please Select Status OR Type");
        }
    };
    return (
        <div className="status-main w-full flex flex-wrap flex-row justify-content-start  ">
            {cpData?.status === "prospect" && (
                <div className="field-width">
                    <label className="block font-semibold mt-4">Change Account Status To:</label>

                    <Dropdown
                        value={statusTo}
                        onChange={(e) => {
                            setStatusTo(e.value);
                        }}
                        options={prospectStatusOptions}
                        className="w-full mt-3"
                        placeholder="Select Status"
                    />
                </div>
            )}
            {cpData?.status === "active" && (
                <div className="field-width">
                    <label className="block font-semibold mt-4">Change Account Status To:</label>
                    <Dropdown
                        value={statusTo}
                        onChange={(e) => {
                            setStatusTo(e.value);
                        }}
                        options={activeStatusOptions}
                        className="w-full  mt-3"
                        placeholder="Select Status"
                    />
                </div>
            )}
            {cpData?.status === "labelCreated" && (
                <div className="field-width">
                    <label className="block font-semibold mt-4">Change Account Status To:</label>
                    <Dropdown
                        value={statusTo}
                        onChange={(e) => {
                            setStatusTo(e.value);
                        }}
                        options={suspendStatusOptions}
                        className="w-full mt-3"
                        placeholder="Select Status"
                    />
                </div>
            )}
            {cpData?.status === "suspended" && (
                <div className="field-width">
                    <label className="block font-semibold mt-4">Change Account Status To:</label>
                    <Dropdown
                        value={statusTo}
                        onChange={(e) => {
                            setStatusTo(e.value);
                        }}
                        options={suspendStatusOptions}
                        className="w-full mt-3"
                        placeholder="Select Status"
                    />
                </div>
            )}
            {cpData?.status === "disconnected" && (
                <div className="field-width">
                    <label className=" font-semibold block mt-4">Change Account Status To:</label>
                    <Dropdown
                        value={statusTo}
                        onChange={(e) => {
                            setStatusTo(e.value);
                        }}
                        options={disconnectStatusOptions}
                        className="w-full mt-3"
                        placeholder="Select Status"
                    />
                </div>
            )}
            {statusTo === "disconnected" ? (
                <>
                    <div className="field-width">
                        <label className="block  font-semibold mt-4">
                            Reason: <span className="steric">*</span>
                        </label>
                        <Dropdown
                            value={disconnectReason}
                            onChange={(e) => {
                                setdisconnectReason(e.value);
                                setdisconnectReasonError(false);
                            }}
                            options={cpData?.carrier?.name === "AT&T" ? ATTDisconnect : transferExceptionOption}
                            placeholder="Select Reason"
                            className="w-full mt-3"
                        />{" "}
                        {discconectReasonEror ? <p className="p-error">Reason Is Required</p> : ""}
                    </div>
                </>
            ) : undefined}
            {statusTo === "reconnect" && cpData?.carrier?.name === "AT&T" ? (
                <>
                    <div className="field-width">
                        <label className="block  font-semibold mt-4">
                            Reason: <span className="steric">*</span>
                        </label>
                        <Dropdown
                            value={reconnectRestoreReason}
                            onChange={(e) => {
                                setReconnectRestore(e.value);
                                setReconnectionRestoreRequired(false);
                            }}
                            options={cpData?.carrier?.name === "AT&T" ? ATTreconnecting_restoring : []}
                            placeholder="Select Reason"
                            className={classNames("input_text w-full mt-3")}
                        />
                        {reconnectionRestoreRequired ? <p className="p-error">Recconection Reason Is Required</p> : ""}
                    </div>
                </>
            ) : undefined}

            {statusTo === "suspended" && cpData?.carrier?.name === "AT&T" ? (
                <>
                    <div className="field-width">
                        <label className="block  font-semibold mt-4">
                            Reason: <span className="steric">*</span>
                        </label>
                        <Dropdown
                            value={suspendReason}
                            onChange={(e) => {
                                setSuspendReason(e.value);
                                setSuspendReasonError(false);
                            }}
                            options={cpData?.carrier?.name === "AT&T" ? ATTsuspendreasons : []}
                            placeholder="Select Reason"
                            className={classNames("input_text w-full mt-3")}
                        />
                        {suspendReasonError ? <p className="p-error">Suspending Reason Is Required</p> : ""}
                    </div>
                </>
            ) : undefined}
            {cpData?.status === "suspended" && statusTo === "active" && cpData?.carrier?.name === "AT&T" ? (
                <>
                    <div className="field-width">
                        <label className="block  font-semibold mt-4">
                            Reason: <span className="steric">*</span>
                        </label>
                        <Dropdown
                            value={reconnectRestoreReason}
                            onChange={(e) => {
                                setReconnectRestore(e.value);
                                setReconnectionRestoreRequired(false);
                            }}
                            options={cpData?.carrier?.name === "AT&T" ? ATTreconnecting_restoring : []}
                            placeholder="Select Activation Reason"
                            className={"input_text w-full mt-3"}
                        />

                        {reconnectionRestoreRequired ? <p className="p-error">Activation Reason Is Required</p> : ""}
                    </div>
                </>
            ) : undefined}

            <div className="field-width">
                <label className="block font-semibold mt-4">Connection Type:</label>
                <Dropdown
                    className="w-full mt-3"
                    value={connectionType}
                    onChange={(e) => {
                        setConnectionType(e.value);
                    }}
                    options={statusTo === "suspended" && cpData?.status === "active" ? connectionExternally : connectionTypeOption}
                    placeholder="Select Account Type"
                />
            </div>

            <div className="w-full flex flex-wrap flex-row justify-content-end  mt-4">
                <Button onClick={UpdateStatus} label="Submit " disabled={isLoading} icon={isLoading ? "pi pi-spin pi-spinner " : ""} />
            </div>
            <ToastContainer />
        </div>
    );
}
