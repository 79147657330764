import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PayInvoiceCheckoutForm } from "./components/StripePayInvoiceCheckoutform";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export const PayInvoiceStripeCardPayment = ({ setShowAmountError, setRefreshPayInvoice, setShowPayInvoiceDialog, funcToCall, setFucToCallOnPay, amount, cpData, selectedInvoices }) => {
    return (
        <Elements stripe={stripePromise}>
            <PayInvoiceCheckoutForm
                setShowAmountError={setShowAmountError}
                cpData={cpData}
                setRefreshPayInvoice={setRefreshPayInvoice}
                setShowPayInvoiceDialog={setShowPayInvoiceDialog}
                funcToCall={funcToCall}
                setFucToCallOnPay={setFucToCallOnPay}
                selectedInvoices={selectedInvoices}
                amount={amount}
            />
        </Elements>
    );
};
