import React, { useEffect, useRef, useState } from "react";
import "./css/InvoicesMain.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../components/Loader/LoaderBlue";
import NotFoundData from "../../Common/Notfound/Notfound";
import { InvoiceActionIcon } from "./assets/Assets";
import { OverlayPanel } from "primereact/overlaypanel";
import { VoidInvoice } from "./dialogs/VoidInvoice/VoidInvoice";
import { WaiveLateFee } from "./dialogs/WaiveLateFee/WaiveLateFee";
import { AdjustAmount } from "./dialogs/AdjustAmount/AdjustAmount";
import { DownloadInvoice } from "./dialogs/DownloadInvoice/DownoadInvoice";
import { PaidExternally } from "./dialogs/PaidExternally/PaidExternally";
export const InvoicesMain = ({ refreshAllpayment, cpData }) => {
    const [allInvoicesData, setAllInvoicesData] = useState([]);
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
    const [isAPiCalled, setIsApiCalled] = useState(false);
    const [currentInvoiceSelected, setCurrentInvoiceSelected] = useState({});
    const [selectedAction, setSelectedAction] = useState("");
    const [overlayContent, setOverlayContent] = useState("");
    const [currentActiveDialog, setCurrentActiveDialog] = useState({
        void: false,
        lateFee: false,
        adjustInvoice: false,
        download: false,
        paidExternally: false,
    });

    let invoiceActionRef = useRef(null);
    const getInvoice = async () => {
        api.get(`/api/web/invoices/getinvoicebycustomerid?customerid=${cpData._id}`)
            .then((invoiceResponse) => {
                setAllInvoicesData(invoiceResponse?.data?.data);
                setIsApiCalled(true);
                setIsInvoiceLoading(false);
            })
            .catch((err) => {
                setIsInvoiceLoading(false);
            });
    };
    useEffect(() => {
        if (cpData !== undefined && selectedAction === "") {
            getInvoice();
        }
    }, [selectedAction, refreshAllpayment]);
    const statusOverlayRef = useRef(null);
    return (
        <div className="main-invoice-container">
            {currentActiveDialog.void ? (
                <VoidInvoice setSelectedAction={setSelectedAction} cpData={cpData} currentInvoice={currentInvoiceSelected} setCurrentActiveDialog={setCurrentActiveDialog} />
            ) : currentActiveDialog?.lateFee ? (
                <WaiveLateFee setSelectedAction={setSelectedAction} cpData={cpData} currentInvoice={currentInvoiceSelected} setCurrentActiveDialog={setCurrentActiveDialog} />
            ) : currentActiveDialog?.adjustInvoice ? (
                <AdjustAmount setSelectedAction={setSelectedAction} cpData={cpData} currentInvoice={currentInvoiceSelected} setCurrentActiveDialog={setCurrentActiveDialog} />
            ) : currentActiveDialog?.download ? (
                <DownloadInvoice setSelectedAction={setSelectedAction} cpData={cpData} currentInvoice={currentInvoiceSelected} setCurrentActiveDialog={setCurrentActiveDialog} />
            ) : currentActiveDialog?.paidExternally ? (
                <PaidExternally setSelectedAction={setSelectedAction} cpData={cpData} currentInvoice={currentInvoiceSelected} setCurrentActiveDialog={setCurrentActiveDialog} />
            ) : undefined}
            {!isInvoiceLoading && isAPiCalled && allInvoicesData.length === 0 ? (
                <NotFoundData currentNoFoundData={"invoice"} />
            ) : (
                <DataTable value={allInvoicesData}>
                    <Column
                        field="invoiceNo"
                        header="Invoice No"
                        body={(rowData) => (
                            <>
                                {rowData?.invoiceVoid && <div style={{ position: "absolute", marginTop: "9px", width: "100%", borderTop: "solid #9c9eb6 1px" }}></div>} <span style={{ cursor: "pointer" }}>{rowData?.invoiceNo}</span>{" "}
                            </>
                        )}
                    />

                    <Column field="invoiceStatus" body={(rowData) => <p className={`${rowData.invoiceStatus === "Unpaid" ? "invoice-status-unpaid" : rowData?.invoiceStatus === "Partial" ? "invoice-status-partial" : "invoice-status-paid"}`}>{rowData.invoiceStatus}</p>} header="Invoice Status" />
                    <Column
                        header="Created Date"
                        body={(rowData) => {
                            return (
                                <span>
                                    {moment
                                        .tz(rowData.createdAt, "America/New_York") // Convert to New York time (Eastern Time Zone)
                                        .format("MM-DD-YYYY")}
                                </span>
                            );
                        }}
                    />
                    <Column
                        field="invoiceType"
                        header="Type"
                        body={(rowData) => (
                            <>
                                <p
                                    onMouseEnter={(e) => {
                                        if (rowData?.invoiceType.length > 15) {
                                            setOverlayContent(rowData?.invoiceType);
                                            statusOverlayRef.current.show(e);
                                        }
                                    }}
                                    onMouseLeave={() => statusOverlayRef.current.hide()}
                                >
                                    {rowData?.invoiceType.length > 15 ? rowData?.invoiceType?.substring(0, 12) + "..." : rowData.invoiceType}
                                </p>
                                <OverlayPanel ref={statusOverlayRef}>
                                    <div style={{ padding: "5px 10px" }}>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: "1.4rem",
                                                overflow: "hidden",
                                                border: "none",
                                                margin: "0px",
                                                lineHeight: "1.8rem",
                                                color: "#72728f",
                                            }}
                                        >
                                            {" "}
                                            {overlayContent}
                                        </p>
                                    </div>
                                </OverlayPanel>
                            </>
                        )}
                    />
                    <Column
                        field="amountPaid"
                        header="Amount Paid"
                        body={(rowData) => (rowData?.amountPaid !== undefined && rowData?.amountPaid !== "0.00" && rowData?.amountPaid !== "0" && rowData?.amountPaid !== "NaN" && rowData?.amountPaid !== "" ? parseFloat(rowData?.amountPaid).toFixed(2) : "NIL")}
                    />
                    <Column field="currentBalance" header="Current Bill" body={(rowData) => (rowData?.currentBalance !== undefined && rowData?.currentBalance !== "NaN" && rowData?.currentBalance !== "" ? parseFloat(rowData?.currentBalance).toFixed(2) : "NIL")} />
                    <Column field="previousBalance" body={(rowData) => (rowData?.previousBalance !== undefined && rowData?.previousBalance !== "NaN" && rowData?.previousBalance !== "" ? parseFloat(rowData?.previousBalance).toFixed(2) : "NIL")} header="Previous Balance" />
                    <Column
                        field="dueAmount"
                        body={(rowData) => {
                            return <p>{rowData?.dueAmount !== "NaN" && rowData?.dueAmount !== "0.00" && rowData?.dueAmount !== "0" && rowData?.dueAmount !== null && rowData?.dueAmount !== "" && rowData?.dueAmount !== undefined ? rowData?.dueAmount : "NIL"}</p>;
                        }}
                        header="Total Due"
                    />
                    <Column field="invoiceOneTimeCharges" header="One Time Charges(A)" body={(rowData) => parseFloat(rowData.invoiceOneTimeCharges).toFixed(2)} />
                    <Column field="planCharges" header="Plan Charges(B)" body={(rowData) => (rowData.planCharges ? rowData.planCharges : "NIL")} />
                    <Column
                        field="additionalCharges"
                        header="Additional Charges(C)"
                        body={(rowData) => {
                            const totalAmount = rowData?.additionalCharges.reduce((total, charge) => total + parseFloat(charge.amount), 0);

                            return <span>{totalAmount ? totalAmount.toFixed(2) : "NIL"}</span>;
                        }}
                    />
                    <Column
                        field="discount"
                        header="Discounts"
                        body={(rowData) => {
                            const totalAmount = rowData?.discount.reduce((total, charge) => total + parseFloat(charge.amount), 0);

                            return <span>{totalAmount ? totalAmount.toFixed(2) : "NIL"}</span>;
                        }}
                    />
                    <Column
                        field="shipmentCost.amount"
                        body={(rowData) => {
                            return <p>{rowData?.shipmentCost?.amount !== undefined && rowData?.shipmentCost?.amount !== null && rowData?.shipmentCost?.amount !== "NaN" && rowData?.shipmentCost?.amount !== "" ? rowData?.shipmentCost?.amount : "NIL"}</p>;
                        }}
                        header="Shipment Cost"
                    />
                    <Column field="lateFee" header="Late Fee" body={(rowData) => (rowData.lateFee && rowData.lateFee !== "0" ? rowData.lateFee : "NIL")} />
                    <Column field="taxe" header="Taxes" body={(rowData) => (rowData.taxe ? rowData.taxe : "NIL")} />
                    <Column field="invoiceDueDate" header="Due Date" body={(rowData) => (rowData.invoiceDueDate && rowData?.invoiceDueDate !== "-" ? rowData.invoiceDueDate : "NIL")} />
                    <Column
                        field="createdBy.name"
                        header="Created By"
                        body={(rowData) => (
                            <>
                                <p
                                    onMouseEnter={(e) => {
                                        if (rowData?.createdBy?.name?.length > 15) {
                                            setOverlayContent(rowData?.createdBy?.name);
                                            statusOverlayRef.current.show(e);
                                        }
                                    }}
                                    onMouseLeave={() => statusOverlayRef.current.hide()}
                                >
                                    {rowData?.createdBy?.name?.length > 13 ? rowData?.createdBy?.name?.substring(0, 10) + "..." : rowData.createdBy?.name?.length === 0 ? "NIL" : rowData?.createdBy?.name}
                                </p>
                                <OverlayPanel ref={statusOverlayRef}>
                                    <div style={{ padding: "5px 10px" }}>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: "1.4rem",
                                                overflow: "hidden",
                                                border: "none",
                                                margin: "0px",
                                                lineHeight: "1.8rem",
                                                color: "#72728f",
                                            }}
                                        >
                                            {" "}
                                            {overlayContent}
                                        </p>
                                    </div>
                                </OverlayPanel>
                            </>
                        )}
                    />
                    <Column
                        field="invoicePaymentMethod"
                        header="Paid Using"
                        body={(rowData) => (
                            <>
                                <p
                                    onMouseEnter={(e) => {
                                        if (rowData?.invoicePaymentMethod.length > 10) {
                                            setOverlayContent(rowData?.invoicePaymentMethod);
                                            statusOverlayRef.current.show(e);
                                        }
                                    }}
                                    onMouseLeave={() => statusOverlayRef.current.hide()}
                                >
                                    {rowData?.invoicePaymentMethod?.length > 10 ? rowData?.invoicePaymentMethod?.substring(0, 7) + "..." : rowData?.invoicePaymentMethod?.length === 0 || rowData?.invoicePaymentMethod === " " ? "NIL" : rowData.invoicePaymentMethod}
                                </p>{" "}
                                <OverlayPanel ref={statusOverlayRef}>
                                    <div style={{ padding: "5px 10px" }}>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: "1.4rem",
                                                overflow: "hidden",
                                                border: "none",
                                                margin: "0px",
                                                lineHeight: "1.8rem",
                                                color: "#72728f",
                                            }}
                                        >
                                            {" "}
                                            {overlayContent}
                                        </p>
                                    </div>
                                </OverlayPanel>
                            </>
                        )}
                    />
                    <Column
                        field="transId"
                        body={(rowData) => {
                            let str = [...rowData.transId, ...rowData.paymentIds] // Merge both arrays
                                .filter((item) => item) // Remove falsy values
                                .join(",");
                            return (
                                <>
                                    <p
                                        onMouseEnter={(e) => {
                                            if (str.length > 15) {
                                                setOverlayContent(str);
                                                statusOverlayRef.current.show(e);
                                            }
                                        }}
                                        onMouseLeave={() => statusOverlayRef.current.hide()}
                                    >
                                        {str.length > 15 ? str.substring(0, 15) + "..." : str.length === 0 ? "NIL" : str}
                                    </p>
                                    <OverlayPanel ref={statusOverlayRef}>
                                        <div style={{ padding: "5px 10px" }}>
                                            <p
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: "1.4rem",
                                                    overflow: "hidden",
                                                    border: "none",
                                                    margin: "0px",
                                                    lineHeight: "1.8rem",
                                                    color: "#72728f",
                                                }}
                                            >
                                                {" "}
                                                {overlayContent}
                                            </p>
                                        </div>
                                    </OverlayPanel>
                                </>
                            );
                        }}
                        header="Confirmation No"
                    />
                    <Column
                        field="Actions"
                        header="Actions"
                        body={(rowData) => {
                            return (
                                <div className="flex flex-wrap flex-row justify-content-end  w-full h-full align-items-center main-action-wrapper">
                                    <div
                                        onClick={(e) => {
                                            invoiceActionRef.current.toggle(e);
                                            setCurrentInvoiceSelected(rowData);
                                        }}
                                        className="actionbtnmain"
                                    >
                                        <InvoiceActionIcon />
                                    </div>
                                    <OverlayPanel ref={invoiceActionRef}>
                                        <div className=" main-invoice-overlay-container main-module-container">
                                            <p
                                                onClick={() => {
                                                    setSelectedAction("Void");
                                                    setCurrentActiveDialog({ void: true, lateFee: false, adjustInvoice: false, download: false });
                                                    setTimeout(() => {
                                                        var element2 = document.getElementById("void-invoice-container");
                                                        // Scroll the element into view
                                                        element2.scrollIntoView({
                                                            behavior: "smooth", // Smooth scroll
                                                            block: "center", // Scroll to the top of the element
                                                        });
                                                    }, 200);
                                                }}
                                                className={`invoice-item-here ${selectedAction === "Void" ? "selected-module" : "NIL"}`}
                                            >
                                                Void
                                            </p>

                                            <p
                                                className={` invoice-item-here  ${selectedAction === "latefee" ? "selected-module" : "NIL"}`}
                                                onClick={() => {
                                                    setSelectedAction("latefee");
                                                    setCurrentActiveDialog({ void: false, lateFee: true, adjustInvoice: false, download: false });
                                                    setTimeout(() => {
                                                        var element2 = document.getElementById("void-invoice-container");

                                                        // Scroll the element into view
                                                        element2.scrollIntoView({
                                                            behavior: "smooth", // Smooth scroll
                                                            block: "center", // Scroll to the top of the element
                                                        });
                                                    }, 200);
                                                }}
                                            >
                                                Late Fee
                                            </p>
                                            <p
                                                className={` invoice-item-here  ${selectedAction === "paidExternally" ? "selected-module" : "NIL"}`}
                                                onClick={() => {
                                                    setSelectedAction("paidexternally");
                                                    setCurrentActiveDialog({ void: false, lateFee: false, adjustInvoice: false, paidExternally: true, download: false });
                                                    setTimeout(() => {
                                                        var element2 = document.getElementById("paid-externally-container");

                                                        // Scroll the element into view
                                                        element2.scrollIntoView({
                                                            behavior: "smooth", // Smooth scroll
                                                            block: "center", // Scroll to the top of the element
                                                        });
                                                    }, 200);
                                                }}
                                            >
                                                Paid Externally
                                            </p>
                                            <p
                                                onClick={() => {
                                                    setSelectedAction("adjustinvoice");
                                                    setCurrentActiveDialog({ void: false, lateFee: false, adjustInvoice: true, download: false });
                                                    setTimeout(() => {
                                                        var element2 = document.getElementById("invoice-adjust-main");

                                                        // Scroll the element into view
                                                        element2.scrollIntoView({
                                                            behavior: "smooth", // Smooth scroll
                                                            block: "center", // Scroll to the top of the element
                                                        });
                                                    }, 200);
                                                }}
                                                className={` invoice-item-here  ${selectedAction === "adjustinvoice" ? "selected-module" : "NIL"}`}
                                            >
                                                Adjust Invoice
                                            </p>
                                            <p
                                                className={` invoice-item-here ${selectedAction === "download" ? "selected-module" : "NIL"}`}
                                                onClick={() => {
                                                    setSelectedAction("download");
                                                    setCurrentActiveDialog({ void: false, lateFee: false, adjustInvoice: false, download: true });
                                                    setTimeout(() => {
                                                        var element2 = document.getElementById("invoice-download-main");

                                                        // Scroll the element into view
                                                        element2.scrollIntoView({
                                                            behavior: "smooth", // Smooth scroll
                                                            block: "center", // Scroll to the top of the element
                                                        });
                                                    }, 200);
                                                }}
                                            >
                                                Download
                                            </p>
                                        </div>
                                    </OverlayPanel>
                                </div>
                            );
                        }}
                    />
                </DataTable>
            )}
            {isInvoiceLoading ? (
                <div className="loader-wrapper">
                    <CommonLoaderBlue color="royalblue" sizehere={21} />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};
