import React, { useRef, useState } from "react";
import "./AssignLabel.css";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import DownloadInvoiceTemplate from "./Components/DownloadInvoiceTemplate";
export const AssignLabel = ({ setRefreshCustomerData, setSelectedAction, setVisible, cpData, currentInvoice, setCurrentActiveDialog }) => {
    let toast = useRef();
    const LoginRes = JSON.parse(localStorage.getItem("userData"));
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({});
    const [singleInvoiceData, setInvoiceData] = useState();

    const [invoiceDownload, setInvoiceDownload] = useState(false);

    const formik = useFormik({
        //validationSchema: validationSchema,
        initialValues: {
            reason: "",
            discountedAmount: "",
            customerId: cpData?._id,
            userId: LoginRes?._id,
            invoiceId: currentInvoice?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);

            api.post(`/api/user/esnAssingment`, {
                csr: LoginRes._id,
                userId: cpData?._id,
            })
                .then((assignLabelResponse) => {
                    toast.current.show({ severity: "success", summary: "Assign ESN", detail: assignLabelResponse?.data?.msg || "ESN Assigned Successfully" });
                    setRefreshCustomerData((prev) => !prev);
                    setTimeout(() => {
                        setVisible(false);
                    }, 500);
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Assign ESN", detail: err?.response?.data?.msg || "ESN Assigning Failed" });

                    setIsLoading(false);
                });
        },
    });
    return (
        <>
            {" "}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
                id="invoice-download-main"
                className="invoice-download-main flex flex-wrap flex-row justify-content-center"
            >
                <div className="main-download-invoice">
                    <div className="top-header-downloadinvoice">
                        <h1>Assign ESN</h1>
                        <p>Are you sure you want to assign ESN To {cpData?.firstName !== "" && cpData?.lastName !== "" ? cpData?.firstName + " " + cpData?.lastName : cpData?.firstName} ? This action will update their profile.</p>
                    </div>
                    <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                setVisible((prev) => !prev);
                            }}
                            label="Cancel"
                        />
                        <Button
                            type="Submit"
                            label={
                                isLoading ? (
                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue customSize="12" color="white" />{" "}
                                    </div>
                                ) : (
                                    "Assign ESN"
                                )
                            }
                        />
                    </div>
                </div>
                <Toast ref={toast} />
            </form>
        </>
    );
};
