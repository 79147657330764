import React, { useRef, useState } from "react";
import "./css/PaymentMain.css";
import { Button } from "primereact/button";
import { InvoicesMain } from "./components/Invoices/InvoicesMain";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../utility/AxiosInceptor";
import { PaymentLogMain } from "./components/PaymentLogs/PaymentLogMain";
import { MainAutoPay } from "./components/AutoPay/AutoPayMain";
import { PayInvoicesMain } from "./components/Payinvoice/PayInvoicesMain";
import { AddAdhocinvoice } from "./components/AdhocInvoice/Adhocinvoice";

export const PaymentMain = ({ setRefreshCustomerData, cpData }) => {
    const [currentActiveTab, setCurrentActiveTab] = useState("invoices");
    const toast = useRef();
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));

    const [refreshAllpayment, setRefreshAllPayment] = useState(false);
    const handleGenerateInvoice = async () => {
        const dataToSend = {
            customerId: cpData?._id,
            planId: cpData?.plan?._id,
            // stripeId: cpData?.stripeId,
            accountId: cpData?.accountId,
            invoiceType: "SignUp",
            planCharges: cpData?.currentPlan?.planCharges,
            additionalCharges: cpData?.currentPlan?.additionalCharges,
            discount: cpData?.currentPlan?.discount,
            totalAmount: cpData?.totalAmount,
            amountPaid: "0",
            invoiceDueDate: cpData?.activeBillingConfiguration?.dueDate,
            billingPeriod: cpData?.currentPlan?.billingPeriod,
            invoiceStatus: "Unpaid",
            invoicePaymentMethod: "",
            invoiceOneTimeCharges: cpData?.activeBillingConfiguration?.oneTimeCharge,
            lateFee: cpData?.activeBillingConfiguration?.applyLateFee,
            planName: cpData?.plan?.name,
            //*********************//
            chargingType: "Monthly",
            printSetting: cpData?.currentPlan?.printSetting,
            paymentChannel: "Stripe",
            isInvoice: true,
            // isAutopay: true,
            selectProduct: cpData?.billId,
            userId: parseLoginRes?._id,
            // autopayChargeDate: "",
            // stripeTokenId: cpData?.currentPlan?.stripeTokenId,
            // stripeCustomerId: cpData?.currentPlan?.stripeCustomerId,
        };

        api.post(`/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Info", detail: res?.data?.message || "Invoice Generated Successfully" });
                setRefreshCustomerData((prev) => !prev);
                setRefreshAllPayment((prev) => !prev);
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "info", detail: err?.response?.data?.message });
            });
    };
    return (
        <div className="payment-main-container">
            <div className="top-header-tab">
                <div className="button-bar">
                    <Button
                        onClick={() => {
                            setCurrentActiveTab("invoices");
                        }}
                        className={`${currentActiveTab === "invoices" ? "current-active-bar-button" : ""} `}
                        label="Invoices"
                    />
                    <Button
                        onClick={() => {
                            setCurrentActiveTab("payment-logs");
                        }}
                        className={`${currentActiveTab === "payment-logs" ? "current-active-bar-button" : ""} `}
                        label="Payment Logs"
                    />
                    <Button
                        onClick={() => {
                            setCurrentActiveTab("auto-pay");
                        }}
                        className={`${currentActiveTab === "auto-pay" ? "current-active-bar-button" : ""} `}
                        label="Auto Pay"
                    />
                    <Button
                        onClick={() => {
                            setCurrentActiveTab("pay-invoice");
                        }}
                        className={`${currentActiveTab === "pay-invoice" ? "current-active-bar-button" : ""} `}
                        label="Pay Invoice"
                    />
                    <Button
                        onClick={() => {
                            setCurrentActiveTab("adhoc-invoice");
                        }}
                        className={`${currentActiveTab === "adhoc-invoice" ? "current-active-bar-button" : ""} `}
                        label="AD HOC Invoices"
                    />
                </div>
                {cpData?.invoice?.length === 0 && <Button className="generateinvoicebtn" onClick={handleGenerateInvoice} style={{ marginTop: "1rem" }} label="Generate Invoice" />}
            </div>
            {currentActiveTab === "invoices" ? (
                <InvoicesMain refreshAllpayment={refreshAllpayment} cpData={cpData} />
            ) : currentActiveTab === "payment-logs" ? (
                <PaymentLogMain refreshAllpayment={refreshAllpayment} cpData={cpData} />
            ) : currentActiveTab === "auto-pay" ? (
                <MainAutoPay setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} />
            ) : currentActiveTab === "pay-invoice" ? (
                <PayInvoicesMain refreshAllpayment={refreshAllpayment} cpData={cpData} />
            ) : currentActiveTab === "adhoc-invoice" ? (
                <AddAdhocinvoice setCurrentActiveTab={setCurrentActiveTab} cpData={cpData} />
            ) : (
                ""
            )}
            <Toast ref={toast} />
        </div>
    );
};
