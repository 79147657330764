import React, { useRef, useState } from "react";
import "./WaiveLateFee.css";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { api } from "../../../../../../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../../../../../../components/Loader/LoaderBlue";
export const WaiveLateFee = ({ setSelectedAction, cpData, currentInvoice, setCurrentActiveDialog }) => {
    let toast = useRef();
    const LoginRes = JSON.parse(localStorage.getItem("userData"));
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        reason: Yup.string().required("Field Is Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            reason: "",
            customerId: cpData?._id,
            userId: LoginRes?._id,
            invoiceId: currentInvoice?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            api.put(`/api/web/invoices/waiveLateFee`, values)
                .then((res) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "success", summary: "Waive Late Fee", detail: "Invoice Late Fee Waived Successfully" });
                    setTimeout(() => {
                        setSelectedAction("");
                        setCurrentActiveDialog(false);
                    }, 1000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Waive Late Fee", detail: err?.response?.data?.message || err?.response?.data?.msg || "Invoice Late Fee Waiving Fa iled" });
                });
        },
    });
    return (
        <>
            <div style={{ position: "fixed", zIndex: 9999, width: "100vw", height: "100vh", backgroundColor: "#FFFFFF99", top: 0, left: 0 }}></div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
                id="void-invoice-container"
                className="void-invoice-container flex flex-wrap flex-row justify-content-center"
            >
                <div className="main-void-invoice">
                    <div className="header-main-container-voidinvoice">
                        <div className="header-main-void-invoice">
                            <h1>Waive Late Fee</h1>
                            <p>Provide a reason for waiving the late fee. Once submitted, this action cannot be undone.</p>
                        </div>
                        <div className="header-bottom-void">
                            <p>
                                Invoice No:<span>{currentInvoice.invoiceNo || "NIL"}</span>
                            </p>
                            <div className="void-reason-container ">
                                <div className="flex flex-wrap w-full flex-row justify-content-between">
                                    <label>Reason</label>
                                    {formik.touched.reason && formik.errors.reason && <label>{formik.errors.reason}</label>}
                                </div>
                                <input name="reason" value={formik.values.reason} className={`${formik.touched.reason && formik.errors.reason && "p-invalid"}`} onChange={formik.handleChange} placeholder="Reason" />
                            </div>
                        </div>
                    </div>
                    <div className="action-buttons flex flex-wrap flex-row justify-content-between aligbn-item-center">
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                setSelectedAction("");
                                setCurrentActiveDialog({
                                    void: false,
                                    lateFee: false,
                                    adjustInvoice: false,
                                    download: false,
                                });
                            }}
                            label="Cancel"
                        />
                        <Button
                            type="submit"
                            label={
                                isLoading ? (
                                    <div className="flex flex-wrap flex-row justify-content-center align-items-center">
                                        <CommonLoaderBlue sizehere="12" color="white" />{" "}
                                    </div>
                                ) : (
                                    "Submit"
                                )
                            }
                        />
                    </div>
                </div>
                <Toast ref={toast} />
            </form>
        </>
    );
};
