import React from "react";
export const CheckMark = () => {
    return (
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33333 8.66634L0 5.33301L1.16667 4.16634L3.33333 6.33301L8.83333 0.833008L10 1.99967L3.33333 8.66634Z" fill="white" />
        </svg>
    );
};
export const CancelPopup = () => {
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_2160_88023)">
                    <rect x="4" y="3" width="24" height="24" rx="4" fill="white" />
                    <rect x="4.5" y="3.5" width="23" height="23" rx="3.5" stroke="#DDE2FC" />
                    <path d="M11.8281 10.8335L20.1615 19.1668" stroke="#475CED" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.8281 19.1668L20.1615 10.8335" stroke="#475CED" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <filter id="filter0_dd_2160_88023" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2160_88023" />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.278431 0 0 0 0 0.360784 0 0 0 0 0.929412 0 0 0 0.15 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2160_88023" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.278431 0 0 0 0 0.360784 0 0 0 0 0.929412 0 0 0 0.3 0" />
                        <feBlend mode="normal" in2="effect1_dropShadow_2160_88023" result="effect2_dropShadow_2160_88023" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2160_88023" result="shape" />
                    </filter>
                </defs>
            </svg>
        </>
    );
};
