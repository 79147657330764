import "./Carrier-Earning.css";
import React, { useRef, useState } from "react";
import CarrierChart from "./CarrierEarningComponents/Carrier-Chart";
import EarningChart from "./CarrierEarningComponents/Earning-Chart";
import { Calendar, ModuleSelector } from "./assets";
import { OverlayPanel } from "primereact/overlaypanel";
import Calendardropdown from "../new-dashboard-components/Carrier-Earning/Calendar-Dropdown/Calendar-DropDown";
export default function CarrierEarningChart({ allEnrollments, setSelectedRange, setShortSelection, shortSelection, selectedRange }) {
    let calendar = useRef(null);
    const [currentModuleSelect, setCurrentModuleSelect] = useState({ prepaid: false, postpaid: false, acp: false, all: false });
    let moduleSelector = useRef(null);
    const [allEnrollmentsCount, setAllEnrollmentsCount] = useState(0);
    const [allChartData, setAllChartData] = useState();
    return (
        <div className="carrier-earning-chart">
            <div className="flex flex-wrap w-full flex-row justify-content-between align-items-center carrier-earning-top-content">
                <div className="carrier-earning-main-tag">
                    <h1>Module Performance Overview</h1>
                    <p>Current stats across all modules. Use filters to explore detailed insights.</p>
                </div>
                <div className="range-calendar-selector-main">
                    <p>
                        {selectedRange ? (selectedRange[0] ? new Date(selectedRange[0]).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" }) : "Start Date") : "Start Date"} -&nbsp;
                        {selectedRange ? (selectedRange[1] ? new Date(selectedRange[1]).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" }) : "End Date") : "End Date"}
                    </p>
                    <div className="range-calendar-main" onClick={(e) => calendar.current.toggle(e)}>
                        <Calendar />
                    </div>

                    <OverlayPanel position="left" ref={calendar} className="calendaroverlay">
                        <Calendardropdown calendar={calendar} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} setSelectedRange={setSelectedRange} />
                    </OverlayPanel>
                    <div
                        className="range-calendar-module-selector"
                        onClick={(e) => {
                            moduleSelector.current.toggle(e);
                        }}
                    >
                        <ModuleSelector />
                    </div>
                    <div className="main-overlay">
                        <OverlayPanel ref={moduleSelector}>
                            <div style={{ gap: "0.4rem" }} className="main-module-container">
                                <p
                                    onClick={() => {
                                        setCurrentModuleSelect((prev) => ({ ...prev, prepaid: !prev.prepaid }));
                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                    className={`${currentModuleSelect.prepaid ? "selected-module" : ""}`}
                                >
                                    Prepaid
                                </p>
                                <p
                                    className={`${currentModuleSelect.postpaid ? "selected-module" : ""}`}
                                    onClick={() => {
                                        setCurrentModuleSelect("postpaid");
                                        setCurrentModuleSelect((prev) => ({ ...prev, postpaid: !prev.postpaid }));

                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                >
                                    Postpaid
                                </p>
                                <p
                                    className={`${currentModuleSelect.acp ? "selected-module" : ""}`}
                                    onClick={() => {
                                        setCurrentModuleSelect((prev) => ({ ...prev, acp: !prev.acp }));

                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                >
                                    Acp
                                </p>
                                <p
                                    className={`${currentModuleSelect.all ? "selected-module" : ""}`}
                                    onClick={() => {
                                        if (!currentModuleSelect.all) {
                                            setCurrentModuleSelect((prev) => ({ ...prev, prepaid: true, postpaid: true, acp: true, all: !prev.all }));
                                        } else {
                                            setCurrentModuleSelect((prev) => ({ ...prev, all: !prev.all }));
                                        }
                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                >
                                    All
                                </p>
                            </div>
                        </OverlayPanel>
                    </div>
                </div>
            </div>
            <CarrierChart currentModuleSelect={currentModuleSelect} allEnrollmentsCount={allEnrollmentsCount} setAllEnrollmentsCount={setAllEnrollmentsCount} allEnrollments={allEnrollments} setAllChartData={setAllChartData} shortSelection={shortSelection} selectedRange={selectedRange} />
            <EarningChart currentModuleSelect={currentModuleSelect} allEnrollmentsCount={allEnrollmentsCount} allEnrollments={allEnrollments} allChartData={allChartData} shortSelection={shortSelection} selectedRange={selectedRange} />
        </div>
    );
}
