import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { HashRouter, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import store from "./app/store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
);
