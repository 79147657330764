import React, { useState, useEffect, useRef } from "react";
import "./css/MainNotesCustomerProfile.css";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { api } from "../../../../../../../../utility/AxiosInceptor";
import { AddNote } from "./components/AddNote";
import CommonLoaderBlue from "../../../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
import { NoteDetails } from "./components/NoteDetails";
export const NotesCommonCustomerProfile = ({ refreshNotes, cpData }) => {
    const [isAddNote, setIsAddNote] = useState(false);
    const [isViewNote, setIsViewNote] = useState(false);
    const [, setRender] = useState(false);
    const [currentSelectedNote, setCurrentSelectedNote] = useState({});
    const [isFetchedAllNotes, setIsFetchedAllNotes] = useState(false);
    const [refreshNoteOnCaseAdded, setRefreshNoteOnCaseAdded] = useState(false);
    //all Customer notes state
    const [overlayContent, setOverlayContent] = useState("");
    const [allNotes, setAllNotes] = useState([]);
    //All customer Notes Getter
    const statusOverlayRef = useRef(null);
    const getNotes = async () => {
        try {
            const res = await api.get(`/api/web/notes/getbyCustomer?customerId=${cpData._id}`);
            setAllNotes(
                res?.data?.data?.map((note, index, arr) => ({
                    ...note,
                    noteNumber: arr.length - index < 10 ? `0${arr.length - index}` : `${arr.length - index}`,
                })),
            );
            setIsFetchedAllNotes(true);
        } catch (error) {
            setIsFetchedAllNotes(true);
        }
    };
    useEffect(() => {
        if (cpData._id) {
            getNotes();
        }
    }, [cpData, refreshNotes, refreshNoteOnCaseAdded]);
    return (
        <div className="main-customernote-container">
            {!isAddNote && !isViewNote ? (
                <>
                    {" "}
                    <div className="header-add-note">
                        <h1 className="header-top-header">Notes</h1>
                        <p className="header-details-header">Add, view, and manage agent notes for a complete record of customer interactions.</p>
                        <div className="btn-addnote">
                            <Button
                                onClick={() => {
                                    //setConfirmationMessage("Are you sure you want to assign label?");
                                    //setVisible(true);
                                    //setAssignType("Label");
                                    setIsAddNote((prev) => !prev);
                                }}
                                type="button"
                                className="nextbtn "
                                label="Add Note"
                                //icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                            />
                        </div>
                    </div>
                    <div className="all-notes">
                        <h1 className="header-top-allnotes-header">Notes</h1>
                        <div className="notes-all-container">
                            {!isFetchedAllNotes ? (
                                <div className="w-full h-full flex flex-wrap flex-row justify-content-center align-items-center">
                                    <CommonLoaderBlue customSize={16} />
                                </div>
                            ) : undefined}
                            {allNotes.map((notes, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setCurrentSelectedNote(notes);
                                            setIsViewNote(true);
                                        }}
                                        className="note-item cursor-pointer"
                                        style={{ position: "relative" }}
                                    >
                                        {notes.void && <div style={{ width: "33rem", marginTop: index === 0 ? "-2px" : "0px", color: "#72728f", position: "absolute", borderTop: "solid #72728f 1px" }}></div>}
                                        <p>{notes?.noteNumber}</p>
                                        <p
                                            onMouseEnter={(e) => {
                                                if (notes.note.length > 40) {
                                                    setOverlayContent(notes.note);
                                                    setRender((prev) => !prev); // Force re-render
                                                    statusOverlayRef.current.show(e);
                                                }
                                            }}
                                            onMouseLeave={() => statusOverlayRef.current.hide()}
                                        >
                                            {notes.note.length > 40 ? notes.note.substr(0, 37) + "..." : notes.note}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <OverlayPanel style={{ marginRight: "1.6rem" }} ref={statusOverlayRef}>
                            <div style={{ maxWidth: "34rem", padding: "5px 10px" }}>
                                <p
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "1.4rem",
                                        overflow: "hidden",
                                        border: "none",
                                        margin: "0px",
                                        lineHeight: "1.8rem",
                                        color: "#72728f",
                                    }}
                                >
                                    {" "}
                                    {overlayContent}
                                </p>
                            </div>
                        </OverlayPanel>
                    </div>
                </>
            ) : isAddNote ? (
                <AddNote setRefreshNoteOnCaseAdded={setRefreshNoteOnCaseAdded} cpData={cpData} setIsAddNote={setIsAddNote} />
            ) : isViewNote ? (
                <NoteDetails setRefreshNoteOnCaseAdded={setRefreshNoteOnCaseAdded} setIsViewNote={setIsViewNote} noteAllData={currentSelectedNote} />
            ) : undefined}
        </div>
    );
};
