import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import { api } from "../../../../../../../utility/AxiosInceptor";
import { useEffect, useState } from "react";
import PaymentStripeForm from "./stripe_payment_dialog/stripe_payment_form";
import CommonLoaderBlue from "../Loaders/Loader/LoaderBlue";
const BASE_URL = process.env.REACT_APP_BASE_URL;
let stripePromise;
export default function PaymentStripModule({ setPayinvoicesVisible, setShowStripe, setRefresh, amount, cpData, selectedInvoices }) {
    let [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
        api.post(`/api/web/billing/paymentintent`, { amount: parseFloat(amount).toFixed(2) })
            .then((response) => {
                setClientSecret(response.data.clientSecret);
            })
            .catch((err) => {});
    }, []);

    return (
        <>
            {clientSecret !== null ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                    }}
                >
                    <PaymentStripeForm cpData={cpData} setRefresh={setRefresh} setShowStripe={setShowStripe} selectedInvoices={selectedInvoices} clientSecret={clientSecret} amount={amount} />
                </Elements>
            ) : (
                <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full mt-4 mb-4 ">
                    <CommonLoaderBlue />
                </div>
            )}
        </>
    );
}
