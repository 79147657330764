import React, { useState } from "react";
import { EmptyUser, RolesIcon } from "../../../../utility";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import CreateUser from "./CreateUser";
import { Dialog } from "primereact/dialog";
const EmptyUserScreen = ({ setRefresh }) => {
    const [showAddUser, setShowAddUser] = useState(false);
    console.log("show add user ", showAddUser);
    const navigate = useNavigate();
    return (
        <div className="rolesEmptyScreen">
            <EmptyUser />
            <div className="flex flex-column align-items-center" style={{ marginTop: "2.4rem" }}>
                <h1>No Users Found!!</h1>
                <p>It looks like your user list is empty. Click the button below to start adding new users and build your community!</p>{" "}
            </div>
            <div className="flex w-full justify-content-center" style={{ gap: "1.6rem", marginTop: "3.2rem" }}>
                <Button className="backbtn" label="Add Role" onClick={() => navigate("/createrole")} style={{ width: "15.7rem", height: "5rem" }} />
                <Button
                    className="nextbtn"
                    label="Add User"
                    onClick={() => {
                        setShowAddUser(true);
                    }}
                    style={{ width: "15.7rem", height: "5rem", marginTop: "3.2rem" }}
                />
            </div>
            <Dialog
                header="null"
                headerClassName="edituserdialogheader"
                visible={showAddUser}
                style={{ width: "71.6rem", height: "50rem" }}
                onHide={() => {
                    setShowAddUser(false);
                }}
            >
                <CreateUser setShowAddUser={setShowAddUser} setRefresh={setRefresh} />
            </Dialog>
        </div>
    );
};

export default EmptyUserScreen;
