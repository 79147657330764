import { api } from "../../../../../../../utility/AxiosInceptor";
import React, { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Axios from "axios";
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "./components/Loader/LoaderBlue";
import CommonLoader from "./components/Loader/Loader";
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CheckoutForm = ({ setPayinvoicesVisible, setShowStripe, setRefresh, cpData, amount, selectedInvoices }) => {
    let toast = useRef();
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const [alternateCardToken, setAlternateCardToken] = useState("");
    const [alternateCardDetailVisibility, setAlternateCardDetailVisibility] = useState(false);
    const [autoPay, setAutoPay] = useState(true);
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const [clientSecret, setClientSecret] = useState("");
    const [elements, setElements] = useState(null);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch the client secret as soon as the page loads
        initialize();
        //heckStatus();
    }, []);
    const formStyle = {
        fontFamily: '"Roboto", sans-serif', // Example font-family
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        fontWeight: "500",
    };

    const buttonStyle = {
        fontFamily: '"Roboto", sans-serif',
        backgroundColor: "#5469d4",
        color: "#ffffff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        marginTop: "10px",
    };
    const initialize = async () => {
        let response = await api.post(`/api/web/billing/paymentintent`, { amount: amount });
        const clientSecret = await response.data.clientSecret;
        setClientSecret(clientSecret);

        const appearance = {
            theme: "stripe",
            variables: {
                fontWeightMedium: "600",
                fontWeightNormal: "600",
            },
            rules: {
                ".Input:focus": {
                    borderColor: "#e6e6e6",
                    border: "1px solid #e6e6e6 ",
                    boxShadow: "none !important",
                },
            },
        };
        const stripe = await stripePromise;
        const elementsInstance = stripe.elements({ appearance, clientSecret });
        setElements(elementsInstance);

        const paymentElementOptions = {
            layout: "tabs",
        };

        const paymentElement = elementsInstance.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const stripe = await stripePromise;
        const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
            },
            redirect: "if_required",
        });
        if (paymentIntent?.status === "requires_action") {
            // 3D Secure authentication is required
            handle3DSecureAuthentication(paymentIntent.client_secret, paymentIntent);
        } else if (error) {
            toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message : "An error occurred while processing the payment"}</p>, life: 2000 });

            const idArray = selectedInvoices.map((obj) => obj._id);
            const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);

            api.post(`/api/web/billing/updatePaymentLog`, {
                customerId: cpData?._id,
                invoiceNo: idArray,
                invoiceType: invoiceTypeArray,
                paymentMethod: "Credit Card",
                stripeId: error?.payment_intent?.id,
                action: "failure",
                cardNumber: error?.payment_method?.card?.last4,
                reason: "failure",
                transactionStatus: error?.message,
                paymentStatus: "failure",
                userId: loginRes?._id,
                amount: amount,
            })
                .then((res) => {
                    setTimeout(() => {
                        setRefresh();
                        setShowStripe(false);
                    }, 1500);
                    // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    //toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
        } else {
            const idArray = selectedInvoices.map((obj) => obj._id);
            const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);
            toast.current.show({ severity: "success", summary: "Payment Proceeding", detail: "Payment Proceed Successfully" });

            api.post(`/api/web/billing/updatePaymentLog`, {
                customerId: cpData?._id,
                invoiceNo: idArray,
                invoiceType: invoiceTypeArray,
                paymentMethod: "Credit Card",
                stripeId: paymentIntent?.id,
                userId: loginRes?._id,
                action: "success",
                reason: "success",
                transactionStatus: paymentIntent?.status,
                paymentStatus: "success",
                amount: amount,
            })
                .then((res) => {
                    // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    ////toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });

            Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                invoices: idArray,
                amountPaid: amount,
                invoicePaymentMethod: "Credit Card",
                paymentId: paymentIntent?.id,
                customerId: cpData?._id,
                userId: loginRes?._id,
                isWallet: false,
            })
                .then(() => {
                    // toast.current.show({ severity: "success", summary: "Invoices Updation", detail: "Invoices Updated Successfully" });

                    setTimeout(() => {
                        setRefresh((prev) => !prev);
                        //setShowStripe(false)
                        setShowStripe(false);
                    }, 500);
                    //setRefresh(prev=>!prev)
                })
                .catch((err) => {
                    //toast.current.show({ severity: "error", summary: "Invoices Updation", detail: "Invoice Updation Failed" });
                    setTimeout(() => {
                        setShowStripe(false);
                    }, 2000);
                });
        }
        {
            /*
    if (error.type === "card_error" || error.type === "validation_error") {
      showMessage(error.message);
    } else {
      showMessage("An unexpected error occurred.");
    } 

    setIsLoading(false);*/
        }
    };
    const handle3DSecureAuthentication = async (clientSecret, paymentIntent) => {
        try {
            const stripe = await stripePromise;
            const { error } = await stripe.handleCardAction(clientSecret);
            if (error) {
                toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message : "An error occurred while processing the payment"}</p> });
                setTimeout(() => {
                    setShowStripe(false);
                }, 2000);
                const idArray = selectedInvoices.map((obj) => obj._id);
                const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);

                api.post(`/api/web/billing/updatePaymentLog`, {
                    customerId: cpData?._id,
                    invoiceNo: idArray,
                    invoiceType: invoiceTypeArray,
                    paymentMethod: "Credit Card",
                    stripeId: error?.payment_intent?.id,
                    action: "failure",
                    cardNumber: error?.payment_method?.card?.last4,
                    reason: "failure",
                    transactionStatus: error?.message,
                    paymentStatus: "failure",
                    userId: loginRes?._id,
                    amount: amount,
                })
                    .then((res) => {
                        // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                    })
                    .catch((err) => {
                        //toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                    });
            } else {
                const idArray = selectedInvoices.map((obj) => obj._id);
                const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);
                toast.current.show({ severity: "success", summary: "Payment Proceeding", detail: "Payment Proceed Successfully" });

                api.post(`/api/web/billing/updatePaymentLog`, {
                    customerId: cpData?._id,
                    invoiceNo: idArray,
                    invoiceType: invoiceTypeArray,
                    paymentMethod: "Credit Card",
                    stripeId: paymentIntent?.id,
                    userId: loginRes?._id,
                    action: "success",
                    reason: "success",
                    transactionStatus: paymentIntent?.status,
                    paymentStatus: "success",
                    amount: amount,
                })
                    .then((res) => {
                        // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                    })
                    .catch((err) => {
                        ////toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                    });

                Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                    invoices: idArray,
                    amountPaid: amount,
                    invoicePaymentMethod: "Credit Card",
                    paymentId: paymentIntent?.id,
                    customerId: cpData?._id,
                    userId: loginRes?._id,
                    isWallet: false,
                })
                    .then(() => {
                        // toast.current.show({ severity: "success", summary: "Invoices Updation", detail: "Invoices Updated Successfully" });

                        setTimeout(() => {
                            setRefresh((prev) => !prev);
                            //setShowStripe(false)
                            setShowStripe(false);
                        }, 500);
                        //setRefresh(prev=>!prev)
                    })
                    .catch((err) => {
                        //toast.current.show({ severity: "error", summary: "Invoices Updation", detail: "Invoice Updation Failed" });
                        setTimeout(() => {
                            setShowStripe(false);
                        }, 2000);
                    });
            }
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message : "An error occurred while processing the payment"}</p> });
            setTimeout(() => {
                setShowStripe(false);
            }, 2000);
            const idArray = selectedInvoices.map((obj) => obj._id);
            const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);

            api.post(`/api/web/billing/updatePaymentLog`, {
                customerId: cpData?._id,
                invoiceNo: idArray,
                invoiceType: invoiceTypeArray,
                paymentMethod: "Credit Card",
                stripeId: error?.payment_intent?.id,
                action: "failure",
                cardNumber: error?.payment_method?.card?.last4,
                reason: "failure",
                transactionStatus: error?.message,
                paymentStatus: "failure",
                userId: loginRes?._id,
                amount: amount,
            })
                .then((res) => {
                    // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    //toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
        } finally {
            //setIsLoading(false);
        }
    };
    const checkStatus = async () => {
        const clientSecretFromURL = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

        if (!clientSecretFromURL) {
            return;
        }

        const stripe = await stripePromise;
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecretFromURL);

        switch (paymentIntent.status) {
            case "succeeded":
                showMessage("Payment succeeded!");
                break;
            case "processing":
                showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                showMessage("Your payment was not successful, please try again.");
                break;
            default:
                showMessage("Something went wrong.");
                break;
        }
    };

    const showMessage = (messageText) => {
        setMessage(messageText);
        setTimeout(() => setMessage(""), 4000);
    };

    const setLoading = (isLoading) => {
        setIsLoading(isLoading);
    };

    return (
        <div>
            <Toast ref={toast} />
            {!clientSecret ? (
                <div className="flex flex-wrap flex-row justify-content-center align-items-center mt-4 mb-4">
                    <CommonLoaderBlue />
                </div>
            ) : undefined}
            <form id="payment-form" onSubmit={handleSubmit} style={formStyle}>
                <div id="payment-element"></div>
                <div className="w-full flex flex-wrap flex-row justify-content-center align-items-center ">
                    <button id="submit" style={buttonStyle} disabled={!clientSecret || isLoading}>
                        <span id="button-text">
                            {isLoading ? (
                                <div className="w-full flex flex-wrap flex-row justify-center align-items-center">
                                    <CommonLoader />
                                </div>
                            ) : (
                                "Pay now"
                            )}
                        </span>
                        <div id="spinner" className={isLoading ? "" : "hidden"}></div>
                    </button>
                </div>
                {message && <div id="payment-message">{message}</div>}
            </form>
        </div>
    );
};

const Payment = ({ setPayinvoicesVisible, setShowStripe, setRefresh, amount, cpData, selectedInvoices }) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm cpData={cpData} setRefresh={setRefresh} setShowStripe={setShowStripe} selectedInvoices={selectedInvoices} amount={amount} />
        </Elements>
    );
};

export default Payment;
