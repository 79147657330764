import React, { useEffect, useRef, useState } from "react";
import "./css/customer-profilemain.css";
import { Toast } from "primereact/toast";
import BillingNavbar from "../modals/BillingNavbar";
import { useLocation } from "react-router-dom";
import { api } from "../../../../../utility/AxiosInceptor";
import TopBarCustomerProfile from "./Components/TopBarCustomerProfile/TopBarCustomerProfile";
import { CustomerProfileInfo } from "./Components/CutomerProfile/Customer-Profile";
import { CustomerLineInfoMain } from "./Components/LineInfo/LineInfoMain";
import { OtherInfoCustomerProfileMain } from "./Components/OtherInfo/Otherinfo";
import { NotesCommonCustomerProfile } from "./Components/CommonComponent/Notes/MainNotesCustomerProfile";
import { PaymentMain } from "./Components/Payments/PaymentMain";
import { QuickLinkMain } from "./Components/QuickLinks/QuickLinksMain";
import { CustomerUsage } from "./Components/Usage/UsageMain";
import { MainCustomerFile } from "./Components/Files/Files";
import { Wallet } from "./Components/Wallet/Wallet";
import { ChildAccountMain } from "./Components/ChildAccounts/ChildAccountMain";
import AllCustomers from "../modals/All-Customers/AllCustomers";
export const MainCustomerProfile = ({ setRefreshOnNotification, refreshOnNotification, refreshEsn, model, setRefreshEsn, refreshNotificationcomponent }) => {
    const [cpData, setCpData] = useState([]);
    const toast = useRef(null);
    useEffect(() => {
        console.log(refreshOnNotification);
    });
    const [refreshwholecustomerdata, setRefreshWholeCustomerData] = useState(false);
    const { state } = useLocation();
    const [selectedId, setSelectedId] = useState(state?.selectedId ? state?.selectedId : JSON.parse(localStorage.getItem("selectedId")));
    // if (state?.selectedId) {
    //     localStorage.setItem("selectedId", JSON.stringify(state?.selectedId));
    // }
    const [changeCustomerStatusDialog, setChangeCustomerStatus] = useState(false);
    const [currentSelectedTab, setCurrentSelectedTab] = useState("customer-profile");
    //Call Only Once ServiceInfo Api It Is Paid
    const [serviceInfoApiCalled, setServiceInfoApiCalled] = useState(false);
    //Plan Data Of Customer Will Show Here
    const [planData, setPlanData] = useState();
    //Get Customer Profile Data on the basis of id getting from Route state
    const getCustomerProfileData = async () => {
        if (!serviceInfoApiCalled) {
            setServiceInfoApiCalled(true);
            let res2;
            try {
                const res = await api.get(`/api/user/userDetails?userId=${JSON.parse(localStorage.getItem("selectedId"))}`);
                res2 = res?.data?.data;
                if (res?.status == 200 || res?.status == 201) {
                    try {
                        const response = await api.get(`/api/web/order/getTrackingNumber/?customerId=${JSON.parse(localStorage.getItem("selectedId"))}`);
                        if (response?.status === 200 || response?.status === 201) {
                            setCpData(res?.data?.data ? { ...res?.data?.data, trackingNumber: response?.data?.data } : []);
                        }
                    } catch (error) {
                        setCpData(res?.data?.data || []);
                    }
                }
            } catch (error) {
                setCpData({});
                // toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg || "Error In Fetching User" });
            }

            if (res2?.esn !== undefined || res2?.phoneNumber !== undefined) {
                const dataToSend = {
                    enrollmentId: res2._id,
                    mdn: res2?.phoneNumber,
                    esn: res2?.esn,
                };

                api.post(`/api/user/serviceInfo`, dataToSend)
                    .then(async (res) => {
                        setPlanData(res?.data?.data);
                    })
                    .catch((err) => {});
            }
        } else {
            try {
                const res = await api.get(`/api/user/userDetails?userId=${JSON.parse(localStorage.getItem("selectedId"))}`);
                if (res?.status == 200 || res?.status == 201) {
                    setCpData(res?.data?.data || {});
                }
            } catch (error) {
                setCpData({});
                // toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg || "Error In Fetching User" });
            }
        }
    };
    const [showAllCustomers, setShowAllCustomers] = useState(true);

    const [refreshCustomerData, setRefreshCustomerData] = useState(false);
    useEffect(() => {
        if (!showAllCustomers) {
            if (refreshOnNotification !== "") {
                setServiceInfoApiCalled(false);
                setCurrentSelectedTab("customer-profile");
                setSelectedId(refreshOnNotification);
            }
            getCustomerProfileData();
        }
        return () => {
            setRefreshOnNotification("");
        };
    }, [refreshCustomerData, showAllCustomers, refreshOnNotification]);

    useEffect(() => {
        if (localStorage.getItem("selectedId")) {
            setShowAllCustomers(false);
        } else {
            setShowAllCustomers(true);
        }
        return () => {
            localStorage.removeItem("selectedId");
        };
    }, []);
    useEffect(() => {
        if (refreshOnNotification !== "") {
            setShowAllCustomers(false);
        }
    }, [refreshOnNotification]);
    return (
        <>
            {" "}
            {showAllCustomers || !localStorage.getItem("selectedId") ? (
                <AllCustomers setRefreshCustomerData={setRefreshCustomerData} setShowAllCustomers={setShowAllCustomers} />
            ) : (
                <div className="customer-profile-main-container">
                    <TopBarCustomerProfile
                        setServiceInfoApiCalled={setServiceInfoApiCalled}
                        setRefreshWholeCustomerData={setRefreshWholeCustomerData}
                        refreshNotificationcomponent={refreshNotificationcomponent}
                        refreshEsn={refreshEsn}
                        setCurrentSelectedTab={setCurrentSelectedTab}
                        currentSelectedTab={currentSelectedTab}
                        setChangeCustomerStatus={setChangeCustomerStatus}
                        changeCustomerStatusDialog={changeCustomerStatusDialog}
                        setRefreshEsn={setRefreshEsn}
                        setSelectedId={setSelectedId}
                        cpData={cpData}
                        model={model}
                        setRefreshCustomerData={setRefreshCustomerData}
                        selectedId={selectedId}
                    />
                    {currentSelectedTab === "customer-profile" || currentSelectedTab === "line-info" || currentSelectedTab === "other-info" ? (
                        <>
                            <div className={`${currentSelectedTab === "line-info" ? "line-info-main" : currentSelectedTab === "customer-profile" ? "customer-profile-main-info" : currentSelectedTab === "other-info" ? "other-info-main" : ""}`}>
                                {currentSelectedTab === "customer-profile" ? (
                                    <CustomerProfileInfo setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} />
                                ) : currentSelectedTab === "line-info" ? (
                                    <CustomerLineInfoMain planData={planData} cpData={cpData} />
                                ) : currentSelectedTab === "other-info" ? (
                                    <OtherInfoCustomerProfileMain cpData={cpData} />
                                ) : undefined}
                                <div className="SideoneNotes">
                                    <NotesCommonCustomerProfile cpData={cpData} />
                                </div>
                            </div>
                        </>
                    ) : currentSelectedTab === "payment" ? (
                        <PaymentMain setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} />
                    ) : currentSelectedTab === "quick-links" ? (
                        <QuickLinkMain cpData={cpData} />
                    ) : currentSelectedTab === "usage" ? (
                        <CustomerUsage cpData={cpData} />
                    ) : currentSelectedTab === "files" ? (
                        <MainCustomerFile cpData={cpData} />
                    ) : currentSelectedTab === "wallet" ? (
                        <Wallet setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} />
                    ) : currentSelectedTab === "child-accounts" ? (
                        <ChildAccountMain setServiceInfoApiCalled={setServiceInfoApiCalled} setRefreshOnNotification={setRefreshOnNotification} setCurrentSelectedTab={setCurrentSelectedTab} setSelectedId={setSelectedId} setRefreshCustomerData={setRefreshCustomerData} cpData={cpData} />
                    ) : undefined}
                </div>
            )}
            <Toast ref={toast} />
        </>
    );
};
